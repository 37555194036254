import React, { useEffect } from "react";
import { ControlType, applyPropertyControls } from "../ui/controltype";
import {
  ComposedChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Line as RLine,
  Tooltip,
  Customized,
} from "recharts";
import { TextBlock } from "./textBlock";
import { CustomizedAxisTick } from "./customTick";
import { CustomizedTooltip } from "./customTooltip";
import { CustomLegend } from "./customLegend";
import { useDispatch } from "react-redux";
import { UPDATE_CHART_REF } from "../utils/actions";
import { Helmet } from "react-helmet";
import {
  presetXAxis,
  presetYAxis,
  presetGrid,
  presetLayout,
  presetTitle,
  presetLegend,
  presetCaption,
  presetTooltip,
  presetRefDots,
  presetRefLines,
  presetRefAreas,
} from "./propertyControlsPresets";
import {
  CustomizedBarchartLabel,
  CustomizedXAxisLabel,
  CustomizedYAxisLabel,
} from "./customLabel";
import {
  CustomizedRefDot,
  CustomizedRefLine,
  CustomizedRefArea,
} from "./customRef";
import {
  getDomains,
  normalizeDimensions,
  getValidDimensionDataArr,
  getTicks,
} from "../utils/utils";
import { useHeaderHeight } from "./customHooks";

function getValues(count) {
  return Array.from(
    { length: count },
    () => Math.floor(Math.random() * 10) * (Math.round(Math.random()) ? 1 : -1)
  );
}

// gets data, highlights bars and returns data extended with isHighlighted prop and color
export function getHighlightedBars(
  data,
  totalColor,
  incColor,
  decColor,
  labelColor,
  isSyncColors
) {
  const result = [] as any;

  if (data.length > 0) {
    data.map((elem, i) => {
      const color =
        i === 0 || i === data.length - 1
          ? totalColor
          : elem.value[1] - elem.value[0] >= 0
          ? incColor
          : decColor;

      result.push({
        ...elem,
        isHighlighted: true,
        color: color,
        labelColor: isSyncColors ? color : labelColor,
      });
    });
  }

  return result;
}

const CustomizedDotLine = (props) => {
  const {
    cx,
    cy,
    dotsColor,
    gap,
    dotsStrokeWidth,
    layout,
    pointsCount,
    width,
    height,
    index,
  } = props;

  const isLastPoint = index === pointsCount - 1;

  const barWidth = layout
    ? Math.floor((width - gap * pointsCount) / pointsCount)
    : Math.floor((height - gap * pointsCount) / pointsCount);

  const markWidth = layout
    ? isLastPoint
      ? barWidth
      : barWidth * 2 + gap
    : dotsStrokeWidth;

  const markHeight = layout
    ? dotsStrokeWidth
    : isLastPoint
    ? barWidth
    : barWidth * 2 + gap;

  const x1 = layout ? 0 : markWidth / 2;
  const x2 = layout ? markWidth : markWidth / 2;

  const y1 = layout ? markHeight / 2 : 0;
  const y2 = layout ? markHeight / 2 : markHeight;

  return (
    <svg
      x={layout ? cx - barWidth / 2 : cx}
      y={layout ? cy : cy - barWidth / 2}
      width={markWidth}
      height={markHeight}
    >
      <line
        x1={x1}
        x2={x2}
        y1={y1}
        y2={y2}
        stroke={dotsColor}
        strokeWidth={dotsStrokeWidth}
      />
    </svg>
  );
};

const barsCount = 11;
const dimensionsData = { x: "items", y: "bars" }; // link data with dimensions

export function Waterfall(props) {
  const {
    layoutSettings,
    chartSettings,
    dataItems,
    dataSeries,
    xAxis,
    yAxis,
    dimensions,
    grid,
    title,
    legend,
    caption,
    tooltip,
    refDots,
    refLines,
    refAreas,
  } = props;

  const { background, width } = layoutSettings;
  const { margin } = chartSettings;

  const dataItemsJSON = JSON.stringify(dataItems.items);
  const dataSeriesJSON = JSON.stringify(dataSeries);

  const dispatch = useDispatch();

  const [data, setData] = React.useState([] as any);
  const [highlightedBars, setHighlightedBars] = React.useState([] as any);
  const [domain, setDomain] = React.useState({} as any);
  const [ticks, setTicks] = React.useState({} as any);
  const [dataTypes, setDataTypes] = React.useState({} as any);
  const [isData, setIsData] = React.useState(false);
  const [layout, setLayout] = React.useState(chartSettings.chartLayout);
  const [fontLoaded, setFontLoaded] = React.useState(false);

  const chartRef = React.useRef<HTMLDivElement>(null);
  const { headerRef, height } = useHeaderHeight(
    width,
    layoutSettings,
    caption,
    title,
    legend,
    legend.nameTotals + legend.nameInc + legend.nameDec
  );

  // custom fonts loading status
  title["fontLoaded"] = fontLoaded;
  for (let i = 0; i < caption.items.length; i++) {
    caption.items[i]["fontLoaded"] = fontLoaded;
  }

  function buildData() {
    const itemsValid = getValidDimensionDataArr(
      dataItems.items,
      dimensions.items
    );

    const seriesValid = getValidDimensionDataArr(
      dataSeries.bars,
      dimensions.bars
    );

    let chartData = [];
    if (seriesValid.length > 0) {
      let summ = 0;
      chartData = itemsValid.map((elem, i) => {
        const v = seriesValid[i];
        if (i === seriesValid.length - 1) {
          summ = 0;
        }
        const values = {
          name: elem,
          value: [summ, summ + v],
          vLine: summ + v,
        };
        summ += v;
        return values;
      });
    }

    return chartData;
  }

  useEffect(() => {
    document.fonts.ready.then(() => {
      setFontLoaded(true);
    });
  });

  let isNewData = false;

  useEffect(() => {
    const dataBuild = buildData();
    const highlighted = getHighlightedBars(
      dataBuild,
      chartSettings.totalColor,
      chartSettings.incColor,
      chartSettings.decColor,
      chartSettings.labelsColor,
      chartSettings.syncColors
    );
    const chartDimensions = normalizeDimensions(dimensions);
    const chartDomain = getDomains({
      x: xAxis,
      y: yAxis,
      dimensions: chartDimensions,
      dimensionsData: dimensionsData,
      layout: chartSettings.chartLayout,
    });
    const chartTicks = getTicks(
      dataBuild,
      chartDimensions,
      chartDomain,
      xAxis,
      yAxis,
      dimensionsData,
      chartSettings.chartLayout
    );
    const isDataNotEmpty = dataBuild.length > 0;

    setData(dataBuild);
    setHighlightedBars(highlighted);
    setDomain(chartDomain);
    setDataTypes(chartDimensions);
    setTicks(chartTicks);
    setIsData(isDataNotEmpty);
    setLayout(chartSettings.chartLayout);

    isNewData = true;
  }, [
    dataItemsJSON,
    dataSeriesJSON,
    dimensions.items,
    dimensions.bars,
    chartSettings.chartLayout,
  ]);

  useEffect(() => {
    if (isData && !isNewData) {
      const chartDomain = getDomains({
        x: xAxis,
        y: yAxis,
        dimensions: dataTypes,
        dimensionsData: dimensionsData,
        layout: layout,
      });
      const chartTicks = getTicks(
        data,
        dataTypes,
        chartDomain,
        xAxis,
        yAxis,
        dimensionsData,
        layout
      );

      setDomain(chartDomain);
      setTicks(chartTicks);
    }
  }, [
    xAxis.domain,
    xAxis.domainMax,
    xAxis.domainMaxValue,
    xAxis.domainMin,
    xAxis.domainMinValue,
    yAxis.domain,
    yAxis.domainMax,
    yAxis.domainMaxValue,
    yAxis.domainMin,
    yAxis.domainMinValue,
  ]);

  useEffect(() => {
    if (isData && !isNewData) {
      const chartTicks = getTicks(
        data,
        dataTypes,
        domain,
        xAxis,
        yAxis,
        dimensionsData,
        layout
      );
      setTicks(chartTicks);
    }
  }, [
    xAxis.isTicksAuto,
    xAxis.tickCountValue,
    xAxis.tickCountAccuracy,
    xAxis.tickCountCategory,
    xAxis.tickCountValueCategory,
    yAxis.isTicksAuto,
    yAxis.tickCountValue,
    yAxis.tickCountAccuracy,
    yAxis.tickCountCategory,
    yAxis.tickCountValueCategory,
  ]);

  useEffect(() => {
    if (isData && !isNewData) {
      const highlighted = getHighlightedBars(
        data,
        chartSettings.totalColor,
        chartSettings.incColor,
        chartSettings.decColor,
        chartSettings.labelsColor,
        chartSettings.syncColors
      );

      setHighlightedBars(highlighted);
    }
  }, [
    chartSettings.totalColor,
    chartSettings.incColor,
    chartSettings.decColor,
    chartSettings.labelsColor,
    chartSettings.syncColors,
  ]);

  useEffect(() => {
    const element = chartRef.current;
    if (element) {
      const svg = element;
      dispatch(UPDATE_CHART_REF(svg));
    }
  }, [chartRef, dispatch]);

  return (
    <div ref={chartRef} style={{ background: background }}>
      <Helmet>
        <link
          rel="stylesheet"
          href={
            "https://fonts.googleapis.com/css?family=" +
            title.fontFamily.replace(/ /g, "+") +
            ":" +
            title.fontVariants
          }
          crossOrigin="anonymous"
        />
        {caption.items.map((elem, i) => {
          return (
            <link
              key={"caption-" + i}
              rel="stylesheet"
              href={
                "https://fonts.googleapis.com/css?family=" +
                elem.fontFamily.replace(/ /g, "+") +
                ":" +
                elem.fontVariants
              }
              crossOrigin="anonymous"
            />
          );
        })}
      </Helmet>
      <div ref={headerRef} style={{ width: width }}>
        {title.isVisible ? (
          <TextBlock key={"title"} title={title} fontLoaded={fontLoaded} />
        ) : (
          ""
        )}
        {caption.isVisible
          ? caption.items.map((elem, i) => {
              return (
                <TextBlock
                  key={"caption" + i}
                  title={elem}
                  fontLoaded={fontLoaded}
                />
              );
            })
          : ""}
        {legend.isVisible ? (
          <CustomLegend
            legendProps={legend}
            items={[
              {
                name: legend.nameTotals,
                dotsType: "circle",
                dotsColor: chartSettings.totalColor,
                dotsStyle: true,
              },
              {
                name: legend.nameInc,
                dotsType: "circle",
                dotsColor: chartSettings.incColor,
                dotsStyle: true,
              },
              {
                name: legend.nameDec,
                dotsType: "circle",
                dotsColor: chartSettings.decColor,
                dotsStyle: true,
              },
            ]}
          />
        ) : (
          ""
        )}
      </div>
      {isData ? (
        <ComposedChart
          width={width}
          height={height}
          data={data}
          layout={layout ? "horizontal" : "vertical"}
          margin={
            margin.isPerSide
              ? {
                  top: margin.top,
                  right: margin.right,
                  left: margin.left,
                  bottom: margin.bottom,
                }
              : {
                  top: margin.margin,
                  right: margin.margin,
                  left: margin.margin,
                  bottom: margin.margin,
                }
          }
          style={{ background: background }}
          barGap={0}
          barCategoryGap={chartSettings.barGap / 2}
        >
          {refAreas.isVisible
            ? refAreas.items.map((elem, i) => (
                <Customized
                  key={"refArea-" + i}
                  component={
                    <CustomizedRefArea
                      cProps={{
                        elem: elem,
                        dataTypes: {
                          items: dataTypes.items,
                          series: dataTypes.bars,
                        },
                        existedValue: {
                          x: data[0]["name"],
                          y: data[0]["value"][0],
                        },
                        layout: layout,
                        xAxisSettings: xAxis,
                        yAxisSettings: yAxis,
                        marginSettings: margin,
                        width: width,
                        height: height,
                      }}
                    />
                  }
                />
              ))
            : null}
          <CartesianGrid
            horizontal={grid.gridHoriz}
            vertical={grid.gridVert}
            stroke={grid.gridColor}
            syncWithTicks={true}
          />
          {tooltip.isVisible ? (
            <Tooltip
              separator={tooltip.separator}
              cursor={
                tooltip.showCursor
                  ? { strokeWidth: 1, stroke: tooltip.cursorColor }
                  : false
              }
              content={
                <CustomizedTooltip
                  txtColor={tooltip.txtColor}
                  background={tooltip.bgColor}
                  chartType={"waterfall"}
                  layout={layout}
                  units={{ x: xAxis.units, y: yAxis.units }}
                  axesDataTypes={{
                    x: layout ? dataTypes.items : dataTypes.bars,
                    y: layout ? dataTypes.bars : dataTypes.items,
                  }}
                  axesLabels={{ x: xAxis.labelText, y: yAxis.labelText }}
                  useAxesLabels={tooltip.useAxesLabels}
                  useLocales={{ x: xAxis.useLocale, y: yAxis.useLocale }}
                />
              }
            />
          ) : (
            ""
          )}
          <XAxis
            dataKey={layout ? "name" : undefined}
            type={layout ? dataTypes.items : dataTypes.bars}
            axisLine={xAxis.isAxisLine}
            ticks={ticks.x}
            interval={0}
            reversed={xAxis.tickOrder}
            hide={!xAxis.isVisible}
            height={xAxis.height}
            domain={domain.X}
            stroke={xAxis.color}
            orientation={xAxis.orient}
            tickSize={xAxis.tickSize}
            tickMargin={xAxis.tickMargin}
            tick={
              ticks.x.length == 0 ? (
                false
              ) : (
                <CustomizedAxisTick
                  fontSize={xAxis.tickFontSize}
                  axis={"x"}
                  units={xAxis.units ? xAxis.units : ""}
                  useLocale={xAxis.useLocale}
                  type={layout ? dataTypes.items : dataTypes.bars}
                  tickAlign={xAxis.orient}
                  angle={xAxis.tickRotate}
                />
              )
            }
            label={
              xAxis.isLabelVisible ? (
                <CustomizedXAxisLabel
                  value={xAxis.labelText}
                  fontSize={xAxis.labelFontSize}
                  color={xAxis.labelColor}
                  align={xAxis.labelAlign}
                  margin={xAxis.labelMargin}
                  axisOrient={xAxis.orient}
                />
              ) : (
                ""
              )
            }
          />
          <YAxis
            dataKey={layout ? undefined : "name"}
            type={layout ? dataTypes.bars : dataTypes.items}
            axisLine={yAxis.isAxisLine}
            ticks={ticks.y}
            interval={0}
            reversed={yAxis.tickOrder}
            hide={!yAxis.isVisible}
            width={yAxis.width}
            domain={domain.Y}
            stroke={yAxis.color}
            orientation={yAxis.orient}
            // allowDuplicatedCategory={false}
            tickSize={yAxis.tickSize}
            tickMargin={yAxis.tickMargin}
            tick={
              ticks.y.length == 0 ? (
                false
              ) : (
                <CustomizedAxisTick
                  fontSize={yAxis.tickFontSize}
                  axis={"y"}
                  units={yAxis.units ? yAxis.units : ""}
                  useLocale={yAxis.useLocale}
                  type={layout ? dataTypes.bars : dataTypes.items}
                  tickAlign={yAxis.orient}
                />
              )
            }
            label={
              yAxis.isLabelVisible ? (
                <CustomizedYAxisLabel
                  value={yAxis.labelText}
                  fontSize={yAxis.labelFontSize}
                  color={yAxis.labelColor}
                  align={yAxis.labelAlign}
                  margin={yAxis.labelMargin}
                  axisOrient={yAxis.orient}
                  rotateAngle={yAxis.labelRotate}
                />
              ) : (
                ""
              )
            }
          />
          <Bar
            dataKey="value"
            label={
              chartSettings.showLabels
                ? {
                    content: (
                      <CustomizedBarchartLabel
                        txtColor={chartSettings.labelsColor}
                        fontSize={chartSettings.labelsFontSize}
                        layout={layout}
                        units={layout ? yAxis.units : xAxis.units}
                        values={data.map(
                          (elem) => elem.value[1] - elem.value[0]
                        )}
                        highlightedBars={highlightedBars}
                      />
                    ),
                  }
                : false
            }
            background={{ fill: chartSettings.bgColor }}
            radius={
              chartSettings.barRadius.isPerCorner
                ? [
                    chartSettings.barRadius.TL,
                    chartSettings.barRadius.TR,
                    chartSettings.barRadius.BR,
                    chartSettings.barRadius.BL,
                  ]
                : chartSettings.barRadius.barRadius
            }
            isAnimationActive={false} // turn off animation because of dot's bug - https://github.com/recharts/recharts/issues/1426
          >
            {data.map((entry: any, index) => {
              const color =
                index > 0 && index < data.length - 1
                  ? entry.value[1] - entry.value[0] >= 0
                    ? chartSettings.incColor
                    : chartSettings.decColor
                  : chartSettings.totalColor;
              return <Cell key={`barCell-${index}`} fill={color} />;
            })}
          </Bar>
          {chartSettings.showLine ? (
            <RLine
              dataKey="vLine"
              dot={
                <CustomizedDotLine
                  layout={layout}
                  dotsColor={chartSettings.lineColor}
                  gap={chartSettings.barGap}
                  dotsStrokeWidth={1}
                  pointsCount={data.length}
                />
              }
              strokeWidth={0}
              isAnimationActive={false}
            />
          ) : (
            ""
          )}
          {refLines.isVisible
            ? refLines.items.map((elem, i) => (
                <Customized
                  key={"refLine-" + i}
                  component={
                    <CustomizedRefLine
                      cProps={{
                        elem: elem,
                        dataTypes: {
                          items: dataTypes.items,
                          series: dataTypes.bars,
                        },
                        existedValue: {
                          x: data[0]["name"],
                          y: data[0]["value"][0],
                        },
                        layout: layout,
                        xAxisSettings: xAxis,
                        yAxisSettings: yAxis,
                        marginSettings: margin,
                        width: width,
                        height: height,
                      }}
                    />
                  }
                />
              ))
            : null}
          {refDots.isVisible
            ? refDots.items.map((elem, i) => (
                <Customized
                  key={"refDot-" + i}
                  component={
                    <CustomizedRefDot
                      cProps={{
                        elem: elem,
                        dataTypes: {
                          items: dataTypes.items,
                          series: dataTypes.bars,
                        },
                        existedValue: {
                          x: data[0]["name"],
                          y: data[0]["value"][0],
                        },
                        layout: layout,
                        xAxisSettings: xAxis,
                        yAxisSettings: yAxis,
                        marginSettings: margin,
                        width: width,
                        height: height,
                      }}
                    />
                  }
                />
              ))
            : null}
        </ComposedChart>
      ) : (
        <span style={{ opacity: 0.25 }}>Not enough data to chart</span>
      )}
    </div>
  );
}

const defaultDimensionsTypes = {
  items: ControlType.String,
  bars: ControlType.Number,
};

Waterfall.defaultProps = {
  isData: false,
  layoutSettings: {
    isFullscreen: true,
    isWidthAuto: true,
    minWidth: 320,
    maxWidth: 2500,
    isHeightAuto: true,
    background: "rgba(255, 255, 255, 1)",
  },
  chartSettings: {
    chartLayout: true,
    barGap: 16,
    totalColor: "rgba(17, 153, 238, 1)",
    incColor: "rgba(17, 153, 238, 1)",
    decColor: "rgba(17, 153, 238, 1)",
    barRadius: {
      barRadius: 0,
      isPerCorner: false,
      TL: 0,
      TR: 0,
      BR: 0,
      BL: 0,
    },
    bgColor: "rgba(17, 153, 238, 0)",
    showLabels: false,
    syncColors: true,
    labelsColor: "rgba(17, 153, 238, 1)",
    labelsFontSize: 12,
    showLine: true,
    lineColor: "rgba(17, 153, 238, 1)",
    margin: {
      margin: 100,
      isPerSide: false,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  sourceData: true,
  fileData: null,
  xAxis: {
    isVisible: false,
    isLabelVisible: true,
    labelText: "X label",
    labelAlign: "middle",
    labelMargin: -20,
    color: "rgba(17, 153, 238, 1)",
    labelFontSize: 12,
    labelColor: "rgba(17, 153, 238, 1)",
    height: 60,
    orient: "bottom",
    isAxisLine: true,
    domain: true,
    domainMin: "dataMin",
    domainMinValue: 0,
    domainMax: "dataMax",
    domainMaxValue: 1,
    tickFontSize: 12,
    isTicksAuto: true,
    tickCountValue: 5,
    tickCountAccuracy: false,
    tickCountCategory: true,
    tickCountValueCategory: 5,
    tickRotate: 0,
    tickSize: 6,
    tickMargin: 4,
    tickOrder: false,
    units: "",
    useLocale: true,
  },
  yAxis: {
    isVisible: false,
    isLabelVisible: true,
    labelText: "Y label",
    labelAlign: "middle",
    labelMargin: -20,
    color: "rgba(17, 153, 238, 1)",
    labelFontSize: 12,
    labelRotate: -90,
    labelColor: "rgba(17, 153, 238, 1)",
    width: 60,
    orient: "left",
    isAxisLine: true,
    domain: true,
    domainMin: 0,
    domainMinValue: 0,
    domainMax: "custom",
    domainMaxValue: 100,
    tickFontSize: 12,
    isTicksAuto: true,
    tickCountValue: 5,
    tickCountAccuracy: false,
    tickCountCategory: true,
    tickCountValueCategory: 5,
    tickSize: 6,
    tickMargin: 4,
    tickOrder: false,
    units: "",
    useLocale: true,
  },
  grid: {
    gridHoriz: false,
    gridVert: false,
    gridColor: "rgba(17, 153, 238, .5)",
  },
  dataItems: {
    get items() {
      const arr = [...Array(barsCount)].map((_, i) =>
        String.fromCharCode("A".charCodeAt(0) + i)
      );
      return arr;
    },
  },
  dataSeries: {
    get bars() {
      let values = getValues(barsCount);
      values[0] = 40;
      let summ = 0;
      for (let i = 0; i < values.length - 1; i++) {
        summ += values[i];
      }
      values[values.length - 1] = summ;
      return values;
    },
  },
  dimensions: defaultDimensionsTypes,
  legend: {
    isVisible: false,
    nameTotals: "Totals",
    nameInc: "Increment",
    nameDec: "Decrement",
    txtColor: "rgba(0,0,0,1)",
    fontSize: 14,
    iconSize: 16,
    layout: true,
    align: "center",
    margin: {
      margin: 0,
      isPerSide: true,
      top: 20,
      bottom: 20,
      left: 0,
      right: 0,
    },
  },
  title: {
    isVisible: false,
    text: "Chart title",
    txtColor: "rgba(0,0,0,1)",
    fontSize: 48,
    fontFamily: "Roboto",
    fontVariants: "regular",
    align: "center",
    margin: {
      margin: 0,
      isPerSide: true,
      top: 20,
      bottom: 20,
      left: 0,
      right: 0,
    },
  },
  refDots: {
    isVisible: false,
    items: [
      {
        xCoordinateNumber: null,
        xCoordinateCategory: "",
        yCoordinateNumber: null,
        yCoordinateCategory: "",
        dotSize: 16,
        dotColor: "rgba(238, 68, 69, 1)",
        dotStyle: true,
        dotStrokeWidth: 1,
        showLabel: false,
        name: "",
        labelColor: "rgba(0, 0, 0, 1)",
        labelFontSize: 12,
        labelPosition: "R",
        labelOffsetX: 0,
        labelOffsetY: 0,
        labelWrap: true,
        labelWidth: 20,
        showLabelLine: true,
        labelLineColor: "rgba(0, 0, 0, 1)",
        showLabelImage: false,
        labelImgUrl: "",
        labelImgPosition: false,
        labelImgCustomSize: false,
        labelImgWidth: 200,
      },
    ],
  },
  refLines: {
    isVisible: false,
    items: [
      {
        orient: true,
        xCoordinateNumber: null,
        xCoordinateCategory: "",
        yCoordinateNumber: null,
        yCoordinateCategory: "",
        lineColor: "rgba(238, 68, 69, 1)",
        lineStrokeWidth: 1,
        lineStyle: "Solid",
        showLabel: false,
        name: "",
        labelColor: "rgba(0, 0, 0, 1)",
        labelFontSize: 12,
        labelPosition: "TC",
        labelOffsetX: 0,
        labelOffsetY: 0,
        labelWrap: true,
        labelWidth: 20,
        labelPositionHoriz: "center",
        labelPositionVertOrient: false,
        labelPositionVert: "center",
        labelPositionVertAlign: "center",
      },
    ],
  },
  refAreas: {
    isVisible: false,
    items: [
      {
        x1CoordinateNumber: null,
        x2CoordinateNumber: null,
        x1CoordinateCategory: "",
        x2CoordinateCategory: "",
        y1CoordinateNumber: null,
        y2CoordinateNumber: null,
        y1CoordinateCategory: "",
        y2CoordinateCategory: "",
        fillColor: "rgba(238, 68, 69, .2)",
        strokeColor: "rgba(0, 0, 0, 1)",
        strokeWidth: 0,
        strokeStyle: "Solid",
      },
    ],
  },
  caption: {
    isVisible: false,
    items: [
      {
        text: "Chart description or data source",
        txtColor: "rgba(0,0,0,.5)",
        fontSize: 18,
        fontFamily: "Roboto",
        fontVariants: "300",
        align: "center",
        margin: {
          margin: 0,
          isPerSide: true,
          top: 20,
          bottom: 20,
          left: 0,
          right: 0,
        },
      },
    ],
  },
  tooltip: {
    isVisible: false,
    txtColor: "rgba(0,0,0,1)",
    bgColor: "rgba(255,255,255,1)",
    separator: ": ",
    useAxesLabels: false,
    showCursor: true,
    cursorColor: "rgba(17, 153, 238, 1)",
  },
};

applyPropertyControls(Waterfall, {
  //Settings or data
  isData: {
    type: ControlType.Boolean,
    title: "Change",
    disabledTitle: "settings",
    enabledTitle: "data",
    wide: true,
  },

  layoutSettings: presetLayout,

  chartSettings: {
    type: ControlType.Group,
    title: "Chart settings",
    isHeaderControls: false,
    propertyControl: {
      chartLayout: {
        type: ControlType.Boolean,
        title: "Layout",
        enabledTitle: "horizontal",
        disabledTitle: "vertical",
      },
      barGap: {
        type: ControlType.Number,
        title: "Bar gap",
      },
      totalColor: {
        type: ControlType.Color,
        title: "Totals color",
      },
      incColor: {
        type: ControlType.Color,
        title: "Increment",
      },
      decColor: {
        type: ControlType.Color,
        title: "Decrement",
      },
      barRadius: {
        type: ControlType.FusedNumber,
        title: "Radius",
        toggleKey: "isPerCorner",
        toggleTitles: ["All", "Individual"],
        valueKeys: ["TL", "TR", "BR", "BL"],
        valueLabels: ["TL", "TR", "BR", "BL"],
        min: 0,
      },
      bgColor: {
        type: ControlType.Color,
        title: "Bg color",
      },

      //Labels
      showLabels: {
        type: ControlType.Boolean,
        title: "Labels",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      syncColors: {
        type: ControlType.Boolean,
        title: "↳ Color",
        enabledTitle: "sync",
        disabledTitle: "custom",
        hidden(props) {
          return props.showLabels === false;
        },
      },
      labelsColor: {
        type: ControlType.Color,
        title: "　↳ Color",
        hidden(props) {
          return props.showLabels === false || props.syncColors === true;
        },
      },
      labelsFontSize: {
        type: ControlType.Number,
        title: "↳ Size",
        hidden(props) {
          return props.showLabels === false;
        },
      },

      //Line
      showLine: {
        type: ControlType.Boolean,
        title: "Line",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      lineColor: {
        type: ControlType.Color,
        title: "↳ Color",
        hidden(props) {
          return props.showLine === false;
        },
      },
      margin: {
        type: ControlType.FusedNumber,
        title: "Margin",
        toggleKey: "isPerSide",
        toggleTitles: ["All", "Individual"],
        valueKeys: ["top", "right", "bottom", "left"],
        valueLabels: ["T", "R", "B", "L"],
        min: 0,
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Axes
  xAxis: presetXAxis(dimensionsData, true), // link data with axes
  yAxis: presetYAxis(dimensionsData, true),

  //Grid
  grid: presetGrid,

  //Reference dots
  refDots: presetRefDots(dimensionsData, true),

  //Reference lines
  refLines: presetRefLines(dimensionsData, true),

  //Reference areas
  refAreas: presetRefAreas(dimensionsData, true),

  //Title
  title: presetTitle,

  //Caption
  caption: presetCaption,

  //Legend
  legend: {
    ...presetLegend,
    propertyControl: {
      nameTotals: {
        type: ControlType.String,
        title: "Totals",
        placeholder: "name for legend",
      },
      nameInc: {
        type: ControlType.String,
        title: "Increment",
        placeholder: "name for legend",
      },
      nameDec: {
        type: ControlType.String,
        title: "Decrement",
        placeholder: "name for legend",
      },
      ...presetLegend.propertyControl,
    },
  },

  //Tooltip
  tooltip: {
    ...presetTooltip(true),
    propertyControl: {
      separator: {
        type: ControlType.String,
        title: "Separator",
      },
      useAxesLabels: {
        type: ControlType.Boolean,
        title: "Axes labels",
        enabledTitle: "use",
        disabledTitle: "ignore",
      },
      ...presetTooltip(true).propertyControl,
    },
  },

  //Data
  sourceData: {
    type: ControlType.Boolean,
    title: "Data",
    enabledTitle: "editable",
    disabledTitle: "file",
    hidden(props) {
      return props.isData === false;
    },
  },
  fileData: {
    title: "↳ File",
    type: ControlType.File,
    allowedFileTypes: ["xls"],
    hidden(props) {
      return props.sourceData === true || props.isData === false;
    },
  },

  // Data types
  dimensions: {
    type: ControlType.Group,
    title: "Axes data types",
    propertyControl: {
      items: {
        type: ControlType.Enum,
        seriesType: false,
        title(props, ui) {
          return ui.chartSettings.chartLayout ? "X" : "Y";
        },
        path: "dataItems", // path to data
        optionTitles: ["number", "category"],
        options: [ControlType.Number, ControlType.String],
        displaySegmentedControl: true,
      },
      bars: {
        type: ControlType.Enum,
        seriesType: false,
        title(props, ui) {
          return ui.chartSettings.chartLayout ? "Y" : "X";
        },
        path: "dataSeries", // path to data
        optionTitles: ["number", "category"],
        options: [ControlType.Number],
        displaySegmentedControl: true,
        hidden(props) {
          return true;
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },

  // Items data (usually - x axis)
  dataItems: {
    type: ControlType.Group,
    title(props) {
      return (
        "Items — " + (props.chartSettings.chartLayout ? "X" : "Y") + " axis"
      );
    },
    propertyControl: {
      items: {
        type: ControlType.Array,
        title: "Values",
        propertyControl: {
          type(props) {
            return props.dimensions.items;
          },
          isNullsAllowed: true,
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },

  // Bars values
  dataSeries: {
    type: ControlType.Group,
    title(props) {
      return (
        "Bars — " + (props.chartSettings.chartLayout ? "Y" : "X") + " axis"
      );
    },
    propertyControl: {
      bars: {
        type: ControlType.Array,
        title: "Values",
        propertyControl: {
          type(props) {
            return props.dimensions.bars;
          },
          isNullsAllowed: true,
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },
});

export default Waterfall;
