import * as React from "react";
import { ControlType, applyPropertyControls } from "../ui/controltype";
import * as d3 from "d3";
import * as venn from "venn-modified";
import { Helmet } from "react-helmet";
import {
  presetLayout,
  presetTitle,
  presetCaption,
  presetLegend,
  presetTooltip,
} from "./propertyControlsPresets";
import { TextBlock } from "./textBlock";
import { CustomLegend } from "./customLegend";
import { nanoid } from "nanoid";
import { useDispatch } from "react-redux";
import { UPDATE_CHART_REF } from "../utils/actions";
import { useHeaderHeight } from "./customHooks";

export function Venn(props) {
  const {
    layoutSettings,
    chartSettings,
    labelsSettings,
    circles,
    intersections,
    title,
    caption,
    legend,
    tooltip,
    circlesValues,
    intersectionsValues,
  } = props;

  const { background, width } = layoutSettings;
  const { margin } = chartSettings;

  const dispatch = useDispatch();
  const [fontLoaded, setFontLoaded] = React.useState(false);

  const chartRef = React.useRef<HTMLDivElement>(null);
  const { headerRef, height } = useHeaderHeight(
    width,
    layoutSettings,
    caption,
    title,
    legend,
    circlesValues.items.map((elem) => elem.name).join("") +
      intersectionsValues.items.map((elem) => elem.name).join("")
  );

  // custom fonts loading status
  title["fontLoaded"] = fontLoaded;
  for (let i = 0; i < caption.items.length; i++) {
    caption.items[i]["fontLoaded"] = fontLoaded;
  }

  React.useEffect(() => {
    document.fonts.ready.then(() => {
      setFontLoaded(true);
    });
  });

  const padding = margin;
  const duration = 0;

  const circlesSets = circles.items.map((item, i) => {
    const id = circlesValues.items[i].id || "circle-" + i;
    return {
      sets: [id],
      size: circlesValues.items[i].value,
      color: item.color,
      label:
        circlesValues.items[i].name ||
        String.fromCharCode("A".charCodeAt(0) + i),
      labelColor: labelsSettings.circlesLabelsColor,
      labelValuesColor: labelsSettings.circlesLabelsValuesColor,
      // : item.color.replace(/(\d*\.?\d*)(?!.*\d)/, "1"), // replace alpha in "rgba(...) with "1"
    };
  });

  // Get all circles ids
  const circlesIds = circlesSets.map((elem) => elem.sets[0]);

  const intersectionsSets: any[] = [];

  for (let i = 0; i < intersections.items.length; i++) {
    let idArr = [
      intersectionsValues.items[i].circleIdFirst,
      intersectionsValues.items[i].circleIdSecond,
    ].filter((n) => n !== "" && n !== " "); // filter empty fields

    for (let j = 0; j < idArr.length; j++) {
      if (circlesIds.indexOf(idArr[j]) === -1) {
        // filter dublicates
        idArr.splice(j, 1);
      }
    }

    if (idArr.length > 1) {
      intersectionsSets.push({
        sets: idArr,
        size: intersectionsValues.items[i].value,
        color: intersections.items[i].color,
        label: intersectionsValues.items[i].name || "" + (i + 1),
        labelColor: labelsSettings.intersectionsLabelsColor,
        labelValuesColor: labelsSettings.intersectionsLabelsValuesColor,
      });
    }
  }

  // Dataset
  const sets = circlesSets.concat(intersectionsSets);
  const setsJSON = JSON.stringify(sets);

  const chart = venn
    .VennDiagram()
    .height(height)
    .width(width)
    .padding(padding)
    .duration(duration)
    .styled(true);

  const svgViewBox = [
    -chartSettings.xOffset,
    -chartSettings.yOffset,
    width,
    height,
  ];

  const labelsGap = 6; // gap between label and value

  function applyStyle(div) {
    const svg = div.select("#svgVenn");
    svg
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", svgViewBox)
      .style("background-color", background);

    // Assign color to cirlces and intersections
    div
      .selectAll(".venn-area path")
      .style("fill", (d) => d.color)
      .style("fill-opacity", 1);

    // Labels
    const labelsCircles = div.selectAll(".venn-circle .label");
    const labelsValuesCircles = div.selectAll(".venn-circle .labelValue");
    const labelsIntersections = div.selectAll(".venn-intersection .label");
    const labelsValuesIntersections = div.selectAll(
      ".venn-intersection .labelValue"
    );

    // Change labels fontSize
    labelsCircles.style("font-size", labelsSettings.circlesLabelsFontSize);
    labelsValuesCircles.style(
      "font-size",
      labelsSettings.circlesLabelsValuesFontSize
    );
    labelsIntersections.style(
      "font-size",
      labelsSettings.intersectionsLabelsFontSize
    );
    labelsValuesIntersections.style(
      "font-size",
      labelsSettings.intersectionsLabelsValuesFontSize
    );

    // If value labels is visible, move circles and intersections labels up
    if (labelsSettings.showCirclesValuesLabels) {
      labelsCircles
        .attr("dominant-baseline", "baseline")
        .attr("dy", 0)
        .select("tspan")
        .attr("dy", -labelsGap / 2);
    } else {
      labelsCircles
        .attr("dominant-baseline", "middle")
        .attr("dy", 0)
        .select("tspan")
        .attr("dy", 0);
    }
    if (labelsSettings.showIntersectionsValuesLabels) {
      labelsIntersections
        .attr("dominant-baseline", "baseline")
        .attr("dy", 0)
        .select("tspan")
        .attr("dy", -labelsGap / 2);
    } else {
      labelsIntersections
        .attr("dominant-baseline", "middle")
        .attr("dy", 0)
        .select("tspan")
        .attr("dy", 0);
    }

    // Assign labels color
    labelsCircles.style("fill", (d) => d.labelColor);
    labelsCircles.style("color", (d) => d.labelColor);
    labelsValuesCircles.style("fill", (d) => d.labelValuesColor);
    labelsValuesCircles.style("color", (d) => d.labelValuesColor);
    labelsIntersections.style("fill", (d) => d.labelColor);
    labelsIntersections.style("color", (d) => d.labelColor);
    labelsValuesIntersections.style("fill", (d) => d.labelValuesColor);
    labelsValuesIntersections.style("color", (d) => d.labelValuesColor);

    // By default VennDiagram draw all labels for circles.
    // If "labels" doesn't scpecified, it gets "names" as labels.
    // Intersection labels draws only if "label" specified.
    // So, if labels is off, make labels transparent.
    labelsCircles.attr(
      "opacity",
      labelsSettings.isVisible && labelsSettings.showCirclesLabels ? 1 : 0
    );
    labelsValuesCircles.attr(
      "opacity",
      labelsSettings.isVisible &&
        labelsSettings.showCirclesLabels &&
        labelsSettings.showCirclesValuesLabels
        ? 1
        : 0
    );
    labelsIntersections.attr(
      "opacity",
      labelsSettings.isVisible && labelsSettings.showIntersectionsLabels ? 1 : 0
    );
    labelsValuesIntersections.attr(
      "opacity",
      labelsSettings.isVisible &&
        labelsSettings.showIntersectionsLabels &&
        labelsSettings.showIntersectionsValuesLabels
        ? 1
        : 0
    );
  }

  React.useEffect(() => {
    var svgChart = d3.select("#svgChartContainer");

    svgChart.selectAll("g").remove();
    svgChart.datum(sets).call(chart);

    // Add value labels
    svgChart
      .selectAll(".venn-area text")
      .clone(true)
      .attr("class", "labelValue")
      .attr("dominant-baseline", "hanging")
      .attr("dy", 0)
      .select("tspan")
      .attr("dy", labelsGap / 2)
      .text((d) => d.size);

    // tooltip
    if (tooltip.isVisible) {
      const tooltipSelection = d3.select("#vennTooltip");

      // add listeners to all the groups to display tooltip on mouseover
      svgChart
        .selectAll(".venn-area")
        .on("mouseover", function (event, d) {
          // sort all the areas relative to the current item
          venn.sortAreas(svgChart, d);

          // Display a tooltip
          tooltipSelection.style("opacity", 1);
          tooltipSelection.select("p").text(d.label + ": " + d.size);
        })

        .on("mousemove", function (event) {
          tooltipSelection
            .transition()
            .duration(100)
            .style("left", event.pageX + 16 + "px")
            .style("top", event.pageY - 24 + "px");
        })

        .on("mouseout", function () {
          tooltipSelection.style("opacity", 0);
        });
    }
  }, [setsJSON, tooltip.isVisible, width, height, margin]);

  React.useEffect(() => {
    var div = d3.select(chartRef.current);
    applyStyle(div);
  });

  React.useEffect(() => {
    const element = chartRef.current;
    if (element) {
      const svg = element;
      dispatch(UPDATE_CHART_REF(svg));
    }
  }, [chartRef, dispatch]);

  return (
    <div ref={chartRef} style={{ background: background }}>
      <Helmet>
        <link
          rel="stylesheet"
          href={
            "https://fonts.googleapis.com/css?family=" +
            title.fontFamily.replace(/ /g, "+") +
            ":" +
            title.fontVariants
          }
          crossOrigin="anonymous"
        />
        {caption.items.map((elem, i) => {
          return (
            <link
              key={"caption-" + i}
              rel="stylesheet"
              href={
                "https://fonts.googleapis.com/css?family=" +
                elem.fontFamily.replace(/ /g, "+") +
                ":" +
                elem.fontVariants
              }
              crossOrigin="anonymous"
            />
          );
        })}
      </Helmet>
      <div ref={headerRef} style={{ width: width }}>
        {title.isVisible ? (
          <TextBlock key={"title"} title={title} fontLoaded={fontLoaded} />
        ) : (
          ""
        )}
        {caption.isVisible
          ? caption.items.map((elem, i) => {
              return (
                <TextBlock
                  key={"caption" + i}
                  title={elem}
                  fontLoaded={fontLoaded}
                />
              );
            })
          : ""}
        {legend.isVisible ? (
          <CustomLegend
            legendProps={legend}
            items={circlesSets
              .concat(legend.showIntersections ? intersectionsSets : [])
              .map((elem) => {
                return {
                  ...elem,
                  name: elem.label,
                  dotsType: "circle",
                  dotsColor: elem.color,
                  dotsStyle: true,
                };
              })}
          />
        ) : (
          ""
        )}
      </div>
      <svg id="svgVenn" className="chart-surface">
        <g id="svgChartContainer"></g>
      </svg>
      {tooltip.isVisible ? (
        <div
          id="vennTooltip"
          style={{
            position: "absolute",
            left: width / 2,
            top: height / 2,
            opacity: 0,
            border: "none",
            boxShadow: "0 1px 8px 1px rgba(0,0,0,0.1)",
            padding: "16px",
            textAlign: "left",
            backgroundColor: tooltip.bgColor,
          }}
        >
          <p
            style={{
              color: tooltip.txtColor,
              margin: "0",
              fontSize: "14px",
            }}
          ></p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

const cCount = 3;
const iCount = 3;

// clone circles id, which are random generated to intersections array
function cloneId(i, value) {
  if (i === 0) {
    Venn.defaultProps.intersectionsValues.items[0].circleIdFirst = value;
    Venn.defaultProps.intersectionsValues.items[2].circleIdFirst = value;
  }
  if (i === 1) {
    Venn.defaultProps.intersectionsValues.items[0].circleIdSecond = value;
    Venn.defaultProps.intersectionsValues.items[1].circleIdFirst = value;
  }
  if (i === 2) {
    Venn.defaultProps.intersectionsValues.items[1].circleIdSecond = value;
    Venn.defaultProps.intersectionsValues.items[2].circleIdSecond = value;
  }
}

Venn.defaultProps = {
  isData: false,
  layoutSettings: {
    isFullscreen: true,
    isWidthAuto: true,
    minWidth: 320,
    maxWidth: 2500,
    isHeightAuto: true,
    background: "rgba(255, 255, 255, 1)",
  },
  chartSettings: {
    xOffset: 0,
    yOffset: 0,
    margin: 100,
  },
  labelsSettings: {
    isVisible: true,
    showCirclesLabels: true,
    circlesLabelsFontSize: 14,
    circlesLabelsColor: "rgba(17, 153, 238, 1)",
    showCirclesValuesLabels: false,
    circlesLabelsValuesFontSize: 12,
    circlesLabelsValuesColor: "rgba(17, 153, 238, 1)",
    showIntersectionsLabels: false,
    intersectionsLabelsColor: "rgba(17, 153, 238, 1)",
    intersectionsLabelsFontSize: 14,
    showIntersectionsValuesLabels: false,
    intersectionsLabelsValuesColor: "rgba(17, 153, 238, 1)",
    intersectionsLabelsValuesFontSize: 12,
  },
  circles: {
    isVisible: true,
    items: [...Array(cCount)].map((_, i) => {
      return {
        color: "rgba(17, 153, 238, .25)",
      };
    }),
  },
  intersections: {
    isVisible: true,
    items: [...Array(iCount)].map((_, i) => {
      return { color: "rgba(17, 153, 238, 0)" };
    }),
  },
  title: {
    isVisible: false,
    text: "Chart title",
    txtColor: "rgba(0,0,0,1)",
    fontSize: 48,
    fontFamily: "Roboto",
    fontVariants: "regular",
    align: "center",
    margin: {
      margin: 0,
      isPerSide: true,
      top: 20,
      bottom: 20,
      left: 0,
      right: 0,
    },
  },
  caption: {
    isVisible: false,
    items: [
      {
        text: "Chart description or data source",
        txtColor: "rgba(0,0,0,.5)",
        fontSize: 18,
        fontFamily: "Roboto",
        fontVariants: "300",
        align: "center",
        margin: {
          margin: 0,
          isPerSide: true,
          top: 20,
          bottom: 20,
          left: 0,
          right: 0,
        },
      },
    ],
  },
  legend: {
    isVisible: false,
    txtColor: "rgba(0,0,0,1)",
    fontSize: 14,
    iconSize: 16,
    layout: true,
    align: "center",
    margin: {
      margin: 0,
      isPerSide: true,
      top: 20,
      bottom: 20,
      left: 0,
      right: 0,
    },
    showIntersections: false,
  },
  tooltip: {
    isVisible: false,
    txtColor: "rgba(0,0,0,1)",
    bgColor: "rgba(255,255,255,1)",
  },
  circlesValues: {
    isVisible: true,
    items: [...Array(cCount)].map((_, i) => {
      return {
        name: "",
        value: 8,
        get id() {
          const v = nanoid(10);
          cloneId(i, v); // fills intersectionsValues -> circlesIds array with circles ids
          return v;
        },
      };
    }),
  },
  intersectionsValues: {
    isVisible: true,
    items: [...Array(iCount)].map((_, i) => {
      return {
        name: "",
        value: 1,
        circleIdFirst: "",
        circleIdSecond: "",
      };
    }),
  },
};

applyPropertyControls(Venn, {
  //Settings or data
  isData: {
    type: ControlType.Boolean,
    title: "Edit",
    disabledTitle: "settings",
    enabledTitle: "data",
    wide: true,
  },

  layoutSettings: presetLayout,

  chartSettings: {
    type: ControlType.Group,
    title: "Chart settings",
    isHeaderControls: false,
    propertyControl: {
      xOffset: {
        type: ControlType.Number,
        title: "X offset",
        min: -100,
        maxx: 100,
      },
      yOffset: {
        type: ControlType.Number,
        title: "Y offset",
        min: -100,
        maxx: 100,
      },
      margin: {
        type: ControlType.Number,
        title: "Margin",
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  // Labels
  labelsSettings: {
    type: ControlType.Group,
    title: "Labels",
    isHeaderControls: true,
    propertyControl: {
      isVisible: {
        type: ControlType.Boolean,
        enabledTitle: "show",
        disabledTitle: "hide",
        hidden(props) {
          return true;
        },
      },
      showCirclesLabels: {
        type: ControlType.Boolean,
        title: "Circles",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      circlesLabelsColor: {
        title: "↳ Color",
        type: ControlType.Color,
        hidden(props) {
          return props.showCirclesLabels === false;
        },
      },
      circlesLabelsFontSize: {
        type: ControlType.Number,
        title: "↳ Font size",
        hidden(props) {
          return props.showCirclesLabels === false;
        },
      },
      showCirclesValuesLabels: {
        type: ControlType.Boolean,
        title: "↳ Values",
        enabledTitle: "show",
        disabledTitle: "hide",
        hidden(props) {
          return props.showCirclesLabels === false;
        },
      },
      circlesLabelsValuesColor: {
        title: " ↳ Color",
        type: ControlType.Color,
        hidden(props) {
          return (
            props.showCirclesLabels === false ||
            props.showCirclesValuesLabels === false
          );
        },
      },
      circlesLabelsValuesFontSize: {
        type: ControlType.Number,
        title: " ↳ Font size",
        hidden(props) {
          return (
            props.showCirclesLabels === false ||
            props.showCirclesValuesLabels === false
          );
        },
      },
      showIntersectionsLabels: {
        type: ControlType.Boolean,
        title: "Overlays",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      intersectionsLabelsColor: {
        title: "↳ Color",
        type: ControlType.Color,
        hidden(props) {
          return props.showIntersectionsLabels === false;
        },
      },
      intersectionsLabelsFontSize: {
        type: ControlType.Number,
        title: "↳ Font size",
        hidden(props) {
          return props.showIntersectionsLabels === false;
        },
      },
      showIntersectionsValuesLabels: {
        type: ControlType.Boolean,
        title: "↳ Values",
        enabledTitle: "show",
        disabledTitle: "hide",
        hidden(props) {
          return props.showIntersectionsLabels === false;
        },
      },
      intersectionsLabelsValuesColor: {
        title: " ↳ Color",
        type: ControlType.Color,
        hidden(props) {
          return (
            props.showIntersectionsLabels === false ||
            props.showIntersectionsValuesLabels === false
          );
        },
      },
      intersectionsLabelsValuesFontSize: {
        type: ControlType.Number,
        title: " ↳ Font size",
        hidden(props) {
          return (
            props.showIntersectionsLabels === false ||
            props.showIntersectionsValuesLabels === false
          );
        },
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  // Circles
  circles: {
    type: ControlType.Collection,
    title: "Circles",
    indexType: "letters",
    ref: "circlesValues",
    propertyControl: {
      color: {
        title: "Color",
        type: ControlType.Color,
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  // Intersections
  intersections: {
    type: ControlType.Collection,
    title: "Overlays",
    ref: "intersectionsValues",
    propertyControl: {
      color: {
        title: "Color",
        type: ControlType.Color,
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Title
  title: presetTitle,

  //Caption
  caption: presetCaption,

  //Legend
  // use controls preset, with custom "margin" propery
  legend: {
    ...presetLegend,
    propertyControl: {
      showIntersections: {
        type: ControlType.Boolean,
        title: "Overlays",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      ...presetLegend.propertyControl,
    },
  },

  //Tooltip
  tooltip: presetTooltip(false),

  // Circles Data
  circlesValues: {
    type: ControlType.Collection,
    title: "Circles",
    indexType: "letters",
    ref: "circles",
    propertyControl: {
      name: {
        type: ControlType.String,
        title: "Name",
        placeholder: "change default name",
      },
      value: {
        type: ControlType.Number,
        title: "Value",
      },
      id: {
        type: ControlType.Status,
        title: "Id",
        placeholder: "id for intersection",
        hidden(props) {
          return true;
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },

  // Intersections values
  intersectionsValues: {
    type: ControlType.Collection,
    title: "Overlays",
    ref: "intersections",
    propertyControl: {
      name: {
        type: ControlType.String,
        title: "Name",
        placeholder: "change default name",
      },
      value: {
        type: ControlType.Number,
        title: "Value",
      },
      circleIdFirst: {
        title: "Circle 1",
        type: ControlType.Enum,
        options(props) {
          const optionsArr = props.circlesValues.items.map((elem) => elem.id);
          return optionsArr;
        },
        optionTitles(props) {
          const titlesArr = props.circlesValues.items.map(
            (elem, i) => elem.name || String.fromCharCode("A".charCodeAt(0) + i)
          );
          return titlesArr;
        },
      },
      circleIdSecond: {
        title: "Circle 2",
        type: ControlType.Enum,
        options(props) {
          const optionsArr = props.circlesValues.items.map((elem) => elem.id);
          return optionsArr;
        },
        optionTitles(props) {
          const titlesArr = props.circlesValues.items.map(
            (elem, i) => elem.name || String.fromCharCode("A".charCodeAt(0) + i)
          );
          return titlesArr;
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },
});

export default Venn;
