import React from "react";
import { Typography } from "@mui/material";

export function ControlTitle(props) {
  const { title } = props;
  return (
    <div style={{ textAlign: "left" }}>
      <Typography style={{ fontSize: "0.8125rem" }} noWrap>
        {title}
      </Typography>
    </div>
  );
}

export default ControlTitle;
