import * as React from "react";

/**
 * Custom hook returns ref with callback hook and header height
 *
 * @see https://gist.github.com/thebuilder/fb07c989093d4a82811625de361884e7
 */
export function useHeaderHeight(
  width,
  layoutSettings,
  caption,
  title,
  legend,
  legendNames
) {
  const [height, setHeight] = React.useState(layoutSettings.height);

  const legendJSON = JSON.stringify(legend);

  // some aggregates to check if chart's height needs to be updated
  const captionCount = caption.items.length;
  const captionContent = caption.items.map((elem) => elem.text).join("");
  const captionFontsize = caption.items
    .map((elem) => elem.fontSize)
    .reduce((a, b) => a + b, 0);
  const captioFontFamily = caption.items
    .map((elem) => elem.fontFamily)
    .join("");
  const captioFontVariants = caption.items
    .map((elem) => elem.fontVariants)
    .join("");
  const captionMargins = caption.items
    .map((elem) => Object.entries(elem).join(""))
    .join("");

  const setRef = React.useCallback(
    (node) => {
      if (node !== null) {
        setHeight(layoutSettings.height - node.offsetHeight);
      }
    },
    [
      width,
      title.isVisible,
      title.text,
      title.fontSize,
      title.fontFamily,
      title.fontVariants,
      title.margin.isPerSide,
      title.margin.margin,
      title.margin.top,
      title.margin.right,
      title.margin.bottom,
      title.margin.left,
      caption.isVisible,
      captionCount,
      captionContent,
      captionFontsize,
      captioFontFamily,
      captioFontVariants,
      captionMargins,
      legendJSON,
      legendNames,
      layoutSettings.height,
    ]
  );

  return { headerRef: setRef, height: height };
}

export function useResizeDrawer({ minWidth, maxWidth, ref }) {
  const [isResizing, setIsResising] = React.useState(false);
  const [drawerWidth, setDrawerWidth] = React.useState(minWidth);

  const enableResize = React.useCallback(() => {
    const element = ref.current;
    if (element) {
      element.style.userSelect = "none";
    }
    setIsResising(true);
  }, [setIsResising]);

  const disableResize = React.useCallback(() => {
    const element = ref.current;
    if (element) {
      element.style.userSelect = "auto";
    }
    setIsResising(false);
  }, [setIsResising]);

  const resize = React.useCallback(
    (e: MouseEvent) => {
      if (isResizing) {
        let newWidth = minWidth;

        if (e.view) {
          if (e.view.window) {
            if (e.view.window.innerWidth) {
              newWidth = e.view.window.innerWidth - e.clientX;
            }
          }
        }

        if (newWidth >= minWidth && newWidth <= maxWidth) {
          setDrawerWidth(newWidth);
        }
      }
    },
    [minWidth, maxWidth, isResizing, setDrawerWidth]
  );

  React.useEffect(() => {
    document.addEventListener("mousemove", resize);
    document.addEventListener("mouseup", disableResize);

    return () => {
      document.removeEventListener("mousemove", resize);
      document.removeEventListener("mouseup", disableResize);
    };
  }, [disableResize, resize]);

  return { drawerWidth, enableResize };
}

// export default useHeaderHeight;
