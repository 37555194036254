import React from "react";
import { Grid, Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { OPEN_POPUP, UPDATE_FILE } from "../utils/actions";

export function InputFile(props) {
  const dispatch = useDispatch();

  const handleFileChange = (selectorFiles: FileList | null) => {
    if (selectorFiles) {
      const file = selectorFiles[0];
      const fileExtention = file.name.split(".").pop() || "";

      const fileType = file.type
        ? file.type === "application/vnd.ms-excel" ||
          file.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ? "exel"
          : file.type === "text/csv"
          ? "csv"
          : file.type === "text/tab-separated-values"
          ? "tsv"
          : "unknown"
        : fileExtention === "xls" || fileExtention === "xlsx"
        ? "exel"
        : fileExtention === "csv"
        ? "csv"
        : fileExtention === "tsv"
        ? "tsv"
        : "unknown";

      dispatch(UPDATE_FILE(file, fileType));
      dispatch(OPEN_POPUP({ content: "FilePreview" }));
    }
  };

  const clearInputValue = (event) => {
    event.target.value = "";
  };

  return (
    <>
      <Grid container alignItems="center">
        <input
          // accept="application/JSON, .csv, .tsv, .xls, .xlsx"
          accept=".xls, .xlsx"
          onClick={clearInputValue}
          onChange={(e) => handleFileChange(e.target.files)}
          id="contained-button-file"
          // multiple
          type="file"
          style={{ display: "none" }}
        />
        <label htmlFor="contained-button-file" style={{ width: "100%" }}>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            component="span"
            style={{
              width: "100%",
              textTransform: "none",
              border: "solid 1px rgba(0, 0, 0, .12)",
            }}
          >
            XLS(X) only
          </Button>
        </label>
      </Grid>
    </>
  );
}

export default InputFile;
