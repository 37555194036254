import React, { useEffect } from "react";
import { ControlType, applyPropertyControls } from "../ui/controltype";
import {
  AreaChart,
  Area as RArea,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  LabelList,
  Customized,
} from "recharts";
import { TextBlock } from "./textBlock";
import { CustomizedDot } from "./customDot";
import { CustomizedAxisTick } from "./customTick";
import { CustomizedTooltip } from "./customTooltip";
import { CustomLegend } from "./customLegend";
import { useDispatch } from "react-redux";
import { UPDATE_CHART_REF } from "../utils/actions";
import { Helmet } from "react-helmet";
import {
  presetLayout,
  presetXAxis,
  presetYAxis,
  presetGrid,
  presetDots,
  presetDotsLabels,
  presetLegend,
  presetTitle,
  presetCaption,
  presetTooltip,
  presetRefDots,
  presetRefLines,
  presetRefAreas,
} from "./propertyControlsPresets";
import {
  CustomizedDotLabel,
  CustomizedXAxisLabel,
  CustomizedYAxisLabel,
} from "./customLabel";
import {
  CustomizedRefDot,
  CustomizedRefLine,
  CustomizedRefArea,
} from "./customRef";
import {
  getDomains,
  normalizeDimensions,
  getValidDimensionDataArr,
  validateChartData,
  getTicks,
} from "../utils/utils";
import * as d3 from "d3";
import { useHeaderHeight } from "./customHooks";

// //Generate data
function streamIndex(d, i) {
  return Math.round(Math.max(0, d) * 100);
}

function getStreamValues(count) {
  let o = 0;
  function bump(a) {
    var x = 1 / (0.1 + Math.random()),
      y = 2 * Math.random() - 0.5,
      z = 10 / (0.1 + Math.random());
    for (var i = 0; i < count; i++) {
      var w = (i / count - y) * z;
      a[i] += x * Math.exp(-w * w);
    }
  }
  var a: number[] = [],
    i;
  for (i = 0; i < count; i++) a[i] = o + o * Math.random();
  for (i = 0; i < 5; i++) bump(a);
  return a.map(streamIndex);
}

const areasCount = 5;
const xCount = 26;
const dimensionsData = { x: "items", y: "areas" }; // link data with dimensions

export function Stream(props) {
  const {
    layoutSettings,
    chartSettings,
    dataItems,
    areas,
    dataSeries,
    xAxis,
    yAxis,
    grid,
    dimensions,
    legend,
    title,
    caption,
    tooltip,
    refDots,
    refLines,
    refAreas,
  } = props;

  const { background, width } = layoutSettings;
  const { margin } = chartSettings;

  const dataItemsJSON = JSON.stringify(dataItems.items);
  const dataSeriesJSON = JSON.stringify(dataSeries);

  const dispatch = useDispatch();

  const [data, setData] = React.useState([]);
  const [domain, setDomain] = React.useState({} as any);
  const [ticks, setTicks] = React.useState({} as any);
  const [dataTypes, setDataTypes] = React.useState({} as any);
  const [isData, setIsData] = React.useState(false);
  const [layout, setLayout] = React.useState(chartSettings.chartLayout);
  const [fontLoaded, setFontLoaded] = React.useState(false);

  const chartRef = React.useRef<HTMLDivElement>(null);
  const { headerRef, height } = useHeaderHeight(
    width,
    layoutSettings,
    caption,
    title,
    legend,
    areas.items.map((elem) => elem.name).join("")
  );

  // custom fonts loading status
  title["fontLoaded"] = fontLoaded;
  for (let i = 0; i < caption.items.length; i++) {
    caption.items[i]["fontLoaded"] = fontLoaded;
  }

  function buildData() {
    const itemsValid = getValidDimensionDataArr(
      dataItems.items,
      dimensions.items
    );

    const seriesValid = dataSeries.items.map((elem) =>
      getValidDimensionDataArr(elem.areas, dimensions.areas)
    );

    let chartData = [];
    if (seriesValid.length > 0) {
      chartData = itemsValid.map((elem, i) => {
        let values = { name: elem };
        for (let j = 0; j < seriesValid.length; j++) {
          let v = seriesValid[j][i];
          if (dimensions.areas === "number" && v === "") {
            v = NaN;
          }
          values[j] = v;
        }
        return values;
      });
    }

    return chartData;
  }

  useEffect(() => {
    document.fonts.ready.then(() => {
      setFontLoaded(true);
    });
  });

  let isNewData = false;

  useEffect(() => {
    const dataBuild = buildData();
    const chartDimensions = normalizeDimensions(dimensions);
    const chartDomain = getDomains({
      x: xAxis,
      y: yAxis,
      dimensions: chartDimensions,
      dimensionsData: dimensionsData,
      layout: chartSettings.chartLayout,
    });
    const chartTicks = getTicks(
      dataBuild,
      chartDimensions,
      chartDomain,
      xAxis,
      yAxis,
      dimensionsData,
      chartSettings.chartLayout,
      true,
      chartSettings.streamType
    );
    const isDataNotEmpty = validateChartData(dataBuild, areas.items.length);

    setData(dataBuild);
    setDomain(chartDomain);
    setDataTypes(chartDimensions);
    setTicks(chartTicks);
    setIsData(isDataNotEmpty);
    setLayout(chartSettings.chartLayout);

    isNewData = true;
  }, [
    dataItemsJSON,
    dataSeriesJSON,
    dimensions.X,
    dimensions.Y,
    chartSettings.chartLayout,
    chartSettings.streamType,
  ]);

  useEffect(() => {
    if (isData && !isNewData) {
      const chartDomain = getDomains({
        x: xAxis,
        y: yAxis,
        dimensions: dataTypes,
        dimensionsData: dimensionsData,
        layout: layout,
      });
      const chartTicks = getTicks(
        data,
        dataTypes,
        chartDomain,
        xAxis,
        yAxis,
        dimensionsData,
        layout,
        true,
        chartSettings.streamType
      );

      setDomain(chartDomain);
      setTicks(chartTicks);
    }
  }, [
    xAxis.domain,
    xAxis.domainMax,
    xAxis.domainMaxValue,
    xAxis.domainMin,
    xAxis.domainMinValue,
    yAxis.domain,
    yAxis.domainMax,
    yAxis.domainMaxValue,
    yAxis.domainMin,
    yAxis.domainMinValue,
  ]);

  useEffect(() => {
    if (isData && !isNewData) {
      const chartTicks = getTicks(
        data,
        dataTypes,
        domain,
        xAxis,
        yAxis,
        dimensionsData,
        layout,
        true,
        chartSettings.streamType
      );
      setTicks(chartTicks);
    }
  }, [
    xAxis.isTicksAuto,
    xAxis.tickCountValue,
    xAxis.tickCountAccuracy,
    xAxis.tickCountCategory,
    xAxis.tickCountValueCategory,
    yAxis.isTicksAuto,
    yAxis.tickCountValue,
    yAxis.tickCountAccuracy,
    yAxis.tickCountCategory,
    yAxis.tickCountValueCategory,
  ]);

  useEffect(() => {
    const element = chartRef.current;
    if (element) {
      const svg = element;
      dispatch(UPDATE_CHART_REF(svg));
    }
  }, [chartRef, dispatch]);

  return (
    <div ref={chartRef} style={{ background: background }}>
      <Helmet>
        <link
          rel="stylesheet"
          href={
            "https://fonts.googleapis.com/css?family=" +
            title.fontFamily.replace(/ /g, "+") +
            ":" +
            title.fontVariants
          }
          crossOrigin="anonymous"
        />
        {caption.items.map((elem, i) => {
          return (
            <link
              key={"caption-" + i}
              rel="stylesheet"
              href={
                "https://fonts.googleapis.com/css?family=" +
                elem.fontFamily.replace(/ /g, "+") +
                ":" +
                elem.fontVariants
              }
              crossOrigin="anonymous"
            />
          );
        })}
      </Helmet>
      <div ref={headerRef} style={{ width: width }}>
        {title.isVisible ? (
          <TextBlock key={"title"} title={title} fontLoaded={fontLoaded} />
        ) : (
          ""
        )}
        {caption.isVisible
          ? caption.items.map((elem, i) => {
              return (
                <TextBlock
                  key={"caption" + i}
                  title={elem}
                  fontLoaded={fontLoaded}
                />
              );
            })
          : ""}
        {legend.isVisible ? (
          <CustomLegend
            legendProps={legend}
            items={areas.items.map((elem) => {
              return {
                ...elem,
                dotsColor: elem.areaColor,
                dotsStyle: true,
              };
            })}
          />
        ) : (
          ""
        )}
      </div>
      {isData ? (
        <AreaChart
          width={width}
          height={height}
          data={data}
          layout={layout ? "horizontal" : "vertical"}
          stackOffset={chartSettings.streamType}
          margin={
            margin.isPerSide
              ? {
                  top: margin.top,
                  right: margin.right,
                  left: margin.left,
                  bottom: margin.bottom,
                }
              : {
                  top: margin.margin,
                  right: margin.margin,
                  left: margin.margin,
                  bottom: margin.margin,
                }
          }
          style={{
            background: background,
          }}
        >
          {refAreas.isVisible
            ? refAreas.items.map((elem, i) => (
                <Customized
                  key={"refArea-" + i}
                  component={
                    <CustomizedRefArea
                      cProps={{
                        elem: elem,
                        dataTypes: {
                          items: dataTypes.items,
                          series: dataTypes.areas,
                        },
                        existedValue: { x: data[0]["name"], y: data[0]["0"] },
                        layout: layout,
                        xAxisSettings: xAxis,
                        yAxisSettings: yAxis,
                        marginSettings: margin,
                        width: width,
                        height: height,
                      }}
                    />
                  }
                />
              ))
            : null}
          <CartesianGrid
            horizontal={grid.gridHoriz}
            vertical={grid.gridVert}
            stroke={grid.gridColor}
            syncWithTicks={true}
          />
          {tooltip.isVisible ? (
            <Tooltip
              separator={tooltip.separator}
              cursor={
                tooltip.showCursor
                  ? { strokeWidth: 1, stroke: tooltip.cursorColor }
                  : false
              }
              content={
                <CustomizedTooltip
                  txtColor={tooltip.txtColor}
                  background={tooltip.bgColor}
                  chartType={"area"}
                  layout={layout}
                  units={{ x: xAxis.units, y: yAxis.units }}
                  axesDataTypes={{
                    x: layout ? dataTypes.items : dataTypes.areas,
                    y: layout ? dataTypes.areas : dataTypes.items,
                  }}
                  axesLabels={{ x: xAxis.labelText, y: yAxis.labelText }}
                  useAxesLabels={tooltip.useAxesLabels}
                  useLocales={{
                    x: xAxis.useLocale,
                    y: yAxis.useLocale,
                  }}
                />
              }
            />
          ) : (
            ""
          )}
          <XAxis
            dataKey={layout ? "name" : undefined}
            type={layout ? dataTypes.items : dataTypes.areas}
            axisLine={xAxis.isAxisLine}
            ticks={ticks.x}
            interval={0}
            reversed={xAxis.tickOrder}
            hide={!xAxis.isVisible}
            height={xAxis.height}
            domain={domain.X}
            stroke={xAxis.color}
            orientation={xAxis.orient}
            // allowDuplicatedCategory={false}
            // allowDataOverflow={true}
            tickSize={xAxis.tickSize}
            tickMargin={xAxis.tickMargin}
            tick={
              ticks.x.length == 0 ? (
                false
              ) : (
                <CustomizedAxisTick
                  fontSize={xAxis.tickFontSize}
                  axis={"x"}
                  units={xAxis.units ? xAxis.units : ""}
                  useLocale={xAxis.useLocale}
                  type={layout ? dataTypes.items : dataTypes.areas}
                  tickAlign={xAxis.orient}
                  angle={xAxis.tickRotate}
                />
              )
            }
            label={
              xAxis.isLabelVisible ? (
                <CustomizedXAxisLabel
                  value={xAxis.labelText}
                  fontSize={xAxis.labelFontSize}
                  color={xAxis.labelColor}
                  align={xAxis.labelAlign}
                  margin={xAxis.labelMargin}
                  axisOrient={xAxis.orient}
                />
              ) : (
                ""
              )
            }
          />
          <YAxis
            dataKey={layout ? undefined : "name"}
            type={layout ? dataTypes.areas : dataTypes.items}
            axisLine={yAxis.isAxisLine}
            ticks={ticks.y}
            interval={0}
            reversed={yAxis.tickOrder}
            hide={!yAxis.isVisible}
            width={yAxis.width}
            domain={domain.Y}
            stroke={yAxis.color}
            orientation={yAxis.orient}
            // allowDuplicatedCategory={false}
            tickSize={yAxis.tickSize}
            tickMargin={yAxis.tickMargin}
            tick={
              ticks.y.length == 0 ? (
                false
              ) : (
                <CustomizedAxisTick
                  fontSize={yAxis.tickFontSize}
                  axis={"y"}
                  units={yAxis.units ? yAxis.units : ""}
                  useLocale={yAxis.useLocale}
                  type={layout ? dataTypes.areas : dataTypes.items}
                  tickAlign={yAxis.orient}
                />
              )
            }
            label={
              yAxis.isLabelVisible ? (
                <CustomizedYAxisLabel
                  value={yAxis.labelText}
                  fontSize={yAxis.labelFontSize}
                  color={yAxis.labelColor}
                  align={yAxis.labelAlign}
                  margin={yAxis.labelMargin}
                  axisOrient={yAxis.orient}
                  rotateAngle={yAxis.labelRotate}
                />
              ) : (
                ""
              )
            }
          />
          {areas.items.map((area, i) => {
            const {
              areaColor,
              showLine,
              lineColor,
              lineStrokeWidth,
              showDots,
              dotsType,
              dotsStyle,
              dotsColor,
              dotsSize,
              dotsStrokeWidth,
              isDashed,
              dashes,
              spaces,
              name,
              showLabels,
              labelsColor,
              labelsFontSize,
              labelsPosition,
            } = area;
            const srokeDash = isDashed ? dashes + " " + spaces : "";
            return (
              <RArea
                name={name ? name : i + 1}
                key={"area-" + i}
                stackId="a"
                type={
                  chartSettings.areaType === "bump"
                    ? layout
                      ? d3.curveBumpX
                      : d3.curveBumpY
                    : chartSettings.areaType
                }
                dataKey={i}
                fill={areaColor}
                fillOpacity={1}
                strokeDasharray={srokeDash}
                stroke={showLine ? lineColor : "none"}
                strokeWidth={lineStrokeWidth}
                dot={
                  showDots ? (
                    <CustomizedDot
                      dotsType={dotsType}
                      dotsStyle={dotsStyle}
                      dotsColor={dotsColor}
                      dotsSize={dotsSize}
                      dotsStrokeWidth={dotsStrokeWidth}
                    />
                  ) : (
                    false
                  )
                }
                isAnimationActive={false} // turn off animation because of dot's bug - https://github.com/recharts/recharts/issues/1426
                // animationDuration={500}
              >
                {showLabels ? (
                  <LabelList
                    dataKey={i}
                    content={(d) => {
                      return (
                        <CustomizedDotLabel
                          viewBox={d.viewBox}
                          value={d.value}
                          position={labelsPosition}
                          fontSize={labelsFontSize}
                          fill={labelsColor}
                          dotsType={dotsType}
                          dotsStrokeWidth={dotsStrokeWidth}
                          dotSize={
                            dotsType === "tick"
                              ? dotsStrokeWidth
                              : dotsStyle
                              ? dotsSize
                              : dotsSize + dotsStrokeWidth
                          }
                        />
                      );
                    }}
                  />
                ) : null}
              </RArea>
            );
          })}
          {refLines.isVisible
            ? refLines.items.map((elem, i) => (
                <Customized
                  key={"refLine-" + i}
                  component={
                    <CustomizedRefLine
                      cProps={{
                        elem: elem,
                        dataTypes: {
                          items: dataTypes.items,
                          series: dataTypes.areas,
                        },
                        existedValue: { x: data[0]["name"], y: data[0]["0"] },
                        layout: layout,
                        xAxisSettings: xAxis,
                        yAxisSettings: yAxis,
                        marginSettings: margin,
                        width: width,
                        height: height,
                      }}
                    />
                  }
                />
              ))
            : null}
          {refDots.isVisible
            ? refDots.items.map((elem, i) => (
                <Customized
                  key={"refDot-" + i}
                  component={
                    <CustomizedRefDot
                      cProps={{
                        elem: elem,
                        dataTypes: {
                          items: dataTypes.items,
                          series: dataTypes.areas,
                        },
                        existedValue: { x: data[0]["name"], y: data[0]["0"] },
                        layout: layout,
                        xAxisSettings: xAxis,
                        yAxisSettings: yAxis,
                        marginSettings: margin,
                        width: width,
                        height: height,
                      }}
                    />
                  }
                />
              ))
            : null}
        </AreaChart>
      ) : (
        <span style={{ opacity: 0.25 }}>Not enough data to chart</span>
      )}
    </div>
  );
}

const defaultDimensionsTypes = {
  items: ControlType.String,
  areas: ControlType.Number,
};

Stream.defaultProps = {
  isData: false,
  layoutSettings: {
    isFullscreen: true,
    isWidthAuto: true,
    minWidth: 320,
    maxWidth: 2500,
    isHeightAuto: true,
    background: "rgba(255, 255, 255, 1)",
  },
  chartSettings: {
    streamType: "silhouette",
    chartLayout: true,
    areaType: "basis",
    margin: {
      margin: 0,
      isPerSide: false,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  sourceData: true,
  fileData: null,
  xAxis: {
    isVisible: false,
    isLabelVisible: true,
    labelText: "X label",
    labelAlign: "middle",
    labelMargin: -20,
    color: "rgba(17, 153, 238, 1)",
    labelFontSize: 12,
    labelColor: "rgba(17, 153, 238, 1)",
    height: 60,
    orient: "bottom",
    isAxisLine: true,
    domain: true,
    domainMin: "dataMin",
    domainMinValue: 0,
    domainMax: "dataMax",
    domainMaxValue: 1,
    tickFontSize: 12,
    isTicksAuto: true,
    tickCountValue: 5,
    tickCountAccuracy: false,
    tickCountCategory: true,
    tickCountValueCategory: 5,
    tickRotate: 0,
    tickSize: 6,
    tickMargin: 4,
    tickOrder: false,
    units: "",
    useLocale: true,
  },
  yAxis: {
    isVisible: false,
    isLabelVisible: true,
    labelText: "Y label",
    labelAlign: "middle",
    labelMargin: -20,
    color: "rgba(17, 153, 238, 1)",
    labelFontSize: 12,
    labelRotate: -90,
    labelColor: "rgba(17, 153, 238, 1)",
    width: 60,
    orient: "left",
    isAxisLine: true,
    domain: true,
    domainMin: "dataMin",
    domainMinValue: 0,
    domainMax: "dataMax",
    domainMaxValue: 1,
    tickFontSize: 12,
    isTicksAuto: true,
    tickCountValue: 5,
    tickCountAccuracy: false,
    tickCountCategory: true,
    tickCountValueCategory: 5,
    tickSize: 6,
    tickMargin: 4,
    tickOrder: false,
    units: "",
    useLocale: true,
  },
  grid: {
    gridHoriz: false,
    gridVert: false,
    gridColor: "rgba(17, 153, 238, .5)",
  },
  areas: {
    isVisible: true,
    items: [...Array(areasCount)].map((item, i) => {
      const opacity = (100 - i * 18) / 100;
      return {
        name: "",
        areaColor: "rgba(17, 153, 238," + opacity + ")",
        showLine: false,
        lineStrokeWidth: 1,
        lineColor: "rgba(17, 153, 238, 1)",
        isDashed: false,
        dashes: 4,
        spaces: 4,
        showDots: false,
        dotsType: "circle",
        dotsSize: 4,
        dotsColor: "rgba(17, 153, 238, 1)",
        dotsStyle: true,
        dotsStrokeWidth: 1,
        showLabels: false,
        labelsColor: "rgba(17, 153, 238, 1)",
        labelsFontSize: 12,
        labelsPosition: "R",
      };
    }),
  },
  dataItems: {
    items: [...Array(xCount)].map((_, i) =>
      String.fromCharCode("A".charCodeAt(0) + i)
    ),
  },
  dataSeries: {
    isVisible: true,
    items: [...Array(areasCount)].map((item, i) => {
      return {
        get areas() {
          return getStreamValues(xCount);
        },
      };
    }),
  },
  dimensions: defaultDimensionsTypes,
  legend: {
    isVisible: false,
    txtColor: "rgba(0,0,0,1)",
    fontSize: 14,
    iconSize: 16,
    layout: true,
    align: "center",
    margin: {
      margin: 0,
      isPerSide: true,
      top: 20,
      bottom: 20,
      left: 0,
      right: 0,
    },
  },
  title: {
    isVisible: false,
    text: "Chart title",
    txtColor: "rgba(0,0,0,1)",
    fontSize: 48,
    fontFamily: "Roboto",
    fontVariants: "regular",
    align: "center",
    margin: {
      margin: 0,
      isPerSide: true,
      top: 20,
      bottom: 20,
      left: 0,
      right: 0,
    },
  },
  refDots: {
    isVisible: false,
    items: [
      {
        xCoordinateNumber: null,
        xCoordinateCategory: "",
        yCoordinateNumber: null,
        yCoordinateCategory: "",
        dotSize: 16,
        dotColor: "rgba(238, 68, 69, 1)",
        dotStyle: true,
        dotStrokeWidth: 1,
        showLabel: false,
        name: "",
        labelColor: "rgba(0, 0, 0, 1)",
        labelFontSize: 12,
        labelPosition: "R",
        labelOffsetX: 0,
        labelOffsetY: 0,
        labelWrap: true,
        labelWidth: 20,
        showLabelLine: true,
        labelLineColor: "rgba(0, 0, 0, 1)",
        showLabelImage: false,
        labelImgUrl: "",
        labelImgPosition: false,
        labelImgCustomSize: false,
        labelImgWidth: 200,
      },
    ],
  },
  refLines: {
    isVisible: false,
    items: [
      {
        orient: true,
        xCoordinateNumber: null,
        xCoordinateCategory: "",
        yCoordinateNumber: null,
        yCoordinateCategory: "",
        lineColor: "rgba(238, 68, 69, 1)",
        lineStrokeWidth: 1,
        lineStyle: "Solid",
        showLabel: false,
        name: "",
        labelColor: "rgba(0, 0, 0, 1)",
        labelFontSize: 12,
        labelPosition: "TC",
        labelOffsetX: 0,
        labelOffsetY: 0,
        labelWrap: true,
        labelWidth: 20,
        labelPositionHoriz: "center",
        labelPositionVertOrient: false,
        labelPositionVert: "center",
        labelPositionVertAlign: "center",
      },
    ],
  },
  refAreas: {
    isVisible: false,
    items: [
      {
        x1CoordinateNumber: null,
        x2CoordinateNumber: null,
        x1CoordinateCategory: "",
        x2CoordinateCategory: "",
        y1CoordinateNumber: null,
        y2CoordinateNumber: null,
        y1CoordinateCategory: "",
        y2CoordinateCategory: "",
        fillColor: "rgba(238, 68, 69, .2)",
        strokeColor: "rgba(0, 0, 0, 1)",
        strokeWidth: 0,
        strokeStyle: "Solid",
      },
    ],
  },
  caption: {
    isVisible: false,
    items: [
      {
        text: "Chart description or data source",
        txtColor: "rgba(0,0,0,.5)",
        fontSize: 18,
        fontFamily: "Roboto",
        fontVariants: "300",
        align: "center",
        margin: {
          margin: 0,
          isPerSide: true,
          top: 20,
          bottom: 20,
          left: 0,
          right: 0,
        },
      },
    ],
  },
  tooltip: {
    isVisible: false,
    txtColor: "rgba(0,0,0,1)",
    bgColor: "rgba(255,255,255,1)",
    separator: ": ",
    useAxesLabels: false,
    showCursor: true,
    cursorColor: "rgba(17, 153, 238, 1)",
  },
};

applyPropertyControls(Stream, {
  //Settings or data
  isData: {
    type: ControlType.Boolean,
    title: "Change",
    disabledTitle: "settings",
    enabledTitle: "data",
    wide: true,
  },

  layoutSettings: presetLayout,

  chartSettings: {
    type: ControlType.Group,
    title: "Chart settings",
    isHeaderControls: false,
    propertyControl: {
      chartLayout: {
        type: ControlType.Boolean,
        title: "Chart layout",
        enabledTitle: "horizontal",
        disabledTitle: "vertical",
      },
      streamType: {
        type: ControlType.Enum,
        title: "Type",
        options: ["silhouette", "wiggle", "expand", "none"],
      },
      areaType: {
        type: ControlType.Enum,
        title: "Line type",
        options: [
          "basis",
          "bump",
          "monotone",
          "basicClosed",
          "basicOpen",
          "linear",
          "linearClosed",
          "natural",
          "monotoneX",
          "monotoneY",
          "step",
          "stepBefore",
          "stepAfter",
        ],
      },
      margin: {
        type: ControlType.FusedNumber,
        title: "Margin",
        toggleKey: "isPerSide",
        toggleTitles: ["All", "Individual"],
        valueKeys: ["top", "right", "bottom", "left"],
        valueLabels: ["T", "R", "B", "L"],
        min: 0,
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Axes
  xAxis: presetXAxis(dimensionsData, true), // link data with axes
  yAxis: presetYAxis(dimensionsData, true),

  //Grid
  grid: presetGrid,

  //Areas
  areas: {
    type: ControlType.Collection,
    title: "Areas",
    ref: "dataSeries",
    propertyControl: {
      name: {
        title: "Name",
        type: ControlType.String,
        placeholder: "name for legend",
      },
      areaColor: {
        title: "Area color",
        type: ControlType.Color,
      },

      //Line
      showLine: {
        type: ControlType.Boolean,
        title: "Line",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      lineStrokeWidth: {
        type: ControlType.Number,
        title: "↳ Line stroke",
        min: 0,
        max: 100,
        displayStepper: false,
        hidden(props) {
          return props.showLine === false;
        },
      },
      lineColor: {
        title: "↳ Line color",
        type: ControlType.Color,
        hidden(props) {
          return props.showLine === false;
        },
      },
      isDashed: {
        type: ControlType.Boolean,
        title: "↳ Dashed",
        enabledTitle: "yes",
        disabledTitle: "no",
        hidden(props) {
          return props.showLine === false;
        },
      },
      dashes: {
        type: ControlType.Number,
        title: "↳ Dashes",
        min: 1,
        max: 100,
        displayStepper: true,
        hidden(props) {
          return props.showLine === false || props.isDashed === false;
        },
      },
      spaces: {
        type: ControlType.Number,
        title: "↳ Spaces",
        min: 1,
        max: 100,
        displayStepper: true,
        hidden(props) {
          return props.showLine === false || props.isDashed === false;
        },
      },

      // Dots
      ...presetDots,

      // Dot's labels
      ...presetDotsLabels,
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Reference dots
  refDots: presetRefDots(dimensionsData, true),

  //Reference lines
  refLines: presetRefLines(dimensionsData, true),

  //Reference areas
  refAreas: presetRefAreas(dimensionsData, true),

  //Title
  title: presetTitle,

  //Caption
  caption: presetCaption,

  //Legend
  legend: presetLegend,

  //Tooltip
  tooltip: {
    ...presetTooltip(true),
    propertyControl: {
      separator: {
        type: ControlType.String,
        title: "Separator",
      },
      useAxesLabels: {
        type: ControlType.Boolean,
        title: "Axes labels",
        enabledTitle: "use",
        disabledTitle: "ignore",
      },
      ...presetTooltip(true).propertyControl,
    },
  },

  //Data
  sourceData: {
    type: ControlType.Boolean,
    title: "Data",
    enabledTitle: "editable",
    disabledTitle: "file",
    hidden(props) {
      return props.isData === false;
    },
  },
  fileData: {
    title: "↳ File",
    type: ControlType.File,
    allowedFileTypes: ["xls"],
    hidden(props) {
      return props.sourceData === true || props.isData === false;
    },
  },

  // Data types
  dimensions: {
    type: ControlType.Group,
    title: "Axes data types",
    propertyControl: {
      items: {
        type: ControlType.Enum,
        seriesType: false,
        title(props, ui) {
          return ui.chartSettings.chartLayout ? "X" : "Y";
        },
        path: "dataItems", // path to data
        optionTitles: ["number", "category"],
        options: [ControlType.Number, ControlType.String],
        displaySegmentedControl: true,
      },
      areas: {
        type: ControlType.Enum,
        seriesType: true,
        title(props, ui) {
          return ui.chartSettings.chartLayout ? "Y" : "X";
        },
        path: "dataSeries", // path to data
        optionTitles: ["number"],
        options: [ControlType.Number],
        displaySegmentedControl: true,
        hidden(props) {
          return true;
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },

  // Items data (usually - x axis)
  dataItems: {
    type: ControlType.Group,
    title(props) {
      return (
        "Items — " + (props.chartSettings.chartLayout ? "X" : "Y") + " axis"
      );
    },
    propertyControl: {
      items: {
        type: ControlType.Array,
        title: "Values",
        propertyControl: {
          type(props) {
            return props.dimensions.items;
          },
          isNullsAllowed: true,
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },

  //Areas Data
  dataSeries: {
    type: ControlType.Collection,
    title(props) {
      return (
        "Areas — " + (props.chartSettings.chartLayout ? "Y" : "X") + " axis"
      );
    },
    ref: "areas",
    propertyControl: {
      areas: {
        type: ControlType.Array,
        title: "Values",
        propertyControl: {
          type(props) {
            return props.dimensions.areas;
          },
          isNullsAllowed: true,
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },
});

export default Stream;
