import React from "react";
import {
  TextField,
  Grid,
  Typography,
  InputAdornment,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import CropDinIcon from "@mui/icons-material/CropDinSharp";
import CropFreeIcon from "@mui/icons-material/CropFreeSharp";
import { useSelector, useDispatch } from "react-redux";
import { UPDATE_CHART_SETTINGS } from "../utils/actions";
import { getObjValueByPath } from "../utils/utils";
import "../../App.css";

export function InputFusedNumber(props) {
  const { path, inputSettings } = props;
  const pathArr = path.split(".");
  const controlName = pathArr[pathArr.length - 1];

  const toggle = inputSettings.toggleKey;
  const mixedKeys = inputSettings.valueKeys;
  const mixedLabels = inputSettings.valueLabels;
  const chartUI = useSelector((state: any) => state.chart.chartUI);
  const settingsObj = getObjValueByPath(chartUI, path);
  const settingsValue = settingsObj[controlName];
  const isMixed = settingsObj[toggle] || false;

  const dispatch = useDispatch();

  const MIN = inputSettings.min || 0;
  const MAX = inputSettings.max || 100;
  const UNIT = inputSettings.unit;
  const STEP = inputSettings.step || 1;
  const VALUE = settingsValue;

  const mixedValues: Array<number> = [
    settingsObj[mixedKeys[0]] || 0,
    settingsObj[mixedKeys[1]] || 0,
    settingsObj[mixedKeys[2]] || 0,
    settingsObj[mixedKeys[3]] || 0,
  ];

  const mixedValuesJSON = JSON.stringify(mixedValues);

  const [inputValue, updateInputValue] = React.useState(VALUE);
  const [mixedInputValue, updateMixedInputValue] = React.useState(mixedValues);

  //update local state while using keyboard
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value;
    updateInputValue(value);
  };

  //keyboard input update global state after unfocus
  const handleBlur = (event) => {
    let value = Number(event.target.value);
    if (!value || value < MIN) {
      value = MIN;
    }
    // if (value > MAX) {
    //   value = MAX;
    // }

    //if value after validation are equal to global state value, then we updating
    //local state, else - we updating global state
    if (value === VALUE) {
      updateInputValue(value);
    } else {
      dispatch(
        UPDATE_CHART_SETTINGS(path, { ...settingsObj, [controlName]: value })
      );
    }
  };

  //change mixed toggle
  function handleMixedFalse() {
    dispatch(UPDATE_CHART_SETTINGS(path, { ...settingsObj, [toggle]: false }));
  }
  function handleMixedTrue() {
    dispatch(UPDATE_CHART_SETTINGS(path, { ...settingsObj, [toggle]: true }));
  }

  //change mixed value
  function handleChangeMixed(event, item, i) {
    const value = event.target.value as number;
    updateMixedInputValue(
      mixedInputValue.map((mixedItem, j) => (i === j ? value : mixedItem))
    );
  }
  //keyboard input update global state mixed values after unfocus
  function handleBlurMixed(event, item, i) {
    let value = Number(event.target.value);
    if (!value || value < MIN) {
      value = MIN;
    }
    // if (value > MAX) {
    //   value = MAX;
    // }

    //if value after validation are equal to global state value, then we updating
    //local state, else - we updating global state
    if (value === mixedValues[i]) {
      updateMixedInputValue(
        mixedInputValue.map((mixedItem, j) => (i === j ? value : mixedItem))
      );
    } else {
      dispatch(UPDATE_CHART_SETTINGS(path, { ...settingsObj, [item]: value }));
    }
  }

  //update state if new VALUE came
  //if we leave input value = "", it will get "0" after update
  React.useEffect(() => {
    updateInputValue(VALUE);
  }, [VALUE]);

  //update state if new mixedValues came
  React.useEffect(() => {
    updateMixedInputValue(mixedValues);
  }, [mixedValuesJSON]);

  const renderInput = (
    <TextField
      type="number"
      variant="standard"
      size="small"
      value={isMixed ? "" : inputValue}
      inputProps={{
        min: MIN,
        // max: MAX,
        step: STEP,
        style: { padding: 0, fontSize: "0.8125rem" },
      }}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={(event) => {
        if (isMixed) {
          handleMixedFalse();
        }
        event.target.select();
      }}
      onKeyUp={(event) => {
        if (event.key === "Enter") {
          handleBlur(event);
        }
      }}
      style={{ width: "100%" }}
      InputProps={{
        endAdornment: UNIT ? (
          <InputAdornment position="end">
            <Typography style={{ fontSize: "0.8125rem", color: "#999999" }}>
              {UNIT}
            </Typography>
          </InputAdornment>
        ) : null,
        style: { paddingRight: 0 },
      }}
    />
  );

  const renderToggle = (
    <ToggleButtonGroup size="small" style={{ width: "100%" }}>
      <ToggleButton
        disabled={!isMixed}
        style={{
          padding: 0,
          width: "50%",
          color: !isMixed ? "#ffffff" : "#333333",
          backgroundColor: !isMixed ? "#333333" : "",
        }}
        color="primary"
        onClick={handleMixedFalse}
        value="Single"
      >
        <CropDinIcon
          fontSize="small"
          style={{ color: !isMixed ? "#ffffff" : "#333333" }}
        />
      </ToggleButton>
      <ToggleButton
        disabled={isMixed}
        style={{
          padding: 0,
          width: "50%",
          color: isMixed ? "#ffffff" : "#333333",
          backgroundColor: isMixed ? "#333333" : "",
        }}
        color="primary"
        onClick={handleMixedTrue}
        value="Distinct"
      >
        <CropFreeIcon
          fontSize="small"
          style={{ color: isMixed ? "#ffffff" : "#333333" }}
        />
      </ToggleButton>
    </ToggleButtonGroup>
  );

  const renderMixedInputs = mixedKeys.map(function (item, i) {
    return (
      <div style={{ width: "100%" }} key={i}>
        <TextField
          type="number"
          variant="standard"
          size="small"
          value={mixedInputValue[i]}
          inputProps={{
            min: MIN,
            max: MAX,
            step: STEP,
            style: { padding: 0, fontSize: "0.8125rem" },
          }}
          onChange={(event) => handleChangeMixed(event, item, i)}
          onBlur={(event) => handleBlurMixed(event, item, i)}
          onFocus={(event) => {
            event.target.select();
          }}
          onKeyUp={(event) => {
            if (event.key === "Enter") {
              handleBlurMixed(event, item, i);
            }
          }}
          style={{ width: "100%" }}
          helperText={mixedLabels[i]}
        />
      </div>
    );
  });

  return (
    <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
      <div style={{ display: "flex", columnGap: 16, flexGrow: 1 }}>
        <div style={{ width: "50%" }}>{renderInput}</div>
        <div style={{ width: "50%" }}>{renderToggle}</div>
      </div>
      {isMixed ? (
        <div
          style={{
            display: "flex",
            justifyContent: "stretch",
            columnGap: 8,
            marginTop: 4,
            flexGrow: 1,
          }}
        >
          {renderMixedInputs}
        </div>
      ) : null}
    </div>
  );
}

export default InputFusedNumber;
