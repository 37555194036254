import * as React from "react";
import { FONTFAMILY_DEFAULT } from "../utils/utils";

export const TextBlock = (props) => {
  const { title, fontLoaded } = props;
  const { margin } = title;

  return (
    <div
      style={{
        overflowWrap: "break-word",
        fontSize: title.fontSize + "px",
        fontFamily: fontLoaded ? title.fontFamily : FONTFAMILY_DEFAULT,
        fontStyle: title.fontVariants.includes("italic") ? "italic" : "normal",
        fontWeight: title.fontVariants.includes("italic")
          ? title.fontVariants.slice(title.fontVariants.indexOf("italic"))
          : title.fontVariants,
        color: title.txtColor,
        textAlign: title.align,
        padding: margin.isPerSide
          ? margin.top +
            "px " +
            margin.right +
            "px " +
            margin.bottom +
            "px " +
            margin.left +
            "px"
          : margin.margin + "px",
      }}
    >
      {title.text}
    </div>
  );
};

export default TextBlock;
