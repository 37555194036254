import Area from "../charts/area";
import AreaStacked from "./areaStacked";
import Bars from "../charts/bars";
import BarsGroups from "../charts/barsGroups";
import BarsStacked from "../charts/barsStacked";
import Bullet from "../charts/bullet";
import Line from "./line";
import Lollipops from "../charts/lollipops";
import ProgressCircle from "../charts/progressCircle";
import Radar from "../charts/radar";
import Range from "../charts/range";
import Scatter from "../charts/scatter";
import Stream from "../charts/stream";
import Venn from "../charts/venn";
import Waterfall from "../charts/waterfall";
import HierarchyForceDirected from "./hierarchyForceDirected";
import HierarchyTree from "../charts/hierarchyTree";
import HierarchyTreeRadial from "../charts/hierarchyTreeRadial";
import HierarchyTreemap from "./hierarchyTreemap";
import HierarchyTreemapNested from "./hierarchyTreemapNested";
// import HierarchyTreemapVoronoi from "./hierarchyTreemapVoronoi";
import HierarchySunburst from "./hierarchySunburst";
import Heatmap from "./heatmap";
import Sankey from "./sankey";
// import Test from "./test";
//----------------------------------
// examples
import exampleScatter from "./exampleScatter";
import exampleSunburst from "./exampleSunburst";
import exampleHeatmap from "./exampleHeatmap";

export const Charts = {
  //----------------------------------
  Area: {
    type: "Area",
    title: "Area",
    component: Area,
    group: "Line & Area",
  },
  AreaStacked: {
    type: "AreaStacked",
    title: "Area stacked",
    component: AreaStacked,
    group: "Line & Area",
  },
  Line: {
    type: "Line",
    title: "Line",
    component: Line,
    group: "Line & Area",
  },
  Stream: {
    type: "Stream",
    title: "Stream",
    component: Stream,
    group: "Line & Area",
  },
  //----------------------------------
  Bars: {
    type: "Bars",
    title: "Bars",
    component: Bars,
    group: "Bars",
  },
  BarsGroups: {
    type: "BarsGroups",
    title: "Bars groups",
    component: BarsGroups,
    group: "Bars",
  },
  BarsStacked: {
    type: "BarsStacked",
    title: "Bars stacked",
    component: BarsStacked,
    group: "Bars",
  },
  Bullet: {
    type: "Bullet",
    title: "Bullet",
    component: Bullet,
    group: "Bars",
  },
  Waterfall: {
    type: "Waterfall",
    title: "Waterfall",
    component: Waterfall,
    group: "Bars",
  },
  //----------------------------------
  HierarchyForceDirected: {
    type: "HierarchyForceDirected",
    title: "Force directed",
    component: HierarchyForceDirected,
    group: "Hierarchy",
  },
  HierarchySunburst: {
    type: "HierarchySunburst",
    title: "Sunburst",
    component: HierarchySunburst,
    group: "Hierarchy",
  },
  HierarchyTree: {
    type: "HierarchyTree",
    title: "Tree",
    component: HierarchyTree,
    group: "Hierarchy",
  },
  HierarchyTreeRadial: {
    type: "HierarchyTreeRadial",
    title: "Tree radial",
    component: HierarchyTreeRadial,
    group: "Hierarchy",
  },
  HierarchyTreemap: {
    type: "HierarchyTreemap",
    title: "Treemap",
    component: HierarchyTreemap,
    group: "Hierarchy",
  },
  HierarchyTreemapNested: {
    type: "HierarchyTreemapNested",
    title: "Treemap nested",
    component: HierarchyTreemapNested,
    group: "Hierarchy",
  },
  // HierarchyTreemapVoronoi: {
  //   title: "Hierarchy treemap Voronoi",
  //   component: HierarchyTreemapVoronoi,
  // },
  //----------------------------------
  Scatter: {
    type: "Scatter",
    title: "Scatter",
    component: Scatter,
    group: "Scatter",
  },
  //----------------------------------
  Heatmap: {
    type: "Heatmap",
    title: "Heatmap",
    component: Heatmap,
    group: "Other",
  },
  Lollipops: {
    type: "Lollipops",
    title: "Lollipops",
    component: Lollipops,
    group: "Other",
  },
  ProgressCircle: {
    type: "ProgressCircle",
    title: "Progress circle",
    component: ProgressCircle,
    group: "Other",
  },
  Radar: {
    type: "Radar",
    title: "Radar",
    component: Radar,
    group: "Other",
  },
  Range: {
    type: "Range",
    title: "Range",
    component: Range,
    group: "Other",
  },
  Sankey: {
    type: "Sankey",
    title: "Sankey",
    component: Sankey,
    group: "Other",
  },
  // Test: {
  //   title: "Test",
  //   component: Test,
  //   group: "Other",
  // },
  Venn: {
    type: "Venn",
    title: "Venn",
    component: Venn,
    group: "Other",
  },
  //----------------------------------
  ExampleHeatmap: {
    type: "Heatmap",
    title: "Heatmap - Simpsons episodes",
    component: Heatmap,
    group: "Examples",
    chartSettings: exampleHeatmap,
  },
  ExampleScatter: {
    type: "Scatter",
    title: "Scatter - Nobel prizes",
    component: Scatter,
    group: "Examples",
    chartSettings: exampleScatter,
  },
  ExampleSunburst: {
    type: "Sunburst",
    title: "Sunburst - US religious",
    component: HierarchySunburst,
    group: "Examples",
    chartSettings: exampleSunburst,
  },
};

export default Charts;
