import { ControlType } from "../ui/controltype";
import { nobelNominees, nobelLaureates, nominees } from "../../data/nobel";

const nobelLaureatesSorted = nobelLaureates.sort((a, b) => a.year - b.year);
const nobelNomineesSorted = nobelNominees.sort((a, b) => {
  return a.yearWin - b.yearWin;
});

const dimensions = {
  Labels: ControlType.String,
  X: ControlType.Number,
  Y: ControlType.String,
  Z: ControlType.Number,
};

export const exampleScatter = {
  isData: false,
  layoutSettings: {
    isFullscreen: false,
    isWidthAuto: true,
    minWidth: 320,
    maxWidth: 1900,
    isHeightAuto: false,
    height: 3000,
    background: "rgba(255, 255, 255, 1)",
  },
  chartSettings: {
    margin: {
      margin: 80,
      isPerSide: true,
      top: 20,
      bottom: 160,
      left: 200,
      right: 200,
    },
  },
  sourceData: true,
  fileData: null,
  xAxis: {
    isVisible: true,
    isLabelVisible: false,
    labelText: "Year",
    labelAlign: "right",
    labelMargin: 0,
    labelColor: "rgba(0, 0, 0, 1)",
    labelFontSize: 12,
    color: "rgba(0, 0, 0, 1)",
    height: 60,
    orient: "top",
    isAxisLine: true,
    domain: false,
    domainMin: "dataMin",
    domainMinValue: 0,
    domainMax: "dataMax",
    domainMaxValue: 1,
    tickFontSize: 12,
    isTicksAuto: true,
    tickCountValue: 5,
    tickCountAccuracy: false,
    tickCountCategory: true,
    tickCountValueCategory: 5,
    tickRotate: -90,
    tickSize: 6,
    tickMargin: 4,
    tickOrder: false,
    units: "",
    useLocale: false,
  },
  yAxis: {
    isVisible: false,
    isLabelVisible: false,
    labelText: "Laureate",
    labelAlign: "right",
    labelMargin: 0,
    labelColor: "rgba(0, 0, 0, 1)",
    labelFontSize: 12,
    labelRotate: -90,
    color: "rgba(0, 0, 0, 1)",
    width: 60,
    orient: "left",
    isAxisLine: true,
    domain: true,
    domainMin: "dataMin",
    domainMinValue: 0,
    domainMax: "dataMax",
    domainMaxValue: 1,
    tickFontSize: 12,
    isTicksAuto: true,
    tickCountValue: 5,
    tickCountAccuracy: false,
    tickCountCategory: true,
    tickCountValueCategory: 5,
    tickSize: 6,
    tickMargin: 4,
    tickOrder: true,
    units: "",
    useLocale: false,
  },
  zAxis: {
    units: "",
    dotsMinSize: 8,
    dotsMaxSize: 80,
    labelText: "Nominations",
    useLocale: true,
  },
  grid: {
    gridHoriz: true,
    gridVert: false,
    gridColor: "rgba(0, 0, 0, .05)",
  },
  scatters: {
    isVisible: true,
    items: [
      {
        name: "— Nominations,",
        dotsType: "circle",
        dotsColor: "rgba(206, 161, 82, .50)",
        dotsStyle: true,
        dotsStrokeWidth: 1,
        showLabels: false,
        labelsColor: "rgba(0, 0, 0, 1)",
        labelsFontSize: 12,
        labelsPosition: "C",
        showLine: false,
        lineColor: "rgba(192, 159, 57, .50)",
        lineStrokeWidth: 1,
        isDashed: false,
        dashes: 4,
        spaces: 4,
        isTooltipAllowed: true,
      },
      {
        name: "— Wins",
        dotsType: "tick",
        dotsColor: "rgba(0, 0, 0, 1)",
        dotsStyle: true,
        dotsStrokeWidth: 1,
        showLabels: true,
        labelsColor: "rgba(0, 0, 0, 1)",
        labelsFontSize: 12,
        labelsPosition: "R",
        showLine: false,
        lineColor: "rgba(192, 159, 57, .50)",
        lineStrokeWidth: 1,
        isDashed: false,
        dashes: 4,
        spaces: 4,
        isTooltipAllowed: false,
      },
    ],
  },
  dataSeries: {
    isVisible: true,
    items: [
      {
        Labels: nobelNomineesSorted.map((item) => item.nominee),
        X: nobelNomineesSorted.map((item) => item.year),
        Y: nobelNomineesSorted.map((item) => item.nominee),
        Z: nobelNomineesSorted.map((item) => item.nomineeCount),
      },
      {
        Labels: nobelLaureatesSorted.map((item) => item.laureate),
        X: nobelLaureatesSorted.map((item) => item.year),
        Y: nobelLaureatesSorted.map((item) => item.laureate),
        Z: [...Array(nobelLaureatesSorted.length)].fill(8),
      },
    ],
  },
  dimensions: dimensions,
  legend: {
    isVisible: true,
    txtColor: "rgba(0,0,0,1)",
    fontSize: 14,
    iconSize: 14,
    layout: true,
    align: "center",
    margin: {
      margin: 0,
      isPerSide: true,
      top: 20,
      bottom: 20,
      left: 0,
      right: 0,
    },
  },
  title: {
    isVisible: true,
    text: "Nobel prize laureates in physics 1901—1970",
    txtColor: "rgba(0,0,0,1)",
    fontSize: 72,
    fontFamily: "Prata",
    fontVariants: "regular",
    align: "center",
    margin: {
      margin: 0,
      isPerSide: true,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  refDots: {
    isVisible: false,
    items: [
      {
        xCoordinateNumber: null,
        xCoordinateCategory: "",
        yCoordinateNumber: null,
        yCoordinateCategory: "",
        dotSize: 16,
        dotColor: "rgba(0, 0, 0, 1)",
        dotStyle: true,
        dotStrokeWidth: 1,
        showLabel: false,
        labelText: "Dot's values or note",
        labelColor: "rgba(0, 0, 0, 1)",
        labelFontSize: 12,
        labelPosition: "R",
        labelOffsetX: 0,
        labelOffsetY: 0,
        labelWrap: true,
        labelWidth: 20,
        showLabelLine: true,
        labelLineColor: "rgba(0, 0, 0, 1)",
      },
    ],
  },
  caption: {
    isVisible: true,
    items: [
      {
        isVisible: true,
        text: "The size of «nomination bubbles» depend on quantity of nominators, who proposed nominee",
        txtColor: "rgba(0,0,0,1)",
        fontSize: 14,
        fontFamily: "Roboto",
        fontVariants: "300",
        align: "center",
        margin: {
          margin: 0,
          isPerSide: true,
          top: 20,
          bottom: 0,
          left: 0,
          right: 0,
        },
      },
      {
        isVisible: true,
        text: "Data by nobelprize.org",
        txtColor: "rgba(0,0,0,.5)",
        fontSize: 14,
        fontFamily: "Roboto",
        fontVariants: "300",
        align: "center",
        margin: {
          margin: 0,
          isPerSide: true,
          top: 20,
          bottom: 0,
          left: 0,
          right: 0,
        },
      },
    ],
  },
  tooltip: {
    isVisible: true,
    txtColor: "rgba(0,0,0,1)",
    bgColor: "rgba(255,255,255,1)",
    useAxesLabels: true,
    showCursor: true,
    cursorColor: "rgba(0, 0, 0, .1)",
  },
};

export default exampleScatter;
