import {
  getChartDefaultProps,
  setObjValueByPath,
  createUISettings,
  createChartSettings,
  addArrayItemByPath,
  toggleObjValueByPath,
  removeArrayItemsByPath,
  getCollectionRef,
  insertGroup,
  MIN_RIGHT_DRAWER_WIDTH,
  getChart,
  convertOldChart,
  getWindowDimensions,
} from "./components/utils/utils";
import { Charts } from "./components/charts/charts";

const { height: PAGE_HEIGHT, width: PAGE_WIDTH } = getWindowDimensions();

export function reducer(state, action) {
  const { name, value, fileType } = action;
  let UI = { ...state.chart.chartUI };

  switch (action.type) {
    case "UPDATE_CHART_TYPE":
      const chartType = value;
      const isDrawerRightOpened = state.isDrawerRightOpened;
      // get predefined or default chart settings
      const chartProps =
        Charts[chartType].chartSettings || getChartDefaultProps(chartType);
      // get default ui settings or build them from predefines chart settings
      const ui = createUISettings(chartType, Charts[chartType].chartSettings);
      const layoutWidth =
        chartProps.layoutSettings.width !== undefined
          ? chartProps.layoutSettings.width
          : isDrawerRightOpened
          ? PAGE_WIDTH - MIN_RIGHT_DRAWER_WIDTH
          : PAGE_WIDTH;
      const layoutHeight =
        chartProps.layoutSettings.height !== undefined
          ? chartProps.layoutSettings.height
          : PAGE_HEIGHT;

      return {
        ...state,
        chart: {
          ...state.chart,
          currentChartType: chartType,
          chartSettings: {
            ...chartProps,
            // if width and height set as chart's default props, get them
            // else, get initial values
            layoutSettings: {
              ...chartProps.layoutSettings,
              width: layoutWidth,
              height: layoutHeight,
            },
          },
          chartUI: {
            ...ui,
            layoutSettings: {
              ...ui["layoutSettings"],
              width: layoutWidth,
              height: layoutHeight,
            },
          },
        },
        firebase: {
          ...state.firebase,
          isCurrentChartSaved: true,
        },
      };

    case "UPDATE_CHART_SETTINGS":
      // if data type updated, we need to update dimension, convert data and show popup if change from category to number type
      const namePath = name.split(".");
      if (namePath.length > 1 && namePath[0] === "dimensions") {
        // if we want convert data type from string to number, show alert popup and then, call UPDATE_DATA_TYPE_TO_NUMBER from there
        if (value === "number") {
          return {
            ...state,
            popup: {
              opened: true,
              props: {
                content: "Convert data alert",
                dimension: name,
              },
            },
          };
        }
        // if number to string, convert and update "dimension" settings
        else {
          const dimensionKey = namePath[1];
          const dimensionControl = getChart(state.chart.currentChartType).type
            .propertyControls.dimensions.propertyControl[dimensionKey];
          const dataPath = dimensionControl.path;
          if (
            dimensionControl.seriesType ||
            state.chart.currentChartType === "Scatter" ||
            state.chart.currentChartType === "ExampleScatter"
          ) {
            const chartData = UI[dataPath].items;
            for (let i = 0; i < chartData.length; i++) {
              const valuesArr = chartData[i].value[dimensionKey];
              for (let j = 0; j < valuesArr.length; j++) {
                const v = Number.isNaN(valuesArr[j].value)
                  ? ""
                  : valuesArr[j].value;
                valuesArr[j].value = "" + v + ""; // mutate ?
              }
            }
          } else {
            const valuesArr = UI[dataPath][dimensionKey];
            for (let j = 0; j < valuesArr.length; j++) {
              const v = Number.isNaN(valuesArr[j].value)
                ? ""
                : valuesArr[j].value;
              valuesArr[j].value = "" + v + ""; // mutate ?
            }
          }
        }
      }

      setObjValueByPath(UI, name, value);

      return {
        ...state,
        chart: {
          ...state.chart,
          chartSettings: createChartSettings(state.chart.currentChartType, UI),
          chartUI: UI,
        },
        firebase: {
          ...state.firebase,
          isCurrentChartSaved: false,
        },
      };

    case "UPDATE_DATA_TYPE_TO_NUMBER":
      const dimension = value.dimension;
      const dimensionKey = dimension.split(".")[1];
      const dimensionControl = getChart(state.chart.currentChartType).type
        .propertyControls.dimensions.propertyControl[dimensionKey];
      const dataPath = dimensionControl.path;

      if (
        dimensionControl.seriesType ||
        state.chart.currentChartType === "Scatter" ||
        state.chart.currentChartType === "ExampleScatter"
      ) {
        const chartData = UI[dataPath].items;
        for (let i = 0; i < chartData.length; i++) {
          const valuesArr = chartData[i].value[dimensionKey];
          for (let j = 0; j < valuesArr.length; j++) {
            // mutate ?
            if (valuesArr[j].value.trim() === "") {
              valuesArr[j].value = NaN;
            } else {
              valuesArr[j].value = valuesArr[j].value * 1;
            }
          }
        }
      } else {
        const valuesArr = UI[dataPath][dimensionKey];
        for (let j = 0; j < valuesArr.length; j++) {
          // mutate ?
          if (valuesArr[j].value.trim() === "") {
            valuesArr[j].value = NaN;
          } else {
            valuesArr[j].value = valuesArr[j].value * 1;
          }
        }
      }

      setObjValueByPath(UI, dimension, "number");

      return {
        ...state,
        chart: {
          ...state.chart,
          chartSettings: createChartSettings(state.chart.currentChartType, UI),
          chartUI: UI,
        },
        firebase: {
          ...state.firebase,
          isCurrentChartSaved: false,
        },
      };

    case "ADD_ARRAY_ITEM":
      addArrayItemByPath(UI, name, value);

      return {
        ...state,
        chart: {
          ...state.chart,
          chartSettings: createChartSettings(state.chart.currentChartType, UI),
          chartUI: UI,
        },
        firebase: {
          ...state.firebase,
          isCurrentChartSaved: false,
        },
      };

    case "REMOVE_ARRAY_ITEMS":
      removeArrayItemsByPath(UI, name);

      return {
        ...state,
        chart: {
          ...state.chart,
          chartSettings: createChartSettings(state.chart.currentChartType, UI),
          chartUI: UI,
        },
        firebase: {
          ...state.firebase,
          isCurrentChartSaved: false,
        },
      };

    case "UPDATE_ARRAY_ITEM_VISIBILITY":
      toggleObjValueByPath(UI, name + ".hidden");

      return {
        ...state,
        chart: {
          ...state.chart,
          chartSettings: createChartSettings(state.chart.currentChartType, UI),
          chartUI: UI,
        },
        firebase: {
          ...state.firebase,
          isCurrentChartSaved: false,
        },
      };

    case "OPEN_DRAWER":
      return {
        ...state,
        isDrawerRightOpened: true,
      };

    case "CLOSE_DRAWER":
      return {
        ...state,
        isDrawerRightOpened: false,
      };

    case "OPEN_POPUP":
      return {
        ...state,
        popup: {
          ...state.popup,
          opened: true,
          props: value,
        },
      };

    case "CLOSE_POPUP":
      return {
        ...state,
        popup: {
          ...state.popup,
          opened: false,
          props: {},
        },
      };

    case "UPDATE_FILE":
      return {
        ...state,
        fileUploaded: { file: value, fileType: fileType },
      };

    case "UPDATE_CHART_REF":
      return {
        ...state,
        chartRef: value,
      };

    case "ADD_GROUP":
      const addRef = getCollectionRef(state.chart.currentChartType, name);
      const addRefValue = addRef
        ? createUISettings(state.chart.currentChartType)[addRef].items[0]
        : null;
      const addValue = value
        ? value
        : createUISettings(state.chart.currentChartType)[name].items[0];
      const addChartUI = addRef
        ? {
            ...state.chart.chartUI,
            [name]: {
              ...state.chart.chartUI[name],
              items: insertGroup(state.chart.chartUI[name].items, addValue),
            },
            [addRef]: {
              ...state.chart.chartUI[addRef],
              items: insertGroup(
                state.chart.chartUI[addRef].items,
                addRefValue
              ),
            },
          }
        : {
            ...state.chart.chartUI,
            [name]: {
              ...state.chart.chartUI[name],
              items: insertGroup(state.chart.chartUI[name].items, addValue),
            },
          };

      return {
        ...state,
        chart: {
          ...state.chart,
          chartSettings: createChartSettings(
            state.chart.currentChartType,
            addChartUI
          ),
          chartUI: addChartUI,
        },
        firebase: {
          ...state.firebase,
          isCurrentChartSaved: false,
        },
      };

    case "REMOVE_GROUP": {
      const groupId = value;
      const removeRef = getCollectionRef(state.chart.currentChartType, name);
      const removeChartUI = removeRef
        ? {
            ...state.chart.chartUI,
            [name]: {
              ...state.chart.chartUI[name],
              items: state.chart.chartUI[name].items.filter(
                (item, i) => i !== groupId
              ),
            },
            [removeRef]: {
              ...state.chart.chartUI[removeRef],
              items: state.chart.chartUI[removeRef].items.filter(
                (item, i) => i !== groupId
              ),
            },
          }
        : {
            ...state.chart.chartUI,
            [name]: {
              ...state.chart.chartUI[name],
              items: state.chart.chartUI[name].items.filter(
                (item, i) => i !== groupId
              ),
            },
          };

      return {
        ...state,
        chart: {
          ...state.chart,
          chartSettings: createChartSettings(
            state.chart.currentChartType,
            removeChartUI
          ),
          chartUI: removeChartUI,
        },
        firebase: {
          ...state.firebase,
          isCurrentChartSaved: false,
        },
      };
    }

    // collapse / unroll group
    case "TOGGLE_GROUP": {
      const groupId = value;
      toggleObjValueByPath(UI, name + ".items." + groupId + ".collapsed");
      return {
        ...state,
        chart: {
          ...state.chart,
          chartUI: UI,
        },
      };
    }

    case "TOGGLE_ALLGROUPS": {
      const itemsCount = UI[name].items.length;
      for (let i = 0; i < itemsCount; i++) {
        setObjValueByPath(UI, name + ".items." + i + ".collapsed", value);
      }
      return {
        ...state,
        chart: {
          ...state.chart,
          chartUI: UI,
        },
      };
    }

    case "UPDATE_GROUP_VISIBILITY": {
      const groupId = value;
      toggleObjValueByPath(UI, name + ".items." + groupId + ".hidden");
      const updateRef = getCollectionRef(state.chart.currentChartType, name);
      if (updateRef) {
        toggleObjValueByPath(UI, updateRef + ".items." + groupId + ".hidden");
      }

      return {
        ...state,
        chart: {
          ...state.chart,
          chartSettings: createChartSettings(state.chart.currentChartType, UI),
          chartUI: UI,
        },
        firebase: {
          ...state.firebase,
          isCurrentChartSaved: false,
        },
      };
    }

    // swap data or settings groups. they may be connected by "refs", so take into consideration.
    case "SWAP_GROUPS":
      const groupId_1 = value[0];
      const groupId_2 = value[1];
      const arrSwap = state.chart.chartUI[name].items;
      // swap data groups
      [arrSwap[groupId_1], arrSwap[groupId_2]] = [
        arrSwap[groupId_2],
        arrSwap[groupId_1],
      ];

      // swap refs
      const swapRef = getCollectionRef(state.chart.currentChartType, name);
      if (swapRef) {
        const arrSwapRef = state.chart.chartUI[swapRef].items;
        if (arrSwapRef) {
          [arrSwapRef[groupId_1], arrSwapRef[groupId_2]] = [
            arrSwapRef[groupId_2],
            arrSwapRef[groupId_1],
          ];
        }
      }

      return {
        ...state,
        chart: {
          ...state.chart,
          chartSettings: createChartSettings(state.chart.currentChartType, UI),
          chartUI: {
            ...state.chart.chartUI,
          },
        },
        firebase: {
          ...state.firebase,
          isCurrentChartSaved: false,
        },
      };

    case "UPDATE_GOOGLEFONTS":
      return {
        ...state,
        googleFonts: value,
      };

    case "UPDATE_AUTH":
      return {
        ...state,
        firebase: {
          ...state.firebase,
          isSignedIn: value,
        },
      };

    case "IS_USER_AUTH_COMPLETE":
      return {
        ...state,
        firebase: {
          ...state.firebase,
          isAuthComplete: value,
        },
      };

    case "UPDATE_CURRENT_CHART_ID":
      return {
        ...state,
        firebase: {
          ...state.firebase,
          currentChartId: value,
        },
      };

    case "UPDATE_CURRENT_CHART_NAME":
      return {
        ...state,
        firebase: {
          ...state.firebase,
          currentChartName: value,
        },
      };

    case "IS_CURRENT_CHART_SAVED":
      return {
        ...state,
        firebase: {
          ...state.firebase,
          isCurrentChartSaved: value,
        },
      };

    case "LOAD_CHART_SETTINGS_FROM_DB":
      const { updatedSettings, updatedUI } = convertOldChart(
        value.chart.chartType,
        value.chart.chartSettings,
        value.chart.chartUI || {}
      );
      return {
        ...state,
        chart: {
          ...state.chart,
          chartSettings: updatedSettings,
          chartUI: updatedUI,
          currentChartType: value.chart.chartType,
        },
        firebase: {
          ...state.firebase,
          currentChartId: value.chartId,
          currentChartName: value.chartName,
          isCurrentChartSaved: true,
        },
      };

    default:
      return state;
  }
}

export default reducer;
