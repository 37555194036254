import React from "react";
import { ControlType } from "./controltype";
import { Checkbox } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  UPDATE_ARRAY_ITEM_VISIBILITY,
  UPDATE_GROUP_VISIBILITY,
} from "../utils/actions";

export function InputCheckbox(props) {
  const { name, index, isHidden, parentType } = props;
  const dispatch = useDispatch();
  const handleChangeVisibility = () => {
    switch (parentType) {
      case ControlType.Array:
        dispatch(UPDATE_ARRAY_ITEM_VISIBILITY(name));
        break;
      case ControlType.Collection:
        dispatch(UPDATE_GROUP_VISIBILITY(name, index));
        break;
    }
  };
  return (
    <Checkbox
      checked={!isHidden}
      style={{ padding: 0 }}
      onChange={handleChangeVisibility}
    />
  );
}

export default InputCheckbox;
