import * as React from "react";
import { useState, useEffect } from "react";
import { ControlType, applyPropertyControls } from "../ui/controltype";
import { useDispatch } from "react-redux";
import { UPDATE_CHART_REF } from "../utils/actions";
import { Helmet } from "react-helmet";
import {
  presetLayout,
  presetTitle,
  presetCaption,
  presetLegend,
  presetTooltip,
} from "./propertyControlsPresets";
import { buildHierarchyData } from "../utils/utils";
import * as d3 from "d3";
import { Tooltip } from "recharts";
import { CustomizedTooltip } from "./customTooltip";
import { dataRaw } from "../../data/sunburst";
import { CustomLegend } from "./customLegend";
import { TextBlock } from "./textBlock";
import { useHeaderHeight } from "./customHooks";

const partition = (data, radius) =>
  d3.partition().size([2 * Math.PI, radius])(
    d3
      .hierarchy(data)
      .sum((d) => d.value)
      .sort((a, b) => b.value - a.value)
  );

export function HierarchySunburst(props) {
  const {
    layoutSettings,
    colors,
    chartSettings,
    labels,
    labelSummary,
    legend,
    title,
    caption,
    tooltip,
    dataWeights,
    dataSeries,
  } = props;

  const { background, width } = layoutSettings;

  const dataWeightsJSON = JSON.stringify(dataWeights);
  const dataSeriesJSON = JSON.stringify(dataSeries);

  const dispatch = useDispatch();

  const [data, setData] = useState(partition({}, 0));
  const [dataChangedFlag, setDataChangedFlag] = useState(true);
  const [isTolltipActive, setIsTolltipActive] = useState(false);
  const [tooltipContent, setTooltipContent] = useState({
    name: "",
    value: 0,
  });
  const [tooltipCoordinates, setTooltipCoordinates] = useState({
    x: 0,
    y: 0,
  });
  const [fontLoaded, setFontLoaded] = React.useState(false);

  const chartRef = React.useRef<HTMLDivElement>(null);
  const { headerRef, height } = useHeaderHeight(
    width,
    layoutSettings,
    caption,
    title,
    legend,
    data
      .descendants()
      .filter((d) => d.depth === 1)
      .map(
        (elem) =>
          elem.data.name +
          ": " +
          Math.round(elem.value * 100) / 100 +
          chartSettings.units
      )
      .join("")
  );

  //calc sunburst chart radius from width, height and margins
  const radius = (Math.min(width, height) / 2) * (chartSettings.scale / 100);

  // custom fonts loading status
  title["fontLoaded"] = fontLoaded;
  for (let i = 0; i < caption.items.length; i++) {
    caption.items[i]["fontLoaded"] = fontLoaded;
  }

  useEffect(() => {
    document.fonts.ready.then(() => {
      setFontLoaded(true);
    });
  });

  const d3ColorSchemes = {
    "#01": "schemePaired",
    "#02": "schemeCategory10",
    "#03": "schemeDark2",
  };

  const getCustomColor = (data, colors, categoryName) => {
    const set = data.children
      ? Object.fromEntries(
          data.children.map((item, i) => [
            item.data.name,
            colors[i] || colors[colors.length - 1],
          ])
        )
      : {};
    return set[categoryName] || "";
  };

  const color = d3.scaleOrdinal(
    d3[d3ColorSchemes[colors.colorsScheme.split(" ")[0]]]
  );

  const getColor = (categoryName) =>
    colors.colorsType
      ? color(categoryName)
      : getCustomColor(data, colors.colorsCustom, categoryName);

  const arc = d3
    .arc()
    .startAngle((d) => d.x0)
    .endAngle((d) => d.x1)
    .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
    .padRadius(radius / 2)
    .innerRadius((d) => d.y0 + chartSettings.spacingRadial / 2)
    .outerRadius((d) => d.y1 - chartSettings.spacingRadial / 2)
    .cornerRadius(chartSettings.roundCorners);

  const middleArcLine = (d) => {
    const halfPi = Math.PI / 2;
    const angles = [d.x0 - halfPi, d.x1 - halfPi];
    const r = Math.max(0, (d.y0 + d.y1) / 2);

    const middleAngle = (angles[1] + angles[0]) / 2;
    const invertDirection = middleAngle > 0 && middleAngle < Math.PI; // On lower quadrants write text ccw
    if (invertDirection) {
      angles.reverse();
    }

    const path = d3.path();
    path.arc(0, 0, r, angles[0], angles[1], invertDirection);
    return path.toString();
  };

  const sunburst = (data, radius) => partition(data, radius);

  const svgViewBox = [
    -width / 2 - chartSettings.xOffset,
    -height / 2 - chartSettings.yOffset,
    width,
    height,
  ];

  const handleTooltipOn = (e, d) => {
    setIsTolltipActive(true);
    setTooltipContent({ name: d.data.name, value: d.value });
  };
  const handleTooltipOff = () => {
    setIsTolltipActive(false);
    setTooltipContent({ name: "", value: 0 });
  };
  const handleTooltipCoordinates = (props) => {
    setTooltipCoordinates({ x: props.x, y: props.y });
  };

  //Apply style
  function applyStyle(svg) {
    svg.attr("width", width).attr("height", height).attr("viewBox", svgViewBox);

    //Style for nodes
    svg
      .select("#svgSunburstNodes")
      .selectAll("path")
      .attr("fill", (d) => {
        while (d.depth > 1) d = d.parent;
        return getColor(d.data.name);
      })
      .attr(
        "fill-opacity",
        colors.colorsType ? colors.colorSchemeOpacity / 100 : 1
      );

    //Style for summary label
    svg
      .select("#svgSunburstLabelSummary")
      .selectAll("text")
      .attr("font-size", labelSummary.labelFontSize)
      .attr("fill", labelSummary.labelColor)
      .attr("color", labelSummary.labelColor)
      .attr("opacity", labelSummary.isVisible ? 1 : 0);

    //Style for labels across arcs (nodes)
    const svgLabelsAcross = svg
      .select("#svgSunburstLabelsAcross")
      .selectAll("text")
      .attr("font-size", labels.titleFontSize)
      .attr("fill", labels.titleColor)
      .attr("color", labels.titleColor);

    //Style for labels along arcs (nodes)
    const svgLabelsAlong = svg
      .select("#svgSunburstLabelsAlong")
      .selectAll("text")
      .attr("font-size", labels.titleFontSize)
      .attr("fill", labels.titleColor)
      .attr("color", labels.titleColor);

    //calc labels and nodes width and height
    let labelsSize: any[] = [];
    let nodesSize: any[] = [];
    svgLabelsAcross.nodes().forEach(function (el) {
      const txtWidth = el.getBBox().width + labels.titleFontSize;
      const txtHeight = el.getBBox().height * 1.2;
      labelsSize.push({ width: txtWidth, height: txtHeight });

      const nodeWidth = el.__data__.y1 - el.__data__.y0;
      const r = (el.__data__.y0 + el.__data__.y1) / 2;
      const angles = [
        el.__data__.x0 - Math.PI / 2,
        el.__data__.x1 - Math.PI / 2,
      ];
      const nodeHeight = r * (angles[1] - angles[0]);
      nodesSize.push({ width: nodeWidth, height: nodeHeight });
    });

    svgLabelsAcross.nodes().forEach(function (el, i) {
      if (labels.isVisible && !labels.labelsType) {
        const isWidth = labels.labelsLength
          ? labelsSize[i].width < nodesSize[i].width
          : 1;
        const isHeight = labelsSize[i].height < nodesSize[i].height;
        const isLabelVisible = isWidth && isHeight ? 1 : 0;
        el.setAttribute("opacity", isLabelVisible);
      } else {
        el.setAttribute("opacity", 0);
      }
    });

    svgLabelsAlong.nodes().forEach(function (el, i) {
      if (labels.isVisible && labels.labelsType) {
        el.setAttribute("opacity", 1);
        const isWidth = labels.labelsLength
          ? labelsSize[i].width < nodesSize[i].height
          : 1;
        const isHeight = labelsSize[i].height < nodesSize[i].width;
        const isLabelVisible = isWidth && isHeight ? 1 : 0;
        el.setAttribute("opacity", isLabelVisible);
      } else {
        el.setAttribute("opacity", 0);
      }
    });
  }

  //Updating when recieve new data or width (and first time mounted)
  useEffect(() => {
    const svg = d3.select("#svgTree");

    svg.selectAll("textPath").remove();

    //Draw nodes
    svg
      .select("#svgSunburstNodes")
      .selectAll("path")
      .data(data.descendants().filter((d) => d.depth))
      .join("path")
      .attr("d", arc)
      .attr("id", (d, i) => "nodeId-" + i)
      .on("mouseover", (e, d) => handleTooltipOn(e, d))
      .on("mouseout", handleTooltipOff)
      .on("mousemove", handleTooltipCoordinates);

    //Draw summary label
    svg
      .select("#svgSunburstLabelSummary")
      .selectAll("text")
      .data(data.descendants().filter((d) => d.depth === 0))
      .join("text")
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "central")
      .text(
        (d) =>
          (Math.round(d.value * 100) / 100).toLocaleString() +
          chartSettings.units
      );

    //Draw labels across arcs (nodes)
    svg
      .select("#svgSunburstLabelsAcross")
      .selectAll("text")
      .data(data.descendants().filter((d) => d.depth))
      .join("text")
      .attr("transform", function (d) {
        const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
        const y = (d.y0 + d.y1) / 2;
        return `rotate(${
          x - 90
        }) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
      })
      .attr("dy", "0.35em")
      .attr("text-anchor", "middle")
      .style("pointer-events", "none")
      .text((d) => d.data.name);

    //Draw paths for labels along arcs (nodes)
    svg
      .select("#svgSunburstLabelsPath")
      .selectAll("path")
      .data(data.descendants().filter((d) => d.depth))
      .join("path")
      .attr("d", middleArcLine)
      .attr("id", (d, i) => "labelPathId-" + i)
      .attr("stroke", "none")
      .attr("fill", "none")
      .style("pointer-events", "none");

    //Draw labels along arcs (nodes)
    svg
      .select("#svgSunburstLabelsAlong")
      .selectAll("text")
      .data(data.descendants().filter((d) => d.depth))
      .join("text")
      .append("textPath")
      .text((d) => d.data.name)
      .attr("href", (d, i) => "#labelPathId-" + i)
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "central")
      .attr("startOffset", "50%")
      .style("pointer-events", "none");
  }, [dataChangedFlag]);

  useEffect(() => {
    const root = buildHierarchyData({
      series: dataSeries.items,
      weights: dataWeights.Weights,
    });
    setData(sunburst(root, radius));
    setDataChangedFlag(!dataChangedFlag);
  }, [
    dataWeightsJSON,
    dataSeriesJSON,
    width,
    height,
    chartSettings.spacingRadial,
    chartSettings.roundCorners,
    chartSettings.units,
    chartSettings.scale,
  ]);

  //Updating style and coordinates settings
  useEffect(() => {
    applyStyle(d3.select(chartRef.current).select("#svgTree"));
  });

  useEffect(() => {
    const element = chartRef.current;
    if (element) {
      const svg = element;
      dispatch(UPDATE_CHART_REF(svg));
    }
  }, [chartRef, dispatch]);

  return (
    <div
      id="HierarchySunburst"
      ref={chartRef}
      style={{ background: background }}
    >
      <Helmet>
        <link
          rel="stylesheet"
          href={
            "https://fonts.googleapis.com/css?family=" +
            title.fontFamily.replace(/ /g, "+") +
            ":" +
            title.fontVariants
          }
          crossOrigin="anonymous"
        />
        {caption.items.map((elem, i) => {
          return (
            <link
              key={"caption-" + i}
              rel="stylesheet"
              href={
                "https://fonts.googleapis.com/css?family=" +
                elem.fontFamily.replace(/ /g, "+") +
                ":" +
                elem.fontVariants
              }
              crossOrigin="anonymous"
            />
          );
        })}
      </Helmet>
      <div ref={headerRef} style={{ width: width }}>
        {title.isVisible ? (
          <TextBlock key={"title"} title={title} fontLoaded={fontLoaded} />
        ) : (
          ""
        )}
        {caption.isVisible
          ? caption.items.map((elem, i) => {
              return (
                <TextBlock
                  key={"caption" + i}
                  title={elem}
                  fontLoaded={fontLoaded}
                />
              );
            })
          : ""}
        {legend.isVisible ? (
          <CustomLegend
            legendProps={legend}
            items={data
              .descendants()
              .filter((d) => d.depth === 1)
              .map((elem) => {
                return {
                  name:
                    elem.data.name +
                    ": " +
                    Math.round(elem.value * 100) / 100 +
                    chartSettings.units,
                  dotsType: "circle",
                  dotsStyle: true,
                  dotsColor: getColor(elem.data.name),
                };
              })}
          />
        ) : (
          ""
        )}
      </div>
      <svg id="svgTree" className="chart-surface">
        <g id="chartContainer">
          <g id="svgSunburstNodes" />
          <g id="svgSunburstLabelsPath" />
          <g id="svgSunburstLabelsAlong" />
          <g id="svgSunburstLabelsAcross" />
          <g id="svgSunburstLabelSummary" />
        </g>
      </svg>
      {tooltip.isVisible ? (
        <Tooltip
          active={isTolltipActive}
          payload={[
            {
              value: Math.round(tooltipContent.value * 100) / 100,
            },
          ]}
          viewBox={{ x: 0, y: 0, width: width, height: height }}
          coordinate={{ x: tooltipCoordinates.x, y: tooltipCoordinates.y }}
          separator=": "
          content={
            <CustomizedTooltip
              txtColor={tooltip.txtColor}
              background={tooltip.bgColor}
              chartType={"sunburst"}
              payload={[{ value: tooltipContent.value }]}
              label={tooltipContent.name}
              units={{ x: "", y: chartSettings.units }}
              axesDataTypes={{
                x: "string",
                y: "number",
              }}
              axesLabels={{ x: "", y: "" }}
              useAxesLabels={false}
              useLocales={{
                x: false,
                y: true,
              }}
            />
          }
        />
      ) : (
        ""
      )}
    </div>
  );
}

HierarchySunburst.defaultProps = {
  isData: false,
  layoutSettings: {
    isFullscreen: true,
    isWidthAuto: true,
    minWidth: 320,
    maxWidth: 2500,
    isHeightAuto: true,
    background: "rgba(255, 255, 255, 1)",
  },
  sourceData: true,
  fileData: null,
  chartSettings: {
    xOffset: 0,
    yOffset: 0,
    scale: 50,
    units: "",
    spacingRadial: 1,
    roundCorners: 0,
  },
  colors: {
    colorsType: false,
    colorsScheme: "#01 (12 colors)",
    colorSchemeOpacity: 80,
    colorsCustom: [
      "rgba(17, 153, 238, 1)",
      "rgba(17, 153, 238, .25)",
      "rgba(17, 153, 238, 1)",
      "rgba(17, 153, 238, .25)",
      "rgba(17, 153, 238, 1)",
      "rgba(17, 153, 238, .25)",
      "rgba(17, 153, 238, 1)",
      "rgba(17, 153, 238, .25)",
    ],
  },
  labels: {
    isVisible: false,
    labelsType: false,
    labelsLength: false,
    titleColor: "rgba(0, 0, 0, 1)",
    titleFontSize: 10,
  },
  labelSummary: {
    isVisible: false,
    labelColor: "rgba(17, 153, 238, 1)",
    labelFontSize: 24,
  },
  title: {
    isVisible: false,
    text: "Chart title",
    txtColor: "rgba(0,0,0,1)",
    fontSize: 48,
    fontFamily: "Roboto",
    fontVariants: "regular",
    align: "center",
    margin: {
      margin: 0,
      isPerSide: true,
      top: 20,
      bottom: 20,
      left: 0,
      right: 0,
    },
  },
  caption: {
    isVisible: false,
    items: [
      {
        text: "Chart description or data source",
        txtColor: "rgba(0,0,0,.5)",
        fontSize: 18,
        fontFamily: "Roboto",
        fontVariants: "300",
        align: "center",
        margin: {
          margin: 0,
          isPerSide: true,
          top: 20,
          bottom: 20,
          left: 0,
          right: 0,
        },
      },
    ],
  },
  legend: {
    isVisible: false,
    txtColor: "rgba(0,0,0,1)",
    fontSize: 14,
    iconSize: 16,
    layout: true,
    align: "center",
    margin: {
      margin: 0,
      isPerSide: true,
      top: 20,
      bottom: 20,
      left: 0,
      right: 0,
    },
  },
  tooltip: {
    isVisible: false,
    txtColor: "rgba(0,0,0,1)",
    bgColor: "rgba(255,255,255,1)",
  },
  dataWeights: {
    Weights: dataRaw.dataValues,
  },
  dataSeries: {
    isVisible: true,
    items: dataRaw.dataGroups,
  },
  dimensions: {
    Hierarchy: ControlType.String,
    Weights: ControlType.Number,
  },
};

applyPropertyControls(HierarchySunburst, {
  //Settings or data
  isData: {
    type: ControlType.Boolean,
    title: "Change",
    disabledTitle: "settings",
    enabledTitle: "data",
    wide: true,
  },

  layoutSettings: presetLayout,

  chartSettings: {
    type: ControlType.Group,
    title: "Chart settings",
    isHeaderControls: false,
    propertyControl: {
      xOffset: {
        type: ControlType.Number,
        title: "X offset",
        min: -1000,
        max: 1000,
      },
      yOffset: {
        type: ControlType.Number,
        title: "Y offset",
        min: -1000,
        max: 1000,
      },
      scale: {
        type: ControlType.Number,
        title: "Scale",
        min: 0,
        max: 200,
        unit: "%",
        // max: maxSize.width / 2,
      },
      units: {
        type: ControlType.String,
        title: "Units",
        placeholder: "%, $...",
      },

      //Paddings
      spacingRadial: {
        type: ControlType.Number,
        title: "Spacing",
        min: 0,
        displayStepper: true,
      },
      roundCorners: {
        type: ControlType.Number,
        title: "Round",
        min: 0,
        displayStepper: true,
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Colors
  colors: {
    type: ControlType.Group,
    title: "Colors",
    isHeaderControls: false,
    propertyControl: {
      colorsType: {
        type: ControlType.Boolean,
        title: "Process",
        enabledTitle: "scheme",
        disabledTitle: "custom",
      },
      colorsScheme: {
        type: ControlType.Enum,
        title: "↳ Scheme",
        options: ["#01 (12 colors)", "#02 (10 colors)", "#03 (8 colors)"],
        hidden(props) {
          return props.colorsType === false;
        },
      },
      colorSchemeOpacity: {
        type: ControlType.Number,
        title: "↳ Opacity",
        min: 0,
        max: 100,
        hidden(props) {
          return props.colorsType === false;
        },
      },
      colorsCustom: {
        type: ControlType.Array,
        title: "Custom colors",
        propertyControl: {
          type(props) {
            return ControlType.Color;
          },
        },
        hidden(props) {
          return props.colorsType === true;
        },
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Labels
  labels: {
    type: ControlType.Group,
    title: "Labels",
    isHeaderControls: true,
    propertyControl: {
      isVisible: {
        type: ControlType.Boolean,
        enabledTitle: "show",
        disabledTitle: "hide",
        hidden(props) {
          return true;
        },
      },
      labelsType: {
        type: ControlType.Boolean,
        title: "Type",
        enabledTitle: "along",
        disabledTitle: "across",
      },
      labelsLength: {
        type: ControlType.Boolean,
        title: "Length",
        enabledTitle: "cut",
        disabledTitle: "full",
      },
      titleColor: {
        type: ControlType.Color,
        title: "Color",
      },
      titleFontSize: {
        type: ControlType.Number,
        title: "Size",
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Summary label
  labelSummary: {
    type: ControlType.Group,
    title: "Summary label",
    isHeaderControls: true,
    propertyControl: {
      isVisible: {
        type: ControlType.Boolean,
        enabledTitle: "show",
        disabledTitle: "hide",
        hidden(props) {
          return true;
        },
      },
      labelColor: {
        type: ControlType.Color,
        title: "Color",
      },
      labelFontSize: {
        type: ControlType.Number,
        title: "Size",
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Title
  title: presetTitle,

  //Caption
  caption: presetCaption,

  //Legend
  legend: presetLegend,

  //Tooltip
  tooltip: presetTooltip(false),

  //Data
  sourceData: {
    type: ControlType.Boolean,
    title: "Data",
    enabledTitle: "editable",
    disabledTitle: "file",
    hidden(props) {
      return props.isData === false;
    },
  },
  fileData: {
    title: "↳ File",
    type: ControlType.File,
    allowedFileTypes: ["xls"],
    hidden(props) {
      return props.sourceData === true || props.isData === false;
    },
  },

  //Weights data
  dataWeights: {
    type: ControlType.Group,
    title: "Weights",
    propertyControl: {
      Weights: {
        type: ControlType.Array,
        title: "Weights",
        propertyControl: {
          type(props) {
            return props.dimensions.Weights;
          },
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },

  //Hierarchy Data
  dataSeries: {
    type: ControlType.Collection,
    title: "Hierarchy",
    propertyControl: {
      Hierarchy: {
        type: ControlType.Array,
        title: "Hierarchy",
        propertyControl: {
          type(props) {
            return props.dimensions.Hierarchy;
          },
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },

  //Chart's dimensions
  dimensions: {
    type: ControlType.Group,
    title: "Dimensions",
    propertyControl: {
      Hierarchy: {
        type: ControlType.Enum,
        seriesType: true,
        title: "Group by",
        path: "dataSeries",
        options: [ControlType.String, ControlType.Number],
      },
      Weights: {
        type: ControlType.Enum,
        seriesType: false,
        title: "Weights",
        path: "dataWeights",
        options: [ControlType.Number],
      },
    },
    hidden() {
      return true;
    },
  },
});

export default HierarchySunburst;
