import React from "react";
import { useSelector } from "react-redux";
import { InputNumber } from "./inputNumber";
import {
  TextField,
  Typography,
  Button,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import LZString from "lz-string";
import { diff } from "deep-object-diff";
import { getChartDefaultProps } from "../utils/utils";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export function Share() {
  const chartConfig = useSelector((state: any) => state.chart);
  const chartId = useSelector((state: any) => state.firebase.currentChartId);
  const isCurrentChartSaved = useSelector(
    (state: any) => state.firebase.isCurrentChartSaved
  );

  const [shareType, setShareType] = React.useState("Embed");

  const linkRef = React.useRef(null as any);
  const embedRef = React.useRef(null as any);

  function getConfigDiff() {
    let objNew = JSON.parse(JSON.stringify(chartConfig.chartSettings));
    let objOld = Object.assign(
      {},
      getChartDefaultProps(chartConfig.currentChartType)
    );

    // deep-object-diff compare default and current chart settings objects and return the difference
    // but we cannot compare arrays inside these objects correctly, so we remove them from default chart settings

    // remove arrays from objects
    // now we might have arrays only at first and second depth of hierarchy
    function removeArrays(obj) {
      for (let key in obj) {
        if (Array.isArray(obj[key])) {
          delete obj[key];
        } else if (typeof obj[key] === "object" && obj[key] !== null) {
          removeArrays(obj[key]);
        }
      }
    }

    removeArrays(objOld);

    //compare default settings and new
    const configDiff = diff(objOld, objNew);

    return LZString.compressToEncodedURIComponent(
      JSON.stringify({
        currentChartType: chartConfig.currentChartType,
        chartSettings: configDiff,
      })
    );
  }

  const shareURL =
    window.location.href +
    (chartId && isCurrentChartSaved
      ? "?chart=" + chartId
      : "?share=" + getConfigDiff());

  const isWidthAuto = chartConfig.chartSettings.layoutSettings.isWidthAuto;
  const isHeightAuto = chartConfig.chartSettings.layoutSettings.isHeightAuto;

  function copyLinkToClipboard(e): void {
    linkRef.current.select();
    document.execCommand("copy");
    e.target.focus();
  }

  function copyEmbedToClipboard(e): void {
    embedRef.current.select();
    document.execCommand("copy");
    e.target.focus();
  }

  const handleSetShareType = (type) => {
    setShareType(type);
  };

  const iframe =
    "<iframe src='" +
    shareURL +
    "' title='' frameborder='0' scrolling='no'></iframe>";

  const link = (
    <Grid container>
      <TextField
        id="shareURL"
        inputRef={linkRef}
        multiline
        maxRows={4}
        hiddenLabel
        value={shareURL}
        variant="filled"
        //   onChange={handleChange}
        style={{ width: "100%" }}
      />
      <Button
        variant="outlined"
        style={{ marginTop: 16 }}
        onClick={copyLinkToClipboard}
      >
        Copy URL to clipboard
      </Button>
    </Grid>
  );

  const embed = (
    <div>
      <TextField
        id="shareURL"
        inputRef={embedRef}
        multiline
        maxRows={4}
        hiddenLabel
        value={iframe}
        variant="filled"
        //   onChange={handleChange}
        style={{ width: "100%" }}
      />
      <Button
        variant="outlined"
        style={{ marginTop: 16 }}
        onClick={copyEmbedToClipboard}
      >
        Copy code to clipboard
      </Button>
    </div>
  );

  return (
    <>
      <Grid container alignItems="center" style={{ alignItems: "start" }}>
        <Typography variant="h4" style={{ marginBottom: 16 }}>
          Share your chart
        </Typography>
      </Grid>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Grid container alignItems="center" style={{ alignItems: "start" }}>
          <ToggleButtonGroup size="large" style={{ marginBottom: 16 }}>
            <ToggleButton
              disabled={shareType === "Link"}
              sx={{
                backgroundColor: "",
                transition: "background-color 250ms",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, .1)",
                },
              }}
              style={{
                padding: "0 8px 0 8px",
                minWidth: 40,
                textTransform: "none",
                color: shareType === "Link" ? "#fff" : "#333",
                backgroundColor: shareType === "Link" ? "#333" : "",
              }}
              color="primary"
              onClick={() => handleSetShareType("Link")}
              value="Link"
            >
              Link
            </ToggleButton>
            <ToggleButton
              disabled={shareType === "Embed"}
              sx={{
                backgroundColor: "",
                transition: "background-color 250ms",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, .1)",
                },
              }}
              style={{
                padding: "0 8px 0 8px",
                minWidth: 40,
                textTransform: "none",
                color: shareType === "Embed" ? "#fff" : "#333",
                backgroundColor: shareType === "Embed" ? "#333" : "",
              }}
              color="primary"
              onClick={() => handleSetShareType("Embed")}
              value="Embed"
            >
              Embed
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </div>
      {shareType === "Link" ? link : embed}
      {!chartId || !isCurrentChartSaved ? (
        <Grid container wrap="nowrap" spacing={2} style={{ marginTop: 24 }}>
          <Grid item>
            <Typography>
              <InfoOutlinedIcon />
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">
              Chart was not saved, so all data and settings are placed into url.
              It was compressed, but could be quite long (first of all, it
              depends on data size). So, if your url turned out more then 2 048
              symbols, some browsers would not resolve it. If less, test it. In
              some cases it would't work too (depends on browser and server
              settings). Anyway, if you want to share link or embed code, it's
              preferable to store your chart and get short link. This option is
              available for authentificated users.
            </Typography>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </>
  );
}

export default Share;
