import * as React from "react";
import { CustomizedDot } from "./customDot";

export const CustomLegend = ({ legendProps, items }) => {
  const { iconSize, layout, align, txtColor, fontSize, margin } = legendProps;

  const renderIcon = (item) => {
    const svgStyle = {
      display: "inline-block",
      verticalAlign: "middle",
      marginRight: 4,
    };

    return (
      <CustomizedDot
        cx={iconSize / 2}
        cy={iconSize / 2}
        dotsType={item.dotsType}
        dotsStyle={item.dotsStyle}
        dotsColor={item.dotsColor}
        dotsSize={iconSize}
        dotsStrokeWidth={item.dotsStrokeWidth}
        svgStyle={svgStyle}
      />
    );
  };

  const renderItems = () => {
    return items.map((item, i) => {
      return (
        <li
          style={{
            display: layout ? "inline-block" : "block",
            marginRight: i < items.length - 1 ? (layout ? 10 : 0) : 0,
          }}
          key={`legend-item-${i}`} // eslint-disable-line react/no-array-index-key
          // {...adaptEventsOfChild(this.props, entry, i)}
        >
          {renderIcon(items[i])}
          <span>{item.name || i + 1}</span>
        </li>
      );
    });
  };

  const finalStyle = {
    padding: 0,
    margin: 0,
    color: txtColor,
    fontSize: fontSize,
  };

  return (
    <div
      style={{
        textAlign: align,
        padding: margin.isPerSide
          ? margin.top +
            "px " +
            margin.right +
            "px " +
            margin.bottom +
            "px " +
            margin.left +
            "px"
          : margin.margin + "px",
      }}
    >
      <ul style={finalStyle}>{renderItems()}</ul>
    </div>
  );
};

export default CustomLegend;
