import { ControlType } from "../ui/controltype";
import { dataSimpsons } from "../../data/simpsons";

const dataSimpsonsFiltered = dataSimpsons.filter(
  (elem) => elem.imdb_rating !== null
);
dataSimpsonsFiltered.sort(function (a, b) {
  if (a.season === b.season) {
    return a.number_in_season < b.number_in_season
      ? -1
      : a.number_in_season > b.number_in_season
      ? 1
      : 0;
  } else {
    return a.season < b.season ? -1 : 1;
  }
});

const dimensions = {
  X: ControlType.String,
  Y: ControlType.String,
  Weights: ControlType.Number,
};

export const exampleHeatmap = {
  isData: false,
  layoutSettings: {
    isFullscreen: true,
    isWidthAuto: true,
    minWidth: 320,
    maxWidth: 2500,
    isHeightAuto: true,
    background: "rgba(254, 212, 29, 1)",
  },
  chartSettings: {
    margin: {
      margin: 40,
      isPerSide: false,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  sourceData: true,
  fileData: null,
  cells: {
    spacing: 0,
    colorStart: "rgba(254, 212, 29, 1)",
    colorEnd: "rgba(241, 78, 40, 1)",
    radius: 0,
  },
  labels: {
    isVisible: true,
    color: "rgba(0,0,0,.5)",
    fontSize: 12,
  },
  xAxis: {
    isVisible: true,
    orient: "top",
    height: 40,
    isTicksVisible: true,
    ticksColor: "rgba(0, 0, 0, 1)",
    ticksFontSize: 12,
    ticksRotate: 0,
    isLabelVisible: true,
    labelText: "Seasons",
    labelAlign: "middle",
    labelMargin: 20,
    labelColor: "rgba(0, 0, 0, 1)",
    labelFontSize: 12,
  },
  yAxis: {
    isVisible: true,
    orient: "left",
    width: 40,
    isTicksVisible: true,
    ticksColor: "rgba(0, 0, 0, 1)",
    ticksFontSize: 12,
    isLabelVisible: true,
    labelText: "Series",
    labelAlign: "center",
    labelMargin: 30,
    labelColor: "rgba(0, 0, 0, 1)",
    labelFontSize: 12,
    labelRotate: -90,
  },
  title: {
    isVisible: true,
    text: "The Simpsons episodes IMDb rating",
    txtColor: "rgba(0,0,0,1)",
    fontSize: 60,
    fontFamily: "Gochi Hand",
    fontVariants: "regular",
    align: "center",
    margin: {
      margin: 0,
      isPerSide: true,
      top: 20,
      bottom: 20,
      left: 0,
      right: 0,
    },
  },
  caption: {
    isVisible: true,
    items: [
      {
        isVisible: true,
        text: "Data by IMDb users rating",
        txtColor: "rgba(0,0,0,.5)",
        fontSize: 14,
        fontFamily: "Roboto",
        fontVariants: "300",
        align: "center",
        margin: {
          margin: 0,
          isPerSide: true,
          top: 0,
          bottom: 20,
          left: 0,
          right: 0,
        },
      },
    ],
  },
  legend: {
    isVisible: true,
    txtColor: "rgba(0,0,0,1)",
    fontSize: 14,
    width: 100,
    height: 10,
    align: "center",
    verticalAlign: "bottom",
    margin: {
      margin: 0,
      isPerSide: true,
      top: 0,
      bottom: 20,
      left: 0,
      right: 0,
    },
  },
  dataX: {
    X: dataSimpsonsFiltered.map((elem) => elem.season),
  },
  dataY: {
    Y: dataSimpsonsFiltered.map((elem) => elem.number_in_season),
  },
  dataWeights: {
    Weights: dataSimpsonsFiltered.map((elem) => elem.imdb_rating),
  },
  dimensions: dimensions,
};

export default exampleHeatmap;
