import { ControlType } from "../ui/controltype";
import { dataGroups, dataValues } from "../../data/usReligious";

export const exampleSunburst = {
  isData: false,
  layoutSettings: {
    isFullscreen: true,
    isWidthAuto: true,
    minWidth: 320,
    maxWidth: 2500,
    isHeightAuto: true,
    background: "rgba(255, 255, 255, 1)",
  },
  sourceData: true,
  fileData: null,
  chartSettings: {
    xOffset: 0,
    yOffset: 0,
    scale: 75,
    units: "%",
    spacingRadial: 10,
    roundCorners: 10,
  },
  colors: {
    colorsType: false,
    colorsScheme: "#01 (12 colors)",
    colorSchemeOpacity: 80,
    colorsCustom: [
      "rgba(229, 67, 132, .5)",
      "rgba(0, 0, 0, .1)",
      "rgba(70, 130, 200, .5)",
      "rgba(0, 0, 0, .75)",
    ],
  },
  labels: {
    isVisible: true,
    labelsType: false,
    labelsLength: false,
    titleColor: "rgba(0,0,0,.75)",
    titleFontSize: 10,
  },
  labelSummary: {
    isVisible: true,
    labelColor: "rgba(0,0,0,1)",
    labelFontSize: 24,
  },
  title: {
    isVisible: true,
    text: "Religious groups in the 🇺🇸 U.S.",
    txtColor: "rgba(0,0,0,1)",
    fontSize: 48,
    fontFamily: "Roboto",
    fontVariants: "regular",
    align: "center",
    margin: {
      margin: 0,
      isPerSide: true,
      top: 40,
      bottom: 20,
      left: 0,
      right: 0,
    },
  },
  caption: {
    isVisible: true,
    items: [
      {
        text: "Data by Pew Research Center",
        txtColor: "rgba(0,0,0,.5)",
        fontSize: 12,
        fontFamily: "Roboto",
        fontVariants: "300",
        align: "center",
        margin: {
          margin: 0,
          isPerSide: true,
          top: 20,
          bottom: 20,
          left: 0,
          right: 0,
        },
      },
    ],
  },
  legend: {
    isVisible: true,
    txtColor: "rgba(0,0,0,1)",
    fontSize: 14,
    layout: true,
    align: "center",
    margin: {
      margin: 0,
      isPerSide: true,
      top: 20,
      bottom: 20,
      left: 0,
      right: 0,
    },
    iconSize: 16,
  },
  tooltip: {
    isVisible: true,
    txtColor: "rgba(0,0,0,1)",
    bgColor: "rgba(255,255,255,1)",
  },
  dataWeights: {
    Weights: dataValues,
  },
  dataSeries: {
    isVisible: true,
    items: dataGroups,
  },
  dimensions: {
    Hierarchy: ControlType.String,
    Weights: ControlType.Number,
  },
};

export default exampleSunburst;
