export const UPDATE_CHART_SETTINGS = function (name, value) {
  return {
    type: "UPDATE_CHART_SETTINGS",
    name: name,
    value: value,
  };
};

export const UPDATE_DATA_TYPE_TO_NUMBER = function (value) {
  return {
    type: "UPDATE_DATA_TYPE_TO_NUMBER",
    value: value,
  };
};

export const UPDATE_CHART_TYPE = function (chartType) {
  return {
    type: "UPDATE_CHART_TYPE",
    value: chartType,
  };
};

export const ADD_ARRAY_ITEM = function (name, value) {
  return {
    type: "ADD_ARRAY_ITEM",
    name: name,
    value: value,
  };
};

export const REMOVE_ARRAY_ITEMS = function (name) {
  return {
    type: "REMOVE_ARRAY_ITEMS",
    name: name,
  };
};

export const UPDATE_ARRAY_ITEM_VISIBILITY = function (name) {
  return {
    type: "UPDATE_ARRAY_ITEM_VISIBILITY",
    name: name,
  };
};

export const UPDATE_FILE = function (value, fileType) {
  return {
    type: "UPDATE_FILE",
    value: value,
    fileType: fileType,
  };
};

export const OPEN_DRAWER = {
  type: "OPEN_DRAWER",
};

export const CLOSE_DRAWER = {
  type: "CLOSE_DRAWER",
};

export const OPEN_POPUP = function (value) {
  return {
    type: "OPEN_POPUP",
    value: value,
  };
};

export const CLOSE_POPUP = {
  type: "CLOSE_POPUP",
};

export const UPDATE_CHART_REF = function (value) {
  return {
    type: "UPDATE_CHART_REF",
    value: value,
  };
};

export const ADD_GROUP = function (name, value?) {
  return {
    type: "ADD_GROUP",
    name: name,
    value: value,
  };
};

export const REMOVE_GROUP = function (name, groupId) {
  return {
    type: "REMOVE_GROUP",
    name: name,
    value: groupId,
  };
};

export const TOGGLE_GROUP = function (name, groupId) {
  return {
    type: "TOGGLE_GROUP",
    name: name,
    value: groupId,
  };
};

export const TOGGLE_ALLGROUPS = function (name, isCollaps) {
  return {
    type: "TOGGLE_ALLGROUPS",
    name: name,
    value: isCollaps,
  };
};

export const UPDATE_GROUP_VISIBILITY = function (name, groupId) {
  return {
    type: "UPDATE_GROUP_VISIBILITY",
    name: name,
    value: groupId,
  };
};

export const SWAP_GROUPS = function (name, swapGroupId1, swapGroupId2) {
  return {
    type: "SWAP_GROUPS",
    name: name,
    value: [swapGroupId1, swapGroupId2],
  };
};

export const UPDATE_GOOGLEFONTS = function (fonts) {
  return {
    type: "UPDATE_GOOGLEFONTS",
    value: fonts,
  };
};

export const UPDATE_AUTH = function (value) {
  return {
    type: "UPDATE_AUTH",
    value: value,
  };
};

export const IS_USER_AUTH_COMPLETE = function (value) {
  return {
    type: "IS_USER_AUTH_COMPLETE",
    value: value,
  };
};

export const UPDATE_CURRENT_CHART_ID = function (value) {
  return {
    type: "UPDATE_CURRENT_CHART_ID",
    value: value,
  };
};

export const UPDATE_CURRENT_CHART_NAME = function (value) {
  return {
    type: "UPDATE_CURRENT_CHART_NAME",
    value: value,
  };
};

export const IS_CURRENT_CHART_SAVED = function (value) {
  return {
    type: "IS_CURRENT_CHART_SAVED",
    value: value,
  };
};

export const LOAD_CHART_SETTINGS_FROM_DB = function (value) {
  return {
    type: "LOAD_CHART_SETTINGS_FROM_DB",
    value: value,
  };
};
