import React from "react";
import {
  GoogleAuthProvider,
  signInWithCredential,
  signOut,
} from "firebase/auth";
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import "./App.css";
import { useSelector, useDispatch } from "react-redux";
import {
  OPEN_POPUP,
  UPDATE_CHART_TYPE,
  UPDATE_CURRENT_CHART_ID,
  UPDATE_CURRENT_CHART_NAME,
  IS_CURRENT_CHART_SAVED,
} from "./components/utils/actions";
import { Menu, MenuItem } from "@mui/material";

// Google Sign-In config
const clientId =
  "1069238930305-rgshm8jdanm2p4mc61oqo52042go7pau.apps.googleusercontent.com";
// const dataLoginUri = "http://localhost:3000";
const dataLoginUri = "https://getchart.online";

const UserPick = () => {
  const currentChartType = useSelector(
    (state: any) => state.chart.currentChartType
  );
  const currentChartId = useSelector(
    (state: any) => state.firebase.currentChartId
  );
  const isCurrentChartSaved = useSelector(
    (state: any) => state.firebase.isCurrentChartSaved
  );

  const [anchorEl, setAnchorEl] = React.useState(null);

  const dispatch = useDispatch();
  const auth = getAuth();

  const handleProfile = () => {
    handleCloseMenu();
    dispatch(OPEN_POPUP({ content: "Profile" }));
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleSignOut = () => {
    if (currentChartId && !isCurrentChartSaved) {
      dispatch(
        OPEN_POPUP({
          content: "Save chart alert",
          action: "Sign out",
          newChartType: currentChartType,
        })
      );
    } else {
      dispatch(UPDATE_CHART_TYPE(currentChartType));
      dispatch(UPDATE_CURRENT_CHART_ID(null));
      dispatch(UPDATE_CURRENT_CHART_NAME(""));
      dispatch(IS_CURRENT_CHART_SAVED(false));
      signOut(auth)
        .then(() => {
          // Sign-out successful
          // google
          if (window !== undefined && window.google !== undefined) {
            window.google.accounts.id.disableAutoSelect();
          }
        })
        .catch((error) => {
          // An error happened
          console.log("Error while sign out: ", error);
        });
    }
    handleCloseMenu();
  };

  return (
    <>
      <img
        onClick={handleClick}
        width={40}
        height={40}
        src={
          auth.currentUser && auth.currentUser.photoURL
            ? auth.currentUser.photoURL
            : ""
        }
        referrerPolicy="no-referrer"
        style={{ borderRadius: 40, cursor: "pointer" }}
      />
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        // getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: -4, horizontal: "center" }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={handleProfile}>Profile</MenuItem>
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu>
    </>
  );
};

// Adds new user to firestore collection "users". If user xists, it will be updated.
async function addNewUser(userCredential, db, collectionUsers) {
  try {
    // Check, if user exist
    getDoc(doc(db, collectionUsers, userCredential.user.uid)).then((result) => {
      if (result.exists()) {
        return;
      } else {
        // If new user, add document
        try {
          setDoc(doc(db, collectionUsers, userCredential.user.uid), {
            uid: userCredential.user.uid,
            name: userCredential.user.displayName,
            email: userCredential.user.email,
            userGroup: doc(db, "userGroups", "AYP54XVqsVIGg2Xzyppz"), // reference to starter user group
          });
        } catch (e) {
          console.error("Error adding document: ", e);
        }
      }
    });
  } catch (e) {
    console.error("Error reading document: ", e);
  }
}

// Google sign-in
export const GoogleSignin = () => {
  const isSignedIn = useSelector((state: any) => state.firebase.isSignedIn);
  const isAuthComplete = useSelector(
    (state: any) => state.firebase.isAuthComplete
  );
  const collectionUsers = useSelector(
    (state: any) => state.firebase.collectionUsers
  );

  // if isAuthComplete === false, then first page load authentification is not complete
  const [isAuthProcess, setIsAuthProcess] = React.useState(!isAuthComplete);

  const auth = getAuth();
  const db = getFirestore();
  const googleButtonRef = React.useRef(null);

  // get auth response
  function handleCredentialResponse(response) {
    setIsAuthProcess(true); // start loader

    // Build Firebase credential with the Google ID token.
    const idToken = response.credential;
    const credential = GoogleAuthProvider.credential(idToken);

    // Sign in with credential from the Google user.
    signInWithCredential(auth, credential)
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
        console.log(errorCode, errorMessage, email, credential);
      })
      .then((userCredential) => {
        if (userCredential !== undefined) {
          addNewUser(userCredential, db, collectionUsers);
        }
      })
      .then(() => setIsAuthProcess(false));

    //decode response
    function parseJwt(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    }
  }

  React.useEffect(() => {
    if (isAuthComplete) {
      setIsAuthProcess(false);
    }
  }, [isAuthComplete]);

  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.id = "google-client-script";
    const buttonRef = googleButtonRef.current!;
    script.onload = () => {
      if (
        window !== undefined &&
        window.google !== undefined &&
        buttonRef !== null
      ) {
        window.google.accounts.id.initialize({
          client_id: clientId,
          callback: handleCredentialResponse, // get auth response
        });
        window.google.accounts.id.renderButton(
          buttonRef,
          { type: "icon", shape: "circle" } // customization attributes
        );
      }
      // TODO - HACK - removing link to stylesheet to prevent cross-origin error while saving chart as image
      const link = document.getElementById(
        "googleidentityservice"
      ) as HTMLLinkElement;
      if (link) {
        link.remove();
      }
    };
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <>
      <div
        ref={googleButtonRef}
        style={{
          display: isSignedIn || isAuthProcess ? "none" : "block",
          width: 40,
          height: 40,
        }}
      />
      <div
        data-client_id={clientId}
        data-context="signin"
        data-ux_mode="popup"
        data-login_uri={dataLoginUri}
        data-auto_prompt="false"
        style={{ width: 0, height: 0 }}
      ></div>
      {isSignedIn ? (
        <UserPick />
      ) : isAuthProcess ? (
        <div style={{ borderRadius: 40 }}>
          <span className="loader" />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default GoogleSignin;
