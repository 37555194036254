import React, { useState, useEffect } from "react";
import { ControlType, applyPropertyControls } from "../ui/controltype";
import { Tooltip } from "recharts";
import * as d3 from "d3";
import * as d3Sankey from "d3-sankey";
import { useDispatch } from "react-redux";
import { UPDATE_CHART_REF } from "../utils/actions";
import { Helmet } from "react-helmet";
import {
  presetLayout,
  presetTitle,
  presetCaption,
  presetTooltip,
} from "./propertyControlsPresets";
import { TextBlock } from "./textBlock";
import { CustomizedTooltip } from "./customTooltip";
import { nanoid } from "nanoid";
import { useHeaderHeight } from "./customHooks";

const defaultData = [
  {
    source: "A",
    target: "B",
    value: 100,
  },
  {
    source: "C",
    target: "B",
    value: 100,
  },
  {
    source: "C",
    target: "D",
    value: 100,
  },
  {
    source: "C",
    target: "E",
    value: 100,
  },
];

/* Cycle Related computations - from https://gist.github.com/cfergus/3956043 */

function markCycles(links) {
  // ideally, find the 'feedback arc set' and remove them.
  // This way is expensive, but should be fine for small numbers of links
  var cycleMakers: any = [];
  var addedLinks = new Array();
  links.forEach(function (link) {
    if (createsCycle(link.source, link.target, addedLinks)) {
      link.causesCycle = true;
      link.cycleIndex = cycleMakers.length;
      cycleMakers.push(link);
    } else {
      addedLinks.push(link);
    }
  });
  return links;
}

function createsCycle(originalSource, nodeToCheck, graph) {
  if (graph.length === 0) {
    return false;
  }

  var nextLinks: any = findLinksOutward(nodeToCheck, graph);
  // leaf node check
  if (nextLinks.length === 0) {
    return false;
  }

  // cycle check
  for (var i = 0; i < nextLinks.length; i++) {
    var nextLink = nextLinks[i];

    if (nextLink.target === originalSource) {
      return true;
    }

    // Recurse
    if (createsCycle(originalSource, nextLink.target, graph)) {
      return true;
    }
  }

  // Exhausted all links
  return false;
}

/* Given a node, find all links for which this is a source
           in the current 'known' graph  */
function findLinksOutward(node, graph) {
  var children: any = [];

  for (var i = 0; i < graph.length; i++) {
    if (node === graph[i].source) {
      children.push(graph[i]);
    }
  }

  return children;
}

function CustomNode(props) {
  const {
    x,
    y,
    width,
    height,
    color,
    labels,
    colorMode,
    stroke,
    strokeWidth,
    strokeColorMode,
    strokeColor,
    node,
    units,
    handleTooltipOn,
    handleTooltipOff,
    handleTooltipCoordinates,
  } = props;

  const isNodeWrong = isNaN(x);
  const nodeHeight = height <= 0 || isNaN(height) ? 1 : height; // check if node height is correct

  const labelsGap = 8;
  const labelX = x + width / 2;
  const labelY = y + nodeHeight / 2;
  const isFirstColumn = node.depth === 0;
  const isLastColumn = node.sourceLinks.length === 0;
  const isLabelsOut = labels.location;
  const labelDelta =
    (width / 2 + labels.padding + (stroke ? strokeWidth / 2 : 0)) *
    ((isFirstColumn && isLabelsOut) || (isLastColumn && !isLabelsOut) ? -1 : 1);
  const textAlign =
    (isFirstColumn && isLabelsOut) || (isLastColumn && !isLabelsOut)
      ? "end"
      : "start";

  return isNodeWrong ? null : (
    <>
      <rect
        x={x}
        y={y}
        width={width}
        height={nodeHeight}
        fill={colorMode ? node.color : color}
        strokeWidth={stroke ? strokeWidth : 0}
        stroke={
          strokeColorMode ? (colorMode ? node.color : color) : strokeColor
        }
        onMouseOver={(e) => handleTooltipOn(e, node, "node")}
        onMouseOut={() => handleTooltipOff()}
        onMouseMove={(e) => handleTooltipCoordinates(e)}
      />
      {labels.isVisible ? (
        <text
          x={labelX}
          y={labelY}
          dx={labelDelta}
          dy={labels.showValues ? -labelsGap / 2 : 0}
          fontSize={labels.fontSize + "px"}
          fill={labels.color}
          color={labels.color}
          textAnchor={textAlign}
          alignmentBaseline={labels.showValues ? "baseline" : "mathematical"}
        >
          {node.name}
        </text>
      ) : (
        ""
      )}
      {labels.isVisible && labels.showValues ? (
        <text
          x={labelX}
          y={labelY}
          dx={labelDelta}
          dy={labelsGap / 2}
          fontSize={labels.valuesFontSize + "px"}
          fill={labels.valuesColor}
          color={labels.valuesColor}
          textAnchor={textAlign}
          alignmentBaseline="hanging"
        >
          {node.value.toLocaleString() + units}
        </text>
      ) : (
        ""
      )}
    </>
  );
}

function CustomLink(props) {
  const {
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourceControlX,
    targetControlX,
    colorMode,
    color,
    gradientOpacity,
    nodesColorMode,
    nodesColor,
    link,
    handleTooltipOn,
    handleTooltipOff,
    handleTooltipCoordinates,
  } = props;

  const isLinkWrong = isNaN(sourceX) || isNaN(targetX);

  const sourceColor = nodesColorMode ? link.source.color : nodesColor;
  const targetColor = nodesColorMode ? link.target.color : nodesColor;

  const d = `
  M${sourceX},${sourceY}
  C${sourceControlX},${sourceY} ${targetControlX},${targetY} ${targetX},${targetY}
  `;

  const gradientId = "linkGradient-" + link.index;

  return isLinkWrong ? null : (
    <>
      {colorMode ? (
        <defs>
          <linearGradient
            id={gradientId}
            gradientUnits="userSpaceOnUse"
            x1={sourceX}
            x2={targetX}
          >
            <stop offset="0%" stopColor={sourceColor} />
            <stop offset="100%" stopColor={targetColor} />
          </linearGradient>
        </defs>
      ) : (
        ""
      )}
      <path
        d={d}
        strokeWidth={link.width}
        fill="transparent"
        stroke={colorMode ? "url('#" + gradientId + "')" : color}
        strokeOpacity={colorMode ? gradientOpacity / 100 : 1}
        onMouseOver={(e) => handleTooltipOn(e, link, "link")}
        onMouseOut={() => handleTooltipOff()}
        onMouseMove={(e) => handleTooltipCoordinates(e)}
      />
    </>
  );
}

function SankeyChart(props) {
  const {
    data,
    width,
    height,
    margin,
    linksSettings,
    nodesSettings,
    labelsSettings,
    units,
    handleTooltipOn,
    handleTooltipOff,
    handleTooltipCoordinates,
  } = props;

  const nodeAlign = {
    left: d3Sankey.sankeyLeft,
    right: d3Sankey.sankeyRight,
    center: d3Sankey.sankeyCenter,
    justify: d3Sankey.sankeyJustify,
  };

  const sankey = d3Sankey
    .sankey()
    .nodeId((d) => d.id)
    .nodeAlign(nodeAlign[nodesSettings.align])
    .nodeSort(null)
    .nodeWidth(nodesSettings.width)
    .nodePadding(nodesSettings.padding)
    .extent([
      [0 + margin.left, 0 + margin.top],
      [width - margin.right, height - margin.bottom],
    ]);

  const { nodes, links } = sankey(data);

  const interpolationGenerator = (a: number, b: number) => {
    const ka = +a;
    const kb = b - ka;
    return (t: any) => ka + kb * t;
  };

  const linkCurvature = 0.5;

  return (
    <svg width={width} height={height}>
      <g>
        {links.map((d, i) => {
          const sourceX = d.source.x0;
          const sourceY = d.y0;
          const targetX = d.target.x0;
          const targetY = d.y1;
          const interpolationFunc = interpolationGenerator(sourceX, targetX);
          return (
            <CustomLink
              key={"link-" + i}
              sourceX={sourceX}
              sourceY={sourceY}
              targetX={targetX}
              targetY={targetY}
              sourceControlX={interpolationFunc(linkCurvature)}
              targetControlX={interpolationFunc(1 - linkCurvature)}
              colorMode={linksSettings.colorMode}
              color={linksSettings.color}
              gradientOpacity={linksSettings.gradientOpacity}
              nodesColorMode={nodesSettings.colorMode}
              nodesColor={nodesSettings.color}
              link={d}
              handleTooltipOn={handleTooltipOn}
              handleTooltipOff={handleTooltipOff}
              handleTooltipCoordinates={handleTooltipCoordinates}
            />
          );
        })}
      </g>
      <g>
        {nodes.map((d, i) => (
          <CustomNode
            key={"node-" + i}
            index={d.index}
            x={d.x0}
            y={d.y0}
            width={d.x1 - d.x0}
            height={d.y1 - d.y0}
            color={nodesSettings.color}
            labels={labelsSettings}
            colorMode={nodesSettings.colorMode}
            stroke={nodesSettings.stroke}
            strokeWidth={nodesSettings.strokeWidth}
            strokeColorMode={nodesSettings.strokeColorMode}
            strokeColor={nodesSettings.strokeColor}
            node={d}
            units={units}
            handleTooltipOn={handleTooltipOn}
            handleTooltipOff={handleTooltipOff}
            handleTooltipCoordinates={handleTooltipCoordinates}
          />
        ))}
      </g>
    </svg>
  );
}

export function Sankey(props) {
  const {
    layoutSettings,
    chartSettings,
    labelsSettings,
    linksSettings,
    nodesSettings,
    nodes,
    nodesValues,
    linksValues,
    title,
    caption,
    tooltip,
  } = props;

  const { background, width } = layoutSettings;
  const { margin } = chartSettings;

  const [isTolltipActive, setIsTolltipActive] = useState(false);
  const [tooltipContent, setTooltipContent] = useState({
    name: "",
    value: 0,
  });
  const [tooltipCoordinates, setTooltipCoordinates] = useState({
    x: 0,
    y: 0,
  });

  const dispatch = useDispatch();

  // filter wrong/incorrect/empty links
  const linksValuesFiltered = linksValues.items.filter((link) => {
    const nodesIds = nodesValues.items.map((d) => d.id);
    const isLinkCorrectSource =
      nodesIds.find((elem) => elem === link.nodesIdSource) === undefined;
    const isLinkCorrectTarget =
      nodesIds.find((elem) => elem === link.nodesIdTarget) === undefined;
    const isLinkVoid = link.nodesIdSource === link.nodesIdTarget; // if source and target nodes are equal
    const isLinkEmpty = link.value <= 0;

    return (
      !isLinkCorrectSource &&
      !isLinkCorrectTarget &&
      !isLinkVoid &&
      !isLinkEmpty
    );
  });

  let data: any =
    nodes.items.length > 0 &&
    nodesValues.items.length > 0 &&
    linksValues.items.length > 0
      ? {
          nodes: nodes.items.map((d, i) => {
            const id = nodesValues.items[i].id || "node-" + i;
            return {
              name:
                nodesValues.items[i].name ||
                String.fromCharCode("A".charCodeAt(0) + i),
              id: id,
              color: nodes.items[i].color,
            };
          }),
          links: linksValuesFiltered.map((d, i) => {
            const nodesIds = nodesValues.items.map((elem) => elem.id);
            const sourceId = d.nodesIdSource;
            const targetId = d.nodesIdTarget;
            return {
              source: sourceId,
              target: targetId,
              value: d.value,
            };
          }),
        }
      : null;

  // check for cycle links and remove them
  if (data) {
    const markedLinks = markCycles(data.links);
    const filteredLinks = markedLinks.filter((elem) => !elem.causesCycle);
    data = { ...data, links: filteredLinks };
  }

  const [fontLoaded, setFontLoaded] = React.useState(false);

  const chartRef = React.useRef<HTMLDivElement>(null);
  const { headerRef, height } = useHeaderHeight(
    width,
    layoutSettings,
    caption,
    title,
    {},
    ""
  );

  // custom fonts loading status
  title["fontLoaded"] = fontLoaded;
  for (let i = 0; i < caption.items.length; i++) {
    caption.items[i]["fontLoaded"] = fontLoaded;
  }

  useEffect(() => {
    document.fonts.ready.then(() => {
      setFontLoaded(true);
    });
  });

  useEffect(() => {
    const element = chartRef.current;
    if (element) {
      const svg = element;
      dispatch(UPDATE_CHART_REF(svg));
    }
  }, [chartRef, dispatch]);

  const handleTooltipOn = (e, d, type) => {
    setIsTolltipActive(true);
    setTooltipContent({
      name: type === "link" ? d.source.name + " → " + d.target.name : d.name,
      value: d.value,
    });
  };
  const handleTooltipOff = () => {
    setIsTolltipActive(false);
    setTooltipContent({ name: "", value: 0 });
  };
  const handleTooltipCoordinates = (props) => {
    setTooltipCoordinates({ x: props.clientX, y: props.clientY });
  };

  return (
    <div ref={chartRef} style={{ background: background }}>
      <Helmet>
        <link
          rel="stylesheet"
          href={
            "https://fonts.googleapis.com/css?family=" +
            title.fontFamily.replace(/ /g, "+") +
            ":" +
            title.fontVariants
          }
          crossOrigin="anonymous"
        />
        {caption.items.map((elem, i) => {
          return (
            <link
              key={"caption-" + i}
              rel="stylesheet"
              href={
                "https://fonts.googleapis.com/css?family=" +
                elem.fontFamily.replace(/ /g, "+") +
                ":" +
                elem.fontVariants
              }
              crossOrigin="anonymous"
            />
          );
        })}
      </Helmet>
      <div ref={headerRef} style={{ width: width }}>
        {title.isVisible ? (
          <TextBlock key={"title"} title={title} fontLoaded={fontLoaded} />
        ) : (
          ""
        )}
        {caption.isVisible
          ? caption.items.map((elem, i) => {
              return (
                <TextBlock
                  key={"caption" + i}
                  title={elem}
                  fontLoaded={fontLoaded}
                />
              );
            })
          : ""}
      </div>
      {data !== null ? (
        <SankeyChart
          data={data}
          width={width}
          height={height}
          margin={
            margin.isPerSide
              ? {
                  top: margin.top,
                  right: margin.right,
                  left: margin.left,
                  bottom: margin.bottom,
                }
              : {
                  top: margin.margin,
                  right: margin.margin,
                  left: margin.margin,
                  bottom: margin.margin,
                }
          }
          nodesSettings={nodesSettings}
          linksSettings={linksSettings}
          labelsSettings={labelsSettings}
          units={chartSettings.units}
          handleTooltipOn={handleTooltipOn}
          handleTooltipOff={handleTooltipOff}
          handleTooltipCoordinates={handleTooltipCoordinates}
        />
      ) : (
        <span style={{ opacity: 0.25 }}>Not enough data to chart</span>
      )}
      {tooltip.isVisible ? (
        <Tooltip
          active={isTolltipActive}
          viewBox={{ x: 0, y: 0, width: width, height: height }}
          coordinate={{ x: tooltipCoordinates.x, y: tooltipCoordinates.y }}
          separator={tooltip.separator}
          payload={[
            {
              name: tooltipContent.name,
              value: tooltipContent.value,
            },
          ]}
          content={
            <CustomizedTooltip
              chartType={"sankey"}
              txtColor={tooltip.txtColor}
              background={tooltip.bgColor}
              units={chartSettings.units}
            />
          }
        />
      ) : (
        ""
      )}
    </div>
  );
}

let nodesSet = new Set();
for (let i = 0; i < defaultData.length; i++) {
  nodesSet.add(defaultData[i].source);
  nodesSet.add(defaultData[i].target);
}

const nodesArr = Array.from(nodesSet);

// clone nodes id, which are random generated to links array
function cloneId(i, value) {
  const nodeName = nodesArr[i]; // get the node name from default data
  let linksSource: number[] = []; // get all indexes of default data array where node is source
  let linksTarget: number[] = []; // ...is target
  for (let i = 0; i < defaultData.length; i++) {
    if (defaultData[i].source === nodeName) {
      linksSource.push(i);
    }
    if (defaultData[i].target === nodeName) {
      linksTarget.push(i);
    }
  }

  // fill linkValues with nodes unique ids
  // sources
  for (let i = 0; i < linksSource.length; i++) {
    const defaultLinkIndex = linksSource[i];
    Sankey.defaultProps.linksValues.items[defaultLinkIndex].nodesIdSource =
      value;
  }
  // targets
  for (let i = 0; i < linksTarget.length; i++) {
    const defaultLinkIndex = linksTarget[i];
    Sankey.defaultProps.linksValues.items[defaultLinkIndex].nodesIdTarget =
      value;
  }
}

const dimensions = {
  source: ControlType.String,
  target: ControlType.String,
  value: ControlType.Number,
};

Sankey.defaultProps = {
  isData: false,
  layoutSettings: {
    isFullscreen: true,
    isWidthAuto: true,
    minWidth: 320,
    maxWidth: 2500,
    isHeightAuto: true,
    background: "rgba(255, 255, 255, 1)",
  },
  chartSettings: {
    units: "",
    margin: {
      margin: 100,
      isPerSide: false,
      top: 160,
      bottom: 100,
      left: 40,
      right: 40,
    },
  },
  labelsSettings: {
    isVisible: false,
    fontSize: 14,
    color: "rgba(17, 153, 238, 1)",
    padding: 8,
    location: false,
    showValues: false,
    valuesFontSize: 12,
    valuesColor: "rgba(17, 153, 238, .75)",
  },
  linksSettings: {
    colorMode: false,
    color: "rgba(17, 153, 238, .25)",
    gradientInfo: "Choose 'unique' in nodes color settings",
    gradientOpacity: 25,
  },
  nodesSettings: {
    padding: 100,
    width: 16,
    align: "justify",
    colorMode: false,
    color: "rgba(17, 153, 238, 1)",
    stroke: false,
    strokeWidth: 1,
    strokeColorMode: false,
    strokeColor: "rgba(0,0,0,1)",
  },
  nodes: {
    isVisible: true,
    items: nodesArr.map((d, i) => {
      const color = d3.scaleOrdinal(d3.schemePaired).domain(nodesArr);
      return {
        color: color(d),
      };
    }),
  },
  nodesValues: {
    isVisible: true,
    items: nodesArr.map((d, i) => {
      return {
        name: "",
        get id() {
          const v = nanoid(10);
          cloneId(i, v);
          return v;
        },
      };
    }),
  },
  linksValues: {
    isVisible: true,
    items: defaultData.map((d, i) => {
      return {
        value: d.value,
        nodesIdSource: "",
        nodesIdTarget: "",
      };
    }),
  },
  title: {
    isVisible: false,
    text: "Chart title",
    txtColor: "rgba(0,0,0,1)",
    fontSize: 48,
    fontFamily: "Roboto",
    fontVariants: "regular",
    align: "center",
    margin: {
      margin: 0,
      isPerSide: true,
      top: 20,
      bottom: 20,
      left: 0,
      right: 0,
    },
  },
  caption: {
    isVisible: false,
    items: [
      {
        text: "Chart description or data source",
        txtColor: "rgba(0,0,0,.5)",
        fontSize: 18,
        fontFamily: "Roboto",
        fontVariants: "300",
        align: "center",
        margin: {
          margin: 0,
          isPerSide: true,
          top: 20,
          bottom: 20,
          left: 0,
          right: 0,
        },
      },
    ],
  },
  tooltip: {
    isVisible: false,
    separator: ": ",
    txtColor: "rgba(0,0,0,1)",
    bgColor: "rgba(255,255,255,1)",
  },
  sourceData: true,
  fileData: null,
  dimensions: dimensions,
};

applyPropertyControls(Sankey, {
  //Settings or data
  isData: {
    type: ControlType.Boolean,
    title: "Change",
    disabledTitle: "settings",
    enabledTitle: "data",
    wide: true,
  },

  layoutSettings: presetLayout,

  chartSettings: {
    type: ControlType.Group,
    title: "Chart settings",
    isHeaderControls: false,
    propertyControl: {
      units: {
        type: ControlType.String,
        title: "Units",
        placeholder: "%, $...",
      },
      margin: {
        type: ControlType.FusedNumber,
        title: "Margin",
        toggleKey: "isPerSide",
        toggleTitles: ["All", "Individual"],
        valueKeys: ["top", "right", "bottom", "left"],
        valueLabels: ["T", "R", "B", "L"],
        min: 0,
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  labelsSettings: {
    type: ControlType.Group,
    title: "Labels",
    isHeaderControls: true,
    propertyControl: {
      isVisible: {
        type: ControlType.Boolean,
        enabledTitle: "show",
        disabledTitle: "hide",
        hidden(props) {
          return true;
        },
      },
      fontSize: {
        type: ControlType.Number,
        title: "Font size",
      },
      color: {
        type: ControlType.Color,
        title: "Color",
      },
      padding: {
        type: ControlType.Number,
        title: "Padding",
      },
      location: {
        type: ControlType.Boolean,
        title: "Location",
        disabledTitle: "inside",
        enabledTitle: "out",
      },
      showValues: {
        type: ControlType.Boolean,
        title: "Values",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      valuesFontSize: {
        type: ControlType.Number,
        title: "↳ Font size",
        hidden(props) {
          return props.showValues === false;
        },
      },
      valuesColor: {
        type: ControlType.Color,
        title: "↳ Color",
        hidden(props) {
          return props.showValues === false;
        },
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  linksSettings: {
    type: ControlType.Group,
    title: "Links settings",
    isHeaderControls: false,
    propertyControl: {
      colorMode: {
        type: ControlType.Boolean,
        title: "Colors",
        disabledTitle: "one for all",
        enabledTitle: "gradients",
      },
      color: {
        type: ControlType.Color,
        title: "↳ Color",
        hidden(props) {
          return props.colorMode === true;
        },
      },
      gradientInfo: {
        type: ControlType.Status,
        title: " ",
        hidden(props) {
          return props.colorMode === false;
        },
      },
      gradientOpacity: {
        type: ControlType.Number,
        title: "↳ Opacity",
        min: 0,
        max: 100,
        unit: "%",
        hidden(props) {
          return props.colorMode === false;
        },
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  nodesSettings: {
    type: ControlType.Group,
    title: "Nodes settings",
    isHeaderControls: false,
    propertyControl: {
      padding: {
        type: ControlType.Number,
        title: "Padding",
        min: 0,
      },
      width: {
        type: ControlType.Number,
        title: "Width",
        min: 0,
      },
      align: {
        type: ControlType.Enum,
        title: "Align",
        options: ["left", "right", "center", "justify"],
      },
      colorMode: {
        type: ControlType.Boolean,
        title: "Fill colors",
        disabledTitle: "one for all",
        enabledTitle: "unique",
      },
      color: {
        type: ControlType.Color,
        title: "↳ Color",
        hidden(props) {
          return props.colorMode === true;
        },
      },
      stroke: {
        type: ControlType.Boolean,
        title: "Stroke",
        disabledTitle: "hide",
        enabledTitle: "show",
      },
      strokeWidth: {
        type: ControlType.Number,
        title: "↳ Width",
        min: 0,
        hidden(props) {
          return props.stroke === false;
        },
      },
      strokeColorMode: {
        type: ControlType.Boolean,
        title: "↳ Color",
        disabledTitle: "custom",
        enabledTitle: "inherit",
        hidden(props) {
          return props.stroke === false;
        },
      },
      strokeColor: {
        type: ControlType.Color,
        title: "　↳ Color",
        hidden(props) {
          return props.stroke === false || props.strokeColorMode === true;
        },
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  // Nodes
  nodes: {
    type: ControlType.Collection,
    title: "Nodes",
    indexType: "letters",
    ref: "nodesValues",
    propertyControl: {
      color: {
        title: "Color",
        type: ControlType.Color,
      },
    },
    hidden(props) {
      return props.isData === true || props.nodesSettings.colorMode === false;
    },
  },

  //Title
  title: presetTitle,

  //Caption
  caption: presetCaption,

  //Tooltip
  tooltip: {
    ...presetTooltip(false),
    propertyControl: {
      separator: {
        type: ControlType.String,
        title: "Separator",
      },
      ...presetTooltip(false).propertyControl,
    },
  },

  //Data
  sourceData: {
    type: ControlType.Boolean,
    title: "Data",
    enabledTitle: "editable",
    disabledTitle: "file",
    hidden(props) {
      return props.isData === false;
    },
  },
  fileData: {
    title: "↳ File",
    type: ControlType.File,
    allowedFileTypes: ["xls"],
    hidden(props) {
      return props.sourceData === true || props.isData === false;
    },
  },

  // Nodes data
  nodesValues: {
    type: ControlType.Collection,
    title: "Nodes",
    indexType: "letters",
    ref: "nodes",
    propertyControl: {
      name: {
        title: "Name",
        type: ControlType.String,
        placeholder: "change default name",
      },
      id: {
        type: ControlType.Status,
        title: "Id",
        placeholder: "id for node",
        hidden(props) {
          return true;
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },

  // Links data
  // If you change "linkvalues" structure, look at buildSankeyData in filePreviewTableSettings
  // Probably, you'll need to change it too
  linksValues: {
    type: ControlType.Collection,
    title: "Links",
    indexType: "numbers",
    propertyControl: {
      value: {
        type: ControlType.Number,
        title: "Value",
      },
      nodesIdSource: {
        type: ControlType.Enum,
        title: "Source",
        options(props) {
          const optionsArr = props.nodesValues.items.map((elem) => elem.id); // get list of values
          return optionsArr;
        },
        optionTitles(props) {
          const titlesArr = props.nodesValues.items.map(
            (elem, i) => elem.name || String.fromCharCode("A".charCodeAt(0) + i)
          );
          return titlesArr;
        },
      },
      nodesIdTarget: {
        type: ControlType.Enum,
        title: "Target",
        options(props) {
          const optionsArr = props.nodesValues.items.map((elem) => elem.id); // get list of values
          return optionsArr;
        },
        optionTitles(props) {
          const titlesArr = props.nodesValues.items.map(
            (elem, i) => elem.name || String.fromCharCode("A".charCodeAt(0) + i)
          );
          return titlesArr;
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },

  // Chart's dimensions
  // !!!
  // "source", "target" and "value" are hardcoded names for sankey chart
  // they used to get and transform data, do not rename them
  // !!!
  dimensions: {
    type: ControlType.Group,
    title: "Dimensions",
    propertyControl: {
      source: {
        type: ControlType.Enum,
        seriesType: false,
        title: "Source",
        path: "linksValues",
        options: [ControlType.String, ControlType.Number],
      },
      target: {
        type: ControlType.Enum,
        seriesType: false,
        title: "Target",
        path: "linksValues",
        options: [ControlType.String, ControlType.Number],
      },
      value: {
        type: ControlType.Enum,
        seriesType: false,
        title: "Value",
        path: "linksValues",
        options: [ControlType.Number],
      },
    },
    hidden() {
      return true;
    },
  },
});

export default Sankey;
