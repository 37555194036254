import React, { useEffect } from "react";
import { ControlType, applyPropertyControls } from "../ui/controltype";
import { PieChart, Pie, Cell } from "recharts";
import { TextBlock } from "./textBlock";
import { useDispatch } from "react-redux";
import { UPDATE_CHART_REF } from "../utils/actions";
import { Helmet } from "react-helmet";
import {
  presetLayout,
  presetTitle,
  presetCaption,
} from "./propertyControlsPresets";
import { useHeaderHeight } from "./customHooks";

const RenderCustomizedLabel = (props) => {
  const { cx, cy, index, percent, fill, fontSize } = props;

  return index === 0 ? (
    <text
      x={cx}
      y={cy}
      fontSize={fontSize}
      fill={fill}
      color={fill}
      textAnchor="middle"
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  ) : null;
};

const RenderCustomizedLabelLine = (props) => {
  const {
    cx,
    cy,
    index,
    markValue,
    markSize,
    markColor,
    markStrokeValue,
    innerRadius,
    outerRadius,
    startAngle,
  } = props;

  const RADIAN = Math.PI / 180;
  const angle = (markValue * 360) / 100 - startAngle;

  const centerRadius = innerRadius + (outerRadius - innerRadius) / 2;

  const x1 = cx + (centerRadius - markSize / 2) * Math.cos(angle * RADIAN);
  const y1 = cy + (centerRadius - markSize / 2) * Math.sin(angle * RADIAN);
  const x2 = cx + (centerRadius + markSize / 2) * Math.cos(angle * RADIAN);
  const y2 = cy + (centerRadius + markSize / 2) * Math.sin(angle * RADIAN);

  return index === 0 ? (
    <line
      x1={x1}
      y1={y1}
      x2={x2}
      y2={y2}
      stroke={markColor}
      strokeWidth={markStrokeValue}
    />
  ) : null;
};

export function ProgressCircle(props) {
  const { layoutSettings, chartSettings, value, markValue, title, caption } =
    props;

  const { background, width } = layoutSettings;
  const { margin } = chartSettings;

  const dispatch = useDispatch();

  const [fontLoaded, setFontLoaded] = React.useState(false);

  const chartRef = React.useRef<HTMLDivElement>(null);
  const { headerRef, height } = useHeaderHeight(
    width,
    layoutSettings,
    caption,
    title,
    {},
    ""
  );

  // custom fonts loading status
  title["fontLoaded"] = fontLoaded;
  for (let i = 0; i < caption.items.length; i++) {
    caption.items[i]["fontLoaded"] = fontLoaded;
  }

  useEffect(() => {
    document.fonts.ready.then(() => {
      setFontLoaded(true);
    });
  });

  useEffect(() => {
    const element = chartRef.current;
    if (element) {
      const svg = element;
      dispatch(UPDATE_CHART_REF(svg));
    }
  }, [chartRef, dispatch]);

  return (
    <div ref={chartRef} style={{ background: background }}>
      <Helmet>
        <link
          rel="stylesheet"
          href={
            "https://fonts.googleapis.com/css?family=" +
            title.fontFamily.replace(/ /g, "+") +
            ":" +
            title.fontVariants
          }
          crossOrigin="anonymous"
        />
        {caption.items.map((elem, i) => {
          return (
            <link
              key={"caption-" + i}
              rel="stylesheet"
              href={
                "https://fonts.googleapis.com/css?family=" +
                elem.fontFamily.replace(/ /g, "+") +
                ":" +
                elem.fontVariants
              }
              crossOrigin="anonymous"
            />
          );
        })}
      </Helmet>
      <div ref={headerRef} style={{ width: width }}>
        {title.isVisible ? (
          <TextBlock key={"title"} title={title} fontLoaded={fontLoaded} />
        ) : (
          ""
        )}
        {caption.isVisible
          ? caption.items.map((elem, i) => {
              return (
                <TextBlock
                  key={"caption" + i}
                  title={elem}
                  fontLoaded={fontLoaded}
                />
              );
            })
          : ""}
      </div>
      <PieChart
        width={width}
        height={height}
        margin={
          margin.isPerSide
            ? {
                top: margin.top,
                right: margin.right,
                left: margin.left,
                bottom: margin.bottom,
              }
            : {
                top: margin.margin,
                right: margin.margin,
                left: margin.margin,
                bottom: margin.margin,
              }
        }
        style={{ background: background }}
      >
        <Pie
          startAngle={90}
          endAngle={-450}
          data={[
            { name: "a", value: value },
            { name: "b", value: 100 - value },
          ]}
          dataKey="value"
          nameKey="name"
          outerRadius="100%"
          innerRadius={chartSettings.innerRadius + "%"}
          label={
            <RenderCustomizedLabel
              fill={
                chartSettings.showLabel
                  ? chartSettings.labelColor
                  : "rgba(255, 255, 255, 0)"
              }
              fontSize={chartSettings.fontSize}
            />
          }
          labelLine={
            chartSettings.showMark ? (
              <RenderCustomizedLabelLine
                markValue={markValue}
                markColor={chartSettings.markColor}
                markStrokeValue={chartSettings.markStrokeValue}
                markSize={chartSettings.markSize}
              />
            ) : undefined
          }
          isAnimationActive={false}
        >
          <Cell fill={chartSettings.lineColor} stroke="none" />
          <Cell fill={chartSettings.bgColor} stroke="none" />
        </Pie>
      </PieChart>
    </div>
  );
}

ProgressCircle.defaultProps = {
  isData: false,
  layoutSettings: {
    isFullscreen: true,
    isWidthAuto: true,
    minWidth: 320,
    maxWidth: 2500,
    isHeightAuto: true,
    background: "rgba(255, 255, 255, 1)",
  },
  chartSettings: {
    innerRadius: 99,
    lineColor: "rgba(17, 153, 238, 1)",
    bgColor: "rgba(17, 153, 238, .1)",
    showLabel: true,
    labelColor: "rgba(17, 153, 238, 1)",
    fontSize: 120,
    showMark: true,
    markSize: 80,
    markColor: "rgba(17, 153, 238, 1)",
    markStrokeValue: 1,
    margin: {
      margin: 100,
      isPerSide: false,
      top: 160,
      bottom: 100,
      left: 40,
      right: 40,
    },
  },
  value: 90,
  markValue: 80,
  title: {
    isVisible: false,
    text: "Chart title",
    txtColor: "rgba(0,0,0,1)",
    fontSize: 48,
    fontFamily: "Roboto",
    fontVariants: "regular",
    align: "center",
    margin: {
      margin: 0,
      isPerSide: true,
      top: 20,
      bottom: 20,
      left: 0,
      right: 0,
    },
  },
  caption: {
    isVisible: false,
    items: [
      {
        text: "Chart description or data source",
        txtColor: "rgba(0,0,0,.5)",
        fontSize: 18,
        fontFamily: "Roboto",
        fontVariants: "300",
        align: "center",
        margin: {
          margin: 0,
          isPerSide: true,
          top: 20,
          bottom: 20,
          left: 0,
          right: 0,
        },
      },
    ],
  },
};

applyPropertyControls(ProgressCircle, {
  //Settings or data
  isData: {
    type: ControlType.Boolean,
    title: "Change",
    disabledTitle: "settings",
    enabledTitle: "data",
    wide: true,
  },

  layoutSettings: presetLayout,

  chartSettings: {
    type: ControlType.Group,
    title: "Chart settings",
    isHeaderControls: false,
    propertyControl: {
      innerRadius: {
        type: ControlType.Number,
        title: "Inner radius",
        min: 0,
        max: 100,
        step: 0.1,
        unit: "%",
      },
      lineColor: {
        type: ControlType.Color,
        title: "Line color",
      },
      bgColor: {
        type: ControlType.Color,
        title: "Bg color",
      },

      //Label
      showLabel: {
        type: ControlType.Boolean,
        title: "Label",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      labelColor: {
        type: ControlType.Color,
        title: "↳ Color",
        hidden(props) {
          return props.showLabel === false;
        },
      },
      fontSize: {
        type: ControlType.Number,
        title: "↳ Size",
        hidden(props) {
          return props.showLabel === false;
        },
      },

      //Mark
      showMark: {
        type: ControlType.Boolean,
        title: "Mark",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      markSize: {
        type: ControlType.Number,
        title: "↳ Size",
        hidden(props) {
          return props.showMark === false;
        },
      },
      markColor: {
        type: ControlType.Color,
        title: "↳ Color",
        hidden(props) {
          return props.showMark === false;
        },
      },
      markStrokeValue: {
        type: ControlType.Number,
        title: "↳ Stroke",
        displayStepper: true,
        min: 0,
        hidden(props) {
          return props.showMark === false;
        },
      },
      margin: {
        type: ControlType.FusedNumber,
        title: "Margin",
        toggleKey: "isPerSide",
        toggleTitles: ["All", "Individual"],
        valueKeys: ["top", "right", "bottom", "left"],
        valueLabels: ["T", "R", "B", "L"],
        min: 0,
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Title
  title: presetTitle,

  //Caption
  caption: presetCaption,

  //Data
  value: {
    type: ControlType.Number,
    title: "Progress",
    unit: "%",
    max: 100,
    hidden(props) {
      return props.isData === false;
    },
  },
  markValue: {
    type: ControlType.Number,
    title: "Mark",
    unit: "%",
    max: 100,
    hidden(props) {
      return props.isData === false;
    },
  },
});

export default ProgressCircle;
