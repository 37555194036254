import React from "react";
import { useDispatch } from "react-redux";
import { Grid, Button, Typography } from "@mui/material";
import {
  UPDATE_CURRENT_CHART_ID,
  IS_CURRENT_CHART_SAVED,
  UPDATE_CURRENT_CHART_NAME,
  UPDATE_CHART_TYPE,
} from "../utils/actions";
import { getAuth, signOut } from "firebase/auth";
import "../../App.css";

export function SaveChartAlert(props) {
  const auth = getAuth();

  const { handleClose, popupProps } = props;
  const { action, newChartType } = popupProps;

  const dispatch = useDispatch();

  function dispatchBatch() {
    dispatch(UPDATE_CHART_TYPE(newChartType));
    dispatch(UPDATE_CURRENT_CHART_ID(null));
    dispatch(UPDATE_CURRENT_CHART_NAME(""));
    dispatch(IS_CURRENT_CHART_SAVED(true));
  }

  const handleAction = () => {
    switch (action) {
      case "New chart":
        dispatchBatch();
        handleClose();
        return;
      case "Sign out":
        dispatchBatch();
        signOut(auth)
          .then(() => {
            // Sign-out successful
            // google
            if (window !== undefined && window.google !== undefined) {
              window.google.accounts.id.disableAutoSelect();
            }
          })
          .catch((error) => {
            // An error happened
            console.log("Error while sign out: ", error);
          });
        handleClose();
        return;
    }
  };

  return (
    <Grid container spacing={2} style={{ marginBottom: 8 }}>
      <Grid item style={{ width: "100%" }}>
        <Typography>
          You have unsaved changes. By continuing, it will be lost.
        </Typography>
      </Grid>
      <Grid container spacing={2} alignItems="center" style={{ margin: 0 }}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disableElevation
            onClick={handleAction}
          >
            Continue
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            disableElevation
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SaveChartAlert;
