import * as React from "react";
import { DefaultTooltipContent } from "recharts";

function getFormattedValue(
  value, // number value
  useLocale, // use locale or not
  units
) {
  return useLocale ? value.toLocaleString() + units : value + units;
}

const Bullet = (props) => {
  const { color } = props;
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 32 32"
      version="1.1"
      style={{
        display: "inline-block",
        verticalAlign: "middle",
        marginRight: "4px",
      }}
    >
      <path
        fill={color}
        transform="translate(16, 16)"
        d="M16,0A16,16,0,1,1,-16,0A16,16,0,1,1,16,0"
      ></path>
    </svg>
  );
};

function getTooltipStr(
  label, // usually category value, but could be number value
  value, // usually number value, but could be category value. could be array (bullet mark, range, waterfall)
  axesDataTypes, // axes data types
  axesLabels, // axes labels
  layout, // horizontal or vertical
  units, // units for axis
  separator, // separator between label and value
  separatorValues, // separator between values, if values are array
  useAxesLabels, // use axes labels in tooltip or not
  useLocales // use 'toLocaleString()' or not
) {
  let str = "";
  let lValue = label;
  let vValue = value;
  let lUnits = "";
  let vUnits = "";

  if (layout) {
    if (axesDataTypes.x === "number") {
      lUnits = units.x;
      if (useLocales.x) lValue = label.toLocaleString();
    }
    if (axesDataTypes.y === "number") {
      vUnits = units.y;
      if (useLocales.y)
        vValue = Array.isArray(value)
          ? value.map((elem) => elem.toLocaleString())
          : value.toLocaleString();
    }
  } else {
    if (axesDataTypes.x === "number") {
      vUnits = units.x;
      if (useLocales.x)
        vValue = Array.isArray(value)
          ? value.map((elem) => elem.toLocaleString())
          : value.toLocaleString();
    }
    if (axesDataTypes.y === "number") {
      lUnits = units.y;
      if (useLocales.y) lValue = label.toLocaleString();
    }
  }

  const l = lValue + lUnits;
  const v = Array.isArray(vValue)
    ? vValue[0] + vUnits + separatorValues + vValue[1] + vUnits
    : vValue + vUnits;

  // const l =
  //   label +
  //   (layout && axesDataTypes.x === "number"
  //     ? units.x
  //     : !layout && axesDataTypes.y === "number"
  //     ? units.y
  //     : "");

  // // get units for "v"
  // const u =
  //   layout && axesDataTypes.y === "number"
  //     ? units.y
  //     : !layout && axesDataTypes.x === "number"
  //     ? units.x
  //     : "";

  // const v = Array.isArray(value)
  //   ? value[0] + u + separatorValues + value[1] + u
  //   : value + u;

  if (axesDataTypes.x !== axesDataTypes.y && !useAxesLabels) {
    const categoryAsix =
      (layout && axesDataTypes.x === "number") ||
      (!layout && axesDataTypes.y === "number")
        ? "y"
        : "x";

    str =
      (categoryAsix === "x" ? l : v) +
      separator +
      (categoryAsix === "x" ? v : l);
  } else {
    str =
      (useAxesLabels ? axesLabels.x : "x") +
      ": " +
      (layout ? l : v) +
      "\n" +
      (useAxesLabels ? axesLabels.y : "y") +
      ": " +
      (layout ? v : l);
  }

  return str;
}

export const CustomizedTooltip = (props) => {
  const {
    active,
    payload,
    label,
    txtColor,
    background,
    chartType,
    units,
    layout,
    axesDataTypes,
    axesLabels,
    separator,
    useAxesLabels,
    useLocales,
    ...rest
  } = props;

  if (active) {
    let Content = () => <p />;
    switch (chartType) {
      case "bullet":
        Content = () => (
          <>
            <p
              style={{
                color: txtColor,
                margin: "0",
                whiteSpace: "pre-line", // to break lines with "/n"
              }}
            >
              {getTooltipStr(
                label,
                [payload[0].value, payload[1].value],
                axesDataTypes,
                axesLabels,
                layout,
                units,
                separator,
                " / ",
                useAxesLabels,
                useLocales
              )}
            </p>
          </>
        );
        break;
      case "lollipops":
        Content = () => (
          <>
            <p
              style={{
                color: txtColor,
                margin: "0",
                whiteSpace: "pre-line", // to break lines with "/n"
              }}
            >
              {getTooltipStr(
                label,
                payload[0].value,
                axesDataTypes,
                axesLabels,
                layout,
                units,
                separator,
                "",
                useAxesLabels,
                useLocales
              )}
            </p>
          </>
        );
        break;
      case "range":
      case "waterfall":
        Content = () => (
          <>
            <p
              style={{
                color: txtColor,
                margin: "0",
                whiteSpace: "pre-line", // to break lines with "/n"
              }}
            >
              {getTooltipStr(
                label,
                [payload[0].value[0], payload[0].value[1]],
                axesDataTypes,
                axesLabels,
                layout,
                units,
                separator,
                " – ",
                useAxesLabels,
                useLocales
              )}
            </p>
          </>
        );
        break;
      case "scatter":
        Content = () => {
          const xV = payload[0].payload["x"];
          const xValue =
            axesDataTypes.x === "number"
              ? getFormattedValue(xV, useLocales.x, units.x)
              : xV;
          const yV = payload[0].payload["y"];
          const yValue =
            axesDataTypes.y === "number"
              ? getFormattedValue(yV, useLocales.y, units.y)
              : yV;
          const zV = payload[0].payload["z"];
          const zValue =
            axesDataTypes.z === "number"
              ? getFormattedValue(zV, useLocales.z, units.z)
              : zV;
          const styleSettings = {
            color: txtColor,
            margin: "0",
          };
          return payload.length > 0 ? (
            <>
              <p style={styleSettings}>
                {(useAxesLabels ? axesLabels.x : "x") + `: ${xValue}`}
              </p>
              <p style={styleSettings}>
                {(useAxesLabels ? axesLabels.y : "y") + `: ${yValue}`}
              </p>
              <p style={styleSettings}>
                {(useAxesLabels ? axesLabels.z : "z") + `: ${zValue}`}
              </p>
            </>
          ) : (
            <></>
          );
        };
        break;
      case "sankey":
        Content = () => (
          <>
            {payload.length > 0 ? (
              <>
                <p
                  style={{
                    color: txtColor,
                    margin: "0",
                  }}
                >
                  {payload[0].name +
                    separator +
                    payload[0].value.toLocaleString() +
                    units}
                </p>
              </>
            ) : (
              ""
            )}
          </>
        );
        break;
      case "radar": // BUG(?) recieving incorrect label for radar chart, so get it manual
        Content = () => (
          <>
            {payload.length > 1 ? (
              <>
                <p style={{ color: txtColor, margin: 0 }}>
                  {axesDataTypes.x === "number"
                    ? getFormattedValue(
                        payload[0].payload.name,
                        useLocales.x,
                        units.x
                      )
                    : payload[0].payload.name}
                </p>
                {payload.map((elem, i) => {
                  return (
                    <p
                      key={"tooltip-" + i}
                      style={{
                        color: txtColor,
                        margin: "8px 0 0 0",
                        fontSize: "14px",
                      }}
                    >
                      <Bullet color={elem.color} />
                      {`${elem.name}: ${
                        axesDataTypes.y === "number"
                          ? getFormattedValue(elem.value, useLocales.y, units.y)
                          : elem.value
                      }`}
                    </p>
                  );
                })}
              </>
            ) : payload.length === 1 ? (
              <p
                style={{
                  color: txtColor,
                  margin: "0",
                  fontSize: "14px",
                }}
              >
                {`${
                  payload[0].payload.name
                    ? axesDataTypes.x === "number"
                      ? getFormattedValue(
                          payload[0].payload.name,
                          useLocales.x,
                          units.x
                        ) + ": "
                      : payload[0].payload.name + ": "
                    : ""
                } ${
                  axesDataTypes.y === "number"
                    ? getFormattedValue(payload[0].value, useLocales.y, units.y)
                    : payload[0].value
                }`}
              </p>
            ) : (
              ""
            )}
          </>
        );
        break;
      // Line, Area variants, Bars, Sunburst
      default:
        Content = () => (
          <>
            {
              // if more than one line or area
              payload.length > 1 ? (
                <>
                  <p style={{ color: txtColor, margin: 0 }}>
                    {(useAxesLabels
                      ? layout
                        ? axesLabels.x + separator
                        : axesLabels.y + separator
                      : "") +
                      (layout
                        ? axesDataTypes.x === "number"
                          ? getFormattedValue(label, useLocales.x, units.x)
                          : label
                        : !layout
                        ? axesDataTypes.y === "number"
                          ? getFormattedValue(label, useLocales.y, units.y)
                          : label
                        : "")}
                  </p>
                  {useAxesLabels ? (
                    <p style={{ color: txtColor, margin: 0 }}>
                      {layout ? axesLabels.y + ":" : axesLabels.x + ":"}
                    </p>
                  ) : (
                    ""
                  )}
                  {payload.map((elem, i) => {
                    const value =
                      layout && axesDataTypes.y === "number"
                        ? getFormattedValue(elem.value, useLocales.y, units.y)
                        : !layout && axesDataTypes.x === "number"
                        ? getFormattedValue(elem.value, useLocales.x, units.x)
                        : "";
                    return (
                      <p
                        key={"tooltip-" + i}
                        style={{
                          color: txtColor,
                          margin: "8px 0 0 0",
                        }}
                      >
                        <Bullet color={elem.color} />
                        {`${elem.name + separator + value}`}
                      </p>
                    );
                  })}
                </>
              ) : // if one line or area
              payload.length === 1 ? (
                <>
                  <p
                    style={{
                      color: txtColor,
                      margin: "0",
                      whiteSpace: "pre-line", // to break lines with "/n"
                    }}
                  >
                    {getTooltipStr(
                      label,
                      payload[0].value,
                      axesDataTypes,
                      axesLabels,
                      layout,
                      units,
                      separator,
                      "",
                      useAxesLabels,
                      useLocales
                    )}
                  </p>
                </>
              ) : (
                ""
              )
            }
          </>
        );
    }

    if (active && payload && payload.length) {
      return (
        <div
          style={{
            border: "none",
            boxShadow: "0 1px 8px 1px rgba(0,0,0,0.1)",
            padding: "16px",
            textAlign: "left",
            backgroundColor: background,
          }}
        >
          <Content />
        </div>
      );
    }
  }

  return <DefaultTooltipContent payload={payload} {...props} />;
};

CustomizedTooltip.defaultProps = {
  layout: true, // to avoid cases, when layout isn't used in chart at all
};

export default CustomizedTooltip;
