import React from "react";
import { colorToObject, RGBToHex, HexToRGBA } from "../utils/utils";
import { TextField, Grid, Typography, InputAdornment } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getObjValueByPath, useIsMount } from "../utils/utils";
import { UPDATE_CHART_SETTINGS } from "../utils/actions";

export function InputColor(props) {
  const { name } = props;

  const chartUI = useSelector((state: any) => state.chart.chartUI);
  const settingsValue = getObjValueByPath(chartUI, name);
  const dispatch = useDispatch();

  const VALUE = settingsValue || "rgba(255, 255, 255, 1)";
  const colorObject = colorToObject(VALUE);
  const colorHex = RGBToHex(
    colorObject.red,
    colorObject.green,
    colorObject.blue
  );

  const [inputColorValue, updateInputColorValue] = React.useState(colorHex);
  const [inputOpacityValue, updateInputOpacityValue] = React.useState(
    Math.round(colorObject.alpha * 100)
  );

  const isFirstRender = useIsMount();

  // update local state while using keyboard
  const handleChangeOpacity = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const value = event.target.value as number;
    updateInputOpacityValue(value);
  };

  // keyboard input update global state after unfocus
  const handleBlurOpacity = (event) => {
    let value = Number(event.target.value);
    if (!value || value < 0) {
      value = 0;
    }
    if (value > 100) {
      value = 100;
    }

    // if value after validation are equal to global state value, then we updating
    // local state, else - we updating global state
    if (value === colorObject.alpha) {
      updateInputOpacityValue(value);
    } else {
      dispatch(UPDATE_CHART_SETTINGS(name, HexToRGBA(colorHex, value)));
    }
  };

  const handleUpdateInputColorValue = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const value = event.target.value as string;
    updateInputColorValue(value);
  };

  // update state if new VALUE came
  React.useEffect(() => {
    updateInputOpacityValue(Math.round(colorObject.alpha * 100));
  }, [colorObject.alpha]);

  // debounce dispatch when color changed
  React.useEffect(() => {
    if (!isFirstRender) {
      const delay = 100;
      const timeoutId = setTimeout(() => {
        dispatch(
          UPDATE_CHART_SETTINGS(
            name,
            HexToRGBA(inputColorValue, inputOpacityValue)
          )
        );
      }, delay);
      return () => clearTimeout(timeoutId);
    }
  }, [inputColorValue]);

  const renderColorPicker = (
    <div
      style={{
        width: "100%",
        backgroundColor: VALUE,
        outline:
          colorHex === "#ffffff" || inputOpacityValue === 0
            ? "solid 1px #dddddd"
            : "none",
      }}
    >
      <input
        type="color"
        value={colorHex}
        onChange={handleUpdateInputColorValue}
        style={{
          border: "none",
          padding: 0,
          width: "100%",
          height: 24,
          opacity: 0,
        }}
      />
    </div>
  );

  const renderInput = (
    <TextField
      type="number"
      variant="standard"
      size="small"
      onChange={handleChangeOpacity}
      onBlur={handleBlurOpacity}
      onFocus={(event) => {
        event.target.select();
      }}
      onKeyUp={(event) => {
        if (event.key === "Enter") {
          handleBlurOpacity(event);
        }
      }}
      value={inputOpacityValue}
      inputProps={{
        min: 0,
        max: 100,
        step: 1,
        style: { padding: 0, fontSize: "0.8125rem" },
      }}
      style={{ width: "100%" }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Typography style={{ fontSize: "0.8125rem", color: "#999999" }}>
              %
            </Typography>
          </InputAdornment>
        ),
        style: { paddingRight: 0 },
      }}
    />
  );

  return (
    <Grid container spacing={2}>
      <Grid container item xs={6} alignItems="center">
        {renderColorPicker}
      </Grid>
      <Grid container item xs={6} alignItems="center">
        {renderInput}
      </Grid>
    </Grid>
  );
}

export default InputColor;
