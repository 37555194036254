import React from "react";
import { Menu, MenuItem, ListSubheader, Button } from "@mui/material";
import {
  UPDATE_CHART_TYPE,
  UPDATE_CURRENT_CHART_ID,
  UPDATE_CURRENT_CHART_NAME,
  IS_CURRENT_CHART_SAVED,
  OPEN_POPUP,
} from "../utils/actions";
import { useDispatch } from "react-redux";
import { Charts } from "../charts/charts";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

export const ChartMenu = (props) => {
  const { saveChartAlert } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCreateNewChart = (chartType) => {
    if (!saveChartAlert) {
      dispatch(UPDATE_CHART_TYPE(chartType));
      dispatch(UPDATE_CURRENT_CHART_ID(null));
      dispatch(UPDATE_CURRENT_CHART_NAME(""));
      dispatch(IS_CURRENT_CHART_SAVED(true));
    } else {
      dispatch(
        OPEN_POPUP({
          content: "Save chart alert",
          action: "New chart",
          newChartType: chartType,
        })
      );
    }
    handleClose();
  };

  //group charts by types
  const chartsGroupped = Object.entries(Charts).reduce(function (r, a) {
    r[a[1].group] = r[a[1].group] || [];
    r[a[1].group].push(a);
    return r;
  }, Object.create(null));

  return (
    <>
      <Button
        onClick={handleClick}
        size="small"
        variant="outlined"
        color="primary"
        component="span"
        startIcon={<AddCircleOutlineOutlinedIcon />}
        style={{
          width: "100%",
          border: "solid 1px rgba(0, 0, 0, .12)",
        }}
      >
        New
      </Button>
      <Menu
        id="newChart-menu"
        anchorEl={anchorEl}
        // getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: -4, horizontal: "center" }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Object.entries(chartsGroupped).map(function (group: any, j) {
          return group[1].map(function (chart, i) {
            return [
              i === 0 ? (
                <ListSubheader disableSticky style={{ pointerEvents: "none" }}>
                  {chart[1].group}
                </ListSubheader>
              ) : null,
              <MenuItem
                key={"menuItem-" + i}
                onClick={() => handleCreateNewChart(chart[0])}
              >
                {chart[1].title}
              </MenuItem>,
            ];
          });
        })}
      </Menu>
    </>
  );
};

export default ChartMenu;
