export const ControlType = {
  /**
   * A control that displays an on / off checkbox. The associated property will be `true` or `false`,
   * depending on the state of the checkbox. Includes an optional `defaultValue`, which is set to `true` by default. You can also customize the labels displayed in the property panel with the `enabledTitle` and `disabledTitle` properties.
   */
  Boolean: "boolean",
  /**
   * A control that accepts any numeric value. This will be provided directly as a property.
   * Will display an input field with a range slider by default. The
   * `displayStepper` option can be enabled to include a stepper control instead.
   */
  Number: "number",
  /**
   * A control that accepts plain text values. This will be provided directly as a property.
   * Will display an input field with an optional placeholder value.
   * If `obscured` attribute is set to true a password input field will be used instead of a regular text input
   * so that the value in the input will be visually obscured, yet still be available as plain text inside the component
   */
  String: "string",
  /**
   * A control that can be used to take a single number or four distinct numeric input fields.
   * The typical use case for this control is for visual properties like border, padding or margin.
   * It will display an input field to accept a single value, alongside a segmented control
   * allowing four distinct values to be provided.
   */
  Text: "text",
  FusedNumber: "fusednumber",
  /**
   * A property control that represents a list of options. The list contains primitive values and each
   * value has to be unique. The selected option will be provided as a property. This control is displayed
   * as a dropdown menu in which a user can select one of the items.
   * `displaySegmentedControl` can be enabled to display a segmented control instead.
   */
  Enum: "enum",
  /**
   * Select with search
   */
  ComboBox: "combobox",
  /**
   * A control that represents a color value. It will be included in the component props as a string.
   * This control is displayed as a color field and will provide the selected color in either
   * HEX (`"#fff"`) or HSL (`hsla(203, 87%, 50%, 0.5)`) notation, depending on
   * whether there is an alpha channel.
   */
  Color: "color",
  /**
   * A control that allows the user to pick a file resource. It will be
   * included in the component props as an URL string.
   * Displayed as an file picker that will open a native file browser. The
   * selected file will be provided as a fully qualified URL. The
   * `allowedFileTypes` property must be provided to specify acceptable file
   * types.
   */
  File: "file",
  /**
   * A control that references to another component on the canvas,
   * included in the component props as a React node.
   * The component will have an outlet to allow linking to other Frames.
   * Available Frames will also be displayed in a dropdown menu in the
   * properties panel. The component reference will be provided as a property.
   * As a convention, the name for the property is usually just `children`.
   */
  Array: "array",
  /**
   * Group
   */
  Group: "group",
  /**
   * MultiGroup
   */
  Collection: "collection",
  /**
   * Not control, but status of variable.
   * Used to inform which value assigned.
   */
  Status: "status",
};

export const applyPropertyControls = (Component, controls) => {
  if (Component.propertyControls) {
    Component.propertyControls = {
      ...Component.propertyControls,
      ...controls,
    };
  } else {
    Component.propertyControls = controls;
  }
};
