import React from "react";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { CLOSE_POPUP } from "../utils/actions";
import { FilePreview } from "./popupFilePreview";
import { Share } from "./popupShare";
import { SaveFile } from "./popupSaveFile";
import { Info } from "./popupInfo";
import { SaveNewChart } from "./popupSaveNewChart";
import { SaveChartAlert } from "./popupSaveChartAlert";
import { Profile } from "./popupProfile";
import { ConvertDataAlert } from "./popupConvertDataAlert";
import { PopupArray } from "./popupArray";
import Close from "@mui/icons-material/Close";

export function Popup(props) {
  const { popupOpened, popupProps } = props;

  const [autoHideToggle, setAutoHideToggle] = React.useState(false);

  const dispatch = useDispatch();

  const handleClose = () => {
    setAutoHideToggle(false);
    dispatch(CLOSE_POPUP);
  };

  const handleAutoHide = () => {
    if (popupProps.content === "FilePreview") {
      setAutoHideToggle(true);
    }
  };

  const content = (() => {
    switch (popupProps.content) {
      case "FilePreview":
        return <FilePreview />;
      case "Share":
        return <Share />;
      case "Download":
        return <SaveFile />;
      case "Info":
        return <Info handleClose={handleClose} />;
      case "Save":
        return <SaveNewChart handleClose={handleClose} />;
      case "Save chart alert":
        return (
          <SaveChartAlert handleClose={handleClose} popupProps={popupProps} />
        );
      case "Convert data alert":
        return (
          <ConvertDataAlert handleClose={handleClose} popupProps={popupProps} />
        );
      case "Profile":
        return <Profile handleClose={handleClose} />;
      case "PopupArray":
        return <PopupArray popupProps={popupProps} />;
    }
  })();

  const popupStyle =
    popupProps.content === "PopupArray"
      ? {
          paper: {
            position: "absolute",
            width: "300px",
            maxWidth: "300px",
            maxHeight: "90%",
            backgroundColor: "white",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            boxShadow: "none",
            padding: "none",
            outline: 0,
            opacity: 1,
          },
        }
      : {
          paper: {
            position: "absolute",
            width:
              popupProps.content === "Info" || popupProps.content === "Profile"
                ? "100%"
                : "80%",
            maxWidth:
              popupProps.content === "Download" ||
              popupProps.content === "Save" ||
              popupProps.content === "Save chart alert" ||
              popupProps.content === "Convert data alert"
                ? "400px"
                : "100%",
            maxHeight:
              popupProps.content === "Info" || popupProps.content === "Profile"
                ? "100%"
                : (window.innerHeight * 80) / 100,
            backgroundColor:
              popupProps.content === "Info" ? "#1199EE" : "white",
            border: "none",
            boxShadow: "none",
            padding:
              popupProps.content === "Info" || popupProps.content === "Profile"
                ? "none"
                : "16px 16px 32px",
            outline: 0,
            opacity: 1,
          },
          popupOpacity: {
            opacity: 0.1,
            transition: "opacity 1s",
            "&:hover": {
              opacity: 1,
            },
          },
        };

  return popupProps.content ? (
    <Dialog
      open={popupOpened}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"lg"}
      fullScreen={
        popupProps.content === "Info" || popupProps.content === "Profile"
      }
      PaperProps={{
        sx: autoHideToggle
          ? { ...popupStyle.paper, ...popupStyle.popupOpacity }
          : popupStyle.paper,
        style: { right: popupProps.content === "PopupArray" ? 0 : "inherit" },
      }}
      BackdropProps={{
        transitionDuration: 100,
        style: {
          backgroundColor:
            popupProps.content === "PopupArray"
              ? "rgba(0, 0, 0, 0)"
              : "rgba(0, 0, 0, .1)",
          visibility:
            popupProps.content === "PopupArray" ||
            popupProps.content === undefined
              ? "hidden"
              : "visible",
        },
      }}
    >
      <DialogTitle>
        {popupProps.content === "PopupArray" ? (
          <Typography style={{ fontSize: "0.8125rem" }}>{}</Typography>
        ) : null}
        <IconButton
          onClick={handleClose}
          title="close"
          sx={[
            {
              position: "absolute",
              right: 8,
              top: 8,
            },
            popupProps.content === "Info" && {
              color: "white",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, .04)",
              },
            },
          ]}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent
        onMouseLeave={handleAutoHide}
        sx={{
          overflowY: popupProps.content === "Profile" ? "initial" : "auto",
        }}
      >
        {content}
      </DialogContent>
    </Dialog>
  ) : null;
}

export default Popup;
