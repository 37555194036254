import React from "react";
import { useSelector } from "react-redux";
// import numeral from "numeral";
import "numeral/locales";
// import * as d3 from "d3";
import * as XLSX from "xlsx";
import FilePreviewHeader from "./filePreviewHeader";
// import FilePreviewParseSettings from "./filePreviewParseSettings";
import FilePreviewTableSettings from "./filePreviewTableSettings";
import { getChart } from "../utils/utils";

// const separators = {
//   comma: ",",
//   semicolon: ";",
//   colon: ":",
//   pipe: "|",
//   tab: "	",
// };

// //TODO test all locales
// const locales = [
//   "en",
//   "bg",
//   // "chs",
//   "cs",
//   "da-dk",
//   "de-ch",
//   "de",
//   // "en-au",
//   // "en-gb",
//   "en-za",
//   "es-es",
//   "es",
//   "et",
//   "fi",
//   "fr",
//   "fr-ca",
//   "fr-ch",
//   "hu",
//   "it",
//   // "ja",
//   "lv",
//   "nl-be",
//   "nl-nl",
//   "no",
//   "pl",
//   "pt-br",
//   "pt-pt",
//   "ru",
//   "ru-ua",
//   "sk",
//   "sl",
//   // "th",
//   "tr",
//   "uk-ua",
//   "vi",
// ];

function removeEmptyRows(data) {
  if (data) {
    return data.filter((item) => {
      for (var key in item) {
        if (item[key] !== null && item[key] !== "") return true;
      }
      return false;
    });
  } else return [];
}
function removeEmptyColumns(data) {
  if (data) {
    const columns = data.reduce(
      (r, a) => (a.forEach((v, i) => (r[i] = r[i] || v)), r),
      []
    );
    return data.map((a) => a.filter((_, i) => columns[i]));
  } else return [];
}

function convertDateToString(data) {
  if (data) {
    const newData: any = [];
    for (let i = 0; i < data.length; i++) {
      const newDataRow: any = [];
      for (let j = 0; j < data[i].length; j++) {
        if (Object.prototype.toString.call(data[i][j]) === "[object Date]") {
          const date = data[i][j];
          const mm = date.getMonth() + 1; // getMonth() is zero-based
          const dd = date.getDate();
          newDataRow.push(
            [
              date.getFullYear(),
              (mm > 9 ? "" : "0") + mm,
              (dd > 9 ? "" : "0") + dd,
            ].join("/")
          );
        } else {
          newDataRow.push(data[i][j]);
        }
      }
      newData.push(newDataRow);
    }
    return newData;
  } else return [];
}

export function FilePreview() {
  const file = useSelector((state: any) => state.fileUploaded.file);
  const fileType = useSelector((state: any) => state.fileUploaded.fileType);
  const currentChartType = useSelector(
    (state: any) => state.chart.currentChartType
  );
  const isScatter =
    currentChartType === "Scatter" || currentChartType === "ExampleScatter";
  const isHierarchy =
    currentChartType === "HierarchyForceDirected" ||
    currentChartType === "HierarchyTree" ||
    currentChartType === "HierarchyTreeRadial" ||
    currentChartType === "HierarchyTreemap" ||
    currentChartType === "HierarchyTreemapNested" ||
    currentChartType === "HierarchySunburst" ||
    currentChartType === "ExampleSunburst";
  const isSankey = currentChartType === "Sankey";
  const dimensions =
    useSelector((state: any) => state.chart.chartSettings.dimensions) || [];
  // const [delimiterCurr, setDelimiterCurr] = React.useState(() => {
  //   switch (fileType) {
  //     case "csv":
  //       return "comma";
  //     case "tsv":
  //       return "tab";
  //     default:
  //       return "comma";
  //   }
  // });
  // const [numbersLocale, setNumbersLocale] = React.useState("en");
  const [tableRows, setTableRows] = React.useState([]);
  // const [isSettings, setIsSettings] = React.useState(false);
  const [isFirstRowHeader, setIsFirstRowHeader] = React.useState(true);
  const [isParseErrors, setIsParseErrors] = React.useState(false);
  const [chartDimensionsTypes, setChartDimensionsTypes] =
    React.useState(dimensions);

  const chartControls = getChart(currentChartType).type.propertyControls;
  const dimensionsControls = chartControls.dimensions
    ? chartControls.dimensions.propertyControl
    : null;
  const chartDimensionsTitles = dimensionsControls
    ? Object.keys(dimensionsControls).map((item) => {
        return dimensionsControls[item].title;
      })
    : [];

  const rowsCount = tableRows ? tableRows.length : 0;
  const columnsCount = rowsCount > 0 ? Object.keys(tableRows[0]).length : 0;
  const MAX_ROWS = 5;

  // function csvToRows(file, separator) {
  //   const reader = new FileReader();
  //   const psv = d3.dsvFormat(separator);
  //   reader.onload = function () {
  //     const parseResult = psv.parseRows(reader.result);
  //     // const hierarchy = d3.csvParse(reader.result);
  //     // console.log(hierarchy);
  //     //remove empty rows and columns
  //     // const validRows = strToNumber(
  //     //   removeEmptyColumns(removeEmptyRows(parseResult)),
  //     //   numbersLocale
  //     // );
  //     const validRows = removeEmptyColumns(removeEmptyRows(parseResult));
  //     setTableRows(validRows);
  //   };
  //   reader.readAsText(file);
  // }

  function excelToRows(file) {
    var name = file.name;
    const reader = new FileReader();
    reader.onload = () => {
      const rowData = reader.result;
      const parseResult = XLSX.read(rowData, {
        type: "binary",
        cellDates: true,
      });
      const sheetName = parseResult.SheetNames[0];
      const firstSheet = parseResult.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(firstSheet, {
        header: 1,
        defval: "",
      });
      const validRows = convertDateToString(
        removeEmptyColumns(removeEmptyRows(data))
      );
      setTableRows(validRows);
    };
    reader.readAsBinaryString(file);
  }

  // function handleSetDelimiter(name) {
  //   setDelimiterCurr(name);
  // }

  // function handleSettings(e) {
  //   e.preventDefault();
  //   setIsSettings(!isSettings);
  // }

  // const handleChangeLocale = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   const value = event.target.value as string;
  //   setNumbersLocale(value);
  // };

  const handleSetFirstRowHeader = () => {
    setIsFirstRowHeader(!isFirstRowHeader);
  };

  const handleParseErrors = (isErrors) => {
    setIsParseErrors(isErrors);
  };

  // const handleChangeDimensionType = (
  //   event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  // ) => {
  //   const { name, value } = event.target;
  //   if (name !== undefined) {
  //     const dimensions = { ...chartDimensionsTypes, [name]: value };
  //     setChartDimensionsTypes(dimensions);
  //   }
  // };

  // React.useEffect(() => {
  //   switch (fileType) {
  //     // case "csv":
  //     // case "tsv":
  //     // csvToRows(file, separators[delimiterCurr]);
  //     // csvToRows(file);
  //     // break;
  //     case "exel":
  //       excelToRows(file);
  //       break;
  //   }
  // }, [delimiterCurr, numbersLocale]);

  React.useEffect(() => {
    switch (fileType) {
      case "exel":
        excelToRows(file);
        break;
    }
  }, []);

  return (
    <>
      <FilePreviewHeader
        fileType={fileType}
        rowsCount={rowsCount}
        MAX_ROWS={MAX_ROWS}
        columnsCount={columnsCount}
        chartDimensionsTitles={chartDimensionsTitles}
        isHierarchy={isHierarchy}
        isSankey={isSankey}
      />
      {/* {fileType === "csv" ? (
        <FilePreviewParseSettings
          isParseErrors={isParseErrors}
          isSettings={isSettings}
          separators={separators}
          delimiterCurr={delimiterCurr}
          numbersLocale={numbersLocale}
          locales={locales}
          handleSettings={handleSettings}
          handleSetDelimiter={handleSetDelimiter}
          handleChangeLocale={handleChangeLocale}
        />
      ) : (
        ""
      )} */}
      <FilePreviewTableSettings
        tableRows={tableRows}
        isFirstRowHeader={isFirstRowHeader}
        // numbersLocale={numbersLocale}
        handleParseErrors={handleParseErrors}
        chartDimensionsTypes={chartDimensionsTypes}
        dimensionsControls={dimensionsControls}
        // handleChangeDimensionType={handleChangeDimensionType}
        handleSetFirstRowHeader={handleSetFirstRowHeader}
        isScatter={isScatter}
        isHierarchy={isHierarchy}
        isSankey={isSankey}
      />
      {/* <DataTable
        tableRows={tableRows}
        isFirstRowHeader={isFirstRowHeader}
        numbersLocale={numbersLocale}
        handleParseErrors={handleParseErrors}
        chartAxesTypes={chartAxesTypes}
        chartAxesNames={chartAxesNames}
      /> */}
    </>
  );
}

export default FilePreview;
