export const dataRaw = {
  dataGroups: [
    {
      Hierarchy: ["A", "A", "A", "B", "B", "B", "C", "C", "C", "D", "D", "D"],
    },
    {
      Hierarchy: [
        "A-1",
        "A-2",
        "",
        "B-1",
        "B-2",
        "",
        "C-1",
        "C-2",
        "",
        "D-1",
        "D-2",
        "",
      ],
    },
    {
      Hierarchy: [
        "A-1-1",
        "",
        "",
        "B-1-1",
        "",
        "",
        "C-1-1",
        "",
        "",
        "D-1-1",
        "",
        "",
      ],
    },
  ],
  dataValues: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
};

export const _data = [
  { name: "cultivateur", parent: "agriculteur", value: 27, polarite: -1 },
  { name: "agriculteur", parent: "Agriculture", value: 3135, polarite: 14 },
  {
    name: "agriculture raisonné",
    parent: "Agriculture",
    value: 499,
    polarite: 21,
  },
  { name: "agro", parent: "Agriculture", value: 174, polarite: -1 },
  { name: "agroforesterie", parent: "Agriculture", value: 138, polarite: -1 },
  { name: "élevage", parent: "Agriculture", value: 1134, polarite: -40 },
  { name: "FNSEA", parent: "Agriculture", value: 143, polarite: -8 },
  { name: "modèle agricole", parent: "Agriculture", value: 197, polarite: 0 },
  { name: "OGM", parent: "Agriculture", value: 399, polarite: -45 },
  { name: "PAC", parent: "Agriculture", value: 371, polarite: 30 },
  { name: "phytosanitaire", parent: "Agriculture", value: 551, polarite: -12 },
  { name: "rejet agricole", parent: "Agriculture", value: 3, polarite: 0 },
  { name: "terres arables", parent: "Agriculture", value: 80, polarite: -5 },
  {
    name: "agriculteur bio",
    parent: "agriculture biologique",
    value: 344,
    polarite: 8,
  },
  {
    name: "permaculture",
    parent: "agriculture biologique",
    value: 546,
    polarite: 22,
  },
  {
    name: "producteur bio",
    parent: "agriculture biologique",
    value: 88,
    polarite: -1,
  },
  {
    name: "produire bio",
    parent: "agriculture biologique",
    value: 513,
    polarite: -6,
  },
  {
    name: "agriculture biologique",
    parent: "agriculture raisonné",
    value: 1969,
    polarite: 90,
  },
  { name: "foret", parent: "agroforesterie", value: 233, polarite: -13 },
  { name: "cantine", parent: "alimentation", value: 500, polarite: -6 },
  {
    name: "consommation de viande",
    parent: "alimentation",
    value: 341,
    polarite: -23,
  },
  { name: "huile de palme", parent: "alimentation", value: 452, polarite: -24 },
  { name: "pollueurs payeurs", parent: "amende", value: 576, polarite: 1 },
  {
    name: "abattage rituel des animal",
    parent: "Animal",
    value: 5,
    polarite: -2,
  },
  { name: "bien-être animal", parent: "Animal", value: 189, polarite: 23 },
  {
    name: "développement du transport aérien",
    parent: "avion",
    value: 6,
    polarite: 1,
  },
  { name: "bateau de croisière", parent: "bateau", value: 347, polarite: -18 },
  { name: "transport cargo", parent: "bateau", value: 25, polarite: -4 },
  { name: "transporteur maritime", parent: "bateau", value: 20, polarite: -1 },
  { name: "chasse", parent: "biodiversité", value: 2351, polarite: -250 },
  { name: "loup", parent: "biodiversité", value: 261, polarite: -12 },
  { name: "ours", parent: "biodiversité", value: 184, polarite: -10 },
  { name: "pêche", parent: "biodiversité", value: 767, polarite: -51 },
  { name: "poids lourd", parent: "camion", value: 1329, polarite: -64 },
  { name: "cantine scolaire", parent: "cantine", value: 230, polarite: 1 },
  { name: "bilan carbone", parent: "carbone", value: 372, polarite: -44 },
  { name: "empreindre carbone", parent: "carbone", value: 464, polarite: 0 },
  { name: "empreinte carbone", parent: "carbone", value: 464, polarite: -36 },
  { name: "diesel", parent: "carburant", value: 2544, polarite: -108 },
  { name: "essence", parent: "carburant", value: 1883, polarite: -32 },
  { name: "gasoil", parent: "carburant", value: 246, polarite: -15 },
  { name: "installation GPL", parent: "carburant", value: 2, polarite: 0 },
  { name: "kérosène", parent: "carburant", value: 1351, polarite: -88 },
  { name: "réacteur", parent: "centrale nucléaire", value: 398, polarite: 9 },
  {
    name: "dérèglement climatique",
    parent: "changement climatique",
    value: 801,
    polarite: -49,
  },
  {
    name: "réchauffement climatique",
    parent: "changement climatique",
    value: 1371,
    polarite: -155,
  },
  {
    name: "taux de dioxyde de carbone",
    parent: "changement climatique",
    value: 2,
    polarite: 0,
  },
  { name: "chaudière", parent: "chauffage", value: 692, polarite: 5 },
  {
    name: "circuit court",
    parent: "circuits courts",
    value: 1512,
    polarite: 0,
  },
  {
    name: "changement climatique",
    parent: "Climat",
    value: 668,
    polarite: -55,
  },
  { name: "COP", parent: "Climat", value: 945, polarite: -4 },
  { name: "GIEC", parent: "Climat", value: 287, polarite: -3 },
  { name: "Maire", parent: "Commune", value: 284, polarite: -3 },
  { name: "verre", parent: "consigne", value: 1052, polarite: -4 },
  { name: "alimentation", parent: "Consommation", value: 1409, polarite: -37 },
  { name: "déchet", parent: "Consommation", value: 5564, polarite: -204 },
  { name: "importation", parent: "Consommation", value: 1506, polarite: -177 },
  { name: "local", parent: "Consommation", value: 5498, polarite: 65 },
  { name: "marchandise", parent: "Consommation", value: 1792, polarite: -22 },
  {
    name: "mode de consommation",
    parent: "Consommation",
    value: 705,
    polarite: 11,
  },
  {
    name: "viande",
    parent: "consommation de viande",
    value: 1121,
    polarite: -25,
  },
  { name: "accord de Paris", parent: "COP", value: 495, polarite: 31 },
  { name: "déchetterie", parent: "déchet", value: 232, polarite: -9 },
  { name: "emballage", parent: "déchet", value: 2903, polarite: -226 },
  { name: "gaspillages", parent: "déchet", value: 1306, polarite: -97 },
  {
    name: "obsolescence programmée",
    parent: "déchet",
    value: 745,
    polarite: -88,
  },
  { name: "poubelle", parent: "déchet", value: 917, polarite: -19 },
  { name: "recyclage", parent: "déchet", value: 2704, polarite: -39 },
  { name: "tri des déchet", parent: "déchet", value: 426, polarite: 7 },
  { name: "zéro déchet", parent: "déchet", value: 140, polarite: 3 },
  { name: "arbre", parent: "déforestation", value: 1088, polarite: -2 },
  { name: "kms", parent: "déplacement", value: 211, polarite: -8 },
  { name: "trajet", parent: "déplacement", value: 1084, polarite: -26 },
  { name: "vitesse", parent: "déplacement", value: 1460, polarite: -240 },
  { name: "Banque", parent: "Économie", value: 743, polarite: -10 },
  { name: "Entreprise", parent: "Économie", value: 10503, polarite: -264 },
  { name: "fiscalité", parent: "Économie", value: 1163, polarite: 36 },
  { name: "grand capitalisme", parent: "Économie", value: 3, polarite: 0 },
  { name: "carbone", parent: "effet de serre", value: 1552, polarite: -16 },
  {
    name: "émission de gaz",
    parent: "effet de serre",
    value: 738,
    polarite: -16,
  },
  { name: "serre", parent: "effet de serre", value: 64, polarite: 0 },
  { name: "EDF", parent: "électricité", value: 791, polarite: -44 },
  { name: "batterie", parent: "électrique", value: 2664, polarite: -58 },
  { name: "élevage industriel", parent: "élevage", value: 293, polarite: -17 },
  { name: "poulailler", parent: "élevage", value: 16, polarite: -2 },
  {
    name: "élevage intensif",
    parent: "élevage industriel",
    value: 546,
    polarite: -33,
  },
  { name: "consigne", parent: "emballage", value: 1005, polarite: 15 },
  { name: "suremballage", parent: "emballage", value: 401, polarite: -64 },
  { name: "centrale", parent: "Energie", value: 2025, polarite: -23 },
  {
    name: "consommation énergétique",
    parent: "Energie",
    value: 279,
    polarite: -8,
  },
  { name: "électricité", parent: "Energie", value: 3925, polarite: -37 },
  { name: "énergie alternatif", parent: "Energie", value: 369, polarite: 22 },
  { name: "énergie fossile", parent: "Energie", value: 2542, polarite: -27 },
  { name: "énergies vertes", parent: "Energie", value: 442, polarite: 7 },
  { name: "ENR", parent: "Energie", value: 202, polarite: 4 },
  { name: "mix énergétique", parent: "Energie", value: 161, polarite: 10 },
  { name: "nucléaire", parent: "Energie", value: 5514, polarite: 15 },
  { name: "charbon", parent: "énergie fossile", value: 1820, polarite: -8 },
  { name: "fioul", parent: "énergie fossile", value: 473, polarite: -6 },
  { name: "gaz", parent: "énergie fossile", value: 2489, polarite: -28 },
  {
    name: "indépendance énergétique",
    parent: "énergie fossile",
    value: 200,
    polarite: 3,
  },
  { name: "pétrole", parent: "énergie fossile", value: 1680, polarite: -17 },
  {
    name: "développement des énergie renouvelable",
    parent: "énergie renouvelable",
    value: 373,
    polarite: 10,
  },
  {
    name: "barrage hydraulique",
    parent: "énergies vertes",
    value: 44,
    polarite: 1,
  },
  {
    name: "énergie propre",
    parent: "énergies vertes",
    value: 955,
    polarite: 24,
  },
  {
    name: "énergie renouvelable",
    parent: "énergies vertes",
    value: 4455,
    polarite: 54,
  },
  { name: "éoliennes", parent: "énergies vertes", value: 5281, polarite: -129 },
  { name: "géothermie", parent: "énergies vertes", value: 428, polarite: 8 },
  { name: "hydrogène", parent: "énergies vertes", value: 2602, polarite: 26 },
  { name: "hydrolienne", parent: "énergies vertes", value: 191, polarite: 4 },
  {
    name: "panneau photovoltaïque",
    parent: "énergies vertes",
    value: 518,
    polarite: -10,
  },
  { name: "PME-PMI", parent: "Entreprise", value: 1, polarite: 0 },
  { name: "potentiel éolien", parent: "éoliennes", value: 1, polarite: 1 },
  { name: "projet de parc éolien", parent: "éoliennes", value: 5, polarite: 0 },
  { name: "initiative européen", parent: "europe", value: 29, polarite: 2 },
  { name: "L'Europe", parent: "europe", value: 5499, polarite: 0 },
  { name: "niveau européen", parent: "europe", value: 2253, polarite: 22 },
  { name: "traite européen", parent: "europe", value: 3, polarite: -1 },
  { name: "gouvernement", parent: "Exécutif", value: 2708, polarite: -59 },
  { name: "Premier Ministre", parent: "Exécutif", value: 56, polarite: -3 },
  { name: "Président", parent: "Exécutif", value: 889, polarite: -2 },
  {
    name: "développement du transport ferroviaire",
    parent: "ferroviaire",
    value: 13,
    polarite: 0,
  },
  { name: "fret ferroviaire", parent: "ferroviaire", value: 271, polarite: 7 },
  { name: "TGV", parent: "ferroviaire", value: 239, polarite: 1 },
  { name: "fioul lourd", parent: "fioul", value: 194, polarite: -8 },
  { name: "fuel lourd", parent: "fioul", value: 160, polarite: -10 },
  { name: "disposition fiscal", parent: "fiscalité", value: 2, polarite: 0 },
  {
    name: "fiscalité écologique",
    parent: "fiscalité",
    value: 297,
    polarite: 9,
  },
  { name: "impôt", parent: "fiscalité", value: 2262, polarite: -20 },
  { name: "ISF", parent: "fiscalité", value: 216, polarite: 0 },
  { name: "subvention", parent: "fiscalité", value: 1198, polarite: -16 },
  { name: "Taxation/Subventions", parent: "fiscalité", value: 4, polarite: 1 },
  { name: "taxe", parent: "fiscalité", value: 7373, polarite: -194 },
  { name: "TVA", parent: "fiscalité", value: 2117, polarite: -19 },
  { name: "ferroutage", parent: "fret ferroviaire", value: 1807, polarite: 39 },
  { name: "fret", parent: "fret ferroviaire", value: 425, polarite: -8 },
  {
    name: "transport du fret",
    parent: "fret ferroviaire",
    value: 143,
    polarite: -2,
  },
  { name: "méthanisation", parent: "gaz", value: 274, polarite: 3 },
  { name: "Fonctionnaire", parent: "Humain", value: 283, polarite: 3 },
  { name: "Migrants", parent: "Humain", value: 59, polarite: 0 },
  { name: "citoyen", parent: "Humain", value: 7141, polarite: 174 },
  { name: "être", parent: "Humain", value: 2602, polarite: 732 },
  { name: "Français responsable", parent: "Humain", value: 12, polarite: -2 },
  { name: "gens", parent: "Humain", value: 3329, polarite: 6 },
  { name: "humanité", parent: "Humain", value: 871, polarite: -58 },
  { name: "population", parent: "Humain", value: 3235, polarite: -52 },
  { name: "salarié", parent: "Humain", value: 558, polarite: 2 },
  { name: "Santé", parent: "Humain", value: 2754, polarite: -99 },
  { name: "télétravail", parent: "Humain", value: 791, polarite: 20 },
  { name: "moteur", parent: "hydrogène", value: 2927, polarite: -2 },
  {
    name: "importation de produit",
    parent: "importation",
    value: 718,
    polarite: -113,
  },
  {
    name: "norme environnemental",
    parent: "importation",
    value: 339,
    polarite: -41,
  },
  {
    name: "entreprise polluant",
    parent: "industrie polluant",
    value: 21,
    polarite: -1,
  },
  {
    name: "industriel",
    parent: "industrie polluant",
    value: 5489,
    polarite: -100,
  },
  {
    name: "pollution industriel",
    parent: "industrie polluant",
    value: 188,
    polarite: -16,
  },
  {
    name: "Assemblée nationale",
    parent: "Institutions politiques",
    value: 59,
    polarite: 1,
  },
  {
    name: "Commune",
    parent: "Institutions politiques",
    value: 1744,
    polarite: 13,
  },
  {
    name: "Département",
    parent: "Institutions politiques",
    value: 504,
    polarite: 2,
  },
  {
    name: "Exécutif",
    parent: "Institutions politiques",
    value: 58,
    polarite: 5,
  },
  {
    name: "Région",
    parent: "Institutions politiques",
    value: 1663,
    polarite: 11,
  },
  { name: "Sénat", parent: "Institutions politiques", value: 64, polarite: -1 },
  {
    name: "isolation des bâtiment",
    parent: "isolation",
    value: 341,
    polarite: 20,
  },
  {
    name: "rénovation énergétique",
    parent: "isolation",
    value: 183,
    polarite: 4,
  },
  { name: "lobbying", parent: "lobby", value: 660, polarite: -25 },
  { name: "lobbyiste", parent: "lobby", value: 302, polarite: -26 },
  { name: "initiative local", parent: "local", value: 164, polarite: 13 },
  { name: "producteur local", parent: "local", value: 177, polarite: 14 },
  { name: "produit local", parent: "local", value: 625, polarite: 20 },
  { name: "chauffage", parent: "Logement", value: 2381, polarite: -30 },
  { name: "concentration urbain", parent: "Logement", value: 17, polarite: 0 },
  { name: "éclairage", parent: "Logement", value: 756, polarite: -63 },
  { name: "isolation", parent: "Logement", value: 1561, polarite: 51 },
  {
    name: "réhabilitation des bâtiment",
    parent: "Logement",
    value: 14,
    polarite: 0,
  },
  { name: "rénovation", parent: "Logement", value: 676, polarite: 14 },
  { name: "bio", parent: "mode de consommation", value: 3423, polarite: 41 },
  {
    name: "circuits courts",
    parent: "mode de consommation",
    value: 1512,
    polarite: 99,
  },
  {
    name: "décroissance",
    parent: "mode de consommation",
    value: 491,
    polarite: 10,
  },
  {
    name: "surconsommation",
    parent: "mode de consommation",
    value: 614,
    polarite: -38,
  },
  { name: "Animal", parent: "Nature", value: 3985, polarite: -14 },
  { name: "biodiversité", parent: "Nature", value: 2998, polarite: -21 },
  { name: "Climat", parent: "Nature", value: 1490, polarite: -23 },
  { name: "déforestation", parent: "Nature", value: 759, polarite: -50 },
  { name: "planète", parent: "Nature", value: 4673, polarite: -134 },
  { name: "pollution", parent: "Nature", value: 11580, polarite: -324 },
  { name: "zone marécageux", parent: "Nature", value: 2, polarite: 0 },
  { name: "centrale nucléaire", parent: "nucléaire", value: 1400, polarite: 2 },
  { name: "déchet nucléaire", parent: "nucléaire", value: 381, polarite: -20 },
  { name: "énergie nucléaire", parent: "nucléaire", value: 941, polarite: 8 },
  { name: "EPR", parent: "nucléaire", value: 351, polarite: -5 },
  { name: "sortie du nucléaire", parent: "nucléaire", value: 161, polarite: 0 },
  {
    name: "obsolescence programmé",
    parent: "obsolescence programmée",
    value: 745,
    polarite: 0,
  },
  {
    name: "panneau solaire",
    parent: "panneau photovoltaïque",
    value: 1728,
    polarite: -8,
  },
  {
    name: "photovoltaïque",
    parent: "panneau photovoltaïque",
    value: 932,
    polarite: -10,
  },
  { name: "ancien élu", parent: "personne politique", value: 2, polarite: 0 },
  {
    name: "Nicolas Hulot",
    parent: "personne politique",
    value: 243,
    polarite: 4,
  },
  {
    name: "utilisation de pesticide",
    parent: "pesticide",
    value: 460,
    polarite: 0,
  },
  {
    name: "utilisation des pesticide",
    parent: "pesticide",
    value: 460,
    polarite: -47,
  },
  { name: "petrol", parent: "pétrole", value: 5, polarite: 0 },
  { name: "glyphosate", parent: "phytosanitaire", value: 2253, polarite: -305 },
  { name: "pesticide", parent: "phytosanitaire", value: 4337, polarite: -453 },
  {
    name: "emballage plastique",
    parent: "plastique",
    value: 640,
    polarite: -60,
  },
  { name: "Union Européenne", parent: "europe", value: 443, polarite: 6 },
  { name: "Démocratie", parent: "Politique", value: 285, polarite: -14 },
  {
    name: "Institutions politiques",
    parent: "Politique",
    value: 5,
    polarite: 0,
  },
  { name: "Service public", parent: "Politique", value: 693, polarite: 6 },
  { name: "courage politique", parent: "Politique", value: 189, polarite: 6 },
  { name: "EELV", parent: "Politique", value: 16, polarite: -2 },
  { name: "incitation", parent: "Politique", value: 1051, polarite: 46 },
  { name: "lobby", parent: "Politique", value: 3638, polarite: -282 },
  { name: "ministère", parent: "Politique", value: 856, polarite: 22 },
  { name: "obligation", parent: "Politique", value: 1575, polarite: -4 },
  {
    name: "partenaire européen",
    parent: "Union Européenne",
    value: 230,
    polarite: 10,
  },
  { name: "personne politique", parent: "Politique", value: 11, polarite: 2 },
  {
    name: "politique écologique",
    parent: "Politique",
    value: 449,
    polarite: 28,
  },
  {
    name: "politique environnemental",
    parent: "Politique",
    value: 362,
    polarite: 33,
  },
  { name: "quota", parent: "règle", value: 424, polarite: -6 },
  { name: "recherche", parent: "Politique", value: 4367, polarite: 106 },
  { name: "règle", parent: "réglementation", value: 1737, polarite: -49 },
  { name: "réglementation", parent: "Politique", value: 697, polarite: -31 },
  { name: "Ric", parent: "Politique", value: 70, polarite: -1 },
  { name: "sanction", parent: "réglementation", value: 503, polarite: -10 },
  {
    name: "volentarime politique",
    parent: "Politique",
    value: 1,
    polarite: -1,
  },
  { name: "vrai politique", parent: "Politique", value: 554, polarite: 29 },
  { name: "europe", parent: "Politique", value: 5499, polarite: -22 },
  { name: "particule fin", parent: "polluant", value: 320, polarite: -13 },
  { name: "plus gros pollueur", parent: "polluant", value: 535, polarite: -6 },
  { name: "polluant toxique", parent: "polluant", value: 26, polarite: 0 },
  {
    name: "pollution de le air",
    parent: "polluant",
    value: 1041,
    polarite: -60,
  },
  { name: "produit dangereux", parent: "polluant", value: 327, polarite: -38 },
  { name: "produit nocif", parent: "polluant", value: 301, polarite: -20 },
  { name: "produit polluant", parent: "polluant", value: 646, polarite: -42 },
  { name: "industrie polluant", parent: "pollution", value: 461, polarite: -4 },
  { name: "mégot", parent: "pollution", value: 218, polarite: -1 },
  { name: "plastique", parent: "pollution", value: 3367, polarite: -147 },
  { name: "polluant", parent: "pollution", value: 6484, polarite: -76 },
  {
    name: "Edouard Philippe",
    parent: "Premier Ministre",
    value: 9,
    polarite: 0,
  },
  { name: "Emmanuel Macron", parent: "Président", value: 459, polarite: 5 },
  {
    name: "effet de serre",
    parent: "réchauffement",
    value: 1903,
    polarite: -16,
  },
  {
    name: "réchauffement",
    parent: "réchauffement climatique",
    value: 415,
    polarite: -26,
  },
  { name: "recyclable", parent: "recyclage", value: 918, polarite: -6 },
  { name: "absence de sanction", parent: "sanction", value: 3, polarite: -1 },
  { name: "cas de sanction", parent: "sanction", value: 35, polarite: -1 },
  { name: "dur sanction", parent: "sanction", value: 4, polarite: 0 },
  { name: "éventuel sanction", parent: "sanction", value: 4, polarite: 0 },
  { name: "forme de sanction", parent: "sanction", value: 2, polarite: 0 },
  {
    name: "lourd sanction financier",
    parent: "sanction",
    value: 9,
    polarite: 0,
  },
  { name: "lourd sanction", parent: "sanction", value: 34, polarite: -1 },
  { name: "menace de sanction", parent: "sanction", value: 3, polarite: 0 },
  {
    name: "mise en place de sanction",
    parent: "sanction",
    value: 6,
    polarite: 0,
  },
  {
    name: "objet de sanction pénal",
    parent: "sanction",
    value: 1,
    polarite: 0,
  },
  {
    name: "peine de sanction financier",
    parent: "sanction",
    value: 3,
    polarite: 0,
  },
  { name: "peine de sanction", parent: "sanction", value: 20, polarite: 1 },
  { name: "place des sanction", parent: "sanction", value: 25, polarite: -3 },
  {
    name: "sanction administratif",
    parent: "sanction",
    value: 1,
    polarite: -1,
  },
  { name: "sanction applicable", parent: "sanction", value: 4, polarite: -1 },
  { name: "sanction commercial", parent: "sanction", value: 12, polarite: -1 },
  { name: "sanction diplomatique", parent: "sanction", value: 2, polarite: 0 },
  { name: "sanction économique", parent: "sanction", value: 37, polarite: -2 },
  { name: "sanction efficace", parent: "sanction", value: 7, polarite: 0 },
  { name: "sanction encourir", parent: "sanction", value: 2, polarite: -1 },
  { name: "sanction financier", parent: "sanction", value: 78, polarite: -8 },
  { name: "sanction grave", parent: "sanction", value: 1, polarite: 0 },
  {
    name: "sanction international",
    parent: "sanction",
    value: 13,
    polarite: 0,
  },
  { name: "sanction judiciaire", parent: "sanction", value: 2, polarite: 0 },
  { name: "sanction pécuniaire", parent: "sanction", value: 4, polarite: 1 },
  { name: "sanction pénal", parent: "sanction", value: 23, polarite: 2 },
  { name: "sanction politique", parent: "sanction", value: 4, polarite: 0 },
  { name: "sanction prévoir", parent: "sanction", value: 9, polarite: -1 },
  { name: "sanction sévère", parent: "sanction", value: 20, polarite: -1 },
  { name: "système de sanction", parent: "sanction", value: 1, polarite: 0 },
  {
    name: "perturbateur endocrinien",
    parent: "Santé",
    value: 305,
    polarite: -24,
  },
  { name: "allocation", parent: "subvention", value: 273, polarite: 0 },
  { name: "bonus", parent: "subvention", value: 375, polarite: -1 },
  { name: "malus", parent: "subvention", value: 594, polarite: -12 },
  { name: "écotaxe", parent: "taxe", value: 322, polarite: -2 },
  { name: "produit importer", parent: "taxe", value: 67, polarite: -6 },
  { name: "taxe carbone", parent: "taxe", value: 1119, polarite: -6 },
  { name: "taxe écologique", parent: "taxe", value: 318, polarite: -1 },
  { name: "amende", parent: "taxe", value: 893, polarite: -93 },
  {
    name: "Agriculture",
    parent: "Transition écologique",
    value: 4910,
    polarite: 110,
  },
  {
    name: "Consommation",
    parent: "Transition écologique",
    value: 8732,
    polarite: -197,
  },
  { name: "Économie", parent: "Politique", value: 4470, polarite: 168 },
  {
    name: "Energie",
    parent: "Transition écologique",
    value: 10421,
    polarite: 13,
  },
  { name: "Humain", parent: "Transition écologique", value: 2931, polarite: 5 },
  {
    name: "Nature",
    parent: "Transition écologique",
    value: 2938,
    polarite: 73,
  },
  {
    name: "Politique",
    parent: "Transition écologique",
    value: 7559,
    polarite: 208,
  },
  {
    name: "Transport",
    parent: "Transition écologique",
    value: 10698,
    polarite: -43,
  },
  {
    name: "Urbanisme",
    parent: "Transition écologique",
    value: 313,
    polarite: -2,
  },
  { name: "avion", parent: "Transport", value: 3420, polarite: -87 },
  { name: "bateau", parent: "Transport", value: 1685, polarite: -52 },
  { name: "carburant", parent: "Transport", value: 3422, polarite: -110 },
  { name: "déplacement", parent: "Transport", value: 2549, polarite: -28 },
  { name: "électrique", parent: "Transport", value: 4228, polarite: -41 },
  { name: "ferroviaire", parent: "Transport", value: 1146, polarite: 5 },
  { name: "offre transport", parent: "Transport", value: 101, polarite: 10 },
  { name: "réseau transport", parent: "Transport", value: 224, polarite: 25 },
  {
    name: "transport routier",
    parent: "Transport",
    value: 1103,
    polarite: -42,
  },
  {
    name: "transports en commun",
    parent: "Transport",
    value: 3623,
    polarite: 143,
  },
  { name: "vélo", parent: "Transport", value: 1695, polarite: 26 },
  {
    name: "autoroute",
    parent: "transport routier",
    value: 1384,
    polarite: -32,
  },
  {
    name: "co-voiturage",
    parent: "transport routier",
    value: 173,
    polarite: 5,
  },
  {
    name: "covoiturage",
    parent: "transport routier",
    value: 679,
    polarite: 37,
  },
  { name: "parking", parent: "transport routier", value: 1138, polarite: 27 },
  { name: "routier", parent: "transport routier", value: 1286, polarite: -11 },
  {
    name: "véhicule",
    parent: "transport routier",
    value: 6107,
    polarite: -109,
  },
  {
    name: "transport collectif",
    parent: "transports en commun",
    value: 422,
    polarite: 22,
  },
  {
    name: "transport en commun",
    parent: "transports en commun",
    value: 3623,
    polarite: 0,
  },
  {
    name: "transports publics",
    parent: "transports en commun",
    value: 556,
    polarite: 30,
  },
  {
    name: "gratuité des transport",
    parent: "transports publics",
    value: 242,
    polarite: 2,
  },
  {
    name: "service public ferroviaire",
    parent: "transports publics",
    value: 6,
    polarite: 1,
  },
  {
    name: "transport public",
    parent: "transports publics",
    value: 556,
    polarite: 0,
  },
  { name: "tri sélectif", parent: "tri des déchet", value: 431, polarite: -2 },
  { name: "tri", parent: "tri sélectif", value: 700, polarite: 2 },
  { name: "bétonnage", parent: "Urbanisme", value: 174, polarite: -12 },
  { name: "Logement", parent: "Urbanisme", value: 3384, polarite: -32 },
  { name: "camion", parent: "véhicule", value: 3517, polarite: -138 },
  { name: "véhicule diesel", parent: "véhicule", value: 534, polarite: -16 },
  {
    name: "véhicule électrique",
    parent: "véhicule",
    value: 1872,
    polarite: -24,
  },
  { name: "véhicule essence", parent: "véhicule", value: 297, polarite: -5 },
  { name: "véhicule polluant", parent: "véhicule", value: 666, polarite: -38 },
  { name: "véhicule propre", parent: "véhicule", value: 522, polarite: 4 },
  { name: "voiture", parent: "véhicule", value: 7773, polarite: -124 },
  { name: "piste cyclabe", parent: "vélo", value: 1, polarite: 0 },
  { name: "vélo électrique", parent: "vélo", value: 224, polarite: 5 },
  { name: "bouteille", parent: "verre", value: 1152, polarite: -36 },
  { name: "automobiliste", parent: "voiture", value: 619, polarite: -36 },
  { name: "parc automobile", parent: "voiture", value: 301, polarite: -3 },
  { name: "voiture diesel", parent: "voiture", value: 358, polarite: -15 },
  { name: "voiture électrique", parent: "voiture", value: 2648, polarite: -78 },
  { name: "voiture individuel", parent: "voiture", value: 200, polarite: -2 },
  { name: "Transition écologique", parent: "", value: 9409, polarite: 233 },
];
