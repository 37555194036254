import * as React from "react";

export const CustomizedAxisTick = (props) => {
  const {
    axis,
    x,
    y,
    payload,
    fontSize,
    units,
    fill,
    tickAlign,
    tickFormatter,
    angle,
    type,
    useLocale,
  } = props;

  const v = tickFormatter ? tickFormatter(payload.value) : payload.value;
  const value =
    type === "number"
      ? useLocale
        ? v.toLocaleString() + units || ""
        : v + units || ""
      : v;

  //vertical align
  let dominantBaseline = "central";
  //horizontal align
  let textAnchor = "middle";

  if (axis === "x") {
    if (angle) {
      dominantBaseline = "central";
      if (angle === 90 && tickAlign === "bottom") {
        textAnchor = "start";
      }
      if (angle === -90 && tickAlign === "bottom") {
        textAnchor = "end";
      }
      if (angle === 90 && tickAlign === "top") {
        textAnchor = "end";
      }
      if (angle === -90 && tickAlign === "top") {
        textAnchor = "start";
      }
    } else {
      if (tickAlign === "bottom") {
        dominantBaseline = "hanging";
      } else {
        dominantBaseline = "top";
      }
    }
  }

  if (axis === "y") {
    if (tickAlign === "left") {
      textAnchor = "end";
    } else {
      textAnchor = "start";
    }
  }

  return x !== x || y !== y ? null : (
    <text
      x={x}
      y={y}
      dominantBaseline={dominantBaseline}
      fontSize={fontSize}
      textAnchor={textAnchor}
      fill={fill}
      color={fill}
      transform={
        axis === "x" ? "rotate(" + angle + " " + x + " " + y + ")" : undefined
      }
    >
      {value}
    </text>
  );
};

export default CustomizedAxisTick;
