import React from "react";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import { OPEN_POPUP } from "../utils/actions";

export function InputArray(props) {
  const dispatch = useDispatch();

  const handleOpenPopup = () => {
    dispatch(OPEN_POPUP({ content: "PopupArray", arrayProps: props }));
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        // paddingBottom: 8,
      }}
    >
      <Button
        variant="outlined"
        fullWidth={true}
        size="small"
        onClick={handleOpenPopup}
        style={{
          padding: 0,
          textTransform: "none",
        }}
      >
        edit
      </Button>
    </div>
  );
}

export default InputArray;
