import React from "react";
import { TextField, Grid, Autocomplete } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { UPDATE_CHART_SETTINGS } from "../utils/actions";
import { getObjValueByPath } from "../utils/utils";

export function InputComboBox(props) {
  const { name, inputSettings } = props;

  const chartUI = useSelector((state: any) => state.chart.chartUI);
  const settingsValue = getObjValueByPath(chartUI, name);
  const fonts = useSelector((state: any) => state.googleFonts);
  const dispatch = useDispatch();

  const VALUE = settingsValue;
  //hack - cannot dinamic update variants by choosing font
  const namePathArr = name.split(".");
  const isTitleOrCaptionFontFamily =
    (namePathArr[0] === "title" || namePathArr[0] === "caption") &&
    namePathArr[namePathArr.length - 1] === "fontFamily";
  let OPTIONS = [];
  if (isTitleOrCaptionFontFamily) {
    OPTIONS = fonts.length > 0 ? fonts.map((item) => item.family) : [];
  } else {
    OPTIONS = inputSettings.options;
  }

  function handleChange(event: any, newValue: string | null) {
    //hack
    if (isTitleOrCaptionFontFamily) {
      dispatch(UPDATE_CHART_SETTINGS(name, newValue));
      //update current font variant
      const newFontVariants = fonts.find(
        (item) => item.family === newValue
      ).variants;
      const isRegular = newFontVariants.find((item) => item === "regular");
      let namePathArr = name.split(".");
      namePathArr.pop();
      const namePath = namePathArr.join(".") + ".fontVariants";
      dispatch(
        UPDATE_CHART_SETTINGS(
          namePath,
          isRegular ? "regular" : newFontVariants[0]
        )
      );
    } else {
      dispatch(UPDATE_CHART_SETTINGS(name, newValue));
    }
  }

  const renderSelect = (
    <Autocomplete
      style={{ width: "100%", textAlign: "left", fontSize: "0.8125rem" }}
      options={OPTIONS}
      value={VALUE}
      disableClearable
      onChange={handleChange}
      // id="combobox"
      // clearOnEscape
      renderInput={(params) => {
        params.inputProps = {
          ...params.inputProps,
          style: { fontSize: "0.8125rem" },
        };
        return <TextField {...params} variant="standard" />;
      }}
    />
  );

  return (
    <Grid container alignItems="center">
      {renderSelect}
    </Grid>
  );
}

export default InputComboBox;
