import React, { useEffect } from "react";
import { ControlType, applyPropertyControls } from "../ui/controltype";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Line as RLine,
  LabelList,
  Tooltip,
  Customized,
} from "recharts";
import { TextBlock } from "./textBlock";
import { CustomizedDot } from "./customDot";
import { CustomizedAxisTick } from "./customTick";
import { CustomizedTooltip } from "./customTooltip";
import { useDispatch } from "react-redux";
import { UPDATE_CHART_REF } from "../utils/actions";
import { Helmet } from "react-helmet";
import {
  presetXAxis,
  presetYAxis,
  presetGrid,
  presetLayout,
  presetTitle,
  presetCaption,
  presetTooltip,
  presetRefDots,
  presetRefLines,
  presetRefAreas,
} from "./propertyControlsPresets";
import { CustomizedXAxisLabel, CustomizedYAxisLabel } from "./customLabel";
import {
  CustomizedRefDot,
  CustomizedRefLine,
  CustomizedRefArea,
} from "./customRef";
import {
  getDomains,
  normalizeDimensions,
  getValidDimensionDataArr,
  getTicks,
} from "../utils/utils";
import { useHeaderHeight } from "./customHooks";

function getValuesSin(count) {
  return Array.from({ length: count }, (item, i) => {
    return Math.trunc(Math.sin((i * 10 * Math.PI) / 180) * 60);
  });
}

function getValuesCos(count) {
  return Array.from({ length: count }, (item, i) => {
    return Math.trunc(Math.cos((i * 10 * Math.PI) / 180) * 60);
  });
}

const barsCount = 82;
const yMin = getValuesSin(barsCount);
const yMax = getValuesCos(barsCount);
const dimensionsData = { x: "items", y: "bars" }; // link data with dimensions

//TO FIX (remove range's dependencies)
const CustomizedLabel = (props) => {
  const {
    x,
    y,
    value,
    fontSize,
    layout,
    units,
    fill,
    dotsSize,
    index,
    dotsType,
    dataValues,
  } = props;

  const gap = [
    "direction",
    "arrow left",
    "arrow right",
    "arrow up",
    "arrow down",
    "none",
  ].includes(dotsType)
    ? 8
    : 8 + dotsSize;
  const currData = dataValues[index];
  const valueRange = currData.valuesBar.find((elem) => elem !== value);
  const isLager = value > valueRange;
  const vertAlign = layout ? (isLager ? "baseline" : "hanging") : "middle";
  const horizAlign = layout ? "middle" : isLager ? "start" : "end";
  const coordsX = layout ? x : isLager ? x + gap : x - gap;
  const coordsY = layout ? (isLager ? y - gap : y + gap) : y;

  return (
    <text
      x={coordsX}
      y={coordsY}
      alignmentBaseline={vertAlign}
      fontSize={fontSize}
      textAnchor={horizAlign}
      fill={fill}
      color={fill}
    >
      {!isNaN(parseFloat(value)) && isFinite(value)
        ? value.toLocaleString() + units
        : null}
    </text>
  );
};

export function Range(props) {
  const {
    layoutSettings,
    chartSettings,
    dataItems,
    dataSeries,
    dataSeries2,
    xAxis,
    yAxis,
    grid,
    title,
    caption,
    tooltip,
    dimensions,
    refDots,
    refLines,
    refAreas,
  } = props;

  const { background, width } = layoutSettings;
  const { margin } = chartSettings;

  const dataItemsJSON = JSON.stringify(dataItems.items);
  const dataSeriesJSON = JSON.stringify(dataSeries);
  const dataSeriesJSON_2 = JSON.stringify(dataSeries2);

  const dispatch = useDispatch();

  const [data, setData] = React.useState([]);
  const [domain, setDomain] = React.useState({} as any);
  const [ticks, setTicks] = React.useState({} as any);
  const [dataTypes, setDataTypes] = React.useState({} as any);
  const [isData, setIsData] = React.useState(false);
  const [layout, setLayout] = React.useState(chartSettings.chartLayout);
  const [fontLoaded, setFontLoaded] = React.useState(false);

  const chartRef = React.useRef<HTMLDivElement>(null);
  const { headerRef, height } = useHeaderHeight(
    width,
    layoutSettings,
    caption,
    title,
    {},
    ""
  );

  // custom fonts loading status
  title["fontLoaded"] = fontLoaded;
  for (let i = 0; i < caption.items.length; i++) {
    caption.items[i]["fontLoaded"] = fontLoaded;
  }

  function buildData() {
    const itemsValid = getValidDimensionDataArr(
      dataItems.items,
      dimensions.items
    );

    const seriesValid_1 = getValidDimensionDataArr(
      dataSeries.bars,
      dimensions.bars
    );
    const seriesValid_2 = getValidDimensionDataArr(
      dataSeries2.bars2,
      dimensions.bars2
    );

    let chartData = [];
    if (seriesValid_1.length > 0 && seriesValid_2.length > 0) {
      chartData = itemsValid.map((elem, i) => {
        let values = {
          name: elem,
          valuesBar: [seriesValid_1[i], seriesValid_2[i]],
          valueY1: seriesValid_1[i],
          valueY2: seriesValid_2[i],
        };

        return values;
      });
    }

    return chartData;
  }

  useEffect(() => {
    document.fonts.ready.then(() => {
      setFontLoaded(true);
    });
  });

  let isNewData = false;

  useEffect(() => {
    const dataBuild = buildData();
    const chartDimensions = normalizeDimensions(dimensions);
    const chartDomain = getDomains({
      x: xAxis,
      y: yAxis,
      dimensions: chartDimensions,
      dimensionsData: dimensionsData,
      layout: chartSettings.chartLayout,
    });
    const chartTicks = getTicks(
      dataBuild,
      chartDimensions,
      chartDomain,
      xAxis,
      yAxis,
      dimensionsData,
      chartSettings.chartLayout
    );
    const isDataNotEmpty = dataBuild.length > 0;

    setData(dataBuild);
    setDomain(chartDomain);
    setDataTypes(chartDimensions);
    setTicks(chartTicks);
    setIsData(isDataNotEmpty);
    setLayout(chartSettings.chartLayout);

    isNewData = true;
  }, [
    dataItemsJSON,
    dataSeriesJSON,
    dataSeriesJSON_2,
    dimensions.items,
    dimensions.bars,
    dimensions.bars2,
    chartSettings.chartLayout,
  ]);

  useEffect(() => {
    if (isData && !isNewData) {
      const chartDomain = getDomains({
        x: xAxis,
        y: yAxis,
        dimensions: dataTypes,
        dimensionsData: dimensionsData,
        layout: layout,
      });
      const chartTicks = getTicks(
        data,
        dataTypes,
        chartDomain,
        xAxis,
        yAxis,
        dimensionsData,
        layout
      );

      setDomain(chartDomain);
      setTicks(chartTicks);
    }
  }, [
    xAxis.domain,
    xAxis.domainMax,
    xAxis.domainMaxValue,
    xAxis.domainMin,
    xAxis.domainMinValue,
    yAxis.domain,
    yAxis.domainMax,
    yAxis.domainMaxValue,
    yAxis.domainMin,
    yAxis.domainMinValue,
  ]);

  useEffect(() => {
    if (isData && !isNewData) {
      const chartTicks = getTicks(
        data,
        dataTypes,
        domain,
        xAxis,
        yAxis,
        dimensionsData,
        layout
      );
      setTicks(chartTicks);
    }
  }, [
    xAxis.isTicksAuto,
    xAxis.tickCountValue,
    xAxis.tickCountAccuracy,
    xAxis.tickCountCategory,
    xAxis.tickCountValueCategory,
    yAxis.isTicksAuto,
    yAxis.tickCountValue,
    yAxis.tickCountAccuracy,
    yAxis.tickCountCategory,
    yAxis.tickCountValueCategory,
  ]);

  useEffect(() => {
    const element = chartRef.current;
    if (element) {
      const svg = element;
      dispatch(UPDATE_CHART_REF(svg));
    }
  }, [chartRef, dispatch]);

  return (
    <div ref={chartRef} style={{ background: background }}>
      <Helmet>
        <link
          rel="stylesheet"
          href={
            "https://fonts.googleapis.com/css?family=" +
            title.fontFamily.replace(/ /g, "+") +
            ":" +
            title.fontVariants
          }
          crossOrigin="anonymous"
        />
        {caption.items.map((elem, i) => {
          return (
            <link
              key={"caption-" + i}
              rel="stylesheet"
              href={
                "https://fonts.googleapis.com/css?family=" +
                elem.fontFamily.replace(/ /g, "+") +
                ":" +
                elem.fontVariants
              }
              crossOrigin="anonymous"
            />
          );
        })}
      </Helmet>
      <div ref={headerRef} style={{ width: width }}>
        {title.isVisible ? (
          <TextBlock key={"title"} title={title} fontLoaded={fontLoaded} />
        ) : (
          ""
        )}
        {caption.isVisible
          ? caption.items.map((elem, i) => {
              return (
                <TextBlock
                  key={"caption" + i}
                  title={elem}
                  fontLoaded={fontLoaded}
                />
              );
            })
          : ""}
      </div>
      {isData ? (
        <ComposedChart
          width={width}
          height={height}
          data={data}
          layout={layout ? "horizontal" : "vertical"}
          margin={
            margin.isPerSide
              ? {
                  top: margin.top,
                  right: margin.right,
                  left: margin.left,
                  bottom: margin.bottom,
                }
              : {
                  top: margin.margin,
                  right: margin.margin,
                  left: margin.margin,
                  bottom: margin.margin,
                }
          }
          style={{ background: background }}
          barSize={chartSettings.barSize}
        >
          {refAreas.isVisible
            ? refAreas.items.map((elem, i) => (
                <Customized
                  key={"refArea-" + i}
                  component={
                    <CustomizedRefArea
                      cProps={{
                        elem: elem,
                        dataTypes: {
                          items: dataTypes.items,
                          series: dataTypes.bars,
                        },
                        existedValue: {
                          x: data[0]["name"],
                          y: data[0]["valueY1"],
                        },
                        layout: layout,
                        xAxisSettings: xAxis,
                        yAxisSettings: yAxis,
                        marginSettings: margin,
                        width: width,
                        height: height,
                      }}
                    />
                  }
                />
              ))
            : null}
          <CartesianGrid
            horizontal={grid.gridHoriz}
            vertical={grid.gridVert}
            stroke={grid.gridColor}
            syncWithTicks={true}
          />
          {tooltip.isVisible ? (
            <Tooltip
              separator={tooltip.separator}
              cursor={
                tooltip.showCursor
                  ? { strokeWidth: 1, stroke: tooltip.cursorColor }
                  : false
              }
              content={
                <CustomizedTooltip
                  txtColor={tooltip.txtColor}
                  background={tooltip.bgColor}
                  chartType={"range"}
                  layout={layout}
                  units={{ x: xAxis.units, y: yAxis.units }}
                  axesDataTypes={{
                    x: layout ? dataTypes.items : dataTypes.bars,
                    y: layout ? dataTypes.bars : dataTypes.items,
                  }}
                  axesLabels={{ x: xAxis.labelText, y: yAxis.labelText }}
                  useAxesLabels={tooltip.useAxesLabels}
                  useLocales={{
                    x: xAxis.useLocale,
                    y: yAxis.useLocale,
                  }}
                />
              }
            />
          ) : (
            ""
          )}
          <XAxis
            dataKey={layout ? "name" : undefined}
            type={layout ? dataTypes.items : dataTypes.bars}
            axisLine={xAxis.isAxisLine}
            ticks={ticks.x}
            interval={0}
            reversed={xAxis.tickOrder}
            hide={!xAxis.isVisible}
            height={xAxis.height}
            domain={domain.X}
            stroke={xAxis.color}
            orientation={xAxis.orient}
            tickSize={xAxis.tickSize}
            tickMargin={xAxis.tickMargin}
            tick={
              ticks.x.length == 0 ? (
                false
              ) : (
                <CustomizedAxisTick
                  fontSize={xAxis.tickFontSize}
                  axis={"x"}
                  units={xAxis.units ? xAxis.units : ""}
                  useLocale={xAxis.useLocale}
                  type={layout ? dataTypes.items : dataTypes.bars}
                  tickAlign={xAxis.orient}
                  angle={xAxis.tickRotate}
                />
              )
            }
            label={
              xAxis.isLabelVisible ? (
                <CustomizedXAxisLabel
                  value={xAxis.labelText}
                  fontSize={xAxis.labelFontSize}
                  color={xAxis.labelColor}
                  align={xAxis.labelAlign}
                  margin={xAxis.labelMargin}
                  axisOrient={xAxis.orient}
                />
              ) : (
                ""
              )
            }
          />
          <YAxis
            dataKey={layout ? undefined : "name"}
            type={layout ? dataTypes.bars : dataTypes.items}
            axisLine={yAxis.isAxisLine}
            ticks={ticks.y}
            interval={0}
            reversed={yAxis.tickOrder}
            hide={!yAxis.isVisible}
            width={yAxis.width}
            domain={domain.Y}
            stroke={yAxis.color}
            orientation={yAxis.orient}
            // allowDuplicatedCategory={false}
            tickSize={yAxis.tickSize}
            tickMargin={yAxis.tickMargin}
            tick={
              ticks.y.length == 0 ? (
                false
              ) : (
                <CustomizedAxisTick
                  fontSize={yAxis.tickFontSize}
                  axis={"y"}
                  units={yAxis.units ? yAxis.units : ""}
                  useLocale={yAxis.useLocale}
                  type={layout ? dataTypes.bars : dataTypes.items}
                  tickAlign={yAxis.orient}
                />
              )
            }
            label={
              yAxis.isLabelVisible ? (
                <CustomizedYAxisLabel
                  value={yAxis.labelText}
                  fontSize={yAxis.labelFontSize}
                  color={yAxis.labelColor}
                  align={yAxis.labelAlign}
                  margin={yAxis.labelMargin}
                  axisOrient={yAxis.orient}
                  rotateAngle={yAxis.labelRotate}
                />
              ) : (
                ""
              )
            }
          />
          <Bar
            dataKey="valuesBar"
            background={{ fill: chartSettings.bgColor }}
            fill={chartSettings.barColor}
            radius={
              chartSettings.barRadius.isPerCorner
                ? [
                    chartSettings.barRadius.TL,
                    chartSettings.barRadius.TR,
                    chartSettings.barRadius.BL,
                    chartSettings.barRadius.BR,
                  ]
                : chartSettings.barRadius.barRadius
            }
            isAnimationActive={false} // turn off animation because of dot's bug - https://github.com/recharts/recharts/issues/1426
            // animationDuration={500}
          />
          <RLine
            dataKey="valueY2"
            strokeWidth={0}
            dot={(d) => {
              return (
                <CustomizedDot
                  key={d.key}
                  layout={layout}
                  cx={d.cx}
                  cy={d.cy}
                  Y={2}
                  dotsTypeDirection={chartSettings.dotsTypeDirection}
                  dotsType={
                    chartSettings.dotsType
                      ? "direction"
                      : chartSettings.dotsTypeY2
                  }
                  valueY1={d.payload.valueY1}
                  valueY2={d.payload.valueY2}
                  dotsStyle={chartSettings.dotsStyle}
                  dotsColor={chartSettings.dotsColor}
                  dotsSize={chartSettings.dotsSize}
                  dotsStrokeWidth={chartSettings.dotsStrokeWidth}
                />
              );
            }}
            isAnimationActive={false}
          >
            {chartSettings.showLabels ? (
              <LabelList
                content={
                  <CustomizedLabel
                    fill={chartSettings.labelsColor}
                    fontSize={chartSettings.labelsFontSize}
                    layout={layout}
                    units={layout ? yAxis.units : xAxis.units}
                    lineKey={"valueY2"}
                    dotsTypeDirection={chartSettings.dotsTypeDirection}
                    dotsType={
                      chartSettings.dotsType
                        ? "direction"
                        : chartSettings.dotsTypeY2
                    }
                    dataValues={data}
                    dotsSize={chartSettings.dotsSize}
                  />
                }
              />
            ) : (
              ""
            )}
          </RLine>
          <RLine
            dataKey="valueY1"
            strokeWidth={0}
            dot={(d) => {
              return (
                <CustomizedDot
                  key={d.key}
                  layout={layout}
                  cx={d.cx}
                  cy={d.cy}
                  Y={1}
                  dotsTypeDirection={chartSettings.dotsTypeDirection}
                  dotsType={
                    chartSettings.dotsType
                      ? "direction"
                      : chartSettings.dotsTypeY1
                  }
                  valueY1={d.payload.valueY1}
                  valueY2={d.payload.valueY2}
                  dotsStyle={chartSettings.dotsStyle}
                  dotsColor={chartSettings.dotsColor}
                  dotsSize={chartSettings.dotsSize}
                  dotsStrokeWidth={chartSettings.dotsStrokeWidth}
                />
              );
            }}
            isAnimationActive={false} // turn off animation because of dot's bug - https://github.com/recharts/recharts/issues/1426
            // isAnimationActive={false}
          >
            {chartSettings.showLabels ? (
              <LabelList
                content={
                  <CustomizedLabel
                    fill={chartSettings.labelsColor}
                    fontSize={chartSettings.labelsFontSize}
                    layout={layout}
                    units={layout ? yAxis.units : xAxis.units}
                    lineKey={"valueY1"}
                    dotsTypeDirection={chartSettings.dotsTypeDirection}
                    dotsType={
                      chartSettings.dotsType
                        ? "direction"
                        : chartSettings.dotsTypeY1
                    }
                    dataValues={data}
                    dotsSize={chartSettings.dotsSize}
                  />
                }
              />
            ) : (
              ""
            )}
          </RLine>
          {refLines.isVisible
            ? refLines.items.map((elem, i) => (
                <Customized
                  key={"refLine-" + i}
                  component={
                    <CustomizedRefLine
                      cProps={{
                        elem: elem,
                        dataTypes: {
                          items: dataTypes.items,
                          series: dataTypes.bars,
                        },
                        existedValue: {
                          x: data[0]["name"],
                          y: data[0]["valueY1"],
                        },
                        layout: layout,
                        xAxisSettings: xAxis,
                        yAxisSettings: yAxis,
                        marginSettings: margin,
                        width: width,
                        height: height,
                      }}
                    />
                  }
                />
              ))
            : null}
          {refDots.isVisible
            ? refDots.items.map((elem, i) => (
                <Customized
                  key={"refDot-" + i}
                  component={
                    <CustomizedRefDot
                      cProps={{
                        elem: elem,
                        dataTypes: {
                          items: dataTypes.items,
                          series: dataTypes.bars,
                        },
                        existedValue: {
                          x: data[0]["name"],
                          y: data[0]["valueY1"],
                        },
                        layout: layout,
                        xAxisSettings: xAxis,
                        yAxisSettings: yAxis,
                        marginSettings: margin,
                        width: width,
                        height: height,
                      }}
                    />
                  }
                />
              ))
            : null}
        </ComposedChart>
      ) : (
        <span style={{ opacity: 0.25 }}>Not enough data to chart</span>
      )}
    </div>
  );
}

const defaultDimensionsTypes = {
  items: ControlType.String,
  bars: ControlType.Number,
  bars2: ControlType.Number,
};

Range.defaultProps = {
  isData: false,
  layoutSettings: {
    isFullscreen: true,
    isWidthAuto: true,
    minWidth: 320,
    maxWidth: 2500,
    isHeightAuto: true,
    background: "rgba(255, 255, 255, 1)",
  },
  chartSettings: {
    chartLayout: true,
    barSize: 1,
    barColor: "rgba(17, 153, 238, 1)",
    barRadius: {
      barRadius: 0,
      isPerCorner: false,
      TL: 0,
      TR: 0,
      BL: 0,
      BR: 0,
    },
    bgColor: "rgba(17, 153, 238, 0)",
    dotsType: true,
    dotsTypeDirection: true,
    dotsTypeY1: "circle",
    dotsTypeY2: "circle",
    dotsSize: 16,
    dotsColor: "rgba(17, 153, 238, 1)",
    dotsStyle: true,
    dotsStrokeWidth: 1,
    showLabels: false,
    labelsColor: "rgba(17, 153, 238, 1)",
    labelsFontSize: 12,
    margin: {
      margin: 0,
      isPerSide: false,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  sourceData: true,
  fileData: null,
  xAxis: {
    isVisible: false,
    isLabelVisible: true,
    labelText: "X label",
    labelAlign: "middle",
    labelMargin: -20,
    color: "rgba(17, 153, 238, 1)",
    labelFontSize: 12,
    labelColor: "rgba(17, 153, 238, 1)",
    height: 60,
    orient: "bottom",
    isAxisLine: true,
    domain: true,
    domainMin: "dataMin",
    domainMinValue: 0,
    domainMax: "dataMax",
    domainMaxValue: 1,
    tickFontSize: 12,
    isTicksAuto: true,
    tickCountValue: 5,
    tickCountAccuracy: false,
    tickCountCategory: true,
    tickCountValueCategory: 5,
    tickRotate: 0,
    tickSize: 6,
    tickMargin: 4,
    tickOrder: false,
    units: "",
    useLocale: true,
  },
  yAxis: {
    isVisible: false,
    isLabelVisible: true,
    labelText: "Y label",
    labelAlign: "middle",
    labelMargin: -20,
    color: "rgba(17, 153, 238, 1)",
    labelFontSize: 12,
    labelRotate: -90,
    labelColor: "rgba(17, 153, 238, 1)",
    width: 60,
    orient: "left",
    isAxisLine: true,
    domain: false,
    domainMin: 0,
    domainMinValue: 0,
    domainMax: "dataMax",
    domainMaxValue: 1,
    tickFontSize: 12,
    isTicksAuto: true,
    tickCountValue: 5,
    tickCountAccuracy: false,
    tickCountCategory: true,
    tickCountValueCategory: 5,
    tickSize: 6,
    tickMargin: 4,
    tickOrder: false,
    units: "",
    useLocale: true,
  },
  grid: {
    gridHoriz: false,
    gridVert: false,
    gridColor: "rgba(17, 153, 238, .5)",
  },
  dataItems: {
    items: [...Array(barsCount)].map((_, i) => {
      const numToLetters = (num) => {
        let mod = num % 26,
          pow = (num / 26) | 0,
          out = mod ? String.fromCharCode(64 + mod) : (--pow, "Z");
        return pow ? numToLetters(pow) + out : out;
      };
      return numToLetters(i + 1);
    }),
  },
  dataSeries: {
    bars: yMin,
  },
  dataSeries2: {
    bars2: yMax,
  },
  dimensions: defaultDimensionsTypes,
  title: {
    isVisible: false,
    text: "Chart title",
    txtColor: "rgba(0,0,0,1)",
    fontSize: 48,
    fontFamily: "Roboto",
    fontVariants: "regular",
    align: "center",
    margin: {
      margin: 0,
      isPerSide: true,
      top: 20,
      bottom: 20,
      left: 0,
      right: 0,
    },
  },
  refDots: {
    isVisible: false,
    items: [
      {
        xCoordinateNumber: null,
        xCoordinateCategory: "",
        yCoordinateNumber: null,
        yCoordinateCategory: "",
        dotSize: 16,
        dotColor: "rgba(238, 68, 69, 1)",
        dotStyle: true,
        dotStrokeWidth: 1,
        showLabel: false,
        name: "",
        labelColor: "rgba(0, 0, 0, 1)",
        labelFontSize: 12,
        labelPosition: "R",
        labelOffsetX: 0,
        labelOffsetY: 0,
        labelWrap: true,
        labelWidth: 20,
        showLabelLine: true,
        labelLineColor: "rgba(0, 0, 0, 1)",
        showLabelImage: false,
        labelImgUrl: "",
        labelImgPosition: false,
        labelImgCustomSize: false,
        labelImgWidth: 200,
      },
    ],
  },
  refLines: {
    isVisible: false,
    items: [
      {
        orient: true,
        xCoordinateNumber: null,
        xCoordinateCategory: "",
        yCoordinateNumber: null,
        yCoordinateCategory: "",
        lineColor: "rgba(238, 68, 69, 1)",
        lineStrokeWidth: 1,
        lineStyle: "Solid",
        showLabel: false,
        name: "",
        labelColor: "rgba(0, 0, 0, 1)",
        labelFontSize: 12,
        labelPosition: "TC",
        labelOffsetX: 0,
        labelOffsetY: 0,
        labelWrap: true,
        labelWidth: 20,
        labelPositionHoriz: "center",
        labelPositionVertOrient: false,
        labelPositionVert: "center",
        labelPositionVertAlign: "center",
      },
    ],
  },
  refAreas: {
    isVisible: false,
    items: [
      {
        x1CoordinateNumber: null,
        x2CoordinateNumber: null,
        x1CoordinateCategory: "",
        x2CoordinateCategory: "",
        y1CoordinateNumber: null,
        y2CoordinateNumber: null,
        y1CoordinateCategory: "",
        y2CoordinateCategory: "",
        fillColor: "rgba(238, 68, 69, .2)",
        strokeColor: "rgba(0, 0, 0, 1)",
        strokeWidth: 0,
        strokeStyle: "Solid",
      },
    ],
  },
  caption: {
    isVisible: false,
    items: [
      {
        text: "Chart description or data source",
        txtColor: "rgba(0,0,0,.5)",
        fontSize: 18,
        fontFamily: "Roboto",
        fontVariants: "300",
        align: "center",
        margin: {
          margin: 0,
          isPerSide: true,
          top: 20,
          bottom: 20,
          left: 0,
          right: 0,
        },
      },
    ],
  },
  tooltip: {
    isVisible: false,
    txtColor: "rgba(0,0,0,1)",
    bgColor: "rgba(255,255,255,1)",
    separator: ": ",
    useAxesLabels: false,
    showCursor: true,
    cursorColor: "rgba(17, 153, 238, 1)",
  },
};

applyPropertyControls(Range, {
  //Settings or data
  isData: {
    type: ControlType.Boolean,
    title: "Change",
    disabledTitle: "settings",
    enabledTitle: "data",
    wide: true,
  },

  layoutSettings: presetLayout,

  chartSettings: {
    type: ControlType.Group,
    title: "Bars settings",
    isHeaderControls: false,
    propertyControl: {
      chartLayout: {
        type: ControlType.Boolean,
        title: "Layout",
        enabledTitle: "horizontal",
        disabledTitle: "vertical",
      },
      barSize: {
        type: ControlType.Number,
        title: "Bar size",
      },
      barColor: {
        type: ControlType.Color,
        title: "Bar color",
      },
      barRadius: {
        type: ControlType.FusedNumber,
        title: "Radius",
        toggleKey: "isPerCorner",
        toggleTitles: ["All", "Individual"],
        valueKeys: ["TL", "TR", "BL", "BR"],
        valueLabels: ["TL", "TR", "BL", "BR"],
        min: 0,
      },
      bgColor: {
        type: ControlType.Color,
        title: "Bg color",
      },
      // Dots
      dotsType: {
        type: ControlType.Boolean,
        title: "Dots type",
        enabledTitle: "direction",
        disabledTitle: "custom",
      },
      dotsTypeDirection: {
        type: ControlType.Boolean,
        title: "↳ Direction",
        enabledTitle: "Y1→Y2",
        disabledTitle: "Y1←Y2",
        hidden(props) {
          return props.dotsType === false;
        },
      },
      dotsTypeY1: {
        type: ControlType.Enum,
        title: "↳ Y1",
        options: [
          "circle",
          "square",
          "rhombus",
          "cross",
          "star",
          "arrow left",
          "arrow right",
          "arrow up",
          "arrow down",
          "none",
        ],
        hidden(props) {
          return props.dotsType === true;
        },
      },
      dotsTypeY2: {
        type: ControlType.Enum,
        title: "↳ Y2",
        options: [
          "circle",
          "square",
          "rhombus",
          "cross",
          "star",
          "arrow left",
          "arrow right",
          "arrow up",
          "arrow down",
          "none",
        ],
        hidden(props) {
          return props.dotsType === true;
        },
      },
      dotsSize: {
        type: ControlType.Number,
        title: "Dots  size",
        displayStepper: true,
      },
      dotsColor: {
        title: "Dots color",
        type: ControlType.Color,
      },
      dotsStyle: {
        type: ControlType.Boolean,
        title: "Dots style",
        enabledTitle: "fill",
        disabledTitle: "stroke",
      },
      dotsStrokeWidth: {
        type: ControlType.Number,
        title: "Dots stroke",
        displayStepper: true,
        hidden(props) {
          return props.dotsStyle === true;
        },
      },
      // Labels
      showLabels: {
        type: ControlType.Boolean,
        title: "Labels",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      labelsColor: {
        type: ControlType.Color,
        title: "↳ Color",
        hidden(props) {
          return props.showLabels === false;
        },
      },
      labelsFontSize: {
        type: ControlType.Number,
        title: "↳ Size",
        hidden(props) {
          return props.showLabels === false;
        },
      },
      margin: {
        type: ControlType.FusedNumber,
        title: "Margin",
        toggleKey: "isPerSide",
        toggleTitles: ["All", "Individual"],
        valueKeys: ["top", "right", "bottom", "left"],
        valueLabels: ["T", "R", "B", "L"],
        min: 0,
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Axes
  xAxis: presetXAxis(dimensionsData, true), // link data with axes
  yAxis: presetYAxis(dimensionsData, true),

  //Grid
  grid: presetGrid,

  //Reference dots
  refDots: presetRefDots(dimensionsData, true),

  //Reference lines
  refLines: presetRefLines(dimensionsData, true),

  //Reference areas
  refAreas: presetRefAreas(dimensionsData, true),

  //Title
  title: presetTitle,

  //Caption
  caption: presetCaption,

  //Tooltip
  tooltip: {
    ...presetTooltip(true),
    propertyControl: {
      separator: {
        type: ControlType.String,
        title: "Separator",
      },
      useAxesLabels: {
        type: ControlType.Boolean,
        title: "Axes labels",
        enabledTitle: "use",
        disabledTitle: "ignore",
      },
      ...presetTooltip(true).propertyControl,
    },
  },

  //Data
  sourceData: {
    type: ControlType.Boolean,
    title: "Data",
    enabledTitle: "editable",
    disabledTitle: "file",
    hidden(props) {
      return props.isData === false;
    },
  },
  fileData: {
    title: "↳ File",
    type: ControlType.File,
    allowedFileTypes: ["xls"],
    hidden(props) {
      return props.sourceData === true || props.isData === false;
    },
  },

  // Data types
  dimensions: {
    type: ControlType.Group,
    title: "Axes data types",
    propertyControl: {
      items: {
        type: ControlType.Enum,
        seriesType: false,
        title(props, ui) {
          return ui.chartSettings.chartLayout ? "X" : "Y";
        },
        path: "dataItems", // path to data
        optionTitles: ["number", "category"],
        options: [ControlType.Number, ControlType.String],
        displaySegmentedControl: true,
      },
      bars: {
        type: ControlType.Enum,
        seriesType: false,
        title(props, ui) {
          return ui.chartSettings.chartLayout ? "Y1" : "X1";
        },
        path: "dataSeries", // path to data
        optionTitles: ["number", "category"],
        options: [ControlType.Number],
        displaySegmentedControl: true,
        hidden(props) {
          return true;
        },
      },
      bars2: {
        type: ControlType.Enum,
        seriesType: false,
        title(props, ui) {
          return ui.chartSettings.chartLayout ? "Y2" : "X2";
        },
        path: "dataSeries2", // path to data
        optionTitles: ["number", "category"],
        options: [ControlType.Number],
        displaySegmentedControl: true,
        hidden(props) {
          return true;
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },

  // Items data (usually - x axis)
  dataItems: {
    type: ControlType.Group,
    title(props) {
      return (
        "Items — " + (props.chartSettings.chartLayout ? "X" : "Y") + " axis"
      );
    },
    propertyControl: {
      items: {
        type: ControlType.Array,
        title: "Values",
        propertyControl: {
          type(props) {
            return props.dimensions.items;
          },
          isNullsAllowed: true,
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },

  // Values
  dataSeries: {
    type: ControlType.Group,
    title(props) {
      return (
        "Values 1 — " + (props.chartSettings.chartLayout ? "Y" : "X") + " axis"
      );
    },
    propertyControl: {
      bars: {
        type: ControlType.Array,
        title: "Values",
        propertyControl: {
          type(props) {
            return props.dimensions.bars;
          },
          isNullsAllowed: true,
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },

  dataSeries2: {
    type: ControlType.Group,
    title(props) {
      return (
        "Values 2 — " + (props.chartSettings.chartLayout ? "Y" : "X") + " axis"
      );
    },
    propertyControl: {
      bars2: {
        type: ControlType.Array,
        title: "Values",
        propertyControl: {
          type(props) {
            return props.dimensions.bars2;
          },
          isNullsAllowed: true,
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },
});

export default Range;
