import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Tooltip,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";
import { styled } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";
import {
  getFirestore,
  doc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import {
  getChartList,
  getChartFromDbById,
  getChartsLimit,
} from "../../firestore";
import {
  UPDATE_CURRENT_CHART_NAME,
  UPDATE_CURRENT_CHART_ID,
  IS_CURRENT_CHART_SAVED,
  LOAD_CHART_SETTINGS_FROM_DB,
} from "../utils/actions";
import "../../App.css";

const listStyle = {
  width: "80%",
  maxWidth: 800,
  minWidth: 300,
  backgroundColor: "background.paper",
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    color: "white",
    fontSize: "14px",
  },
}));

export function Profile(props) {
  const { handleClose } = props;

  const isSignedIn = useSelector((state: any) => state.firebase.isSignedIn);
  const collectionCharts = useSelector(
    (state: any) => state.firebase.collectionCharts
  );
  const collectionUsers = useSelector(
    (state: any) => state.firebase.collectionUsers
  );
  const currentChartId = useSelector(
    (state: any) => state.firebase.currentChartId
  );
  const isCurrentChartSaved = useSelector(
    (state: any) => state.firebase.isCurrentChartSaved
  );

  const [isLoadingChartsList, setIsLoadingChartsList] = React.useState(true);
  const [isLoadingChartsLimit, setIsLoadingChartsLimit] = React.useState(true);
  const [isLoadingChart, setIsLoadingChart] = React.useState(false);
  const [chartsList, setChartsList] = React.useState([]);
  const [editChart, setEditChart] = React.useState({
    id: "",
    name: "",
    action: "",
  });
  const [saveChartNameProgress, setSaveChartNameProgress] =
    React.useState(false);
  const [saveError, setSaveError] = React.useState(false);
  const [popupDeleteAlertOpened, setPopupDeleteAlertOpened] =
    React.useState(false);
  const [popupLoadAlertOpened, setPopupLoadAlertOpened] = React.useState(false);
  const [userChartsLimit, setUserChartsLimit] = React.useState(null);

  const auth = getAuth();
  const db = getFirestore();
  const dispatch = useDispatch();

  async function loadChartList(auth, collectionCharts) {
    setIsLoadingChartsList(true);
    await getChartList(auth, collectionCharts).then((result: any) => {
      if (result) {
        setChartsList(result);
      }
      setIsLoadingChartsList(false);
    });
  }

  React.useEffect(() => {
    loadChartList(auth, collectionCharts);
    setIsLoadingChartsLimit(true);
    getChartsLimit(auth, db, collectionUsers).then((result: any) => {
      if (result) {
        setUserChartsLimit(result);
      }
      setIsLoadingChartsLimit(false);
    });
  }, []);

  const selectChart = async (chartId, isDiscardChanges) => {
    if (!isCurrentChartSaved && !isDiscardChanges) {
      setEditChart({ id: chartId, name: "", action: "load" });
      setPopupLoadAlertOpened(true);
    } else {
      if (chartId) {
        setIsLoadingChart(true);
        getChartFromDbById(chartId, db, collectionCharts).then((result) => {
          if (result) {
            dispatch(LOAD_CHART_SETTINGS_FROM_DB(result));
          }
          setIsLoadingChart(false);
          handleClose();
        });
      } else {
        handleClose();
      }
    }
  };

  const ChartsListItem = ({ chartId, chartName, updated }) => {
    const handleEditChartName = () => {
      setEditChart({ id: chartId, name: chartName, action: "rename" });
    };

    const handleOpenPopupDeleteAlert = () => {
      setPopupDeleteAlertOpened(true);
      setEditChart({ id: chartId, name: chartName, action: "delete" });
    };

    const handleSelectChart = () => {
      selectChart(chartId, false);
    };

    return (
      <>
        <ListItem button>
          <ListItemText
            primary={chartName}
            secondary={"⟳ " + new Date(updated).toLocaleString()}
            onClick={() => handleSelectChart()}
          />
          <ListItemSecondaryAction>
            <HtmlTooltip
              title="Rename"
              enterDelay={600}
              enterNextDelay={600}
              arrow
            >
              <IconButton
                aria-label="Rename chart"
                onClick={() => handleEditChartName()}
              >
                <EditIcon />
              </IconButton>
            </HtmlTooltip>
            <HtmlTooltip
              title="Delete"
              enterDelay={600}
              enterNextDelay={600}
              arrow
            >
              <IconButton
                edge="end"
                area-label="Delete chart"
                onClick={() => handleOpenPopupDeleteAlert()}
              >
                <DeleteIcon />
              </IconButton>
            </HtmlTooltip>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider component="li" />
      </>
    );
  };

  const ChartsListItemInput = () => {
    // update local state while using keyboard
    const handleChange = (event: React.ChangeEvent<{ value: string }>) => {
      const value = event.target.value;
      const str = !value.replace(/\s/g, "").length ? "" : value; // if value contains only spaces, delete it
      setEditChart({ ...editChart, name: str });
    };

    const handleSaveChartName = async () => {
      setSaveChartNameProgress(true);

      const docRef = doc(db, collectionCharts, editChart.id);

      try {
        await updateDoc(docRef, {
          chartName: editChart.name,
          updated: serverTimestamp(),
        }).then(() => {
          setEditChart({ id: "", name: "", action: "" });
          setSaveChartNameProgress(false);
          setSaveError(false);
          dispatch(UPDATE_CURRENT_CHART_NAME(editChart.name));
          loadChartList(auth, collectionCharts);
        });
      } catch (e) {
        setSaveChartNameProgress(false);
        setSaveError(true);
        console.error("Error renaming chart: ", e);
      }
    };

    const handleCancelChartName = () => {
      setEditChart({ id: "", name: "", action: "" });
    };

    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            columnGap: 16,
          }}
        >
          <TextField
            disabled={saveChartNameProgress}
            fullWidth
            type="text"
            variant="outlined"
            value={editChart.name}
            autoFocus
            inputProps={{
              maxLength: 255,
            }}
            onChange={handleChange}
            style={{ marginTop: 16, marginBottom: 16 }}
          />
          <div style={{ display: "flex", alignItems: "center", columnGap: 8 }}>
            <Button
              disabled={editChart.name === "" || saveChartNameProgress}
              variant="contained"
              color="primary"
              size="large"
              disableElevation
              onClick={handleSaveChartName}
            >
              Save
            </Button>
            <Button
              disabled={saveChartNameProgress}
              variant="outlined"
              color="primary"
              size="large"
              disableElevation
              onClick={handleCancelChartName}
            >
              Cancel
            </Button>
          </div>
          {saveChartNameProgress ? (
            <div style={{ width: 40, height: 40, marginRight: -56 }}>
              <span className="loader" />
            </div>
          ) : (
            ""
          )}
        </div>
        <Divider component="li" />
      </>
    );
  };

  const PopupLoadAlert = () => {
    const handleClosePopupLoadAlert = () => {
      setPopupLoadAlertOpened(false);
      setEditChart({ id: "", name: "", action: "" });
    };

    const handleLoadChart = () => {
      selectChart(editChart.id, true);
      setPopupLoadAlertOpened(false);
    };

    return (
      <Dialog
        open={popupLoadAlertOpened}
        onClose={handleClosePopupLoadAlert}
        fullWidth={true}
        maxWidth={"lg"}
        PaperProps={{
          sx: {
            position: "absolute",
            width: "80%",
            maxWidth: "400px",
            border: "none",
            boxShadow: "none",
            padding: "16px 16px 32px",
            outline: 0,
          },
        }}
        BackdropProps={{
          transitionDuration: 100,
          style: {
            backgroundColor: "rgba(0,0,0,.1)",
          },
        }}
      >
        <DialogTitle>
          <IconButton
            onClick={handleClosePopupLoadAlert}
            title="close"
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ marginBottom: 8 }}>
            <Grid item style={{ width: "100%" }}>
              <Typography>
                You have unsaved changes. By continuing, it will be lost.
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disableElevation
                onClick={handleLoadChart}
              >
                Load anyway
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  const PopupDeleteAlert = () => {
    const [deleteChartProgress, setDeleteChartProgress] = React.useState(false);

    const handleClosePopupDeleteAlert = () => {
      setPopupDeleteAlertOpened(false);
      setEditChart({ id: "", name: "", action: "" });
    };

    const handleDeleteChart = async () => {
      setDeleteChartProgress(true);

      try {
        await deleteDoc(doc(db, collectionCharts, editChart.id)).then(() => {
          setEditChart({ id: "", name: "", action: "" });
          setDeleteChartProgress(false);
          setSaveError(false);
          setPopupDeleteAlertOpened(false);
          loadChartList(auth, collectionCharts);
          if (currentChartId && editChart.id === currentChartId) {
            dispatch(UPDATE_CURRENT_CHART_ID(""));
            dispatch(UPDATE_CURRENT_CHART_NAME(""));
            dispatch(IS_CURRENT_CHART_SAVED(false));
          }
        });
      } catch (e) {
        setDeleteChartProgress(false);
        setSaveError(true);
        console.error("Error renaming chart: ", e);
      }
    };

    return (
      <Dialog
        open={popupDeleteAlertOpened}
        onClose={handleClosePopupDeleteAlert}
        fullWidth={true}
        maxWidth={"lg"}
        PaperProps={{
          sx: {
            position: "absolute",
            width: "80%",
            maxWidth: "400px",
            border: "none",
            boxShadow: "none",
            padding: "16px 16px 32px",
            outline: 0,
          },
        }}
        BackdropProps={{
          transitionDuration: 100,
          style: {
            backgroundColor: "rgba(0,0,0,.1)",
          },
        }}
      >
        <DialogTitle>
          <IconButton
            onClick={handleClosePopupDeleteAlert}
            title="close"
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} style={{ marginBottom: 8 }}>
            <Grid item style={{ width: "100%" }}>
              <Typography color="error">
                Chart &laquo;{editChart.name}&raquo; will be permanently
                deleted. Are you sure?
              </Typography>
            </Grid>
            <Grid
              container
              spacing={2}
              alignItems="center"
              style={{ margin: 0 }}
            >
              <Grid item>
                <Button
                  disabled={editChart.name === "" || deleteChartProgress}
                  variant="contained"
                  size="large"
                  disableElevation
                  onClick={handleDeleteChart}
                  style={{
                    backgroundColor: "rgba(244, 67, 54, 1)",
                    color: "white",
                  }}
                >
                  Delete chart
                </Button>
              </Grid>
              <Grid item>
                {deleteChartProgress ? (
                  <div style={{ width: 40, height: 40 }}>
                    <span className="loader" />
                  </div>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item>
                {deleteChartProgress ? <span>Please, wait</span> : ""}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  return isSignedIn ? (
    <div
      style={{
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {isLoadingChartsList || isLoadingChartsLimit || isLoadingChart ? (
        <div>
          <span className="loader-xl" />
        </div>
      ) : (
        <>
          <Grid container spacing={2} alignItems="center" sx={listStyle}>
            <Grid item>
              <img
                src={auth.currentUser ? auth.currentUser.photoURL || "" : ""}
                style={{ borderRadius: 96, marginLeft: -24 }}
              />
            </Grid>
            <Grid item>
              <Typography variant="h6">
                {auth.currentUser ? auth.currentUser.displayName : ""}
              </Typography>
              <Typography variant="caption">
                {auth.currentUser ? auth.currentUser.email : ""}
              </Typography>
            </Grid>
          </Grid>
          {chartsList.length > 0 ? (
            <>
              <Grid
                container
                justifyContent="flex-start"
                sx={listStyle}
                style={{ marginTop: 24 }}
              >
                <Typography variant="h4">
                  Your charts ({chartsList.length} of {userChartsLimit} used)
                </Typography>
              </Grid>
              <List sx={listStyle} style={{ marginBottom: 80 }}>
                <Divider component="li" />
                {chartsList.map((elem: any, i) =>
                  editChart.action === "rename" &&
                  editChart.id === elem.chartId ? (
                    <ChartsListItemInput key={"chartListItem" + i} />
                  ) : (
                    <ChartsListItem
                      chartName={elem.chartName}
                      chartId={elem.chartId}
                      updated={elem.updated}
                      key={"chartListItem" + i}
                    />
                  )
                )}
              </List>
            </>
          ) : (
            <Grid
              container
              justifyContent="flex-start"
              sx={listStyle}
              style={{ marginTop: 24 }}
            >
              <Typography variant="h4">You have no stored charts</Typography>
            </Grid>
          )}
        </>
      )}
      <PopupLoadAlert />
      <PopupDeleteAlert />
    </div>
  ) : null;
}

export default Profile;
