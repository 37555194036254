import React from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { getObjValueByPath } from "../utils/utils";

export function InputStatus(props) {
  const { name } = props;
  const chartUI = useSelector((state: any) => state.chart.chartUI);
  const settingsValue = getObjValueByPath(chartUI, name);
  const VALUE = settingsValue;

  return (
    <Grid
      container
      alignItems="center"
      style={{
        alignItems: "start",
        fontSize: 14,
        color: "#aaaaaa",
      }}
    >
      {VALUE.toString().replace("string", "category")}
    </Grid>
  );
}

export default InputStatus;
