export const nobelLaureates = [
  { laureate: "Louis Néel", year: 1970 },
  { laureate: "Hannes Alfvén", year: 1970 },
  { laureate: "Murray Gell-Mann", year: 1969 },
  { laureate: "Luis Alvarez", year: 1968 },
  { laureate: "Hans Bethe", year: 1967 },
  // { laureate: "Dennis Gabor", year: 1971 },
  // { laureate: "John Bardeen", year: 1972 },
  // { laureate: "Leon N. Cooper", year: 1972 },
  // { laureate: "Robert Schrieffer", year: 1972 },
  // { laureate: "Brian D. Josephson", year: 1973 },
  // { laureate: "Leo Esaki", year: 1973 },
  // { laureate: "Ivar Giaever", year: 1973 },
  // { laureate: "Martin Ryle", year: 1974 },
  // { laureate: "Antony Hewish", year: 1974 },
  // { laureate: "Aage N. Bohr", year: 1975 },
  // { laureate: "Ben R. Mottelson", year: 1975 },
  // { laureate: "James Rainwater", year: 1975 },
  // { laureate: "Burton Richter", year: 1976 },
  // { laureate: "Samuel C.C. Ting", year: 1976 },
  // { laureate: "Philip W. Anderson", year: 1977 },
  // { laureate: "Sir Nevill F. Mott ", year: 1977 },
  // { laureate: "John H. Van Vleck", year: 1977 },
  // { laureate: "Pyotr Kapitsa", year: 1978 },
  // { laureate: "Arno Penzias", year: 1978 },
  // { laureate: "Robert Woodrow Wilson", year: 1978 },
  // { laureate: "Sheldon Glashow", year: 1979 },
  // { laureate: "Abdus Salam", year: 1979 },
  // { laureate: "Steven Weinberg", year: 1979 },
  // { laureate: "James Cronin", year: 1980 },
  // { laureate: "Val Fitch", year: 1980 },
  // { laureate: "Nicolaas Bloembergen", year: 1981 },
  // { laureate: "Arthur L. Schawlow", year: 1981 },
  // { laureate: "Kai M. Siegbahn", year: 1981 },
  // { laureate: "Kenneth G. Wilson", year: 1982 },
  // { laureate: "Subrahmanyan Chandrasekhar", year: 1983 },
  // { laureate: "William A. Fowler", year: 1983 },
  // { laureate: "Carlo Rubbia", year: 1984 },
  // { laureate: "Simon van der Meer", year: 1984 },
  // { laureate: "Klaus von Klitzing", year: 1985 },
  // { laureate: "Ernst Ruska", year: 1986 },
  // { laureate: "Gerd Binnig", year: 1986 },
  // { laureate: "Heinrich Rohrer", year: 1986 },
  // { laureate: "J. Georg Bednorz", year: 1987 },
  // { laureate: "K. Alex Müller", year: 1987 },
  // { laureate: "Leon M. Lederman", year: 1988 },
  // { laureate: "Melvin Schwartz", year: 1988 },
  // { laureate: "Jack Steinberger", year: 1988 },
  // { laureate: "Norman F. Ramsey", year: 1989 },
  // { laureate: "Hans G. Dehmelt", year: 1989 },
  // { laureate: "Wolfgang Paul", year: 1989 },
  // { laureate: "Jerome I. Friedman", year: 1990 },
  // { laureate: "Henry W. Kendall", year: 1990 },
  // { laureate: "Richard E. Taylor", year: 1990 },
  // { laureate: "Pierre-Gilles de Gennes", year: 1991 },
  // { laureate: "Georges Charpak", year: 1992 },
  // { laureate: "Russell A. Hulse", year: 1993 },
  // { laureate: "Joseph H. Taylor Jr.", year: 1993 },
  // { laureate: "Bertram N. Brockhouse", year: 1994 },
  // { laureate: "Clifford G. Shull", year: 1994 },
  // { laureate: "Martin L. Perl", year: 1995 },
  // { laureate: "Frederick Reines", year: 1995 },
  // { laureate: "David M. Lee", year: 1996 },
  // { laureate: "Douglas D. Osheroff", year: 1996 },
  // { laureate: "Robert C. Richardson", year: 1996 },
  // { laureate: "Steven Chu", year: 1997 },
  // { laureate: "Claude Cohen-Tannoudji", year: 1997 },
  // { laureate: "William D. Phillips", year: 1997 },
  // { laureate: "Robert B. Laughlin", year: 1998 },
  // { laureate: "Horst L. Störmer", year: 1998 },
  // { laureate: "Daniel C. Tsui", year: 1998 },
  // { laureate: "Gerardus 't Hooft", year: 1999 },
  // { laureate: "Martinus J.G. Veltman", year: 1999 },
  // { laureate: "Zhores Alferov", year: 2000 },
  // { laureate: "Herbert Kroemer", year: 2000 },
  // { laureate: "Jack Kilby", year: 2000 },
  // { laureate: "Eric Cornell", year: 2001 },
  // { laureate: "Wolfgang Ketterle", year: 2001 },
  // { laureate: "Carl Wieman", year: 2001 },
  // { laureate: "Raymond Davis Jr.", year: 2002 },
  // { laureate: "Masatoshi Koshiba", year: 2002 },
  // { laureate: "Riccardo Giacconi", year: 2002 },
  // { laureate: "Alexei Abrikosov", year: 2003 },
  // { laureate: "Vitaly L. Ginzburg", year: 2003 },
  // { laureate: "Anthony J. Leggett", year: 2003 },
  // { laureate: "David J. Gross", year: 2004 },
  // { laureate: "H. David Politzer", year: 2004 },
  // { laureate: "Frank Wilczek", year: 2004 },
  // { laureate: "Roy J. Glauber", year: 2005 },
  // { laureate: "John L. Hall", year: 2005 },
  // { laureate: "Theodor W. Hänsch", year: 2005 },
  // { laureate: "John C. Mather", year: 2006 },
  // { laureate: "George F. Smoot", year: 2006 },
  // { laureate: "Albert Fert", year: 2007 },
  // { laureate: "Peter Grünberg", year: 2007 },
  // { laureate: "Yoichiro Nambu", year: 2008 },
  // { laureate: "Makoto Kobayashi", year: 2008 },
  // { laureate: "Toshihide Maskawa", year: 2008 },
  // { laureate: "Charles K. Kao", year: 2009 },
  // { laureate: "Willard S. Boyle", year: 2009 },
  // { laureate: "George E. Smith", year: 2009 },
  // { laureate: "Andre Geim", year: 2010 },
  // { laureate: "Konstantin Novoselov", year: 2010 },
  // { laureate: "Saul Perlmutter", year: 2011 },
  // { laureate: "Brian P. Schmidt", year: 2011 },
  // { laureate: "Adam G. Riess", year: 2011 },
  // { laureate: "Serge Haroche", year: 2012 },
  // { laureate: "David J. Wineland", year: 2012 },
  // { laureate: "François Englert", year: 2013 },
  // { laureate: "Peter Higgs", year: 2013 },
  // { laureate: "Isamu Akasaki", year: 2014 },
  // { laureate: "Hiroshi Amano", year: 2014 },
  // { laureate: "Shuji Nakamura", year: 2014 },
  // { laureate: "Takaaki Kajita", year: 2015 },
  // { laureate: "Arthur B. McDonald", year: 2015 },
  // { laureate: "David J. Thouless", year: 2016 },
  // { laureate: "F. Duncan M. Haldane", year: 2016 },
  // { laureate: "J. Michael Kosterlitz", year: 2016 },
  // { laureate: "Rainer Weiss", year: 2017 },
  // { laureate: "Barry C. Barish", year: 2017 },
  // { laureate: "Kip S. Thorne", year: 2017 },
  // { laureate: "Arthur Ashkin", year: 2018 },
  // { laureate: "Gérard Mourou", year: 2018 },
  // { laureate: "Donna Strickland", year: 2018 },
  // { laureate: "James Peebles", year: 2019 },
  // { laureate: "Michel Mayor", year: 2019 },
  // { laureate: "Didier Queloz", year: 2019 },
  // { laureate: "Roger Penrose", year: 2020 },
  // { laureate: "Reinhard Genzel", year: 2020 },
  // { laureate: "Andrea Ghez", year: 2020 },
  // { laureate: "Syukuro Manabe", year: 2021 },
  // { laureate: "Klaus Hasselmann", year: 2021 },
  // { laureate: "Giorgio Parisi", year: 2021 },
  // { laureate: "Alain Aspect", year: 2022 },
  // { laureate: "John Clauser", year: 2022 },
  // { laureate: "Anton Zeilinger", year: 2022 },
  // { laureate: "Pierre Agostini", year: 2023 },
  // { laureate: "Ferenc Krausz", year: 2023 },
  // { laureate: "Anne L’Huillier", year: 2023 },
  {
    laureate: "Alfred Kastler",
    year: 1966,
  },
  {
    laureate: "Sin-Itiro Tomonaga",
    year: 1965,
  },
  {
    laureate: "Julian Schwinger",
    year: 1965,
  },
  {
    laureate: "Richard P. Feynman",
    year: 1965,
  },
  {
    laureate: "Charles H. Townes",
    year: 1964,
  },
  {
    laureate: "Nicolay G. Basov",
    year: 1964,
  },
  {
    laureate: "Aleksandr M. Prokhorov",
    year: 1964,
  },
  {
    laureate: "Eugene Wigner",
    year: 1963,
  },
  {
    laureate: "Maria Goeppert Mayer",
    year: 1963,
  },
  {
    laureate: "J. Hans D. Jensen",
    year: 1963,
  },
  {
    laureate: "Lev Landau",
    year: 1962,
  },
  {
    laureate: "Robert Hofstadter",
    year: 1961,
  },
  {
    laureate: "Rudolf Mössbauer",
    year: 1961,
  },
  {
    laureate: "Donald A. Glaser",
    year: 1960,
  },
  {
    laureate: "Emilio Segrè",
    year: 1959,
  },
  {
    laureate: "Owen Chamberlain",
    year: 1959,
  },
  {
    laureate: "Pavel A. Cherenkov",
    year: 1958,
  },
  {
    laureate: "Igor Y. Tamm",
    year: 1958,
  },
  {
    laureate: "Il´ja M. Frank",
    year: 1958,
  },
  {
    laureate: "Chen Ning Yang",
    year: 1957,
  },
  {
    laureate: "Tsung-Dao Lee",
    year: 1957,
  },
  {
    laureate: "William B. Shockley",
    year: 1956,
  },
  {
    laureate: "John Bardeen",
    year: 1956,
  },
  {
    laureate: "Walter H. Brattain",
    year: 1956,
  },
  {
    laureate: "Willis E. Lamb",
    year: 1955,
  },
  {
    laureate: "Polykarp Kusch",
    year: 1955,
  },
  {
    laureate: "Max Born",
    year: 1954,
  },
  {
    laureate: "Walther Bothe",
    year: 1954,
  },
  {
    laureate: "Frits Zernike",
    year: 1953,
  },
  {
    laureate: "Felix Bloch",
    year: 1952,
  },
  {
    laureate: "E. M. Purcell",
    year: 1952,
  },
  {
    laureate: "John Cockcroft",
    year: 1951,
  },
  {
    laureate: "Ernest T.S. Walton",
    year: 1951,
  },
  {
    laureate: "Cecil Powell",
    year: 1950,
  },
  {
    laureate: "Hideki Yukawa",
    year: 1949,
  },
  {
    laureate: "Patrick M.S. Blackett",
    year: 1948,
  },
  {
    laureate: "Edward V. Appleton",
    year: 1947,
  },
  {
    laureate: "Percy W. Bridgman",
    year: 1946,
  },
  {
    laureate: "Wolfgang Pauli",
    year: 1945,
  },
  {
    laureate: "Isidor Isaac Rabi",
    year: 1944,
  },
  {
    laureate: "Otto Stern",
    year: 1943,
  },
  {
    laureate: "Ernest Lawrence",
    year: 1939,
  },
  {
    laureate: "Enrico Fermi",
    year: 1938,
  },
  {
    laureate: "Clinton Davisson",
    year: 1937,
  },
  {
    laureate: "George Paget Thomson",
    year: 1937,
  },
  {
    laureate: "Victor F. Hess",
    year: 1936,
  },
  {
    laureate: "Carl D. Anderson",
    year: 1936,
  },
  {
    laureate: "James Chadwick",
    year: 1935,
  },
  {
    laureate: "Erwin Schrödinger",
    year: 1933,
  },
  {
    laureate: "Paul A.M. Dirac",
    year: 1933,
  },
  {
    laureate: "Werner Heisenberg",
    year: 1932,
  },
  {
    laureate: "Sir Chandrasekhara Venkata Raman",
    year: 1930,
  },
  {
    laureate: "Louis de Broglie",
    year: 1929,
  },
  {
    laureate: "Owen Willans Richardson",
    year: 1928,
  },
  {
    laureate: "Arthur H. Compton",
    year: 1927,
  },
  {
    laureate: "C.T.R. Wilson",
    year: 1927,
  },
  {
    laureate: "Jean Baptiste Perrin",
    year: 1926,
  },
  {
    laureate: "James Franck",
    year: 1925,
  },
  {
    laureate: "Gustav Hertz",
    year: 1925,
  },
  {
    laureate: "Manne Siegbahn",
    year: 1924,
  },
  {
    laureate: "Robert A. Millikan",
    year: 1923,
  },
  {
    laureate: "Niels Bohr",
    year: 1922,
  },
  {
    laureate: "Albert Einstein",
    year: 1921,
  },
  {
    laureate: "Charles Edouard Guillaume",
    year: 1920,
  },
  {
    laureate: "Johannes Stark",
    year: 1919,
  },
  {
    laureate: "Max Planck",
    year: 1918,
  },
  {
    laureate: "Charles Glover Barkla",
    year: 1917,
  },
  {
    laureate: "William Bragg",
    year: 1915,
  },
  {
    laureate: "Lawrence Bragg",
    year: 1915,
  },
  {
    laureate: "Max von Laue",
    year: 1914,
  },
  {
    laureate: "Heike Kamerlingh Onnes",
    year: 1913,
  },
  {
    laureate: "Gustaf Dalén",
    year: 1912,
  },
  {
    laureate: "Wilhelm Wien",
    year: 1911,
  },
  {
    laureate: "Johannes Diderik van der Waals",
    year: 1910,
  },
  {
    laureate: "Guglielmo Marconi",
    year: 1909,
  },
  {
    laureate: "Ferdinand Braun",
    year: 1909,
  },
  {
    laureate: "Gabriel Lippmann",
    year: 1908,
  },
  {
    laureate: "Albert A. Michelson",
    year: 1907,
  },
  {
    laureate: "J.J. Thomson",
    year: 1906,
  },
  {
    laureate: "Philipp Lenard",
    year: 1905,
  },
  {
    laureate: "Lord Rayleigh",
    year: 1904,
  },
  {
    laureate: "Henri Becquerel",
    year: 1903,
  },
  {
    laureate: "Pierre Curie",
    year: 1903,
  },
  {
    laureate: "Marie Curie",
    year: 1903,
  },
  {
    laureate: "Hendrik A. Lorentz",
    year: 1902,
  },
  {
    laureate: "Pieter Zeeman",
    year: 1902,
  },
  {
    laureate: "Wilhelm Conrad Röntgen",
    year: 1901,
  },
];

export const nobelNominees = [
  {
    nominee: "Hannes Alfvén",
    nomineeCount: 10,
    year: 1970,
    yearWin: 1970,
  },
  {
    nominee: "Hannes Alfvén",
    nomineeCount: 5,
    year: 1969,
    yearWin: 1970,
  },
  {
    nominee: "Hannes Alfvén",
    nomineeCount: 4,
    year: 1968,
    yearWin: 1970,
  },
  {
    nominee: "Hannes Alfvén",
    nomineeCount: 7,
    year: 1967,
    yearWin: 1970,
  },
  {
    nominee: "Hannes Alfvén",
    nomineeCount: 7,
    year: 1966,
    yearWin: 1970,
  },
  {
    nominee: "Hannes Alfvén",
    nomineeCount: 6,
    year: 1965,
    yearWin: 1970,
  },
  {
    nominee: "Hannes Alfvén",
    nomineeCount: 6,
    year: 1964,
    yearWin: 1970,
  },
  {
    nominee: "Hannes Alfvén",
    nomineeCount: 2,
    year: 1963,
    yearWin: 1970,
  },
  {
    nominee: "Hannes Alfvén",
    nomineeCount: 1,
    year: 1962,
    yearWin: 1970,
  },
  {
    nominee: "Hannes Alfvén",
    nomineeCount: 1,
    year: 1961,
    yearWin: 1970,
  },
  {
    nominee: "Hannes Alfvén",
    nomineeCount: 2,
    year: 1960,
    yearWin: 1970,
  },
  {
    nominee: "Hannes Alfvén",
    nomineeCount: 1,
    year: 1953,
    yearWin: 1970,
  },
  {
    nominee: "Louis Néel",
    nomineeCount: 6,
    year: 1970,
    yearWin: 1970,
  },
  {
    nominee: "Louis Néel",
    nomineeCount: 7,
    year: 1969,
    yearWin: 1970,
  },
  {
    nominee: "Louis Néel",
    nomineeCount: 3,
    year: 1968,
    yearWin: 1970,
  },
  {
    nominee: "Louis Néel",
    nomineeCount: 5,
    year: 1967,
    yearWin: 1970,
  },
  {
    nominee: "Louis Néel",
    nomineeCount: 17,
    year: 1966,
    yearWin: 1970,
  },
  {
    nominee: "Louis Néel",
    nomineeCount: 11,
    year: 1965,
    yearWin: 1970,
  },
  {
    nominee: "Louis Néel",
    nomineeCount: 3,
    year: 1964,
    yearWin: 1970,
  },
  {
    nominee: "Louis Néel",
    nomineeCount: 5,
    year: 1963,
    yearWin: 1970,
  },
  {
    nominee: "Louis Néel",
    nomineeCount: 2,
    year: 1962,
    yearWin: 1970,
  },
  {
    nominee: "Louis Néel",
    nomineeCount: 3,
    year: 1961,
    yearWin: 1970,
  },
  {
    nominee: "Louis Néel",
    nomineeCount: 6,
    year: 1960,
    yearWin: 1970,
  },
  {
    nominee: "Louis Néel",
    nomineeCount: 4,
    year: 1959,
    yearWin: 1970,
  },
  {
    nominee: "Louis Néel",
    nomineeCount: 6,
    year: 1958,
    yearWin: 1970,
  },
  {
    nominee: "Louis Néel",
    nomineeCount: 3,
    year: 1957,
    yearWin: 1970,
  },
  {
    nominee: "Louis Néel",
    nomineeCount: 7,
    year: 1956,
    yearWin: 1970,
  },
  {
    nominee: "Louis Néel",
    nomineeCount: 2,
    year: 1955,
    yearWin: 1970,
  },
  {
    nominee: "Louis Néel",
    nomineeCount: 4,
    year: 1954,
    yearWin: 1970,
  },
  {
    nominee: "Louis Néel",
    nomineeCount: 1,
    year: 1953,
    yearWin: 1970,
  },
  {
    nominee: "Louis Néel",
    nomineeCount: 1,
    year: 1952,
    yearWin: 1970,
  },
  {
    nominee: "Murray Gell-Mann",
    nomineeCount: 34,
    year: 1969,
    yearWin: 1969,
  },
  {
    nominee: "Murray Gell-Mann",
    nomineeCount: 28,
    year: 1968,
    yearWin: 1969,
  },
  {
    nominee: "Murray Gell-Mann",
    nomineeCount: 28,
    year: 1967,
    yearWin: 1969,
  },
  {
    nominee: "Murray Gell-Mann",
    nomineeCount: 25,
    year: 1966,
    yearWin: 1969,
  },
  {
    nominee: "Murray Gell-Mann",
    nomineeCount: 8,
    year: 1965,
    yearWin: 1969,
  },
  {
    nominee: "Murray Gell-Mann",
    nomineeCount: 1,
    year: 1964,
    yearWin: 1969,
  },
  {
    nominee: "Murray Gell-Mann",
    nomineeCount: 2,
    year: 1960,
    yearWin: 1969,
  },
  {
    nominee: "Luis Alvarez",
    nomineeCount: 5,
    year: 1968,
    yearWin: 1968,
  },
  {
    nominee: "Luis Alvarez",
    nomineeCount: 5,
    year: 1967,
    yearWin: 1968,
  },
  {
    nominee: "Luis Alvarez",
    nomineeCount: 3,
    year: 1966,
    yearWin: 1968,
  },
  {
    nominee: "Luis Alvarez",
    nomineeCount: 4,
    year: 1965,
    yearWin: 1968,
  },
  {
    nominee: "Luis Alvarez",
    nomineeCount: 2,
    year: 1964,
    yearWin: 1968,
  },
  {
    nominee: "Luis Alvarez",
    nomineeCount: 2,
    year: 1963,
    yearWin: 1968,
  },
  {
    nominee: "Luis Alvarez",
    nomineeCount: 2,
    year: 1962,
    yearWin: 1968,
  },
  {
    nominee: "Luis Alvarez",
    nomineeCount: 1,
    year: 1960,
    yearWin: 1968,
  },
  {
    nominee: "Luis Alvarez",
    nomineeCount: 1,
    year: 1953,
    yearWin: 1968,
  },
  {
    nominee: "Hans Bethe",
    nomineeCount: 7,
    year: 1967,
    yearWin: 1967,
  },
  {
    nominee: "Hans Bethe",
    nomineeCount: 3,
    year: 1966,
    yearWin: 1967,
  },
  {
    nominee: "Hans Bethe",
    nomineeCount: 3,
    year: 1965,
    yearWin: 1967,
  },
  {
    nominee: "Hans Bethe",
    nomineeCount: 3,
    year: 1964,
    yearWin: 1967,
  },
  {
    nominee: "Hans Bethe",
    nomineeCount: 9,
    year: 1962,
    yearWin: 1967,
  },
  {
    nominee: "Hans Bethe",
    nomineeCount: 2,
    year: 1960,
    yearWin: 1967,
  },
  {
    nominee: "Hans Bethe",
    nomineeCount: 1,
    year: 1959,
    yearWin: 1967,
  },
  {
    nominee: "Hans Bethe",
    nomineeCount: 2,
    year: 1958,
    yearWin: 1967,
  },
  {
    nominee: "Hans Bethe",
    nomineeCount: 1,
    year: 1957,
    yearWin: 1967,
  },
  {
    nominee: "Hans Bethe",
    nomineeCount: 6,
    year: 1956,
    yearWin: 1967,
  },
  {
    nominee: "Hans Bethe",
    nomineeCount: 1,
    year: 1955,
    yearWin: 1967,
  },
  {
    nominee: "Hans Bethe",
    nomineeCount: 2,
    year: 1954,
    yearWin: 1967,
  },
  {
    nominee: "Hans Bethe",
    nomineeCount: 6,
    year: 1953,
    yearWin: 1967,
  },
  {
    nominee: "Hans Bethe",
    nomineeCount: 2,
    year: 1951,
    yearWin: 1967,
  },
  {
    nominee: "Hans Bethe",
    nomineeCount: 1,
    year: 1950,
    yearWin: 1967,
  },
  {
    nominee: "Hans Bethe",
    nomineeCount: 2,
    year: 1949,
    yearWin: 1967,
  },
  {
    nominee: "Hans Bethe",
    nomineeCount: 1,
    year: 1948,
    yearWin: 1967,
  },

  {
    nominee: "Hans Bethe",
    nomineeCount: 1,
    year: 1946,
    yearWin: 1967,
  },
  {
    nominee: "Hans Bethe",
    nomineeCount: 1,
    year: 1943,
    yearWin: 1967,
  },
  {
    nominee: "Alfred Kastler",
    nomineeCount: 8,
    year: 1966,
    yearWin: 1966,
  },
  {
    nominee: "Alfred Kastler",
    nomineeCount: 16,
    year: 1965,
    yearWin: 1966,
  },
  {
    nominee: "Alfred Kastler",
    nomineeCount: 6,
    year: 1964,
    yearWin: 1966,
  },
  {
    nominee: "Alfred Kastler",
    nomineeCount: 5,
    year: 1963,
    yearWin: 1966,
  },
  {
    nominee: "Alfred Kastler",
    nomineeCount: 6,
    year: 1962,
    yearWin: 1966,
  },
  {
    nominee: "Alfred Kastler",
    nomineeCount: 1,
    year: 1961,
    yearWin: 1966,
  },
  {
    nominee: "Alfred Kastler",
    nomineeCount: 5,
    year: 1960,
    yearWin: 1966,
  },
  {
    nominee: "Alfred Kastler",
    nomineeCount: 1,
    year: 1959,
    yearWin: 1966,
  },
  {
    nominee: "Alfred Kastler",
    nomineeCount: 1,
    year: 1958,
    yearWin: 1966,
  },
  {
    nominee: "Alfred Kastler",
    nomineeCount: 1,
    year: 1956,
    yearWin: 1966,
  },
  {
    nominee: "Julian Schwinger",
    nomineeCount: 1,
    year: 1966,
    yearWin: 1965,
  },
  {
    nominee: "Richard P. Feynman",
    nomineeCount: 13,
    year: 1965,
    yearWin: 1965,
  },
  {
    nominee: "Julian Schwinger",
    nomineeCount: 4,
    year: 1965,
    yearWin: 1965,
  },
  {
    nominee: "Sin-Itiro Tomonaga",
    nomineeCount: 1,
    year: 1965,
    yearWin: 1965,
  },
  {
    nominee: "Richard P. Feynman",
    nomineeCount: 11,
    year: 1964,
    yearWin: 1965,
  },
  {
    nominee: "Julian Schwinger",
    nomineeCount: 6,
    year: 1964,
    yearWin: 1965,
  },
  {
    nominee: "Sin-Itiro Tomonaga",
    nomineeCount: 2,
    year: 1964,
    yearWin: 1965,
  },
  {
    nominee: "Richard P. Feynman",
    nomineeCount: 3,
    year: 1963,
    yearWin: 1965,
  },
  {
    nominee: "Julian Schwinger",
    nomineeCount: 2,
    year: 1963,
    yearWin: 1965,
  },
  {
    nominee: "Sin-Itiro Tomonaga",
    nomineeCount: 1,
    year: 1963,
    yearWin: 1965,
  },
  {
    nominee: "Richard P. Feynman",
    nomineeCount: 7,
    year: 1962,
    yearWin: 1965,
  },
  {
    nominee: "Julian Schwinger",
    nomineeCount: 1,
    year: 1962,
    yearWin: 1965,
  },
  {
    nominee: "Sin-Itiro Tomonaga",
    nomineeCount: 1,
    year: 1962,
    yearWin: 1965,
  },
  {
    nominee: "Richard P. Feynman",
    nomineeCount: 6,
    year: 1960,
    yearWin: 1965,
  },
  {
    nominee: "Julian Schwinger",
    nomineeCount: 6,
    year: 1960,
    yearWin: 1965,
  },
  {
    nominee: "Sin-Itiro Tomonaga",
    nomineeCount: 1,
    year: 1960,
    yearWin: 1965,
  },
  {
    nominee: "Richard P. Feynman",
    nomineeCount: 2,
    year: 1957,
    yearWin: 1965,
  },
  {
    nominee: "Sin-Itiro Tomonaga",
    nomineeCount: 2,
    year: 1957,
    yearWin: 1965,
  },
  {
    nominee: "Julian Schwinger",
    nomineeCount: 2,
    year: 1957,
    yearWin: 1965,
  },
  {
    nominee: "Richard P. Feynman",
    nomineeCount: 6,
    year: 1956,
    yearWin: 1965,
  },
  {
    nominee: "Julian Schwinger",
    nomineeCount: 5,
    year: 1956,
    yearWin: 1965,
  },
  {
    nominee: "Sin-Itiro Tomonaga",
    nomineeCount: 3,
    year: 1956,
    yearWin: 1965,
  },
  {
    nominee: "Sin-Itiro Tomonaga",
    nomineeCount: 1,
    year: 1955,
    yearWin: 1965,
  },
  {
    nominee: "Sin-Itiro Tomonaga",
    nomineeCount: 1,
    year: 1952,
    yearWin: 1965,
  },
  {
    nominee: "Julian Schwinger",
    nomineeCount: 1,
    year: 1952,
    yearWin: 1965,
  },
  {
    nominee: "Julian Schwinger",
    nomineeCount: 2,
    year: 1951,
    yearWin: 1965,
  },
  {
    nominee: "Sin-Itiro Tomonaga",
    nomineeCount: 2,
    year: 1951,
    yearWin: 1965,
  },
  {
    nominee: "Charles H. Townes",
    nomineeCount: 2,
    year: 1965,
    yearWin: 1964,
  },
  {
    nominee: "Charles H. Townes",
    nomineeCount: 29,
    year: 1964,
    yearWin: 1964,
  },
  {
    nominee: "Aleksandr M. Prokhorov",
    nomineeCount: 9,
    year: 1964,
    yearWin: 1964,
  },
  {
    nominee: "Nicolay G. Basov",
    nomineeCount: 4,
    year: 1964,
    yearWin: 1964,
  },
  {
    nominee: "Charles H. Townes",
    nomineeCount: 18,
    year: 1963,
    yearWin: 1964,
  },
  {
    nominee: "Nicolay G. Basov",
    nomineeCount: 1,
    year: 1963,
    yearWin: 1964,
  },
  {
    nominee: "Aleksandr M. Prokhorov",
    nomineeCount: 1,
    year: 1963,
    yearWin: 1964,
  },
  {
    nominee: "Charles H. Townes",
    nomineeCount: 14,
    year: 1962,
    yearWin: 1964,
  },
  {
    nominee: "Nicolay G. Basov",
    nomineeCount: 1,
    year: 1962,
    yearWin: 1964,
  },
  {
    nominee: "Aleksandr M. Prokhorov",
    nomineeCount: 1,
    year: 1962,
    yearWin: 1964,
  },
  {
    nominee: "Charles H. Townes",
    nomineeCount: 3,
    year: 1961,
    yearWin: 1964,
  },
  {
    nominee: "Charles H. Townes",
    nomineeCount: 2,
    year: 1960,
    yearWin: 1964,
  },
  {
    nominee: "Nicolay G. Basov",
    nomineeCount: 2,
    year: 1960,
    yearWin: 1964,
  },
  {
    nominee: "Aleksandr M. Prokhorov",
    nomineeCount: 2,
    year: 1960,
    yearWin: 1964,
  },
  {
    nominee: "Charles H. Townes",
    nomineeCount: 4,
    year: 1959,
    yearWin: 1964,
  },
  {
    nominee: "Charles H. Townes",
    nomineeCount: 3,
    year: 1958,
    yearWin: 1964,
  },
  {
    nominee: "J. Hans D. Jensen",
    nomineeCount: 3,
    year: 1963,
    yearWin: 1963,
  },
  {
    nominee: "Eugene Wigner",
    nomineeCount: 5,
    year: 1963,
    yearWin: 1963,
  },
  {
    nominee: "Maria Goeppert Mayer",
    nomineeCount: 2,
    year: 1963,
    yearWin: 1963,
  },
  {
    nominee: "J. Hans D. Jensen",
    nomineeCount: 7,
    year: 1962,
    yearWin: 1963,
  },
  {
    nominee: "Eugene Wigner",
    nomineeCount: 4,
    year: 1962,
    yearWin: 1963,
  },
  {
    nominee: "Maria Goeppert Mayer",
    nomineeCount: 6,
    year: 1962,
    yearWin: 1963,
  },
  {
    nominee: "J. Hans D. Jensen",
    nomineeCount: 1,
    year: 1961,
    yearWin: 1963,
  },
  {
    nominee: "Eugene Wigner",
    nomineeCount: 1,
    year: 1961,
    yearWin: 1963,
  },
  {
    nominee: "Eugene Wigner",
    nomineeCount: 2,
    year: 1960,
    yearWin: 1963,
  },
  {
    nominee: "J. Hans D. Jensen",
    nomineeCount: 4,
    year: 1960,
    yearWin: 1963,
  },
  {
    nominee: "Maria Goeppert Mayer",
    nomineeCount: 4,
    year: 1960,
    yearWin: 1963,
  },
  {
    nominee: "Eugene Wigner",
    nomineeCount: 1,
    year: 1959,
    yearWin: 1963,
  },
  {
    nominee: "Maria Goeppert Mayer",
    nomineeCount: 2,
    year: 1959,
    yearWin: 1963,
  },
  {
    nominee: "J. Hans D. Jensen",
    nomineeCount: 2,
    year: 1959,
    yearWin: 1963,
  },
  {
    nominee: "Eugene Wigner",
    nomineeCount: 2,
    year: 1958,
    yearWin: 1963,
  },
  {
    nominee: "Maria Goeppert Mayer",
    nomineeCount: 2,
    year: 1958,
    yearWin: 1963,
  },
  {
    nominee: "J. Hans D. Jensen",
    nomineeCount: 2,
    year: 1958,
    yearWin: 1963,
  },
  {
    nominee: "Eugene Wigner",
    nomineeCount: 7,
    year: 1957,
    yearWin: 1963,
  },
  {
    nominee: "Maria Goeppert Mayer",
    nomineeCount: 2,
    year: 1957,
    yearWin: 1963,
  },
  {
    nominee: "J. Hans D. Jensen",
    nomineeCount: 2,
    year: 1957,
    yearWin: 1963,
  },
  {
    nominee: "Maria Goeppert Mayer",
    nomineeCount: 6,
    year: 1956,
    yearWin: 1963,
  },
  {
    nominee: "J. Hans D. Jensen",
    nomineeCount: 6,
    year: 1956,
    yearWin: 1963,
  },
  {
    nominee: "Maria Goeppert Mayer",
    nomineeCount: 2,
    year: 1955,
    yearWin: 1963,
  },
  {
    nominee: "Eugene Wigner",
    nomineeCount: 1,
    year: 1955,
    yearWin: 1963,
  },
  {
    nominee: "J. Hans D. Jensen",
    nomineeCount: 1,
    year: 1955,
    yearWin: 1963,
  },
  {
    nominee: "Eugene Wigner",
    nomineeCount: 1,
    year: 1953,
    yearWin: 1963,
  },
  {
    nominee: "Lev Landau",
    nomineeCount: 5,
    year: 1963,
    yearWin: 1962,
  },
  {
    nominee: "Lev Landau",
    nomineeCount: 2,
    year: 1962,
    yearWin: 1962,
  },
  {
    nominee: "Lev Landau",
    nomineeCount: 3,
    year: 1960,
    yearWin: 1962,
  },
  {
    nominee: "Lev Landau",
    nomineeCount: 1,
    year: 1959,
    yearWin: 1962,
  },
  {
    nominee: "Lev Landau",
    nomineeCount: 3,
    year: 1958,
    yearWin: 1962,
  },
  {
    nominee: "Lev Landau",
    nomineeCount: 2,
    year: 1957,
    yearWin: 1962,
  },
  {
    nominee: "Lev Landau",
    nomineeCount: 4,
    year: 1956,
    yearWin: 1962,
  },
  {
    nominee: "Lev Landau",
    nomineeCount: 1,
    year: 1954,
    yearWin: 1962,
  },
  {
    nominee: "Robert Hofstadter",
    nomineeCount: 3,
    year: 1961,
    yearWin: 1961,
  },
  {
    nominee: "Rudolf Mössbauer",
    nomineeCount: 13,
    year: 1961,
    yearWin: 1961,
  },
  {
    nominee: "Robert Hofstadter",
    nomineeCount: 4,
    year: 1960,
    yearWin: 1961,
  },
  {
    nominee: "Robert Hofstadter",
    nomineeCount: 6,
    year: 1959,
    yearWin: 1961,
  },
  {
    nominee: "Robert Hofstadter",
    nomineeCount: 1,
    year: 1958,
    yearWin: 1961,
  },
  {
    nominee: "Donald A. Glaser",
    nomineeCount: 9,
    year: 1960,
    yearWin: 1960,
  },
  {
    nominee: "Donald A. Glaser",
    nomineeCount: 4,
    year: 1959,
    yearWin: 1960,
  },
  {
    nominee: "Emilio Segrè",
    nomineeCount: 9,
    year: 1959,
    yearWin: 1959,
  },
  {
    nominee: "Owen Chamberlain",
    nomineeCount: 6,
    year: 1959,
    yearWin: 1959,
  },
  {
    nominee: "Emilio Segrè",
    nomineeCount: 4,
    year: 1958,
    yearWin: 1959,
  },
  {
    nominee: "Owen Chamberlain",
    nomineeCount: 1,
    year: 1958,
    yearWin: 1959,
  },
  {
    nominee: "Emilio Segrè",
    nomineeCount: 9,
    year: 1957,
    yearWin: 1959,
  },
  {
    nominee: "Owen Chamberlain",
    nomineeCount: 1,
    year: 1957,
    yearWin: 1959,
  },
  {
    nominee: "Emilio Segrè",
    nomineeCount: 1,
    year: 1956,
    yearWin: 1959,
  },
  {
    nominee: "Emilio Segrè",
    nomineeCount: 1,
    year: 1953,
    yearWin: 1959,
  },
  {
    nominee: "Pavel A. Cherenkov",
    nomineeCount: 2,
    year: 1958,
    yearWin: 1958,
  },
  {
    nominee: "Il´ja M. Frank",
    nomineeCount: 1,
    year: 1958,
    yearWin: 1958,
  },
  {
    nominee: "Igor Y. Tamm",
    nomineeCount: 1,
    year: 1958,
    yearWin: 1958,
  },
  {
    nominee: "Pavel A. Cherenkov",
    nomineeCount: 6,
    year: 1957,
    yearWin: 1958,
  },
  {
    nominee: "Igor Y. Tamm",
    nomineeCount: 2,
    year: 1957,
    yearWin: 1958,
  },
  {
    nominee: "Il´ja M. Frank",
    nomineeCount: 1,
    year: 1957,
    yearWin: 1958,
  },
  {
    nominee: "Pavel A. Cherenkov",
    nomineeCount: 2,
    year: 1956,
    yearWin: 1958,
  },
  {
    nominee: "Pavel A. Cherenkov",
    nomineeCount: 1,
    year: 1955,
    yearWin: 1958,
  },
  {
    nominee: "Pavel A. Cherenkov",
    nomineeCount: 1,
    year: 1952,
    yearWin: 1958,
  },
  {
    nominee: "Chen Ning Yang",
    nomineeCount: 1,
    year: 1957,
    yearWin: 1957,
  },
  {
    nominee: "Tsung-Dao Lee",
    nomineeCount: 1,
    year: 1957,
    yearWin: 1957,
  },
  {
    nominee: "John Bardeen",
    nomineeCount: 3,
    year: 1966,
    yearWin: 1956,
  },
  {
    nominee: "John Bardeen",
    nomineeCount: 1,
    year: 1965,
    yearWin: 1956,
  },
  {
    nominee: "John Bardeen",
    nomineeCount: 2,
    year: 1963,
    yearWin: 1956,
  },
  {
    nominee: "John Bardeen",
    nomineeCount: 2,
    year: 1961,
    yearWin: 1956,
  },
  {
    nominee: "John Bardeen",
    nomineeCount: 3,
    year: 1956,
    yearWin: 1956,
  },
  {
    nominee: "Walter H. Brattain",
    nomineeCount: 3,
    year: 1956,
    yearWin: 1956,
  },
  {
    nominee: "William B. Shockley",
    nomineeCount: 2,
    year: 1956,
    yearWin: 1956,
  },
  {
    nominee: "John Bardeen",
    nomineeCount: 1,
    year: 1955,
    yearWin: 1956,
  },
  {
    nominee: "William B. Shockley",
    nomineeCount: 1,
    year: 1955,
    yearWin: 1956,
  },
  {
    nominee: "William B. Shockley",
    nomineeCount: 3,
    year: 1954,
    yearWin: 1956,
  },
  {
    nominee: "John Bardeen",
    nomineeCount: 3,
    year: 1954,
    yearWin: 1956,
  },
  {
    nominee: "Walter H. Brattain",
    nomineeCount: 3,
    year: 1954,
    yearWin: 1956,
  },
  {
    nominee: "John Bardeen",
    nomineeCount: 1,
    year: 1953,
    yearWin: 1956,
  },
  {
    nominee: "Walter H. Brattain",
    nomineeCount: 1,
    year: 1953,
    yearWin: 1956,
  },
  {
    nominee: "Willis E. Lamb",
    nomineeCount: 5,
    year: 1955,
    yearWin: 1955,
  },
  {
    nominee: "Polykarp Kusch",
    nomineeCount: 2,
    year: 1955,
    yearWin: 1955,
  },
  {
    nominee: "Willis E. Lamb",
    nomineeCount: 6,
    year: 1954,
    yearWin: 1955,
  },
  {
    nominee: "Willis E. Lamb",
    nomineeCount: 4,
    year: 1953,
    yearWin: 1955,
  },
  {
    nominee: "Willis E. Lamb",
    nomineeCount: 3,
    year: 1952,
    yearWin: 1955,
  },
  {
    nominee: "Polykarp Kusch",
    nomineeCount: 1,
    year: 1952,
    yearWin: 1955,
  },
  {
    nominee: "Willis E. Lamb",
    nomineeCount: 4,
    year: 1951,
    yearWin: 1955,
  },
  {
    nominee: "Max Born",
    nomineeCount: 5,
    year: 1954,
    yearWin: 1954,
  },
  {
    nominee: "Walther Bothe",
    nomineeCount: 7,
    year: 1954,
    yearWin: 1954,
  },
  {
    nominee: "Max Born",
    nomineeCount: 4,
    year: 1953,
    yearWin: 1954,
  },
  {
    nominee: "Walther Bothe",
    nomineeCount: 2,
    year: 1953,
    yearWin: 1954,
  },
  {
    nominee: "Max Born",
    nomineeCount: 4,
    year: 1952,
    yearWin: 1954,
  },
  {
    nominee: "Walther Bothe",
    nomineeCount: 1,
    year: 1952,
    yearWin: 1954,
  },
  {
    nominee: "Max Born",
    nomineeCount: 3,
    year: 1951,
    yearWin: 1954,
  },
  {
    nominee: "Walther Bothe",
    nomineeCount: 1,
    year: 1951,
    yearWin: 1954,
  },
  {
    nominee: "Max Born",
    nomineeCount: 4,
    year: 1950,
    yearWin: 1954,
  },
  {
    nominee: "Max Born",
    nomineeCount: 4,
    year: 1949,
    yearWin: 1954,
  },
  {
    nominee: "Walther Bothe",
    nomineeCount: 2,
    year: 1949,
    yearWin: 1954,
  },
  {
    nominee: "Max Born",
    nomineeCount: 4,
    year: 1948,
    yearWin: 1954,
  },
  {
    nominee: "Max Born",
    nomineeCount: 2,
    year: 1947,
    yearWin: 1954,
  },
  {
    nominee: "Walther Bothe",
    nomineeCount: 1,
    year: 1946,
    yearWin: 1954,
  },
  {
    nominee: "Max Born",
    nomineeCount: 1,
    year: 1946,
    yearWin: 1954,
  },
  {
    nominee: "Max Born",
    nomineeCount: 1,
    year: 1939,
    yearWin: 1954,
  },
  {
    nominee: "Max Born",
    nomineeCount: 1,
    year: 1934,
    yearWin: 1954,
  },
  {
    nominee: "Walther Bothe",
    nomineeCount: 1,
    year: 1934,
    yearWin: 1954,
  },
  {
    nominee: "Max Born",
    nomineeCount: 1,
    year: 1930,
    yearWin: 1954,
  },
  {
    nominee: "Frits Zernike",
    nomineeCount: 3,
    year: 1953,
    yearWin: 1953,
  },
  {
    nominee: "Frits Zernike",
    nomineeCount: 2,
    year: 1952,
    yearWin: 1953,
  },
  {
    nominee: "Frits Zernike",
    nomineeCount: 2,
    year: 1951,
    yearWin: 1953,
  },
  {
    nominee: "Frits Zernike",
    nomineeCount: 2,
    year: 1950,
    yearWin: 1953,
  },
  {
    nominee: "Frits Zernike",
    nomineeCount: 1,
    year: 1948,
    yearWin: 1953,
  },
  {
    nominee: "Frits Zernike",
    nomineeCount: 1,
    year: 1944,
    yearWin: 1953,
  },
  {
    nominee: "Felix Bloch",
    nomineeCount: 1,
    year: 1953,
    yearWin: 1952,
  },
  {
    nominee: "Felix Bloch",
    nomineeCount: 7,
    year: 1952,
    yearWin: 1952,
  },
  {
    nominee: "E. M. Purcell",
    nomineeCount: 5,
    year: 1952,
    yearWin: 1952,
  },
  {
    nominee: "Felix Bloch",
    nomineeCount: 4,
    year: 1951,
    yearWin: 1952,
  },
  {
    nominee: "E. M. Purcell",
    nomineeCount: 2,
    year: 1951,
    yearWin: 1952,
  },
  {
    nominee: "Felix Bloch",
    nomineeCount: 3,
    year: 1950,
    yearWin: 1952,
  },
  {
    nominee: "E. M. Purcell",
    nomineeCount: 2,
    year: 1950,
    yearWin: 1952,
  },
  {
    nominee: "E. M. Purcell",
    nomineeCount: 1,
    year: 1949,
    yearWin: 1952,
  },
  {
    nominee: "Felix Bloch",
    nomineeCount: 1,
    year: 1949,
    yearWin: 1952,
  },
  {
    nominee: "Felix Bloch",
    nomineeCount: 2,
    year: 1948,
    yearWin: 1952,
  },
  {
    nominee: "John Cockcroft",
    nomineeCount: 1,
    year: 1952,
    yearWin: 1951,
  },
  {
    nominee: "John Cockcroft",
    nomineeCount: 5,
    year: 1951,
    yearWin: 1951,
  },
  {
    nominee: "Ernest T.S. Walton",
    nomineeCount: 2,
    year: 1951,
    yearWin: 1951,
  },
  {
    nominee: "John Cockcroft",
    nomineeCount: 1,
    year: 1949,
    yearWin: 1951,
  },
  {
    nominee: "John Cockcroft",
    nomineeCount: 2,
    year: 1947,
    yearWin: 1951,
  },
  {
    nominee: "Ernest T.S. Walton",
    nomineeCount: 1,
    year: 1947,
    yearWin: 1951,
  },
  {
    nominee: "John Cockcroft",
    nomineeCount: 1,
    year: 1946,
    yearWin: 1951,
  },
  {
    nominee: "John Cockcroft",
    nomineeCount: 1,
    year: 1945,
    yearWin: 1951,
  },
  {
    nominee: "Ernest T.S. Walton",
    nomineeCount: 1,
    year: 1945,
    yearWin: 1951,
  },
  {
    nominee: "John Cockcroft",
    nomineeCount: 1,
    year: 1943,
    yearWin: 1951,
  },
  {
    nominee: "John Cockcroft",
    nomineeCount: 1,
    year: 1939,
    yearWin: 1951,
  },
  {
    nominee: "Ernest T.S. Walton",
    nomineeCount: 1,
    year: 1939,
    yearWin: 1951,
  },
  {
    nominee: "John Cockcroft",
    nomineeCount: 1,
    year: 1938,
    yearWin: 1951,
  },
  {
    nominee: "Ernest T.S. Walton",
    nomineeCount: 1,
    year: 1938,
    yearWin: 1951,
  },
  {
    nominee: "John Cockcroft",
    nomineeCount: 2,
    year: 1937,
    yearWin: 1951,
  },
  {
    nominee: "Ernest T.S. Walton",
    nomineeCount: 2,
    year: 1937,
    yearWin: 1951,
  },
  {
    nominee: "Cecil Powell",
    nomineeCount: 1,
    year: 1951,
    yearWin: 1950,
  },
  {
    nominee: "Cecil Powell",
    nomineeCount: 14,
    year: 1950,
    yearWin: 1950,
  },
  {
    nominee: "Cecil Powell",
    nomineeCount: 8,
    year: 1949,
    yearWin: 1950,
  },
  {
    nominee: "Hideki Yukawa",
    nomineeCount: 2,
    year: 1950,
    yearWin: 1949,
  },
  {
    nominee: "Hideki Yukawa",
    nomineeCount: 10,
    year: 1949,
    yearWin: 1949,
  },
  {
    nominee: "Hideki Yukawa",
    nomineeCount: 2,
    year: 1948,
    yearWin: 1949,
  },
  {
    nominee: "Hideki Yukawa",
    nomineeCount: 2,
    year: 1946,
    yearWin: 1949,
  },
  {
    nominee: "Hideki Yukawa",
    nomineeCount: 1,
    year: 1945,
    yearWin: 1949,
  },
  {
    nominee: "Hideki Yukawa",
    nomineeCount: 1,
    year: 1944,
    yearWin: 1949,
  },
  {
    nominee: "Hideki Yukawa",
    nomineeCount: 1,
    year: 1943,
    yearWin: 1949,
  },
  {
    nominee: "Hideki Yukawa",
    nomineeCount: 1,
    year: 1941,
    yearWin: 1949,
  },
  {
    nominee: "Hideki Yukawa",
    nomineeCount: 2,
    year: 1940,
    yearWin: 1949,
  },
  {
    nominee: "Patrick M.S. Blackett",
    nomineeCount: 1,
    year: 1949,
    yearWin: 1948,
  },
  {
    nominee: "Patrick M.S. Blackett",
    nomineeCount: 6,
    year: 1948,
    yearWin: 1948,
  },
  {
    nominee: "Patrick M.S. Blackett",
    nomineeCount: 2,
    year: 1947,
    yearWin: 1948,
  },
  {
    nominee: "Patrick M.S. Blackett",
    nomineeCount: 2,
    year: 1945,
    yearWin: 1948,
  },
  {
    nominee: "Patrick M.S. Blackett",
    nomineeCount: 2,
    year: 1944,
    yearWin: 1948,
  },
  {
    nominee: "Patrick M.S. Blackett",
    nomineeCount: 1,
    year: 1942,
    yearWin: 1948,
  },
  {
    nominee: "Patrick M.S. Blackett",
    nomineeCount: 1,
    year: 1940,
    yearWin: 1948,
  },
  {
    nominee: "Patrick M.S. Blackett",
    nomineeCount: 1,
    year: 1939,
    yearWin: 1948,
  },
  {
    nominee: "Patrick M.S. Blackett",
    nomineeCount: 3,
    year: 1936,
    yearWin: 1948,
  },
  {
    nominee: "Edward V. Appleton",
    nomineeCount: 1,
    year: 1947,
    yearWin: 1947,
  },
  {
    nominee: "Edward V. Appleton",
    nomineeCount: 1,
    year: 1946,
    yearWin: 1947,
  },
  {
    nominee: "Edward V. Appleton",
    nomineeCount: 2,
    year: 1944,
    yearWin: 1947,
  },
  {
    nominee: "Edward V. Appleton",
    nomineeCount: 2,
    year: 1943,
    yearWin: 1947,
  },
  {
    nominee: "Edward V. Appleton",
    nomineeCount: 2,
    year: 1942,
    yearWin: 1947,
  },
  {
    nominee: "Edward V. Appleton",
    nomineeCount: 1,
    year: 1941,
    yearWin: 1947,
  },
  {
    nominee: "Edward V. Appleton",
    nomineeCount: 3,
    year: 1939,
    yearWin: 1947,
  },
  {
    nominee: "Edward V. Appleton",
    nomineeCount: 3,
    year: 1935,
    yearWin: 1947,
  },
  {
    nominee: "Percy W. Bridgman",
    nomineeCount: 2,
    year: 1946,
    yearWin: 1946,
  },
  {
    nominee: "Percy W. Bridgman",
    nomineeCount: 3,
    year: 1945,
    yearWin: 1946,
  },
  {
    nominee: "Percy W. Bridgman",
    nomineeCount: 2,
    year: 1943,
    yearWin: 1946,
  },
  {
    nominee: "Percy W. Bridgman",
    nomineeCount: 2,
    year: 1942,
    yearWin: 1946,
  },
  {
    nominee: "Percy W. Bridgman",
    nomineeCount: 2,
    year: 1941,
    yearWin: 1946,
  },
  {
    nominee: "Percy W. Bridgman",
    nomineeCount: 2,
    year: 1940,
    yearWin: 1946,
  },
  {
    nominee: "Percy W. Bridgman",
    nomineeCount: 10,
    year: 1939,
    yearWin: 1946,
  },
  {
    nominee: "Percy W. Bridgman",
    nomineeCount: 1,
    year: 1936,
    yearWin: 1946,
  },
  {
    nominee: "Percy W. Bridgman",
    nomineeCount: 1,
    year: 1934,
    yearWin: 1946,
  },
  {
    nominee: "Percy W. Bridgman",
    nomineeCount: 11,
    year: 1933,
    yearWin: 1946,
  },
  {
    nominee: "Percy W. Bridgman",
    nomineeCount: 2,
    year: 1929,
    yearWin: 1946,
  },
  {
    nominee: "Percy W. Bridgman",
    nomineeCount: 1,
    year: 1928,
    yearWin: 1946,
  },
  {
    nominee: "Percy W. Bridgman",
    nomineeCount: 1,
    year: 1924,
    yearWin: 1946,
  },
  {
    nominee: "Percy W. Bridgman",
    nomineeCount: 1,
    year: 1919,
    yearWin: 1946,
  },
  {
    nominee: "Wolfgang Pauli",
    nomineeCount: 6,
    year: 1946,
    yearWin: 1945,
  },
  {
    nominee: "Wolfgang Pauli",
    nomineeCount: 3,
    year: 1945,
    yearWin: 1945,
  },
  {
    nominee: "Wolfgang Pauli",
    nomineeCount: 1,
    year: 1944,
    yearWin: 1945,
  },
  {
    nominee: "Wolfgang Pauli",
    nomineeCount: 2,
    year: 1943,
    yearWin: 1945,
  },
  {
    nominee: "Wolfgang Pauli",
    nomineeCount: 1,
    year: 1942,
    yearWin: 1945,
  },
  {
    nominee: "Wolfgang Pauli",
    nomineeCount: 1,
    year: 1941,
    yearWin: 1945,
  },
  {
    nominee: "Wolfgang Pauli",
    nomineeCount: 3,
    year: 1940,
    yearWin: 1945,
  },
  {
    nominee: "Wolfgang Pauli",
    nomineeCount: 1,
    year: 1938,
    yearWin: 1945,
  },
  {
    nominee: "Wolfgang Pauli",
    nomineeCount: 5,
    year: 1937,
    yearWin: 1945,
  },
  {
    nominee: "Wolfgang Pauli",
    nomineeCount: 4,
    year: 1935,
    yearWin: 1945,
  },
  {
    nominee: "Wolfgang Pauli",
    nomineeCount: 1,
    year: 1934,
    yearWin: 1945,
  },
  {
    nominee: "Wolfgang Pauli",
    nomineeCount: 1,
    year: 1933,
    yearWin: 1945,
  },
  {
    nominee: "Isidor Isaac Rabi",
    nomineeCount: 1,
    year: 1945,
    yearWin: 1944,
  },
  {
    nominee: "Isidor Isaac Rabi",
    nomineeCount: 1,
    year: 1944,
    yearWin: 1944,
  },
  {
    nominee: "Isidor Isaac Rabi",
    nomineeCount: 4,
    year: 1943,
    yearWin: 1944,
  },
  {
    nominee: "Isidor Isaac Rabi",
    nomineeCount: 3,
    year: 1942,
    yearWin: 1944,
  },
  {
    nominee: "Isidor Isaac Rabi",
    nomineeCount: 2,
    year: 1941,
    yearWin: 1944,
  },
  {
    nominee: "Isidor Isaac Rabi",
    nomineeCount: 9,
    year: 1940,
    yearWin: 1944,
  },
  {
    nominee: "Isidor Isaac Rabi",
    nomineeCount: 1,
    year: 1939,
    yearWin: 1944,
  },
  {
    nominee: "Otto Stern",
    nomineeCount: 1,
    year: 1945,
    yearWin: 1943,
  },
  {
    nominee: "Otto Stern",
    nomineeCount: 2,
    year: 1944,
    yearWin: 1943,
  },
  {
    nominee: "Otto Stern",
    nomineeCount: 3,
    year: 1943,
    yearWin: 1943,
  },
  {
    nominee: "Otto Stern",
    nomineeCount: 2,
    year: 1941,
    yearWin: 1943,
  },
  {
    nominee: "Otto Stern",
    nomineeCount: 14,
    year: 1940,
    yearWin: 1943,
  },
  {
    nominee: "Otto Stern",
    nomineeCount: 2,
    year: 1939,
    yearWin: 1943,
  },
  {
    nominee: "Otto Stern",
    nomineeCount: 3,
    year: 1938,
    yearWin: 1943,
  },
  {
    nominee: "Otto Stern",
    nomineeCount: 5,
    year: 1937,
    yearWin: 1943,
  },
  {
    nominee: "Otto Stern",
    nomineeCount: 2,
    year: 1936,
    yearWin: 1943,
  },
  {
    nominee: "Otto Stern",
    nomineeCount: 2,
    year: 1935,
    yearWin: 1943,
  },
  {
    nominee: "Otto Stern",
    nomineeCount: 15,
    year: 1934,
    yearWin: 1943,
  },
  {
    nominee: "Otto Stern",
    nomineeCount: 2,
    year: 1933,
    yearWin: 1943,
  },
  {
    nominee: "Otto Stern",
    nomineeCount: 6,
    year: 1932,
    yearWin: 1943,
  },
  {
    nominee: "Otto Stern",
    nomineeCount: 5,
    year: 1931,
    yearWin: 1943,
  },
  {
    nominee: "Otto Stern",
    nomineeCount: 2,
    year: 1930,
    yearWin: 1943,
  },
  {
    nominee: "Otto Stern",
    nomineeCount: 8,
    year: 1929,
    yearWin: 1943,
  },
  {
    nominee: "Otto Stern",
    nomineeCount: 4,
    year: 1928,
    yearWin: 1943,
  },
  {
    nominee: "Otto Stern",
    nomineeCount: 3,
    year: 1927,
    yearWin: 1943,
  },
  {
    nominee: "Otto Stern",
    nomineeCount: 1,
    year: 1925,
    yearWin: 1943,
  },
  {
    nominee: "Ernest Lawrence",
    nomineeCount: 2,
    year: 1940,
    yearWin: 1939,
  },
  {
    nominee: "Ernest Lawrence",
    nomineeCount: 11,
    year: 1939,
    yearWin: 1939,
  },
  {
    nominee: "Ernest Lawrence",
    nomineeCount: 4,
    year: 1938,
    yearWin: 1939,
  },
  {
    nominee: "Enrico Fermi",
    nomineeCount: 1,
    year: 1948,
    yearWin: 1938,
  },
  {
    nominee: "Enrico Fermi",
    nomineeCount: 1,
    year: 1947,
    yearWin: 1938,
  },
  {
    nominee: "Enrico Fermi",
    nomineeCount: 2,
    year: 1939,
    yearWin: 1938,
  },
  {
    nominee: "Enrico Fermi",
    nomineeCount: 11,
    year: 1938,
    yearWin: 1938,
  },
  {
    nominee: "Enrico Fermi",
    nomineeCount: 13,
    year: 1937,
    yearWin: 1938,
  },
  {
    nominee: "Enrico Fermi",
    nomineeCount: 4,
    year: 1936,
    yearWin: 1938,
  },
  {
    nominee: "Enrico Fermi",
    nomineeCount: 3,
    year: 1935,
    yearWin: 1938,
  },
  {
    nominee: "Clinton Davisson",
    nomineeCount: 9,
    year: 1937,
    yearWin: 1937,
  },
  {
    nominee: "George Paget Thomson",
    nomineeCount: 4,
    year: 1937,
    yearWin: 1937,
  },
  {
    nominee: "Clinton Davisson",
    nomineeCount: 5,
    year: 1936,
    yearWin: 1937,
  },
  {
    nominee: "Clinton Davisson",
    nomineeCount: 10,
    year: 1934,
    yearWin: 1937,
  },
  {
    nominee: "George Paget Thomson",
    nomineeCount: 1,
    year: 1934,
    yearWin: 1937,
  },
  {
    nominee: "Clinton Davisson",
    nomineeCount: 4,
    year: 1933,
    yearWin: 1937,
  },
  {
    nominee: "George Paget Thomson",
    nomineeCount: 1,
    year: 1933,
    yearWin: 1937,
  },
  {
    nominee: "Clinton Davisson",
    nomineeCount: 5,
    year: 1932,
    yearWin: 1937,
  },
  {
    nominee: "George Paget Thomson",
    nomineeCount: 1,
    year: 1932,
    yearWin: 1937,
  },
  {
    nominee: "Clinton Davisson",
    nomineeCount: 3,
    year: 1931,
    yearWin: 1937,
  },
  {
    nominee: "George Paget Thomson",
    nomineeCount: 1,
    year: 1931,
    yearWin: 1937,
  },
  {
    nominee: "Clinton Davisson",
    nomineeCount: 3,
    year: 1930,
    yearWin: 1937,
  },
  {
    nominee: "Clinton Davisson",
    nomineeCount: 5,
    year: 1929,
    yearWin: 1937,
  },
  {
    nominee: "Carl D. Anderson",
    nomineeCount: 1,
    year: 1953,
    yearWin: 1936,
  },
  {
    nominee: "Carl D. Anderson",
    nomineeCount: 2,
    year: 1952,
    yearWin: 1936,
  },
  {
    nominee: "Carl D. Anderson",
    nomineeCount: 1,
    year: 1951,
    yearWin: 1936,
  },
  {
    nominee: "Carl D. Anderson",
    nomineeCount: 1,
    year: 1950,
    yearWin: 1936,
  },
  {
    nominee: "Carl D. Anderson",
    nomineeCount: 3,
    year: 1949,
    yearWin: 1936,
  },
  {
    nominee: "Carl D. Anderson",
    nomineeCount: 3,
    year: 1948,
    yearWin: 1936,
  },
  {
    nominee: "Carl D. Anderson",
    nomineeCount: 1,
    year: 1947,
    yearWin: 1936,
  },
  {
    nominee: "Carl D. Anderson",
    nomineeCount: 1,
    year: 1946,
    yearWin: 1936,
  },
  {
    nominee: "Carl D. Anderson",
    nomineeCount: 1,
    year: 1945,
    yearWin: 1936,
  },
  {
    nominee: "Carl D. Anderson",
    nomineeCount: 1,
    year: 1944,
    yearWin: 1936,
  },
  {
    nominee: "Carl D. Anderson",
    nomineeCount: 2,
    year: 1942,
    yearWin: 1936,
  },
  {
    nominee: "Carl D. Anderson",
    nomineeCount: 1,
    year: 1941,
    yearWin: 1936,
  },
  {
    nominee: "Carl D. Anderson",
    nomineeCount: 9,
    year: 1936,
    yearWin: 1936,
  },
  {
    nominee: "Victor F. Hess",
    nomineeCount: 2,
    year: 1936,
    yearWin: 1936,
  },
  {
    nominee: "Carl D. Anderson",
    nomineeCount: 2,
    year: 1935,
    yearWin: 1936,
  },
  {
    nominee: "Carl D. Anderson",
    nomineeCount: 8,
    year: 1934,
    yearWin: 1936,
  },
  {
    nominee: "Victor F. Hess",
    nomineeCount: 1,
    year: 1933,
    yearWin: 1936,
  },
  {
    nominee: "Victor F. Hess",
    nomineeCount: 1,
    year: 1931,
    yearWin: 1936,
  },
  {
    nominee: "James Chadwick",
    nomineeCount: 1,
    year: 1939,
    yearWin: 1935,
  },
  {
    nominee: "James Chadwick",
    nomineeCount: 13,
    year: 1935,
    yearWin: 1935,
  },
  {
    nominee: "James Chadwick",
    nomineeCount: 7,
    year: 1934,
    yearWin: 1935,
  },
  {
    nominee: "Erwin Schrödinger",
    nomineeCount: 11,
    year: 1933,
    yearWin: 1933,
  },
  {
    nominee: "Paul A.M. Dirac",
    nomineeCount: 2,
    year: 1933,
    yearWin: 1933,
  },
  {
    nominee: "Erwin Schrödinger",
    nomineeCount: 6,
    year: 1932,
    yearWin: 1933,
  },
  {
    nominee: "Erwin Schrödinger",
    nomineeCount: 3,
    year: 1931,
    yearWin: 1933,
  },
  {
    nominee: "Erwin Schrödinger",
    nomineeCount: 9,
    year: 1930,
    yearWin: 1933,
  },
  {
    nominee: "Erwin Schrödinger",
    nomineeCount: 10,
    year: 1929,
    yearWin: 1933,
  },
  {
    nominee: "Paul A.M. Dirac",
    nomineeCount: 1,
    year: 1929,
    yearWin: 1933,
  },
  {
    nominee: "Erwin Schrödinger",
    nomineeCount: 2,
    year: 1928,
    yearWin: 1933,
  },
  {
    nominee: "Werner Heisenberg",
    nomineeCount: 10,
    year: 1933,
    yearWin: 1932,
  },
  {
    nominee: "Werner Heisenberg",
    nomineeCount: 7,
    year: 1932,
    yearWin: 1932,
  },
  {
    nominee: "Werner Heisenberg",
    nomineeCount: 2,
    year: 1931,
    yearWin: 1932,
  },
  {
    nominee: "Werner Heisenberg",
    nomineeCount: 6,
    year: 1930,
    yearWin: 1932,
  },
  {
    nominee: "Werner Heisenberg",
    nomineeCount: 3,
    year: 1929,
    yearWin: 1932,
  },
  {
    nominee: "Werner Heisenberg",
    nomineeCount: 1,
    year: 1928,
    yearWin: 1932,
  },
  {
    nominee: "Sir Chandrasekhara Venkata Raman",
    nomineeCount: 10,
    year: 1930,
    yearWin: 1930,
  },
  {
    nominee: "Sir Chandrasekhara Venkata Raman",
    nomineeCount: 2,
    year: 1929,
    yearWin: 1930,
  },
  {
    nominee: "Louis de Broglie",
    nomineeCount: 1,
    year: 1950,
    yearWin: 1929,
  },
  {
    nominee: "Louis de Broglie",
    nomineeCount: 12,
    year: 1929,
    yearWin: 1929,
  },
  {
    nominee: "Louis de Broglie",
    nomineeCount: 1,
    year: 1928,
    yearWin: 1929,
  },
  {
    nominee: "Louis de Broglie",
    nomineeCount: 1,
    year: 1926,
    yearWin: 1929,
  },
  {
    nominee: "Louis de Broglie",
    nomineeCount: 1,
    year: 1925,
    yearWin: 1929,
  },
  {
    nominee: "Owen Willans Richardson",
    nomineeCount: 5,
    year: 1929,
    yearWin: 1928,
  },
  {
    nominee: "Owen Willans Richardson",
    nomineeCount: 3,
    year: 1928,
    yearWin: 1928,
  },
  {
    nominee: "Owen Willans Richardson",
    nomineeCount: 1,
    year: 1927,
    yearWin: 1928,
  },
  {
    nominee: "Owen Willans Richardson",
    nomineeCount: 2,
    year: 1926,
    yearWin: 1928,
  },
  {
    nominee: "Owen Willans Richardson",
    nomineeCount: 1,
    year: 1925,
    yearWin: 1928,
  },
  {
    nominee: "Owen Willans Richardson",
    nomineeCount: 1,
    year: 1924,
    yearWin: 1928,
  },
  {
    nominee: "Arthur H. Compton",
    nomineeCount: 9,
    year: 1927,
    yearWin: 1927,
  },
  {
    nominee: "C.T.R. Wilson",
    nomineeCount: 3,
    year: 1927,
    yearWin: 1927,
  },
  {
    nominee: "Arthur H. Compton",
    nomineeCount: 5,
    year: 1926,
    yearWin: 1927,
  },
  {
    nominee: "C.T.R. Wilson",
    nomineeCount: 1,
    year: 1926,
    yearWin: 1927,
  },
  {
    nominee: "Arthur H. Compton",
    nomineeCount: 1,
    year: 1925,
    yearWin: 1927,
  },
  {
    nominee: "C.T.R. Wilson",
    nomineeCount: 1,
    year: 1925,
    yearWin: 1927,
  },
  {
    nominee: "C.T.R. Wilson",
    nomineeCount: 3,
    year: 1924,
    yearWin: 1927,
  },
  {
    nominee: "C.T.R. Wilson",
    nomineeCount: 1,
    year: 1918,
    yearWin: 1927,
  },
  {
    nominee: "C.T.R. Wilson",
    nomineeCount: 1,
    year: 1915,
    yearWin: 1927,
  },
  {
    nominee: "Jean Baptiste Perrin",
    nomineeCount: 11,
    year: 1926,
    yearWin: 1926,
  },
  {
    nominee: "Jean Baptiste Perrin",
    nomineeCount: 3,
    year: 1925,
    yearWin: 1926,
  },
  {
    nominee: "Jean Baptiste Perrin",
    nomineeCount: 6,
    year: 1924,
    yearWin: 1926,
  },
  {
    nominee: "Jean Baptiste Perrin",
    nomineeCount: 2,
    year: 1923,
    yearWin: 1926,
  },
  {
    nominee: "Jean Baptiste Perrin",
    nomineeCount: 3,
    year: 1922,
    yearWin: 1926,
  },
  {
    nominee: "Jean Baptiste Perrin",
    nomineeCount: 3,
    year: 1921,
    yearWin: 1926,
  },
  {
    nominee: "Jean Baptiste Perrin",
    nomineeCount: 1,
    year: 1920,
    yearWin: 1926,
  },
  {
    nominee: "Jean Baptiste Perrin",
    nomineeCount: 1,
    year: 1919,
    yearWin: 1926,
  },
  {
    nominee: "Jean Baptiste Perrin",
    nomineeCount: 3,
    year: 1918,
    yearWin: 1926,
  },
  {
    nominee: "Jean Baptiste Perrin",
    nomineeCount: 1,
    year: 1917,
    yearWin: 1926,
  },
  {
    nominee: "Jean Baptiste Perrin",
    nomineeCount: 1,
    year: 1916,
    yearWin: 1926,
  },
  {
    nominee: "Jean Baptiste Perrin",
    nomineeCount: 1,
    year: 1913,
    yearWin: 1926,
  },
  {
    nominee: "James Franck",
    nomineeCount: 6,
    year: 1926,
    yearWin: 1925,
  },
  {
    nominee: "Gustav Hertz",
    nomineeCount: 1,
    year: 1926,
    yearWin: 1925,
  },
  {
    nominee: "James Franck",
    nomineeCount: 2,
    year: 1925,
    yearWin: 1925,
  },
  {
    nominee: "James Franck",
    nomineeCount: 4,
    year: 1924,
    yearWin: 1925,
  },
  {
    nominee: "Gustav Hertz",
    nomineeCount: 1,
    year: 1924,
    yearWin: 1925,
  },
  {
    nominee: "James Franck",
    nomineeCount: 1,
    year: 1923,
    yearWin: 1925,
  },
  {
    nominee: "Manne Siegbahn",
    nomineeCount: 3,
    year: 1925,
    yearWin: 1924,
  },
  {
    nominee: "Robert A. Millikan",
    nomineeCount: 2,
    year: 1926,
    yearWin: 1923,
  },
  {
    nominee: "Robert A. Millikan",
    nomineeCount: 1,
    year: 1923,
    yearWin: 1923,
  },
  {
    nominee: "Robert A. Millikan",
    nomineeCount: 4,
    year: 1922,
    yearWin: 1923,
  },
  {
    nominee: "Robert A. Millikan",
    nomineeCount: 1,
    year: 1921,
    yearWin: 1923,
  },
  {
    nominee: "Robert A. Millikan",
    nomineeCount: 2,
    year: 1920,
    yearWin: 1923,
  },
  {
    nominee: "Robert A. Millikan",
    nomineeCount: 6,
    year: 1919,
    yearWin: 1923,
  },
  {
    nominee: "Robert A. Millikan",
    nomineeCount: 2,
    year: 1918,
    yearWin: 1923,
  },
  {
    nominee: "Robert A. Millikan",
    nomineeCount: 1,
    year: 1916,
    yearWin: 1923,
  },
  {
    nominee: "Niels Bohr",
    nomineeCount: 11,
    year: 1922,
    yearWin: 1922,
  },
  {
    nominee: "Niels Bohr",
    nomineeCount: 1,
    year: 1921,
    yearWin: 1922,
  },
  {
    nominee: "Niels Bohr",
    nomineeCount: 3,
    year: 1920,
    yearWin: 1922,
  },
  {
    nominee: "Niels Bohr",
    nomineeCount: 3,
    year: 1919,
    yearWin: 1922,
  },
  {
    nominee: "Niels Bohr",
    nomineeCount: 1,
    year: 1918,
    yearWin: 1922,
  },
  {
    nominee: "Niels Bohr",
    nomineeCount: 1,
    year: 1917,
    yearWin: 1922,
  },
  {
    nominee: "Albert Einstein",
    nomineeCount: 2,
    year: 1923,
    yearWin: 1921,
  },
  {
    nominee: "Albert Einstein",
    nomineeCount: 17,
    year: 1922,
    yearWin: 1921,
  },
  {
    nominee: "Albert Einstein",
    nomineeCount: 14,
    year: 1921,
    yearWin: 1921,
  },
  {
    nominee: "Albert Einstein",
    nomineeCount: 8,
    year: 1920,
    yearWin: 1921,
  },
  {
    nominee: "Albert Einstein",
    nomineeCount: 5,
    year: 1919,
    yearWin: 1921,
  },
  {
    nominee: "Albert Einstein",
    nomineeCount: 6,
    year: 1918,
    yearWin: 1921,
  },
  {
    nominee: "Albert Einstein",
    nomineeCount: 3,
    year: 1917,
    yearWin: 1921,
  },
  {
    nominee: "Albert Einstein",
    nomineeCount: 1,
    year: 1916,
    yearWin: 1921,
  },
  {
    nominee: "Albert Einstein",
    nomineeCount: 2,
    year: 1914,
    yearWin: 1921,
  },
  {
    nominee: "Albert Einstein",
    nomineeCount: 3,
    year: 1913,
    yearWin: 1921,
  },
  {
    nominee: "Albert Einstein",
    nomineeCount: 4,
    year: 1912,
    yearWin: 1921,
  },
  {
    nominee: "Albert Einstein",
    nomineeCount: 1,
    year: 1910,
    yearWin: 1921,
  },
  {
    nominee: "Charles Edouard Guillaume",
    nomineeCount: 1,
    year: 1920,
    yearWin: 1920,
  },
  {
    nominee: "Charles Edouard Guillaume",
    nomineeCount: 1,
    year: 1914,
    yearWin: 1920,
  },
  {
    nominee: "Charles Edouard Guillaume",
    nomineeCount: 1,
    year: 1913,
    yearWin: 1920,
  },
  {
    nominee: "Charles Edouard Guillaume",
    nomineeCount: 1,
    year: 1912,
    yearWin: 1920,
  },
  {
    nominee: "Charles Edouard Guillaume",
    nomineeCount: 1,
    year: 1910,
    yearWin: 1920,
  },
  {
    nominee: "Charles Edouard Guillaume",
    nomineeCount: 3,
    year: 1908,
    yearWin: 1920,
  },
  {
    nominee: "Charles Edouard Guillaume",
    nomineeCount: 1,
    year: 1904,
    yearWin: 1920,
  },
  {
    nominee: "Charles Edouard Guillaume",
    nomineeCount: 1,
    year: 1903,
    yearWin: 1920,
  },
  {
    nominee: "Charles Edouard Guillaume",
    nomineeCount: 1,
    year: 1902,
    yearWin: 1920,
  },
  {
    nominee: "Johannes Stark",
    nomineeCount: 1,
    year: 1919,
    yearWin: 1919,
  },
  {
    nominee: "Johannes Stark",
    nomineeCount: 1,
    year: 1918,
    yearWin: 1919,
  },
  {
    nominee: "Johannes Stark",
    nomineeCount: 3,
    year: 1917,
    yearWin: 1919,
  },
  {
    nominee: "Johannes Stark",
    nomineeCount: 5,
    year: 1916,
    yearWin: 1919,
  },
  {
    nominee: "Johannes Stark",
    nomineeCount: 1,
    year: 1914,
    yearWin: 1919,
  },
  {
    nominee: "Max Planck",
    nomineeCount: 6,
    year: 1919,
    yearWin: 1918,
  },
  {
    nominee: "Max Planck",
    nomineeCount: 6,
    year: 1918,
    yearWin: 1918,
  },
  {
    nominee: "Max Planck",
    nomineeCount: 5,
    year: 1917,
    yearWin: 1918,
  },
  {
    nominee: "Max Planck",
    nomineeCount: 4,
    year: 1916,
    yearWin: 1918,
  },
  {
    nominee: "Max Planck",
    nomineeCount: 2,
    year: 1915,
    yearWin: 1918,
  },
  {
    nominee: "Max Planck",
    nomineeCount: 11,
    year: 1914,
    yearWin: 1918,
  },
  {
    nominee: "Max Planck",
    nomineeCount: 5,
    year: 1913,
    yearWin: 1918,
  },
  {
    nominee: "Max Planck",
    nomineeCount: 7,
    year: 1912,
    yearWin: 1918,
  },
  {
    nominee: "Max Planck",
    nomineeCount: 6,
    year: 1911,
    yearWin: 1918,
  },
  {
    nominee: "Max Planck",
    nomineeCount: 10,
    year: 1910,
    yearWin: 1918,
  },
  {
    nominee: "Max Planck",
    nomineeCount: 9,
    year: 1909,
    yearWin: 1918,
  },
  {
    nominee: "Max Planck",
    nomineeCount: 1,
    year: 1908,
    yearWin: 1918,
  },
  {
    nominee: "Max Planck",
    nomineeCount: 2,
    year: 1907,
    yearWin: 1918,
  },
  {
    nominee: "Charles Glover Barkla",
    nomineeCount: 1,
    year: 1918,
    yearWin: 1917,
  },
  {
    nominee: "William Bragg",
    nomineeCount: 4,
    year: 1915,
    yearWin: 1915,
  },
  {
    nominee: "Lawrence Bragg",
    nomineeCount: 2,
    year: 1915,
    yearWin: 1915,
  },
  {
    nominee: "William Bragg",
    nomineeCount: 1,
    year: 1914,
    yearWin: 1915,
  },
  {
    nominee: "Max von Laue",
    nomineeCount: 3,
    year: 1915,
    yearWin: 1914,
  },
  {
    nominee: "Max von Laue",
    nomineeCount: 2,
    year: 1914,
    yearWin: 1914,
  },
  {
    nominee: "Heike Kamerlingh Onnes",
    nomineeCount: 7,
    year: 1913,
    yearWin: 1913,
  },
  {
    nominee: "Heike Kamerlingh Onnes",
    nomineeCount: 6,
    year: 1912,
    yearWin: 1913,
  },
  {
    nominee: "Heike Kamerlingh Onnes",
    nomineeCount: 1,
    year: 1910,
    yearWin: 1913,
  },
  {
    nominee: "Heike Kamerlingh Onnes",
    nomineeCount: 6,
    year: 1909,
    yearWin: 1913,
  },
  {
    nominee: "Gustaf Dalén",
    nomineeCount: 1,
    year: 1912,
    yearWin: 1912,
  },
  {
    nominee: "Wilhelm Wien",
    nomineeCount: 1,
    year: 1929,
    yearWin: 1911,
  },
  {
    nominee: "Wilhelm Wien",
    nomineeCount: 1,
    year: 1927,
    yearWin: 1911,
  },
  {
    nominee: "Wilhelm Wien",
    nomineeCount: 1,
    year: 1926,
    yearWin: 1911,
  },
  {
    nominee: "Wilhelm Wien",
    nomineeCount: 1,
    year: 1911,
    yearWin: 1911,
  },
  {
    nominee: "Wilhelm Wien",
    nomineeCount: 1,
    year: 1910,
    yearWin: 1911,
  },
  {
    nominee: "Wilhelm Wien",
    nomineeCount: 2,
    year: 1909,
    yearWin: 1911,
  },
  {
    nominee: "Wilhelm Wien",
    nomineeCount: 1,
    year: 1908,
    yearWin: 1911,
  },
  {
    nominee: "Wilhelm Wien",
    nomineeCount: 1,
    year: 1907,
    yearWin: 1911,
  },
  {
    nominee: "Johannes Diderik van der Waals",
    nomineeCount: 1,
    year: 1910,
    yearWin: 1910,
  },
  {
    nominee: "Johannes Diderik van der Waals",
    nomineeCount: 2,
    year: 1909,
    yearWin: 1910,
  },
  {
    nominee: "Johannes Diderik van der Waals",
    nomineeCount: 5,
    year: 1907,
    yearWin: 1910,
  },
  {
    nominee: "Johannes Diderik van der Waals",
    nomineeCount: 1,
    year: 1901,
    yearWin: 1910,
  },
  {
    nominee: "Guglielmo Marconi",
    nomineeCount: 1,
    year: 1933,
    yearWin: 1909,
  },
  {
    nominee: "Guglielmo Marconi",
    nomineeCount: 1,
    year: 1929,
    yearWin: 1909,
  },
  {
    nominee: "Guglielmo Marconi",
    nomineeCount: 2,
    year: 1909,
    yearWin: 1909,
  },
  {
    nominee: "Ferdinand Braun",
    nomineeCount: 1,
    year: 1909,
    yearWin: 1909,
  },
  {
    nominee: "Guglielmo Marconi",
    nomineeCount: 2,
    year: 1908,
    yearWin: 1909,
  },
  {
    nominee: "Ferdinand Braun",
    nomineeCount: 1,
    year: 1907,
    yearWin: 1909,
  },
  {
    nominee: "Ferdinand Braun",
    nomineeCount: 2,
    year: 1906,
    yearWin: 1909,
  },
  {
    nominee: "Ferdinand Braun",
    nomineeCount: 1,
    year: 1905,
    yearWin: 1909,
  },
  {
    nominee: "Guglielmo Marconi",
    nomineeCount: 6,
    year: 1903,
    yearWin: 1909,
  },
  {
    nominee: "Guglielmo Marconi",
    nomineeCount: 2,
    year: 1902,
    yearWin: 1909,
  },
  {
    nominee: "Guglielmo Marconi",
    nomineeCount: 1,
    year: 1901,
    yearWin: 1909,
  },
  {
    nominee: "Gabriel Lippmann",
    nomineeCount: 5,
    year: 1908,
    yearWin: 1908,
  },
  {
    nominee: "Gabriel Lippmann",
    nomineeCount: 7,
    year: 1907,
    yearWin: 1908,
  },
  {
    nominee: "Gabriel Lippmann",
    nomineeCount: 3,
    year: 1906,
    yearWin: 1908,
  },
  {
    nominee: "Gabriel Lippmann",
    nomineeCount: 3,
    year: 1905,
    yearWin: 1908,
  },
  {
    nominee: "Gabriel Lippmann",
    nomineeCount: 3,
    year: 1904,
    yearWin: 1908,
  },
  {
    nominee: "Gabriel Lippmann",
    nomineeCount: 1,
    year: 1903,
    yearWin: 1908,
  },
  {
    nominee: "Gabriel Lippmann",
    nomineeCount: 1,
    year: 1901,
    yearWin: 1908,
  },
  {
    nominee: "Albert A. Michelson",
    nomineeCount: 3,
    year: 1907,
    yearWin: 1907,
  },
  {
    nominee: "Albert A. Michelson",
    nomineeCount: 1,
    year: 1904,
    yearWin: 1907,
  },
  {
    nominee: "J.J. Thomson",
    nomineeCount: 1,
    year: 1912,
    yearWin: 1906,
  },
  {
    nominee: "J.J. Thomson",
    nomineeCount: 8,
    year: 1906,
    yearWin: 1906,
  },
  {
    nominee: "J.J. Thomson",
    nomineeCount: 13,
    year: 1905,
    yearWin: 1906,
  },
  {
    nominee: "J.J. Thomson",
    nomineeCount: 1,
    year: 1904,
    yearWin: 1906,
  },
  {
    nominee: "J.J. Thomson",
    nomineeCount: 1,
    year: 1902,
    yearWin: 1906,
  },
  {
    nominee: "Philipp Lenard",
    nomineeCount: 1,
    year: 1925,
    yearWin: 1905,
  },
  {
    nominee: "Philipp Lenard",
    nomineeCount: 1,
    year: 1924,
    yearWin: 1905,
  },
  {
    nominee: "Philipp Lenard",
    nomineeCount: 2,
    year: 1905,
    yearWin: 1905,
  },
  {
    nominee: "Philipp Lenard",
    nomineeCount: 3,
    year: 1904,
    yearWin: 1905,
  },
  {
    nominee: "Philipp Lenard",
    nomineeCount: 1,
    year: 1903,
    yearWin: 1905,
  },
  {
    nominee: "Philipp Lenard",
    nomineeCount: 1,
    year: 1902,
    yearWin: 1905,
  },
  {
    nominee: "Philipp Lenard",
    nomineeCount: 6,
    year: 1901,
    yearWin: 1905,
  },
  {
    nominee: "Lord Rayleigh",
    nomineeCount: 11,
    year: 1904,
    yearWin: 1904,
  },
  {
    nominee: "Lord Rayleigh",
    nomineeCount: 7,
    year: 1903,
    yearWin: 1904,
  },
  {
    nominee: "Lord Rayleigh",
    nomineeCount: 2,
    year: 1902,
    yearWin: 1904,
  },
  {
    nominee: "Henri Becquerel",
    nomineeCount: 6,
    year: 1903,
    yearWin: 1903,
  },
  {
    nominee: "Marie Curie",
    nomineeCount: 1,
    year: 1903,
    yearWin: 1903,
  },
  {
    nominee: "Pierre Curie",
    nomineeCount: 5,
    year: 1903,
    yearWin: 1903,
  },
  {
    nominee: "Henri Becquerel",
    nomineeCount: 3,
    year: 1902,
    yearWin: 1903,
  },
  {
    nominee: "Marie Curie",
    nomineeCount: 2,
    year: 1902,
    yearWin: 1903,
  },
  {
    nominee: "Pierre Curie",
    nomineeCount: 3,
    year: 1902,
    yearWin: 1903,
  },
  {
    nominee: "Henri Becquerel",
    nomineeCount: 1,
    year: 1901,
    yearWin: 1903,
  },
  {
    nominee: "Hendrik A. Lorentz",
    nomineeCount: 1,
    year: 1919,
    yearWin: 1902,
  },
  {
    nominee: "Hendrik A. Lorentz",
    nomineeCount: 2,
    year: 1918,
    yearWin: 1902,
  },
  {
    nominee: "Hendrik A. Lorentz",
    nomineeCount: 1,
    year: 1913,
    yearWin: 1902,
  },
  {
    nominee: "Hendrik A. Lorentz",
    nomineeCount: 2,
    year: 1912,
    yearWin: 1902,
  },
  {
    nominee: "Hendrik A. Lorentz",
    nomineeCount: 10,
    year: 1902,
    yearWin: 1902,
  },
  {
    nominee: "Pieter Zeeman",
    nomineeCount: 1,
    year: 1902,
    yearWin: 1902,
  },
  {
    nominee: "Pieter Zeeman",
    nomineeCount: 2,
    year: 1901,
    yearWin: 1902,
  },
  {
    nominee: "Wilhelm Conrad Röntgen",
    nomineeCount: 16,
    year: 1901,
    yearWin: 1901,
  },
];

// year - year, when nomination took place
// nominees - name and nominators count
export const nominees = [
  {
    year: 1901,
    nominees: {
      "Svante Arrhenius": 2,
      "Henri Becquerel": 1,
      "William Campbell": 2,
      "Philipp Lenard": 6,
      "Wilhelm Conrad Röntgen": 16,
      "Gabriel Lippmann": 1,
      "Guglielmo Marconi": 1,
      "Adolf Nordenskiöld": 1,
      "Grenville Clark": 1,
      "William Thomson, Lord Kelvin": 1,
      "Johannes Diderik van der Waals": 1,
      "Pieter Zeeman": 2,
    },
  },
  {
    year: 1902,
    nominees: {
      "Svante Arrhenius": 3,
      "Henri Becquerel": 3,
      "Marie Curie": 2,
      "Pierre Curie": 3,
      "Lord Rayleigh": 2,
      "René Benoit": 1,
      "Pierre Chappuis": 1,
      "Charles Edouard Guillaume": 1,
      "Wilhelm Hittorf": 1,
      "John Kerr": 2,
      "Philipp Lenard": 1,
      "Hendrik A. Lorentz": 10,
      "Guglielmo Marconi": 2,
      "J.J. Thomson": 1,
      "William Thomson, Lord Kelvin": 1,
      "Pieter Zeeman": 1,
    },
  },
  {
    year: 1903,
    nominees: {
      "Svante Arrhenius": 7,
      "Henri Becquerel": 6,
      "Marie Curie": 1,
      "Pierre Curie": 5,
      "René Benoit": 1,
      "Pierre Chappuis": 1,
      "Charles Edouard Guillaume": 1,
      "Ludwig Boltzmann": 1,
      "Wilhelm Hittorf": 1,
      "John Kerr": 1,
      "Gustave Le Bon": 1,
      "Philipp Lenard": 1,
      "Gabriel Lippmann": 1,
      "Guglielmo Marconi": 6,
      "Lord Rayleigh": 7,
      "William Thomson, Lord Kelvin": 2,
    },
  },
  {
    year: 1904,
    nominees: {
      "Philipp Lenard": 3,
      "Ernst Abbe": 1,
      "Wilhelm Hittorf": 1,
      "Friedrich Kohlrausch": 2,
      "René Benoit": 1,
      "Pierre Chappuis": 1,
      "Charles Edouard Guillaume": 1,
      "Edouard Branly": 1,
      "J.J. Thomson": 1,
      "Sir James Dewar": 1,
      "Walter Kaufmann": 1,
      "Karol Olszewski": 1,
      "Julius Elster": 1,
      "Hans Geitel": 1,
      "Oliver Heaviside": 1,
      "Lord Rayleigh": 11,
      "Arthur Schuster": 1,
      "Gabriel Lippmann": 3,
      "Henri Poincaré": 2,
      "Albert A. Michelson": 1,
      "Sir William Ramsay": 1,
    },
  },
  {
    year: 1905,
    nominees: {
      "Ernst Abbe": 1,
      "René Blondlot": 1,
      "Ludwig Boltzmann": 1,
      "Ferdinand Braun": 1,
      "Sir James Dewar": 1,
      "Petr Lebedev": 1,
      "Julius Elster": 1,
      "Hans Geitel": 1,
      "Oliver Heaviside": 1,
      "Philipp Lenard": 2,
      "Heinrich Kayser": 1,
      "Friedrich Kohlrausch": 1,
      "Gabriel Lippmann": 3,
      "Augusto Righi": 1,
      "J.J. Thomson": 13,
    },
  },
  {
    year: 1906,
    nominees: {
      "Ludwig Boltzmann": 3,
      "Ferdinand Braun": 2,
      "Julius von Hann": 1,
      "Friedrich Kohlrausch": 1,
      "J.J. Thomson": 8,
      "Gabriel Lippmann": 3,
      "Henri Poincaré": 3,
      "Augusto Righi": 1,
      "William Thomson, Lord Kelvin": 1,
    },
  },
  {
    year: 1907,
    nominees: {
      "Ferdinand Braun": 1,
      "William Crookes": 1,
      "Pierre Duhem": 1,
      "Albert A. Michelson": 3,
      "Augusto Righi": 2,
      "Ernest Lord Rutherford": 7,
      "Julius Elster": 1,
      "Hans Geitel": 1,
      "William Thomson, Lord Kelvin": 2,
      "Ernst Hagen": 1,
      "Heinrich Rubens": 1,
      "Oliver Heaviside": 1,
      "Max Planck": 2,
      "Wilhelm Wien": 1,
      "Friedrich Kohlrausch": 1,
      "Gabriel Lippmann": 7,
      "Henri Poincaré": 2,
      "Johannes Diderik van der Waals": 5,
    },
  },
  {
    year: 1908,
    nominees: {
      "Emile Amagat": 1,
      "Guglielmo Marconi": 2,
      "René Benoit": 3,
      "Charles Edouard Guillaume": 3,
      "Julius Elster": 2,
      "Hans Geitel": 2,
      "Friedrich Kohlrausch": 1,
      "Gustaf de Laval": 1,
      "Gabriel Lippmann": 5,
      "Max Planck": 1,
      "Wilhelm Wien": 1,
      "Augusto Righi": 4,
      "Ernest Lord Rutherford": 5,
    },
  },
  {
    year: 1909,
    nominees: {
      "Edouard Branly": 1,
      "Guglielmo Marconi": 2,
      "Valdemar Poulsen": 1,
      "Ferdinand Braun": 1,
      "Julius Elster": 1,
      "Hans Geitel": 1,
      "Wilbur Wright": 8,
      "Henri Farman": 8,
      "Gabriel Voisin": 8,
      "Orville Wright": 8,
      "Max Planck": 9,
      "Wilhelm Wien": 2,
      "George Hale": 7,
      "Oliver Heaviside": 1,
      "John Poynting": 1,
      "Heike Kamerlingh Onnes": 6,
      "Henri Poincaré": 3,
      "Friedrich Kohlrausch": 3,
      "Johannes Diderik van der Waals": 2,
      "Augusto Righi": 1,
      "Heinrich Rubens": 2,
    },
  },
  {
    year: 1910,
    nominees: {
      "Henri Abraham": 1,
      "Paul Langevin": 1,
      "Knut Ångström": 1,
      "René Benoit": 1,
      "Charles Edouard Guillaume": 1,
      "Henri Poincaré": 34,
      "William Crookes": 1,
      "Albert Einstein": 1,
      "Julius Elster": 4,
      "Hans Geitel": 4,
      "Max Planck": 10,
      "Allvar Gullstrand": 1,
      "Heike Kamerlingh Onnes": 1,
      "Otto Lummer": 1,
      "Wilhelm Wien": 1,
      "Johannes Diderik van der Waals": 1,
      "Augusto Righi": 5,
    },
  },
  {
    year: 1911,
    nominees: {
      "William Crookes": 1,
      "Julius Elster": 3,
      "Hans Geitel": 3,
      "Loránd Eötvös": 3,
      "Charles Fabry": 1,
      "Eugen Goldstein": 1,
      "Allvar Gullstrand": 2,
      "Julius von Hann": 1,
      "Ernst Mach Jr": 2,
      "Oliver Heaviside": 1,
      "Otto Lummer": 1,
      "Max Planck": 6,
      "Wilhelm Wien": 1,
      "Walther Nernst": 1,
      "Henri Poincaré": 5,
      "John Poynting": 1,
      "Augusto Righi": 1,
    },
  },
  {
    year: 1912,
    nominees: {
      "René Benoit": 1,
      "Charles Edouard Guillaume": 1,
      "Gustaf Dalén": 1,
      "Sir James Dewar": 1,
      "Heike Kamerlingh Onnes": 6,
      "Albert Einstein": 4,
      "Hendrik A. Lorentz": 2,
      "Petr Lebedev": 1,
      "Robert Hadfield": 2,
      "Oliver Heaviside": 1,
      "Max Planck": 7,
      "Ernst Mach Jr": 3,
      "Walther Nernst": 2,
      "J.J. Thomson": 1,
      "Henri Poincaré": 2,
      "Augusto Righi": 3,
      "John Poynting": 3,
    },
  },
  {
    year: 1913,
    nominees: {
      "Emile Amagat": 6,
      "Henri Deslandres": 1,
      "René Benoit": 1,
      "Charles Edouard Guillaume": 1,
      "Hugh Callendar": 1,
      "Sir James Dewar": 2,
      "Heike Kamerlingh Onnes": 7,
      "Carl von Linde": 1,
      "Albert Einstein": 3,
      "Jean Baptiste Perrin": 1,
      "Theodor Svedberg": 1,
      "Hendrik A. Lorentz": 1,
      "Hermann Frahm": 1,
      "Eugen Goldstein": 1,
      "Robert Hadfield": 2,
      "George Hale": 1,
      "Julius von Hann": 1,
      "Karol Olszewski": 4,
      "Otto Lehmann": 1,
      "Max Planck": 5,
      "Augusto Righi": 5,
      "Adolf Slaby": 1,
      "Orville Wright": 1,
      "Wilbur Wright": 1,
      "Ferdinand von Zeppelin": 1,
    },
  },
  {
    year: 1914,
    nominees: {
      "Emile Amagat": 2,
      "René Benoit": 1,
      "Charles Edouard Guillaume": 1,
      "William Bragg": 1,
      "Max von Laue": 2,
      "William Crookes": 1,
      "Albert Einstein": 2,
      "Loránd Eötvös": 3,
      "Friedrich Paschen": 2,
      "Charles Fabry": 2,
      "Augusto Righi": 4,
      "Georges Gouy": 1,
      "George Hale": 2,
      "Arthur Schuster": 1,
      "Max Planck": 11,
      "Oliver Heaviside": 1,
      "Martin Knudsen": 2,
      "Henry Le Châtelier": 1,
      "Walther Nernst": 2,
      "Otto Lehmann": 1,
      "Ernst Mach Jr": 1,
      "Johannes Stark": 1,
      "Robert Wood": 2,
    },
  },
  {
    year: 1915,
    nominees: {
      "Carl Störmer": 1,
      "Kristian Birkeland": 1,
      "William Bragg": 4,
      "Max von Laue": 3,
      "Lawrence Bragg": 2,
      "Henri Deslandres": 1,
      "George Hale": 3,
      "Charles Darwin": 1,
      "Henry Moseley": 1,
      "Max Planck": 2,
      "Thomas Edison": 1,
      "G Casalis": 1,
      "N Pavia": 1,
      "Aimé Cotton": 1,
      "Otto Lehmann": 1,
      "Walther Nernst": 1,
      "Friedrich Paschen": 1,
      "Augusto Righi": 1,
      "C.T.R. Wilson": 1,
      "Robert Wood": 1,
    },
  },
  {
    year: 1916,
    nominees: {
      "Georges Gouy": 2,
      "Henri Mouton": 1,
      "Aimé Cotton": 1,
      "Pierre Duhem": 1,
      "Charles Fabry": 1,
      "Paul Langevin": 1,
      "Jean Baptiste Perrin": 1,
      "Pierre Weiss": 1,
      "René Benoit": 1,
      "Henri Abraham": 1,
      "Alexandre Dufour": 1,
      "Gustav Ferrié": 1,
      "Kristian Birkeland": 2,
      "Carl Störmer": 2,
      "Petrus Debye": 1,
      "Henri Deslandres": 1,
      "George Hale": 4,
      "Albert Einstein": 1,
      "Heinrich Kayser": 1,
      "Carl Runge": 1,
      "Martin Knudsen": 2,
      "Irving Langmuir": 1,
      "Robert A. Millikan": 1,
      "Otto Lehmann": 1,
      "Walther Nernst": 1,
      "Peder Pedersen": 2,
      "Valdemar Poulsen": 3,
      "Max Planck": 4,
      "Johannes Stark": 5,
      "Augusto Righi": 2,
    },
  },
  {
    year: 1917,
    nominees: {
      "Kristian Birkeland": 1,
      "Carl Störmer": 1,
      "Niels Bohr": 1,
      "Martin Knudsen": 1,
      "Petrus Debye": 1,
      "Albert Einstein": 3,
      "Loránd Eötvös": 2,
      "Jean Baptiste Perrin": 1,
      "Charles Fabry": 1,
      "Georges Gouy": 2,
      "George Hale": 6,
      "Heinrich Kayser": 1,
      "Carl Runge": 1,
      "Johannes Rydberg": 1,
      "Otto Lehmann": 1,
      "Walther Nernst": 2,
      "Peder Pedersen": 2,
      "Valdemar Poulsen": 3,
      "Max Planck": 5,
      "Arnold Sommerfeld": 1,
      "Augusto Righi": 2,
      "Arthur Schuster": 1,
      "Johannes Stark": 3,
    },
  },
  {
    year: 1918,
    nominees: {
      "Charles Glover Barkla": 1,
      "Niels Bohr": 1,
      "Arnold Sommerfeld": 1,
      "Max Planck": 6,
      "Albert Einstein": 6,
      "Alfred Pérot": 1,
      "Hugh Callendar": 1,
      "Theodore Lyman": 1,
      "Robert A. Millikan": 2,
      "William Crookes": 1,
      "C.T.R. Wilson": 1,
      "Hendrik A. Lorentz": 2,
      "Friedrich Paschen": 1,
      "Charles Fabry": 2,
      "Georges Gouy": 1,
      "Martin Knudsen": 2,
      "Otto Lehmann": 1,
      "Carl von Linde": 1,
      "Peder Pedersen": 2,
      "Valdemar Poulsen": 2,
      "Jean Baptiste Perrin": 3,
      "Augusto Righi": 1,
      "Fernando Sanford": 1,
      "Johannes Stark": 1,
    },
  },
  {
    year: 1919,
    nominees: {
      "Niels Bohr": 3,
      "Max Planck": 6,
      "Percy W. Bridgman": 1,
      "Albert Einstein": 5,
      "Hendrik A. Lorentz": 1,
      "Arnold Sommerfeld": 2,
      "Friedrich Paschen": 1,
      "Georges Gouy": 1,
      "Martin Knudsen": 1,
      "Otto Lehmann": 1,
      "Robert A. Millikan": 6,
      "Peder Pedersen": 2,
      "Valdemar Poulsen": 2,
      "Jean Baptiste Perrin": 1,
      "Theodor Svedberg": 1,
      "Augusto Righi": 3,
      "Fernando Sanford": 1,
      "Johannes Stark": 1,
    },
  },
  {
    year: 1920,
    nominees: {
      "Walter Adams": 1,
      "Carl Barus": 1,
      "Robert A. Millikan": 2,
      "Robert Wood": 1,
      "Niels Bohr": 3,
      "Arnold Sommerfeld": 1,
      "Aimé Cotton": 1,
      "Albert Einstein": 8,
      "Charles Edouard Guillaume": 1,
      "Martin Knudsen": 1,
      "Otto Lehmann": 1,
      "Auguste Lumière": 1,
      "Louis Lumière": 1,
      "Peder Pedersen": 2,
      "Valdemar Poulsen": 3,
      "Jean Baptiste Perrin": 1,
      "Augusto Righi": 4,
      "Fernando Sanford": 1,
    },
  },
  {
    year: 1921,
    nominees: {
      "Carl Barus": 1,
      "Niels Bohr": 1,
      "Hugh Callendar": 1,
      "Petrus Debye": 1,
      "Albert Einstein": 14,
      "Jean Baptiste Perrin": 3,
      "Ernst Gehrcke": 1,
      "Martin Knudsen": 1,
      "Paul Langevin": 1,
      "Otto Lehmann": 1,
      "Auguste Lumière": 1,
      "Louis Lumière": 1,
      "Robert A. Millikan": 1,
      "Walther Nernst": 1,
      "Peder Pedersen": 2,
      "Valdemar Poulsen": 3,
      "Pierre Weiss": 1,
    },
  },
  {
    year: 1922,
    nominees: {
      "George Hale": 4,
      "Robert A. Millikan": 4,
      "Charles Abbot": 1,
      "Francis Aston": 3,
      "William Coolidge": 1,
      "Niels Bohr": 11,
      "Albert Einstein": 17,
      "Petrus Debye": 1,
      "Arnold Sommerfeld": 4,
      "Aimé Cotton": 1,
      "Paul Langevin": 2,
      "Jean Baptiste Perrin": 3,
      "Pierre Weiss": 1,
      "Charles Fabry": 2,
      "Georges Gouy": 1,
      "Friedrich Paschen": 5,
      "Leo Graetz": 1,
      "Otto Lehmann": 1,
      "Auguste Lumière": 1,
      "Louis Lumière": 1,
      "Peder Pedersen": 2,
      "Valdemar Poulsen": 2,
      "Ernest Lord Rutherford": 1,
    },
  },
  {
    year: 1923,
    nominees: {
      "Vilhelm Bjerknes": 1,
      "Henri Deslandres": 1,
      "George Hale": 2,
      "James Franck": 1,
      "Wolfgang Gaede": 1,
      "Ernst Gehrcke": 1,
      "Friedrich Paschen": 3,
      "Robert A. Millikan": 1,
      "Arnold Sommerfeld": 2,
      "Peder Pedersen": 2,
      "Valdemar Poulsen": 2,
      "Jean Baptiste Perrin": 2,
      "Ernest Lord Rutherford": 1,
      "Albert Einstein": 2,
    },
  },
  {
    year: 1924,
    nominees: {
      "Vilhelm Bjerknes": 1,
      "Percy W. Bridgman": 1,
      "Petrus Debye": 2,
      "James Franck": 4,
      "Gustav Hertz": 1,
      "Friedrich Paschen": 4,
      "Robert Wood": 4,
      "Wolfgang Gaede": 1,
      "Ernst Gehrcke": 1,
      "Leo Graetz": 1,
      "George Hale": 1,
      "Philipp Lenard": 1,
      "Auguste Lumière": 1,
      "Louis Lumière": 1,
      "Arnold Sommerfeld": 1,
      "Jean Baptiste Perrin": 6,
      "Owen Willans Richardson": 1,
      "Jules Richard": 1,
      "Ernest Lord Rutherford": 1,
      "William Shaw": 1,
      "Sir John Townsend": 1,
      "Pierre Weiss": 1,
      "C.T.R. Wilson": 3,
    },
  },
  {
    year: 1925,
    nominees: {
      "Louis de Broglie": 1,
      "Maurice de Broglie": 1,
      "Arthur H. Compton": 1,
      "Aimé Cotton": 1,
      "Petrus Debye": 2,
      "Arnold Sommerfeld": 6,
      "George Hale": 1,
      "Charles Fabry": 1,
      "Robert Wood": 3,
      "John Fleming": 3,
      "James Franck": 2,
      "Wolfgang Gaede": 2,
      "Walter Gerlach": 1,
      "Otto Stern": 1,
      "Paul Langevin": 1,
      "Philipp Lenard": 1,
      "Friedrich Paschen": 3,
      "Jean Baptiste Perrin": 3,
      "Owen Willans Richardson": 1,
      "Arthur Schuster": 1,
      "Manne Siegbahn": 3,
      "C.T.R. Wilson": 1,
      "Carl Störmer": 1,
      "Siegmund Strauss": 1,
      "Pierre Weiss": 1,
    },
  },
  {
    year: 1926,
    nominees: {
      "Vilhelm Bjerknes": 2,
      "Louis de Broglie": 1,
      "Maurice de Broglie": 1,
      "Arthur H. Compton": 5,
      "Petrus Debye": 2,
      "James Franck": 6,
      "Owen Willans Richardson": 2,
      "Gustav Hertz": 1,
      "Friedrich Paschen": 8,
      "Robert Wood": 2,
      "Eugen Goldstein": 1,
      "Werner Kohlhörster": 1,
      "Carl von Linde": 2,
      "Theodore Lyman": 1,
      "Robert A. Millikan": 2,
      "Dayton Miller": 1,
      "Arnold Sommerfeld": 3,
      "Jean Baptiste Perrin": 11,
      "Wilhelm Wien": 1,
      "C.T.R. Wilson": 1,
    },
  },
  {
    year: 1927,
    nominees: {
      "Arthur H. Compton": 9,
      "Walter Gerlach": 3,
      "Otto Stern": 3,
      "Carl Ramsauer": 1,
      "William Coolidge": 1,
      "Aimé Cotton": 4,
      "Petrus Debye": 1,
      "John Fleming": 4,
      "Owen Willans Richardson": 1,
      "Victor Kaplan": 1,
      "Paul Langevin": 1,
      "Auguste Lumière": 1,
      "Louis Lumière": 1,
      "Friedrich Paschen": 1,
      "Arnold Sommerfeld": 3,
      "William Shaw": 1,
      "Carl Störmer": 1,
      "Wilhelm Wien": 1,
      "C.T.R. Wilson": 3,
      "Robert Wood": 1,
    },
  },
  {
    year: 1928,
    nominees: {
      "Tor Bergeron": 1,
      "Jacob Bjerknes": 1,
      "Halvor Solberg": 1,
      "Percy W. Bridgman": 1,
      "Louis de Broglie": 1,
      "Maurice de Broglie": 2,
      "Aimé Cotton": 1,
      "Petrus Debye": 2,
      "Erwin Schrödinger": 2,
      "John Fleming": 1,
      "Owen Willans Richardson": 3,
      "Ernst Gehrcke": 1,
      "Walter Gerlach": 3,
      "Otto Stern": 4,
      "Robert Wood": 3,
      "Werner Heisenberg": 1,
      "Paul Langevin": 3,
      "Pierre Weiss": 4,
      "Irving Langmuir": 1,
      "Friedrich Paschen": 4,
      "Arnold Sommerfeld": 3,
      "Ludwig Prandtl": 3,
    },
  },
  {
    year: 1929,
    nominees: {
      "Edwin Armstrong": 2,
      "Alexander Meissner": 2,
      "Vilhelm Bjerknes": 1,
      "Percy W. Bridgman": 2,
      "Clinton Davisson": 5,
      "Lester Germer": 1,
      "Louis de Broglie": 12,
      "Walter Gerlach": 7,
      "Otto Stern": 8,
      "Erwin Schrödinger": 10,
      "Pierre Weiss": 4,
      "Paul Langevin": 6,
      "Arnold Sommerfeld": 9,
      "Paul A.M. Dirac": 1,
      "Werner Heisenberg": 3,
      "Emil Warburg": 1,
      "Friedrich Paschen": 3,
      "Robert Wood": 3,
      "Jean Cabannes": 1,
      "Sir Chandrasekhara Venkata Raman": 2,
      "Aimé Cotton": 1,
      "Petrus Debye": 3,
      "Wolfgang Gaede": 1,
      "Wilhelm Wien": 1,
      "Owen Willans Richardson": 5,
      "David Hilbert": 1,
      "Irving Langmuir": 1,
      "Guglielmo Marconi": 1,
      "Michael Pupin": 2,
    },
  },
  {
    year: 1930,
    nominees: {
      "Max Born": 1,
      "Werner Heisenberg": 6,
      "Arnold Sommerfeld": 4,
      "Clinton Davisson": 3,
      "Ira Bowen": 2,
      "Henry Norris Russell": 1,
      "Aimé Cotton": 2,
      "Petrus Debye": 2,
      "Gustav Ferrié": 1,
      "Walter Gerlach": 1,
      "Otto Stern": 2,
      "Erwin Schrödinger": 9,
      "Sir Chandrasekhara Venkata Raman": 10,
      "David Hilbert": 1,
      "Grigoriy Landsberg": 1,
      "Leonid Mandelshtam (Mandelstam)": 2,
      "Paul Langevin": 1,
      "Pierre Weiss": 3,
      "Robert Wood": 4,
      "Friedrich Paschen": 1,
      "Meghnad Saha": 2,
    },
  },
  {
    year: 1931,
    nominees: {
      "Jean Cabannes": 1,
      "Aimé Cotton": 2,
      "Georges Claude": 3,
      "Clinton Davisson": 3,
      "Lester Germer": 1,
      "George Paget Thomson": 1,
      "Petrus Debye": 4,
      "Robert Wood": 1,
      "Otto Stern": 5,
      "Friedrich Paschen": 1,
      "Arnold Sommerfeld": 2,
      "Gustav Ferrié": 1,
      "Walter Gerlach": 1,
      "Werner Heisenberg": 2,
      "Erwin Schrödinger": 3,
      "Victor F. Hess": 1,
      "Carl Ramsauer": 1,
      "David Hilbert": 1,
      "Paul Langevin": 2,
      "Pierre Weiss": 1,
      "Ernest Lord Rutherford": 1,
    },
  },
  {
    year: 1932,
    nominees: {
      "Aimé Cotton": 2,
      "Clinton Davisson": 5,
      "Lester Germer": 3,
      "Petrus Debye": 7,
      "Erwin Schrödinger": 6,
      "George Paget Thomson": 1,
      "Robert Wood": 1,
      "Otto Stern": 6,
      "Friedrich Paschen": 3,
      "Arnold Sommerfeld": 5,
      "Sir Arthur Eddington": 1,
      "Charles Fabry": 2,
      "Walter Gerlach": 2,
      "Werner Heisenberg": 7,
      "David Hilbert": 1,
      "Kotaro Honda": 3,
      "Auguste Piccard": 1,
      "Ernest Lord Rutherford": 1,
      "Pierre Weiss": 1,
    },
  },
  {
    year: 1933,
    nominees: {
      "Percy W. Bridgman": 11,
      "Aimé Cotton": 1,
      "Clinton Davisson": 4,
      "Lester Germer": 1,
      "Erwin Schrödinger": 11,
      "George Paget Thomson": 1,
      "Petrus Debye": 2,
      "Werner Heisenberg": 10,
      "Robert Wood": 2,
      "Otto Stern": 2,
      "Friedrich Paschen": 4,
      "Arnold Sommerfeld": 8,
      "Paul A.M. Dirac": 2,
      "Paul Langevin": 2,
      "Sir Arthur Eddington": 1,
      "Wolfgang Pauli": 1,
      "Victor F. Hess": 1,
      "David Hilbert": 2,
      "Pierre Weiss": 1,
      "Irving Langmuir": 1,
      "Guglielmo Marconi": 1,
      "Auguste Piccard": 1,
      "Ernest Lord Rutherford": 1,
      "René Thiry": 1,
    },
  },
  {
    year: 1934,
    nominees: {
      "Carl D. Anderson": 8,
      "Irène Joliot-Curie": 9,
      "Arnold Sommerfeld": 6,
      "Otto Stern": 15,
      "James Chadwick": 7,
      "Walter Gerlach": 5,
      "Frédéric Joliot": 6,
      "Clinton Davisson": 10,
      "Lester Germer": 8,
      "Wolfgang Pauli": 1,
      "Max Born": 1,
      "Aimé Cotton": 6,
      "Pierre Weiss": 1,
      "Paul Langevin": 1,
      "Walther Bothe": 1,
      "Ira Bowen": 1,
      "Ferdinand Brickwedde": 1,
      "George Murphy": 1,
      "Harold Clayton Urey": 1,
      "Percy W. Bridgman": 1,
      "George Paget Thomson": 1,
      "Sir Arthur Eddington": 1,
      "Gustaf Elmen": 1,
      "John Fleming": 1,
      "George Hale": 1,
      "Carl Störmer": 1,
      "Lars Vegard": 1,
      "Giancarlo Vallauri": 1,
    },
  },
  {
    year: 1935,
    nominees: {
      "Carl D. Anderson": 2,
      "James Chadwick": 13,
      "Edward V. Appleton": 3,
      "Arthur Kennelly": 2,
      "Frédéric Joliot": 6,
      "Irène Joliot-Curie": 6,
      "Enrico Fermi": 3,
      "William Coolidge": 1,
      "Felix Ehrenhaft": 1,
      "Hugo Dingler": 1,
      "Alexander Gurwitsch [Gurvitsch]": 1,
      "Sir Arthur Eddington": 1,
      "Abraham Esau": 1,
      "Wolfgang Gaede": 1,
      "Hans Geiger": 1,
      "Samuel Abraham Goudsmit": 1,
      "Wolfgang Pauli": 4,
      "Wander de Haas": 1,
      "Otto Stern": 2,
      "Paul Langevin": 1,
      "John McLennan": 1,
      "Ernest Lord Rutherford": 1,
      "Arnold Sommerfeld": 1,
      "Giancarlo Vallauri": 1,
      "Fritz Zwicky": 1,
    },
  },
  {
    year: 1936,
    nominees: {
      "Carl D. Anderson": 9,
      "Patrick M.S. Blackett": 3,
      "Giuseppe Paolo Stanislao Occhialini": 1,
      "Jacob Clay": 1,
      "Victor F. Hess": 2,
      "Jacob Bjerknes": 1,
      "Vilhelm Bjerknes": 2,
      "Percy W. Bridgman": 1,
      "Clinton Davisson": 5,
      "Lester Germer": 4,
      "Arnold Sommerfeld": 2,
      "Petrus Debye": 1,
      "Enrico Fermi": 4,
      "Sir Arthur Eddington": 1,
      "Wolfgang Gaede": 1,
      "Walter Gerlach": 1,
      "Otto Stern": 2,
      "Wander de Haas": 1,
      "Pierre Weiss": 2,
    },
  },
  {
    year: 1937,
    nominees: {
      "Halvor Solberg": 3,
      "Vilhelm Bjerknes": 3,
      "Tor Bergeron": 3,
      "Jacob Bjerknes": 3,
      "John Cockcroft": 2,
      "Ernest T.S. Walton": 2,
      "Clinton Davisson": 9,
      "Lester Germer": 8,
      "George Paget Thomson": 4,
      "Enrico Fermi": 13,
      "Walter Gerlach": 4,
      "Otto Stern": 5,
      "Wolfgang Pauli": 5,
      "Arnold Sommerfeld": 8,
      "Alfred Loomis": 7,
      "Robert Wood": 8,
      "Otto Hahn": 2,
      "Lise Meitner": 2,
      "Wolfgang Gaede": 3,
      "Martin Knudsen": 3,
      "Hans Geiger": 1,
      "Wander de Haas": 2,
      "Gilles Holst": 1,
      "Paul Langevin": 1,
      "Ludwig Prandtl": 1,
      "Ernest Lord Rutherford": 1,
      "Meghnad Saha": 1,
      "Sir Geoffrey  Ingram Taylor": 1,
      "Nikola Tesla": 1,
      "Pierre Weiss": 1,
    },
  },
  {
    year: 1938,
    nominees: {
      "Vilhelm Bjerknes": 4,
      "Enrico Fermi": 11,
      "Wander de Haas": 1,
      "Ernest Lawrence": 4,
      "Otto Stern": 3,
      "Erich Regener": 1,
      "Wolfgang Pauli": 1,
      "John Cockcroft": 1,
      "Ernest T.S. Walton": 1,
      "Halvor Solberg": 3,
      "Tor Bergeron": 3,
      "Jacob Bjerknes": 3,
    },
  },
  {
    year: 1939,
    nominees: {
      "Edward V. Appleton": 3,
      "Patrick M.S. Blackett": 1,
      "Max Born": 1,
      "Ira Bowen": 1,
      "Percy W. Bridgman": 10,
      "Maurice de Broglie": 1,
      "James Chadwick": 1,
      "Enrico Fermi": 2,
      "Wander de Haas": 1,
      "Ernest Lawrence": 11,
      "Isidor Isaac Rabi": 1,
      "Meghnad Saha": 1,
      "Otto Stern": 2,
      "John Cockcroft": 1,
      "Ernest T.S. Walton": 1,
      "Halvor Solberg": 2,
      "Vilhelm Bjerknes": 2,
      "Tor Bergeron": 2,
      "Jacob Bjerknes": 2,
      "Petrus Debye": 1,
      "Paul Scherrer": 1,
      "Albert Hull": 1,
    },
  },
  {
    year: 1940,
    nominees: {
      "Vilhelm Bjerknes": 4,
      "Patrick M.S. Blackett": 1,
      "Percy W. Bridgman": 2,
      "Wander de Haas": 3,
      "Jaroslav Heyrovský": 5,
      "Irving Langmuir": 1,
      "Wolfgang Pauli": 3,
      "Arnold Sommerfeld": 1,
      "Hideki Yukawa": 2,
      "Otto Stern": 14,
      "Ernest Lawrence": 2,
      "(Arthur) Neville Chamberlain": 1,
      "Henri Bouasse": 1,
      "Meghnad Saha": 1,
      "Otto Hahn": 3,
      "Lise Meitner": 3,
      "Isidor Isaac Rabi": 9,
      "William Giauque": 1,
      "Walter Gerlach": 1,
      "Willem Keesom": 1,
    },
  },
  {
    year: 1941,
    nominees: {
      "Edward V. Appleton": 1,
      "Pierre Auger": 1,
      "Percy W. Bridgman": 2,
      "Wander de Haas": 1,
      "Otto Hahn": 3,
      "Isidor Isaac Rabi": 2,
      "Giancarlo Vallauri": 2,
      "Hideki Yukawa": 1,
      "Otto Stern": 2,
      "Wolfgang Pauli": 1,
      "Fritz Strassmann": 1,
      "Lise Meitner": 1,
      "Seth Neddermeyer": 2,
      "Carl D. Anderson": 1,
    },
  },
  {
    year: 1942,
    nominees: {
      "Edward V. Appleton": 2,
      "Vilhelm Bjerknes": 2,
      "Patrick M.S. Blackett": 1,
      "Percy W. Bridgman": 2,
      "Wander de Haas": 4,
      "George de Hevesy": 1,
      "Wolfgang Pauli": 1,
      "Isidor Isaac Rabi": 3,
      "Robert Wood": 2,
      "Seth Neddermeyer": 2,
      "Carl D. Anderson": 2,
      "Sir John Townsend": 1,
    },
  },
  {
    year: 1943,
    nominees: {
      "Edward V. Appleton": 2,
      "Hans Albrecht Bethe": 1,
      "Percy W. Bridgman": 2,
      "Jean Cabannes": 1,
      "John Cockcroft": 1,
      "Otto Hahn": 2,
      "Donald William Kerst": 2,
      "Santiago Mayolo": 1,
      "Wolfgang Pauli": 2,
      "Isidor Isaac Rabi": 4,
      "Hideki Yukawa": 1,
      "Wander de Haas": 1,
      "Willem Keesom": 1,
      "Otto Stern": 3,
      "George Gamow": 1,
      "Lise Meitner": 1,
    },
  },
  {
    year: 1944,
    nominees: {
      "Edward V. Appleton": 2,
      "Vilhelm Bjerknes": 3,
      "Patrick M.S. Blackett": 2,
      "Aimé Cotton": 1,
      "Sir Arthur Eddington": 1,
      "Sir John Townsend": 1,
      "Hideki Yukawa": 1,
      "Willem Keesom": 1,
      "Frits Zernike": 1,
      "Seth Neddermeyer": 1,
      "Carl D. Anderson": 1,
      "Otto Stern": 2,
      "Wolfgang Pauli": 1,
      "Walter Gerlach": 1,
      "Isidor Isaac Rabi": 1,
      "Hendrik Dorgelo": 1,
      "Christiaan van Geel": 1,
      "Gilles Holst": 1,
      "Frans Penning": 1,
    },
  },
  {
    year: 1945,
    nominees: {
      "Vilhelm Bjerknes": 1,
      "Patrick M.S. Blackett": 2,
      "Percy W. Bridgman": 3,
      "Donald William Kerst": 1,
      "Bernard Lyot": 1,
      "Seth Neddermeyer": 2,
      "Wolfgang Pauli": 3,
      "Isidor Isaac Rabi": 1,
      "Sir Geoffrey  Ingram Taylor": 1,
      "Hideki Yukawa": 1,
      "Carl D. Anderson": 1,
      "John Cockcroft": 1,
      "Ernest T.S. Walton": 1,
      "Otto Hahn": 2,
      "Fritz Strassmann": 1,
      "Lise Meitner": 1,
      "Otto Robert Frisch": 1,
      "Willem Keesom": 1,
      "Wander de Haas": 1,
      "Otto Stern": 1,
    },
  },
  {
    year: 1946,
    nominees: {
      "Edward V. Appleton": 1,
      "Walther Bothe": 1,
      "Percy W. Bridgman": 2,
      "John Cockcroft": 1,
      "Arthur Dempster": 1,
      "Pyotr Kapitsa": 1,
      "Donald William Kerst": 1,
      "Paul Langevin": 1,
      "Lise Meitner": 5,
      "Robert Oppenheimer": 1,
      "Wolfgang Pauli": 6,
      "Sir Francis Simon": 1,
      "Hideki Yukawa": 2,
      "Carl D. Anderson": 1,
      "Seth Neddermeyer": 1,
      "Max Born": 1,
      "Otto Hahn": 3,
      "Otto Robert Frisch": 3,
      "George Gamow": 1,
      "Maurice de Broglie": 1,
      "Fritz Strassmann": 1,
      "Hans Albrecht Bethe": 1,
      "Nuclear Scientists": 1,
    },
  },
  {
    year: 1947,
    nominees: {
      "Edward V. Appleton": 1,
      "Patrick M.S. Blackett": 2,
      "Max Born": 2,
      "John Cockcroft": 2,
      "Enrico Fermi": 1,
      "Camille Gutton": 1,
      "Pyotr Kapitsa": 4,
      "Donald William Kerst": 3,
      "Bernard Lyot": 1,
      "Walther Meissner": 1,
      "Lise Meitner": 6,
      "Carl D. Anderson": 1,
      "Seth Neddermeyer": 1,
      "Karl-Friedrich Bonhoeffer": 1,
      "Klaus Clusius": 1,
      "Ernest T.S. Walton": 1,
      "Otto Robert Frisch": 3,
      "Otto Hahn": 1,
      "George Eugene Uhlenbeck": 3,
      "Samuel Abraham Goudsmit": 3,
      "Llewellyn Thomas": 1,
      "Willem Keesom": 1,
      "Vladimir Iosifovich Veksler": 1,
      "Edwin McMillan": 1,
      "Robert Serber": 1,
      "Pierre Auger": 1,
      "Dimitrij Skobelzyn": 1,
      "Bruno Benedetto Rossi": 1,
      "Nuclear Scientists": 1,
    },
  },
  {
    year: 1948,
    nominees: {
      "Hans Albrecht Bethe": 1,
      "Patrick M.S. Blackett": 6,
      "Felix Bloch": 2,
      "Max Born": 4,
      "Arthur Dempster": 1,
      "Enrico Fermi": 1,
      "Pyotr Kapitsa": 2,
      "Donald William Kerst": 3,
      "Bernard Lyot": 3,
      "Lise Meitner": 2,
      "Balthasar van der Pol": 1,
      "Helmut Scheffers": 1,
      "Arnold Sommerfeld": 1,
      "Vladimir Iosifovich Veksler": 2,
      "Hideki Yukawa": 2,
      "Pierre Auger": 2,
      "Willem Keesom": 1,
      "Seth Neddermeyer": 3,
      "Carl D. Anderson": 3,
      "Marcel Schein": 1,
      "Otto Robert Frisch": 1,
      "Bruno Benedetto Rossi": 1,
      "Henry Yeagley": 1,
      "Robert Wichard Pohl": 1,
      "Karl Jansky": 1,
      "Bengt Edlén": 1,
      "Walter Grotrian": 1,
      "Frits Zernike": 1,
      "Edwin McMillan": 1,
    },
  },
  {
    year: 1949,
    nominees: {
      "Hans Albrecht Bethe": 2,
      "Max Born": 4,
      "Walther Bothe": 2,
      "Vannevar Bush": 1,
      "John Cockcroft": 1,
      "Aimé Cotton": 1,
      "Harold Jeffreys": 1,
      "Donald William Kerst": 2,
      "Bernard Lyot": 2,
      "Lise Meitner": 1,
      "Seth Neddermeyer": 3,
      "Alfred Nier": 1,
      "Balthasar van der Pol": 2,
      "Albert Portevin": 1,
      "Cecil Powell": 8,
      "Bruno Benedetto Rossi": 3,
      "Arnold Sommerfeld": 3,
      "Jabez Curry Street": 2,
      "Leo Szilard": 1,
      "Hideki Yukawa": 10,
      "Carl D. Anderson": 3,
      "Marcel Schein": 1,
      "César Lattes": 2,
      "Eugène Gardner": 1,
      "E. M. Purcell": 1,
      "Felix Bloch": 1,
      "Patrick M.S. Blackett": 1,
      "Giuseppe Paolo Stanislao Occhialini": 4,
      "Pierre Auger": 1,
      "Klaus Clusius": 1,
      "Karl-Friedrich Bonhoeffer": 1,
      "Hendrik Kramers": 1,
      "Marle Tuve": 1,
      "Gregory Breit": 1,
      "Lawrence Hafstad": 1,
      "Edward Stevenson": 1,
    },
  },
  {
    year: 1950,
    nominees: {
      "Georg von Békésy": 1,
      "Hans Albrecht Bethe": 1,
      "Felix Bloch": 3,
      "Louis de Broglie": 1,
      "Pyotr Kapitsa": 1,
      "Bernard Lyot": 7,
      "Walther Meissner": 1,
      "Seth Neddermeyer": 2,
      "Balthasar van der Pol": 1,
      "Cecil Powell": 14,
      "Arnold Sommerfeld": 3,
      "Robert Wood": 1,
      "Hideki Yukawa": 2,
      "Frits Zernike": 2,
      "Max Born": 4,
      "Hendrik Kramers": 3,
      "Ira Bowen": 1,
      "Bengt Edlén": 2,
      "Joseph Mattauch": 1,
      "Walther Kossel": 1,
      "Carl D. Anderson": 1,
      "E. M. Purcell": 2,
      "Henry Norris Russell": 2,
      "Marietta Blau": 1,
      "Hertha Wambacher": 1,
      "Giuseppe Paolo Stanislao Occhialini": 3,
      "Horace Welcome Babcock": 1,
      "Grote  Reber": 1,
    },
  },
  {
    year: 1951,
    nominees: {
      "Luis Walter Alvarez": 1,
      "Pierre Auger": 1,
      "Hans Albrecht Bethe": 2,
      "Homi Bhabha": 1,
      "Felix Bloch": 4,
      "Max Born": 3,
      "Walther Bothe": 1,
      "John Cockcroft": 5,
      "Pascual Jordan": 1,
      "Willis E. Lamb": 4,
      "César Lattes": 1,
      "Bernard Lyot": 4,
      "Edwin McMillan": 3,
      "Erwin Wilhelm Müller": 2,
      "Seth Neddermeyer": 1,
      "Robert Oppenheimer": 1,
      "Wolfgang Kurt Hermann Panofsky": 1,
      "Cecil Powell": 1,
      "Meghnad Saha": 1,
      "Marcel Schein": 2,
      "Paul Scherrer": 1,
      "Julian Schwinger": 2,
      "Arnold Sommerfeld": 4,
      "Sin-Itiro Tomonaga": 2,
      "John Hasbrouck  Van Vleck": 1,
      "Frits Zernike": 2,
      "George Eugene Uhlenbeck": 1,
      "Samuel Abraham Goudsmit": 1,
      "E. M. Purcell": 2,
      "Ernest T.S. Walton": 2,
      "Donald William Kerst": 1,
      "Vladimir Iosifovich Veksler": 1,
      "Robert Retherford": 3,
      "Carl D. Anderson": 1,
      "William Francis Swann": 1,
    },
  },
  {
    year: 1952,
    nominees: {
      "Samuel Jackson Barnett": 2,
      "Felix Bloch": 7,
      "Max Born": 4,
      "Walther Bothe": 1,
      "Pavel A. Cherenkov": 1,
      "John Cockcroft": 1,
      "Georges Destriau": 1,
      "Donald William Kerst": 1,
      "César Lattes": 2,
      "Bernard Lyot": 4,
      "Sir Nevill Francis Mott": 1,
      "Erwin Wilhelm Müller": 2,
      "Seth Neddermeyer": 2,
      "Louis Néel": 1,
      "Giuseppe Paolo Stanislao Occhialini": 3,
      "Lars Onsager": 1,
      "E. M. Purcell": 5,
      "Frits Zernike": 2,
      "Carl D. Anderson": 2,
      "Bruno Benedetto Rossi": 1,
      "Hendrik Kramers": 2,
      "Samuel Abraham Goudsmit": 1,
      "George Eugene Uhlenbeck": 1,
      "Willis E. Lamb": 3,
      "Polykarp Kusch": 1,
      "Robert Retherford": 2,
      "Wolfgang Kurt Hermann Panofsky": 1,
      "Sin-Itiro Tomonaga": 1,
      "Julian Schwinger": 1,
      "Marcel Schein": 1,
      "William Francis Swann": 1,
      "Jacob Clay": 1,
      "H Bradt": 1,
      "Bernard Peters": 1,
    },
  },
  {
    year: 1953,
    nominees: {
      "Samuel Jackson Barnett": 2,
      "Georg von Békésy": 1,
      "Hans Albrecht Bethe": 6,
      "Homi Bhabha": 1,
      "Felix Bloch": 1,
      "Max Born": 4,
      "Walther Bothe": 2,
      "Jean Cabannes": 1,
      "Klaus Clusius": 1,
      "Edwin Hubble": 3,
      "Donald William Kerst": 5,
      "Willis E. Lamb": 4,
      "César Lattes": 1,
      "Louis Leprince-Ringuet": 2,
      "Willard Frank Libby": 1,
      "Erwin Wilhelm Müller": 1,
      "Louis Néel": 1,
      "Giuseppe Paolo Stanislao Occhialini": 1,
      "Lars Onsager": 1,
      "Wolfgang Kurt Hermann Panofsky": 1,
      "Robert Wichard Pohl": 1,
      "Emilio Segrè": 1,
      "Sir Francis Simon": 1,
      "Jabez Curry Street": 1,
      "Sir Geoffrey  Ingram Taylor": 1,
      "Eugene Wigner": 1,
      "Frits Zernike": 3,
      "Carl D. Anderson": 1,
      "William Francis Swann": 1,
      "John Bardeen": 1,
      "Walter H. Brattain": 1,
      "Henry Norris Russell": 1,
      "Robert Jemison Van de Graaff": 1,
      "Robert Retherford": 2,
      "George Eugene Uhlenbeck": 1,
      "Samuel Abraham Goudsmit": 1,
      "Carl Friedrich  von Weizsäcker": 1,
      "Luis Walter Alvarez": 1,
      "Pierre Auger": 1,
      "Pyotr Kapitsa": 1,
      "Hannes Alfvén": 1,
    },
  },
  {
    year: 1954,
    nominees: {
      "Georg von Békésy": 1,
      "Hans Albrecht Bethe": 2,
      "Homi Bhabha": 1,
      "Max Born": 5,
      "Walther Bothe": 7,
      "Bengt Edlén": 3,
      "Walther Kossel": 1,
      "Willis E. Lamb": 6,
      "César Lattes": 1,
      "Jean Laval": 1,
      "Georges Lemaître": 1,
      "Louis Leprince-Ringuet": 2,
      "Lise Meitner": 1,
      "Louis Néel": 4,
      "Giuseppe Paolo Stanislao Occhialini": 1,
      "Bruno Benedetto Rossi": 6,
      "Sir Geoffrey  Ingram Taylor": 3,
      "Vladimir Zworykin": 1,
      "Martin Deutsch": 2,
      "Maurice Goldhaber": 1,
      "Lev Landau": 1,
      "Fritz London": 1,
      "Lars Onsager": 1,
      "Ilya Prigogine": 1,
      "Jabez Curry Street": 1,
      "Samuel Jackson Barnett": 2,
      "Samuel Abraham Goudsmit": 2,
      "George Eugene Uhlenbeck": 2,
      "William B. Shockley": 3,
      "John Bardeen": 3,
      "Walter H. Brattain": 3,
      "Karl Lark-Horowitz": 1,
      "James Baker": 1,
      "Edwin Land": 1,
      "Edward Linfoot": 1,
    },
  },
  {
    year: 1955,
    nominees: {
      "Homi Bhabha": 1,
      "Marietta Blau": 1,
      "Léon Brillouin": 1,
      "Pavel A. Cherenkov": 1,
      "Klaus Clusius": 2,
      "Martin Deutsch": 1,
      "Hans Geiger": 1,
      "Pyotr Kapitsa": 1,
      "Willem Keesom": 1,
      "Walther Kossel": 5,
      "Willis E. Lamb": 5,
      "Louis Leprince-Ringuet": 2,
      "Maria Goeppert Mayer": 2,
      "Walther Meissner": 3,
      "Lise Meitner": 1,
      "Louis Néel": 2,
      "Giuseppe Paolo Stanislao Occhialini": 2,
      "Robert Oppenheimer": 1,
      "George Dixon Rochester": 1,
      "Bruno Benedetto Rossi": 2,
      "Meghnad Saha": 1,
      "Sir Geoffrey Ingram Taylor": 3,
      "Sin-Itiro Tomonaga": 1,
      "Eugene Wigner": 1,
      "George Eugene Uhlenbeck": 11,
      "Samuel Abraham Goudsmit": 11,
      "Samuel Jackson Barnett": 2,
      "John Bardeen": 1,
      "William B. Shockley": 1,
      "J. Hans D. Jensen": 1,
      "Hans Albrecht Bethe": 1,
      "Polykarp Kusch": 2,
      "Robert Retherford": 1,
      "Bodo von Borries": 2,
      "Ernst August Friedrich Ruska": 2,
      "Max Knoll": 1,
      "Hendrik Christoffel van de Hulst": 3,
      "Christiaan Alexander (Lex) Muller": 3,
      "Jan Oort": 3,
      "Jabez Curry Street": 1,
    },
  },
  {
    year: 1956,
    nominees: {
      "Horace Welcome Babcock": 1,
      "Hans Albrecht Bethe": 7,
      "Homi Bhabha": 1,
      "Marietta Blau": 1,
      "Jan Hendrick de Boer": 1,
      "Satyendra Nath Bose": 1,
      "Pavel A. Cherenkov": 2,
      "Sydney Chapman": 1,
      "Klaus Clusius": 2,
      "Martin Deutsch": 2,
      "Richard P. Feynman": 6,
      "Lee de Forest": 1,
      "Dorothy Crowfoot Hodgkin": 1,
      "Pyotr Kapitsa": 6,
      "Alfred Kastler": 1,
      "Louis Leprince-Ringuet": 1,
      "Willard Frank Libby": 1,
      "Louis Néel": 7,
      "Mark Laurence Oliphant": 1,
      "Lars Onsager": 1,
      "Jan Oort": 3,
      "Wolfgang Kurt Hermann Panofsky": 1,
      "Bruno Benedetto Rossi": 4,
      "Sir Francis Simon": 2,
      "Sir Geoffrey Ingram Taylor": 1,
      "Julian Schwinger": 5,
      "Maria Goeppert Mayer": 6,
      "J. Hans D. Jensen": 6,
      "Cornelis Jacobus  Gorter": 4,
      "Samuel Abraham Goudsmit": 9,
      "George Eugene Uhlenbeck": 9,
      "Samuel Jackson Barnett": 2,
      "Lev Landau": 4,
      "Giuseppe Paolo Stanislao Occhialini": 1,
      "Jabez Curry Street": 1,
      "Bengt Edlén": 1,
      "Sin-Itiro Tomonaga": 3,
      "Gustaf Ising": 1,
      "Rolf Wideröe": 1,
      "Donald William Kerst": 1,
      "John Bardeen": 3,
      "Walter H. Brattain": 3,
      "William B. Shockley": 2,
      "Gerald Pearson": 1,
      "Hendrik Christoffel  van de Hulst": 2,
      "Christiaan Alexander (Lex) Muller": 2,
      "Otto Haxel": 1,
      "Hans Suess": 1,
      "Lise Meitner": 1,
      "Emilio Segrè": 1,
      "Vladimir Iosifovich Veksler": 1,
      "Edwin McMillan": 1,
    },
  },
  {
    year: 1957,
    nominees: {
      "Marietta Blau": 1,
      "Brebis Bleaney": 1,
      "Pavel A. Cherenkov": 6,
      "Subramanyan Chandrasekhar": 1,
      "Klaus Clusius": 1,
      "Martin Deutsch": 1,
      "Bengt Edlén": 2,
      "Richard P. Feynman": 2,
      "Dorothy Crowfoot Hodgkin": 1,
      "Pyotr Kapitsa": 6,
      "Louis Leprince-Ringuet": 1,
      "Willard Frank Libby": 1,
      "Erwin Wilhelm Müller": 1,
      "Louis Néel": 3,
      "Wolfgang Kurt Hermann Panofsky": 1,
      "George Dixon Rochester": 2,
      "Carl-Gustaf Arvid Rossby": 1,
      "Bruno Benedetto Rossi": 4,
      "Emilio Segrè": 9,
      "Sir Geoffrey  Ingram Taylor": 2,
      "Eugene Wigner": 7,
      "Lars Onsager": 1,
      "Hans Albrecht Bethe": 1,
      "Robert Wichard Pohl": 1,
      "Léon Brillouin": 1,
      "Ernest Wollan": 1,
      "Clifford Shull": 1,
      "Samuel Abraham Goudsmit": 2,
      "George Eugene Uhlenbeck": 2,
      "Clifford Butler": 1,
      "Owen Chamberlain": 1,
      "Sin-Itiro Tomonaga": 2,
      "Julian Schwinger": 2,
      "Giuseppe Paolo Stanislao Occhialini": 2,
      "Igor Y. Tamm": 2,
      "Il´ja M. Frank": 1,
      "Sergey Vavilov": 1,
      "Cornelis Jacobus  Gorter": 1,
      "Lev Landau": 2,
      "Hans Busch": 3,
      "Ernst August Friedrich Ruska": 3,
      "Max Knoll": 3,
      "Marcello Conversi": 1,
      "Ettore Pancini": 1,
      "Oreste Piccioni": 1,
      "Hendrik Christoffel van de Hulst": 2,
      "Jan Oort": 2,
      "Christiaan Alexander(Lex) Muller": 2,
      "Maria Goeppert Mayer": 2,
      "J. Hans D. Jensen": 2,
      "Otto Haxel": 1,
      "Hans Suess": 1,
      "Aleksei Naumov": 1,
      "Gersh Budker": 1,
      "Vladimir Iosifovich Veksler": 1,
      "Clyde Cowan, Jr": 1,
      "Frederick Reines": 1,
      "Chen Ning Yang": 1,
      "Tsung-Dao Lee": 1,
    },
  },
  {
    year: 1958,
    nominees: {
      "Jesse Beams": 1,
      "Georg von Békésy": 2,
      "Seymour Benzer": 1,
      "Hans Albrecht Bethe": 2,
      "Klaus Clusius": 1,
      "Martin Deutsch": 2,
      "Alfred Kastler": 1,
      "Lev Landau": 3,
      "Louis Néel": 6,
      "Heinz Neuber": 1,
      "Lars Onsager": 2,
      "Ilya Prigogine": 1,
      "Boris Rajewsky": 1,
      "Bruno Benedetto Rossi": 1,
      "Emilio Segrè": 4,
      "Sir Geoffrey Ingram Taylor": 1,
      "Charles H. Townes": 3,
      'Victor Frederick "Viki" Weisskopf': 1,
      "Carl Friedrich von Weizsäcker": 1,
      "Eugene Wigner": 2,
      "Chieng-Shiung Wu": 2,
      "Yevgeny Konstantinovich Zavoisky": 1,
      "Harold Delos Babcock": 1,
      "Horace Welcome Babcock": 1,
      "David Bohm": 1,
      "David Pines": 1,
      "Clyde Cowan, Jr": 2,
      "Frederick Reines": 2,
      "Maria Goeppert Mayer": 2,
      "J. Hans D. Jensen": 2,
      "Sir Nevill Francis Mott": 1,
      "William Hume-Rothery": 1,
      "Pyotr Kapitsa": 2,
      "Pavel A. Cherenkov": 2,
      "Sergey Vavilov": 1,
      "Il´ja M. Frank": 1,
      "Igor Y. Tamm": 1,
      "Giuseppe Paolo Stanislao Occhialini": 1,
      "George Dixon Rochester": 1,
      "Clifford Butler": 1,
      "George Eugene Uhlenbeck": 2,
      "Samuel Abraham Goudsmit": 2,
      "Jan Oort": 3,
      "Hendrik Christoffel  van de Hulst": 2,
      "Christiaan Alexander (Lex) Muller": 2,
      "Owen Chamberlain": 1,
      "Clyde Wiegand": 1,
      "Thomas Ypsilantis": 1,
      "Alfred Landé": 1,
      "Robert Hofstadter": 1,
      "Abdus Salam": 1,
    },
  },
  {
    year: 1959,
    nominees: {
      "John Bernal": 1,
      "Hans Albrecht Bethe": 1,
      "Brebis Bleaney": 1,
      "Nikolai Nikolaevic Bogoljubov": 1,
      "Satyendra Nath Bose": 1,
      "Sydney Chapman": 1,
      "Klaus Clusius": 1,
      "Friedrich Dessauer": 1,
      "Donald A. Glaser": 4,
      "Maurice Goldhaber": 1,
      "Robert Hofstadter": 6,
      "Abram Joffé": 1,
      "Alfred Kastler": 1,
      "Nicholas Kemmer": 1,
      "Charles Kittel": 1,
      "Lev Landau": 1,
      "Louis Néel": 4,
      "Jan Oort": 1,
      "Robert Wichard Pohl": 1,
      "Wolfgang Kurt Hermann Panofsky": 1,
      "Walter Schottky": 4,
      "Emilio Segrè": 9,
      "Sir Geoffrey  Ingram Taylor": 2,
      "Charles H. Townes": 4,
      "Albrecht Unsöld": 1,
      "Vladimir Iosifovich Veksler": 2,
      "Eugene Wigner": 1,
      "Yevgeny Konstantinovich  Zavoisky": 2,
      "Clyde Cowan, Jr": 2,
      "Frederick Reines": 2,
      "Otto Robert Frisch": 1,
      "Lise Meitner": 1,
      "Carl Wagner": 1,
      "Jabez Curry Street": 1,
      "Bruno Benedetto Rossi": 1,
      "Norbert Wiener": 1,
      "Claude Shannon": 1,
      "Giuseppe Paolo Stanislao Occhialini": 1,
      "George Dixon Rochester": 1,
      "Clifford Butler": 1,
      "Owen Chamberlain": 6,
      "Dorothy Crowfoot Hodgkin": 1,
      "Frederick Charles Frank": 1,
      "Erwin Wilhelm Müller": 1,
      "Frank Bowden": 1,
      "André Lallemand": 1,
      "Maria Goeppert Mayer": 2,
      "J. Hans D. Jensen": 2,
      "Pyotr Kapitsa": 1,
      "Cornelis Jacobus  Gorter": 1,
      "Chieng-Shiung Wu": 1,
      "Léon Lederman": 1,
    },
  },
  {
    year: 1960,
    nominees: {
      "Hans Albrecht Bethe": 2,
      "Nikolai Nikolaevic Bogoljubov": 3,
      "Léon Brillouin": 1,
      "Klaus Clusius": 1,
      "Martin Deutsch": 6,
      "Donald A. Glaser": 9,
      "Dorothy Crowfoot Hodgkin": 3,
      "Robert Hofstadter": 4,
      "Hendrik Christoffel  van de Hulst": 10,
      "Alfred Kastler": 5,
      "André Lallemand": 1,
      "Lev Landau": 3,
      "Louis Leprince-Ringuet": 1,
      "Willard Frank Libby": 3,
      "Sir Nevill Francis Mott": 1,
      "Erwin Wilhelm Müller": 3,
      "Louis Néel": 6,
      "Jan Oort": 10,
      "James Van Allen": 4,
      "Eugene Wigner": 2,
      "Yevgeny Konstantinovich  Zavoisky": 1,
      "Anatole Abragam": 1,
      "Albert  Overhauser": 1,
      "Jean Brossel": 1,
      "Clyde Cowan, Jr": 1,
      "Frederick Reines": 1,
      "Luis Walter Alvarez": 1,
      "J. Hans D. Jensen": 4,
      "Maria Goeppert Mayer": 4,
      "Pyotr Kapitsa": 1,
      "Ernst August Friedrich Ruska": 1,
      "Abraham Pais": 2,
      "Murray Gell-Mann": 2,
      "Kazuhiko Nishijima ": 1,
      "Richard P. Feynman": 6,
      "Julian Schwinger": 6,
      "Sin-Itiro Tomonaga": 1,
      "Hannes Alfvén": 2,
      "Bengt Edlén": 1,
      "Max Ferdinand Perutz": 2,
      "John  Kendrew": 2,
      "Charles H. Townes": 2,
      "Nicolay G. Basov": 2,
      "Aleksandr M. Prokhorov": 2,
      "Nicolaas Bloembergen": 1,
      "Maurice Goldhaber": 1,
      "Chieng-Shiung Wu": 1,
      "Bruno Benedetto Rossi": 1,
    },
  },
  {
    year: 1961,
    nominees: {
      "Hannes Alfvén": 1,
      "Sydney Chapman": 1,
      "Louis Gray": 1,
      "Walter Heinrich  Heitler": 1,
      "Robert Hofstadter": 3,
      "J. Hans D. Jensen": 1,
      "André Lallemand": 2,
      "Rudolf Mössbauer": 13,
      "Louis Néel": 3,
      "Lars Onsager": 4,
      "Sir Geoffrey  Ingram Taylor": 3,
      "Charles H. Townes": 3,
      "James Van Allen": 2,
      "Eugene Wigner": 1,
      "Yevgeny Konstantinovich  Zavoisky": 1,
      "Aage Niels Bohr": 2,
      "Ben Mottelson": 2,
      "Clyde Cowan, Jr": 3,
      "Frederick Reines": 3,
      "Max Ferdinand Perutz": 1,
      "John  Kendrew": 1,
      "Dorothy Crowfoot Hodgkin": 2,
      "Frederick Charles Frank": 1,
      "Otto Robert Frisch": 1,
      "Lise Meitner": 1,
      "John Hasbrouck  Van Vleck": 1,
      "Alfred Kastler": 1,
      "Bruno Benedetto Rossi": 1,
      "Giuseppe Paolo Stanislao Occhialini": 2,
      "George Dixon Rochester": 1,
      "Clifford Butler": 1,
      "Hendrik Christoffel  van de Hulst": 2,
      "Jan Oort": 2,
      "Christiaan Alexander (Lex) Muller": 2,
      "John Bardeen": 2,
      "Leon Cooper": 2,
      "John Robert Schrieffer": 2,
      "Murray Gell-Mann": 1,
      "Kazuhiko Nishijima ": 1,
      "Tadao Nakano": 1,
      "Ernst August Friedrich Ruska": 1,
      "Max Knoll": 1,
      "Ladislaus Laszlo Marton": 1,
      "Egon Orowan": 1,
      "Michael Polanyi": 1,
      "Frederick Seitz": 1,
      "Sir Nevill Francis Mott": 1,
    },
  },
  {
    year: 1962,
    nominees: {
      "Hannes Alfvén": 1,
      "Luis Walter Alvarez": 2,
      "Nikolay Vasilyevich Belov": 1,
      "Hans Albrecht Bethe": 9,
      "Satyendra Nath Bose": 2,
      "Klaus Clusius": 1,
      "Marian Danysz": 1,
      "Georges Destriau": 1,
      "Richard P. Feynman": 7,
      "Maurice Goldhaber": 1,
      "Walter Heinrich  Heitler": 1,
      "J. Hans D. Jensen": 7,
      "Alfred Kastler": 6,
      "André Lallemand": 2,
      "Lev Landau": 2,
      "Bogdan Maglich [Maglić]": 1,
      "Erwin Wilhelm Müller": 1,
      "Louis Néel": 2,
      "Wolfgang Kurt Hermann Panofsky": 1,
      "Bruno Benedetto Rossi": 6,
      "Reinhold Rüdenberg": 1,
      "Charles H. Townes": 14,
      "James Van Allen": 3,
      "Eugene Wigner": 4,
      "Yevgeny Konstantinovich  Zavoisky": 2,
      "Clyde Cowan, Jr": 1,
      "Frederick Reines": 1,
      "Robert Doll": 1,
      "Martin Näbauer": 1,
      "Maria Goeppert Mayer": 6,
      "George Dixon Rochester": 3,
      "Clifford Butler": 3,
      "Jabez Curry Street": 1,
      "Julian Schwinger": 1,
      "Francis Bitter": 1,
      "Sin-Itiro Tomonaga": 1,
      "Subramanyan Chandrasekhar": 1,
      "Joseph Weber": 1,
      "Theodore Maiman": 1,
      "Nicolaas Bloembergen": 1,
      "Ali Javan": 1,
      "Nicolay G. Basov": 1,
      "Aleksandr M. Prokhorov": 1,
    },
  },
  {
    year: 1963,
    nominees: {
      "Luis Walter Alvarez": 2,
      "Sydney Chapman": 1,
      "Klaus Clusius": 1,
      "Max Delbrück": 1,
      "Bengt Edlén": 1,
      "William Fairbank": 2,
      "Richard P. Feynman": 3,
      "Walter Friedrich": 1,
      "Otto Robert Frisch": 1,
      "Walter Heinrich  Heitler": 2,
      "J. Hans D. Jensen": 3,
      "Pascual Jordan": 1,
      "Alfred Kastler": 5,
      "Lev Landau": 5,
      "Léon Lederman": 3,
      "Erwin Wilhelm Müller": 2,
      "Louis Néel": 5,
      "Lars Onsager": 3,
      "Julian Schwinger": 2,
      "Sir Geoffrey  Ingram Taylor": 2,
      "Charles H. Townes": 18,
      "Eugene Wigner": 5,
      "Yevgeny Konstantinovich  Zavoisky": 1,
      "Anatole Abragam": 1,
      "Albert  Overhauser": 1,
      "Hannes Alfvén": 2,
      "Bruno Benedetto Rossi": 2,
      "Robert Doll": 1,
      "Sin-Itiro Tomonaga": 1,
      "Arthur Leonard Schawlow": 2,
      "James Gordon": 1,
      "Giuseppe Paolo Stanislao Occhialini": 5,
      "Joseph Weber": 1,
      "Nicolaas Bloembergen": 2,
      "Ali Javan": 1,
      "Nicolay G. Basov": 1,
      "Aleksandr M. Prokhorov": 1,
      "Maria Goeppert Mayer": 2,
      "Sir Martin Ryle": 1,
      "Sir Bernard Lovell": 1,
      "John Bardeen": 2,
      "Leon Cooper": 1,
      "John Robert Schrieffer": 1,
      "Herbert Fröhlich": 1,
      "Nikolai Nikolaevic Bogoljubov": 1,
      "Clifford Butler": 4,
      "George Dixon Rochester": 4,
      "Marcello Conversi": 5,
      "Ettore Pancini": 4,
      "Oreste Piccioni": 4,
      "Adriano Gozzini": 1,
      "Clyde Cowan, Jr": 1,
      "Frederick Reines": 1,
      "Melvin Schwartz": 2,
      "Jack Steinberger": 2,
    },
  },
  {
    year: 1964,
    nominees: {
      "Hannes Alfvén": 6,
      "Luis Walter Alvarez": 2,
      "Hans Albrecht Bethe": 3,
      "Bengt Edlén": 1,
      "Richard P. Feynman": 11,
      "Scott Ellsworth  Forbush": 1,
      "Herbert Fröhlich": 1,
      "Cornelis Jacobus  Gorter": 4,
      "Walter Heinrich  Heitler": 2,
      "Hendrik Christoffel  van de Hulst": 1,
      "Alfred Kastler": 6,
      "Lise Meitner": 1,
      "Philip Burton  Moon": 1,
      "Louis Néel": 3,
      "Giuseppe Paolo Stanislao Occhialini": 4,
      "Lars Onsager": 2,
      "Melvin Schwartz": 1,
      "Julian Schwinger": 6,
      "Charles H. Townes": 29,
      "Robert Jemison Van de Graaff": 1,
      "Chieng-Shiung Wu": 2,
      "Yevgeny Konstantinovich  Zavoisky": 5,
      "Vladimir Iosifovich Veksler": 1,
      "Edoardo Amaldi": 1,
      "Carl Friedrich  von Weizsäcker": 1,
      "Leopold Infeld": 1,
      "Freeman John  Dyson": 1,
      "Sin-Itiro Tomonaga": 2,
      "Murray Gell-Mann": 1,
      "Kazuhiko Nishijima ": 1,
      "Harold Jeffreys": 1,
      "Keith Edward  Bullen": 1,
      "Sir Bernard Lovell": 1,
      "Sir Martin Ryle": 1,
      "Ilya  Prigogine": 1,
      "H Schüler": 1,
      "Th Schmidt": 1,
      "Aleksandr M. Prokhorov": 9,
      "Nicolay G. Basov": 4,
      "Arthur Leonard Schawlow": 2,
      "André Lallemand": 1,
      "George Eugene Uhlenbeck": 6,
      "Samuel Abraham Goudsmit": 6,
      "Clifford Butler": 3,
      "George Dixon Rochester": 3,
      "Clyde Cowan, Jr": 1,
      "Frederick Reines": 1,
      "Margaret Burbidge": 1,
      "Geoffrey Ronald  Burbidge": 1,
      "William Fowler": 1,
      "Fred Hoyle": 1,
    },
  },
  {
    year: 1965,
    nominees: {
      "Hannes Alfvén": 6,
      "Luis Walter Alvarez": 4,
      "Hans Albrecht Bethe": 3,
      "Léon Brillouin": 2,
      "Richard P. Feynman": 13,
      "Murray Gell-Mann": 9,
      "S de Groot": 1,
      "Walter Heinrich  Heitler": 3,
      "Pyotr Kapitsa": 6,
      "Alfred Kastler": 16,
      "Nicholas Kemmer": 4,
      "Louis Néel": 11,
      "Susumu  Okubo ": 1,
      "Lars Onsager": 1,
      "Ernst August Friedrich Ruska": 1,
      "Walter Schottky": 3,
      "Charles H. Townes": 2,
      "James Van Allen": 5,
      "John Hasbrouck  Van Vleck": 4,
      'Victor Frederick "Viki" Weisskopf': 2,
      "Yevgeny Konstantinovich  Zavoisky": 1,
      "Carl Friedrich  von Weizsäcker": 1,
      "Aage Niels Bohr": 3,
      "Ben Mottelson": 3,
      "J Wheeler": 1,
      "George Dixon Rochester": 5,
      "C Butler": 4,
      "Giuseppe Paolo Stanislao Occhialini": 2,
      "Marcello Conversi": 1,
      "Adriano Gozzini": 1,
      "Yuval  Néeman ": 2,
      "Y Ohnuki": 1,
      "Maurice Françon": 1,
      "Julian Schwinger": 4,
      "Sin-Itiro Tomonaga": 1,
      "Marian Danysz": 5,
      "Bruno Benedetto Rossi": 1,
      "Chieng-Shiung Wu": 1,
      "Jean Brossel": 4,
      "Jerzy Pniewski": 4,
      "Robert  Hanbury Brown": 1,
      "Richard Twiss ": 1,
      "Fred Hoyle": 1,
      "Martin Schwarzchild": 1,
      "Friedrich Hund": 1,
      "Erich Hückel": 1,
      "Frederick Reines": 2,
      "Clyde Cowan, Jr": 2,
      "Sir Martin Ryle": 1,
      "Sir Bernard Lovell": 1,
      "George Eugene Uhlenbeck": 4,
      "Samuel Abraham Goudsmit": 4,
      "J Volger": 1,
      "John Bardeen": 1,
      "Leon Cooper": 1,
      "John Robert Schrieffer": 1,
      "Vladimir Iosifovich Veksler": 1,
      "Edwin McMillan": 1,
      "Nicholas Constantine  Christofilos ": 1,
      "Cornelis Jacobus  Gorter": 1,
      "Lise Meitner": 1,
    },
  },
  {
    year: 1966,
    nominees: {
      "Hannes Alfvén": 7,
      "Luis Walter Alvarez": 3,
      "John Bernal": 1,
      "Hans Albrecht Bethe": 3,
      "Bertram Neville  Brockhouse": 1,
      "Horace Richard  Crane": 5,
      "Dennis Gabor": 1,
      "Murray Gell-Mann": 26,
      "Walter Heinrich  Heitler": 2,
      "Erich Hückel": 1,
      "Harold Jeffreys": 1,
      "Pyotr Kapitsa": 2,
      "Alfred Kastler": 8,
      "Bernd Theodor  Matthias": 2,
      "Walther Meissner": 1,
      "Louis Néel": 17,
      "Lars Onsager": 8,
      "Bruno Benedetto Rossi": 3,
      "Abdus Salam": 3,
      "M Schmidt": 2,
      "Robert Jemison Van de Graaff": 1,
      "Yevgeny Konstantinovich  Zavoisky": 6,
      "Brebis Bleaney": 2,
      "Nikolai Nikolaevic Bogoljubov": 4,
      "Vladimir Aleksandrovich  Fok": 2,
      "Val Logsdon  Fitch": 3,
      "James Watson  Cronin": 2,
      "Melvin Schwartz": 1,
      "Kazuhiko Nishijima ": 1,
      "Samuel Abraham Goudsmit": 5,
      "George Eugene Uhlenbeck": 5,
      "Robert  Hanbury Brown": 1,
      "Richard Twiss ": 1,
      "Julian Schwinger": 1,
      "Jean Brossel": 1,
      "Sir Bernard Lovell": 4,
      "Sir Martin Ryle": 5,
      "Erwin Wilhelm Müller": 1,
      "Ernst August Friedrich Ruska": 1,
      "Jacques Friedel": 1,
      "John Hasbrouck  Van Vleck": 1,
      "Sir Alfred Brian  Pippard": 1,
      "David Shoenberg": 1,
      "Cornelis Jacobus  Gorter": 1,
      "Clyde Cowan, Jr": 1,
      "Frederick Reines": 1,
      "W Fowler": 1,
      "N Samios": 1,
      "John Bardeen": 3,
      "Leon Cooper": 3,
      "John Robert Schrieffer": 3,
      "Hendrik Christoffel  van de Hulst": 3,
      "George Dixon Rochester": 1,
      "C Butler": 1,
      "Louis Leprince-Ringuet": 1,
      "Yuval  Néeman ": 1,
      "Y Ohnuki": 1,
      "Herbert Fröhlich": 1,
      "Aage Niels Bohr": 1,
      "Ben Mottelson": 1,
      "J Keuffel": 1,
      "T Cranshaw": 1,
      "J de Beer": 1,
      "S Fukui": 1,
      "S Miyamoto": 1,
      "D Schneiderman": 1,
      "Robert Benjamin  Leighton": 1,
      "K Sloan": 1,
      "J Allen": 1,
      "B Murray": 1,
      "R Sharp": 1,
    },
  },
];

export const nomineesRaw = [
  {
    year: 1901,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Svante Arrhenius \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Svante Arrhenius \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Becquerel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n William Campbell \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n William Campbell \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Philipp Lenard \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Philipp Lenard \n Wilhelm Conrad Röntgen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Philipp Lenard \n Wilhelm Conrad Röntgen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Philipp Lenard \n Wilhelm Conrad Röntgen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Philipp Lenard \n Wilhelm Conrad Röntgen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Philipp Lenard \n Wilhelm Conrad Röntgen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gabriel Lippmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Guglielmo Marconi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Adolf Nordenskiöld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wilhelm Conrad Röntgen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wilhelm Conrad Röntgen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wilhelm Conrad Röntgen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Grenville Clark \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wilhelm Conrad Röntgen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wilhelm Conrad Röntgen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wilhelm Conrad Röntgen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wilhelm Conrad Röntgen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wilhelm Conrad Röntgen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wilhelm Conrad Röntgen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wilhelm Conrad Röntgen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wilhelm Conrad Röntgen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n William Thomson, Lord Kelvin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Johannes Diderik van der Waals \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pieter Zeeman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pieter Zeeman \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1902,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Svante Arrhenius \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Svante Arrhenius \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Svante Arrhenius \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Becquerel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Becquerel \n Marie Curie \n Pierre Curie \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lord Rayleigh \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Becquerel \n Marie Curie \n Pierre Curie \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n René Benoit \n Pierre Chappuis \n Charles Edouard Guillaume \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pierre Curie \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wilhelm Hittorf \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Kerr \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Kerr \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Philipp Lenard \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hendrik A. Lorentz \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hendrik A. Lorentz \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hendrik A. Lorentz \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hendrik A. Lorentz \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hendrik A. Lorentz \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hendrik A. Lorentz \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Guglielmo Marconi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Guglielmo Marconi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lord Rayleigh \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n J.J. Thomson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n William Thomson, Lord Kelvin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pieter Zeeman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hendrik A. Lorentz \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hendrik A. Lorentz \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hendrik A. Lorentz \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hendrik A. Lorentz \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1903,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Svante Arrhenius \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Svante Arrhenius \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Svante Arrhenius \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Svante Arrhenius \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Svante Arrhenius \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Svante Arrhenius \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Svante Arrhenius \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Becquerel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Becquerel \n Marie Curie \n Pierre Curie \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Becquerel \n Pierre Curie \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Becquerel \n Pierre Curie \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Becquerel \n Pierre Curie \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Becquerel \n Pierre Curie \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n René Benoit \n Pierre Chappuis \n Charles Edouard Guillaume \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ludwig Boltzmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wilhelm Hittorf \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Kerr \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gustave Le Bon \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Philipp Lenard \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gabriel Lippmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Guglielmo Marconi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Guglielmo Marconi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Guglielmo Marconi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Guglielmo Marconi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Guglielmo Marconi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Guglielmo Marconi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lord Rayleigh \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lord Rayleigh \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lord Rayleigh \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lord Rayleigh \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lord Rayleigh \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lord Rayleigh \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lord Rayleigh \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n William Thomson, Lord Kelvin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n William Thomson, Lord Kelvin \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1904,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Philipp Lenard \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernst Abbe \n Wilhelm Hittorf \n Friedrich Kohlrausch \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n René Benoit \n Pierre Chappuis \n Charles Edouard Guillaume \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Edouard Branly \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n J.J. Thomson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir James Dewar \n Walter Kaufmann \n Karol Olszewski \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Julius Elster \n Hans Geitel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Philipp Lenard \n Oliver Heaviside \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lord Rayleigh \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Friedrich Kohlrausch \n Philipp Lenard \n Arthur Schuster \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gabriel Lippmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gabriel Lippmann \n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gabriel Lippmann \n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert A. Michelson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lord Rayleigh \n Sir William Ramsay \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lord Rayleigh \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lord Rayleigh \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lord Rayleigh \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lord Rayleigh \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lord Rayleigh \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lord Rayleigh \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lord Rayleigh \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lord Rayleigh \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lord Rayleigh \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1905,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Ernst Abbe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n René Blondlot \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ludwig Boltzmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ferdinand Braun \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir James Dewar \n Petr Lebedev \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Julius Elster \n Hans Geitel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Oliver Heaviside \n Philipp Lenard \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Heinrich Kayser \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Kohlrausch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Philipp Lenard \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gabriel Lippmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gabriel Lippmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gabriel Lippmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n J.J. Thomson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n J.J. Thomson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n J.J. Thomson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n J.J. Thomson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n J.J. Thomson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n J.J. Thomson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n J.J. Thomson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n J.J. Thomson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n J.J. Thomson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n J.J. Thomson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n J.J. Thomson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n J.J. Thomson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n J.J. Thomson \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1906,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Ludwig Boltzmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ludwig Boltzmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ludwig Boltzmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ferdinand Braun \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ferdinand Braun \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Julius von Hann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Kohlrausch \n J.J. Thomson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gabriel Lippmann \n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gabriel Lippmann \n Henri Poincaré \n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n Gabriel Lippmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n J.J. Thomson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n J.J. Thomson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n J.J. Thomson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n J.J. Thomson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n J.J. Thomson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n J.J. Thomson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n J.J. Thomson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n William Thomson, Lord Kelvin \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1907,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Ferdinand Braun \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n William Crookes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pierre Duhem \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Albert A. Michelson \n Augusto Righi \n Ernest Lord Rutherford \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Julius Elster \n Hans Geitel \n William Thomson, Lord Kelvin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernst Hagen \n Heinrich Rubens \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Oliver Heaviside \n Max Planck \n Wilhelm Wien \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Kohlrausch \n Ernest Lord Rutherford \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gabriel Lippmann \n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gabriel Lippmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gabriel Lippmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gabriel Lippmann \n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gabriel Lippmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gabriel Lippmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gabriel Lippmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert A. Michelson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert A. Michelson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lord Rutherford \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lord Rutherford \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lord Rutherford \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lord Rutherford \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lord Rutherford \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n William Thomson, Lord Kelvin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Johannes Diderik van der Waals \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Johannes Diderik van der Waals \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Johannes Diderik van der Waals \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Johannes Diderik van der Waals \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Johannes Diderik van der Waals \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1908,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Emile Amagat \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Guglielmo Marconi \n René Benoit \n Charles Edouard Guillaume \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n René Benoit \n Charles Edouard Guillaume \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n René Benoit \n Charles Edouard Guillaume \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Julius Elster \n Hans Geitel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Julius Elster \n Hans Geitel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Kohlrausch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gustaf de Laval \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gabriel Lippmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gabriel Lippmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gabriel Lippmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gabriel Lippmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gabriel Lippmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Guglielmo Marconi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n Wilhelm Wien \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lord Rutherford \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lord Rutherford \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lord Rutherford \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lord Rutherford \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lord Rutherford \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1909,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee:
          "\n Edouard Branly \n Guglielmo Marconi \n Valdemar Poulsen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ferdinand Braun \n Guglielmo Marconi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Julius Elster \n Hans Geitel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wilbur Wright \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Farman \n Gabriel Voisin \n Orville Wright \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wilbur Wright \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Farman \n Gabriel Voisin \n Orville Wright \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wilbur Wright \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Farman \n Gabriel Voisin \n Orville Wright \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wilbur Wright \n Max Planck \n Wilhelm Wien \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Farman \n Gabriel Voisin \n Orville Wright \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wilbur Wright \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Farman \n Gabriel Voisin \n Orville Wright \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wilbur Wright \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Farman \n Gabriel Voisin \n Orville Wright \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wilbur Wright \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Farman \n Gabriel Voisin \n Orville Wright \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wilbur Wright \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Farman \n Gabriel Voisin \n Orville Wright \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Hale \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Hale \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Hale \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Hale \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Hale \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Hale \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Hale \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Oliver Heaviside \n John Poynting \n Wilhelm Wien \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Heike Kamerlingh Onnes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Heike Kamerlingh Onnes \n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Heike Kamerlingh Onnes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Heike Kamerlingh Onnes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Heike Kamerlingh Onnes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Heike Kamerlingh Onnes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Kohlrausch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Kohlrausch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Kohlrausch \n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n Johannes Diderik van der Waals \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Heinrich Rubens \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Heinrich Rubens \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Johannes Diderik van der Waals \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1910,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Henri Abraham \n Paul Langevin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Knut Ångström \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n René Benoit \n Charles Edouard Guillaume \n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n William Crookes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Julius Elster \n Hans Geitel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Julius Elster \n Hans Geitel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n Julius Elster \n Hans Geitel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Julius Elster \n Hans Geitel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Allvar Gullstrand \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Heike Kamerlingh Onnes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Lummer \n Max Planck \n Wilhelm Wien \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Johannes Diderik van der Waals \n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1911,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n William Crookes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Julius Elster \n Hans Geitel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Julius Elster \n Hans Geitel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Julius Elster \n Hans Geitel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Loránd Eötvös \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Loránd Eötvös \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Loránd Eötvös \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles Fabry \n Eugen Goldstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Allvar Gullstrand \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Allvar Gullstrand \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Julius von Hann \n Ernst Mach Jr \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Oliver Heaviside \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Lummer \n Max Planck \n Wilhelm Wien \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernst Mach Jr \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walther Nernst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Poynting \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1912,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n René Benoit \n Charles Edouard Guillaume \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gustaf Dalén \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir James Dewar \n Heike Kamerlingh Onnes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n Hendrik A. Lorentz \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n Hendrik A. Lorentz \n Petr Lebedev \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Hadfield \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Hadfield \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Oliver Heaviside \n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Heike Kamerlingh Onnes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Heike Kamerlingh Onnes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Heike Kamerlingh Onnes \n Ernst Mach Jr \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Heike Kamerlingh Onnes \n Ernst Mach Jr \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Heike Kamerlingh Onnes \n Walther Nernst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernst Mach Jr \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walther Nernst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n J.J. Thomson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Poincaré \n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Poynting \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Poynting \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Poynting \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1913,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Emile Amagat \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Emile Amagat \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Emile Amagat \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Emile Amagat \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Emile Amagat \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Emile Amagat \n Henri Deslandres \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n René Benoit \n Charles Edouard Guillaume \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hugh Callendar \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir James Dewar \n Heike Kamerlingh Onnes \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Sir James Dewar \n Heike Kamerlingh Onnes \n Carl von Linde \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n Theodor Svedberg \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n Hendrik A. Lorentz \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hermann Frahm \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Eugen Goldstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Hadfield \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Hadfield \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Hale \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Julius von Hann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Heike Kamerlingh Onnes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Heike Kamerlingh Onnes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Heike Kamerlingh Onnes \n Karol Olszewski \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Heike Kamerlingh Onnes \n Karol Olszewski \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Heike Kamerlingh Onnes \n Karol Olszewski \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Lehmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Karol Olszewski \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Adolf Slaby \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Orville Wright \n Wilbur Wright \n Ferdinand von Zeppelin \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1914,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Emile Amagat \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Emile Amagat \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n René Benoit \n Charles Edouard Guillaume \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n William Bragg \n Max von Laue \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n William Crookes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Loránd Eötvös \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Loránd Eötvös \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Loránd Eötvös \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n Charles Fabry \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n Charles Fabry \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n Georges Gouy \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Hale \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arthur Schuster \n George Hale \n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Oliver Heaviside \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Martin Knudsen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Martin Knudsen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max von Laue \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henry Le Châtelier \n Walther Nernst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Lehmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernst Mach Jr \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n Walther Nernst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Johannes Stark \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Wood \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Wood \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1915,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Carl Störmer \n Kristian Birkeland \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n William Bragg \n Max von Laue \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n William Bragg \n Lawrence Bragg \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Deslandres \n George Hale \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n William Bragg \n Charles Darwin \n Henry Moseley \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n William Bragg \n Lawrence Bragg \n Max von Laue \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Thomas Edison \n George Hale \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n G Casalis \n N Pavia \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aimé Cotton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Hale \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max von Laue \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Lehmann \n Walther Nernst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n C.T.R. Wilson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Wood \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1916,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Georges Gouy \n Henri Mouton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aimé Cotton \n Pierre Duhem \n Charles Fabry \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Paul Langevin \n Jean Baptiste Perrin \n Pierre Weiss \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n René Benoit \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Abraham \n Alexandre Dufour \n Gustav Ferrié \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Kristian Birkeland \n Carl Störmer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Kristian Birkeland \n Carl Störmer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Petrus Debye \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Deslandres \n George Hale \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Georges Gouy \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Hale \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Hale \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Hale \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Heinrich Kayser \n Carl Runge \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Martin Knudsen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Martin Knudsen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Irving Langmuir \n Robert A. Millikan \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Lehmann \n Walther Nernst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Peder Pedersen \n Valdemar Poulsen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Peder Pedersen \n Valdemar Poulsen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n Johannes Stark \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Valdemar Poulsen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Johannes Stark \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Johannes Stark \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Johannes Stark \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Johannes Stark \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1917,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Kristian Birkeland \n Carl Störmer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Niels Bohr \n Martin Knudsen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Petrus Debye \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Loránd Eötvös \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Loránd Eötvös \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n Charles Fabry \n Georges Gouy \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Georges Gouy \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Hale \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Hale \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Hale \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Hale \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Hale \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Hale \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Heinrich Kayser \n Carl Runge \n Johannes Rydberg \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Lehmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walther Nernst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walther Nernst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Peder Pedersen \n Valdemar Poulsen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Peder Pedersen \n Valdemar Poulsen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Valdemar Poulsen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arthur Schuster \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Johannes Stark \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Johannes Stark \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Johannes Stark \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1918,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Charles Glover Barkla \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Niels Bohr \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Pérot \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hugh Callendar \n Theodore Lyman \n Robert A. Millikan \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n William Crookes \n C.T.R. Wilson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n Hendrik A. Lorentz \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n Friedrich Paschen \n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n Albert Einstein \n Hendrik A. Lorentz \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles Fabry \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles Fabry \n Robert A. Millikan \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Georges Gouy \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Martin Knudsen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Martin Knudsen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Lehmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Carl von Linde \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Peder Pedersen \n Valdemar Poulsen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Peder Pedersen \n Valdemar Poulsen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Fernando Sanford \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Johannes Stark \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1919,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Niels Bohr \n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Niels Bohr \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Niels Bohr \n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n Hendrik A. Lorentz \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n Friedrich Paschen \n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Georges Gouy \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Martin Knudsen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Lehmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert A. Millikan \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert A. Millikan \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert A. Millikan \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert A. Millikan \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert A. Millikan \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert A. Millikan \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Peder Pedersen \n Valdemar Poulsen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Peder Pedersen \n Valdemar Poulsen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n Theodor Svedberg \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Planck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Fernando Sanford \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Johannes Stark \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1920,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Walter Adams \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Carl Barus \n Robert A. Millikan \n Robert Wood \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Niels Bohr \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Niels Bohr \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Niels Bohr \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aimé Cotton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles Edouard Guillaume \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Martin Knudsen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Lehmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Auguste Lumière \n Louis Lumière \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert A. Millikan \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Peder Pedersen \n Valdemar Poulsen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Peder Pedersen \n Valdemar Poulsen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Valdemar Poulsen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Augusto Righi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Fernando Sanford \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1921,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Carl Barus \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Niels Bohr \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hugh Callendar \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Petrus Debye \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n Jean Baptiste Perrin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernst Gehrcke \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Martin Knudsen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Paul Langevin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Lehmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Auguste Lumière \n Louis Lumière \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert A. Millikan \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walther Nernst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Peder Pedersen \n Valdemar Poulsen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Peder Pedersen \n Valdemar Poulsen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Valdemar Poulsen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pierre Weiss \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1922,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n George Hale \n Robert A. Millikan \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles Abbot \n Francis Aston \n William Coolidge \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Francis Aston \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Francis Aston \n Robert A. Millikan \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Niels Bohr \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Niels Bohr \n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Niels Bohr \n Petrus Debye \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Niels Bohr \n Albert Einstein \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Niels Bohr \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Niels Bohr \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n Niels Bohr \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Niels Bohr \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Niels Bohr \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Niels Bohr \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Niels Bohr \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aimé Cotton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Paul Langevin \n Jean Baptiste Perrin \n Pierre Weiss \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n Charles Fabry \n Georges Gouy \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n George Hale \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n Jean Baptiste Perrin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n Friedrich Paschen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles Fabry \n Robert A. Millikan \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Leo Graetz \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Hale \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Hale \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Paul Langevin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Lehmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Auguste Lumière \n Louis Lumière \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert A. Millikan \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Peder Pedersen \n Valdemar Poulsen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Peder Pedersen \n Valdemar Poulsen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lord Rutherford \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1923,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Vilhelm Bjerknes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henri Deslandres \n George Hale \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Franck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Gaede \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernst Gehrcke \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Hale \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n Robert A. Millikan \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Peder Pedersen \n Valdemar Poulsen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Peder Pedersen \n Valdemar Poulsen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lord Rutherford \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Einstein \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1924,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Vilhelm Bjerknes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Petrus Debye \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Petrus Debye \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Franck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Franck \n Gustav Hertz \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Franck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Franck \n Friedrich Paschen \n Robert Wood \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Gaede \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernst Gehrcke \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Leo Graetz \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Hale \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Philipp Lenard \n Friedrich Paschen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Auguste Lumière \n Louis Lumière \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n Owen Willans Richardson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jules Richard \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lord Rutherford \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n William Shaw \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir John Townsend \n Pierre Weiss \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n C.T.R. Wilson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n C.T.R. Wilson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n C.T.R. Wilson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Wood \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Wood \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Wood \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1925,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Louis de Broglie \n Maurice de Broglie \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arthur H. Compton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aimé Cotton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Petrus Debye \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Petrus Debye \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Hale \n Charles Fabry \n Robert Wood \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Fleming \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Fleming \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Fleming \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Franck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Franck \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Gaede \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n Wolfgang Gaede \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Gerlach \n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Paul Langevin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Philipp Lenard \n Friedrich Paschen \n Robert Wood \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n Owen Willans Richardson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arthur Schuster \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Manne Siegbahn \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Manne Siegbahn \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Manne Siegbahn \n C.T.R. Wilson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Carl Störmer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Siegmund Strauss \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pierre Weiss \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Wood \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1926,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Vilhelm Bjerknes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Vilhelm Bjerknes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis de Broglie \n Maurice de Broglie \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arthur H. Compton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arthur H. Compton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arthur H. Compton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arthur H. Compton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arthur H. Compton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Petrus Debye \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Petrus Debye \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Franck \n Owen Willans Richardson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Franck \n Gustav Hertz \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Franck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n Robert Wood \n James Franck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Franck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Franck \n Friedrich Paschen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Eugen Goldstein \n Werner Kohlhörster \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Carl von Linde \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Carl von Linde \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Theodore Lyman \n Robert A. Millikan \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Dayton Miller \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert A. Millikan \n Friedrich Paschen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n Owen Willans Richardson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Baptiste Perrin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wilhelm Wien \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n C.T.R. Wilson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Wood \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1927,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Arthur H. Compton \n Walter Gerlach \n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arthur H. Compton \n Walter Gerlach \n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arthur H. Compton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arthur H. Compton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arthur H. Compton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arthur H. Compton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arthur H. Compton \n Carl Ramsauer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arthur H. Compton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arthur H. Compton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n William Coolidge \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aimé Cotton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aimé Cotton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aimé Cotton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aimé Cotton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Petrus Debye \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Fleming \n Owen Willans Richardson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Fleming \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Fleming \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Fleming \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Gerlach \n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Victor Kaplan \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Paul Langevin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Auguste Lumière \n Louis Lumière \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n William Shaw \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Carl Störmer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wilhelm Wien \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n C.T.R. Wilson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n C.T.R. Wilson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n C.T.R. Wilson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Wood \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1928,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Tor Bergeron \n Jacob Bjerknes \n Halvor Solberg \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis de Broglie \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Maurice de Broglie \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Maurice de Broglie \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aimé Cotton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Petrus Debye \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Erwin Schrödinger \n Petrus Debye \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Fleming \n Owen Willans Richardson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernst Gehrcke \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Gerlach \n Otto Stern \n Robert Wood \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Gerlach \n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Gerlach \n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Werner Heisenberg \n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Paul Langevin \n Pierre Weiss \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Paul Langevin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Paul Langevin \n Pierre Weiss \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Owen Willans Richardson \n Irving Langmuir \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ludwig Prandtl \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ludwig Prandtl \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ludwig Prandtl \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Owen Willans Richardson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pierre Weiss \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pierre Weiss \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Wood \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Wood \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1929,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Edwin Armstrong \n Alexander Meissner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Edwin Armstrong \n Alexander Meissner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Vilhelm Bjerknes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n Clinton Davisson \n Lester Germer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis de Broglie \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis de Broglie \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Gerlach \n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Erwin Schrödinger \n Louis de Broglie \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis de Broglie \n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pierre Weiss \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis de Broglie \n Clinton Davisson \n Paul Langevin \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Louis de Broglie \n Erwin Schrödinger \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Gerlach \n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Louis de Broglie \n Erwin Schrödinger \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Gerlach \n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Louis de Broglie \n Erwin Schrödinger \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Gerlach \n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Louis de Broglie \n Erwin Schrödinger \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n Walter Gerlach \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis de Broglie \n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis de Broglie \n Paul A.M. Dirac \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Werner Heisenberg \n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis de Broglie \n Clinton Davisson \n Emil Warburg \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n Robert Wood \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Cabannes \n Sir Chandrasekhara Venkata Raman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aimé Cotton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clinton Davisson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clinton Davisson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Petrus Debye \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Petrus Debye \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Petrus Debye \n Pierre Weiss \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Gerlach \n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Gaede \n Wilhelm Wien \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Gerlach \n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Werner Heisenberg \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Werner Heisenberg \n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Owen Willans Richardson \n David Hilbert \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Paul Langevin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Paul Langevin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Paul Langevin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Paul Langevin \n Pierre Weiss \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Paul Langevin \n Pierre Weiss \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Wood \n Sir Chandrasekhara Venkata Raman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Owen Willans Richardson \n Irving Langmuir \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Guglielmo Marconi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Michael Pupin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Michael Pupin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Owen Willans Richardson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Owen Willans Richardson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Owen Willans Richardson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Erwin Schrödinger \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Wood \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1930,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Max Born \n Werner Heisenberg \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n Clinton Davisson \n Ira Bowen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henry Norris Russell \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n Clinton Davisson \n Ira Bowen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aimé Cotton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aimé Cotton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clinton Davisson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Petrus Debye \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Petrus Debye \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gustav Ferrié \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Gerlach \n Otto Stern \n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Werner Heisenberg \n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Werner Heisenberg \n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Chandrasekhara Venkata Raman \n Werner Heisenberg \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Werner Heisenberg \n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Werner Heisenberg \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n David Hilbert \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Grigoriy Landsberg \n Leonid Mandelshtam (Mandelstam) \n Sir Chandrasekhara Venkata Raman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Paul Langevin \n Pierre Weiss \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Leonid Mandelshtam (Mandelstam) \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Wood \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n Friedrich Paschen \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Chandrasekhara Venkata Raman \n Robert Wood \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Wood \n Sir Chandrasekhara Venkata Raman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Chandrasekhara Venkata Raman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Chandrasekhara Venkata Raman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Chandrasekhara Venkata Raman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Chandrasekhara Venkata Raman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Chandrasekhara Venkata Raman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Chandrasekhara Venkata Raman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Meghnad Saha \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Meghnad Saha \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Erwin Schrödinger \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pierre Weiss \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pierre Weiss \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Wood \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1931,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Jean Cabannes \n Aimé Cotton \n Georges Claude \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Georges Claude \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Georges Claude \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aimé Cotton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clinton Davisson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clinton Davisson \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Clinton Davisson \n Lester Germer \n George Paget Thomson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Petrus Debye \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Petrus Debye \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Petrus Debye \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Wood \n Petrus Debye \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n Friedrich Paschen \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gustav Ferrié \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Gerlach \n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Werner Heisenberg \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Werner Heisenberg \n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Victor F. Hess \n Carl Ramsauer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n David Hilbert \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Paul Langevin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Paul Langevin \n Pierre Weiss \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lord Rutherford \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1932,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Aimé Cotton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aimé Cotton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clinton Davisson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clinton Davisson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clinton Davisson \n Lester Germer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clinton Davisson \n Lester Germer \n Petrus Debye \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Clinton Davisson \n Lester Germer \n George Paget Thomson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Petrus Debye \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Petrus Debye \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Petrus Debye \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Petrus Debye \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Petrus Debye \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Wood \n Petrus Debye \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n Friedrich Paschen \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Arthur Eddington \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles Fabry \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles Fabry \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n Walter Gerlach \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Gerlach \n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Werner Heisenberg \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Werner Heisenberg \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Werner Heisenberg \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Werner Heisenberg \n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Werner Heisenberg \n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Werner Heisenberg \n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Werner Heisenberg \n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n David Hilbert \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Kotaro Honda \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Kotaro Honda \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Kotaro Honda \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Auguste Piccard \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lord Rutherford \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pierre Weiss \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1933,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aimé Cotton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clinton Davisson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clinton Davisson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clinton Davisson \n Lester Germer \n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Clinton Davisson \n George Paget Thomson \n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Petrus Debye \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Werner Heisenberg \n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Wood \n Petrus Debye \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n Friedrich Paschen \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Werner Heisenberg \n Erwin Schrödinger \n Paul A.M. Dirac \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Paul A.M. Dirac \n Werner Heisenberg \n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Paul Langevin \n Robert Wood \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Arthur Eddington \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Werner Heisenberg \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Werner Heisenberg \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Werner Heisenberg \n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Werner Heisenberg \n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Werner Heisenberg \n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Werner Heisenberg \n Wolfgang Pauli \n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n Werner Heisenberg \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Victor F. Hess \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n David Hilbert \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n David Hilbert \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Paul Langevin \n Pierre Weiss \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Irving Langmuir \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Guglielmo Marconi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Paschen \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Auguste Piccard \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lord Rutherford \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n Erwin Schrödinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n René Thiry \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1934,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Carl D. Anderson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Carl D. Anderson \n Irène Joliot-Curie \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Carl D. Anderson \n Irène Joliot-Curie \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Carl D. Anderson \n Irène Joliot-Curie \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Irène Joliot-Curie \n Arnold Sommerfeld \n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Chadwick \n Walter Gerlach \n Frédéric Joliot \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clinton Davisson \n Lester Germer \n Carl D. Anderson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Irène Joliot-Curie \n Arnold Sommerfeld \n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Chadwick \n Walter Gerlach \n Frédéric Joliot \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clinton Davisson \n Lester Germer \n Carl D. Anderson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Irène Joliot-Curie \n Arnold Sommerfeld \n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Chadwick \n Walter Gerlach \n Frédéric Joliot \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clinton Davisson \n Lester Germer \n Carl D. Anderson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Irène Joliot-Curie \n Arnold Sommerfeld \n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Chadwick \n Walter Gerlach \n Frédéric Joliot \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clinton Davisson \n Lester Germer \n Carl D. Anderson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clinton Davisson \n Lester Germer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Gerlach \n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Pauli \n Max Born \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aimé Cotton \n Pierre Weiss \n Paul Langevin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walther Bothe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ira Bowen \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Ferdinand Brickwedde \n George Murphy \n Harold Clayton Urey \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Chadwick \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n James Chadwick \n Frédéric Joliot \n Irène Joliot-Curie \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n James Chadwick \n Frédéric Joliot \n Irène Joliot-Curie \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aimé Cotton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aimé Cotton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aimé Cotton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aimé Cotton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aimé Cotton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clinton Davisson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clinton Davisson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clinton Davisson \n Lester Germer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clinton Davisson \n Lester Germer \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Clinton Davisson \n Lester Germer \n George Paget Thomson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Arthur Eddington \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gustaf Elmen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Fleming \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Hale \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Carl Störmer \n Lars Vegard \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Giancarlo Vallauri \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1935,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Carl D. Anderson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Chadwick \n Carl D. Anderson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Edward V. Appleton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Edward V. Appleton \n Arthur Kennelly \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Edward V. Appleton \n Arthur Kennelly \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Chadwick \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Chadwick \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Chadwick \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Chadwick \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Chadwick \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Chadwick \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Chadwick \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n James Chadwick \n Frédéric Joliot \n Irène Joliot-Curie \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n James Chadwick \n Frédéric Joliot \n Irène Joliot-Curie \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Frédéric Joliot \n Irène Joliot-Curie \n James Chadwick \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n James Chadwick \n Frédéric Joliot \n Irène Joliot-Curie \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Chadwick \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n William Coolidge \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Felix Ehrenhaft \n Hugo Dingler \n Alexander Gurwitsch [Gurvitsch] \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Arthur Eddington \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Abraham Esau \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Frédéric Joliot \n Irène Joliot-Curie \n Enrico Fermi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Gaede \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Geiger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Samuel Abraham Goudsmit \n Wolfgang Pauli \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wander de Haas \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n Frédéric Joliot \n Irène Joliot-Curie \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Paul Langevin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John McLennan \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Pauli \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Pauli \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Pauli \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lord Rutherford \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Giancarlo Vallauri \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Fritz Zwicky \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1936,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Carl D. Anderson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Carl D. Anderson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Carl D. Anderson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Carl D. Anderson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Carl D. Anderson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Carl D. Anderson \n Patrick M.S. Blackett \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Carl D. Anderson \n Patrick M.S. Blackett \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Carl D. Anderson \n Patrick M.S. Blackett \n Giuseppe Paolo Stanislao Occhialini \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Carl D. Anderson \n Jacob Clay \n Victor F. Hess \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jacob Bjerknes \n Vilhelm Bjerknes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Vilhelm Bjerknes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clinton Davisson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clinton Davisson \n Lester Germer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clinton Davisson \n Lester Germer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clinton Davisson \n Lester Germer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clinton Davisson \n Lester Germer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n Petrus Debye \n Enrico Fermi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Arthur Eddington \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Gaede \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Gerlach \n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wander de Haas \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Victor F. Hess \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pierre Weiss \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pierre Weiss \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1937,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Halvor Solberg \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Vilhelm Bjerknes \n Tor Bergeron \n Jacob Bjerknes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Halvor Solberg \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Vilhelm Bjerknes \n Tor Bergeron \n Jacob Bjerknes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Halvor Solberg \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Tor Bergeron \n Jacob Bjerknes \n Vilhelm Bjerknes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Cockcroft \n Ernest T.S. Walton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Cockcroft \n Ernest T.S. Walton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clinton Davisson \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Clinton Davisson \n Lester Germer \n George Paget Thomson \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Clinton Davisson \n Lester Germer \n George Paget Thomson \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Clinton Davisson \n Lester Germer \n George Paget Thomson \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Clinton Davisson \n Lester Germer \n George Paget Thomson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n Walter Gerlach \n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Pauli \n Clinton Davisson \n Lester Germer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n Alfred Loomis \n Robert Wood \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n Walter Gerlach \n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Pauli \n Clinton Davisson \n Lester Germer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n Alfred Loomis \n Robert Wood \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n Walter Gerlach \n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Pauli \n Clinton Davisson \n Lester Germer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n Alfred Loomis \n Robert Wood \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n Walter Gerlach \n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Pauli \n Clinton Davisson \n Lester Germer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n Alfred Loomis \n Robert Wood \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n Otto Hahn \n Lise Meitner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n Robert Wood \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Loomis \n Robert Wood \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Gaede \n Martin Knudsen \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Loomis \n Robert Wood \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Gaede \n Martin Knudsen \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Loomis \n Robert Wood \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Gaede \n Martin Knudsen \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Geiger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wander de Haas \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wander de Haas \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Hahn \n Lise Meitner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gilles Holst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Pauli \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Paul Langevin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ludwig Prandtl \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lord Rutherford \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Meghnad Saha \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Geoffrey  Ingram Taylor \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Nikola Tesla \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pierre Weiss \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1938,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Vilhelm Bjerknes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wander de Haas \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lawrence \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lawrence \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n Ernest Lawrence \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Erich Regener \n Wolfgang Pauli \n Enrico Fermi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n Ernest Lawrence \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Cockcroft \n Ernest T.S. Walton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Halvor Solberg \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Vilhelm Bjerknes \n Tor Bergeron \n Jacob Bjerknes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Halvor Solberg \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Vilhelm Bjerknes \n Tor Bergeron \n Jacob Bjerknes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Halvor Solberg \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Vilhelm Bjerknes \n Tor Bergeron \n Jacob Bjerknes \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1939,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Edward V. Appleton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Edward V. Appleton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Edward V. Appleton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Patrick M.S. Blackett \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Born \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ira Bowen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Maurice de Broglie \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Chadwick \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wander de Haas \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lawrence \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lawrence \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lawrence \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lawrence \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lawrence \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lawrence \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lawrence \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lawrence \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lawrence \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lawrence \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lawrence \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Isidor Isaac Rabi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Meghnad Saha \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Cockcroft \n Ernest T.S. Walton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Halvor Solberg \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Vilhelm Bjerknes \n Tor Bergeron \n Jacob Bjerknes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Halvor Solberg \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Vilhelm Bjerknes \n Tor Bergeron \n Jacob Bjerknes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Petrus Debye \n Paul Scherrer \n Albert Hull \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1940,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Vilhelm Bjerknes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Vilhelm Bjerknes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Vilhelm Bjerknes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Vilhelm Bjerknes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Patrick M.S. Blackett \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wander de Haas \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jaroslav Heyrovský \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jaroslav Heyrovský \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jaroslav Heyrovský \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jaroslav Heyrovský \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jaroslav Heyrovský \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Irving Langmuir \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Pauli \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hideki Yukawa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n Wolfgang Pauli \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n Ernest Lawrence \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n (Arthur) Neville Chamberlain \n Henri Bouasse \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernest Lawrence \n Meghnad Saha \n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Hahn \n Lise Meitner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n Isidor Isaac Rabi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n Isidor Isaac Rabi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n Isidor Isaac Rabi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n Isidor Isaac Rabi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n Isidor Isaac Rabi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n Isidor Isaac Rabi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n Isidor Isaac Rabi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n Isidor Isaac Rabi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n Isidor Isaac Rabi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wander de Haas \n William Giauque \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n Otto Hahn \n Lise Meitner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n Walter Gerlach \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wander de Haas \n Willem Keesom \n Wolfgang Pauli \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hideki Yukawa \n Otto Hahn \n Lise Meitner \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1941,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Edward V. Appleton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pierre Auger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wander de Haas \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Hahn \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Isidor Isaac Rabi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Giancarlo Vallauri \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Giancarlo Vallauri \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hideki Yukawa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n Wolfgang Pauli \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Hahn \n Fritz Strassmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Hahn \n Lise Meitner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n Isidor Isaac Rabi \n Seth Neddermeyer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Carl D. Anderson \n Seth Neddermeyer \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1942,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Edward V. Appleton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Edward V. Appleton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Vilhelm Bjerknes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Vilhelm Bjerknes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Patrick M.S. Blackett \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wander de Haas \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wander de Haas \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wander de Haas \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George de Hevesy \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Pauli \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Isidor Isaac Rabi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Isidor Isaac Rabi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Isidor Isaac Rabi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Wood \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Wood \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Seth Neddermeyer \n Carl D. Anderson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Seth Neddermeyer \n Carl D. Anderson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir John Townsend \n Wander de Haas \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1943,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Edward V. Appleton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Edward V. Appleton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Cabannes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Cockcroft \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Hahn \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Donald William Kerst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Santiago Mayolo \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Pauli \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Isidor Isaac Rabi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Isidor Isaac Rabi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Isidor Isaac Rabi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hideki Yukawa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wander de Haas \n Willem Keesom \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n Wolfgang Pauli \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n Isidor Isaac Rabi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Gamow \n Donald William Kerst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n Otto Stern \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Hahn \n Lise Meitner \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1944,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Edward V. Appleton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Edward V. Appleton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Vilhelm Bjerknes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Vilhelm Bjerknes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Vilhelm Bjerknes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Patrick M.S. Blackett \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Patrick M.S. Blackett \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aimé Cotton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Arthur Eddington \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir John Townsend \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hideki Yukawa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Willem Keesom \n Frits Zernike \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Seth Neddermeyer \n Carl D. Anderson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n Wolfgang Pauli \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Stern \n Walter Gerlach \n Isidor Isaac Rabi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hendrik Dorgelo \n Christiaan van Geel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gilles Holst \n Frans Penning \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1945,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Vilhelm Bjerknes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Patrick M.S. Blackett \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Patrick M.S. Blackett \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Donald William Kerst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bernard Lyot \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Seth Neddermeyer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Pauli \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Pauli \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Isidor Isaac Rabi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Geoffrey  Ingram Taylor \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hideki Yukawa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Carl D. Anderson \n Seth Neddermeyer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Cockcroft \n Ernest T.S. Walton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Hahn \n Fritz Strassmann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lise Meitner \n Otto Robert Frisch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Willem Keesom \n Wander de Haas \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Pauli \n Otto Stern \n Otto Hahn \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1946,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Edward V. Appleton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walther Bothe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Percy W. Bridgman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Cockcroft \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arthur Dempster \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pyotr Kapitsa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Donald William Kerst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Paul Langevin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lise Meitner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Oppenheimer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Pauli \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Pauli \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Pauli \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Francis Simon \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hideki Yukawa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Carl D. Anderson \n Seth Neddermeyer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Pauli \n Max Born \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Pauli \n Otto Hahn \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lise Meitner \n Otto Robert Frisch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lise Meitner \n Otto Robert Frisch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lise Meitner \n Otto Robert Frisch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Hahn \n Lise Meitner \n Wolfgang Pauli \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Gamow \n Hideki Yukawa \n Maurice de Broglie \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Hahn \n Fritz Strassmann \n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n  Nuclear Scientists \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1947,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Edward V. Appleton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Patrick M.S. Blackett \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Born \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Cockcroft \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Camille Gutton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pyotr Kapitsa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pyotr Kapitsa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pyotr Kapitsa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Donald William Kerst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Donald William Kerst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bernard Lyot \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walther Meissner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lise Meitner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lise Meitner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Carl D. Anderson \n Seth Neddermeyer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Karl-Friedrich Bonhoeffer \n Klaus Clusius \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Cockcroft \n Ernest T.S. Walton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lise Meitner \n Otto Robert Frisch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lise Meitner \n Otto Robert Frisch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lise Meitner \n Otto Robert Frisch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Hahn \n Lise Meitner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Eugene Uhlenbeck \n Samuel Abraham Goudsmit \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Samuel Abraham Goudsmit \n George Eugene Uhlenbeck \n Llewellyn Thomas \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pyotr Kapitsa \n Willem Keesom \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Samuel Abraham Goudsmit \n George Eugene Uhlenbeck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Vladimir Iosifovich Veksler \n Edwin McMillan \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Donald William Kerst \n Robert Serber \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Patrick M.S. Blackett \n Pierre Auger \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Max Born \n Dimitrij Skobelzyn \n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n  Nuclear Scientists \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1948,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Patrick M.S. Blackett \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Patrick M.S. Blackett \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Patrick M.S. Blackett \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Felix Bloch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Born \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Born \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Born \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Born \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arthur Dempster \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Enrico Fermi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pyotr Kapitsa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Donald William Kerst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bernard Lyot \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bernard Lyot \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lise Meitner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Balthasar van der Pol \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Helmut Scheffers \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Vladimir Iosifovich Veksler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hideki Yukawa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Patrick M.S. Blackett \n Pierre Auger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Patrick M.S. Blackett \n Pierre Auger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Felix Bloch \n Hideki Yukawa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pyotr Kapitsa \n Willem Keesom \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Seth Neddermeyer \n Carl D. Anderson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Seth Neddermeyer \n Carl D. Anderson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Carl D. Anderson \n Marcel Schein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Seth Neddermeyer \n Donald William Kerst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lise Meitner \n Otto Robert Frisch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bruno Benedetto Rossi \n Patrick M.S. Blackett \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Henry Yeagley \n Robert Wichard Pohl \n Karl Jansky \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bengt Edlén \n Walter Grotrian \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Frits Zernike \n Bernard Lyot \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Edwin McMillan \n Vladimir Iosifovich Veksler \n Donald William Kerst \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1949,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Born \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Born \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Born \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walther Bothe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Vannevar Bush \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Cockcroft \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aimé Cotton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Harold Jeffreys \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Donald William Kerst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bernard Lyot \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bernard Lyot \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lise Meitner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Seth Neddermeyer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Nier \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Balthasar van der Pol \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Balthasar van der Pol \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albert Portevin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Cecil Powell \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Cecil Powell \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Cecil Powell \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jabez Curry Street \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Leo Szilard \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hideki Yukawa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hideki Yukawa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hideki Yukawa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hideki Yukawa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hideki Yukawa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hideki Yukawa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hideki Yukawa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hideki Yukawa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Seth Neddermeyer \n Carl D. Anderson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Carl D. Anderson \n Marcel Schein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Born \n Walther Bothe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n César Lattes \n Eugène Gardner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n E. M. Purcell \n Felix Bloch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Patrick M.S. Blackett \n Cecil Powell \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Cecil Powell \n Giuseppe Paolo Stanislao Occhialini \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Cecil Powell \n Giuseppe Paolo Stanislao Occhialini \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Cecil Powell \n Giuseppe Paolo Stanislao Occhialini \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n César Lattes \n Giuseppe Paolo Stanislao Occhialini \n Cecil Powell \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hideki Yukawa \n Pierre Auger \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Klaus Clusius \n Karl-Friedrich Bonhoeffer \n Hendrik Kramers \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Marle Tuve \n Gregory Breit \n Lawrence Hafstad \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Edward Stevenson \n Seth Neddermeyer \n Carl D. Anderson \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Donald William Kerst \n Hideki Yukawa \n Jabez Curry Street \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1950,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Georg von Békésy \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Felix Bloch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis de Broglie \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pyotr Kapitsa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bernard Lyot \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bernard Lyot \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bernard Lyot \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walther Meissner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Seth Neddermeyer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Balthasar van der Pol \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Cecil Powell \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Cecil Powell \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Cecil Powell \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Cecil Powell \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Cecil Powell \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Cecil Powell \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Cecil Powell \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Wood \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hideki Yukawa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Frits Zernike \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Born \n Hendrik Kramers \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Born \n Hendrik Kramers \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Born \n Hendrik Kramers \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ira Bowen \n Bengt Edlén \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Joseph Mattauch \n Walther Kossel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Seth Neddermeyer \n Carl D. Anderson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n E. M. Purcell \n Felix Bloch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n Henry Norris Russell \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n Henry Norris Russell \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Marietta Blau \n Hertha Wambacher \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Cecil Powell \n Hideki Yukawa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Giuseppe Paolo Stanislao Occhialini \n Cecil Powell \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Giuseppe Paolo Stanislao Occhialini \n Cecil Powell \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Giuseppe Paolo Stanislao Occhialini \n Cecil Powell \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Born \n Cecil Powell \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bernard Lyot \n Cecil Powell \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bernard Lyot \n Cecil Powell \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bernard Lyot \n Horace Welcome Babcock \n Grote  Reber \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Felix Bloch \n E. M. Purcell \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Frits Zernike \n Bernard Lyot \n Bengt Edlén \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1951,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Luis Walter Alvarez \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pierre Auger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Homi Bhabha \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Felix Bloch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Felix Bloch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Born \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Born \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walther Bothe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Cockcroft \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Cockcroft \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Cockcroft \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pascual Jordan \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Willis E. Lamb \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n César Lattes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bernard Lyot \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bernard Lyot \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bernard Lyot \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bernard Lyot \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Edwin McMillan \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Erwin Wilhelm Müller \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Seth Neddermeyer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Oppenheimer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Kurt Hermann Panofsky \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Cecil Powell \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Meghnad Saha \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Marcel Schein \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Paul Scherrer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Julian Schwinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sin-Itiro Tomonaga \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Hasbrouck  Van Vleck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Frits Zernike \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Frits Zernike \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Born \n Arnold Sommerfeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arnold Sommerfeld \n Erwin Wilhelm Müller \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Eugene Uhlenbeck \n Samuel Abraham Goudsmit \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Felix Bloch \n E. M. Purcell \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Felix Bloch \n E. M. Purcell \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Cockcroft \n Ernest T.S. Walton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Cockcroft \n Ernest T.S. Walton \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Donald William Kerst \n Edwin McMillan \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Edwin McMillan \n Vladimir Iosifovich Veksler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Willis E. Lamb \n Robert Retherford \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Willis E. Lamb \n Robert Retherford \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Willis E. Lamb \n Robert Retherford \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sin-Itiro Tomonaga \n Julian Schwinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Carl D. Anderson \n Marcel Schein \n William Francis Swann \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1952,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Samuel Jackson Barnett \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Samuel Jackson Barnett \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Felix Bloch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Felix Bloch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Felix Bloch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Felix Bloch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Born \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Born \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walther Bothe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pavel A. Cherenkov \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Cockcroft \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Georges Destriau \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Donald William Kerst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n César Lattes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bernard Lyot \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bernard Lyot \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bernard Lyot \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Nevill Francis Mott \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Erwin Wilhelm Müller \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Erwin Wilhelm Müller \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Seth Neddermeyer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Giuseppe Paolo Stanislao Occhialini \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Giuseppe Paolo Stanislao Occhialini \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Giuseppe Paolo Stanislao Occhialini \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lars Onsager \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n E. M. Purcell \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n E. M. Purcell \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Frits Zernike \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Frits Zernike \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Carl D. Anderson \n Seth Neddermeyer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bernard Lyot \n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Born \n Hendrik Kramers \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Born \n Hendrik Kramers \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Samuel Abraham Goudsmit \n George Eugene Uhlenbeck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Willis E. Lamb \n Polykarp Kusch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Willis E. Lamb \n Robert Retherford \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Willis E. Lamb \n Robert Retherford \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n César Lattes \n Wolfgang Kurt Hermann Panofsky \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n E. M. Purcell \n Felix Bloch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n E. M. Purcell \n Felix Bloch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n E. M. Purcell \n Felix Bloch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sin-Itiro Tomonaga \n Julian Schwinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Carl D. Anderson \n Marcel Schein \n William Francis Swann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jacob Clay \n H Bradt \n Bernard Peters \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1953,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Samuel Jackson Barnett \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Samuel Jackson Barnett \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Georg von Békésy \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Homi Bhabha \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Felix Bloch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Born \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Born \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Born \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walther Bothe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walther Bothe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Cabannes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Klaus Clusius \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Edwin Hubble \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Edwin Hubble \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Donald William Kerst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Donald William Kerst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Donald William Kerst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Donald William Kerst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Willis E. Lamb \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n César Lattes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Leprince-Ringuet \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Leprince-Ringuet \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Willard Frank Libby \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Erwin Wilhelm Müller \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Giuseppe Paolo Stanislao Occhialini \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lars Onsager \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Kurt Hermann Panofsky \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Wichard Pohl \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Emilio Segrè \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Francis Simon \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jabez Curry Street \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Geoffrey  Ingram Taylor \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Eugene Wigner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Frits Zernike \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Frits Zernike \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Frits Zernike \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Carl D. Anderson \n William Francis Swann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Bardeen \n Walter H. Brattain \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Edwin Hubble \n Henry Norris Russell \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Donald William Kerst \n Robert Jemison Van de Graaff \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Willis E. Lamb \n Robert Retherford \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Willis E. Lamb \n Robert Retherford \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Eugene Uhlenbeck \n Samuel Abraham Goudsmit \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Carl Friedrich  von Weizsäcker \n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Max Born \n Luis Walter Alvarez \n Pierre Auger \n Pyotr Kapitsa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n Hannes Alfvén \n Willis E. Lamb \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1954,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Georg von Békésy \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Homi Bhabha \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Born \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Born \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Born \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Born \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walther Bothe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walther Bothe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walther Bothe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walther Bothe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walther Bothe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walther Bothe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walther Bothe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bengt Edlén \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walther Kossel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Willis E. Lamb \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Willis E. Lamb \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Willis E. Lamb \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Willis E. Lamb \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Willis E. Lamb \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n César Lattes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Laval \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Georges Lemaître \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Leprince-Ringuet \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Leprince-Ringuet \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lise Meitner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Giuseppe Paolo Stanislao Occhialini \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Geoffrey  Ingram Taylor \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Geoffrey  Ingram Taylor \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Geoffrey  Ingram Taylor \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Vladimir Zworykin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Born \n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Martin Deutsch \n Maurice Goldhaber \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bengt Edlén \n Willis E. Lamb \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lev Landau \n Fritz London \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lars Onsager \n Ilya  Prigogine \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jabez Curry Street \n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Samuel Jackson Barnett \n Samuel Abraham Goudsmit \n George Eugene Uhlenbeck \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Samuel Jackson Barnett \n Samuel Abraham Goudsmit \n George Eugene Uhlenbeck \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n William B. Shockley \n John Bardeen \n Walter H. Brattain \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n William B. Shockley \n John Bardeen \n Walter H. Brattain \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n William B. Shockley \n John Bardeen \n Walter H. Brattain \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Bengt Edlén \n Karl Lark-Horowitz \n Martin Deutsch \n James Baker \n Edwin Land \n Edward Linfoot \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1955,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Homi Bhabha \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Marietta Blau \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Léon Brillouin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pavel A. Cherenkov \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Klaus Clusius \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Klaus Clusius \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Martin Deutsch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Geiger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pyotr Kapitsa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Willem Keesom \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walther Kossel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walther Kossel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walther Kossel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walther Kossel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walther Kossel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Willis E. Lamb \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Willis E. Lamb \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Leprince-Ringuet \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Maria Goeppert Mayer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walther Meissner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walther Meissner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walther Meissner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lise Meitner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Giuseppe Paolo Stanislao Occhialini \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Giuseppe Paolo Stanislao Occhialini \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Oppenheimer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Dixon Rochester \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Meghnad Saha \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Geoffrey  Ingram Taylor \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Geoffrey  Ingram Taylor \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Geoffrey  Ingram Taylor \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sin-Itiro Tomonaga \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Eugene Wigner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Eugene Uhlenbeck \n Samuel Abraham Goudsmit \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Eugene Uhlenbeck \n Samuel Abraham Goudsmit \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Eugene Uhlenbeck \n Samuel Abraham Goudsmit \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Eugene Uhlenbeck \n Samuel Abraham Goudsmit \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Eugene Uhlenbeck \n Samuel Abraham Goudsmit \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Eugene Uhlenbeck \n Samuel Abraham Goudsmit \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Eugene Uhlenbeck \n Samuel Abraham Goudsmit \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Eugene Uhlenbeck \n Samuel Abraham Goudsmit \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Samuel Jackson Barnett \n Samuel Abraham Goudsmit \n George Eugene Uhlenbeck \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Samuel Jackson Barnett \n Samuel Abraham Goudsmit \n George Eugene Uhlenbeck \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Louis Néel \n George Eugene Uhlenbeck \n Samuel Abraham Goudsmit \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Bardeen \n William B. Shockley \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n J. Hans D. Jensen \n Maria Goeppert Mayer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Leprince-Ringuet \n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Polykarp Kusch \n Willis E. Lamb \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Polykarp Kusch \n Willis E. Lamb \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Willis E. Lamb \n Robert Retherford \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bodo von Borries \n Ernst August Friedrich Ruska \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Max Knoll \n Bodo von Borries \n Ernst August Friedrich Ruska \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Hendrik Christoffel  van de Hulst \n Christiaan Alexander (Lex) Muller \n Jan Oort \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Hendrik Christoffel  van de Hulst \n Christiaan Alexander (Lex) Muller \n Jan Oort \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Hendrik Christoffel  van de Hulst \n Christiaan Alexander (Lex) Muller \n Jan Oort \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jabez Curry Street \n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1956,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Horace Welcome Babcock \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Homi Bhabha \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Marietta Blau \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jan Hendrick de Boer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Satyendra Nath Bose \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pavel A. Cherenkov \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sydney Chapman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Klaus Clusius \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Klaus Clusius \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Martin Deutsch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Martin Deutsch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lee de Forest \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Dorothy Crowfoot Hodgkin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pyotr Kapitsa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Leprince-Ringuet \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Willard Frank Libby \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Mark Laurence Oliphant \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lars Onsager \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jan Oort \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Kurt Hermann Panofsky \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Francis Simon \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Geoffrey  Ingram Taylor \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n Julian Schwinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Maria Goeppert Mayer \n J. Hans D. Jensen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Maria Goeppert Mayer \n J. Hans D. Jensen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Maria Goeppert Mayer \n J. Hans D. Jensen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Cornelis Jacobus  Gorter \n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n Cornelis Jacobus  Gorter \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n Cornelis Jacobus  Gorter \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Samuel Abraham Goudsmit \n George Eugene Uhlenbeck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Samuel Abraham Goudsmit \n George Eugene Uhlenbeck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Samuel Abraham Goudsmit \n George Eugene Uhlenbeck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Samuel Abraham Goudsmit \n George Eugene Uhlenbeck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Samuel Abraham Goudsmit \n George Eugene Uhlenbeck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Samuel Abraham Goudsmit \n George Eugene Uhlenbeck \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Samuel Jackson Barnett \n Samuel Abraham Goudsmit \n George Eugene Uhlenbeck \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Samuel Jackson Barnett \n Samuel Abraham Goudsmit \n George Eugene Uhlenbeck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pyotr Kapitsa \n Lev Landau \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pyotr Kapitsa \n Lev Landau \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pyotr Kapitsa \n Lev Landau \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pyotr Kapitsa \n Lev Landau \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Giuseppe Paolo Stanislao Occhialini \n Pavel A. Cherenkov \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jabez Curry Street \n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bengt Edlén \n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n Richard P. Feynman \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Richard P. Feynman \n Julian Schwinger \n Sin-Itiro Tomonaga \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Richard P. Feynman \n Julian Schwinger \n Sin-Itiro Tomonaga \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Richard P. Feynman \n Julian Schwinger \n Sin-Itiro Tomonaga \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gustaf Ising \n Rolf Wideröe \n Donald William Kerst \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n John Bardeen \n Walter H. Brattain \n William B. Shockley \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n John Bardeen \n Walter H. Brattain \n William B. Shockley \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Gerald Pearson \n John Bardeen \n Walter H. Brattain \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Hendrik Christoffel  van de Hulst \n Christiaan Alexander (Lex) Muller \n Jan Oort \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Hendrik Christoffel  van de Hulst \n Christiaan Alexander (Lex) Muller \n Jan Oort \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Pyotr Kapitsa \n Cornelis Jacobus  Gorter \n Sir Francis Simon \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Otto Haxel \n J. Hans D. Jensen \n Hans Suess \n Maria Goeppert Mayer \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Samuel Abraham Goudsmit \n George Eugene Uhlenbeck \n Maria Goeppert Mayer \n J. Hans D. Jensen \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Lise Meitner \n Emilio Segrè \n Maria Goeppert Mayer \n J. Hans D. Jensen \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Vladimir Iosifovich Veksler \n Edwin McMillan \n Richard P. Feynman \n Julian Schwinger \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1957,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Marietta Blau \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Brebis Bleaney \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pavel A. Cherenkov \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pavel A. Cherenkov \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Subramanyan Chandrasekhar \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Klaus Clusius \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Martin Deutsch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bengt Edlén \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bengt Edlén \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Dorothy Crowfoot Hodgkin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pyotr Kapitsa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pyotr Kapitsa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Leprince-Ringuet \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Willard Frank Libby \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Erwin Wilhelm Müller \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Kurt Hermann Panofsky \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Dixon Rochester \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Carl-Gustaf Arvid Rossby \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Emilio Segrè \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Emilio Segrè \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Emilio Segrè \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Emilio Segrè \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Emilio Segrè \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Emilio Segrè \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Geoffrey  Ingram Taylor \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Geoffrey  Ingram Taylor \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Eugene Wigner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Eugene Wigner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Eugene Wigner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Eugene Wigner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Eugene Wigner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Eugene Wigner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Eugene Wigner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lars Onsager \n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Emilio Segrè \n Robert Wichard Pohl \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Léon Brillouin \n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n Ernest Wollan \n Clifford Shull \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Samuel Abraham Goudsmit \n George Eugene Uhlenbeck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Samuel Abraham Goudsmit \n George Eugene Uhlenbeck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Dixon Rochester \n Clifford Butler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Emilio Segrè \n Owen Chamberlain \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sin-Itiro Tomonaga \n Julian Schwinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Julian Schwinger \n Sin-Itiro Tomonaga \n Richard P. Feynman \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Bruno Benedetto Rossi \n Giuseppe Paolo Stanislao Occhialini \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Giuseppe Paolo Stanislao Occhialini \n Pavel A. Cherenkov \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pavel A. Cherenkov \n Igor Y. Tamm \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pavel A. Cherenkov \n Il´ja M. Frank \n Igor Y. Tamm \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pyotr Kapitsa \n Sergey Vavilov \n Pavel A. Cherenkov \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pyotr Kapitsa \n Cornelis Jacobus  Gorter \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pyotr Kapitsa \n Lev Landau \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pyotr Kapitsa \n Lev Landau \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Hans Busch \n Ernst August Friedrich Ruska \n Max Knoll \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Hans Busch \n Ernst August Friedrich Ruska \n Max Knoll \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Hans Busch \n Ernst August Friedrich Ruska \n Max Knoll \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Marcello Conversi \n Ettore Pancini \n Oreste Piccioni \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Hendrik Christoffel  van de Hulst \n Jan Oort \n Christiaan Alexander (Lex) Muller \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Hendrik Christoffel  van de Hulst \n Jan Oort \n Christiaan Alexander (Lex) Muller \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Maria Goeppert Mayer \n J. Hans D. Jensen \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Otto Haxel \n J. Hans D. Jensen \n Hans Suess \n Maria Goeppert Mayer \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Aleksei Naumov \n Gersh Budker \n Vladimir Iosifovich Veksler \n Clyde Cowan, Jr \n Frederick Reines \n Emilio Segrè \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Chen Ning Yang \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Tsung-Dao Lee \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1958,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Jesse Beams \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Georg von Békésy \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Georg von Békésy \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Seymour Benzer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Klaus Clusius \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Martin Deutsch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lev Landau \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Heinz Neuber \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lars Onsager \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ilya  Prigogine \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Boris Rajewsky \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Emilio Segrè \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Emilio Segrè \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Geoffrey  Ingram Taylor \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: '\n Victor Frederick "Viki" Weisskopf \n',
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Carl Friedrich  von Weizsäcker \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Eugene Wigner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Eugene Wigner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Chieng-Shiung Wu \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Chieng-Shiung Wu \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Yevgeny Konstantinovich  Zavoisky \n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Harold Delos Babcock \n Horace Welcome Babcock \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n David Bohm \n David Pines \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clyde Cowan, Jr \n Frederick Reines \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clyde Cowan, Jr \n Frederick Reines \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Maria Goeppert Mayer \n J. Hans D. Jensen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Nevill Francis Mott \n William Hume-Rothery \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pyotr Kapitsa \n Lev Landau \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pyotr Kapitsa \n Lev Landau \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pavel A. Cherenkov \n Sergey Vavilov \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pavel A. Cherenkov \n Il´ja M. Frank \n Igor Y. Tamm \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Giuseppe Paolo Stanislao Occhialini \n George Dixon Rochester \n Clifford Butler \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Lars Onsager \n George Eugene Uhlenbeck \n Samuel Abraham Goudsmit \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jan Oort \n Emilio Segrè \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Hendrik Christoffel  van de Hulst \n Jan Oort \n Christiaan Alexander (Lex) Muller \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Hendrik Christoffel  van de Hulst \n Jan Oort \n Christiaan Alexander (Lex) Muller \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Owen Chamberlain \n Emilio Segrè \n Clyde Wiegand \n Thomas Ypsilantis \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Martin Deutsch \n Charles H. Townes \n Alfred Landé \n Samuel Abraham Goudsmit \n George Eugene Uhlenbeck \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Maria Goeppert Mayer \n J. Hans D. Jensen \n Robert Hofstadter \n Abdus Salam \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1959,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n John Bernal \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Brebis Bleaney \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Nikolai Nikolaevic Bogoljubov \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Satyendra Nath Bose \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sydney Chapman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Klaus Clusius \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Dessauer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Donald A. Glaser \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Donald A. Glaser \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Donald A. Glaser \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Donald A. Glaser \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Maurice Goldhaber \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Hofstadter \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Hofstadter \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Hofstadter \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Hofstadter \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Abram Joffé \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Nicholas Kemmer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles Kittel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lev Landau \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jan Oort \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Wichard Pohl \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Kurt Hermann Panofsky \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Schottky \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Schottky \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Schottky \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Emilio Segrè \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Emilio Segrè \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Geoffrey  Ingram Taylor \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Geoffrey  Ingram Taylor \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Albrecht Unsöld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Vladimir Iosifovich Veksler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Eugene Wigner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Yevgeny Konstantinovich  Zavoisky \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clyde Cowan, Jr \n Frederick Reines \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clyde Cowan, Jr \n Frederick Reines \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Robert Frisch \n Lise Meitner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Schottky \n Carl Wagner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jabez Curry Street \n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Norbert Wiener \n Claude Shannon \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Giuseppe Paolo Stanislao Occhialini \n George Dixon Rochester \n Clifford Butler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Owen Chamberlain \n Emilio Segrè \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Owen Chamberlain \n Emilio Segrè \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Owen Chamberlain \n Emilio Segrè \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Owen Chamberlain \n Emilio Segrè \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Owen Chamberlain \n Emilio Segrè \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Emilio Segrè \n Robert Hofstadter \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Dorothy Crowfoot Hodgkin \n Frederick Charles Frank \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Erwin Wilhelm Müller \n Frank Bowden \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n André Lallemand \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Vladimir Iosifovich Veksler \n Yevgeny Konstantinovich  Zavoisky \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Maria Goeppert Mayer \n J. Hans D. Jensen \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n J. Hans D. Jensen \n Maria Goeppert Mayer \n Pyotr Kapitsa \n Cornelis Jacobus  Gorter \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Chieng-Shiung Wu \n Léon Lederman \n Robert Hofstadter \n Emilio Segrè \n Owen Chamberlain \n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1960,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Nikolai Nikolaevic Bogoljubov \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Nikolai Nikolaevic Bogoljubov \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Léon Brillouin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Klaus Clusius \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Martin Deutsch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Martin Deutsch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Martin Deutsch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Martin Deutsch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Martin Deutsch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Donald A. Glaser \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Donald A. Glaser \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Donald A. Glaser \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Donald A. Glaser \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Donald A. Glaser \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Donald A. Glaser \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Donald A. Glaser \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Donald A. Glaser \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Dorothy Crowfoot Hodgkin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Hofstadter \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Hofstadter \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Hofstadter \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Hofstadter \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hendrik Christoffel  van de Hulst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n André Lallemand \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lev Landau \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Leprince-Ringuet \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Willard Frank Libby \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Willard Frank Libby \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Willard Frank Libby \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Nevill Francis Mott \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Erwin Wilhelm Müller \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jan Oort \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Van Allen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Van Allen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Van Allen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Eugene Wigner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Yevgeny Konstantinovich  Zavoisky \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Anatole Abragam \n Albert  Overhauser \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Brossel \n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clyde Cowan, Jr \n Frederick Reines \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Donald A. Glaser \n Luis Walter Alvarez \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n J. Hans D. Jensen \n Maria Goeppert Mayer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n J. Hans D. Jensen \n Maria Goeppert Mayer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n J. Hans D. Jensen \n Maria Goeppert Mayer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n J. Hans D. Jensen \n Maria Goeppert Mayer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pyotr Kapitsa \n Lev Landau \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jan Oort \n Hendrik Christoffel  van de Hulst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jan Oort \n Hendrik Christoffel  van de Hulst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jan Oort \n Hendrik Christoffel  van de Hulst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jan Oort \n Hendrik Christoffel  van de Hulst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jan Oort \n Hendrik Christoffel  van de Hulst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jan Oort \n Hendrik Christoffel  van de Hulst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jan Oort \n Hendrik Christoffel  van de Hulst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jan Oort \n Hendrik Christoffel  van de Hulst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hendrik Christoffel  van de Hulst \n Jan Oort \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n Erwin Wilhelm Müller \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernst August Friedrich Ruska \n Erwin Wilhelm Müller \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Abraham Pais \n Murray Gell-Mann \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Murray Gell-Mann \n Kazuhiko Nishijima  \n Abraham Pais \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n Julian Schwinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n Julian Schwinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n Julian Schwinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n Julian Schwinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Julian Schwinger \n Richard P. Feynman \n Sin-Itiro Tomonaga \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hannes Alfvén \n Bengt Edlén \n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Max Ferdinand Perutz \n John  Kendrew \n Dorothy Crowfoot Hodgkin \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Max Ferdinand Perutz \n John  Kendrew \n Dorothy Crowfoot Hodgkin \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Charles H. Townes \n Nicolay G. Basov \n Aleksandr M. Prokhorov \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Lev Landau \n Nikolai Nikolaevic Bogoljubov \n Hannes Alfvén \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Charles H. Townes \n Nicolay G. Basov \n Aleksandr M. Prokhorov \n Nicolaas Bloembergen \n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Eugene Wigner \n Richard P. Feynman \n Julian Schwinger \n Martin Deutsch \n Maurice Goldhaber \n Chieng-Shiung Wu \n Bruno Benedetto Rossi \n James Van Allen \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1961,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Hannes Alfvén \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sydney Chapman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Gray \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Heinrich  Heitler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Hofstadter \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Hofstadter \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Hofstadter \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n J. Hans D. Jensen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n André Lallemand \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n André Lallemand \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Rudolf Mössbauer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Rudolf Mössbauer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Rudolf Mössbauer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Rudolf Mössbauer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Rudolf Mössbauer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Rudolf Mössbauer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Rudolf Mössbauer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Rudolf Mössbauer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Rudolf Mössbauer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Rudolf Mössbauer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Rudolf Mössbauer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lars Onsager \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lars Onsager \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lars Onsager \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lars Onsager \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Geoffrey  Ingram Taylor \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Geoffrey  Ingram Taylor \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Van Allen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Van Allen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Eugene Wigner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Yevgeny Konstantinovich  Zavoisky \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aage Niels Bohr \n Ben Mottelson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aage Niels Bohr \n Ben Mottelson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clyde Cowan, Jr \n Frederick Reines \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clyde Cowan, Jr \n Frederick Reines \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clyde Cowan, Jr \n Frederick Reines \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Max Ferdinand Perutz \n John  Kendrew \n Dorothy Crowfoot Hodgkin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Frederick Charles Frank \n Dorothy Crowfoot Hodgkin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Robert Frisch \n Lise Meitner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n John Hasbrouck  Van Vleck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Bruno Benedetto Rossi \n Giuseppe Paolo Stanislao Occhialini \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Giuseppe Paolo Stanislao Occhialini \n George Dixon Rochester \n Clifford Butler \n Rudolf Mössbauer \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Hendrik Christoffel  van de Hulst \n Jan Oort \n Christiaan Alexander (Lex) Muller \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Hendrik Christoffel  van de Hulst \n Jan Oort \n Christiaan Alexander (Lex) Muller \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Bardeen \n Leon Cooper \n John Robert Schrieffer \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n John Bardeen \n Leon Cooper \n John Robert Schrieffer \n Murray Gell-Mann \n Kazuhiko Nishijima  \n Tadao Nakano \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Ernst August Friedrich Ruska \n Max Knoll \n Ladislaus Laszlo Marton \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Sir Geoffrey  Ingram Taylor \n Egon Orowan \n Michael Polanyi \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Frederick Seitz \n Sir Nevill Francis Mott \n Rudolf Mössbauer \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1962,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Hannes Alfvén \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Luis Walter Alvarez \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Luis Walter Alvarez \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Nikolay Vasilyevich Belov \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Satyendra Nath Bose \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Klaus Clusius \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Marian Danysz \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Georges Destriau \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Maurice Goldhaber \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Heinrich  Heitler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n J. Hans D. Jensen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n André Lallemand \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n André Lallemand \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lev Landau \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lev Landau \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bogdan Maglich [Maglić] \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Erwin Wilhelm Müller \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Wolfgang Kurt Hermann Panofsky \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Reinhold Rüdenberg \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Van Allen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Van Allen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Van Allen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Eugene Wigner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Eugene Wigner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Eugene Wigner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Eugene Wigner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Yevgeny Konstantinovich  Zavoisky \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Yevgeny Konstantinovich  Zavoisky \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clyde Cowan, Jr \n Frederick Reines \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Doll \n Martin Näbauer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Maria Goeppert Mayer \n J. Hans D. Jensen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Maria Goeppert Mayer \n J. Hans D. Jensen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Maria Goeppert Mayer \n J. Hans D. Jensen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Maria Goeppert Mayer \n J. Hans D. Jensen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Maria Goeppert Mayer \n J. Hans D. Jensen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Maria Goeppert Mayer \n J. Hans D. Jensen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Dixon Rochester \n Clifford Butler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Dixon Rochester \n Clifford Butler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Dixon Rochester \n Clifford Butler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jabez Curry Street \n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n Julian Schwinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Francis Bitter \n Alfred Kastler \n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Satyendra Nath Bose \n Sin-Itiro Tomonaga \n Subramanyan Chandrasekhar \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Joseph Weber \n Charles H. Townes \n Theodore Maiman \n Nicolaas Bloembergen \n Ali Javan \n Nicolay G. Basov \n Aleksandr M. Prokhorov \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1963,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Luis Walter Alvarez \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Luis Walter Alvarez \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sydney Chapman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Klaus Clusius \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Max Delbrück \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bengt Edlén \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n William Fairbank \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Friedrich \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Otto Robert Frisch \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Heinrich  Heitler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Heinrich  Heitler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n J. Hans D. Jensen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pascual Jordan \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lev Landau \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lev Landau \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lev Landau \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lev Landau \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lev Landau \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Léon Lederman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Erwin Wilhelm Müller \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Erwin Wilhelm Müller \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lars Onsager \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lars Onsager \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lars Onsager \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Julian Schwinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Geoffrey  Ingram Taylor \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Geoffrey  Ingram Taylor \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Eugene Wigner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Eugene Wigner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Eugene Wigner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Eugene Wigner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Eugene Wigner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Yevgeny Konstantinovich  Zavoisky \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Anatole Abragam \n Albert  Overhauser \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hannes Alfvén \n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n William Fairbank \n Robert Doll \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Sin-Itiro Tomonaga \n Julian Schwinger \n Richard P. Feynman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n Arthur Leonard Schawlow \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n Arthur Leonard Schawlow \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n James Gordon \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Giuseppe Paolo Stanislao Occhialini \n Bruno Benedetto Rossi \n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Joseph Weber \n Charles H. Townes \n Nicolaas Bloembergen \n Ali Javan \n Nicolay G. Basov \n Aleksandr M. Prokhorov \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Maria Goeppert Mayer \n J. Hans D. Jensen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Maria Goeppert Mayer \n J. Hans D. Jensen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Martin Ryle \n Sir Bernard Lovell \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n John Bardeen \n Leon Cooper \n John Robert Schrieffer \n Charles H. Townes \n Nicolaas Bloembergen \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Herbert Fröhlich \n John Bardeen \n Nikolai Nikolaevic Bogoljubov \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Clifford Butler \n Giuseppe Paolo Stanislao Occhialini \n George Dixon Rochester \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Clifford Butler \n Giuseppe Paolo Stanislao Occhialini \n George Dixon Rochester \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Clifford Butler \n Giuseppe Paolo Stanislao Occhialini \n George Dixon Rochester \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Clifford Butler \n Giuseppe Paolo Stanislao Occhialini \n George Dixon Rochester \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Marcello Conversi \n Ettore Pancini \n Oreste Piccioni \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Marcello Conversi \n Ettore Pancini \n Oreste Piccioni \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Marcello Conversi \n Ettore Pancini \n Oreste Piccioni \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Marcello Conversi \n Ettore Pancini \n Oreste Piccioni \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Marcello Conversi \n Adriano Gozzini \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clyde Cowan, Jr \n Frederick Reines \n Hannes Alfvén \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Melvin Schwartz \n Léon Lederman \n Jack Steinberger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Melvin Schwartz \n Léon Lederman \n Jack Steinberger \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1964,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Hannes Alfvén \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hannes Alfvén \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hannes Alfvén \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hannes Alfvén \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hannes Alfvén \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Luis Walter Alvarez \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bengt Edlén \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Scott Ellsworth  Forbush \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Herbert Fröhlich \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Cornelis Jacobus  Gorter \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Heinrich  Heitler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Heinrich  Heitler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hendrik Christoffel  van de Hulst \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lise Meitner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Philip Burton  Moon \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Giuseppe Paolo Stanislao Occhialini \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lars Onsager \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Melvin Schwartz \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Julian Schwinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Jemison Van de Graaff \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Chieng-Shiung Wu \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Yevgeny Konstantinovich  Zavoisky \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Yevgeny Konstantinovich  Zavoisky \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Vladimir Iosifovich Veksler \n Edoardo Amaldi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n Carl Friedrich  von Weizsäcker \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Hans Albrecht Bethe \n Richard P. Feynman \n Leopold Infeld \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n Freeman John  Dyson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n Julian Schwinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n Julian Schwinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n Julian Schwinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Richard P. Feynman \n Sin-Itiro Tomonaga \n Julian Schwinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Richard P. Feynman \n Sin-Itiro Tomonaga \n Julian Schwinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Murray Gell-Mann \n Kazuhiko Nishijima  \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Harold Jeffreys \n Keith Edward  Bullen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Bernard Lovell \n Sir Martin Ryle \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lars Onsager \n Ilya  Prigogine \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n H Schüler \n Th Schmidt \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aleksandr M. Prokhorov \n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aleksandr M. Prokhorov \n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aleksandr M. Prokhorov \n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aleksandr M. Prokhorov \n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aleksandr M. Prokhorov \n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Nicolay G. Basov \n Aleksandr M. Prokhorov \n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Nicolay G. Basov \n Aleksandr M. Prokhorov \n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Nicolay G. Basov \n Aleksandr M. Prokhorov \n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Nicolay G. Basov \n Aleksandr M. Prokhorov \n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Arthur Leonard Schawlow \n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Charles H. Townes \n Arthur Leonard Schawlow \n Luis Walter Alvarez \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n Alfred Kastler \n André Lallemand \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n Chieng-Shiung Wu \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n George Eugene Uhlenbeck \n Samuel Abraham Goudsmit \n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n George Eugene Uhlenbeck \n Samuel Abraham Goudsmit \n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Eugene Uhlenbeck \n Samuel Abraham Goudsmit \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Eugene Uhlenbeck \n Samuel Abraham Goudsmit \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Eugene Uhlenbeck \n Samuel Abraham Goudsmit \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Eugene Uhlenbeck \n Samuel Abraham Goudsmit \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Yevgeny Konstantinovich  Zavoisky \n Cornelis Jacobus  Gorter \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Yevgeny Konstantinovich  Zavoisky \n Cornelis Jacobus  Gorter \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Yevgeny Konstantinovich  Zavoisky \n Cornelis Jacobus  Gorter \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Clifford Butler \n Giuseppe Paolo Stanislao Occhialini \n George Dixon Rochester \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Clifford Butler \n Giuseppe Paolo Stanislao Occhialini \n George Dixon Rochester \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Clifford Butler \n Giuseppe Paolo Stanislao Occhialini \n George Dixon Rochester \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clyde Cowan, Jr \n Frederick Reines \n Hannes Alfvén \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Margaret Burbidge \n Geoffrey Ronald  Burbidge \n William Fowler \n Fred Hoyle \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1965,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Hannes Alfvén \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hannes Alfvén \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hannes Alfvén \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hannes Alfvén \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Luis Walter Alvarez \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Luis Walter Alvarez \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Luis Walter Alvarez \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Léon Brillouin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Léon Brillouin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Murray Gell-Mann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Murray Gell-Mann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n S de Groot \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Heinrich  Heitler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Heinrich  Heitler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pyotr Kapitsa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pyotr Kapitsa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pyotr Kapitsa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pyotr Kapitsa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pyotr Kapitsa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Nicholas Kemmer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Nicholas Kemmer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Nicholas Kemmer \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Susumu  Okubo  \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lars Onsager \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Ernst August Friedrich Ruska \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Schottky \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Schottky \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Van Allen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Van Allen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Van Allen \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Hasbrouck  Van Vleck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Hasbrouck  Van Vleck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Hasbrouck  Van Vleck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Hasbrouck  Van Vleck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: '\n Victor Frederick "Viki" Weisskopf \n',
      },
      {
        nominee: "",
      },
      {
        nominee: '\n Victor Frederick "Viki" Weisskopf \n',
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Yevgeny Konstantinovich  Zavoisky \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n Carl Friedrich  von Weizsäcker \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aage Niels Bohr \n Ben Mottelson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aage Niels Bohr \n Ben Mottelson \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Aage Niels Bohr \n Ben Mottelson \n J Wheeler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Dixon Rochester \n C Butler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n C Butler \n George Dixon Rochester \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n C Butler \n Giuseppe Paolo Stanislao Occhialini \n George Dixon Rochester \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n C Butler \n Giuseppe Paolo Stanislao Occhialini \n George Dixon Rochester \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Marcello Conversi \n Adriano Gozzini \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Murray Gell-Mann \n George Dixon Rochester \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Luis Walter Alvarez \n Murray Gell-Mann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Murray Gell-Mann \n Yuval  Néeman  \n Y Ohnuki \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Richard P. Feynman \n Murray Gell-Mann \n Yuval  Néeman  \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n Murray Gell-Mann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n Murray Gell-Mann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n Maurice Françon \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n Julian Schwinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n Julian Schwinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n Julian Schwinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Richard P. Feynman \n Julian Schwinger \n Sin-Itiro Tomonaga \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Richard P. Feynman \n Nicholas Kemmer \n Marian Danysz \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Bruno Benedetto Rossi \n Chieng-Shiung Wu \n Richard P. Feynman \n Murray Gell-Mann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Brossel \n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Brossel \n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Brossel \n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Jean Brossel \n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Marian Danysz \n Jerzy Pniewski \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Marian Danysz \n Jerzy Pniewski \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Marian Danysz \n Jerzy Pniewski \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Marian Danysz \n Jerzy Pniewski \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert  Hanbury Brown \n Richard Twiss  \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Fred Hoyle \n Martin Schwarzchild \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Friedrich Hund \n Erich Hückel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Frederick Reines \n Clyde Cowan, Jr \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clyde Cowan, Jr \n Frederick Reines \n Hannes Alfvén \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Martin Ryle \n Sir Bernard Lovell \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Eugene Uhlenbeck \n Samuel Abraham Goudsmit \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n George Eugene Uhlenbeck \n Samuel Abraham Goudsmit \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n George Eugene Uhlenbeck \n Samuel Abraham Goudsmit \n J Volger \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Walter Schottky \n Samuel Abraham Goudsmit \n George Eugene Uhlenbeck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Van Allen \n Hannes Alfvén \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n James Van Allen \n Charles H. Townes \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Bardeen \n Leon Cooper \n John Robert Schrieffer \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Vladimir Iosifovich Veksler \n Edwin McMillan \n Nicholas Constantine  Christofilos  \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Pyotr Kapitsa \n Cornelis Jacobus  Gorter \n Walter Heinrich  Heitler \n Lise Meitner \n",
      },
      {
        nominee: "",
      },
    ],
  },
  {
    year: 1966,
    nominees: [
      {
        nominee: "Nominee(s)",
      },
      {
        nominee: "\n Hannes Alfvén \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hannes Alfvén \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hannes Alfvén \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hannes Alfvén \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hannes Alfvén \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hannes Alfvén \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Luis Walter Alvarez \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Bernal \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bertram Neville  Brockhouse \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Horace Richard  Crane \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Horace Richard  Crane \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Horace Richard  Crane \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Horace Richard  Crane \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Horace Richard  Crane \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Dennis Gabor \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Murray Gell-Mann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Murray Gell-Mann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Murray Gell-Mann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Murray Gell-Mann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Murray Gell-Mann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Murray Gell-Mann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Murray Gell-Mann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Murray Gell-Mann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Murray Gell-Mann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Murray Gell-Mann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Murray Gell-Mann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Murray Gell-Mann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Murray Gell-Mann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Murray Gell-Mann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Murray Gell-Mann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Heinrich  Heitler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Erich Hückel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Harold Jeffreys \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pyotr Kapitsa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Pyotr Kapitsa \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bernd Theodor  Matthias \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bernd Theodor  Matthias \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walther Meissner \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lars Onsager \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lars Onsager \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lars Onsager \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lars Onsager \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lars Onsager \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lars Onsager \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lars Onsager \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Lars Onsager \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Abdus Salam \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n M Schmidt \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert Jemison Van de Graaff \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Yevgeny Konstantinovich  Zavoisky \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Yevgeny Konstantinovich  Zavoisky \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Yevgeny Konstantinovich  Zavoisky \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Luis Walter Alvarez \n Murray Gell-Mann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Hans Albrecht Bethe \n Bruno Benedetto Rossi \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Brebis Bleaney \n Yevgeny Konstantinovich  Zavoisky \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Brebis Bleaney \n Yevgeny Konstantinovich  Zavoisky \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Nikolai Nikolaevic Bogoljubov \n Vladimir Aleksandrovich  Fok \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Nikolai Nikolaevic Bogoljubov \n Vladimir Aleksandrovich  Fok \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Val Logsdon  Fitch \n James Watson  Cronin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Val Logsdon  Fitch \n James Watson  Cronin \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Val Logsdon  Fitch \n Melvin Schwartz \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Murray Gell-Mann \n Kazuhiko Nishijima  \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Murray Gell-Mann \n Abdus Salam \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Murray Gell-Mann \n Abdus Salam \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Murray Gell-Mann \n M Schmidt \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Samuel Abraham Goudsmit \n George Eugene Uhlenbeck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Samuel Abraham Goudsmit \n George Eugene Uhlenbeck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Robert  Hanbury Brown \n Richard Twiss  \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Walter Heinrich  Heitler \n Julian Schwinger \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Alfred Kastler \n Jean Brossel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Bernard Lovell \n Sir Martin Ryle \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Erwin Wilhelm Müller \n Ernst August Friedrich Ruska \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n Jacques Friedel \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Louis Néel \n John Hasbrouck  Van Vleck \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Sir Alfred Brian  Pippard \n David Shoenberg \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Cornelis Jacobus  Gorter \n Yevgeny Konstantinovich  Zavoisky \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n Clyde Cowan, Jr \n Frederick Reines \n Hannes Alfvén \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n W Fowler \n N Samios \n Murray Gell-Mann \n",
      },
      {
        nominee: "",
      },
      {
        nominee: "\n John Bardeen \n Leon Cooper \n John Robert Schrieffer \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Murray Gell-Mann \n Samuel Abraham Goudsmit \n George Eugene Uhlenbeck \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Murray Gell-Mann \n Samuel Abraham Goudsmit \n George Eugene Uhlenbeck \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Murray Gell-Mann \n Samuel Abraham Goudsmit \n George Eugene Uhlenbeck \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Sir Bernard Lovell \n Sir Martin Ryle \n Hendrik Christoffel  van de Hulst \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Sir Bernard Lovell \n Sir Martin Ryle \n Hendrik Christoffel  van de Hulst \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Sir Bernard Lovell \n Sir Martin Ryle \n Hendrik Christoffel  van de Hulst \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n George Dixon Rochester \n C Butler \n Louis Leprince-Ringuet \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Murray Gell-Mann \n Yuval  Néeman  \n Y Ohnuki \n Sir Martin Ryle \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n John Bardeen \n Leon Cooper \n John Robert Schrieffer \n Nikolai Nikolaevic Bogoljubov \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n John Bardeen \n Leon Cooper \n John Robert Schrieffer \n Nikolai Nikolaevic Bogoljubov \n Herbert Fröhlich \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n Murray Gell-Mann \n Luis Walter Alvarez \n Hans Albrecht Bethe \n Aage Niels Bohr \n Ben Mottelson \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n J Keuffel \n T Cranshaw \n J de Beer \n S Fukui \n S Miyamoto \n",
      },
      {
        nominee: "",
      },
      {
        nominee:
          "\n D Schneiderman \n Robert Benjamin  Leighton \n K Sloan \n J Allen \n B Murray \n R Sharp \n",
      },
      {
        nominee: "",
      },
    ],
  },
];

export const laureates = [
  {
    year: "1901",
    laureates: ["Wilhelm Conrad Röntgen"],
  },
  {
    year: "1902",
    laureates: ["Hendrik A. Lorentz", "Pieter Zeeman"],
  },
  {
    year: "1903",
    laureates: ["Henri Becquerel", "Pierre Curie", "Marie Curie"],
  },
  {
    year: "1904",
    laureates: ["Lord Rayleigh"],
  },
  {
    year: "1905",
    laureates: ["Philipp Lenard"],
  },
  {
    year: "1906",
    laureates: ["J.J. Thomson"],
  },
  {
    year: "1907",
    laureates: ["Albert A. Michelson"],
  },
  {
    year: "1908",
    laureates: ["Gabriel Lippmann"],
  },
  {
    year: "1909",
    laureates: ["Guglielmo Marconi", "Ferdinand Braun"],
  },
  {
    year: "1910",
    laureates: ["Johannes Diderik van der Waals"],
  },
  {
    year: "1911",
    laureates: ["Wilhelm Wien"],
  },
  {
    year: "1912",
    laureates: ["Gustaf Dalén"],
  },
  {
    year: "1913",
    laureates: ["Heike Kamerlingh Onnes"],
  },
  {
    year: "1914",
    laureates: ["Max von Laue"],
  },
  {
    year: "1915",
    laureates: ["William Bragg", "Lawrence Bragg"],
  },
  {
    year: "1916",
    laureates: [],
  },
  {
    year: "1917",
    laureates: ["Charles Glover Barkla"],
  },
  {
    year: "1918",
    laureates: ["Max Planck"],
  },
  {
    year: "1919",
    laureates: ["Johannes Stark"],
  },
  {
    year: "1920",
    laureates: ["Charles Edouard Guillaume"],
  },
  {
    year: "1921",
    laureates: ["Albert Einstein"],
  },
  {
    year: "1922",
    laureates: ["Niels Bohr"],
  },
  {
    year: "1923",
    laureates: ["Robert A. Millikan"],
  },
  {
    year: "1924",
    laureates: ["Manne Siegbahn"],
  },
  {
    year: "1925",
    laureates: ["James Franck", "Gustav Hertz"],
  },
  {
    year: "1926",
    laureates: ["Jean Baptiste Perrin"],
  },
  {
    year: "1927",
    laureates: ["Arthur H. Compton", "C.T.R. Wilson"],
  },
  {
    year: "1928",
    laureates: ["Owen Willans Richardson"],
  },
  {
    year: "1929",
    laureates: ["Louis de Broglie"],
  },
  {
    year: "1930",
    laureates: ["Sir Chandrasekhara Venkata Raman"],
  },
  {
    year: "1931",
    laureates: [],
  },
  {
    year: "1932",
    laureates: ["Werner Heisenberg"],
  },
  {
    year: "1933",
    laureates: ["Erwin Schrödinger", "Paul A.M. Dirac"],
  },
  {
    year: "1934",
    laureates: [],
  },
  {
    year: "1935",
    laureates: ["James Chadwick"],
  },
  {
    year: "1936",
    laureates: ["Victor F. Hess", "Carl D. Anderson"],
  },
  {
    year: "1937",
    laureates: ["Clinton Davisson", "George Paget Thomson"],
  },
  {
    year: "1938",
    laureates: ["Enrico Fermi"],
  },
  {
    year: "1939",
    laureates: ["Ernest Lawrence"],
  },
  {
    year: "1940",
    laureates: [],
  },
  {
    year: "1941",
    laureates: [],
  },
  {
    year: "1942",
    laureates: [],
  },
  {
    year: "1943",
    laureates: ["Otto Stern"],
  },
  {
    year: "1944",
    laureates: ["Isidor Isaac Rabi"],
  },
  {
    year: "1945",
    laureates: ["Wolfgang Pauli"],
  },
  {
    year: "1946",
    laureates: ["Percy W. Bridgman"],
  },
  {
    year: "1947",
    laureates: ["Edward V. Appleton"],
  },
  {
    year: "1948",
    laureates: ["Patrick M.S. Blackett"],
  },
  {
    year: "1949",
    laureates: ["Hideki Yukawa"],
  },
  {
    year: "1950",
    laureates: ["Cecil Powell"],
  },
  {
    year: "1951",
    laureates: ["John Cockcroft", "Ernest T.S. Walton"],
  },
  {
    year: "1952",
    laureates: ["Felix Bloch", "E. M. Purcell"],
  },
  {
    year: "1953",
    laureates: ["Frits Zernike"],
  },
  {
    year: "1954",
    laureates: ["Max Born", "Walther Bothe"],
  },
  {
    year: "1955",
    laureates: ["Willis E. Lamb", "Polykarp Kusch"],
  },
  {
    year: "1956",
    laureates: ["William B. Shockley", "John Bardeen", "Walter H. Brattain"],
  },
  {
    year: "1957",
    laureates: ["Chen Ning Yang", "Tsung-Dao Lee"],
  },
  {
    year: "1958",
    laureates: ["Pavel A. Cherenkov", "Igor Y. Tamm", "Il´ja M. Frank"],
  },
  {
    year: "1959",
    laureates: ["Emilio Segrè", "Owen Chamberlain"],
  },
  {
    year: "1960",
    laureates: ["Donald A. Glaser"],
  },
  {
    year: "1961",
    laureates: ["Robert Hofstadter", "Rudolf Mössbauer"],
  },
  {
    year: "1962",
    laureates: ["Lev Landau"],
  },
  {
    year: "1963",
    laureates: ["Eugene Wigner", "Maria Goeppert Mayer", "J. Hans D. Jensen"],
  },
  {
    year: "1964",
    laureates: [
      "Charles H. Townes",
      "Nicolay G. Basov",
      "Aleksandr M. Prokhorov",
    ],
  },
  {
    year: "1965",
    laureates: ["Sin-Itiro Tomonaga", "Julian Schwinger", "Richard P. Feynman"],
  },
  {
    year: "1966",
    laureates: ["Alfred Kastler"],
  },
];

export const laureatesRaw = [
  {
    awardYear: "1901",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 150782,
    prizeAmountAdjusted: 8722510,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1901",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "1",
        knownName: {
          en: "Wilhelm Conrad Röntgen",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "in recognition of the extraordinary services he has rendered by the discovery of the remarkable rays subsequently named after him",
          se: "såsom ett erkännande av den utomordentliga förtjänst han inlagt genom upptäckten av de egendomliga strålar, som sedermera uppkallats efter honom",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/1",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1902",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 141847,
    prizeAmountAdjusted: 8205634,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1902",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "2",
        knownName: {
          en: "Hendrik A. Lorentz",
        },
        portion: "1/2",
        sortOrder: "1",
        motivation: {
          en: "in recognition of the extraordinary service they rendered by their researches into the influence of magnetism upon radiation phenomena",
          se: "såsom ett erkännande av den utomordentliga förtjänst de inlagt genom sina undersökningar över magnetismens inflytande på strålningsfenomenen",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/2",
          action: "Get",
          types: "application/json",
        },
      },
      {
        id: "3",
        knownName: {
          en: "Pieter Zeeman",
        },
        portion: "1/2",
        sortOrder: "2",
        motivation: {
          en: "in recognition of the extraordinary service they rendered by their researches into the influence of magnetism upon radiation phenomena",
          se: "såsom ett erkännande av den utomordentliga förtjänst de inlagt genom sina undersökningar över magnetismens inflytande på strålningsfenomenen",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/3",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1903",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 141358,
    prizeAmountAdjusted: 7936836,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1903",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "4",
        knownName: {
          en: "Henri Becquerel",
        },
        portion: "1/2",
        sortOrder: "1",
        motivation: {
          en: "in recognition of the extraordinary services he has rendered by his discovery of spontaneous radioactivity",
          se: "såsom ett erkännande av den utomordentliga förtjänst han inlagt genom upptäckten av den spontana radioaktiviteten",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/4",
          action: "Get",
          types: "application/json",
        },
      },
      {
        id: "5",
        knownName: {
          en: "Pierre Curie",
        },
        portion: "1/4",
        sortOrder: "2",
        motivation: {
          en: "in recognition of the extraordinary services they have rendered by their joint researches on the radiation phenomena discovered by Professor Henri Becquerel",
          se: "såsom ett erkännande av den utomordentliga förtjänst de inlagt genom sina gemensamt utförda arbeten rörande de av Professor Henri Becquerel upptäckta strålningsfenomenen",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/5",
          action: "Get",
          types: "application/json",
        },
      },
      {
        id: "6",
        knownName: {
          en: "Marie Curie",
        },
        portion: "1/4",
        sortOrder: "3",
        motivation: {
          en: "in recognition of the extraordinary services they have rendered by their joint researches on the radiation phenomena discovered by Professor Henri Becquerel",
          se: "såsom ett erkännande av den utomordentliga förtjänst de inlagt genom sina gemensamt utförda arbeten rörande de av Professor Henri Becquerel upptäckta strålningsfenomenen",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/6",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1904",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 140859,
    prizeAmountAdjusted: 7908819,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1904",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "8",
        knownName: {
          en: "Lord Rayleigh",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for his investigations of the densities of the most important gases and for his discovery of argon in connection with these studies",
          se: "för hans undersökningar rörande de viktigaste gasernas täthet samt hans i sammanhang med dessa undersökningar gjorda upptäckt av argon",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/8",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1905",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 138089,
    prizeAmountAdjusted: 7753291,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1905",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "9",
        knownName: {
          en: "Philipp Lenard",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for his work on cathode rays",
          se: "för hans arbeten över katodstrålarna",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/9",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1906",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 138536,
    prizeAmountAdjusted: 7556149,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1906",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "10",
        knownName: {
          en: "J.J. Thomson",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "in recognition of the great merits of his theoretical and experimental investigations on the conduction of electricity by gases",
          se: "såsom ett erkännande av den stora förtjänst han inlagt genom sina teoretiska och experimentella undersökningar över elektricitetens gång genom gaser",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/10",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1907",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 138796,
    prizeAmountAdjusted: 7161123,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1907",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "11",
        knownName: {
          en: "Albert A. Michelson",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for his optical precision instruments and the spectroscopic and metrological investigations carried out with their aid",
          se: "för hans optiska precisionsinstrument och hans därmed utförda spektroskopiska och metrologiska undersökningar",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/11",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1908",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 139800,
    prizeAmountAdjusted: 7212924,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1908",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "12",
        knownName: {
          en: "Gabriel Lippmann",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for his method of reproducing colours photographically based on the phenomenon of interference",
          se: "för hans på interferensfenomenet grundade metod att fotografiskt återgiva färger",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/12",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1909",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 139800,
    prizeAmountAdjusted: 7212924,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1909",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "13",
        knownName: {
          en: "Guglielmo Marconi",
        },
        portion: "1/2",
        sortOrder: "1",
        motivation: {
          en: "in recognition of their contributions to the development of wireless telegraphy",
          se: "såsom ett erkännande av deras förtjänster om den trådlösa telegrafiens utveckling",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/13",
          action: "Get",
          types: "application/json",
        },
      },
      {
        id: "14",
        knownName: {
          en: "Ferdinand Braun",
        },
        portion: "1/2",
        sortOrder: "2",
        motivation: {
          en: "in recognition of their contributions to the development of wireless telegraphy",
          se: "såsom ett erkännande av deras förtjänster om den trådlösa telegrafiens utveckling",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/14",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1910",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 140703,
    prizeAmountAdjusted: 7259514,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1910",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "15",
        knownName: {
          en: "Johannes Diderik van der Waals",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for his work on the equation of state for gases and liquids",
          se: "för hans arbeten angående gasers och vätskors tillståndsekvation",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/15",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1911",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 140695,
    prizeAmountAdjusted: 7460743,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1911",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "16",
        knownName: {
          en: "Wilhelm Wien",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for his discoveries regarding the laws governing the radiation of heat",
          se: "för hans upptäckter angående värmestrålningens lagar",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/16",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1912",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 140476,
    prizeAmountAdjusted: 6876120,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1912",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "17",
        knownName: {
          en: "Gustaf Dalén",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for his invention of automatic regulators for use in conjunction with gas accumulators for illuminating lighthouses and buoys",
          se: "för hans uppfinningar av självverkande regulatorer att i kombination med gasaccumulatorer användas till belysning av fyrar och lysbojar",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/17",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1913",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 143010,
    prizeAmountAdjusted: 7000156,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1913",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "18",
        knownName: {
          en: "Heike Kamerlingh Onnes",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for his investigations on the properties of matter at low temperatures which led, inter alia, to the production of liquid helium",
          se: "i anledning av hans undersökningar över kroppars egenskaper vid låga temperaturer, vilka bland annat lett till framställningen av flytande helium",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/18",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1914",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    dateAwarded: "1915-11-11",
    prizeAmount: 146900,
    prizeAmountAdjusted: 7190567,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1914",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "19",
        knownName: {
          en: "Max von Laue",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for his discovery of the diffraction of X-rays by crystals",
          se: "för hans upptäckt av röntgenstrålarnas diffraktion i kristaller",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/19",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1915",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    dateAwarded: "1915-11-12",
    prizeAmount: 149223,
    prizeAmountAdjusted: 6330371,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1915",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "20",
        knownName: {
          en: "William Bragg",
        },
        portion: "1/2",
        sortOrder: "1",
        motivation: {
          en: "for their services in the analysis of crystal structure by means of X-rays",
          se: "för deras förtjänster om utforskandet av kristallstrukturer medelst röntgenstrålar",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/20",
          action: "Get",
          types: "application/json",
        },
      },
      {
        id: "21",
        knownName: {
          en: "Lawrence Bragg",
        },
        portion: "1/2",
        sortOrder: "2",
        motivation: {
          en: "for their services in the analysis of crystal structure by means of X-rays",
          se: "för deras förtjänster om utforskandet av kristallstrukturer medelst röntgenstrålar",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/21",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1916",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    topMotivation: {
      en: "No Nobel Prize was awarded this year. The prize money was allocated to the Special Fund of this prize section.",
    },
    prizeAmount: 131793,
    prizeAmountAdjusted: 4933193,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1916",
      action: "Get",
      types: "application/json",
    },
  },
  {
    awardYear: "1917",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    dateAwarded: "1918-11-12",
    prizeAmount: 133823,
    prizeAmountAdjusted: 3991689,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1917",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "22",
        knownName: {
          en: "Charles Glover Barkla",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for his discovery of the characteristic R&ouml;ntgen radiation of the elements",
          se: "för hans upptäckt av den karakteristiska röntgenstrålningen hos elementen",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/22",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1918",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 138198,
    prizeAmountAdjusted: 2899121,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1918",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "23",
        knownName: {
          en: "Max Planck",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "in recognition of the services he rendered to the advancement of Physics by his discovery of energy quanta",
          se: "såsom ett erkännande av den förtjänst han genom upptäckten av elementarkvanta inlagt om fysikens utveckling",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/23",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1919",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 133127,
    prizeAmountAdjusted: 2420376,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1919",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "24",
        knownName: {
          en: "Johannes Stark",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for his discovery of the Doppler effect in canal rays and the splitting of spectral lines in electric fields",
          se: "för hans upptäckt av dopplereffekten hos kanalstrålar och av spektrallinjers uppdelning i elektriska fält",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/24",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1920",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 134100,
    prizeAmountAdjusted: 2438066,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1920",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "25",
        knownName: {
          en: "Charles Edouard Guillaume",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "in recognition of the service he has rendered to precision measurements in Physics by his discovery of anomalies in nickel steel alloys",
          se: "såsom ett erkännande av den förtjänst han genom upptäckten av nickelstållegeringarnas anomalier inlagt om precisionsfysiken",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/25",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1921",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 121573,
    prizeAmountAdjusted: 2578698,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1921",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "26",
        knownName: {
          en: "Albert Einstein",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for his services to Theoretical Physics, and especially for his discovery of the law of the photoelectric effect",
          se: "för hans förtjänster om den teoretiska fysiken, särskilt hans upptäckt av lagen för den fotoelektriska effekten",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/26",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1922",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 122483,
    prizeAmountAdjusted: 3203014,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1922",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "27",
        knownName: {
          en: "Niels Bohr",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for his services in the investigation of the structure of atoms and of the radiation emanating from them",
          se: "för hans förtjänster om utforskandet av atomernas struktur och den från dem utgående strålningen",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/27",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1923",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 114935,
    prizeAmountAdjusted: 3226631,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1923",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "28",
        knownName: {
          en: "Robert A. Millikan",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for his work on the elementary charge of electricity and on the photoelectric effect",
          se: "för hans arbeten rörande den elektriska enhetsladdningen samt den fotoelektriska effekten",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/28",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1924",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 116719,
    prizeAmountAdjusted: 3276714,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1924",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "29",
        knownName: {
          en: "Manne Siegbahn",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for his discoveries and research in the field of X-ray spectroscopy",
          se: "för hans röntgenspektroskopiska upptäckter och forskningar",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/29",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1925",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 118165,
    prizeAmountAdjusted: 3269232,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1925",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "30",
        knownName: {
          en: "James Franck",
        },
        portion: "1/2",
        sortOrder: "1",
        motivation: {
          en: "for their discovery of the laws governing the impact of an electron upon an atom",
          se: "med anledning av deras upptäckt av lagarna för stöten mellan en elektron och en atom",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/30",
          action: "Get",
          types: "application/json",
        },
      },
      {
        id: "31",
        knownName: {
          en: "Gustav Hertz",
        },
        portion: "1/2",
        sortOrder: "2",
        motivation: {
          en: "for their discovery of the laws governing the impact of an electron upon an atom",
          se: "med anledning av deras upptäckt av lagarna för stöten mellan en elektron och en atom",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/31",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1926",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 116960,
    prizeAmountAdjusted: 3332487,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1926",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "32",
        knownName: {
          en: "Jean Baptiste Perrin",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for his work on the discontinuous structure of matter, and especially for his discovery of sedimentation equilibrium",
          se: "för hans arbeten rörande materiens diskontinuerliga struktur, särskilt för hans upptäckt av sedimentationsjämvikten",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/32",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1927",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 126501,
    prizeAmountAdjusted: 3658946,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1927",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "33",
        knownName: {
          en: "Arthur H. Compton",
        },
        portion: "1/2",
        sortOrder: "1",
        motivation: {
          en: "for his discovery of the effect named after him",
          se: "för hans upptäckt av den efter honom benämnda effekten",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/33",
          action: "Get",
          types: "application/json",
        },
      },
      {
        id: "34",
        knownName: {
          en: "C.T.R. Wilson",
        },
        portion: "1/2",
        sortOrder: "2",
        motivation: {
          en: "for his method of making the paths of electrically charged particles visible by condensation of vapour",
          se: "för upptäckten av hans metod att genom ångkondensation göra elektriskt laddade partiklars banor iakttagbara",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/34",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1928",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 156939,
    prizeAmountAdjusted: 4539342,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1928",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "35",
        knownName: {
          en: "Owen Willans Richardson",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for his work on the thermionic phenomenon and especially for the discovery of the law named after him",
          se: "för hans arbeten rörande termjonfenomenet och särskilt för upptäckten av den efter honom benämnda lagen",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/35",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1929",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 172760,
    prizeAmountAdjusted: 4996952,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1929",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "36",
        knownName: {
          en: "Louis de Broglie",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for his discovery of the wave nature of electrons",
          se: "för upptäckten av elektronernas vågnatur",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/36",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1930",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 172947,
    prizeAmountAdjusted: 5158685,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1930",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "37",
        knownName: {
          en: "Sir Chandrasekhara Venkata Raman",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for his work on the scattering of light and for the discovery of the effect named after him",
          se: "för hans arbeten angående ljusets diffusion och för upptäckten av den efter honom uppkallade effekten",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/37",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1931",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    topMotivation: {
      en: "No Nobel Prize was awarded this year. The prize money was allocated to the Special Fund of this prize section.",
    },
    prizeAmount: 173206,
    prizeAmountAdjusted: 5333069,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1931",
      action: "Get",
      types: "application/json",
    },
  },
  {
    awardYear: "1932",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 171753,
    prizeAmountAdjusted: 5375024,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1932",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "38",
        knownName: {
          en: "Werner Heisenberg",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for the creation of quantum mechanics, the application of which has, inter alia, led to the discovery of the allotropic forms of hydrogen",
          se: "för uppställande av kvantmekaniken, vars användning bl.a. lett till upptäckten av vätets allotropa former",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/38",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1933",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 170332,
    prizeAmountAdjusted: 5511251,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1933",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "39",
        knownName: {
          en: "Erwin Schrödinger",
        },
        portion: "1/2",
        sortOrder: "1",
        motivation: {
          en: "for the discovery of new productive forms of atomic theory",
          se: "för upptäckandet av nya fruktbärande former av atomteorien",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/39",
          action: "Get",
          types: "application/json",
        },
      },
      {
        id: "40",
        knownName: {
          en: "Paul A.M. Dirac",
        },
        portion: "1/2",
        sortOrder: "2",
        motivation: {
          en: "for the discovery of new productive forms of atomic theory",
          se: "för upptäckandet av nya fruktbärande former av atomteorien",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/40",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1934",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    topMotivation: {
      en: "No Nobel Prize was awarded this year. The prize money was with 1/3 allocated to the Main Fund and with 2/3 to the Special Fund of this prize section.",
    },
    prizeAmount: 162608,
    prizeAmountAdjusted: 5261333,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1934",
      action: "Get",
      types: "application/json",
    },
  },
  {
    awardYear: "1935",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 159917,
    prizeAmountAdjusted: 5088026,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1935",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "41",
        knownName: {
          en: "James Chadwick",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for the discovery of the neutron",
          se: "för upptäckten av neutronen",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/41",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1936",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 159850,
    prizeAmountAdjusted: 5002519,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1936",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "42",
        knownName: {
          en: "Victor F. Hess",
        },
        portion: "1/2",
        sortOrder: "1",
        motivation: {
          en: "for his discovery of cosmic radiation",
          se: "för upptäckten av den kosmiska strålningen",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/42",
          action: "Get",
          types: "application/json",
        },
      },
      {
        id: "43",
        knownName: {
          en: "Carl D. Anderson",
        },
        portion: "1/2",
        sortOrder: "2",
        motivation: {
          en: "for his discovery of the positron",
          se: "för upptäckten av positronen",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/43",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1937",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 158463,
    prizeAmountAdjusted: 4801680,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1937",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "44",
        knownName: {
          en: "Clinton Davisson",
        },
        portion: "1/2",
        sortOrder: "1",
        motivation: {
          en: "for their experimental discovery of the diffraction of electrons by crystals",
          se: "för den experimentella upptäckten av interferensfenomenet vid kristallers bestrålning med elektroner",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/44",
          action: "Get",
          types: "application/json",
        },
      },
      {
        id: "45",
        knownName: {
          en: "George Paget Thomson",
        },
        portion: "1/2",
        sortOrder: "2",
        motivation: {
          en: "for their experimental discovery of the diffraction of electrons by crystals",
          se: "för den experimentella upptäckten av interferensfenomenet vid kristallers bestrålning med elektroner",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/45",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1938",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 155077,
    prizeAmountAdjusted: 4625656,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1938",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "46",
        knownName: {
          en: "Enrico Fermi",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for his demonstrations of the existence of new radioactive elements produced by neutron irradiation, and for his related discovery of nuclear reactions brought about by slow neutrons",
          se: "för hans påvisande av nya radioaktiva grundämnen, framställda genom neutronbestrålning, och hans i anslutning därtill gjorda upptäckt av kärnreaktioner, åstadkomna genom långsamma neutroner",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/46",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1939",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 148822,
    prizeAmountAdjusted: 4304564,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1939",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "47",
        knownName: {
          en: "Ernest Lawrence",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for the invention and development of the cyclotron and for results obtained with it, especially with regard to artificial radioactive elements",
          se: "för uppfinningen av cyklotronen, dess utveckling och med densamma vunna resultat, särskilt beträffande artificiellt radioaktiva element",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/47",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1940",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    topMotivation: {
      en: "No Nobel Prize was awarded this year. The prize money was with 1/3 allocated to the Main Fund and with 2/3 to the Special Fund of this prize section.",
    },
    prizeAmount: 138570,
    prizeAmountAdjusted: 3574731,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1940",
      action: "Get",
      types: "application/json",
    },
  },
  {
    awardYear: "1941",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    topMotivation: {
      en: "No Nobel Prize was awarded this year. The prize money was with 1/3 allocated to the Main Fund and with 2/3 to the Special Fund of this prize section.",
    },
    prizeAmount: 131496,
    prizeAmountAdjusted: 2988403,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1941",
      action: "Get",
      types: "application/json",
    },
  },
  {
    awardYear: "1942",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    topMotivation: {
      en: "No Nobel Prize was awarded this year. The prize money was with 1/3 allocated to the Main Fund and with 2/3 to the Special Fund of this prize section.",
    },
    prizeAmount: 131891,
    prizeAmountAdjusted: 2797555,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1942",
      action: "Get",
      types: "application/json",
    },
  },
  {
    awardYear: "1943",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 123691,
    prizeAmountAdjusted: 2566588,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1943",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "48",
        knownName: {
          en: "Otto Stern",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for his contribution to the development of the molecular ray method and his discovery of the magnetic moment of the proton",
          se: "för hans bidrag till utveckling av molekylstrålemetoden och upptäckten av protonens magnetiska moment",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/48",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1944",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 121841,
    prizeAmountAdjusted: 2555983,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1944",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "49",
        knownName: {
          en: "Isidor Isaac Rabi",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for his resonance method for recording the magnetic properties of atomic nuclei",
          se: "för den av honom angivna resonansmetoden för registrering av atomkärnans magnetiska egenskaper",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/49",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1945",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 121333,
    prizeAmountAdjusted: 2545326,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1945",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "50",
        knownName: {
          en: "Wolfgang Pauli",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for the discovery of the Exclusion Principle, also called the Pauli Principle",
          se: "för upptäckten av uteslutningsprincipen, även benämnd Pauliprincipen",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/50",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1946",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 121524,
    prizeAmountAdjusted: 2549333,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1946",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "51",
        knownName: {
          en: "Percy W. Bridgman",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for the invention of an apparatus to produce extremely high pressures, and for the discoveries he made therewith in the field of high pressure physics",
          se: "för uppfinningen av en apparatur för åstadkommande av extremt höga tryck för de upptäckter han med densamma gjort inom området för de höga tryckens fysik",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/51",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1947",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 146115,
    prizeAmountAdjusted: 2967378,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1947",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "52",
        knownName: {
          en: "Edward V. Appleton",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for his investigations of the physics of the upper atmosphere especially for the discovery of the so-called Appleton layer",
          se: "för hans arbeten rörande atmosfärens fysik, särskilt för upptäckten av det s.k. 'Appletonskiktet'",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/52",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1948",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 159773,
    prizeAmountAdjusted: 3112313,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1948",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "53",
        knownName: {
          en: "Patrick M.S. Blackett",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for his development of the Wilson cloud chamber method, and his discoveries therewith in the fields of nuclear physics and cosmic radiation",
          se: "för hans utveckling av Wilsonmetoden och hans därmed gjorda upptäckter inom kärnfysiken och rörande den kosmiska strålningen",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/53",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1949",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 156290,
    prizeAmountAdjusted: 2983576,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1949",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "54",
        knownName: {
          en: "Hideki Yukawa",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for his prediction of the existence of mesons on the basis of theoretical work on nuclear forces",
          se: "för hans på grundval av teoretiska arbeten rörande kärnkrafterna gjorda förutsägelse av mesonernas existens",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/54",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1950",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 164304,
    prizeAmountAdjusted: 3105508,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1950",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "55",
        knownName: {
          en: "Cecil Powell",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for his development of the photographic method of studying nuclear processes and his discoveries regarding mesons made with this method",
          se: "för hans utveckling av den fotografiska metoden för studiet av kärnprocesser och hans därvid gjorda upptäckter rörande mesonerna",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/55",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1951",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 167612,
    prizeAmountAdjusted: 2734798,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1951",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "56",
        knownName: {
          en: "John Cockcroft",
        },
        portion: "1/2",
        sortOrder: "1",
        motivation: {
          en: "for their pioneer work on the transmutation of atomic nuclei by artificially accelerated atomic particles",
          se: "för deras pionjärarbete vid genomförandet av kärnomvandlingar medelst artificiellt accelererade partiklar",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/56",
          action: "Get",
          types: "application/json",
        },
      },
      {
        id: "57",
        knownName: {
          en: "Ernest T.S. Walton",
        },
        portion: "1/2",
        sortOrder: "2",
        motivation: {
          en: "for their pioneer work on the transmutation of atomic nuclei by artificially accelerated atomic particles",
          se: "för deras pionjärarbete vid genomförandet av kärnomvandlingar medelst artificiellt accelererade partiklar",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/57",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1952",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 171135,
    prizeAmountAdjusted: 2592831,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1952",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "58",
        knownName: {
          en: "Felix Bloch",
        },
        portion: "1/2",
        sortOrder: "1",
        motivation: {
          en: "for their development of new methods for nuclear magnetic precision measurements and discoveries in connection therewith",
          se: "för deras utveckling av nya metoder för kärnmagnetiska precisionsmätningar och därmed gjorda upptäckter",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/58",
          action: "Get",
          types: "application/json",
        },
      },
      {
        id: "59",
        knownName: {
          en: "E. M. Purcell",
        },
        portion: "1/2",
        sortOrder: "2",
        motivation: {
          en: "for their development of new methods for nuclear magnetic precision measurements and discoveries in connection therewith",
          se: "för deras utveckling av nya metoder för kärnmagnetiska precisionsmätningar och därmed gjorda upptäckter",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/59",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1953",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 175293,
    prizeAmountAdjusted: 2614331,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1953",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "60",
        knownName: {
          en: "Frits Zernike",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for his demonstration of the phase contrast method, especially for his invention of the phase contrast microscope",
          se: "för den av honom angivna faskontrastmetoden, särskilt för hans uppfinning av faskontrastmikroskopet",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/60",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1954",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 181647,
    prizeAmountAdjusted: 2688094,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1954",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "61",
        knownName: {
          en: "Max Born",
        },
        portion: "1/2",
        sortOrder: "1",
        motivation: {
          en: "for his fundamental research in quantum mechanics, especially for his statistical interpretation of the wavefunction",
          se: "för hans grundläggande arbeten inom kvantmekaniken, särskilt hans statistiska tolkning av vågfunktionen",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/61",
          action: "Get",
          types: "application/json",
        },
      },
      {
        id: "62",
        knownName: {
          en: "Walther Bothe",
        },
        portion: "1/2",
        sortOrder: "2",
        motivation: {
          en: "for the coincidence method and his discoveries made therewith",
          se: "för koincidensmetoden och hans därmed gjorda upptäckter",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/62",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1955",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    dateAwarded: "1955-11-02",
    prizeAmount: 190214,
    prizeAmountAdjusted: 2730214,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1955",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "63",
        knownName: {
          en: "Willis E. Lamb",
        },
        portion: "1/2",
        sortOrder: "1",
        motivation: {
          en: "for his discoveries concerning the fine structure of the hydrogen spectrum",
          se: "för hans upptäckter rörande finstrukturen i vätets spektrum",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/63",
          action: "Get",
          types: "application/json",
        },
      },
      {
        id: "64",
        knownName: {
          en: "Polykarp Kusch",
        },
        portion: "1/2",
        sortOrder: "2",
        motivation: {
          en: "for his precision determination of the magnetic moment of the electron",
          se: "för hans precisionsbestämning av elektronens magnetiska moment",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/64",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1956",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 200123,
    prizeAmountAdjusted: 2748452,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1956",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "65",
        knownName: {
          en: "William B. Shockley",
        },
        portion: "1/3",
        sortOrder: "1",
        motivation: {
          en: "for their researches on semiconductors and their discovery of the transistor effect",
          se: "för deras undersökningar över halvledare och upptäckt av transistor effekten",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/65",
          action: "Get",
          types: "application/json",
        },
      },
      {
        id: "66",
        knownName: {
          en: "John Bardeen",
        },
        portion: "1/3",
        sortOrder: "2",
        motivation: {
          en: "for their researches on semiconductors and their discovery of the transistor effect",
          se: "för deras undersökningar över halvledare och upptäckt av transistor effekten",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/66",
          action: "Get",
          types: "application/json",
        },
      },
      {
        id: "67",
        knownName: {
          en: "Walter H. Brattain",
        },
        portion: "1/3",
        sortOrder: "3",
        motivation: {
          en: "for their researches on semiconductors and their discovery of the transistor effect",
          se: "för deras undersökningar över halvledare och upptäckt av transistor effekten",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/67",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1957",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 208629,
    prizeAmountAdjusted: 2746709,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1957",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "68",
        knownName: {
          en: "Chen Ning Yang",
        },
        portion: "1/2",
        sortOrder: "1",
        motivation: {
          en: "for their penetrating investigation of the so-called parity laws which has led to important discoveries regarding the elementary particles",
          se: "för deras djupgående undersökning av de s.k. paritetslagarna, vilken har lett till viktiga upptäckter rörande elementarpartiklarna",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/68",
          action: "Get",
          types: "application/json",
        },
      },
      {
        id: "69",
        knownName: {
          en: "Tsung-Dao Lee",
        },
        portion: "1/2",
        sortOrder: "2",
        motivation: {
          en: "for their penetrating investigation of the so-called parity laws which has led to important discoveries regarding the elementary particles",
          se: "för deras djupgående undersökning av de s.k. paritetslagarna, vilken har lett till viktiga upptäckter rörande elementarpartiklarna",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/69",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1958",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 214559,
    prizeAmountAdjusted: 2694692,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1958",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "70",
        knownName: {
          en: "Pavel A. Cherenkov",
        },
        portion: "1/3",
        sortOrder: "1",
        motivation: {
          en: "for the discovery and the interpretation of the Cherenkov effect",
          se: "för upptäckten och tolkningen av Cherenkoveffekten",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/70",
          action: "Get",
          types: "application/json",
        },
      },
      {
        id: "71",
        knownName: {
          en: "Igor Y. Tamm",
        },
        portion: "1/3",
        sortOrder: "3",
        motivation: {
          en: "for the discovery and the interpretation of the Cherenkov effect",
          se: "för upptäckten och tolkningen av Cherenkoveffekten",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/71",
          action: "Get",
          types: "application/json",
        },
      },
      {
        id: "721",
        knownName: {
          en: "Il´ja M. Frank",
        },
        portion: "1/3",
        sortOrder: "2",
        motivation: {
          en: "for the discovery and the interpretation of the Cherenkov effect",
          se: "för upptäckten och tolkningen av Cherenkoveffekten",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/721",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1959",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 220678,
    prizeAmountAdjusted: 2753427,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1959",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "72",
        knownName: {
          en: "Emilio Segrè",
        },
        portion: "1/2",
        sortOrder: "1",
        motivation: {
          en: "for their discovery of the antiproton",
          se: "för deras upptäckt av antiprotonen",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/72",
          action: "Get",
          types: "application/json",
        },
      },
      {
        id: "73",
        knownName: {
          en: "Owen Chamberlain",
        },
        portion: "1/2",
        sortOrder: "2",
        motivation: {
          en: "for their discovery of the antiproton",
          se: "för deras upptäckt av antiprotonen",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/73",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1960",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    dateAwarded: "1960-11-03",
    prizeAmount: 225987,
    prizeAmountAdjusted: 2713265,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1960",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "74",
        knownName: {
          en: "Donald A. Glaser",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for the invention of the bubble chamber",
          se: "för uppfinningen av bubbelkammaren",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/74",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1961",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 250233,
    prizeAmountAdjusted: 2930643,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1961",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "75",
        knownName: {
          en: "Robert Hofstadter",
        },
        portion: "1/2",
        sortOrder: "1",
        motivation: {
          en: "for his pioneering studies of electron scattering in atomic nuclei and for his thereby achieved discoveries concerning the structure of the nucleons",
          se: "för hans banbrytande undersökningar över elektronspridningen mot atomkärnor och därvid gjorda upptäckter rörande nukleonernas struktur",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/75",
          action: "Get",
          types: "application/json",
        },
      },
      {
        id: "76",
        knownName: {
          en: "Rudolf Mössbauer",
        },
        portion: "1/2",
        sortOrder: "2",
        motivation: {
          en: "for his researches concerning the resonance absorption of gamma radiation and his discovery in this connection of the effect which bears his name",
          se: "för hans undersökningar rörande gammastrålningens resonansabsorption och därvid gjorda upptäckt av den efter honom benämnda effekten",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/76",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1962",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 257220,
    prizeAmountAdjusted: 2888429,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1962",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "77",
        knownName: {
          en: "Lev Landau",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for his pioneering theories for condensed matter, especially liquid helium",
          se: "för hans banbrytande teorier för kondenserad materia, särskilt flytande helium",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/77",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1963",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 265000,
    prizeAmountAdjusted: 2890771,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1963",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "78",
        knownName: {
          en: "Eugene Wigner",
        },
        portion: "1/2",
        sortOrder: "1",
        motivation: {
          en: "for his contributions to the theory of the atomic nucleus and the elementary particles, particularly through the discovery and application of fundamental symmetry principles",
          se: "för hans insatser inom teorien för atomkärnorna och elementarpartiklarna, särskilt genom upptäckten och tillämpningen av fundamentala symmetriprinciper",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/78",
          action: "Get",
          types: "application/json",
        },
      },
      {
        id: "79",
        knownName: {
          en: "Maria Goeppert Mayer",
        },
        portion: "1/4",
        sortOrder: "2",
        motivation: {
          en: "for their discoveries concerning nuclear shell structure",
          se: "för deras upptäckter beträffande atomkärnornas skalstruktur",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/79",
          action: "Get",
          types: "application/json",
        },
      },
      {
        id: "80",
        knownName: {
          en: "J. Hans D. Jensen",
        },
        portion: "1/4",
        sortOrder: "3",
        motivation: {
          en: "for their discoveries concerning nuclear shell structure",
          se: "för deras upptäckter beträffande atomkärnornas skalstruktur",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/80",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1964",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 273000,
    prizeAmountAdjusted: 2879320,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1964",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "81",
        knownName: {
          en: "Charles H. Townes",
        },
        portion: "1/2",
        sortOrder: "1",
        motivation: {
          en: "for fundamental work in the field of quantum electronics, which has led to the construction of oscillators and amplifiers based on the maser-laser principle",
          se: "för grundläggande arbeten inom kvantelektroniken, som lett till framställning av oscillatorer och förstärkare enligt maser-laserprincipen",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/81",
          action: "Get",
          types: "application/json",
        },
      },
      {
        id: "82",
        knownName: {
          en: "Nicolay G. Basov",
        },
        portion: "1/4",
        sortOrder: "2",
        motivation: {
          en: "for fundamental work in the field of quantum electronics, which has led to the construction of oscillators and amplifiers based on the maser-laser principle",
          se: "för grundläggande arbeten inom kvantelektroniken, som lett till framställning av oscillatorer och förstärkare enligt maser-laserprincipen",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/82",
          action: "Get",
          types: "application/json",
        },
      },
      {
        id: "83",
        knownName: {
          en: "Aleksandr M. Prokhorov",
        },
        portion: "1/4",
        sortOrder: "3",
        motivation: {
          en: "for fundamental work in the field of quantum electronics, which has led to the construction of oscillators and amplifiers based on the maser-laser principle",
          se: "för grundläggande arbeten inom kvantelektroniken, som lett till framställning av oscillatorer och förstärkare enligt maser-laserprincipen",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/83",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1965",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 282000,
    prizeAmountAdjusted: 2833358,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1965",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "84",
        knownName: {
          en: "Sin-Itiro Tomonaga",
        },
        portion: "1/3",
        sortOrder: "1",
        motivation: {
          en: "for their fundamental work in quantum electrodynamics, with deep-ploughing consequences for the physics of elementary particles",
          se: "för deras fundamentala insats inom kvantelektrodynamiken, med djupgående konsekvenser för elementarpartikelfysiken",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/84",
          action: "Get",
          types: "application/json",
        },
      },
      {
        id: "85",
        knownName: {
          en: "Julian Schwinger",
        },
        portion: "1/3",
        sortOrder: "2",
        motivation: {
          en: "for their fundamental work in quantum electrodynamics, with deep-ploughing consequences for the physics of elementary particles",
          se: "för deras fundamentala insats inom kvantelektrodynamiken, med djupgående konsekvenser för elementarpartikelfysiken",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/85",
          action: "Get",
          types: "application/json",
        },
      },
      {
        id: "86",
        knownName: {
          en: "Richard P. Feynman",
        },
        portion: "1/3",
        sortOrder: "3",
        motivation: {
          en: "for their fundamental work in quantum electrodynamics, with deep-ploughing consequences for the physics of elementary particles",
          se: "för deras fundamentala insats inom kvantelektrodynamiken, med djupgående konsekvenser för elementarpartikelfysiken",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/86",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
  {
    awardYear: "1966",
    category: {
      en: "Physics",
      no: "Fysikk",
      se: "Fysik",
    },
    categoryFullName: {
      en: "The Nobel Prize in Physics",
      no: "Nobelprisen i fysikk",
      se: "Nobelpriset i fysik",
    },
    prizeAmount: 300000,
    prizeAmountAdjusted: 2835149,
    links: {
      rel: "nobelPrize",
      href: "https://api.nobelprize.org/2/nobelPrize/phy/1966",
      action: "Get",
      types: "application/json",
    },
    laureates: [
      {
        id: "87",
        knownName: {
          en: "Alfred Kastler",
        },
        portion: "1",
        sortOrder: "1",
        motivation: {
          en: "for the discovery and development of optical methods for studying Hertzian resonances in atoms",
          se: "för upptäckt och utveckling av optiska metoder för studiet av hertzska resonanser i atomer",
        },
        links: {
          rel: "laureate",
          href: "https://api.nobelprize.org/2/laureate/87",
          action: "Get",
          types: "application/json",
        },
      },
    ],
  },
];
