import React from "react";
import { TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { UPDATE_CHART_SETTINGS } from "../utils/actions";
import { getObjValueByPath } from "../utils/utils";

export function InputString(props) {
  const { name, inputSettings, isArray, index } = props;

  const PLACEHOLDER = inputSettings ? inputSettings.placeholder || "" : "";
  const VALUE = useSelector((state: any) =>
    getObjValueByPath(state.chart.chartUI, name)
  );
  const dispatch = useDispatch();
  const [inputValue, updateInputValue] = React.useState(VALUE);
  const [prevValue, updatePrevValue] = React.useState(VALUE);

  //if use useEffect hook, component will re-render twice, now it re-renders immidiatly
  if (VALUE !== prevValue) {
    updateInputValue(VALUE);
    updatePrevValue(VALUE);
  }

  //update local state while using keyboard
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value;
    updateInputValue(value);
  };

  //keyboard input update global state after unfocus
  const handleBlur = (event) => {
    let value = event.target.value;

    //if value are equal to global state value, then we updating
    //local state, else - we updating global state
    if (value === VALUE) {
      updateInputValue(value);
    } else {
      dispatch(UPDATE_CHART_SETTINGS(name, value));
    }
  };

  return (
    <TextField
      type="text"
      variant="standard"
      size="small"
      value={inputValue}
      inputProps={{
        // maxLength: 32,
        style: { padding: 0, fontSize: "0.8125rem" },
      }}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={(event) => {
        event.target.select();
      }}
      onKeyUp={(event) => {
        if (event.key === "Enter") {
          handleBlur(event);
        }
      }}
      style={{ width: "100%" }}
      placeholder={!isArray ? PLACEHOLDER : ""}
    />
  );
}

export default InputString;
