export const dataGroups = [
  {
    Hierarchy: [
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Christian",
      "Non-Christian",
      "Non-Christian",
      "Non-Christian",
      "Non-Christian",
      "Non-Christian",
      "Non-Christian",
      "Non-Christian",
      "Non-Christian",
      "Non-Christian",
      "Non-Christian",
      "Non-Christian",
      "Non-Christian",
      "Non-Christian",
      "Non-Christian",
      "Non-Religion",
      "Non-Religion",
      "Non-Religion",
      "Non-Religion",
      "Don't know",
    ],
  },
  {
    Hierarchy: [
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Evangelical Protestant",
      "Mainline Protestant",
      "Mainline Protestant",
      "Mainline Protestant",
      "Mainline Protestant",
      "Mainline Protestant",
      "Mainline Protestant",
      "Mainline Protestant",
      "Mainline Protestant",
      "Mainline Protestant",
      "Mainline Protestant",
      "Mainline Protestant",
      "Mainline Protestant",
      "Mainline Protestant",
      "Mainline Protestant",
      "Mainline Protestant",
      "Mainline Protestant",
      "Mainline Protestant",
      "Mainline Protestant",
      "Mainline Protestant",
      "Mainline Protestant",
      "Mainline Protestant",
      "Historically Black Protestant",
      "Historically Black Protestant",
      "Historically Black Protestant",
      "Historically Black Protestant",
      "Historically Black Protestant",
      "Historically Black Protestant",
      "Historically Black Protestant",
      "Historically Black Protestant",
      "Historically Black Protestant",
      "Historically Black Protestant",
      "Historically Black Protestant",
      "Historically Black Protestant",
      "Historically Black Protestant",
      "Historically Black Protestant",
      "Historically Black Protestant",
      "Historically Black Protestant",
      "Catholic",
      "Mormon",
      "Orthodox Christian",
      "Orthodox Christian",
      "Orthodox Christian",
      "Orthodox Christian",
      "Jehovah's Witness",
      "Other Christian",
      "Jewish",
      "Muslim",
      "Buddhist",
      "Hindu",
      "Other World Religions",
      "Other Faiths",
      "Other Faiths",
      "Other Faiths",
      "Other Faiths",
      "Other Faiths",
      "Other Faiths",
      "Other Faiths",
      "Other Faiths",
      "Other Faiths",
      "Atheist",
      "Agnostic",
      "Nothing in particular",
      "Nothing in particular",
      "Don't know",
    ],
  },
  {
    Hierarchy: [
      "Baptist",
      "Baptist",
      "Baptist",
      "Baptist",
      "Baptist",
      "Baptist",
      "Baptist",
      "Methodist",
      "Nondenominational",
      "Nondenominational",
      "Nondenominational",
      "Nondenominational",
      "Nondenominational",
      "Nondenominational",
      "Nondenominational",
      "Lutheran",
      "Lutheran",
      "Lutheran",
      "Presbyterian",
      "Presbyterian",
      "Pentecostal",
      "Pentecostal",
      "Pentecostal",
      "Pentecostal",
      "Pentecostal",
      "Pentecostal",
      "Pentecostal",
      "Pentecostal",
      "Pentecostal",
      "Pentecostal",
      "Episcopalian/Anglican",
      "Restorationist",
      "Congregationalist",
      "Holiness",
      "Holiness",
      "Holiness",
      "Holiness",
      "Holiness",
      "Holiness",
      "Reformed",
      "Adventist",
      "Anabaptist",
      "Pietist",
      "Other evangelical/fundamentalist family",
      "Nonspecific Protestant",
      "Baptist",
      "Baptist",
      "Methodist",
      "Methodist",
      "Nondenominational",
      "Nondenominational",
      "Lutheran",
      "Lutheran",
      "Presbyterian",
      "Presbyterian",
      "Episcopal/Anglican",
      "Episcopal/Anglican",
      "Episcopal/Anglican",
      "Restorationist",
      "Restorationist",
      "Congregationalist",
      "Congregationalist",
      "Reformed",
      "Anabaptist",
      "Friends",
      "Nonspecific Protestant",
      "Baptist",
      "Baptist",
      "Baptist",
      "Baptist",
      "Baptist",
      "Methodist",
      "Methodist",
      "Methodist",
      "Methodist",
      "Pentecostal",
      "Pentecostal",
      "Pentecostal",
      "Pentecostal",
      "Holiness",
      "Nondenominational",
      "Nonspecific Protestant",
      0,
      0,
      "Greek Orthodox",
      "Russian Orthodox",
      "Orthodox Church in America",
      "Other Orthodox Christian",
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      "Other",
      "Other",
      "Other",
      "Other",
      "Other",
      "Other",
      "New Age",
      "New Age",
      "Native American Religions",
      0,
      0,
      "Religion not important",
      "Religion important",
      0,
    ],
  },
  {
    Hierarchy: [
      "Southern Baptist Convention",
      "Independent Baptist",
      "Missionary Baptist",
      "Conservative Baptist Association of America",
      "Free Will Baptist",
      "General Association of Regular Baptist Churches",
      "Other",
      0,
      "Nondenominational evangelical",
      "Nondenominational fundamentalist",
      "Nondenominational charismatic",
      "Nondenominational Christian",
      "Interdenominational",
      "Community Church",
      "Other",
      "Church-Missouri Synod",
      "Wisconsin Evangelical Lutheran Synod",
      "Other Lutheran",
      "Presbyterian Church in America",
      "Other",
      "Assemblies of God",
      "Church of God (Cleveland, Tennessee)",
      "Foursquare Church",
      "Pentecostal Church of God",
      "Pentecostal Holiness Church",
      "Calvary Chapel",
      "Apostolic Pentecostal",
      "Nondenominational Pentecostal",
      "Church of God of the Apostolic Faith",
      "Other",
      0,
      0,
      0,
      "Church of the Nazarene",
      "Wesleyan Church",
      "Free Methodist Church",
      "Christian and Missionary Alliance",
      "Church of God (Anderson, Indiana)",
      "Other",
      0,
      0,
      0,
      0,
      0,
      0,
      "ABCUSA",
      "Other",
      "UMC",
      "Other",
      "Interdenominational",
      "Other",
      "ELCA",
      "Other",
      "PC (USA)",
      "Other",
      "Episcopal",
      "Anglican",
      "Other",
      "Disciples of Christ",
      "Other",
      "United Church of Christ",
      "Other",
      0,
      0,
      0,
      0,
      "National Baptist Convention",
      "Progressive Baptist Convention",
      "Independent Baptist",
      "Missionary Baptist",
      "Other",
      "African Methodist Episcopal Church",
      "African Methodist Episcopal Zion Church",
      "Christian Methodist Episcopal Church",
      "Other",
      "Church of God in Christ",
      "Apostolic Pentecostal",
      "United Pentecostal Church International",
      "Other",
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      "Unitarian",
      "Humanist",
      "Deist",
      "Spiritual but not religious",
      'Eclectic, a bit of everything, "I have my own beliefs"',
      "Other",
      "Pagan or Wiccan",
      "Other in the New Age family",
      0,
      0,
      0,
      0,
      0,
      0,
    ],
  },
];

export const dataValues = [
  5.3, 2.5, 0.2, 0.1, 0.1, 0.1, 1, 0.1, 2, 0.3, 0.6, 0.1, 0.6, 0.1, 1.2, 1.1,
  0.1, 0.3, 0.4, 0.4, 1.4, 0.4, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 1.1, 0.1,
  1.6, 0.1, 0.3, 0.1, 0.1, 0.05, 0.05, 0.1, 0.1, 0.6, 0.3, 0.1, 0.3, 1.5, 1.5,
  0.6, 3.6, 0.3, 0.3, 0.7, 1.4, 0.7, 0.9, 0.5, 0.9, 0.2, 0.1, 0.15, 0.15, 0.4,
  0.1, 0.1, 0.1, 0.1, 1.9, 1.4, 0.3, 0.2, 0.3, 1.8, 0.3, 0.1, 0.05, 0.05, 0.6,
  0.1, 0.1, 0.2, 0.1, 0.3, 0.4, 20.8, 1.6, 0.1, 0.1, 0.1, 0.1, 0.8, 0.4, 1.9,
  0.9, 0.7, 0.7, 0.3, 0.3, 0.1, 0.1, 0.3, 0.1, 0.1, 0.3, 0.1, 0.1, 3.1, 4, 8.8,
  6.9, 0.6,
];
