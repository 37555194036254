import { ControlType } from "../ui/controltype";
import { getValidDimensionDataArr, getCatsFromSeries } from "../utils/utils";

function getXAxisDataType(ui, dataKey, useLayout) {
  const layout = useLayout ? ui.chartSettings.chartLayout : true;
  return layout ? ui.dimensions[dataKey.x] : ui.dimensions[dataKey.y];
}

function getYAxisDataType(ui, dataKey, useLayout) {
  const layout = useLayout ? ui.chartSettings.chartLayout : true;
  return layout ? ui.dimensions[dataKey.y] : ui.dimensions[dataKey.x];
}

// get values for 'category' axis
function getCategories(props, axis, useLayout) {
  const layout = useLayout ? props.chartSettings.chartLayout : true;
  let arr = [] as any;

  if (axis === "X") {
    arr = layout
      ? props.dataItems.items.map((elem) => elem)
      : getCatsFromSeries(props.dataSeries.items);
  } else {
    arr = layout
      ? getCatsFromSeries(props.dataSeries.items)
      : props.dataItems.items.map((elem) => elem);
  }

  return getValidDimensionDataArr(arr, "string");
}

export const presetRefAreas = (dataKey, useLayout) => {
  return {
    type: ControlType.Collection,
    title: "Reference area",
    isHeaderControls: true,
    isGroupsColorLegend: false,
    propertyControl: {
      x1CoordinateNumber: {
        type: ControlType.Number,
        title: "X1",
        // use value from reference
        useRef(props) {
          return useLayout ? !props.chartSettings.chartLayout : false;
        },
        refName: "y1CoordinateNumber",
        hidden(props, ui) {
          return getXAxisDataType(ui, dataKey, useLayout) !== "number";
        },
      },
      x2CoordinateNumber: {
        type: ControlType.Number,
        title: "X2",
        // use value from reference
        useRef(props) {
          return useLayout ? !props.chartSettings.chartLayout : false;
        },
        refName: "y2CoordinateNumber",
        hidden(props, ui) {
          return getXAxisDataType(ui, dataKey, useLayout) !== "number";
        },
      },
      x1CoordinateCategory: {
        type: ControlType.Enum,
        title: "X1",
        options(props) {
          return getCategories(props, "X", useLayout);
        },
        optionTitles(props) {
          return getCategories(props, "X", useLayout);
        },
        placeholder: "Choose category",
        // use value from reference
        useRef(props) {
          return useLayout ? !props.chartSettings.chartLayout : false;
        },
        refName: "y1CoordinateCategory",
        hidden(props, ui) {
          return getXAxisDataType(ui, dataKey, useLayout) === "number";
        },
      },
      x2CoordinateCategory: {
        type: ControlType.Enum,
        title: "X2",
        options(props) {
          return getCategories(props, "X", useLayout);
        },
        optionTitles(props) {
          return getCategories(props, "X", useLayout);
        },
        placeholder: "Choose category",
        // use value from reference
        useRef(props) {
          return useLayout ? !props.chartSettings.chartLayout : false;
        },
        refName: "y2CoordinateCategory",
        hidden(props, ui) {
          return getXAxisDataType(ui, dataKey, useLayout) === "number";
        },
      },
      y1CoordinateNumber: {
        type: ControlType.Number,
        title: "Y1",
        // use value from reference
        useRef(props) {
          return useLayout ? !props.chartSettings.chartLayout : false;
        },
        refName: "x1CoordinateNumber",
        hidden(props, ui) {
          return getYAxisDataType(ui, dataKey, useLayout) !== "number";
        },
      },
      y2CoordinateNumber: {
        type: ControlType.Number,
        title: "Y2",
        // use value from reference
        useRef(props) {
          return useLayout ? !props.chartSettings.chartLayout : false;
        },
        refName: "x2CoordinateNumber",
        hidden(props, ui) {
          return getYAxisDataType(ui, dataKey, useLayout) !== "number";
        },
      },
      y1CoordinateCategory: {
        type: ControlType.Enum,
        title: "Y1",
        options(props) {
          return getCategories(props, "Y", useLayout);
        },
        optionTitles(props) {
          return getCategories(props, "Y", useLayout);
        },
        placeholder: "Choose category",
        // use value from reference
        useRef(props) {
          return useLayout ? !props.chartSettings.chartLayout : false;
        },
        refName: "x1CoordinateCategory",
        hidden(props, ui) {
          return getYAxisDataType(ui, dataKey, useLayout) === "number";
        },
      },
      y2CoordinateCategory: {
        type: ControlType.Enum,
        title: "Y2",
        options(props) {
          return getCategories(props, "Y", useLayout);
        },
        optionTitles(props) {
          return getCategories(props, "Y", useLayout);
        },
        placeholder: "Choose category",
        // use value from reference
        useRef(props) {
          return useLayout ? !props.chartSettings.chartLayout : false;
        },
        refName: "x2CoordinateCategory",
        hidden(props, ui) {
          return getYAxisDataType(ui, dataKey, useLayout) === "number";
        },
      },
      fillColor: {
        type: ControlType.Color,
        title: "Fill color",
      },
      strokeColor: {
        type: ControlType.Color,
        title: "Stroke color",
      },
      strokeWidth: {
        type: ControlType.Number,
        title: "Stroke",
        min: 0,
        max: 100,
        displayStepper: true,
      },
      strokeStyle: {
        type: ControlType.Enum,
        title: "Style",
        options: ["Solid", "Dashed", "Dotted"],
        optionTitles: ["Solid", "Dashed", "Dotted"],
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  };
};

export const presetRefLines = (dataKey, useLayout) => {
  return {
    type: ControlType.Collection,
    title: "Reference line",
    isHeaderControls: true,
    isGroupsColorLegend: false,
    propertyControl: {
      orient: {
        type: ControlType.Boolean,
        title: "Orientation",
        enabledTitle: "horizontal",
        disabledTitle: "vertical",
      },
      xCoordinateNumber: {
        type: ControlType.Number,
        title: "X coordinate",
        // use value from reference
        useRef(props) {
          return useLayout ? !props.chartSettings.chartLayout : false;
        },
        refName: "yCoordinateNumber",
        hidden(props, ui) {
          return (
            getXAxisDataType(ui, dataKey, useLayout) !== "number" ||
            props.orient
          );
        },
      },
      xCoordinateCategory: {
        type: ControlType.Enum,
        title: "X coordinate",
        options(props) {
          return getCategories(props, "X", useLayout);
        },
        optionTitles(props) {
          return getCategories(props, "X", useLayout);
        },
        placeholder: "Choose category",
        // use value from reference
        useRef(props) {
          return useLayout ? !props.chartSettings.chartLayout : false;
        },
        refName: "yCoordinateCategory",
        hidden(props, ui) {
          return (
            getXAxisDataType(ui, dataKey, useLayout) === "number" ||
            props.orient
          );
        },
      },
      yCoordinateNumber: {
        type: ControlType.Number,
        title: "Y coordinate",
        // use value from reference
        useRef(props) {
          return useLayout ? !props.chartSettings.chartLayout : false;
        },
        refName: "xCoordinateNumber",
        hidden(props, ui) {
          return (
            getYAxisDataType(ui, dataKey, useLayout) !== "number" ||
            !props.orient
          );
        },
      },
      yCoordinateCategory: {
        type: ControlType.Enum,
        title: "Y coordinate",
        options(props) {
          return getCategories(props, "Y", useLayout);
        },
        optionTitles(props) {
          return getCategories(props, "Y", useLayout);
        },
        placeholder: "Choose category",
        // use value from reference
        useRef(props) {
          return useLayout ? !props.chartSettings.chartLayout : false;
        },
        refName: "xCoordinateCategory",
        hidden(props, ui) {
          return (
            getYAxisDataType(ui, dataKey, useLayout) === "number" ||
            !props.orient
          );
        },
      },
      lineColor: {
        type: ControlType.Color,
        title: "Color",
      },
      lineStrokeWidth: {
        type: ControlType.Number,
        title: "Stroke",
        min: 0,
        max: 100,
        displayStepper: true,
      },
      lineStyle: {
        type: ControlType.Enum,
        title: "Style",
        options: ["Solid", "Dashed", "Dotted"],
        optionTitles: ["Solid", "Dashed", "Dotted"],
      },
      showLabel: {
        type: ControlType.Boolean,
        title: "Label",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      name: {
        title: "↳ Text",
        type: ControlType.Text,
        placeholder: "Label for reference line",
        hidden(props) {
          return props.showLabel === false;
        },
      },
      labelPositionHoriz: {
        type: ControlType.Enum,
        title: "↳ Position",
        options: ["left", "center", "right"],
        optionTitles: ["left", "center", "right"],
        displaySegmentedControl: true,
        hidden(props) {
          return props.showLabel === false || props.orient === false;
        },
      },
      labelPositionVertOrient: {
        type: ControlType.Boolean,
        title: "↳ Orientation",
        enabledTitle: "horizontal",
        disabledTitle: "vertical",
        hidden(props) {
          return props.showLabel === false || props.orient === true;
        },
      },
      labelPositionVert: {
        type: ControlType.Enum,
        title: " ↳ Position",
        options: ["top", "center", "bottom"],
        optionTitles: ["top", "center", "bottom"],
        displaySegmentedControl: true,
        hidden(props) {
          return props.showLabel === false || props.orient === true;
        },
      },
      labelPositionVertAlign: {
        type: ControlType.Enum,
        title: " ↳ Align",
        options: ["left", "center", "right"],
        optionTitles: ["left", "center", "right"],
        displaySegmentedControl: true,
        hidden(props) {
          return (
            props.showLabel === false ||
            props.orient === true ||
            props.labelPositionVertOrient === false
          );
        },
      },
      labelOffsetX: {
        type: ControlType.Number,
        title: "↳ X Offset",
        min: -500,
        max: 500,
        hidden(props) {
          return props.showLabel === false;
        },
      },
      labelOffsetY: {
        type: ControlType.Number,
        title: "↳ Y Offset",
        min: -500,
        max: 500,
        hidden(props) {
          return props.showLabel === false;
        },
      },
      labelColor: {
        title: "↳ Color",
        type: ControlType.Color,
        hidden(props) {
          return props.showLabel === false;
        },
      },
      labelFontSize: {
        type: ControlType.Number,
        title: "↳ Size",
        hidden(props) {
          return props.showLabel === false;
        },
      },
      labelWrap: {
        type: ControlType.Boolean,
        title: "↳ Wrap",
        enabledTitle: "yes",
        disabledTitle: "no",
        hidden(props) {
          return props.showLabel === false;
        },
      },
      labelWidth: {
        type: ControlType.Number,
        title: " ↳ Width",
        min: 0,
        hidden(props) {
          return props.showLabel === false || props.labelWrap === false;
        },
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  };
};

export const presetRefDots = (dataKey, useLayout) => {
  return {
    type: ControlType.Collection,
    title: "Reference dot",
    isHeaderControls: true,
    isGroupsColorLegend: false,
    propertyControl: {
      xCoordinateNumber: {
        type: ControlType.Number,
        title: "X coordinate",
        // use value from reference
        useRef(props) {
          return useLayout ? !props.chartSettings.chartLayout : false;
        },
        refName: "yCoordinateNumber",
        hidden(props, ui) {
          return getXAxisDataType(ui, dataKey, useLayout) !== "number";
        },
      },
      xCoordinateCategory: {
        type: ControlType.Enum,
        title: "X coordinate",
        options(props) {
          return getCategories(props, "X", useLayout);
        },
        optionTitles(props) {
          return getCategories(props, "X", useLayout);
        },
        placeholder: "Choose category",
        // use value from reference
        useRef(props) {
          return useLayout ? !props.chartSettings.chartLayout : false;
        },
        refName: "yCoordinateCategory",
        hidden(props, ui) {
          return getXAxisDataType(ui, dataKey, useLayout) === "number";
        },
      },
      yCoordinateNumber: {
        type: ControlType.Number,
        title: "Y coordinate",
        // use value from reference
        useRef(props) {
          return useLayout ? !props.chartSettings.chartLayout : false;
        },
        refName: "xCoordinateNumber",
        hidden(props, ui) {
          return getYAxisDataType(ui, dataKey, useLayout) !== "number";
        },
      },
      yCoordinateCategory: {
        type: ControlType.Enum,
        title: "Y coordinate",
        options(props) {
          return getCategories(props, "Y", useLayout);
        },
        optionTitles(props) {
          return getCategories(props, "Y", useLayout);
        },
        placeholder: "Choose category",
        // use value from reference
        useRef(props) {
          return useLayout ? !props.chartSettings.chartLayout : false;
        },
        refName: "xCoordinateCategory",
        hidden(props, ui) {
          return getYAxisDataType(ui, dataKey, useLayout) === "number";
        },
      },
      dotSize: {
        type: ControlType.Number,
        title: "Size",
        min: 0,
        max: 500,
        displayStepper: true,
      },
      dotColor: {
        title: "Color",
        type: ControlType.Color,
      },
      dotStyle: {
        type: ControlType.Boolean,
        title: "Style",
        enabledTitle: "fill",
        disabledTitle: "stroke",
      },
      dotStrokeWidth: {
        type: ControlType.Number,
        title: "↳ Stroke",
        min: 0,
        max: 100,
        displayStepper: true,
        hidden(props) {
          return props.dotStyle === true;
        },
      },
      showLabel: {
        type: ControlType.Boolean,
        title: "Label",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      name: {
        title: "↳ Text",
        type: ControlType.Text,
        placeholder: "Dot's values or note",
        hidden(props) {
          return props.showLabel === false;
        },
      },
      labelPosition: {
        type: ControlType.Enum,
        title: "↳ Position",
        options: ["T", "B", "C", "L", "R"],
        optionTitles: ["T", "B", "C", "L", "R"],
        displaySegmentedControl: true,
        hidden(props) {
          return props.showLabel === false;
        },
      },
      labelOffsetX: {
        type: ControlType.Number,
        title: "↳ X Offset",
        min: -500,
        max: 500,
        hidden(props) {
          return props.showLabel === false;
        },
      },
      labelOffsetY: {
        type: ControlType.Number,
        title: "↳ Y Offset",
        min: -500,
        max: 500,
        hidden(props) {
          return props.showLabel === false;
        },
      },
      labelColor: {
        title: "↳ Color",
        type: ControlType.Color,
        hidden(props) {
          return props.showLabel === false;
        },
      },
      labelFontSize: {
        type: ControlType.Number,
        title: "↳ Size",
        hidden(props) {
          return props.showLabel === false;
        },
      },
      labelWrap: {
        type: ControlType.Boolean,
        title: "↳ Wrap",
        enabledTitle: "yes",
        disabledTitle: "no",
        hidden(props) {
          return props.showLabel === false;
        },
      },
      labelWidth: {
        type: ControlType.Number,
        title: " ↳ Width",
        min: 0,
        hidden(props) {
          return props.showLabel === false || props.labelWrap === false;
        },
      },
      showLabelLine: {
        type: ControlType.Boolean,
        title: "↳ Line",
        enabledTitle: "show",
        disabledTitle: "hide",
        hidden(props) {
          return props.showLabel === false;
        },
      },
      labelLineColor: {
        title: " ↳ Color",
        type: ControlType.Color,
        hidden(props) {
          return props.showLabel === false || props.showLabelLine === false;
        },
      },
      showLabelImage: {
        type: ControlType.Boolean,
        title: "↳ Image",
        enabledTitle: "show",
        disabledTitle: "hide",
        hidden(props) {
          return props.showLabel === false;
        },
      },
      labelImgUrl: {
        title: " ↳ Url",
        type: ControlType.String,
        hidden(props) {
          return props.showLabel === false || props.showLabelImage === false;
        },
      },
      labelImgPosition: {
        type: ControlType.Boolean,
        title: " ↳ Layout",
        enabledTitle: "vertical",
        disabledTitle: "horizontal",
        hidden(props) {
          return props.showLabel === false || props.showLabelImage === false;
        },
      },
      labelImgCustomSize: {
        title: " ↳ Size",
        type: ControlType.Boolean,
        enabledTitle: "custom",
        disabledTitle: "original",
        hidden(props) {
          return props.showLabel === false || props.showLabelImage === false;
        },
      },
      labelImgWidth: {
        title: "  ↳ Width",
        type: ControlType.Number,
        hidden(props) {
          return (
            props.showLabel === false ||
            props.showLabelImage === false ||
            props.labelImgCustomSize === false
          );
        },
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  };
};

export const presetLayout = {
  type: ControlType.Group,
  title: "Layout settings",
  isHeaderControls: false,
  propertyControl: {
    // Size
    isFullscreen: {
      type: ControlType.Boolean,
      title: "Size",
      enabledTitle: "fullscreen",
      disabledTitle: "custom",
    },
    isWidthAuto: {
      type: ControlType.Boolean,
      title: "↳ Width",
      enabledTitle: "100%",
      disabledTitle: "fixed",
      hidden(props) {
        return props.isFullscreen === true;
      },
    },
    width: {
      type: ControlType.Number,
      title: " ↳ Value",
      min: 0,
      max: 5000,
      hidden(props) {
        return props.isWidthAuto === true || props.isFullscreen === true;
      },
    },
    minWidth: {
      type: ControlType.Number,
      title: " ↳ Min width",
      min: 0,
      max: 5000,
      hidden(props) {
        return props.isWidthAuto === false;
      },
    },
    maxWidth: {
      type: ControlType.Number,
      title: " ↳ Max width",
      min: 0,
      max: 5000,
      hidden(props) {
        return props.isWidthAuto === false;
      },
    },
    isHeightAuto: {
      type: ControlType.Boolean,
      title: "↳ Height",
      enabledTitle: "100%",
      disabledTitle: "fixed",
      hidden(props) {
        return props.isFullscreen === true;
      },
    },
    height: {
      type: ControlType.Number,
      title: " ↳ Value",
      min: 0,
      max: 2000,
      hidden(props) {
        return props.isHeightAuto === true || props.isFullscreen === true;
      },
    },
    // Background
    background: {
      type: ControlType.Color,
      title: "Background",
    },
  },
  hidden(props) {
    return props.isData === true;
  },
};

// X axis
export const presetXAxis = (dataKey, useLayout) => {
  return {
    type: ControlType.Group,
    title: "X axis",
    isHeaderControls: true,
    propertyControl: {
      isVisible: {
        type: ControlType.Boolean,
        enabledTitle: "show",
        disabledTitle: "hide",
        hidden(props) {
          return true;
        },
      },
      // type: {
      //   type: ControlType.Enum,
      //   title: "Type",
      //   options: ["number", "string"],
      //   optionTitles: ["number", "category"],
      //   displaySegmentedControl: true,
      // },
      // type: {
      //   type: ControlType.Status,
      //   title: "Type",
      //   hidden(props) {
      //     return true;
      //   },
      // },
      color: {
        type: ControlType.Color,
        title: "Color",
      },
      height: {
        type: ControlType.Number,
        title: "Height",
      },
      orient: {
        type: ControlType.Enum,
        title: "Orient",
        options: ["bottom", "top"],
        optionTitles: ["bottom", "top"],
        displaySegmentedControl: true,
      },
      isAxisLine: {
        type: ControlType.Boolean,
        title: "Line",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      domain: {
        type: ControlType.Boolean,
        title: "Domain",
        enabledTitle: "0—max",
        disabledTitle: "custom",
        hidden(props, ui) {
          return getXAxisDataType(ui, dataKey, useLayout) === "string";
        },
      },
      domainMin: {
        type: ControlType.Enum,
        title: "↳ Min",
        options: ["dataMin", 0, "custom"],
        optionTitles: ["min", 0, "custom"],
        hidden(props, ui) {
          return (
            getXAxisDataType(ui, dataKey, useLayout) === "string" ||
            props.domain === true
          );
        },
      },
      domainMinValue: {
        type: ControlType.Number,
        title: " ↳ Value",
        hidden(props, ui) {
          return (
            getXAxisDataType(ui, dataKey, useLayout) === "string" ||
            props.domain === true ||
            props.domainMin !== "custom"
          );
        },
      },
      domainMax: {
        type: ControlType.Enum,
        title: "↳ Max",
        options: ["dataMax", "custom"],
        optionTitles: ["max", "custom"],
        hidden(props, ui) {
          return (
            getXAxisDataType(ui, dataKey, useLayout) === "string" ||
            props.domain === true
          );
        },
      },
      domainMaxValue: {
        type: ControlType.Number,
        title: " ↳ Value",
        hidden(props, ui) {
          return (
            getXAxisDataType(ui, dataKey, useLayout) === "string" ||
            props.domain === true ||
            props.domainMax !== "custom"
          );
        },
      },
      isTicksAuto: {
        type: ControlType.Boolean,
        title: "Ticks",
        enabledTitle: "auto",
        disabledTitle: "custom",
      },
      tickCountValue: {
        type: ControlType.Number,
        title: "↳ Count",
        min: 0,
        hidden(props, ui) {
          return (
            getXAxisDataType(ui, dataKey, useLayout) === "string" ||
            props.isTicksAuto === true
          );
        },
      },
      tickCountAccuracy: {
        type: ControlType.Boolean,
        title: " ↳ Accuracy",
        enabledTitle: "precisely",
        disabledTitle: "circa",
        hidden(props, ui) {
          return (
            getXAxisDataType(ui, dataKey, useLayout) === "string" ||
            props.isTicksAuto === true
          );
        },
      },
      tickCountCategory: {
        type: ControlType.Boolean,
        title: "↳ Count",
        enabledTitle: "all",
        disabledTitle: "interval",
        hidden(props, ui) {
          return (
            getXAxisDataType(ui, dataKey, useLayout) === "number" ||
            props.isTicksAuto === true
          );
        },
      },
      tickCountValueCategory: {
        type: ControlType.Number,
        title: " ↳ Skip",
        min: 0,
        hidden(props, ui) {
          return (
            getXAxisDataType(ui, dataKey, useLayout) === "number" ||
            props.tickCountCategory === true ||
            props.isTicksAuto === true
          );
        },
      },
      tickFontSize: {
        type: ControlType.Number,
        title: "↳ Font size",
        min: 0,
        hidden(props, ui) {
          return props.isTicksAuto === true;
        },
      },
      tickRotate: {
        type: ControlType.Enum,
        title: "↳ Rotate",
        options: [-90, 0, 90],
        optionTitles: ["-90°", "0°", "90°"],
        displaySegmentedControl: true,
        hidden(props, ui) {
          return props.isTicksAuto === true;
        },
      },
      tickSize: {
        type: ControlType.Number,
        title: "↳ Size",
        min: 0,
        hidden(props, ui) {
          return props.isTicksAuto === true;
        },
      },
      tickMargin: {
        type: ControlType.Number,
        title: "↳ Margin",
        min: 0,
        hidden(props, ui) {
          return props.isTicksAuto === true;
        },
      },
      tickOrder: {
        type: ControlType.Boolean,
        title: "↳ Order",
        enabledTitle: "reversed",
        disabledTitle: "forward",
        hidden(props, ui) {
          return props.isTicksAuto === true;
        },
      },
      isLabelVisible: {
        type: ControlType.Boolean,
        title: "Label",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      labelText: {
        title: "↳ Text",
        type: ControlType.String,
        placeholder: "",
        hidden(props) {
          return props.isLabelVisible === false;
        },
      },
      labelAlign: {
        type: ControlType.Enum,
        title: "↳ Align",
        options: ["left", "middle", "right"],
        optionTitles: ["left", "middle", "right"],
        displaySegmentedControl: true,
        hidden(props) {
          return props.isLabelVisible === false;
        },
      },
      labelMargin: {
        type: ControlType.Number,
        title: "↳ Margin",
        min: 0,
        hidden(props) {
          return props.isLabelVisible === false;
        },
      },
      labelColor: {
        type: ControlType.Color,
        title: "↳ Color",
        hidden(props) {
          return props.isLabelVisible === false;
        },
      },
      labelFontSize: {
        type: ControlType.Number,
        title: "↳ Font size",
        hidden(props) {
          return props.isLabelVisible === false;
        },
      },
      units: {
        type: ControlType.String,
        title: "Units",
        placeholder: "%, Kg, …",
        hidden(props, ui) {
          return getXAxisDataType(ui, dataKey, useLayout) === "string";
        },
      },
      useLocale: {
        type: ControlType.Boolean,
        title: "Locale",
        enabledTitle: "use",
        disabledTitle: "no",
        hidden(props, ui) {
          return getXAxisDataType(ui, dataKey, useLayout) === "string";
        },
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  };
};

// Y axis
export const presetYAxis = (dataKey, useLayout) => {
  return {
    type: ControlType.Group,
    title: "Y axis",
    isHeaderControls: true,
    propertyControl: {
      isVisible: {
        type: ControlType.Boolean,
        enabledTitle: "show",
        disabledTitle: "hide",
        hidden(props) {
          return true;
        },
      },
      // type: {
      //   type: ControlType.Enum,
      //   title: "Type",
      //   options: ["number", "string"],
      //   optionTitles: ["number", "category"],
      //   displaySegmentedControl: true,
      // },
      // type: {
      //   type: ControlType.Status,
      //   title: "Type",
      //   hidden(props) {
      //     return true;
      //   },
      // },
      color: {
        type: ControlType.Color,
        title: "Color",
      },
      width: {
        type: ControlType.Number,
        title: "Width",
      },
      orient: {
        type: ControlType.Enum,
        title: "Orient",
        options: ["left", "right"],
        optionTitles: ["left", "right"],
        displaySegmentedControl: true,
      },
      isAxisLine: {
        type: ControlType.Boolean,
        title: "Line",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      domain: {
        type: ControlType.Boolean,
        title: "Domain",
        enabledTitle: "0—max",
        disabledTitle: "custom",
        hidden(props, ui) {
          return getYAxisDataType(ui, dataKey, useLayout) === "string";
        },
      },
      domainMin: {
        type: ControlType.Enum,
        title: "↳ Min",
        options: ["dataMin", 0, "custom"],
        optionTitles: ["min", 0, "custom"],
        hidden(props, ui) {
          return (
            getYAxisDataType(ui, dataKey, useLayout) === "string" ||
            props.domain === true
          );
        },
      },
      domainMinValue: {
        type: ControlType.Number,
        title: " ↳ Value",
        hidden(props, ui) {
          return (
            getYAxisDataType(ui, dataKey, useLayout) === "string" ||
            props.domain === true ||
            props.domainMin !== "custom"
          );
        },
      },
      domainMax: {
        type: ControlType.Enum,
        title: "↳ Max",
        options: ["dataMax", "custom"],
        optionTitles: ["max", "custom"],
        hidden(props, ui) {
          return (
            getYAxisDataType(ui, dataKey, useLayout) === "string" ||
            props.domain === true
          );
        },
      },
      domainMaxValue: {
        type: ControlType.Number,
        title: " ↳ Value",
        hidden(props, ui) {
          return (
            getYAxisDataType(ui, dataKey, useLayout) === "string" ||
            props.domain === true ||
            props.domainMax !== "custom"
          );
        },
      },
      isTicksAuto: {
        type: ControlType.Boolean,
        title: "Ticks",
        enabledTitle: "auto",
        disabledTitle: "custom",
      },
      tickCountValue: {
        type: ControlType.Number,
        title: "↳ Count",
        min: 0,
        hidden(props, ui) {
          return (
            getYAxisDataType(ui, dataKey, useLayout) === "string" ||
            props.isTicksAuto === true
          );
        },
      },
      tickCountAccuracy: {
        type: ControlType.Boolean,
        title: " ↳ Accuracy",
        enabledTitle: "precisely",
        disabledTitle: "circa",
        hidden(props, ui) {
          return (
            getYAxisDataType(ui, dataKey, useLayout) === "string" ||
            props.isTicksAuto === true
          );
        },
      },
      tickCountCategory: {
        type: ControlType.Boolean,
        title: "↳ Count",
        enabledTitle: "all",
        disabledTitle: "interval",
        hidden(props, ui) {
          return (
            getYAxisDataType(ui, dataKey, useLayout) === "number" ||
            props.isTicksAuto === true
          );
        },
      },
      tickCountValueCategory: {
        type: ControlType.Number,
        title: " ↳ Skip",
        min: 0,
        hidden(props, ui) {
          return (
            getYAxisDataType(ui, dataKey, useLayout) === "number" ||
            props.tickCountCategory === true ||
            props.isTicksAuto === true
          );
        },
      },
      tickFontSize: {
        type: ControlType.Number,
        title: "↳ Font size",
        min: 0,
        hidden(props, ui) {
          return props.isTicksAuto === true;
        },
      },
      tickSize: {
        type: ControlType.Number,
        title: "↳ Size",
        min: 0,
        hidden(props, ui) {
          return props.isTicksAuto === true;
        },
      },
      tickMargin: {
        type: ControlType.Number,
        title: "↳ Margin",
        min: 0,
        hidden(props, ui) {
          return props.isTicksAuto === true;
        },
      },
      tickOrder: {
        type: ControlType.Boolean,
        title: "↳ Order",
        enabledTitle: "reversed",
        disabledTitle: "forward",
        hidden(props, ui) {
          return props.isTicksAuto === true;
        },
      },
      isLabelVisible: {
        type: ControlType.Boolean,
        title: "Label",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      labelText: {
        title: "↳ Text",
        type: ControlType.String,
        placeholder: "",
        hidden(props) {
          return props.isLabelVisible === false;
        },
      },
      labelAlign: {
        type: ControlType.Enum,
        title: "↳ Align",
        options: ["left", "middle", "right"],
        optionTitles: ["left", "middle", "right"],
        displaySegmentedControl: true,
        hidden(props) {
          return props.isLabelVisible === false;
        },
      },
      labelMargin: {
        type: ControlType.Number,
        title: "↳ Margin",
        min: 0,
        hidden(props) {
          return props.isLabelVisible === false;
        },
      },
      labelColor: {
        type: ControlType.Color,
        title: "↳ Color",
        hidden(props) {
          return props.isLabelVisible === false;
        },
      },
      labelFontSize: {
        type: ControlType.Number,
        title: "↳ Font size",
        hidden(props) {
          return props.isLabelVisible === false;
        },
      },
      labelRotate: {
        type: ControlType.Enum,
        title: "↳ Rotate",
        options: [-90, 90],
        optionTitles: ["-90°", "90°"],
        displaySegmentedControl: true,
        hidden(props) {
          return props.isLabelVisible === false;
        },
      },
      units: {
        type: ControlType.String,
        title: "Units",
        placeholder: "%, Kg, …",
        hidden(props, ui) {
          return getYAxisDataType(ui, dataKey, useLayout) === "string";
        },
      },
      useLocale: {
        type: ControlType.Boolean,
        title: "Locale",
        enabledTitle: "use",
        disabledTitle: "no",
        hidden(props, ui) {
          return getYAxisDataType(ui, dataKey, useLayout) === "string";
        },
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  };
};

export const presetPolarAngleAxis = (dataKey, useLayout) => {
  return {
    type: ControlType.Group,
    title: "X axis",
    isHeaderControls: true,
    propertyControl: {
      isVisible: {
        type: ControlType.Boolean,
        enabledTitle: "show",
        disabledTitle: "hide",
        hidden(props) {
          return true;
        },
      },
      color: {
        type: ControlType.Color,
        title: "Color",
      },
      isAxisLine: {
        type: ControlType.Boolean,
        title: "Line",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      domain: {
        type: ControlType.Boolean,
        title: "Domain",
        enabledTitle: "0—max",
        disabledTitle: "custom",
        hidden(props, ui) {
          return getXAxisDataType(ui, dataKey, useLayout) === "string";
        },
      },
      domainMin: {
        type: ControlType.Enum,
        title: "↳ Min",
        options: ["dataMin", 0, "custom"],
        optionTitles: ["min", 0, "custom"],
        hidden(props, ui) {
          return (
            getXAxisDataType(ui, dataKey, useLayout) === "string" ||
            props.domain === true
          );
        },
      },
      domainMinValue: {
        type: ControlType.Number,
        title: " ↳ Value",
        hidden(props, ui) {
          return (
            getXAxisDataType(ui, dataKey, useLayout) === "string" ||
            props.domain === true ||
            props.domainMin !== "custom"
          );
        },
      },
      domainMax: {
        type: ControlType.Enum,
        title: "↳ Max",
        options: ["dataMax", "custom"],
        optionTitles: ["max", "custom"],
        hidden(props, ui) {
          return (
            getXAxisDataType(ui, dataKey, useLayout) === "string" ||
            props.domain === true
          );
        },
      },
      domainMaxValue: {
        type: ControlType.Number,
        title: " ↳ Value",
        hidden(props, ui) {
          return (
            getXAxisDataType(ui, dataKey, useLayout) === "string" ||
            props.domain === true ||
            props.domainMax !== "custom"
          );
        },
      },
      tickCount: {
        type: ControlType.Boolean,
        title: "Tick count",
        enabledTitle: "auto",
        disabledTitle: "custom",
        hidden(props, ui) {
          return getXAxisDataType(ui, dataKey, useLayout) === "string";
        },
      },
      tickCountValue: {
        type: ControlType.Number,
        title: "↳ Ticks",
        min: 0,
        hidden(props, ui) {
          return (
            getXAxisDataType(ui, dataKey, useLayout) === "string" ||
            props.tickCount === true
          );
        },
      },
      // tickCountCategory: {
      //   type: ControlType.Boolean,
      //   title: "Tick count",
      //   enabledTitle: "all",
      //   disabledTitle: "interval",
      //   hidden(props, ui) {
      //     return getXAxisDataType(ui, dataKey, useLayout) === "number";
      //   },
      // },
      // tickCountValueCategory: {
      //   type: ControlType.Number,
      //   title: "↳ Skip",
      //   min: 0,
      //   hidden(props, ui) {
      //     return (
      //       getXAxisDataType(ui, dataKey, useLayout) === "number" ||
      //       props.tickCountCategory === true
      //     );
      //   },
      // },
      showTicks: {
        type: ControlType.Boolean,
        title: "Ticks",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      tickFontSize: {
        type: ControlType.Number,
        title: "↳ Font size",
        min: 0,
        hidden(props) {
          return props.showTicks === false;
        },
      },
      tickRotate: {
        type: ControlType.Enum,
        title: "↳ Orient",
        options: ["horiz", "along", "across"],
        optionTitles: ["horiz", "along", "across"],
        displaySegmentedControl: true,
        hidden(props) {
          return props.showTicks === false;
        },
      },
      tickSize: {
        type: ControlType.Number,
        title: "↳ Size",
        min: 0,
        hidden(props) {
          return props.showTicks === false;
        },
      },
      tickMargin: {
        type: ControlType.Number,
        title: "↳ Margin",
        min: 0,
        hidden(props) {
          return props.showTicks === false;
        },
      },
      units: {
        type: ControlType.String,
        title: "Units",
        placeholder: "%, Kg, …",
        hidden(props, ui) {
          return getXAxisDataType(ui, dataKey, useLayout) === "string";
        },
      },
      useLocale: {
        type: ControlType.Boolean,
        title: "Locale",
        enabledTitle: "use",
        disabledTitle: "no",
        hidden(props, ui) {
          return getXAxisDataType(ui, dataKey, useLayout) === "string";
        },
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  };
};

export const presetPolarRadiusAxis = (dataKey, useLayout) => {
  return {
    type: ControlType.Group,
    title: "Y axis",
    isHeaderControls: true,
    propertyControl: {
      isVisible: {
        type: ControlType.Boolean,
        enabledTitle: "show",
        disabledTitle: "hide",
        hidden(props) {
          return true;
        },
      },
      angle: {
        type: ControlType.Number,
        title: "Angle",
        min: 0,
        max: 360,
      },
      color: {
        type: ControlType.Color,
        title: "Color",
      },
      isAxisLine: {
        type: ControlType.Boolean,
        title: "Line",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      domain: {
        type: ControlType.Boolean,
        title: "Domain",
        enabledTitle: "0—max",
        disabledTitle: "custom",
        hidden(props, ui) {
          return getYAxisDataType(ui, dataKey, useLayout) === "string";
        },
      },
      domainMin: {
        type: ControlType.Enum,
        title: "↳ Min",
        options: ["dataMin", 0, "custom"],
        optionTitles: ["min", 0, "custom"],
        hidden(props, ui) {
          return (
            getYAxisDataType(ui, dataKey, useLayout) === "string" ||
            props.domain === true
          );
        },
      },
      domainMinValue: {
        type: ControlType.Number,
        title: " ↳ Value",
        hidden(props, ui) {
          return (
            getYAxisDataType(ui, dataKey, useLayout) === "string" ||
            props.domain === true ||
            props.domainMin !== "custom"
          );
        },
      },
      domainMax: {
        type: ControlType.Enum,
        title: "↳ Max",
        options: ["dataMax", "custom"],
        optionTitles: ["max", "custom"],
        hidden(props, ui) {
          return (
            getYAxisDataType(ui, dataKey, useLayout) === "string" ||
            props.domain === true
          );
        },
      },
      domainMaxValue: {
        type: ControlType.Number,
        title: " ↳ Value",
        hidden(props, ui) {
          return (
            getYAxisDataType(ui, dataKey, useLayout) === "string" ||
            props.domain === true ||
            props.domainMax !== "custom"
          );
        },
      },
      tickCount: {
        type: ControlType.Boolean,
        title: "Tick count",
        enabledTitle: "auto",
        disabledTitle: "custom",
        hidden(props, ui) {
          return getYAxisDataType(ui, dataKey, useLayout) === "string";
        },
      },
      tickCountValue: {
        type: ControlType.Number,
        title: "↳ Ticks",
        min: 0,
        hidden(props, ui) {
          return (
            getYAxisDataType(ui, dataKey, useLayout) === "string" ||
            props.tickCount === true
          );
        },
      },
      tickCountCategory: {
        type: ControlType.Boolean,
        title: "Tick count",
        enabledTitle: "all",
        disabledTitle: "interval",
        hidden(props, ui) {
          return getYAxisDataType(ui, dataKey, useLayout) === "number";
        },
      },
      tickCountValueCategory: {
        type: ControlType.Number,
        title: "↳ Skip",
        min: 0,
        hidden(props, ui) {
          return (
            getYAxisDataType(ui, dataKey, useLayout) === "number" ||
            props.tickCountCategory === true
          );
        },
      },
      tickFontSize: {
        type: ControlType.Number,
        title: "↳ Font size",
        min: 0,
      },
      orient: {
        type: ControlType.Enum,
        title: "↳ Orient",
        options: ["left", "middle", "right"],
        optionTitles: ["left", "middle", "right"],
        displaySegmentedControl: true,
      },
      tickMargin: {
        type: ControlType.Number,
        title: "↳ Margin",
        min: 0,
        hidden(props) {
          return props.orient === "middle";
        },
      },
      units: {
        type: ControlType.String,
        title: "Units",
        placeholder: "%, Kg, …",
        hidden(props, ui) {
          return getYAxisDataType(ui, dataKey, useLayout) === "string";
        },
      },
      useLocale: {
        type: ControlType.Boolean,
        title: "Locale",
        enabledTitle: "use",
        disabledTitle: "no",
        hidden(props, ui) {
          return getYAxisDataType(ui, dataKey, useLayout) === "string";
        },
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  };
};

//Grid
export const presetGrid = {
  type: ControlType.Group,
  title: "Grid",
  isHeaderControls: false,
  propertyControl: {
    gridHoriz: {
      type: ControlType.Boolean,
      title: "Grid H",
      enabledTitle: "show",
      disabledTitle: "hide",
    },
    gridVert: {
      type: ControlType.Boolean,
      title: "Grid V",
      enabledTitle: "show",
      disabledTitle: "hide",
    },
    gridColor: {
      title: "Grid color",
      type: ControlType.Color,
      hidden(props) {
        return (props.gridVert || props.gridHoriz) === false;
      },
    },
  },
  hidden(props) {
    return props.isData === true;
  },
};

export const presetLegend = {
  type: ControlType.Group,
  title: "Legend",
  isHeaderControls: true,
  propertyControl: {
    isVisible: {
      type: ControlType.Boolean,
      enabledTitle: "show",
      disabledTitle: "hide",
      hidden(props) {
        return true;
      },
    },
    txtColor: {
      title: "Text color",
      type: ControlType.Color,
    },
    fontSize: {
      type: ControlType.Number,
      title: "Font size",
      min: 0,
      max: 100,
      displayStepper: false,
    },
    iconSize: {
      type: ControlType.Number,
      title: "Icon size",
      min: 0,
      max: 100,
      displayStepper: false,
    },
    layout: {
      type: ControlType.Boolean,
      title: "Layout",
      enabledTitle: "horizontal",
      disabledTitle: "vertical",
    },
    align: {
      type: ControlType.Enum,
      title: "Align",
      options: ["left", "center", "right"],
      optionTitles: ["left", "center", "right"],
      displaySegmentedControl: true,
    },
    margin: {
      type: ControlType.FusedNumber,
      title: "Margin",
      toggleKey: "isPerSide",
      toggleTitles: ["All", "Individual"],
      valueKeys: ["top", "right", "bottom", "left"],
      valueLabels: ["T", "R", "B", "L"],
      min: 0,
    },
  },
  hidden(props) {
    return props.isData === true;
  },
};

export const presetTitle = {
  type: ControlType.Group,
  title: "Title",
  isHeaderControls: true,
  propertyControl: {
    isVisible: {
      type: ControlType.Boolean,
      enabledTitle: "show",
      disabledTitle: "hide",
      hidden(props) {
        return true;
      },
    },
    text: {
      title: "Title",
      type: ControlType.String,
      placeholder: "",
    },
    txtColor: {
      title: "Text color",
      type: ControlType.Color,
    },
    fontSize: {
      type: ControlType.Number,
      title: "Font size",
      min: 0,
      max: 500,
      displayStepper: false,
    },
    //hack - cannot dinamic update variants by choosing font family,
    //so get variants in inputEnum
    fontFamily: {
      type: ControlType.ComboBox,
      title: "Font",
      options: [],
    },
    //hack - cannot dinamic update variants by choosing font family,
    //so get variants in inputEnum
    fontVariants: {
      type: ControlType.Enum,
      title: "Variants",
      options: [],
    },
    align: {
      type: ControlType.Enum,
      title: "Align",
      options: ["left", "center", "right"],
      optionTitles: ["left", "center", "right"],
      displaySegmentedControl: true,
    },
    margin: {
      type: ControlType.FusedNumber,
      title: "Margin",
      toggleKey: "isPerSide",
      toggleTitles: ["All", "Individual"],
      valueKeys: ["top", "right", "bottom", "left"],
      valueLabels: ["T", "R", "B", "L"],
      min: 0,
    },
  },
  hidden(props) {
    return props.isData === true;
  },
};

export const presetCaption = {
  type: ControlType.Collection,
  title: "Caption",
  isHeaderControls: true,
  isGroupsColorLegend: false,
  propertyControl: {
    text: {
      title: "Caption",
      type: ControlType.Text,
      placeholder: "",
    },
    txtColor: {
      title: "Text color",
      type: ControlType.Color,
    },
    fontSize: {
      type: ControlType.Number,
      title: "Font size",
      min: 0,
      max: 500,
      displayStepper: false,
    },
    //hack - cannot dinamic update variants by choosing font family,
    //so get variants in inputEnum
    fontFamily: {
      type: ControlType.ComboBox,
      title: "Font",
      options: [],
    },
    //hack - cannot dinamic update variants by choosing font family,
    //so get variants in inputEnum
    fontVariants: {
      type: ControlType.Enum,
      title: "Variants",
      options: [],
    },
    align: {
      type: ControlType.Enum,
      title: "Align",
      options: ["left", "center", "right"],
      optionTitles: ["left", "center", "right"],
      displaySegmentedControl: true,
    },
    margin: {
      type: ControlType.FusedNumber,
      title: "Margin",
      toggleKey: "isPerSide",
      toggleTitles: ["All", "Individual"],
      valueKeys: ["top", "right", "bottom", "left"],
      valueLabels: ["T", "R", "B", "L"],
      min: 0,
    },
  },
  hidden(props) {
    return props.isData === true;
  },
};

export const presetTooltip = (isCursor: boolean = false) => {
  return {
    type: ControlType.Group,
    title: "Tooltip",
    isHeaderControls: true,
    propertyControl: {
      isVisible: {
        type: ControlType.Boolean,
        enabledTitle: "show",
        disabledTitle: "hide",
        hidden(props) {
          return true;
        },
      },
      txtColor: {
        title: "Text color",
        type: ControlType.Color,
      },
      bgColor: {
        title: "Background",
        type: ControlType.Color,
      },
      ...(isCursor && {
        showCursor: {
          type: ControlType.Boolean,
          title: "Cursor",
          enabledTitle: "show",
          disabledTitle: "hide",
        },
        cursorColor: {
          title: "↳ Color",
          type: ControlType.Color,
          hidden(props) {
            return props.showCursor === false;
          },
        },
      }),
    },
    hidden(props) {
      return props.isData === true;
    },
  };
};

//Dots
export const presetDots = {
  showDots: {
    type: ControlType.Boolean,
    title: "Dots",
    enabledTitle: "show",
    disabledTitle: "hide",
  },
  dotsType: {
    type: ControlType.Enum,
    title: "↳ Type",
    options: ["circle", "square", "rhombus", "cross", "star", "tick"],
    hidden(props) {
      return props.showDots === false;
    },
  },
  dotsSize: {
    type: ControlType.Number,
    title: "↳ Size",
    min: 0,
    max: 500,
    displayStepper: true,
    hidden(props) {
      return props.showDots === false;
    },
  },
  dotsColor: {
    title: "↳ Color",
    type: ControlType.Color,
    hidden(props) {
      return props.showDots === false;
    },
  },
  dotsStyle: {
    type: ControlType.Boolean,
    title: "↳ Style",
    enabledTitle: "fill",
    disabledTitle: "stroke",
    hidden(props) {
      return props.showDots === false;
    },
  },
  dotsStrokeWidth: {
    type: ControlType.Number,
    title: "　↳ Stroke",
    min: 0,
    max: 100,
    displayStepper: true,
    hidden(props) {
      return props.showDots === false || props.dotsStyle === true;
    },
  },
};

//Labels settings
export const presetDotsLabels = {
  showLabels: {
    type: ControlType.Boolean,
    title: "Labels",
    enabledTitle: "show",
    disabledTitle: "hide",
  },
  labelsPosition: {
    type: ControlType.Enum,
    title: "↳ Position",
    options: ["T", "B", "C", "L", "R"],
    optionTitles: ["T", "B", "C", "L", "R"],
    displaySegmentedControl: true,
    hidden(props) {
      return props.showLabels === false;
    },
  },
  labelsColor: {
    title: "↳ Color",
    type: ControlType.Color,
    hidden(props) {
      return props.showLabels === false;
    },
  },
  labelsFontSize: {
    type: ControlType.Number,
    title: "↳ Size",
    hidden(props) {
      return props.showLabels === false;
    },
  },
};

// Line ans areas titles
export const presetSeriesTitles = {
  showTitle: {
    type: ControlType.Boolean,
    title: "Title",
    enabledTitle: "show",
    disabledTitle: "hide",
  },
  titleColor: {
    title: "↳ Color",
    type: ControlType.Color,
    hidden(props) {
      return props.showTitle === false;
    },
  },
  titleFontSize: {
    type: ControlType.Number,
    title: "↳ Size",
    hidden(props) {
      return props.showTitle === false;
    },
  },
  showTitleDot: {
    type: ControlType.Boolean,
    title: "↳ Dot",
    enabledTitle: "show",
    disabledTitle: "hide",
    hidden(props) {
      return props.showTitle === false;
    },
  },
  titleDotColor: {
    title: " ↳ Color",
    type: ControlType.Color,
    hidden(props) {
      return props.showTitle === false || props.showTitleDot === false;
    },
  },
  titleDotSize: {
    type: ControlType.Number,
    title: " ↳ Size",
    hidden(props) {
      return props.showTitle === false || props.showTitleDot === false;
    },
  },
};
