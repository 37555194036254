import React from "react";
import { useDispatch } from "react-redux";
import { Grid, Button, Typography } from "@mui/material";
import { UPDATE_DATA_TYPE_TO_NUMBER } from "../utils/actions";
import "../../App.css";

export function ConvertDataAlert(props) {
  const { handleClose, popupProps } = props;
  const dispatch = useDispatch();

  const handleAction = () => {
    dispatch(UPDATE_DATA_TYPE_TO_NUMBER(popupProps));
    handleClose();
  };

  return (
    <Grid container spacing={2} style={{ marginBottom: 8 }}>
      <Grid item style={{ width: "100%" }}>
        <Typography>
          Changing axis type from &laquo;category&raquo; to &laquo;number&raquo;
          may result in data loss due to conversion.
        </Typography>
      </Grid>
      <Grid container spacing={2} alignItems="center" style={{ margin: 0 }}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disableElevation
            onClick={handleAction}
          >
            Continue
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            disableElevation
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ConvertDataAlert;
