import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { UPDATE_CHART_REF } from "../utils/actions";
import { ControlType, applyPropertyControls } from "../ui/controltype";
import {
  Radar as RRadar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  LabelList,
  Tooltip,
} from "recharts";
import { TextBlock } from "./textBlock";
import { CustomizedDot } from "./customDot";
import { CustomizedTooltip } from "./customTooltip";
import { CustomLegend } from "./customLegend";
import { Helmet } from "react-helmet";
import {
  presetLayout,
  presetDots,
  presetDotsLabels,
  presetLegend,
  presetTitle,
  presetCaption,
  presetTooltip,
  presetPolarAngleAxis,
  presetPolarRadiusAxis,
} from "./propertyControlsPresets";
import { CustomizedDotLabel } from "./customLabel";
import {
  getDomains,
  getValidDimensionDataArr,
  normalizeDimensions,
  validateChartData,
  TICKS_COUNT_DEFAULT,
} from "../utils/utils";
import { useHeaderHeight } from "./customHooks";

function getValues(count) {
  return Array.from({ length: count }, () => Math.floor(Math.random() * 100));
}
const radarsCount = 2;
const xCount = 13;
const dimensionsData = { x: "items", y: "areas" }; // link data with dimensions

const CustomizedYAxisTick = (props) => {
  const {
    x,
    y,
    payload,
    fill,
    PolarRadiusAxisAngle,
    units,
    fontSize,
    orientation,
    tickMargin,
    type,
    useLocale,
  } = props;

  const value =
    useLocale && type === "number"
      ? payload.value.toLocaleString()
      : payload.value;

  return (
    <g transform={`translate(${x},${y}) rotate(${90 - PolarRadiusAxisAngle})`}>
      <text
        id={"polarRadiusAxisTick-" + props.index}
        x={
          orientation === "left"
            ? -tickMargin
            : orientation === "right"
            ? tickMargin
            : 0
        }
        y={0}
        textAnchor={
          orientation === "left"
            ? "end"
            : orientation === "right"
            ? "start"
            : "middle"
        }
        fill={fill}
        color={fill}
        fontSize={fontSize}
        alignmentBaseline="central"
      >
        {type === "number" && units ? `${value}${units}` : value}
      </text>
    </g>
  );
};

const CustomizedXAxisTick = (props) => {
  const {
    x,
    y,
    payload,
    fontSize,
    fill,
    angle,
    tickMargin,
    units,
    type,
    useLocale,
  } = props;

  const polarAngle = payload.coordinate;
  const RADIAN = Math.PI / 180;

  const newX = x + tickMargin * Math.cos(polarAngle * RADIAN);
  const newY = y - tickMargin * Math.sin(polarAngle * RADIAN);

  const value =
    useLocale && type === "number"
      ? payload.value.toLocaleString()
      : payload.value;

  return (
    <text
      x={newX}
      y={newY}
      alignmentBaseline={angle === "along" ? "central" : "baseline"}
      fontSize={fontSize}
      textAnchor={angle === "along" ? "start" : "middle"}
      fill={fill}
      color={fill}
      transform={
        angle === "along"
          ? "rotate(" + (0 - polarAngle) + " " + newX + " " + newY + ")"
          : angle === "across"
          ? "rotate(" + (90 - polarAngle) + " " + newX + " " + newY + ")"
          : undefined
      }
    >
      {type === "number" && units ? `${value}${units}` : value}
    </text>
  );
};

export function Radar(props) {
  const {
    layoutSettings,
    radarSettings,
    xAxis,
    yAxis,
    dimensions,
    dataItems,
    dataSeries,
    radars,
    grid,
    legend,
    title,
    caption,
    tooltip,
  } = props;

  const { background, width } = layoutSettings;

  const dataItemsJSON = JSON.stringify(dataItems.items);
  const dataSeriesJSON = JSON.stringify(dataSeries);

  const [data, setData] = React.useState([]);
  const [domain, setDomain] = React.useState({} as any);
  const [dataTypes, setDataTypes] = React.useState({} as any);
  const [isData, setIsData] = React.useState(false);
  const [fontLoaded, setFontLoaded] = React.useState(false);

  const dispatch = useDispatch();

  const chartRef = React.useRef<HTMLDivElement>(null);
  const { headerRef, height } = useHeaderHeight(
    width,
    layoutSettings,
    caption,
    title,
    legend,
    radars.items.map((elem) => elem.name).join("")
  );

  const cx = width / 2 + radarSettings.xOffset;
  const cy = height / 2 + radarSettings.yOffset;

  // custom fonts loading status
  title["fontLoaded"] = fontLoaded;
  for (let i = 0; i < caption.items.length; i++) {
    caption.items[i]["fontLoaded"] = fontLoaded;
  }

  function buildData() {
    // filter unique items bcause recharts generates react component's keys
    // based on ticks degrees, so there might be several components with identical keys
    const itemsFiltered = dataItems.items.filter(
      (elem) => !Number.isNaN(elem) && elem !== null && elem !== ""
    );
    const itemsValid = Array.from(new Set(itemsFiltered));

    const seriesValid = dataSeries.items.map((elem) =>
      getValidDimensionDataArr(elem.areas, dimensions.areas)
    );

    let chartData = [] as any;
    if (seriesValid.length > 0) {
      chartData = itemsValid.map((elem, i) => {
        let values = { name: elem };
        for (let j = 0; j < seriesValid.length; j++) {
          let v = seriesValid[j][i];
          if (dimensions.areas === "number" && v === "") {
            v = NaN;
          }
          values[j] = v;
        }
        return values;
      });
    }

    return chartData;
  }

  useEffect(() => {
    document.fonts.ready.then(() => {
      setFontLoaded(true);
    });
  });

  let isNewData = false;

  useEffect(() => {
    const dataBuild = buildData();
    const chartDimensions = normalizeDimensions(dimensions);
    const chartDomain = getDomains({
      x: xAxis,
      y: yAxis,
      dimensions: chartDimensions,
      dimensionsData: dimensionsData,
      layout: true,
    });
    const isDataNotEmpty = validateChartData(dataBuild, radars.items.length);

    setData(dataBuild);
    setDomain(chartDomain);
    setDataTypes(chartDimensions);
    setIsData(isDataNotEmpty);

    isNewData = true;
  }, [dataItemsJSON, dataSeriesJSON, dimensions.items, dimensions.areas]);

  useEffect(() => {
    if (isData && !isNewData) {
      const chartDomain = getDomains({
        x: xAxis,
        y: yAxis,
        dimensions: dataTypes,
        dimensionsData: dimensionsData,
        layout: true,
      });

      setDomain(chartDomain);
    }
  }, [
    xAxis.domain,
    xAxis.domainMax,
    xAxis.domainMaxValue,
    xAxis.domainMin,
    xAxis.domainMinValue,
    yAxis.domain,
    yAxis.domainMax,
    yAxis.domainMaxValue,
    yAxis.domainMin,
    yAxis.domainMinValue,
  ]);

  useEffect(() => {
    const element = chartRef.current;
    if (element) {
      const svg = element;
      dispatch(UPDATE_CHART_REF(svg));
    }
  }, [chartRef, dispatch]);

  return (
    <div ref={chartRef} style={{ background: background }}>
      <Helmet>
        <link
          rel="stylesheet"
          href={
            "https://fonts.googleapis.com/css?family=" +
            title.fontFamily.replace(/ /g, "+") +
            ":" +
            title.fontVariants
          }
          crossOrigin="anonymous"
        />
        {caption.items.map((elem, i) => {
          return (
            <link
              key={"caption-" + i}
              rel="stylesheet"
              href={
                "https://fonts.googleapis.com/css?family=" +
                elem.fontFamily.replace(/ /g, "+") +
                ":" +
                elem.fontVariants
              }
              crossOrigin="anonymous"
            />
          );
        })}
      </Helmet>
      <div ref={headerRef} style={{ width: width }}>
        {title.isVisible ? (
          <TextBlock key={"title"} title={title} fontLoaded={fontLoaded} />
        ) : (
          ""
        )}
        {caption.isVisible
          ? caption.items.map((elem, i) => {
              return (
                <TextBlock
                  key={"caption" + i}
                  title={elem}
                  fontLoaded={fontLoaded}
                />
              );
            })
          : ""}
        {legend.isVisible ? (
          <CustomLegend
            legendProps={legend}
            items={radars.items.map((elem) => {
              return {
                ...elem,
                dotsColor: elem.lineColor || elem.lineColor,
                dotsStyle: true,
              };
            })}
          />
        ) : (
          ""
        )}
      </div>
      {isData ? (
        <RadarChart
          width={width}
          height={height}
          cx={cx}
          cy={cy}
          data={data}
          outerRadius={radarSettings.radius + "%"}
          style={{ background: background }}
        >
          {grid.isVisible && dataSeries.items.length > 0 ? (
            <PolarGrid
              gridType={radarSettings.radarType ? "circle" : "polygon"}
              stroke={grid.gridColor}
            />
          ) : (
            ""
          )}
          {tooltip.isVisible ? (
            <Tooltip
              cursor={
                tooltip.showCursor
                  ? { strokeWidth: 1, stroke: tooltip.cursorColor }
                  : false
              }
              content={
                <CustomizedTooltip
                  active
                  payload
                  label
                  txtColor={tooltip.txtColor}
                  background={tooltip.bgColor}
                  units={{ x: xAxis.units, y: yAxis.units }}
                  chartType={"radar"}
                  axesDataTypes={{
                    x: dataTypes.items,
                    y: dataTypes.areas,
                  }}
                  useLocales={{
                    x: xAxis.useLocale,
                    y: yAxis.useLocale,
                  }}
                />
              }
            />
          ) : (
            ""
          )}
          {xAxis.isVisible ? (
            <PolarAngleAxis
              dataKey="name"
              type={dataTypes.items}
              axisLine={xAxis.isAxisLine}
              axisLineType={radarSettings.radarType ? "circle" : "polygon"}
              allowDuplicatedCategory={false}
              domain={domain.X}
              style={{ strokeWidth: 1, stroke: xAxis.color }}
              tickSize={
                xAxis.showTicks
                  ? xAxis.tickSize
                    ? xAxis.tickSize
                    : 0.000001
                  : 0.000001
              }
              tick={
                xAxis.showTicks ? (
                  <CustomizedXAxisTick
                    type={dataTypes.items}
                    fill={xAxis.color}
                    fontSize={xAxis.tickFontSize}
                    angle={xAxis.tickRotate}
                    tickSize={xAxis.tickSize}
                    tickMargin={xAxis.tickMargin}
                    units={xAxis.units ? xAxis.units : ""}
                    useLocale={xAxis.useLocale}
                  />
                ) : (
                  false
                )
              }
            />
          ) : (
            ""
          )}
          {radars.items.length > 0
            ? radars.items.map((elem, i) => {
                const {
                  showLine,
                  lineStrokeWidth,
                  lineColor,
                  areaColor,
                  isDashed,
                  dashes,
                  spaces,
                  name,
                  showDots,
                  dotsType,
                  dotsStyle,
                  dotsColor,
                  dotsSize,
                  dotsStrokeWidth,
                  showLabels,
                  labelsPosition,
                  labelsFontSize,
                  labelsColor,
                } = elem;
                const srokeDash = isDashed ? dashes + " " + spaces : "";
                return (
                  <RRadar
                    key={"radar-" + i}
                    name={name ? name : i + 1}
                    dataKey={i}
                    fill={areaColor}
                    fillOpacity={1}
                    strokeDasharray={srokeDash}
                    stroke={lineColor}
                    strokeWidth={showLine ? lineStrokeWidth : 0}
                    dot={
                      showDots ? (
                        <CustomizedDot
                          dotsType={dotsType}
                          dotsStyle={dotsStyle}
                          dotsColor={dotsColor}
                          dotsSize={dotsSize}
                          dotsStrokeWidth={dotsStrokeWidth}
                        />
                      ) : (
                        false
                      )
                    }
                    isAnimationActive={false} // turn off animation because of dot's bug - https://github.com/recharts/recharts/issues/1426
                    // animationDuration={500}
                  >
                    {showLabels ? (
                      <LabelList
                        dataKey={i}
                        content={(d) => {
                          return (
                            <CustomizedDotLabel
                              cx={d.x}
                              cy={d.y}
                              value={d.value}
                              position={labelsPosition}
                              fontSize={labelsFontSize}
                              fill={labelsColor}
                              dotsType={dotsType}
                              dotsStrokeWidth={dotsStrokeWidth}
                              dotSize={
                                dotsType === "tick"
                                  ? dotsStrokeWidth
                                  : dotsStyle
                                  ? dotsSize
                                  : dotsSize + dotsStrokeWidth
                              }
                            />
                          );
                        }}
                      />
                    ) : null}
                  </RRadar>
                );
              })
            : ""}
          {/* If you hide whole component when yAxis is not visible, domain settings will be ignored.
            So, we hide axis line and ticks instead.*/}
          <PolarRadiusAxis
            type={dataTypes.areas}
            axisLine={
              yAxis.isVisible
                ? dataSeries.items.length > 0
                  ? yAxis.isAxisLine
                  : false
                : false
            }
            allowDuplicatedCategory={false}
            angle={yAxis.angle}
            tickCount={
              yAxis.isVisible
                ? yAxis.tickCount
                  ? TICKS_COUNT_DEFAULT
                  : yAxis.tickCountValue
                : 0
            }
            domain={domain.Y}
            tick={
              dataSeries.items.length > 0 ? (
                <CustomizedYAxisTick
                  type={dataTypes.areas}
                  fill={yAxis.color}
                  fontSize={yAxis.tickFontSize}
                  PolarRadiusAxisAngle={yAxis.angle}
                  orientation={yAxis.orient}
                  tickMargin={yAxis.tickMargin}
                  units={yAxis.units}
                  useLocale={yAxis.useLocale}
                />
              ) : (
                false
              )
            }
            style={{ strokeWidth: 1, stroke: yAxis.color }}
          />
        </RadarChart>
      ) : (
        <span style={{ opacity: 0.25 }}>Not enough data to chart</span>
      )}
    </div>
  );
}

const dimensions = {
  items: ControlType.String,
  areas: ControlType.Number,
};

Radar.defaultProps = {
  isData: false,
  layoutSettings: {
    isFullscreen: true,
    isWidthAuto: true,
    minWidth: 320,
    maxWidth: 2500,
    isHeightAuto: true,
    background: "rgba(255, 255, 255, 1)",
  },
  radarSettings: {
    radarType: true,
    radius: 80,
    xOffset: 0,
    yOffset: 0,
  },
  xAxis: {
    isVisible: true,
    color: "rgba(17, 153, 238, 1)",
    isAxisLine: true,
    domain: true,
    domainMin: "dataMin",
    domainMinValue: 0,
    domainMax: "dataMax",
    domainMaxValue: 1,
    showTicks: true,
    tickFontSize: 12,
    tickCount: true,
    tickCountValue: 5,
    tickRotate: "across",
    tickSize: 6,
    tickMargin: 4,
    units: "",
    useLocale: true,
  },
  yAxis: {
    isVisible: true,
    angle: 90,
    color: "rgba(17, 153, 238, 1)",
    labelColor: "rgba(17, 153, 238, 1)",
    orient: "right",
    isAxisLine: true,
    domain: true,
    domainMin: "dataMin",
    domainMinValue: 0,
    domainMax: "dataMax",
    domainMaxValue: 1,
    tickFontSize: 12,
    tickCount: true,
    tickCountValue: 5,
    tickCountCategory: true,
    tickCountValueCategory: 5,
    tickMargin: 4,
    units: "",
    useLocale: true,
  },
  sourceData: true,
  fileData: null,
  grid: {
    isVisible: true,
    gridColor: "rgba(17, 153, 238, .5)",
  },
  radars: {
    isVisible: true,
    items: [...Array(radarsCount)].map((item) => {
      return {
        name: "",
        showLine: true,
        lineStrokeWidth: 1,
        lineColor: "rgba(17, 153, 238, 1)",
        areaColor: "rgba(17, 153, 238, .1)",
        isDashed: false,
        dashes: 4,
        spaces: 4,
        showDots: false,
        dotsType: "circle",
        dotsSize: 4,
        dotsColor: "rgba(17, 153, 238, 1)",
        dotsStyle: true,
        dotsStrokeWidth: 1,
        showLabels: false,
        labelsColor: "rgba(17, 153, 238, 1)",
        labelsFontSize: 12,
        labelsPosition: "R",
      };
    }),
  },
  dataItems: {
    items: [...Array(xCount)].map((_, i) =>
      String.fromCharCode("A".charCodeAt(0) + i)
    ),
  },
  dataSeries: {
    isVisible: true,
    items: [...Array(radarsCount)].map((item, i) => {
      return {
        get areas() {
          return getValues(xCount);
        },
      };
    }),
  },
  dimensions: dimensions,
  legend: {
    isVisible: false,
    txtColor: "rgba(0,0,0,1)",
    fontSize: 14,
    iconSize: 16,
    layout: true,
    align: "center",
    margin: {
      margin: 0,
      isPerSide: true,
      top: 20,
      bottom: 20,
      left: 0,
      right: 0,
    },
  },
  title: {
    isVisible: false,
    text: "Chart title",
    txtColor: "rgba(0,0,0,1)",
    fontSize: 48,
    fontFamily: "Roboto",
    fontVariants: "regular",
    align: "center",
    margin: {
      margin: 0,
      isPerSide: true,
      top: 20,
      bottom: 20,
      left: 0,
      right: 0,
    },
  },
  caption: {
    isVisible: false,
    items: [
      {
        text: "Chart description or data source",
        txtColor: "rgba(0,0,0,.5)",
        fontSize: 18,
        fontFamily: "Roboto",
        fontVariants: "300",
        align: "center",
        margin: {
          margin: 0,
          isPerSide: true,
          top: 20,
          bottom: 20,
          left: 0,
          right: 0,
        },
      },
    ],
  },
  tooltip: {
    isVisible: false,
    txtColor: "rgba(0,0,0,1)",
    bgColor: "rgba(255,255,255,1)",
    showCursor: true,
    cursorColor: "rgba(17, 153, 238, 1)",
  },
};

applyPropertyControls(Radar, {
  //Settings or data
  isData: {
    type: ControlType.Boolean,
    title: "Change",
    disabledTitle: "settings",
    enabledTitle: "data",
    wide: true,
  },

  layoutSettings: presetLayout,

  radarSettings: {
    type: ControlType.Group,
    title: "Chart settings",
    isHeaderControls: false,
    propertyControl: {
      radarType: {
        type: ControlType.Boolean,
        title: "Type",
        enabledTitle: "circle",
        disabledTitle: "polygon",
      },
      radius: {
        type: ControlType.Number,
        title: "Radius",
        min: 0,
        max: 100,
        unit: "%",
      },
      xOffset: {
        type: ControlType.Number,
        title: "X offset",
        min: -100,
        maxx: 100,
      },
      yOffset: {
        type: ControlType.Number,
        title: "Y offset",
        min: -100,
        maxx: 100,
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  // X axis - PolarAngleAxis
  xAxis: presetPolarAngleAxis(dimensionsData, false),
  // Y axis - PolarRadiusAxis
  yAxis: presetPolarRadiusAxis(dimensionsData, false),

  //Grid
  grid: {
    type: ControlType.Group,
    title: "Grid",
    isHeaderControls: true,
    propertyControl: {
      isVisible: {
        type: ControlType.Boolean,
        enabledTitle: "show",
        disabledTitle: "hide",
        hidden(props) {
          return true;
        },
      },
      gridColor: {
        title: "Grid color",
        type: ControlType.Color,
        hidden(props) {
          return (props.gridVert || props.gridHoriz) === false;
        },
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Radars
  radars: {
    type: ControlType.Collection,
    title: "Areas",
    ref: "dataSeries",
    propertyControl: {
      name: {
        title: "Name",
        type: ControlType.String,
        placeholder: "name for legend",
      },
      areaColor: {
        title: "Area color",
        type: ControlType.Color,
      },

      //Line
      showLine: {
        type: ControlType.Boolean,
        title: "Line",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      lineStrokeWidth: {
        type: ControlType.Number,
        title: "↳ Line stroke",
        min: 0,
        max: 100,
        displayStepper: false,
        hidden(props) {
          return props.showLine === false;
        },
      },
      lineColor: {
        title: "↳ Line color",
        type: ControlType.Color,
        hidden(props) {
          return props.showLine === false;
        },
      },
      isDashed: {
        type: ControlType.Boolean,
        title: "↳ Dashed",
        enabledTitle: "yes",
        disabledTitle: "no",
        hidden(props) {
          return props.showLine === false;
        },
      },
      dashes: {
        type: ControlType.Number,
        title: "↳ Dashes",
        min: 1,
        max: 100,
        displayStepper: true,
        hidden(props) {
          return props.showLine === false || props.isDashed === false;
        },
      },
      spaces: {
        type: ControlType.Number,
        title: "↳ Spaces",
        min: 1,
        max: 100,
        displayStepper: true,
        hidden(props) {
          return props.showLine === false || props.isDashed === false;
        },
      },

      // Dots
      ...presetDots,

      // Dot's labels
      ...presetDotsLabels,
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Title
  title: presetTitle,

  //Caption
  caption: presetCaption,

  //Legend
  legend: presetLegend,

  //Tooltip
  tooltip: presetTooltip(true),

  //Data
  sourceData: {
    type: ControlType.Boolean,
    title: "Data",
    enabledTitle: "editable",
    disabledTitle: "file",
    hidden(props) {
      return props.isData === false;
    },
  },
  fileData: {
    title: "↳ File",
    type: ControlType.File,
    allowedFileTypes: ["xls"],
    hidden(props) {
      return props.sourceData === true || props.isData === false;
    },
  },

  //Chart's dimensions
  dimensions: {
    type: ControlType.Group,
    title: "Axes data types",
    propertyControl: {
      items: {
        type: ControlType.Enum,
        seriesType: false,
        title: "X",
        path: "dataItems",
        optionTitles: ["number", "category"],
        options: [ControlType.Number, ControlType.String],
        displaySegmentedControl: true,
      },
      areas: {
        type: ControlType.Enum,
        seriesType: true,
        title: "Y",
        path: "dataSeries",
        optionTitles: ["number"],
        options: [ControlType.Number],
        displaySegmentedControl: true,
        hidden(props) {
          return true;
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },

  //Categories
  dataItems: {
    type: ControlType.Group,
    title: "Items — X axis",
    propertyControl: {
      items: {
        type: ControlType.Array,
        title: "Values",
        propertyControl: {
          type(props) {
            return props.dimensions.items;
          },
          isNullsAllowed: true,
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },

  //Areas Data
  dataSeries: {
    type: ControlType.Collection,
    title: "Radars",
    ref: "radars",
    propertyControl: {
      areas: {
        type: ControlType.Array,
        title: "Values",
        propertyControl: {
          type(props) {
            return props.dimensions.areas;
          },
          isNullsAllowed: true,
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },
});

export default Radar;
