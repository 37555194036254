import React, { memo, useEffect } from "react";

function splitTextByLines(text, width) {
  const lines = [] as any;
  let currentLine = "";

  text.split("\n").forEach((line) => {
    if (line === "") {
      lines.push("");
    } else {
      line.split(" ").forEach((word) => {
        if (currentLine.length + word.length <= width) {
          currentLine += (currentLine ? " " : "") + word;
        } else {
          lines.push(currentLine);
          currentLine = word;
        }
      });
      lines.push(currentLine);
      currentLine = "";
    }
  });

  return lines;
}

function wordWrap(text, width, lineHeight, fontSize, position, x, y) {
  const lines = splitTextByLines(text, width);
  const lineOffset = lineHeight * fontSize;

  return lines.map((elem, i) => {
    let dy = i * lineOffset;
    if (position === "L" || position === "R" || position === "C") {
      dy -= ((lines.length - 1) * lineOffset) / 2;
    }
    if (position === "T") {
      dy = (i - lines.length + 1) * lineOffset;
    }
    // if position === "B", uses default dy (i*lineOffset)

    return (
      <tspan key={"dotLabel-" + i} x={x} y={y} dy={dy}>
        {elem}
      </tspan>
    );
  });
}

export const CustomizedBarchartLabel = (props) => {
  const {
    index,
    x,
    y,
    width,
    height,
    value,
    values,
    fontSize,
    layout,
    units,
    txtColor,
    gap,
    highlightedBars,
  } = props;

  const labelGap = gap ? gap + fontSize / 4 : fontSize / 4;
  const color =
    highlightedBars &&
    highlightedBars.length > 0 &&
    highlightedBars[index].isHighlighted
      ? highlightedBars[index].labelColor
      : txtColor;

  return (
    <text
      x={layout ? x + width / 2 : x + width}
      y={layout ? y : y + height / 2}
      dy={layout ? (height >= 0 ? -labelGap : labelGap) : 0}
      dx={layout ? 0 : width >= 0 ? labelGap : -labelGap}
      alignmentBaseline={
        layout ? (height >= 0 ? "baseline" : "hanging") : "middle"
      }
      fontSize={fontSize}
      textAnchor={layout ? "middle" : width >= 0 ? "start" : "end"}
      fill={color}
      color={color}
    >
      {values ? values[index] + units : value + units}
    </text>
  );
};

export const CustomizedStackLabel = (props) => {
  const {
    x,
    y,
    width,
    height,
    value,
    fontSize,
    layout,
    units,
    fill,
    labelsMinValue,
  } = props;
  return (
    <text
      x={layout ? x + width / 2 : x + width / 2}
      y={layout ? y + height / 2 : y + height / 2}
      alignmentBaseline={layout ? "central" : "central"}
      fontSize={fontSize}
      textAnchor={layout ? "middle" : "middle"}
      fill={fill}
      color={fill}
    >
      {labelsMinValue !== undefined && value >= labelsMinValue
        ? value + units
        : null}
    </text>
  );
};

export const CustomizedDotLabel = (props) => {
  const {
    isX, // if X coordinate is defined by X Value (not defined for reference dots by default)
    isY, // if Y coordinate is defined by Y Value (not defined for reference dots by default)
    xCenter, // X center of chart (whole width - margins - axis width)
    yCenter, // Y Center of chart (whole height - margins - axis height)
    viewBox, // x, y, width, height of Dot
    value,
    fontSize,
    fill,
    dotSize,
    position,
    wrap,
    lineWidth,
    offsetX,
    offsetY,
    showLabelLine,
    labelLineColor,
    showLabelImage,
    imageUrl,
    imagePosition,
    imageCustomSize,
    imageWidth,
  } = props;

  const [imageDimensions, setImageDimensions] = React.useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    let mounted = true;

    const loadImage = (setImageDimensions, imageUrl, isCustomSize, width) => {
      const img = new Image();
      img.src = imageUrl;

      img.onload = () => {
        if (mounted) {
          if (!isCustomSize) {
            setImageDimensions({
              width: img.width,
              height: img.height,
            });
          } else {
            const ratio = img.width / img.height;
            setImageDimensions({
              width: width,
              height: width / ratio,
            });
          }
        }
      };
      img.onerror = (err) => {
        console.log("img error");
        console.error(err);
        if (mounted) {
          setImageDimensions({
            height: 0,
            width: 0,
          });
        }
      };
    };

    if (showLabelImage) {
      if (imageUrl !== undefined && imageUrl !== "") {
        loadImage(setImageDimensions, imageUrl, imageCustomSize, imageWidth);
      }
    }

    return () => {
      mounted = false;
    };
  }, [showLabelImage, imageUrl, imageCustomSize, imageWidth]);

  // dot might has NaN coordinates
  // if so, exit
  if ((isX && isNaN(viewBox.x)) || (isY && isNaN(viewBox.y))) {
    return null;
  }

  const cx = isX ? viewBox.x + viewBox.width / 2 : xCenter;
  const cy = isY ? viewBox.y + viewBox.height / 2 : yCenter;

  let alignment = "central";
  let anchor = "start";
  let xCoord = cx;
  let yCoord = cy;

  const lineHeight = 1.2;
  const lineGap = 8; // gap between line and label

  switch (position) {
    case "T":
      alignment = "baseline";
      anchor = "middle";
      xCoord = cx;
      yCoord = cy - dotSize / 2 - fontSize / 4;
      break;
    case "B":
      alignment = "hanging";
      anchor = "middle";
      xCoord = cx;
      yCoord = cy + dotSize / 2 + fontSize / 4;
      break;
    case "C":
      alignment = "central";
      anchor = "middle";
      xCoord = cx;
      yCoord = cy;
      break;
    case "L":
      alignment = "central";
      anchor = "end";
      xCoord = cx - dotSize / 2 - fontSize / 2;
      yCoord = cy;
      break;
    case "R":
      alignment = "central";
      anchor = "start";
      xCoord = cx + dotSize / 2 + fontSize / 2;
      yCoord = cy;
      break;
  }

  if (offsetX) {
    xCoord += offsetX;
  }
  if (offsetY) {
    yCoord += offsetY;
  }

  let xTxt, yTxt, xImg, yImg;
  xTxt = xCoord;
  yTxt = yCoord;
  xImg = xCoord;
  yImg = yCoord;

  let txtPosition = position;

  if (showLabelImage && imageDimensions.width > 0) {
    // vertical label
    if (imagePosition) {
      if (position === "C") {
        xImg -= imageDimensions.width / 2;
        yImg -= imageDimensions.height / 2;
        alignment = "central";
      }
      if (position === "T") {
        xImg -= imageDimensions.width / 2;
        yImg -= imageDimensions.height;
        yTxt -= imageDimensions.height + fontSize / 2;
        alignment = "baseline";
      }
      if (position === "B") {
        xImg -= imageDimensions.width / 2;
        yTxt = yImg + imageDimensions.height + fontSize / 2;
        alignment = "hanging";
      }
      if (position === "L") {
        xImg -= imageDimensions.width;
        yImg -= imageDimensions.height;
        yTxt = yImg + imageDimensions.height + fontSize / 2;
        alignment = "hanging";
        txtPosition = "B";
      }
      if (position === "R") {
        yImg -= imageDimensions.height;
        yTxt = yImg + imageDimensions.height + fontSize / 2;
        alignment = "hanging";
        txtPosition = "B";
      }
    }
    // horizontal label
    if (!imagePosition) {
      if (position === "C") {
        xImg -= imageDimensions.width / 2;
        yImg -= imageDimensions.height / 2;
        alignment = "central";
      }
      if (position === "T") {
        xImg -= imageDimensions.width;
        yImg -= imageDimensions.height;
        xTxt += fontSize / 2;
        alignment = "baseline";
        anchor = "start";
      }
      if (position === "B") {
        xImg -= imageDimensions.width;
        xTxt += fontSize / 2;
        alignment = "hanging";
        anchor = "start";
      }
      if (position === "L") {
        xTxt -= imageDimensions.width + fontSize / 2;
        xImg -= imageDimensions.width;
        yImg -= imageDimensions.height / 2;
      }
      if (position === "R") {
        xTxt += imageDimensions.width + fontSize / 2;
        yImg -= imageDimensions.height / 2;
      }
    }
  }

  const txtValue =
    wrap && lineWidth !== undefined && lineWidth > 0
      ? wordWrap(
          value,
          lineWidth,
          lineHeight,
          fontSize,
          txtPosition,
          xTxt,
          yTxt
        )
      : value;

  return (
    <g>
      {showLabelImage && imageDimensions.width > 0 ? (
        <image
          href={imageUrl}
          x={xImg}
          y={yImg}
          width={imageDimensions.width}
          height={imageDimensions.height}
        />
      ) : null}
      <text
        x={xTxt}
        y={yTxt}
        dominantBaseline={alignment}
        fontSize={fontSize}
        textAnchor={anchor}
        fill={fill}
        color={fill}
      >
        {txtValue}
      </text>
      {showLabelLine ? (
        <line
          x1={
            xCoord +
            (position === "R" ? -lineGap : position === "L" ? lineGap : 0)
          }
          y1={
            yCoord +
            (position === "T" ? lineGap : position === "B" ? -lineGap : 0)
          }
          x2={cx}
          y2={cy}
          strokeWidth={1}
          stroke={labelLineColor}
        />
      ) : null}
    </g>
  );
};

CustomizedDotLabel.defaultProps = {
  isX: true,
  isY: true,
  xCenter: 0,
  yCenter: 0,
};

export const CustomizedXAxisLabel = (props) => {
  const { value, viewBox, margin, align, axisOrient, fontSize, color } = props;

  let xCoord = viewBox.x;
  switch (align) {
    case "left":
      xCoord = viewBox.x;
      break;
    case "right":
      xCoord = viewBox.x + viewBox.width;
      break;
    case "middle":
      xCoord = viewBox.x + viewBox.width / 2;
  }

  let yCoord =
    axisOrient === "top"
      ? viewBox.y - margin
      : viewBox.y + viewBox.height + margin;

  const anchor =
    align === "left" ? "start" : align === "right" ? "end" : "middle";
  return (
    <g>
      <text
        x={xCoord}
        y={yCoord}
        fontSize={fontSize}
        fill={color}
        color={color}
        dominantBaseline="central"
        textAnchor={anchor}
      >
        <tspan>{value}</tspan>
      </text>
    </g>
  );
};

export const CustomizedYAxisLabel = (props) => {
  const {
    value,
    viewBox,
    margin,
    align,
    axisOrient,
    fontSize,
    color,
    rotateAngle,
  } = props;

  let yCoord = viewBox.y;
  switch (align) {
    case "left":
      yCoord = viewBox.y + viewBox.height;
      break;
    case "right":
      yCoord = viewBox.y;
      break;
    case "middle":
      yCoord = viewBox.y + viewBox.height / 2;
  }

  let xCoord =
    axisOrient === "left"
      ? viewBox.x - margin
      : viewBox.x + viewBox.width + margin;

  const anchor =
    rotateAngle === -90
      ? align === "left"
        ? "start"
        : align === "right"
        ? "end"
        : "middle"
      : align === "left"
      ? "end"
      : align === "right"
      ? "start"
      : "middle";

  return (
    <g>
      <text
        x={xCoord}
        y={yCoord}
        fontSize={fontSize}
        fill={color}
        color={color}
        dominantBaseline="central"
        textAnchor={anchor}
        transform={"rotate(" + rotateAngle + " " + xCoord + " " + yCoord + ")"}
      >
        <tspan>{value}</tspan>
      </text>
    </g>
  );
};

export const CustomizedRefLineLabel = (props) => {
  const {
    orient,
    value,
    viewBox,
    offsetX,
    offsetY,
    fontSize,
    color,
    wrap,
    lineWidth,
    labelPositionHoriz,
    labelPositionVert,
    labelPositionVertAlign,
    labelPositionVertOrient,
  } = props;

  const lineHeight = 1.2;

  if (orient) {
    // horizontal ref line
    const anchor =
      labelPositionHoriz === "left"
        ? "start"
        : labelPositionHoriz === "center"
        ? "middle"
        : "end";

    const x =
      labelPositionHoriz === "left"
        ? viewBox.x
        : labelPositionHoriz === "center"
        ? viewBox.x + viewBox.width / 2
        : viewBox.x + viewBox.width;

    const y = viewBox.y + viewBox.height / 2;

    return (
      <g>
        <text
          x={x + offsetX}
          y={y + offsetY}
          textAnchor={anchor}
          dominantBaseline="middle"
          fontSize={fontSize}
          fill={color}
          color={color}
        >
          {wrap && lineWidth !== undefined && lineWidth > 0 ? (
            wordWrap(
              value,
              lineWidth,
              lineHeight,
              fontSize,
              "C",
              x + offsetX,
              y + offsetY
            )
          ) : (
            <tspan>value</tspan>
          )}
        </text>
      </g>
    );
  } else {
    // vertical ref line
    const x = viewBox.x + viewBox.width / 2;
    const y =
      labelPositionVert === "top"
        ? viewBox.y
        : labelPositionVert === "center"
        ? viewBox.y + viewBox.height / 2
        : viewBox.y + viewBox.height;

    if (labelPositionVertOrient) {
      // vertical ref line and horizontal label
      const anchor =
        labelPositionVertAlign === "left"
          ? "start"
          : labelPositionVertAlign === "center"
          ? "middle"
          : "end";

      return (
        <g>
          <text
            x={x + offsetX}
            y={y + offsetY}
            textAnchor={anchor}
            dominantBaseline="middle"
            fontSize={fontSize}
            fill={color}
            color={color}
          >
            {wrap && lineWidth !== undefined && lineWidth > 0 ? (
              wordWrap(
                value,
                lineWidth,
                lineHeight,
                fontSize,
                "C",
                x + offsetX,
                y + offsetY
              )
            ) : (
              <tspan>value</tspan>
            )}
          </text>
        </g>
      );
    } else {
      // vertical ref line and vertical label
      const anchor =
        labelPositionVert === "top"
          ? "end"
          : labelPositionVert === "center"
          ? "middle"
          : "start";

      return (
        <g>
          <text
            x={x - offsetY}
            y={y + offsetX}
            textAnchor={anchor}
            dominantBaseline="middle"
            fontSize={fontSize}
            fill={color}
            color={color}
            transform={"rotate(-90 " + x + " " + y + ")"}
          >
            {wrap && lineWidth !== undefined && lineWidth > 0 ? (
              wordWrap(
                value,
                lineWidth,
                lineHeight,
                fontSize,
                "C",
                x + offsetX,
                y + offsetY
              )
            ) : (
              <tspan>value</tspan>
            )}
          </text>
        </g>
      );
    }
  }
};

export default CustomizedBarchartLabel;
