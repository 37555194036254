import React from "react";
import { Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { UPDATE_CHART_SETTINGS } from "../utils/actions";
import { getObjValueByPath } from "../utils/utils";

export function InputBoolean(props) {
  const { name, inputSettings } = props;
  const buttonSize = inputSettings.wide ? "large" : "small";
  const chartUI = useSelector((state: any) => state.chart.chartUI);
  const settingsValue = getObjValueByPath(chartUI, name);
  const dispatch = useDispatch();
  const VALUE = settingsValue;

  function handleSetFalse() {
    dispatch(UPDATE_CHART_SETTINGS(name, false));
  }
  function handleSetTrue() {
    dispatch(UPDATE_CHART_SETTINGS(name, true));
  }

  return (
    <Grid container alignItems="center" style={{ alignItems: "start" }}>
      <ToggleButtonGroup size={buttonSize} style={{ width: "100%" }}>
        <ToggleButton
          disabled={!VALUE}
          style={{
            padding: 0,
            width: "50%",
            textTransform: "none",
            color: !VALUE ? "#ffffff" : "#333333",
            backgroundColor: !VALUE ? "#333333" : "",
          }}
          color="primary"
          onClick={handleSetFalse}
          value={inputSettings.disabledTitle}
        >
          {inputSettings.disabledTitle}
        </ToggleButton>
        <ToggleButton
          disabled={VALUE}
          style={{
            padding: 0,
            width: "50%",
            textTransform: "none",
            color: VALUE ? "#ffffff" : "#333333",
            backgroundColor: VALUE ? "#333333" : "",
          }}
          color="primary"
          onClick={handleSetTrue}
          value={inputSettings.enabledTitle}
        >
          {inputSettings.enabledTitle}
        </ToggleButton>
      </ToggleButtonGroup>
    </Grid>
  );
}

export default InputBoolean;
