import * as React from "react";
import { useState, useEffect } from "react";
import { ControlType, applyPropertyControls } from "../ui/controltype";
import { useDispatch } from "react-redux";
import { UPDATE_CHART_REF } from "../utils/actions";
import { Helmet } from "react-helmet";
import {
  presetTitle,
  presetCaption,
  presetLayout,
} from "./propertyControlsPresets";
import { buildHierarchyData } from "../utils/utils";
import * as d3 from "d3";
import { TextBlock } from "./textBlock";
import { useHeaderHeight } from "./customHooks";

const dataGroups = [
  {
    Hierarchy: [
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
    ],
  },
  {
    Hierarchy: [
      "Alameda",
      "Alameda",
      "Alameda",
      "Alameda",
      "Alameda",
      "Alameda",
      "Alameda",
      "Alameda",
      "Alameda",
      "Alameda",
      "Alameda",
      "Alameda",
      "Alameda",
      "Alameda",
      "Contra Costa",
      "Contra Costa",
      "Contra Costa",
      "Contra Costa",
      "Contra Costa",
      "Contra Costa",
      "Contra Costa",
      "Contra Costa",
      "Contra Costa",
      "Contra Costa",
      "Contra Costa",
      "Contra Costa",
      "Contra Costa",
      "Contra Costa",
      "Contra Costa",
      "Contra Costa",
      "Contra Costa",
      "Marin",
      "Marin",
      "Marin",
      "Marin",
      "Marin",
      "Marin",
      "Napa",
      "Napa",
      "Napa",
      "Napa",
      "Napa",
      "San Francisco",
      "San Mateo",
      "San Mateo",
      "San Mateo",
      "San Mateo",
      "San Mateo",
      "San Mateo",
      "San Mateo",
      "San Mateo",
      "San Mateo",
      "San Mateo",
      "San Mateo",
      "San Mateo",
      "San Mateo",
      "San Mateo",
      "San Mateo",
      "Santa Clara",
      "Santa Clara",
      "Santa Clara",
      "Santa Clara",
      "Santa Clara",
      "Santa Clara",
      "Santa Clara",
      "Santa Clara",
      "Santa Clara",
      "Santa Clara",
      "Santa Clara",
      "Santa Clara",
      "Santa Clara",
      "Solano",
      "Solano",
      "Solano",
      "Solano",
      "Solano",
      "Solano",
      "Solano",
      "Sonoma",
      "Sonoma",
      "Sonoma",
      "Sonoma",
      "Sonoma",
      "Sonoma",
      "Sonoma",
      "Sonoma",
    ],
  },
  {
    Hierarchy: [
      "Alameda",
      "Albany",
      "Berkeley",
      "Dublin",
      "Emeryville",
      "Fremont",
      "Hayward",
      "Livermore",
      "Newark",
      "Oakland",
      "Piedmont",
      "Pleasanton",
      "San Leandro",
      "Union City",
      "Antioch",
      "Brentwood",
      "Clayton",
      "Concord",
      "El Cerrito",
      "Hercules",
      "Lafayette",
      "Martinez",
      "Oakley",
      "Orinda",
      "Pinole",
      "Pittsburg",
      "Pleasant Hill",
      "Richmond",
      "San Pablo",
      "San Ramon",
      "Walnut Creek",
      "Belvedere",
      "Larkspur",
      "Mill Valley",
      "Novato",
      "San Rafael",
      "Sausalito",
      "American Canyon",
      "Calistoga",
      "Napa",
      "St. Helena",
      "Yountville",
      "San Francisco",
      "Belmont",
      "Brisbane",
      "Burlingame",
      "Daly City",
      "East Palo Alto",
      "Foster City",
      "Half Moon Bay",
      "Menlo Park",
      "Millbrae",
      "Pacifica",
      "Redwood City",
      "San Bruno",
      "San Carlos",
      "San Mateo",
      "South San Francisco",
      "Campbell",
      "Cupertino",
      "Gilroy",
      "Los Altos",
      "Milpitas",
      "Monte Sereno",
      "Morgan Hill",
      "Mountain View",
      "Palo Alto",
      "San Jose",
      "Santa Clara",
      "Saratoga",
      "Sunnyvale",
      "Benicia",
      "Dixon",
      "Fairfield",
      "Rio Vista",
      "Suisun City",
      "Vacaville",
      "Vallejo",
      "Cloverdale",
      "Cotati",
      "Healdsburg",
      "Petaluma",
      "Rohnert Park",
      "Santa Rosa",
      "Sebastopol",
      "Sonoma",
    ],
  },
];

const dataValues = [
  77409, 19420, 118585, 54523, 11111, 227934, 154507, 86493, 44677, 412040,
  11201, 77046, 89039, 73500, 108675, 56923, 11655, 126938, 24646, 25011, 25381,
  37544, 38968, 18936, 19040, 67998, 34395, 108303, 29991, 74366, 67568, 2017,
  12268, 14318, 54790, 58948, 7115, 20334, 5281, 79567, 5995, 2991, 850282,
  26918, 4568, 30118, 105543, 29353, 32967, 12281, 33319, 22522, 38844, 82595,
  42736, 29596, 102224, 66587, 40788, 60297, 52576, 30238, 73447, 3514, 41839,
  78827, 66649, 1009363, 122725, 30830, 149596, 27780, 19144, 110953, 8055,
  29045, 95607, 119644, 8763, 7415, 11614, 59757, 41928, 173165, 7630, 10952,
];

export function HierarchyTreemapNested(props) {
  const {
    layoutSettings,
    chartSettings,
    spacing,
    labels,
    title,
    caption,
    dataWeights,
    dataSeries,
  } = props;

  const { background, width } = layoutSettings;
  const { margin } = chartSettings;

  const dataWeightsJSON = JSON.stringify(dataWeights);
  const dataSeriesJSON = JSON.stringify(dataSeries);
  const marginJSON = JSON.stringify(margin);

  const chartMargins = margin.isPerSide
    ? {
        top: margin.top,
        right: margin.right,
        left: margin.left,
        bottom: margin.bottom,
      }
    : {
        top: margin.margin,
        right: margin.margin,
        left: margin.margin,
        bottom: margin.margin,
      };

  const dispatch = useDispatch();

  const chartRef = React.useRef<HTMLDivElement>(null);
  const { headerRef, height } = useHeaderHeight(
    width,
    layoutSettings,
    caption,
    title,
    {},
    ""
  );

  const [fontLoaded, setFontLoaded] = React.useState(false);

  // custom fonts loading status
  title["fontLoaded"] = fontLoaded;
  for (let i = 0; i < caption.items.length; i++) {
    caption.items[i]["fontLoaded"] = fontLoaded;
  }

  useEffect(() => {
    document.fonts.ready.then(() => {
      setFontLoaded(true);
    });
  });

  const treemap = (data) =>
    d3
      .treemap()
      .size([
        width - chartMargins.left - chartMargins.right,
        height - chartMargins.top - chartMargins.bottom,
      ])
      .tile(
        chartSettings.tile === "Dice"
          ? d3.treemapDice
          : chartSettings.tile === "Slice"
          ? d3.treemapSlice
          : chartSettings.tile === "SliceDice"
          ? d3.treemapSliceDice
          : chartSettings.tile === "Squarify"
          ? d3.treemapSquarify
          : d3.treemapBinary
      )
      .paddingOuter(spacing.outer)
      .paddingTop(spacing.top)
      .paddingInner(spacing.inner)
      .round(true)(
      d3
        .hierarchy(data)
        .sum((d) => d.value)
        .sort((a, b) => b.value - a.value)
    );

  const [data, setData] = useState(treemap({}));
  const [dataChangedFlag, setDataChangedFlag] = useState(true);

  const svgViewBox = [
    0 - chartMargins.left,
    0 - chartMargins.top,
    width,
    height,
  ];
  const color = d3
    .scaleLinear()
    .domain([data.height, 0])
    .range([chartSettings.colorBack, chartSettings.colorFront]);
  const format = d3.format(",d");

  //Apply style
  function applyStyle(svg) {
    svg
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", svgViewBox)
      .style("background-color", background);

    svg
      .select("#svgTreeNodes")
      .selectAll("rect")
      .attr("fill", (d) => color(d.height));

    const svgLabels = svg
      .select("#svgTreeLabels")
      .selectAll("text")
      .attr("font-size", labels.titleFontSize)
      .attr("fill", labels.titleColor)
      .attr("color", labels.titleColor)
      .attr("x", (d) => d.x0 + labels.titleFontSize / 2)
      .attr("y", (d) => d.y0 + labels.titleFontSize / 2);

    svgLabels
      .filter((d) => !d.children)
      .selectAll("tspan")
      .filter(":last-child")
      .attr("x", (d) => d.parentX + labels.titleFontSize / 2)
      .attr("dy", labels.titleFontSize * 1.2 + "px")
      .attr("font-size", labels.valueFontSize)
      .attr("fill", labels.valueColor)
      .attr("color", labels.valueColor)
      .attr("opacity", labels.showValues ? 1 : 0);

    svgLabels.nodes().forEach(function (el) {
      if (labels.isVisible) {
        //calc labels width and height and compare with node width
        //if it less, make it transparent
        const nodeWidth = el.__data__.x1 - el.__data__.x0;
        const nodeHeight = el.__data__.y1 - el.__data__.y0;
        const txtWidth = el.getBBox().width + labels.titleFontSize / 2;
        const txtHeight = el.getBBox().height + labels.titleFontSize / 2;
        const isParent = el.__data__.data.children
          ? el.__data__.data.children.length
          : 0;
        el.setAttribute(
          "opacity",
          isParent
            ? txtWidth < nodeWidth && txtHeight < spacing.top * 1.5
              ? 1
              : 0
            : txtWidth < nodeWidth && txtHeight < nodeHeight
            ? 1
            : 0
        );
      } else {
        el.setAttribute("opacity", 0);
      }
    });
  }

  //Updating when recieve new data (and first time mounted)
  useEffect(() => {
    const svg = d3.select("#svgTree");
    const structure = data.descendants();

    //nodes
    svg
      .select("#svgTreeNodes")
      .selectAll("rect")
      .data(structure)
      .join("rect")
      .attr("x", (d) => d.x0)
      .attr("y", (d) => d.y0)
      .attr("width", (d) => d.x1 - d.x0)
      .attr("height", (d) => d.y1 - d.y0);

    //labels
    const svgLabels = svg
      .select("#svgTreeLabels")
      .selectAll("text")
      .data(structure)
      .join("text")
      .attr("x", (d) => d.x0 + labels.titleFontSize / 2)
      .attr("y", (d) => d.y0 + labels.titleFontSize / 2);

    svgLabels
      .selectAll("tspan")
      .data((d) =>
        [d.data.name + " ", format(d.value)].map((elem) => {
          return { txt: elem, parentX: d.x0, parentY: d.y0 };
        })
      )
      .join("tspan")
      .attr("alignment-baseline", "hanging")
      .text((d) => d.txt);

    svgLabels
      .filter((d) => d.children === undefined)
      .selectAll("tspan")
      .filter(":last-child")
      .attr("x", (d) => d.parentX + labels.titleFontSize / 2)
      .attr("dy", labels.titleFontSize * 1.2 + "px");

    svgLabels
      .filter((d) => d.children !== undefined)
      .selectAll("tspan")
      .filter(":last-child")
      .attr("x", null)
      .attr("dy", null);
  }, [dataChangedFlag]);

  useEffect(() => {
    const root = buildHierarchyData({
      series: dataSeries.items,
      weights: dataWeights.Weights,
    });
    setData(treemap(root));
    setDataChangedFlag(!dataChangedFlag);
  }, [
    dataWeightsJSON,
    dataSeriesJSON,
    width,
    height,
    spacing.outer,
    spacing.top,
    spacing.inner,
    marginJSON,
    chartSettings.tile,
  ]);

  //Updating style and coordinates settings
  useEffect(() => {
    applyStyle(d3.select(chartRef.current).select("#svgTree"));
  });

  useEffect(() => {
    const element = chartRef.current;
    if (element) {
      const svg = element;
      dispatch(UPDATE_CHART_REF(svg));
    }
  }, [chartRef, dispatch]);

  return (
    <div
      id="HierarchyTreemapNested"
      ref={chartRef}
      style={{ background: background }}
    >
      <Helmet>
        <link
          rel="stylesheet"
          href={
            "https://fonts.googleapis.com/css?family=" +
            title.fontFamily.replace(/ /g, "+") +
            ":" +
            title.fontVariants
          }
          crossOrigin="anonymous"
        />
        {caption.items.map((elem, i) => {
          return (
            <link
              key={"caption-" + i}
              rel="stylesheet"
              href={
                "https://fonts.googleapis.com/css?family=" +
                elem.fontFamily.replace(/ /g, "+") +
                ":" +
                elem.fontVariants
              }
              crossOrigin="anonymous"
            />
          );
        })}
      </Helmet>
      <div ref={headerRef} style={{ width: width }}>
        {title.isVisible ? (
          <TextBlock key={"title"} title={title} fontLoaded={fontLoaded} />
        ) : (
          ""
        )}
        {caption.isVisible
          ? caption.items.map((elem, i) => {
              return (
                <TextBlock
                  key={"caption" + i}
                  title={elem}
                  fontLoaded={fontLoaded}
                />
              );
            })
          : ""}
      </div>
      <svg id="svgTree" className="chart-surface">
        <g id="chartContainer">
          <g id="svgTreeNodes" />
          <g id="svgTreeLabels" />
        </g>
      </svg>
    </div>
  );
}

HierarchyTreemapNested.defaultProps = {
  isData: false,
  layoutSettings: {
    isFullscreen: true,
    isWidthAuto: true,
    minWidth: 320,
    maxWidth: 2500,
    isHeightAuto: true,
    background: "rgba(255, 255, 255, 1)",
  },
  chartSettings: {
    tile: "Binary",
    colorBack: "rgba(17, 153, 238, 1)",
    colorFront: "rgba(255, 255, 255, .85)",
    margin: {
      margin: 0,
      isPerSide: false,
      top: 100,
      bottom: 40,
      left: 10,
      right: 10,
    },
  },
  sourceData: true,
  fileData: null,
  spacing: {
    top: 32,
    outer: 8,
    inner: 1,
  },
  labels: {
    isVisible: true,
    titleColor: "rgba(0,0,0,1)",
    titleFontSize: 14,
    showValues: true,
    valueColor: "rgba(0,0,0,.5)",
    valueFontSize: 12,
  },
  title: {
    isVisible: false,
    text: "Chart title",
    txtColor: "rgba(0,0,0,1)",
    fontSize: 48,
    fontFamily: "Roboto",
    fontVariants: "regular",
    align: "center",
    margin: {
      margin: 0,
      isPerSide: true,
      top: 20,
      bottom: 20,
      left: 0,
      right: 0,
    },
  },
  caption: {
    isVisible: false,
    items: [
      {
        text: "Chart description or data source",
        txtColor: "rgba(0,0,0,.5)",
        fontSize: 18,
        fontFamily: "Roboto",
        fontVariants: "300",
        align: "center",
        margin: {
          margin: 0,
          isPerSide: true,
          top: 20,
          bottom: 20,
          left: 0,
          right: 0,
        },
      },
    ],
  },
  dataWeights: {
    Weights: dataValues,
  },
  dataSeries: { isVisible: true, items: dataGroups },
  dimensions: {
    Hierarchy: ControlType.String,
    Weights: ControlType.Number,
  },
};

applyPropertyControls(HierarchyTreemapNested, {
  //Settings or data
  isData: {
    type: ControlType.Boolean,
    title: "Change",
    disabledTitle: "settings",
    enabledTitle: "data",
    wide: true,
  },

  layoutSettings: presetLayout,

  chartSettings: {
    type: ControlType.Group,
    title: "Chart settings",
    isHeaderControls: false,
    propertyControl: {
      tile: {
        type: ControlType.Enum,
        title: "Tiling",
        options: ["Binary", "Dice", "Slice", "Slice Dice", "Squarify"],
      },
      colorBack: {
        type: ControlType.Color,
        title: "Color back",
      },
      colorFront: {
        type: ControlType.Color,
        title: "Color front",
      },
      margin: {
        type: ControlType.FusedNumber,
        title: "Margin",
        toggleKey: "isPerSide",
        toggleTitles: ["All", "Individual"],
        valueKeys: ["top", "right", "bottom", "left"],
        valueLabels: ["T", "R", "B", "L"],
        min: 0,
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Paddings
  spacing: {
    type: ControlType.Group,
    title: "Tiles spacing",
    isHeaderControls: false,
    propertyControl: {
      top: {
        type: ControlType.Number,
        title: "Top",
        min: 0,
        displayStepper: true,
      },
      outer: {
        type: ControlType.Number,
        title: "Outer",
        min: 0,
        displayStepper: true,
      },
      inner: {
        type: ControlType.Number,
        title: "Inner",
        min: 0,
        displayStepper: true,
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Labels
  labels: {
    type: ControlType.Group,
    title: "Labels",
    isHeaderControls: true,
    propertyControl: {
      isVisible: {
        type: ControlType.Boolean,
        enabledTitle: "show",
        disabledTitle: "hide",
        hidden(props) {
          return true;
        },
      },
      titleColor: {
        type: ControlType.Color,
        title: "Color",
      },
      titleFontSize: {
        type: ControlType.Number,
        title: "Size",
      },
      showValues: {
        type: ControlType.Boolean,
        title: "Values",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      valueColor: {
        type: ControlType.Color,
        title: "↳ Color",
        hidden(props) {
          return props.showValues === false;
        },
      },
      valueFontSize: {
        type: ControlType.Number,
        title: "↳ Size",
        hidden(props) {
          return props.showValues === false;
        },
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Title
  title: presetTitle,

  //Caption
  caption: presetCaption,

  //Data
  sourceData: {
    type: ControlType.Boolean,
    title: "Data",
    enabledTitle: "editable",
    disabledTitle: "file",
    hidden(props) {
      return props.isData === false;
    },
  },
  fileData: {
    title: "↳ File",
    type: ControlType.File,
    allowedFileTypes: ["xls"],
    hidden(props) {
      return props.sourceData === true || props.isData === false;
    },
  },

  //Weights data
  dataWeights: {
    type: ControlType.Group,
    title: "Weights",
    propertyControl: {
      Weights: {
        type: ControlType.Array,
        title: "Weights",
        propertyControl: {
          type(props) {
            return props.dimensions.Weights;
          },
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },

  //Hierarchy Data
  dataSeries: {
    type: ControlType.Collection,
    title: "Hierarchy",
    // ref: "nodes",
    propertyControl: {
      Hierarchy: {
        type: ControlType.Array,
        title: "Hierarchy",
        propertyControl: {
          type(props) {
            return props.dimensions.Hierarchy;
          },
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },

  //Chart's dimensions
  dimensions: {
    type: ControlType.Group,
    title: "Dimensions",
    propertyControl: {
      Hierarchy: {
        type: ControlType.Enum,
        seriesType: true,
        title: "Group by",
        path: "dataSeries",
        options: [ControlType.String, ControlType.Number],
      },
      Weights: {
        type: ControlType.Enum,
        seriesType: false,
        title: "Weights",
        path: "dataWeights",
        options: [ControlType.Number],
      },
    },
    hidden() {
      return true;
    },
  },
});

export default HierarchyTreemapNested;
