import { doc, getDoc } from "firebase/firestore";
import { getIdToken } from "firebase/auth";

export async function getChartFromDbById(
  docId,
  appDB,
  firestoreCollectionCharts
) {
  const docRef = doc(appDB, firestoreCollectionCharts, docId);
  const chart = await getDoc(docRef).then((docSnap) => {
    if (docSnap.exists()) {
      const data = docSnap.data();
      const value = {
        chart: data.chart,
        chartName: data.chartName,
        chartId: docSnap.id,
      };
      return value;
    }
  });
  return chart;
}

// Use fetch to request the API information
export async function getChartList(auth, collectionCharts) {
  const projectID = "getchart-online";
  const url = `https://firestore.googleapis.com/v1/projects/${projectID}/databases/(default)/documents:runQuery`;

  const query = {
    structuredQuery: {
      from: [{ collectionId: collectionCharts }],
      orderBy: [{ field: { fieldPath: "updated" }, direction: "DESCENDING" }],
      select: {
        fields: [
          { fieldPath: "chartName" },
          { fieldPath: "updated" },
          { fieldPath: "updated" },
        ],
      },
      where: {
        fieldFilter: {
          field: {
            fieldPath: "userId",
          },
          op: "EQUAL",
          value: {
            stringValue: auth.currentUser.uid,
          },
        },
      },
    },
  };

  const chartsList = await getIdToken(auth.currentUser).then((token) => {
    // getIdToken - for rest request we need fresh access token
    const fetchResult = fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(query),
    })
      .then((response) => response.json())
      .then((json) => {
        let charts: any = [];
        json.forEach((doc) => {
          if (doc.document !== undefined && doc.document.fields !== undefined) {
            const chart = {
              chartName: doc.document.fields.chartName.stringValue,
              updated: doc.document.fields.updated.timestampValue,
              chartId: doc.document.name.substring(
                doc.document.name.lastIndexOf("/") + 1,
                doc.document.name.length
              ),
            };
            charts.push(chart);
          }
        });
        return charts;
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
    return fetchResult;
  });
  return chartsList;
}

export async function getChartsLimit(auth, db, collectionUsers) {
  const docRef = doc(db, collectionUsers, auth.currentUser.uid);
  const chartsLimit: any = await getDoc(docRef)
    .then((docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        return data.userGroup;
      }
    })
    .then((ref) => {
      const chartsAmount = getDoc(ref).then((refDocSnap) => {
        if (refDocSnap.exists()) {
          const data: any = refDocSnap.data();
          return data.chartsAmount;
        }
      });
      return chartsAmount;
    });
  return chartsLimit;
}
