import * as React from "react";
import { useState, useEffect } from "react";
import { ControlType, applyPropertyControls } from "../ui/controltype";
import { useDispatch } from "react-redux";
import { UPDATE_CHART_REF } from "../utils/actions";
import * as d3 from "d3";
import { Helmet } from "react-helmet";
import {
  presetLayout,
  presetTitle,
  presetCaption,
} from "./propertyControlsPresets";
import { buildHierarchyData } from "../utils/utils";
import { TextBlock } from "./textBlock";
import { useHeaderHeight } from "./customHooks";

const dataGroups = [
  {
    Hierarchy: [
      "King George VI",
      "King George VI",
      "King George VI",
      "King George VI",
      "King George VI",
      "King George VI",
      "King George VI",
      "King George VI",
      "King George VI",
      "King George VI",
      "King George VI",
      "King George VI",
    ],
  },
  {
    Hierarchy: [
      "Queen Elizabeth II",
      "Queen Elizabeth II",
      "Queen Elizabeth II",
      "Queen Elizabeth II",
      "Queen Elizabeth II",
      "Queen Elizabeth II",
      "Queen Elizabeth II",
      "Queen Elizabeth II",
      "Princess Magaret",
      "Princess Magaret",
      "Princess Magaret",
      "Princess Magaret",
    ],
  },
  {
    Hierarchy: [
      "Charles Prince of Wales",
      "Princess Anne",
      "Princess Anne",
      "Princess Anne",
      "Andrew Duke of York",
      "Andrew Duke of York",
      "Edward Earl of Wessex",
      "Edward Earl of Wessex",
      "David Viscount Linley",
      "David Viscount Linley",
      "Lady Sara Chatto",
      "Lady Sara Chatto",
    ],
  },
  {
    Hierarchy: [
      "Prince Harry of Wales",
      "Peter Phillips",
      "Peter Phillips",
      "Zara Tindall",
      "Princess Beatrice of York",
      "Princess Eugenie of York",
      "Lady Louise Windsor",
      "James, Viscount Severn",
      "Charles Armstrong Jones",
      "Armstrong Jones",
      "Samuel Chatto",
      "Arthur Chatto",
    ],
  },
  {
    Hierarchy: [
      0,
      "Savannah Phillips",
      "Isla Phillips",
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ],
  },
];
const dataValues = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];

export function HierarchyTree(props) {
  const {
    layoutSettings,
    chartSettings,
    links,
    branches,
    leaves,
    title,
    caption,
    dataWeights,
    dataSeries,
  } = props;

  const { background, width } = layoutSettings;

  const dataWeightsJSON = JSON.stringify(dataWeights);
  const dataSeriesJSON = JSON.stringify(dataSeries);

  const dispatch = useDispatch();

  const buildTree = (d, nodesSpacing) => {
    //calc summ of leaves values and put value prop into nodes
    const root = d3.hierarchy(d).sum((elem) => elem.value);

    root.dx = nodesSpacing;
    root.dy = width / (root.height + 2);

    const tree = d3.tree().nodeSize([root.dx, root.dy])(root);

    //find min and max x coordinate
    let x0 = Infinity;
    let x1 = -x0;
    tree.each((d) => {
      if (d.x > x1) x1 = d.x;
      if (d.x < x0) x0 = d.x;
    });

    tree.x0 = x0;
    tree.x1 = x1;

    return tree;
  };

  const chartRef = React.useRef<HTMLDivElement>(null);
  const { headerRef, height } = useHeaderHeight(
    width,
    layoutSettings,
    caption,
    title,
    {},
    ""
  );

  const [fontLoaded, setFontLoaded] = React.useState(false);

  // custom fonts loading status
  title["fontLoaded"] = fontLoaded;
  for (let i = 0; i < caption.items.length; i++) {
    caption.items[i]["fontLoaded"] = fontLoaded;
  }

  useEffect(() => {
    document.fonts.ready.then(() => {
      setFontLoaded(true);
    });
  });

  const [data, setData] = useState(d3.hierarchy({}));
  const [dataChangedFlag, setDataChangedFlag] = useState(true);

  const labelsPadding = 4;

  const weightNodeMax = d3.max(data.descendants(), (d) => d.value);
  const weighNodetMin = d3.min(data.descendants(), (d) => d.value);
  const weightlinkMax = d3.max(data.links(), (d) => d.target.value);
  const weightlinkMin = d3.min(data.links(), (d) => d.target.value);

  const scaleLeavesSize = d3
    .scaleLinear()
    .domain([weighNodetMin, weightNodeMax])
    .range([chartSettings.nodeSizeMin, chartSettings.nodeSizeMax]);

  const scaleLinksSize = d3
    .scaleLinear()
    .domain([
      chartSettings.syncLinkWeights ? weighNodetMin : weightlinkMin,
      chartSettings.syncLinkWeights ? weightNodeMax : weightlinkMax,
    ])
    .range([
      chartSettings.syncLinkWeights
        ? chartSettings.nodeSizeMin
        : chartSettings.linkSizeMin,
      chartSettings.syncLinkWeights
        ? chartSettings.nodeSizeMax
        : chartSettings.linkSizeMax,
    ]);

  const hierarchyDepth = data.height ? data.height + 2 : 1;
  const linkWidth = width / hierarchyDepth;
  const dataX0 = data.x0 | 0;
  const dataX1 = data.x1 | 0;
  const svgViewBox = [
    -linkWidth - chartSettings.xOffset,
    -(-dataX0 + (height - (dataX1 - dataX0)) / 2) - chartSettings.yOffset,
    width,
    height,
  ];

  //Apply style
  function applyStyle(svg) {
    svg
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", svgViewBox)
      .style("background-color", background);

    //nodes
    svg
      .selectAll("circle")
      .attr("fill", (d) => (d.children ? branches.color : leaves.color))
      .attr("stroke", (d) =>
        d.children ? branches.strokeColor : leaves.strokeColor
      )
      .attr("stroke-width", (d) =>
        d.children ? branches.strokeWidth : leaves.strokeWidth
      )
      .attr("r", (d) =>
        d.children
          ? chartSettings.useWeights
            ? scaleLeavesSize(d.value) / 2
            : branches.size / 2
          : chartSettings.useWeights
          ? scaleLeavesSize(d.value) / 2
          : leaves.size / 2
      );

    //links
    svg
      .selectAll("path")
      .attr("stroke", links.color)
      .attr(
        "stroke-width",
        chartSettings.useWeights
          ? (d) => scaleLinksSize(d.target.value)
          : links.stroke
      )
      .attr("fill", "none");

    //labels
    svg
      .select("#svgTreeLabels")
      .selectAll("text")
      .attr("font-size", (d) =>
        d.children ? branches.labelFontSize : leaves.labelFontSize
      )
      .attr("fill", (d) =>
        d.children
          ? branches.showLabels
            ? branches.labelColor
            : "rgba(0, 0, 0, 0)"
          : leaves.showLabels
          ? leaves.labelColor
          : "rgba(0, 0, 0, 0)"
      )
      .attr("color", (d) =>
        d.children
          ? branches.showLabels
            ? branches.labelColor
            : "rgba(0, 0, 0, 0)"
          : leaves.showLabels
          ? leaves.labelColor
          : "rgba(0, 0, 0, 0)"
      )
      .attr("x", (d) =>
        d.children
          ? chartSettings.useWeights
            ? d.y - scaleLeavesSize(d.value) / 2 - labelsPadding
            : d.y - branches.size / 2 - labelsPadding
          : chartSettings.useWeights
          ? d.y + scaleLeavesSize(d.value) / 2 + labelsPadding
          : d.y + leaves.size / 2 + labelsPadding
      )
      .attr("dy", "0.31em");
  }

  //Updating when recieve new data or width (and first time mounted)
  useEffect(() => {
    const svg = d3.select("#svgTree");

    if (data.children) {
      //links
      svg
        .select("#svgTreeLinks")
        .selectAll("path")
        .data(data.links())
        .join("path")
        .attr(
          "d",
          d3
            .linkHorizontal()
            .x((d) => d.y)
            .y((d) => d.x)
        );

      //nodes
      svg
        .select("#svgTreeNodes")
        .selectAll("circle")
        .data(data.descendants())
        .join("circle")
        .attr("cx", (d) => d.y)
        .attr("cy", (d) => d.x);

      //labels
      svg
        .select("#svgTreeLabels")
        .selectAll("text")
        .data(data.descendants())
        .join("text")
        .attr("x", (d) =>
          d.children
            ? chartSettings.useWeights
              ? d.y - scaleLeavesSize(d.value) - labelsPadding
              : d.y - branches.size - labelsPadding
            : chartSettings.useWeights
            ? d.y + scaleLeavesSize(d.value) + labelsPadding
            : d.y + leaves.size + labelsPadding
        )
        .attr("y", (d) => d.x)
        .attr("text-anchor", (d) => (d.children ? "end" : "start"))
        .text((d) => d.data.name);
    } else {
      svg.select("#svgTreeLinks").selectAll("path").remove();
      svg.select("#svgTreeNodes").selectAll("circle").remove();
      svg.select("#svgTreeLabels").selectAll("text").remove();
    }
  }, [dataChangedFlag]);

  //Updating style and coordinates settings
  useEffect(() => {
    applyStyle(d3.select(chartRef.current).select("#svgTree"));
  });

  useEffect(() => {
    const dataRaw = buildHierarchyData({
      series: dataSeries.items,
      weights: dataWeights.Weights,
    });
    const root = buildTree(dataRaw, chartSettings.nodesSpacing);
    setData(root);
    setDataChangedFlag(!dataChangedFlag);
  }, [dataWeightsJSON, dataSeriesJSON, chartSettings.nodesSpacing, width]);

  useEffect(() => {
    const element = chartRef.current;
    if (element) {
      const svg = element;
      dispatch(UPDATE_CHART_REF(svg));
    }
  }, [chartRef, dispatch]);

  return (
    <div id="hierarchyTree" ref={chartRef} style={{ background: background }}>
      <Helmet>
        <link
          rel="stylesheet"
          href={
            "https://fonts.googleapis.com/css?family=" +
            title.fontFamily.replace(/ /g, "+") +
            ":" +
            title.fontVariants
          }
          crossOrigin="anonymous"
        />
        {caption.items.map((elem, i) => {
          return (
            <link
              key={"caption-" + i}
              rel="stylesheet"
              href={
                "https://fonts.googleapis.com/css?family=" +
                elem.fontFamily.replace(/ /g, "+") +
                ":" +
                elem.fontVariants
              }
              crossOrigin="anonymous"
            />
          );
        })}
      </Helmet>
      <div ref={headerRef} style={{ width: width }}>
        {title.isVisible ? (
          <TextBlock key={"title"} title={title} fontLoaded={fontLoaded} />
        ) : (
          ""
        )}
        {caption.isVisible
          ? caption.items.map((elem, i) => {
              return (
                <TextBlock
                  key={"caption" + i}
                  title={elem}
                  fontLoaded={fontLoaded}
                />
              );
            })
          : ""}
      </div>
      <svg id="svgTree" className="chart-surface">
        <g id="chartContainer">
          <g id="svgTreeLinks" />
          <g id="svgTreeNodes" />
          <g id="svgTreeLabels" />
        </g>
      </svg>
    </div>
  );
}

HierarchyTree.defaultProps = {
  isData: false,
  layoutSettings: {
    isFullscreen: true,
    isWidthAuto: true,
    minWidth: 320,
    maxWidth: 2500,
    isHeightAuto: true,
    background: "rgba(255, 255, 255, 1)",
  },
  chartSettings: {
    nodesSpacing: 50,
    useWeights: false,
    nodeSizeMin: 3,
    nodeSizeMax: 20,
    syncLinkWeights: true,
    linkSizeMin: 1,
    linkSizeMax: 8,
    xOffset: 0,
    yOffset: 0,
  },
  sourceData: true,
  fileData: null,
  links: {
    color: "rgba(17, 153, 238, .25)",
    stroke: 1,
  },
  branches: {
    size: 6,
    color: "rgba(255, 255, 255, 1)",
    strokeColor: "rgba(17, 153, 238, 1)",
    strokeWidth: 1,
    showLabels: true,
    labelColor: "rgba(17, 153, 238, 1)",
    labelFontSize: 14,
  },
  leaves: {
    size: 6,
    color: "rgba(17, 153, 238, 1)",
    strokeColor: "rgba(17, 153, 238, 1)",
    strokeWidth: 0,
    showLabels: true,
    labelColor: "rgba(17, 153, 238, 1)",
    labelFontSize: 14,
  },
  title: {
    isVisible: false,
    text: "Chart title",
    txtColor: "rgba(0,0,0,1)",
    fontSize: 48,
    fontFamily: "Roboto",
    fontVariants: "regular",
    align: "center",
    margin: {
      margin: 0,
      isPerSide: true,
      top: 20,
      bottom: 20,
      left: 0,
      right: 0,
    },
  },
  caption: {
    isVisible: false,
    items: [
      {
        text: "Chart description or data source",
        txtColor: "rgba(0,0,0,.5)",
        fontSize: 18,
        fontFamily: "Roboto",
        fontVariants: "300",
        align: "center",
        margin: {
          margin: 0,
          isPerSide: true,
          top: 20,
          bottom: 20,
          left: 0,
          right: 0,
        },
      },
    ],
  },
  dataWeights: {
    Weights: dataValues,
  },
  dataSeries: {
    isVisible: true,
    items: dataGroups,
  },
  dimensions: {
    Hierarchy: ControlType.String,
    Weights: ControlType.Number,
  },
};

applyPropertyControls(HierarchyTree, {
  //Settings or data
  isData: {
    type: ControlType.Boolean,
    title: "Change",
    disabledTitle: "settings",
    enabledTitle: "data",
    wide: true,
  },

  layoutSettings: presetLayout,

  chartSettings: {
    type: ControlType.Group,
    title: "Chart settings",
    isHeaderControls: false,
    propertyControl: {
      nodesSpacing: {
        type: ControlType.Number,
        title: "Spacing",
        min: 0,
        max: 500,
        displayStepper: true,
      },

      //Use weights for leaves and branches sizes
      useWeights: {
        type: ControlType.Boolean,
        title: "Use weights",
        enabledTitle: "yes",
        disabledTitle: "no",
      },
      nodeSizeMin: {
        type: ControlType.Number,
        title: "↳ Node Min",
        displayStepper: true,
        hidden(props) {
          return props.useWeights === false;
        },
      },
      nodeSizeMax: {
        type: ControlType.Number,
        title: "↳ Node Max",
        displayStepper: true,
        hidden(props) {
          return props.useWeights === false;
        },
      },
      syncLinkWeights: {
        type: ControlType.Boolean,
        title: "↳ Sync links",
        enabledTitle: "yes",
        disabledTitle: "no",
        hidden(props) {
          return props.useWeights === false;
        },
      },
      linkSizeMin: {
        type: ControlType.Number,
        title: "↳ Link Min",
        displayStepper: true,
        hidden(props) {
          return props.syncLinkWeights === true;
        },
      },
      linkSizeMax: {
        type: ControlType.Number,
        title: "↳ Link Max",
        displayStepper: true,
        hidden(props) {
          return props.syncLinkWeights === true;
        },
      },

      //Common settings
      xOffset: {
        type: ControlType.Number,
        title: "X offset",
        min: -1000,
        max: 1000,
      },
      yOffset: {
        type: ControlType.Number,
        title: "Y offset",
        min: -1000,
        max: 1000,
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Links
  links: {
    type: ControlType.Group,
    title: "Links",
    isHeaderControls: false,
    propertyControl: {
      color: {
        title: "Color",
        type: ControlType.Color,
      },
      stroke: {
        type: ControlType.Number,
        title: "Thickness",
        displayStepper: true,
        hidden(props, UI) {
          return UI.chartSettings.useWeights === true;
        },
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Branches
  branches: {
    type: ControlType.Group,
    title: "Branches",
    isHeaderControls: false,
    propertyControl: {
      size: {
        type: ControlType.Number,
        title: "Size",
        displayStepper: true,
        hidden(props, UI) {
          return UI.chartSettings.useWeights === true;
        },
      },
      color: {
        title: "Fill",
        type: ControlType.Color,
      },
      strokeColor: {
        title: "Stroke color",
        type: ControlType.Color,
      },
      strokeWidth: {
        type: ControlType.Number,
        title: "Stroke size",
        displayStepper: true,
      },
      showLabels: {
        type: ControlType.Boolean,
        title: "Labels",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      labelColor: {
        type: ControlType.Color,
        title: "↳ Color",
        hidden(props) {
          return props.showLabels === false;
        },
      },
      labelFontSize: {
        type: ControlType.Number,
        title: "↳ Size",
        hidden(props) {
          return props.showLabels === false;
        },
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Leaves
  leaves: {
    type: ControlType.Group,
    title: "Leaves",
    isHeaderControls: false,
    propertyControl: {
      size: {
        type: ControlType.Number,
        title: "Size",
        displayStepper: true,
        hidden(props, UI) {
          return UI.chartSettings.useWeights === true;
        },
      },
      color: {
        title: "Fill",
        type: ControlType.Color,
      },
      strokeColor: {
        title: "Stroke color",
        type: ControlType.Color,
      },
      strokeWidth: {
        type: ControlType.Number,
        title: "Stroke size",
        displayStepper: true,
      },
      showLabels: {
        type: ControlType.Boolean,
        title: "Labels",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      labelColor: {
        type: ControlType.Color,
        title: "↳ Color",
        hidden(props) {
          return props.showLabels === false;
        },
      },
      labelFontSize: {
        type: ControlType.Number,
        title: "↳ Size",
        hidden(props) {
          return props.showLabels === false;
        },
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Title
  title: presetTitle,

  //Caption
  caption: presetCaption,

  //Data
  sourceData: {
    type: ControlType.Boolean,
    title: "Data",
    enabledTitle: "editable",
    disabledTitle: "file",
    hidden(props) {
      return props.isData === false;
    },
  },
  fileData: {
    title: "↳ File",
    type: ControlType.File,
    allowedFileTypes: ["xls"],
    hidden(props) {
      return props.sourceData === true || props.isData === false;
    },
  },

  //Weights data
  dataWeights: {
    type: ControlType.Group,
    title: "Weights",
    propertyControl: {
      Weights: {
        type: ControlType.Array,
        title: "Weights",
        propertyControl: {
          type(props) {
            return props.dimensions.Weights;
          },
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },

  //Hierarchy Data
  dataSeries: {
    type: ControlType.Collection,
    title: "Hierarchy",
    // ref: "nodes",
    propertyControl: {
      Hierarchy: {
        type: ControlType.Array,
        title: "Hierarchy",
        propertyControl: {
          type(props) {
            return props.dimensions.Hierarchy;
          },
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },

  //Chart's dimensions
  dimensions: {
    type: ControlType.Group,
    title: "Dimensions",
    propertyControl: {
      Hierarchy: {
        type: ControlType.Enum,
        seriesType: true,
        title: "Group by",
        path: "dataSeries",
        options: [ControlType.String, ControlType.Number],
      },
      Weights: {
        type: ControlType.Enum,
        seriesType: false,
        title: "Weights",
        path: "dataWeights",
        options: [ControlType.Number],
      },
    },
    hidden() {
      return true;
    },
  },
});

export default HierarchyTree;
