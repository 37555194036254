import React from "react";
import { useDispatch } from "react-redux";
import { CLOSE_DRAWER, OPEN_POPUP } from "../utils/actions";
import {
  IconButton,
  Fab,
  Tooltip,
  TooltipProps,
  tooltipClasses,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/SaveAltOutlined";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import ShareIcon from "@mui/icons-material/ShareOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";
import { GoogleSignin } from "../../firebase";

const fabStyle = {
  backgroundColor: "white",
  color: "primary.light",
  border: "1px solid",
  borderColor: "#dadce0",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "rgba(0,0,0,.06)",
  },
  "&:active": {
    boxShadow: "none",
  },
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    color: "white",
    fontSize: "14px",
  },
}));

export const ActionBar = () => {
  const dispatch = useDispatch();

  function closeDrawer() {
    dispatch(CLOSE_DRAWER);
  }
  const handleShare = () => {
    dispatch(OPEN_POPUP({ content: "Share" }));
  };
  const handleDownload = () => {
    dispatch(OPEN_POPUP({ content: "Download" }));
  };
  const handleInfo = () => {
    dispatch(OPEN_POPUP({ content: "Info" }));
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {/* buttons */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          columnGap: 8,
        }}
      >
        {/* Sign-in */}
        <div
          style={{
            marginLeft: -8,
            height: "40px",
          }}
        >
          <GoogleSignin />
        </div>
        {/* Sign-in end */}
        <HtmlTooltip
          title="Download"
          enterDelay={600}
          enterNextDelay={600}
          arrow
        >
          <Fab
            size="small"
            color="primary"
            aria-label="Download"
            onClick={handleDownload}
            sx={fabStyle}
          >
            <SaveIcon />
          </Fab>
        </HtmlTooltip>
        <HtmlTooltip title="Share" enterDelay={600} enterNextDelay={600} arrow>
          <Fab
            size="small"
            color="primary"
            aria-label="Share"
            onClick={handleShare}
            sx={fabStyle}
          >
            <ShareIcon />
          </Fab>
        </HtmlTooltip>
        <HtmlTooltip title="Info" enterDelay={600} enterNextDelay={600} arrow>
          <Fab
            size="small"
            color="primary"
            aria-label="Info"
            onClick={handleInfo}
            sx={fabStyle}
          >
            <InfoIcon />
          </Fab>
        </HtmlTooltip>
      </div>
      {/* buttons end */}
      <div>
        <IconButton
          onClick={closeDrawer}
          title="close"
          style={{ marginRight: -8 }}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );
};

export default ActionBar;
