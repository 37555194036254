import * as React from "react";
import { useState, useEffect } from "react";
import { ControlType, applyPropertyControls } from "../ui/controltype";
import { useDispatch } from "react-redux";
import { UPDATE_CHART_REF } from "../utils/actions";
import * as d3 from "d3";
import { Helmet } from "react-helmet";
import {
  presetLayout,
  presetTitle,
  presetCaption,
} from "./propertyControlsPresets";
import { TextBlock } from "./textBlock";
import { useHeaderHeight } from "./customHooks";

export const HeatmapLegend = (props) => {
  const { legend, min, max, colorStart, colorEnd } = props;
  const { margin } = legend;

  if (legend.isVisible) {
    const txtGap = 8;

    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent:
            legend.align === "left"
              ? "start"
              : legend.align === "right"
              ? "end"
              : "center",
        }}
      >
        <div
          id="svgHeatmapLegend"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            color: legend.fontColor,
            fontSize: legend.fontSize,
            padding: margin.isPerSide
              ? margin.top +
                "px " +
                margin.right +
                "px " +
                margin.bottom +
                "px " +
                margin.left +
                "px"
              : margin.margin + "px",
          }}
        >
          <div style={{ marginRight: txtGap }}>{min}</div>
          <div
            style={{
              width: legend.width,
              height: legend.height,
              background:
                "linear-gradient(to right, " +
                colorStart +
                " , " +
                colorEnd +
                ")",
            }}
          ></div>
          <div style={{ marginLeft: txtGap }}>{max}</div>
        </div>
      </div>
    );
  } else return null;
};

export function Heatmap(props) {
  const {
    layoutSettings,
    chartSettings,
    cells,
    xAxis,
    yAxis,
    labels,
    title,
    caption,
    legend,
    dataX,
    dataY,
    dataWeights,
  } = props;

  const { background, width } = layoutSettings;
  const { margin } = chartSettings;

  const dataWeightsJSON = JSON.stringify(dataWeights);
  const dataXJSON = JSON.stringify(dataX);
  const dataYJSON = JSON.stringify(dataY);
  const marginJSON = JSON.stringify(margin);

  const dispatch = useDispatch();

  const axisTickSize = 6;
  const axisLabelGap = 4;
  const axisTicksMargin = axisTickSize + axisLabelGap;

  const dataRaw = {
    rows: dataX.X,
    columns: dataY.Y,
    heats: dataWeights.Weights,
  };

  const chartRef = React.useRef<HTMLDivElement>(null);
  const { headerRef, height } = useHeaderHeight(
    width,
    layoutSettings,
    caption,
    title,
    legend,
    "" + d3.min(dataRaw.heats) + "" + d3.max(dataRaw.heats)
  );

  const [fontLoaded, setFontLoaded] = React.useState(false);

  // custom fonts loading status
  title["fontLoaded"] = fontLoaded;
  for (let i = 0; i < caption.items.length; i++) {
    caption.items[i]["fontLoaded"] = fontLoaded;
  }

  useEffect(() => {
    document.fonts.ready.then(() => {
      setFontLoaded(true);
    });
  });

  const chartMargins = margin.isPerSide
    ? {
        top: margin.top,
        right: margin.right,
        left: margin.left,
        bottom: margin.bottom,
      }
    : {
        top: margin.margin,
        right: margin.margin,
        left: margin.margin,
        bottom: margin.margin,
      };

  const chart = {
    x:
      yAxis.isVisible && yAxis.orient === "left"
        ? yAxis.width + chartMargins.left
        : chartMargins.left,
    y:
      xAxis.isVisible && xAxis.orient === "top"
        ? xAxis.height + chartMargins.top
        : chartMargins.top,
    width: yAxis.isVisible
      ? width - chartMargins.left - chartMargins.right - yAxis.width
      : width - chartMargins.left - chartMargins.right,
    height: xAxis.isVisible
      ? height - chartMargins.top - chartMargins.bottom - xAxis.height
      : height - chartMargins.top - chartMargins.bottom,
  };

  //y axis X coordinate
  const yAxisXCoord = yAxis.orient === "left" ? chart.x : chart.x + chart.width;
  //y axis ticks X coordinate
  const yAxisTicksXCoord =
    yAxis.orient === "left"
      ? yAxisXCoord - axisTicksMargin
      : yAxisXCoord + axisTicksMargin;

  //x axis Y coordinate
  const xAxisYCoord =
    xAxis.orient === "bottom" ? chart.y + chart.height : chart.y;
  //y axis ticks Y coordinate
  const xAxisTicksYCoord =
    xAxis.orient === "bottom"
      ? xAxisYCoord + axisTicksMargin
      : xAxisYCoord - axisTicksMargin;

  const xAxisLabelX =
    xAxis.labelAlign === "middle"
      ? yAxisXCoord + (chart.width / 2) * (yAxis.orient === "left" ? 1 : -1)
      : xAxis.labelAlign === "left"
      ? chart.x
      : chart.x + chart.width;

  const yAxisLabelY =
    yAxis.labelAlign === "center"
      ? xAxisYCoord + (chart.height / 2) * (xAxis.orient === "top" ? 1 : -1)
      : yAxis.labelAlign === "top"
      ? chart.y
      : chart.y + chart.height;

  function buildData(data, cellsSettins) {
    const rows = data.rows;
    const columns = data.columns;
    const heats = data.heats;

    // Build X scales and axis
    const xScale = d3
      .scaleBand()
      .domain(rows)
      .range([chart.x, chart.x + chart.width]);
    const yScale = d3
      .scaleBand()
      .domain(columns)
      .range([chart.y, chart.y + chart.height]);

    // Set padding to cells
    xScale.padding(cellsSettins.spacing / xScale.bandwidth());
    yScale.padding(cellsSettins.spacing / yScale.bandwidth());

    // Build color scale
    var colorScale = d3
      .scaleLinear()
      .range([cellsSettins.colorStart, cellsSettins.colorEnd])
      .domain([d3.min(heats), d3.max(heats)])
      .clamp(true);
    // .unknown(cellsSettins.colorStart);

    const dataHeatmap = heats.map((elem, i) => {
      return {
        x: xScale(rows[i]),
        y: yScale(columns[i]),
        xName: rows[i],
        yName: columns[i],
        color: colorScale(heats[i]),
        width: xScale.bandwidth(),
        height: yScale.bandwidth(),
        value: heats[i],
      };
    });

    return dataHeatmap;
  }

  const [data, setData] = useState([]);
  const [dataChangedFlag, setDataChangedFlag] = useState(true);

  const svgViewBox = [0, 0, width, height];

  const drawXAxisTicks = (svg) => {
    const xAxisTicks = d3.group(data, (d) => d.xName);

    return svg
      .select("#svgHeatmapAxisXTicks")
      .selectAll("text")
      .data(xAxisTicks)
      .join("text")
      .attr("x", (d) => d[1][0].x + d[1][0].width / 2)
      .attr("y", xAxisTicksYCoord)
      .text((d) => d[0]);
  };

  const drawYAxisTicks = (svg) => {
    const yAxisTicks = d3.group(data, (d) => d.yName);

    return svg
      .select("#svgHeatmapAxisYTicks")
      .selectAll("text")
      .data(yAxisTicks)
      .join("text")
      .attr("x", yAxisTicksXCoord)
      .attr("y", (d) => d[1][0].y + d[1][0].height / 2)
      .text((d) => d[0]);
  };

  const drawXAxisLabel = (svg) => {
    const labelData = {
      x: xAxisLabelX,
      y: xAxisTicksYCoord,
    };

    return svg
      .select("#svgHeatmapAxisXLabel")
      .selectAll("text")
      .data([labelData])
      .join("text")
      .attr("x", (d) => d.x)
      .attr("y", (d) => d.y);
  };

  const drawYAxisLabel = (svg) => {
    const labelData = {
      x: yAxisTicksXCoord,
      y: yAxisLabelY,
    };

    return svg
      .select("#svgHeatmapAxisYLabel")
      .selectAll("text")
      .data([labelData])
      .join("text")
      .attr("x", (d) => d.x)
      .attr("y", (d) => d.y);
  };

  //Apply style
  function applyStyle(svg) {
    svg
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", svgViewBox)
      .style("background-color", background);

    //nodes
    svg
      .select("#svgHeatmapNodes")
      .selectAll("rect")
      .attr("fill", (d) => d.color)
      .attr("rx", cells.radius)
      .attr("ry", cells.radius);

    //nodes labels (heats)
    if (labels.isVisible) {
      svg
        .select("#svgHeatmapLabels")
        .selectAll("text")
        .attr("fill", labels.color)
        .attr("color", labels.color)
        .attr("font-size", labels.fontSize);
    }

    //x axis ticks
    if (xAxis.isTicksVisible) {
      //vertical align
      let dominantBaseline = "central";
      //horizontal align
      let textAnchor = "middle";

      if (xAxis.ticksRotate) {
        switch (xAxis.ticksRotate) {
          case 90:
            textAnchor = xAxis.orient === "bottom" ? "start" : "end";
            break;
          case -90:
            textAnchor = xAxis.orient === "bottom" ? "end" : "start";
            break;
        }
      } else {
        if (xAxis.orient === "bottom") {
          dominantBaseline = "hanging";
        } else {
          dominantBaseline = "top";
        }
      }

      svg
        .select("#svgHeatmapAxisXTicks")
        .selectAll("text")
        .attr("fill", xAxis.ticksColor)
        .attr("color", xAxis.ticksColor)
        .attr("font-size", xAxis.ticksFontSize)
        .attr("dominant-baseline", dominantBaseline)
        .attr("text-anchor", textAnchor)
        .attr(
          "transform",
          (d) =>
            "rotate(" +
            xAxis.ticksRotate +
            " " +
            (d[1][0].x + d[1][0].width / 2) +
            " " +
            xAxisTicksYCoord +
            ")"
        );
    }

    //y axis ticks
    if (yAxis.isTicksVisible) {
      //vertical align
      const dominantBaseline = "central";
      //horizontal align
      const textAnchor = yAxis.orient === "left" ? "end" : "start";

      svg
        .select("#svgHeatmapAxisYTicks")
        .selectAll("text")
        .attr("fill", yAxis.ticksColor)
        .attr("color", yAxis.ticksColor)
        .attr("font-size", yAxis.ticksFontSize)
        .attr("dominant-baseline", dominantBaseline)
        .attr("text-anchor", textAnchor);
    }

    //x axis label
    if (xAxis.isLabelVisible) {
      //vertical align
      let dominantBaseline = "central";
      if (xAxis.orient === "bottom") {
        dominantBaseline = "hanging";
      } else {
        dominantBaseline = "top";
      }

      //horizontal align
      let textAnchor =
        xAxis.labelAlign === "middle"
          ? "middle"
          : xAxis.labelAlign === "left"
          ? "start"
          : "end";

      svg
        .select("#svgHeatmapAxisXLabel")
        .selectAll("text")
        .attr(
          "y",
          (d) => d.y - xAxis.labelMargin * (xAxis.orient === "top" ? 1 : -1)
        )
        .attr("x", xAxisLabelX)
        .attr("dominant-baseline", dominantBaseline)
        .attr("text-anchor", textAnchor)
        .attr("fill", xAxis.labelColor)
        .attr("color", xAxis.labelColor)
        .attr("font-size", xAxis.labelFontSize)
        .text(xAxis.labelText);
    }

    //y axis label
    if (yAxis.isLabelVisible) {
      //vertical align
      const dominantBaseline = yAxis.orient === "left" ? "top" : "hanging";
      //horizontal align
      let textAnchor = "middle";
      switch (yAxis.labelAlign) {
        case "bottom":
          textAnchor = yAxis.labelRotate === -90 ? "start" : "end";
          break;
        case "top":
          textAnchor = yAxis.labelRotate === -90 ? "end" : "start";
          break;
      }

      svg
        .select("#svgHeatmapAxisYLabel")
        .selectAll("text")
        .attr(
          "x",
          (d) => d.x - yAxis.labelMargin * (yAxis.orient === "left" ? 1 : -1)
        )
        .attr("y", yAxisLabelY)
        .attr(
          "transform",
          (d) =>
            "rotate(" +
            yAxis.labelRotate +
            " " +
            (d.x - yAxis.labelMargin * (yAxis.orient === "left" ? 1 : -1)) +
            " " +
            yAxisLabelY +
            ")"
        )
        .attr("dominant-baseline", dominantBaseline)
        .attr("text-anchor", textAnchor)
        .attr("fill", yAxis.labelColor)
        .attr("color", yAxis.labelColor)
        .attr("font-size", yAxis.labelFontSize)
        .text(yAxis.labelText);
    }
  }

  //Updating when recieve new data (and first time mounted)
  useEffect(() => {
    const svg = d3.select("#svgHeatmap");

    //Draw nodes
    svg
      .select("#svgHeatmapNodes")
      .selectAll("rect")
      .data(data)
      .join("rect")
      .attr("width", (d) => d.width)
      .attr("height", (d) => d.height)
      .attr("x", (d) => d.x)
      .attr("y", (d) => d.y);

    //Draw heats labels
    if (labels.isVisible) {
      svg
        .select("#svgHeatmapLabels")
        .selectAll("text")
        .data(data)
        .join("text")
        .attr("x", (d) => d.x + d.width / 2)
        .attr("y", (d) => d.y + d.height / 2)
        .attr("text-anchor", "middle")
        .attr("alignment-baseline", "central")
        .text((d) => d.value);
    } else {
      svg.select("#svgHeatmapLabels").selectAll("text").remove();
    }

    //Draw axes
    if (xAxis.isVisible) {
      //Draw X axis label
      if (xAxis.isLabelVisible) {
        drawXAxisLabel(svg);
      } else {
        svg.select("#svgHeatmapAxisXLabel").selectAll("text").remove();
      }

      //Draw X axis ticks
      if (xAxis.isTicksVisible) {
        drawXAxisTicks(svg);
      } else {
        svg.select("#svgHeatmapAxisXTicks").selectAll("text").remove();
      }
    } else {
      svg.select("#svgHeatmapAxisX").selectAll("text").remove();
    }

    if (yAxis.isVisible) {
      //Draw Y axis label
      if (yAxis.isLabelVisible) {
        drawYAxisLabel(svg);
      } else {
        svg.select("#svgHeatmapAxisYLabel").selectAll("text").remove();
      }

      //Draw Y axis ticks
      if (yAxis.isTicksVisible) {
        drawYAxisTicks(svg);
      } else {
        svg.select("#svgHeatmapAxisYTicks").selectAll("text").remove();
      }
    } else {
      svg.select("#svgHeatmapAxisY").selectAll("text").remove();
    }
  }, [dataChangedFlag]);

  //Updating style and coordinates settings
  useEffect(() => {
    var div = d3.select(chartRef.current);
    applyStyle(div.select("#svgHeatmap"));
  });

  useEffect(() => {
    const heatmapData = buildData(dataRaw, cells);
    setData(heatmapData);
    setDataChangedFlag(!dataChangedFlag);
  }, [
    width,
    height,
    cells.spacing,
    cells.colorStart,
    cells.colorEnd,
    labels.isVisible,
    xAxis.isVisible,
    xAxis.height,
    xAxis.orient,
    xAxis.isTicksVisible,
    xAxis.isLabelVisible,
    yAxis.isVisible,
    yAxis.width,
    yAxis.orient,
    yAxis.isTicksVisible,
    yAxis.isLabelVisible,
    dataWeightsJSON,
    dataXJSON,
    dataYJSON,
    marginJSON,
  ]);

  useEffect(() => {
    const element = chartRef.current;
    if (element) {
      const svg = element;
      dispatch(UPDATE_CHART_REF(svg));
    }
  }, [chartRef, dispatch]);

  return (
    <div id="Heatmap" ref={chartRef} style={{ background: background }}>
      <Helmet>
        <link
          rel="stylesheet"
          href={
            "https://fonts.googleapis.com/css?family=" +
            title.fontFamily.replace(/ /g, "+") +
            ":" +
            title.fontVariants
          }
          crossOrigin="anonymous"
        />
        {caption.items.map((elem, i) => {
          return (
            <link
              key={"caption-" + i}
              rel="stylesheet"
              href={
                "https://fonts.googleapis.com/css?family=" +
                elem.fontFamily.replace(/ /g, "+") +
                ":" +
                elem.fontVariants
              }
              crossOrigin="anonymous"
            />
          );
        })}
      </Helmet>
      <div ref={headerRef} style={{ width: width }}>
        {title.isVisible ? (
          <TextBlock key={"title"} title={title} fontLoaded={fontLoaded} />
        ) : (
          ""
        )}
        {caption.isVisible
          ? caption.items.map((elem, i) => {
              return (
                <TextBlock
                  key={"caption" + i}
                  title={elem}
                  fontLoaded={fontLoaded}
                />
              );
            })
          : ""}
        <HeatmapLegend
          legend={legend}
          min={d3.min(dataRaw.heats)}
          max={d3.max(dataRaw.heats)}
          colorStart={cells.colorStart}
          colorEnd={cells.colorEnd}
          width={width}
          height={height}
          viewBox={svgViewBox}
        />
        {/* {legend.isVisible ? (
          <CustomLegend legendProps={legend} items={scatters.items} />
        ) : (
          ""
        )} */}
      </div>
      <svg id="svgHeatmap" className="chart-surface">
        <g id="chartContainer">
          <g id="svgHeatmapAxisX">
            <g id="svgHeatmapAxisXLabel" />
            <g id="svgHeatmapAxisXTicks" />
          </g>
          <g id="svgHeatmapAxisY">
            <g id="svgHeatmapAxisYLabel" />
            <g id="svgHeatmapAxisYTicks" />
          </g>
          <g id="svgHeatmapNodes" />
          <g id="svgHeatmapLabels" />
        </g>
      </svg>
    </div>
  );
}

const dimensions = {
  X: ControlType.String,
  Y: ControlType.String,
  Weights: ControlType.Number,
};

Heatmap.defaultProps = {
  isData: false,
  layoutSettings: {
    isFullscreen: true,
    isWidthAuto: true,
    minWidth: 320,
    maxWidth: 2500,
    isHeightAuto: true,
    background: "rgba(255, 255, 255, 1)",
  },
  chartSettings: {
    margin: {
      margin: 0,
      isPerSide: false,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  sourceData: true,
  fileData: null,
  cells: {
    spacing: 0,
    colorStart: "rgba(17, 153, 238, 0)",
    colorEnd: "rgba(17, 153, 238, 1)",
    radius: 0,
  },
  labels: {
    isVisible: true,
    color: "rgba(0,0,0,.5)",
    fontSize: 12,
  },
  xAxis: {
    isVisible: false,
    orient: "top",
    height: 40,
    isTicksVisible: true,
    ticksColor: "rgba(0, 0, 0, 1)",
    ticksFontSize: 12,
    ticksRotate: 0,
    isLabelVisible: false,
    labelText: "Axis label",
    labelAlign: "middle",
    labelMargin: 20,
    labelColor: "rgba(0, 0, 0, 1)",
    labelFontSize: 12,
  },
  yAxis: {
    isVisible: false,
    orient: "left",
    width: 40,
    isTicksVisible: true,
    ticksColor: "rgba(0, 0, 0, 1)",
    ticksFontSize: 12,
    isLabelVisible: false,
    labelText: "Axis label",
    labelAlign: "center",
    labelMargin: 30,
    labelColor: "rgba(0, 0, 0, 1)",
    labelFontSize: 12,
    labelRotate: -90,
  },
  title: {
    isVisible: false,
    text: "Chart title",
    txtColor: "rgba(0,0,0,1)",
    fontSize: 48,
    fontFamily: "Roboto",
    fontVariants: "regular",
    align: "center",
    margin: {
      margin: 0,
      isPerSide: true,
      top: 20,
      bottom: 20,
      left: 0,
      right: 0,
    },
  },
  caption: {
    isVisible: false,
    items: [
      {
        text: "Chart description or data source",
        txtColor: "rgba(0,0,0,.5)",
        fontSize: 18,
        fontFamily: "Roboto",
        fontVariants: "300",
        align: "center",
        margin: {
          margin: 0,
          isPerSide: true,
          top: 20,
          bottom: 20,
          left: 0,
          right: 0,
        },
      },
    ],
  },
  legend: {
    isVisible: false,
    txtColor: "rgba(0,0,0,1)",
    fontSize: 14,
    width: 100,
    height: 10,
    align: "center",
    margin: {
      margin: 0,
      isPerSide: true,
      top: 20,
      bottom: 20,
      left: 0,
      right: 0,
    },
  },
  dataX: {
    get X() {
      const cycle = 20;
      let arr: number[] = [];
      for (let j = 0; j < cycle; j++) {
        for (let i = 0; i < cycle; i++) {
          arr.push(i + 1);
        }
      }
      return arr;
    },
  },
  dataY: {
    get Y() {
      const cycle = 20;
      let arr: number[] = [];
      for (let j = 0; j < cycle; j++) {
        for (let i = 0; i < cycle; i++) {
          arr.push(j + 1);
        }
      }
      return arr;
    },
  },
  dataWeights: {
    get Weights() {
      const cycle = 20;
      let arr: number[] = [];
      for (let j = 0; j < cycle; j++) {
        for (let i = 0; i < cycle; i++) {
          arr.push(Math.round(Math.random() * 100) / 100);
        }
      }
      return arr;
    },
  },
  dimensions: dimensions,
};

applyPropertyControls(Heatmap, {
  //Settings or data
  isData: {
    type: ControlType.Boolean,
    title: "Change",
    disabledTitle: "settings",
    enabledTitle: "data",
    wide: true,
  },

  layoutSettings: presetLayout,

  chartSettings: {
    type: ControlType.Group,
    title: "Chart settings",
    isHeaderControls: false,
    propertyControl: {
      margin: {
        type: ControlType.FusedNumber,
        title: "Margin",
        toggleKey: "isPerSide",
        toggleTitles: ["All", "Individual"],
        valueKeys: ["top", "right", "bottom", "left"],
        valueLabels: ["T", "R", "B", "L"],
        min: 0,
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Cells
  cells: {
    type: ControlType.Group,
    title: "Cells",
    isHeaderControls: false,
    propertyControl: {
      spacing: {
        type: ControlType.Number,
        title: "Spacing",
        min: 0,
        displayStepper: true,
      },
      colorStart: {
        title: "Color 1",
        type: ControlType.Color,
      },
      colorEnd: {
        title: "Color 2",
        type: ControlType.Color,
      },
      radius: {
        type: ControlType.Number,
        title: "Radius",
        min: 0,
        displayStepper: true,
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Axes
  xAxis: {
    type: ControlType.Group,
    title: "X axis",
    isHeaderControls: true,
    propertyControl: {
      isVisible: {
        type: ControlType.Boolean,
        enabledTitle: "show",
        disabledTitle: "hide",
        hidden(props) {
          return true;
        },
      },
      orient: {
        type: ControlType.Enum,
        title: "Orient",
        options: ["bottom", "top"],
        optionTitles: ["bottom", "top"],
        displaySegmentedControl: true,
      },
      height: {
        type: ControlType.Number,
        title: "Height",
      },
      isTicksVisible: {
        type: ControlType.Boolean,
        title: "Ticks",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      ticksColor: {
        type: ControlType.Color,
        title: "↳ Color",
        hidden(props) {
          return props.isTicksVisible === false;
        },
      },
      ticksFontSize: {
        type: ControlType.Number,
        title: "↳ Font size",
        hidden(props) {
          return props.isTicksVisible === false;
        },
      },
      ticksRotate: {
        type: ControlType.Enum,
        title: "↳ Rotate",
        options: [-90, 0, 90],
        optionTitles: ["-90°", "0°", "90°"],
        displaySegmentedControl: true,
        hidden(props) {
          return props.isTicksVisible === false;
        },
      },
      isLabelVisible: {
        type: ControlType.Boolean,
        title: "Label",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      labelText: {
        title: "↳ Text",
        type: ControlType.String,
        placeholder: "",
        hidden(props) {
          return props.isLabelVisible === false;
        },
      },
      labelAlign: {
        type: ControlType.Enum,
        title: "↳ Align",
        options: ["left", "middle", "right"],
        optionTitles: ["left", "middle", "right"],
        displaySegmentedControl: true,
        hidden(props) {
          return props.isLabelVisible === false;
        },
      },
      labelMargin: {
        type: ControlType.Number,
        title: "↳ Margin",
        min: 0,
        hidden(props) {
          return props.isLabelVisible === false;
        },
      },
      labelColor: {
        type: ControlType.Color,
        title: "↳ Color",
        hidden(props) {
          return props.isLabelVisible === false;
        },
      },
      labelFontSize: {
        type: ControlType.Number,
        title: "↳ Font size",
        hidden(props) {
          return props.isLabelVisible === false;
        },
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  yAxis: {
    type: ControlType.Group,
    title: "Y axis",
    isHeaderControls: true,
    propertyControl: {
      isVisible: {
        type: ControlType.Boolean,
        enabledTitle: "show",
        disabledTitle: "hide",
        hidden(props) {
          return true;
        },
      },
      orient: {
        type: ControlType.Enum,
        title: "Orient",
        options: ["left", "right"],
        optionTitles: ["left", "right"],
        displaySegmentedControl: true,
      },
      width: {
        type: ControlType.Number,
        title: "Width",
      },
      isTicksVisible: {
        type: ControlType.Boolean,
        title: "Ticks",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      ticksColor: {
        type: ControlType.Color,
        title: "↳ Color",
        hidden(props) {
          return props.isTicksVisible === false;
        },
      },
      ticksFontSize: {
        type: ControlType.Number,
        title: "↳ Font size",
        hidden(props) {
          return props.isTicksVisible === false;
        },
      },
      isLabelVisible: {
        type: ControlType.Boolean,
        title: "Label",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      labelText: {
        title: "↳ Text",
        type: ControlType.String,
        placeholder: "",
        hidden(props) {
          return props.isLabelVisible === false;
        },
      },
      labelAlign: {
        type: ControlType.Enum,
        title: "↳ Align",
        options: ["top", "center", "bottom"],
        optionTitles: ["top", "center", "bottom"],
        displaySegmentedControl: true,
        hidden(props) {
          return props.isLabelVisible === false;
        },
      },
      labelMargin: {
        type: ControlType.Number,
        title: "↳ Margin",
        min: 0,
        hidden(props) {
          return props.isLabelVisible === false;
        },
      },
      labelColor: {
        type: ControlType.Color,
        title: "↳ Color",
        hidden(props) {
          return props.isLabelVisible === false;
        },
      },
      labelFontSize: {
        type: ControlType.Number,
        title: "↳ Font size",
        hidden(props) {
          return props.isLabelVisible === false;
        },
      },
      labelRotate: {
        type: ControlType.Enum,
        title: "↳ Rotate",
        options: [-90, 90],
        optionTitles: ["-90°", "90°"],
        displaySegmentedControl: true,
        hidden(props) {
          return props.isLabelVisible === false;
        },
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Labels
  labels: {
    type: ControlType.Group,
    title: "Labels",
    isHeaderControls: true,
    propertyControl: {
      isVisible: {
        type: ControlType.Boolean,
        enabledTitle: "show",
        disabledTitle: "hide",
        hidden(props) {
          return true;
        },
      },
      color: {
        type: ControlType.Color,
        title: "Color",
      },
      fontSize: {
        type: ControlType.Number,
        title: "Size",
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Title
  title: presetTitle,

  //Caption
  caption: presetCaption,

  //Legend
  legend: {
    type: ControlType.Group,
    title: "Legend",
    isHeaderControls: true,
    propertyControl: {
      isVisible: {
        type: ControlType.Boolean,
        enabledTitle: "show",
        disabledTitle: "hide",
        hidden(props) {
          return true;
        },
      },
      txtColor: {
        title: "Text color",
        type: ControlType.Color,
      },
      fontSize: {
        type: ControlType.Number,
        title: "Font size",
        min: 0,
        max: 500,
        displayStepper: false,
      },
      width: {
        type: ControlType.Number,
        title: "Width",
        min: 0,
      },
      height: {
        type: ControlType.Number,
        title: "Height",
        min: 0,
        max: 100,
      },
      align: {
        type: ControlType.Enum,
        title: "Align",
        options: ["left", "center", "right"],
        optionTitles: ["left", "center", "right"],
        displaySegmentedControl: true,
      },
      margin: {
        type: ControlType.FusedNumber,
        title: "Margin",
        toggleKey: "isPerSide",
        toggleTitles: ["All", "Individual"],
        valueKeys: ["top", "right", "bottom", "left"],
        valueLabels: ["T", "R", "B", "L"],
        min: 0,
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Data
  sourceData: {
    type: ControlType.Boolean,
    title: "Data",
    enabledTitle: "editable",
    disabledTitle: "file",
    hidden(props) {
      return props.isData === false;
    },
  },
  fileData: {
    title: "↳ File",
    type: ControlType.File,
    allowedFileTypes: ["xls"],
    hidden(props) {
      return props.sourceData === true || props.isData === false;
    },
  },

  //X categories
  dataX: {
    type: ControlType.Group,
    title: "X categories",
    propertyControl: {
      X: {
        type: ControlType.Array,
        title: "X Axis",
        propertyControl: {
          type(props) {
            return props.dimensions.X;
          },
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },

  //Y categories
  dataY: {
    type: ControlType.Group,
    title: "Y categories",
    propertyControl: {
      Y: {
        type: ControlType.Array,
        title: "Y Axis",
        propertyControl: {
          type(props) {
            return props.dimensions.Y;
          },
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },

  //Weights
  dataWeights: {
    type: ControlType.Group,
    title: "Weights",
    propertyControl: {
      Weights: {
        type: ControlType.Array,
        title: "Values",
        propertyControl: {
          type(props) {
            return props.dimensions.Weights;
          },
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },

  //Chart's dimensions
  dimensions: {
    type: ControlType.Group,
    title: "Dimensions",
    propertyControl: {
      X: {
        type: ControlType.Enum,
        seriesType: false,
        title: "X",
        path: "dataX",
        options: [ControlType.String, ControlType.Number],
      },
      Y: {
        type: ControlType.Enum,
        seriesType: false,
        title: "Y",
        path: "dataY",
        options: [ControlType.String, ControlType.Number],
      },
      Weights: {
        type: ControlType.Enum,
        seriesType: false,
        title: "Weights",
        path: "dataWeights",
        options: [ControlType.Number],
      },
    },
    hidden() {
      return true;
    },
  },
});

export default Heatmap;
