import React from "react";
import { Typography } from "@mui/material";

const FilePreviewHeader = (props) => {
  const {
    fileType,
    rowsCount,
    MAX_ROWS,
    columnsCount,
    chartDimensionsTitles,
    isHierarchy,
    isSankey,
  } = props;
  return (
    <>
      <Typography variant="subtitle2">
        {fileType} file uploaded 👌🏻 rows: {rowsCount}{" "}
        {rowsCount > MAX_ROWS ? (
          <span style={{ opacity: 0.5 }}>(shown: {MAX_ROWS})</span>
        ) : (
          ""
        )}
        , columns: {columnsCount}
      </Typography>
      <Typography variant="h4" style={{ marginBottom: 16 }}>
        Choose data (columns) for chart
        {/* {isHierarchy || isSankey
          ? "Choose data (columns) for chart"
          : "Choose data (columns) for chart's axes: " +
            chartDimensionsTitles.map((item, i) => {
              return i > 0 ? " " + item : item;
            })} */}
      </Typography>
    </>
  );
};

export default FilePreviewHeader;
