import React from "react";
import "./inputContainer.css";

export function InputContainer(props) {
  const { isArray, isWide, children, controlName } = props;

  const renderArrayItem = (
    <>
      <div style={{ marginLeft: 8, marginTop: 4, marginBottom: 4 }}>
        {children[0]}
      </div>
      <div style={{ width: "100%", marginTop: 4, marginBottom: 4 }}>
        {children[1]}
      </div>
      <div
        style={{ marginRight: 8, marginLeft: 8, marginTop: 4, marginBottom: 4 }}
      >
        {children[2]}
      </div>
    </>
  );

  //"settings/data" toggle
  const isDataStyle = {
    width: "100%",
    marginBottom: 8,
    paddingBottom: 16,
    backgroundColor: "#fff",
    borderBottom: "solid 1px rgba(0,0,0,.12)",
  } as React.CSSProperties;

  const renderToggle = (
    <div
      className="controlItem"
      style={controlName === "isData" ? isDataStyle : {}}
    >
      {children[1]}
    </div>
  );

  const controlItem = (
    <>
      <div
        style={{
          width: "40%",
          minHeight: 24,
          display: "flex",
          alignItems: "center",
          marginLeft: 8,
          marginTop: 4,
          marginBottom: 4,
        }}
      >
        {children[0]}
      </div>
      <div
        style={{
          width: "60%",
          minHeight: 24,
          display: "flex",
          alignItems: "center",
          marginRight: 8,
          marginTop: 4,
          marginBottom: 4,
        }}
      >
        {children[1]}
      </div>
    </>
  );

  return (
    <div
      className="controlItem"
      style={{
        width: "100%",
        display: "flex",
        columnGap: "8px",
      }}
    >
      {isArray ? renderArrayItem : isWide ? renderToggle : controlItem}
    </div>
  );
}

export default InputContainer;
