import React from "react";
import { ControlType } from "./controltype";
import { getChart } from "../utils/utils";
import { InputBoolean } from "./inputBoolean";
import { InputNumber } from "./inputNumber";
import { InputColor } from "./inputColor";
import { InputEnum } from "./inputEnum";
import { InputComboBox } from "./inputComboBox";
import { InputString } from "./inputString";
import { InputText } from "./inputText";
import { InputFusedNumber } from "./inputFusedNumber";
import { InputArray } from "./inputArray";
import { ControlTitle } from "./controlTitle";
import { useSelector, useDispatch } from "react-redux";
import { InputContainer } from "./inputContainer";
import { InputFile } from "./inputFile";
import { InputStatus } from "./inputStatus";
import {
  REMOVE_GROUP,
  TOGGLE_GROUP,
  TOGGLE_ALLGROUPS,
  ADD_GROUP,
  SWAP_GROUPS,
} from "../utils/actions";
import { Divider, Button } from "@mui/material";
import {
  collectionHeader,
  collectionItemHeader,
  groupHeader,
} from "./groupHeaders";
import "./rightdrawer.css";

const iControl = (name, title, i, isWide?) => {
  return (
    <InputContainer isWide={isWide} controlName={name}>
      <ControlTitle title={title} />
      {i}
    </InputContainer>
  );
};

const iBoolean = (name, settings) => {
  const i = <InputBoolean key={name} name={name} inputSettings={settings} />;
  return iControl(name, settings.title, i, settings.wide);
};
const iColor = (name, settings) => {
  const i = <InputColor key={name} name={name} inputSettings={settings} />;
  return iControl(name, settings.title, i);
};
const iEnum = (name, settings) => {
  const i = <InputEnum key={name} name={name} inputSettings={settings} />;
  return iControl(name, settings.title, i);
};
const iComboBox = (name, settings) => {
  const i = <InputComboBox key={name} name={name} inputSettings={settings} />;
  return iControl(name, settings.title, i);
};
const iFile = (name, settings) => {
  const i = <InputFile key={name} name={name} inputSettings={settings} />;
  return iControl(name, settings.title, i);
};
const iFusedNumber = (path, settings) => {
  const i = (
    <InputFusedNumber key={path} path={path} inputSettings={settings} />
  );
  return iControl(path, settings.title, i);
};
const iNumber = (name, settings) => {
  const i = <InputNumber key={name} name={name} inputSettings={settings} />;
  return iControl(name, settings.title, i);
};
const iString = (name, settings) => {
  const i = <InputString key={name} name={name} inputSettings={settings} />;
  return iControl(name, settings.title, i);
};
const iText = (name, settings) => {
  const i = <InputText key={name} name={name} inputSettings={settings} />;
  return iControl(name, settings.title, i);
};
const iStatus = (name, settings) => {
  const i = <InputStatus key={name} name={name} inputSettings={settings} />;
  return iControl(name, settings.title, i);
};
const iArray = (name, settings, groupProps) => {
  const i = (
    <InputArray
      name={name}
      inputSettings={settings}
      collectionLabel={groupProps?.collectionLabel}
      collectionItemIndex={groupProps?.collectionItemIndex}
      collectionItemLabel={groupProps?.collectionItemLabel}
      groupLabel={groupProps?.groupLabel}
    />
  );

  return iControl(name, settings.title, i);
};

//draws chart controls with specified settings
export function ChartSettings() {
  const currentChartType = useSelector(
    (state: any) => state.chart.currentChartType
  );
  const chartSettings = useSelector((state: any) => state.chart.chartSettings);
  const chartUI = useSelector((state: any) => state.chart.chartUI) || {};

  const dispatch = useDispatch();
  const handleAddGroup = (name) => {
    dispatch(ADD_GROUP(name));
  };
  const handleRemoveGroup = (name, index) => {
    dispatch(REMOVE_GROUP(name, index));
  };
  const handleToggleGroup = (name, index) => {
    dispatch(TOGGLE_GROUP(name, index));
  };
  const handleToggleGroups = (name, isCollaps) => {
    dispatch(TOGGLE_ALLGROUPS(name, isCollaps));
  };
  const handleMoveGroupUp = (name, index) => {
    dispatch(SWAP_GROUPS(name, index, index - 1));
  };
  const handleMoveGroupDown = (name, index) => {
    dispatch(SWAP_GROUPS(name, index, index + 1));
  };

  //function parseControls - generate controls components array
  //propertyControls - chart's controls from config (propertyControls)
  //actualSettings - actual settings values from redux
  //actualUI - actual settings values from redux if control in MultiGroup or Array
  function parseControls(propertyControls, actualSettings, actualUI) {
    //recursive controls tree challenge
    //parent? - parent node, if present
    //arrayProps? -
    function fillOutput(
      chartControls,
      settings,
      UI,
      path = "",
      parent = "",
      groupProps?
    ) {
      const controls = Object.assign({}, chartControls);
      for (var item in controls) {
        let isHidden = false;
        //check, if control must be visible or not
        if (controls[item].hidden) {
          isHidden = controls[item].hidden(settings, UI);
        }
        if (!isHidden) {
          let parentsArr = path.split(".");
          if (parent && parentsArr[parentsArr.length - 1] !== parent) {
            path += path ? "." + parent : parent;
          }

          const name = path ? path + "." + item : item;
          // if title is function, execute it with chart's settings as prop
          const title =
            typeof controls[item].title === "function"
              ? controls[item].title(settings, UI)
              : controls[item].title || name;
          const itemSettings = { ...controls[item], title: title };

          switch (controls[item].type) {
            case ControlType.Collection:
              // true if all items collapsed, overwise - false
              let isCollectionItemsCollapsed = true;
              const itemsCount = UI[name].items.length;
              for (let i = 0; i < itemsCount; i++) {
                if (UI[name].items[i].collapsed === false) {
                  isCollectionItemsCollapsed = false;
                }
              }
              output.push(
                collectionHeader(
                  title,
                  name,
                  controls[item].isHeaderControls,
                  UI[name].isVisible,
                  isCollectionItemsCollapsed,
                  itemsCount,
                  handleToggleGroups
                )
              );
              // if we have collection and it's visible, then start recursive
              if (
                !controls[item].isHeaderControls ||
                (controls[item].isHeaderControls && UI[name].isVisible)
              ) {
                const collectionPropertyControls =
                  typeof controls[item].propertyControl === "function"
                    ? controls[item].propertyControl(UI)
                    : controls[item].propertyControl;
                for (let i = 0; i < UI[name].items.length; i++) {
                  // if isGroupsColorLegend is true, get first color for label,
                  // search for color in collection item if not found, check the ref
                  let itemColor = null;
                  if (controls[item].isGroupsColorLegend) {
                    for (let elem in collectionPropertyControls) {
                      if (
                        collectionPropertyControls[elem].type ===
                        ControlType.Color
                      ) {
                        itemColor = UI[name].items[i].value[elem];
                        break;
                      }
                    }
                    if (!itemColor) {
                      const refCollection = controls[item].ref;
                      if (refCollection) {
                        const refCollectionPropertyControls =
                          typeof controls[refCollection].propertyControl ===
                          "function"
                            ? controls[refCollection].propertyControl(UI)
                            : controls[refCollection].propertyControl;
                        for (let elem in refCollectionPropertyControls) {
                          if (
                            refCollectionPropertyControls[elem].type ===
                            ControlType.Color
                          ) {
                            itemColor = UI[refCollection].items[i].value[elem];
                            break;
                          }
                        }
                      }
                    }
                  }
                  //get label
                  let itemLabel = UI[name].items[i].value.name;
                  if (!itemLabel) {
                    const refCollection = controls[item].ref;
                    if (refCollection) {
                      if (UI[refCollection].items[i]) {
                        itemLabel = UI[refCollection].items[i].value.name;
                      }
                    }
                  }
                  // if didn't find group label in "name" field, check "text"
                  if (!itemLabel) {
                    itemLabel = UI[name].items[i].value.text;
                  }

                  // get group index
                  const itemIndexType = controls[name].indexType || "numbers";
                  const itemIndex =
                    itemIndexType === "letters"
                      ? String.fromCharCode("A".charCodeAt(0) + i)
                      : i + 1;

                  // add group header
                  output.push(
                    collectionItemHeader(
                      // if no title, show numeric or letter index
                      itemLabel || itemIndex,
                      // show "visibility checkbox" if several items are visible and hide it if only one
                      !(
                        !UI[name].items[i].hidden &&
                        UI[name].items.filter((item) => !item.hidden).length ===
                          1
                      ),
                      UI[name].items.length > 1,
                      name,
                      i,
                      UI[name].items.length,
                      UI[name].items[i].collapsed,
                      UI[name].items[i].hidden,
                      itemColor,
                      handleRemoveGroup,
                      handleToggleGroup,
                      handleMoveGroupUp,
                      handleMoveGroupDown
                    )
                  );
                  if (!UI[name].items[i].collapsed) {
                    fillOutput(
                      collectionPropertyControls,
                      UI[name].items[i].value,
                      UI,
                      name + ".items." + i,
                      "value",
                      {
                        collectionLabel: title,
                        collectionItemIndex: i,
                        collectionItemLabel: itemLabel || itemIndex,
                        groupLabel: undefined,
                      }
                    );
                  }
                }
                const multiGroup = name;
                output.push(<Divider style={{ margin: "16px 0 16px 0" }} />);
                output.push(
                  <Button
                    variant="outlined"
                    size="small"
                    style={{ width: "100%" }}
                    onClick={() => handleAddGroup(multiGroup)}
                  >
                    add one
                  </Button>
                );
              }
              break;
            case ControlType.Group:
              output.push(
                groupHeader(title, name, controls[item].isHeaderControls)
              );
              // if we have group and it's visible, then start recursive
              if (
                !controls[item].isHeaderControls ||
                (controls[item].isHeaderControls && UI[name].isVisible)
              ) {
                const groupPropertyControls =
                  typeof controls[item].propertyControl === "function"
                    ? controls[item].propertyControl(UI)
                    : controls[item].propertyControl;
                fillOutput(groupPropertyControls, UI[name], UI, name, "", {
                  collectionLabel: undefined,
                  collectionItemIndex: undefined,
                  collectionItemLabel: undefined,
                  groupLabel: title,
                });
              }
              break;
            case ControlType.Array:
              output.push(iArray(name, itemSettings, groupProps));
              break;
            case ControlType.Boolean:
              output.push(iBoolean(name, itemSettings));
              break;
            case ControlType.Color:
              output.push(iColor(name, itemSettings));
              break;
            case ControlType.Enum:
              output.push(iEnum(name, itemSettings));
              break;
            case ControlType.ComboBox:
              output.push(iComboBox(name, itemSettings));
              break;
            case ControlType.File:
              output.push(iFile(name, itemSettings));
              break;
            case ControlType.FusedNumber:
              output.push(iFusedNumber(name, itemSettings));
              break;
            case ControlType.Number:
              output.push(iNumber(name, itemSettings));
              break;
            case ControlType.String:
              output.push(iString(name, itemSettings));
              break;
            case ControlType.Text:
              output.push(iText(name, itemSettings));
              break;
            case ControlType.Status:
              output.push(iStatus(name, itemSettings));
              break;
            default:
              output.push(<></>);
          }
        }
      }
    }

    let output: Array<JSX.Element> = [];
    fillOutput(propertyControls, actualSettings, actualUI);

    return output;
  }

  // get chart's controls from config
  // don't actually understand why propertyControls in "type"
  const chartControls = getChart(currentChartType).type.propertyControls;
  const UIControls = parseControls(chartControls, chartSettings, chartUI);

  // get "settings/data" control to render it separately from other controls
  const controlIndex = UIControls.findIndex(
    (item) => item.props.controlName === "isData"
  );
  const isDataControl = UIControls.splice(controlIndex, 1);

  return (
    <>
      <div style={{ padding: "0 16px 0 16px" }}>
        {isDataControl.length > 0 ? isDataControl[0] : ""}
      </div>
      <div className="scrollControls">
        {UIControls.map((item, i) => (
          <React.Fragment key={"control-" + i}>{item}</React.Fragment>
        ))}
      </div>
    </>
  );
}

export default ChartSettings;
