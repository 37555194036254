import * as React from "react";
import { ReferenceDot, ReferenceLine } from "recharts";
import { CustomizedDot } from "./customDot";
import { CustomizedDotLabel, CustomizedRefLineLabel } from "./customLabel";
import {
  getChartCenterX,
  getChartCenterY,
  getChartViewBox,
  getRefDotCoordinates,
} from "../utils/utils";

export const CustomizedRefArea = (props) => {
  const { cProps, ...rest } = props;

  const {
    elem,
    dataTypes,
    existedValue,
    layout,
    xAxisSettings,
    yAxisSettings,
    width,
    height,
    marginSettings,
  } = cProps;

  const dot1 = getRefDotCoordinates(
    dataTypes.items,
    dataTypes.series,
    elem.x1CoordinateNumber,
    elem.x1CoordinateCategory,
    elem.y1CoordinateNumber,
    elem.y1CoordinateCategory,
    existedValue.x,
    existedValue.y,
    layout
  );

  const dot2 = getRefDotCoordinates(
    dataTypes.items,
    dataTypes.series,
    elem.x2CoordinateNumber,
    elem.x2CoordinateCategory,
    elem.y2CoordinateNumber,
    elem.y2CoordinateCategory,
    existedValue.x,
    existedValue.y,
    layout
  );

  const vBox = getChartViewBox(
    width,
    height,
    marginSettings,
    xAxisSettings,
    yAxisSettings
  );

  return (
    <ReferenceLine
      {...rest}
      xAxis={rest.xAxisMap[0]}
      yAxis={rest.yAxisMap[0]}
      viewBox={vBox}
      segment={[
        { x: dot1.x, y: dot1.y },
        { x: dot2.x, y: dot2.y },
      ]}
      stroke={elem.lineColor}
      strokeWidth={elem.lineStrokeWidth}
      ifOverflow={"hidden"} // clip to the canvas
      shape={(props) => {
        return (
          <rect
            x={
              dot1.isX && dot2.isX
                ? Math.min(props.x1, props.x2)
                : dot1.isX
                ? props.x1
                : dot2.isX
                ? vBox.x
                : vBox.x
            }
            y={
              dot1.isY && dot2.isY
                ? Math.min(props.y1, props.y2)
                : dot1.isY
                ? vBox.y
                : dot2.isY
                ? props.y2
                : vBox.y
            }
            width={
              dot1.isX && dot2.isX
                ? Math.abs(props.x2 - props.x1)
                : dot1.isX
                ? Math.abs(vBox.width - props.x1 + vBox.x)
                : dot2.isX
                ? Math.abs(props.x2 - vBox.x)
                : vBox.width
            }
            height={
              dot1.isY && dot2.isY
                ? Math.abs(props.y2 - props.y1)
                : dot1.isY
                ? Math.abs(props.y1 - vBox.y)
                : dot2.isY
                ? Math.abs(vBox.height - props.y2 + vBox.y)
                : vBox.height
            }
            fill={elem.fillColor}
            stroke={elem.strokeColor}
            strokeWidth={elem.strokeWidth}
            strokeDasharray={
              elem.strokeStyle === "Dashed"
                ? "4 4"
                : elem.strokeStyle === "Dotted"
                ? "1 1"
                : "none"
            }
          />
        );
      }}
    />
  );
};

export const CustomizedRefLine = (props) => {
  const { cProps, ...rest } = props;

  const {
    elem,
    dataTypes,
    existedValue,
    layout,
    xAxisSettings,
    yAxisSettings,
    width,
    height,
    marginSettings,
  } = cProps;

  const dot = getRefDotCoordinates(
    dataTypes.items,
    dataTypes.series,
    elem.xCoordinateNumber,
    elem.xCoordinateCategory,
    elem.yCoordinateNumber,
    elem.yCoordinateCategory,
    existedValue.x,
    existedValue.y,
    layout
  );

  const xCenter = getChartCenterX(width, marginSettings, yAxisSettings);
  const yCenter = getChartCenterY(height, marginSettings, xAxisSettings);

  const vBox = getChartViewBox(
    width,
    height,
    marginSettings,
    xAxisSettings,
    yAxisSettings
  );

  return (
    <ReferenceLine
      {...rest}
      xAxis={rest.xAxisMap[0]}
      yAxis={rest.yAxisMap[0]}
      viewBox={vBox}
      x={elem.orient ? null : dot.x}
      y={elem.orient ? dot.y : null}
      stroke={elem.lineColor}
      strokeWidth={elem.lineStrokeWidth}
      shape={(props) => (
        <line
          x1={!elem.orient && !dot.isX ? xCenter : props.x1}
          x2={!elem.orient && !dot.isX ? xCenter : props.x2}
          y1={elem.orient && !dot.isY ? yCenter : props.y1}
          y2={elem.orient && !dot.isY ? yCenter : props.y2}
          stroke={elem.lineColor}
          strokeWidth={elem.lineStrokeWidth}
          strokeDasharray={
            elem.lineStyle === "Dashed"
              ? "4 4"
              : elem.lineStyle === "Dotted"
              ? "1 1"
              : "none"
          }
        />
      )}
      label={
        elem.showLabel
          ? (props) => (
              <CustomizedRefLineLabel
                {...props}
                orient={elem.orient}
                viewBox={{
                  x: !elem.orient && !dot.isX ? vBox.x : props.viewBox.x,
                  y: elem.orient && !dot.isY ? vBox.y : props.viewBox.y,
                  width:
                    !elem.orient && !dot.isX ? vBox.width : props.viewBox.width,
                  height:
                    elem.orient && !dot.isY
                      ? vBox.height
                      : props.viewBox.height,
                }}
                value={elem.name}
                fontSize={elem.labelFontSize}
                color={elem.labelColor}
                wrap={elem.labelWrap}
                lineWidth={elem.labelWidth}
                offsetX={elem.labelOffsetX}
                offsetY={elem.labelOffsetY}
                labelPositionHoriz={elem.labelPositionHoriz}
                labelPositionVert={elem.labelPositionVert}
                labelPositionVertOrient={elem.labelPositionVertOrient}
                labelPositionVertAlign={elem.labelPositionVertAlign}
              />
            )
          : undefined
      }
    />
  );
};

export const CustomizedRefDot = (props) => {
  const { cProps, ...rest } = props;

  const {
    elem,
    dataTypes,
    existedValue,
    layout,
    xAxisSettings,
    yAxisSettings,
    width,
    height,
    marginSettings,
  } = cProps;

  const dot = getRefDotCoordinates(
    dataTypes.items,
    dataTypes.series,
    elem.xCoordinateNumber,
    elem.xCoordinateCategory,
    elem.yCoordinateNumber,
    elem.yCoordinateCategory,
    existedValue.x,
    existedValue.y,
    layout
  );

  const xCenter = getChartCenterX(width, marginSettings, yAxisSettings);
  const yCenter = getChartCenterY(height, marginSettings, xAxisSettings);

  const vBox = getChartViewBox(
    width,
    height,
    marginSettings,
    xAxisSettings,
    yAxisSettings
  );

  return (
    <ReferenceDot
      {...rest}
      xAxis={rest.xAxisMap[0]}
      yAxis={rest.yAxisMap[0]}
      viewBox={vBox}
      x={dot.x}
      y={dot.y}
      r={elem.dotSize / 2}
      shape={(props) => (
        <CustomizedDot
          {...props}
          dotsType={"circle"}
          cx={dot.isX ? props.cx : xCenter}
          cy={dot.isY ? props.cy : yCenter}
          dotsStyle={elem.dotStyle}
          dotsColor={elem.dotColor}
          dotsSize={elem.dotSize}
          dotsStrokeWidth={elem.dotStrokeWidth}
        />
      )}
      label={
        elem.showLabel ? (
          <CustomizedDotLabel
            isX={dot.isX}
            isY={dot.isY}
            xCenter={xCenter}
            yCenter={yCenter}
            value={elem.name}
            position={elem.labelPosition}
            fontSize={elem.labelFontSize}
            fill={elem.labelColor}
            dotSize={elem.dotSize}
            wrap={elem.labelWrap}
            lineWidth={elem.labelWidth}
            offsetX={elem.labelOffsetX}
            offsetY={elem.labelOffsetY}
            showLabelLine={elem.showLabelLine}
            labelLineColor={elem.labelLineColor}
            showLabelImage={elem.showLabelImage}
            imageUrl={elem.labelImgUrl}
            imagePosition={elem.labelImgPosition}
            imageCustomSize={elem.labelImgCustomSize}
            imageWidth={elem.labelImgWidth}
          />
        ) : undefined
      }
    />
  );
};
