import React from "react";
import { Provider } from "react-redux";
import { createStore } from "redux";
import "./App.css";
import { NavBar } from "./components/ui/navbar";
import { RightDrawer } from "./components/ui/rightdrawer";
import { Popup } from "./components/ui/popup";
import {
  getChartDefaultProps,
  getChart,
  getWindowDimensions,
  useWindowDimensions,
  createUISettings,
  MIN_RIGHT_DRAWER_WIDTH,
  MAX_RIGHT_DRAWER_WIDTH,
  useIsMount,
  convertOldChart,
} from "./components/utils/utils";
import { Charts } from "./components/charts/charts";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  UPDATE_CHART_SETTINGS,
  UPDATE_GOOGLEFONTS,
  OPEN_POPUP,
  UPDATE_AUTH,
  IS_USER_AUTH_COMPLETE,
  IS_CURRENT_CHART_SAVED,
  LOAD_CHART_SETTINGS_FROM_DB,
} from "./components/utils/actions";
import LZString from "lz-string";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { reducer } from "./reducer";
import { getChartFromDbById } from "./firestore";
import { useResizeDrawer } from "./components/charts/customHooks";

// Firebase config
const firebaseConfig = {
  apiKey: "AIzaSyCX0bnIaEVPUJHSaJX5zEPLgxWnmWpqVfQ",
  authDomain: "getchart-online.firebaseapp.com",
  projectId: "getchart-online",
  storageBucket: "getchart-online.appspot.com",
  messagingSenderId: "1069238930305",
  appId: "1:1069238930305:web:dec57266f8ab836a217680",
  measurementId: "G-KMXNFMM5CE",
};

const app = initializeApp(firebaseConfig); // Initialize Firebase and Firestore
const appDB = getFirestore(app); // app's firestore db,
const firestoreCollectionUsers = "users"; // firestore collection with users
const firestoreCollectionCharts = "charts"; // firestore collection with stored charts

const isNewUser = localStorage.getItem("lastVisit") ? false : true;
localStorage.setItem("lastVisit", "" + Date.now());

const theme = createTheme({
  palette: {
    primary: {
      main: "#333333",
    },
    secondary: {
      main: "#999999",
    },
  },
  typography: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  // Global styles for the auto-fill keyframes are injected and removed
  // on each mount and unmount, respectively. If you are loading a large number
  // of Text Field components at once, it might be a good idea to change
  // this default behavior by enabling disableInjectingGlobalStyles in MuiInputBase.
  //
  // Make sure to inject GlobalStyles for the auto-fill keyframes at the top of your application.
  // https://mui.com/material-ui/react-text-field/#performance
  components: {
    MuiInputBase: {
      defaultProps: {
        disableInjectingGlobalStyles: true,
      },
    },
  },
});

// get share chart
const urlParams = new URLSearchParams(window.location.search);
let shareChart: any = null;
const isShareChart = urlParams.has("share") || urlParams.has("chart");
const isShareChartLink = urlParams.has("share");
const isShareChartStored = urlParams.has("chart");
let isShareChartLinkError = false;

if (isShareChartLink) {
  shareChart = getChartFromShareLink();
  if (shareChart === null) {
    isShareChartLinkError = true;
  }
}
if (isShareChartStored || isShareChartLinkError) {
  shareChart = {
    chart: {
      chartSettings: {
        layoutSettings: {
          width: null,
          height: null,
        },
      },
      chartUI: {},
      currentChartType: "",
    },
    firebase: {
      currentChartId: "",
      currentChartName: "",
      isCurrentChartSaved: true,
    },
  };
}

function getChartFromShareLink() {
  try {
    // get chart settings from url and merge them with default settings
    const encodedStr = JSON.parse(
      LZString.decompressFromEncodedURIComponent(urlParams.get("share"))
    );
    const chartType = encodedStr.currentChartType;
    const { updatedSettings, updatedUI } = convertOldChart(
      chartType,
      encodedStr.chartSettings,
      {}
    );
    return {
      chart: {
        currentChartType: chartType,
        chartSettings: updatedSettings,
        chartUI: updatedUI,
      },
    };
  } catch (e) {
    return null;
  }
}

// get all chart's types except examples
const chartsKeys = Object.keys(Charts).filter(
  (elem) => Charts[elem].group !== "Examples"
);
// const DEFAULT_CHART = "Scatter";
// get random chart's type
const DEFAULT_CHART =
  chartsKeys[Math.floor(Math.random() * (chartsKeys.length - 0)) + 0];

const CURRENT_CHART_TYPE = isShareChart
  ? shareChart.chart.currentChartType
  : DEFAULT_CHART;

const CHART_SETTINGS = isShareChart
  ? shareChart.chart.chartSettings
  : getChartDefaultProps(DEFAULT_CHART);
const CHART_UI: any = isShareChart
  ? shareChart.chart.chartUI
  : createUISettings(DEFAULT_CHART, CHART_SETTINGS);

const { height: PAGE_HEIGHT, width: PAGE_WIDTH } = getWindowDimensions();

const showDrawer = isShareChart ? false : PAGE_WIDTH < 799 ? false : true;

let chartWidth = PAGE_WIDTH - MIN_RIGHT_DRAWER_WIDTH;
if (
  CHART_SETTINGS.layoutSettings.isFullscreen ||
  CHART_SETTINGS.layoutSettings.isWidthAuto
) {
  if (!showDrawer) {
    chartWidth = PAGE_WIDTH;
  }
  if (chartWidth < CHART_SETTINGS.layoutSettings.minWidth) {
    chartWidth = CHART_SETTINGS.layoutSettings.minWidth;
  }
  if (chartWidth > CHART_SETTINGS.layoutSettings.maxWidth) {
    chartWidth = CHART_SETTINGS.layoutSettings.maxWidth;
  }
} else {
  chartWidth = CHART_SETTINGS.layoutSettings.width;
}

let chartHeight = PAGE_HEIGHT;
if (
  CHART_SETTINGS.layoutSettings.isFullscreen ||
  CHART_SETTINGS.layoutSettings.isHeightAuto
) {
  chartHeight = PAGE_HEIGHT;
} else {
  chartHeight = CHART_SETTINGS.layoutSettings.height;
}

// App's initial state
const initialState = {
  isDrawerRightOpened: showDrawer,
  popup: {
    opened: false,
    props: {},
  },
  chart: {
    currentChartType: CURRENT_CHART_TYPE,
    chartSettings: {
      ...CHART_SETTINGS,
      layoutSettings: {
        ...CHART_SETTINGS.layoutSettings,
        width: chartWidth,
        height: chartHeight,
      },
    },
    chartUI: {
      ...CHART_UI,
      layoutSettings: {
        ...CHART_UI.layoutSettings,
        width: chartWidth,
        height: chartHeight,
      },
    },
  },
  chartRef: null,
  fileUploaded: {
    file: null,
    fileType: "",
  },
  googleFonts: [],
  firebase: {
    isSignedIn: false,
    collectionUsers: firestoreCollectionUsers,
    collectionCharts: firestoreCollectionCharts,
    currentChartId: null, // stored chart id, which is currently editing
    currentChartName: "", // user defined chart name
    isAuthComplete: false, // is app first load auth process is finished
    isCurrentChartSaved: true, // is current editing chart have changes to save
  },
};

const store = createStore(reducer, initialState);
store.getState();

const ChartNotFound = () => (
  <div style={{ width: "50%", height: "100dvh", placeContent: "center" }}>
    <img width="50%" src="./getchart.online.svg" />
    <p>
      Hmm.. this chart was deleted, not created yet, or you have mistake in url.
      Check it and try again.
    </p>
    <p>
      Or{" "}
      <a href="./" className="App-link">
        create your own chart
      </a>
      .
    </p>
  </div>
);

const Loader = () => (
  <div>
    <span className="loader-xl" />
  </div>
);

declare const window: any;

function AppComponent() {
  const isDrawerRightOpened = useSelector(
    (state: any) => state.isDrawerRightOpened
  );
  const currentChartType = useSelector(
    (state: any) => state.chart.currentChartType
  );
  const currentChartId = useSelector(
    (state: any) => state.firebase.currentChartId
  );
  const chartSettings = useSelector((state: any) => state.chart.chartSettings);
  const isCurrentChartSaved = useSelector(
    (state: any) => state.firebase.isCurrentChartSaved
  );
  const popup = useSelector((state: any) => state.popup);

  const { isFullscreen, isWidthAuto, minWidth, maxWidth, isHeightAuto } =
    chartSettings.layoutSettings;
  const { height, width } = useWindowDimensions();

  const [isChartLoadingFromDB, setIsChartLoadingFromDB] =
    React.useState(isShareChartStored);
  const [isChartLoadingFromDBError, setIsChartLoadingFromDBError] =
    React.useState(false);

  const appRef = React.useRef<HTMLDivElement>(null);

  const { drawerWidth, enableResize } = useResizeDrawer({
    minWidth: MIN_RIGHT_DRAWER_WIDTH,
    maxWidth: MAX_RIGHT_DRAWER_WIDTH,
    ref: appRef,
  });

  const auth = getAuth();
  const dispatch = useDispatch();
  const isFirstRender = useIsMount();

  function updateChartSize() {
    if (isFullscreen || isHeightAuto) {
      dispatch(UPDATE_CHART_SETTINGS("layoutSettings.height", height));
    }
    if (isFullscreen || isWidthAuto) {
      let chartWidth = isDrawerRightOpened ? width - drawerWidth : width;
      if (chartWidth < minWidth) {
        chartWidth = minWidth;
      }
      if (chartWidth > maxWidth) {
        chartWidth = maxWidth;
      }
      dispatch(UPDATE_CHART_SETTINGS("layoutSettings.width", chartWidth));
    }
  }

  React.useEffect(() => {
    if (!isFirstRender) {
      updateChartSize();
      dispatch(IS_CURRENT_CHART_SAVED(isCurrentChartSaved));
    }
  }, [
    isWidthAuto,
    isHeightAuto,
    isFullscreen,
    currentChartType,
    currentChartId,
    width,
    minWidth,
    maxWidth,
    height,
    isDrawerRightOpened,
    drawerWidth,
  ]);

  React.useEffect(() => {
    document.body.style.background = chartSettings.layoutSettings.background;
  }, [chartSettings.layoutSettings.background]);

  React.useEffect(() => {
    if (isShareChartStored) {
      getChartFromDbById(
        urlParams.get("chart"),
        appDB,
        firestoreCollectionCharts
      ).then((result) => {
        if (result) {
          dispatch(LOAD_CHART_SETTINGS_FROM_DB(result));
        } else {
          setIsChartLoadingFromDBError(true);
        }
        setIsChartLoadingFromDB(false);
      });
    }

    // Watcher check, if user authentificated
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        //...
        dispatch(UPDATE_AUTH(true));
      } else {
        // User is signed out
        //...
        dispatch(UPDATE_AUTH(false));
      }
      // dispatch(UPDATE_AUTH(auth));
      dispatch(IS_USER_AUTH_COMPLETE(true));
    });

    // if we have bew user, show popup with app's info
    if (isNewUser && !isShareChart) {
      dispatch(OPEN_POPUP({ content: "Info" }));
    }

    // fetch Google fonts
    fetch(
      "https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyAXNkmGsY0J8DLoZtI_UcNuLWLzIQ_pD5k"
    )
      .then((response) => response.json())
      .then((responseJson) => {
        dispatch(UPDATE_GOOGLEFONTS(responseJson.items));
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // zoom out if browser width < defined chart's min or fixed width
  let scaleRatio = 1;
  if (isShareChart) {
    if (isFullscreen || isWidthAuto) {
      if (minWidth > width) {
        scaleRatio = width / minWidth;
      }
    } else {
      if (chartSettings.layoutSettings.width > width) {
        scaleRatio = width / chartSettings.layoutSettings.width;
      }
    }
  }

  return (
    <div ref={appRef} className={isChartLoadingFromDB ? "AppLoading" : "App"}>
      {isShareChart ? "" : <NavBar />}
      {isChartLoadingFromDB ? (
        <Loader />
      ) : isChartLoadingFromDBError || isShareChartLinkError ? (
        <ChartNotFound />
      ) : (
        <div
          className="ChartContainer"
          style={
            isDrawerRightOpened
              ? {
                  marginRight: drawerWidth,
                  width: "100%",
                  overflow: "auto",
                }
              : {
                  // zoom scales incorrect on mobile devices (small displacements)
                  // zoom: scaleRatio < 1 ? scaleRatio : undefined,
                  transform:
                    scaleRatio < 1 ? "scale(" + scaleRatio + ")" : undefined,
                  transformOrigin: scaleRatio < 1 ? "top" : undefined,
                }
          }
        >
          {getChart(currentChartType, chartSettings)}
        </div>
      )}
      {isShareChart ? (
        ""
      ) : (
        <RightDrawer drawerWidth={drawerWidth} enableResize={enableResize} />
      )}
      <Popup popupOpened={popup.opened} popupProps={popup.props} />
    </div>
  );
}

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AppComponent />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
