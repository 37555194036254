import * as React from "react";
import { useState, useEffect } from "react";
import { ControlType, applyPropertyControls } from "../ui/controltype";
import { useDispatch } from "react-redux";
import { UPDATE_CHART_REF } from "../utils/actions";
import * as d3 from "d3";
import { Helmet } from "react-helmet";
import {
  presetLayout,
  presetTitle,
  presetCaption,
} from "./propertyControlsPresets";
import { buildHierarchyData } from "../utils/utils";
import { TextBlock } from "./textBlock";
import { useHeaderHeight } from "./customHooks";

const dataGroups = [
  {
    Hierarchy: [
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
      "California",
    ],
  },
  {
    Hierarchy: [
      "Alameda",
      "Alameda",
      "Alameda",
      "Alameda",
      "Alameda",
      "Alameda",
      "Alameda",
      "Alameda",
      "Alameda",
      "Alameda",
      "Alameda",
      "Alameda",
      "Alameda",
      "Alameda",
      "Contra Costa",
      "Contra Costa",
      "Contra Costa",
      "Contra Costa",
      "Contra Costa",
      "Contra Costa",
      "Contra Costa",
      "Contra Costa",
      "Contra Costa",
      "Contra Costa",
      "Contra Costa",
      "Contra Costa",
      "Contra Costa",
      "Contra Costa",
      "Contra Costa",
      "Contra Costa",
      "Contra Costa",
      "Marin",
      "Marin",
      "Marin",
      "Marin",
      "Marin",
      "Marin",
      "Napa",
      "Napa",
      "Napa",
      "Napa",
      "Napa",
      "San Francisco",
      "San Mateo",
      "San Mateo",
      "San Mateo",
      "San Mateo",
      "San Mateo",
      "San Mateo",
      "San Mateo",
      "San Mateo",
      "San Mateo",
      "San Mateo",
      "San Mateo",
      "San Mateo",
      "San Mateo",
      "San Mateo",
      "San Mateo",
      "Santa Clara",
      "Santa Clara",
      "Santa Clara",
      "Santa Clara",
      "Santa Clara",
      "Santa Clara",
      "Santa Clara",
      "Santa Clara",
      "Santa Clara",
      "Santa Clara",
      "Santa Clara",
      "Santa Clara",
      "Santa Clara",
      "Solano",
      "Solano",
      "Solano",
      "Solano",
      "Solano",
      "Solano",
      "Solano",
      "Sonoma",
      "Sonoma",
      "Sonoma",
      "Sonoma",
      "Sonoma",
      "Sonoma",
      "Sonoma",
      "Sonoma",
    ],
  },
  {
    Hierarchy: [
      "Alameda",
      "Albany",
      "Berkeley",
      "Dublin",
      "Emeryville",
      "Fremont",
      "Hayward",
      "Livermore",
      "Newark",
      "Oakland",
      "Piedmont",
      "Pleasanton",
      "San Leandro",
      "Union City",
      "Antioch",
      "Brentwood",
      "Clayton",
      "Concord",
      "El Cerrito",
      "Hercules",
      "Lafayette",
      "Martinez",
      "Oakley",
      "Orinda",
      "Pinole",
      "Pittsburg",
      "Pleasant Hill",
      "Richmond",
      "San Pablo",
      "San Ramon",
      "Walnut Creek",
      "Belvedere",
      "Larkspur",
      "Mill Valley",
      "Novato",
      "San Rafael",
      "Sausalito",
      "American Canyon",
      "Calistoga",
      "Napa",
      "St. Helena",
      "Yountville",
      "San Francisco",
      "Belmont",
      "Brisbane",
      "Burlingame",
      "Daly City",
      "East Palo Alto",
      "Foster City",
      "Half Moon Bay",
      "Menlo Park",
      "Millbrae",
      "Pacifica",
      "Redwood City",
      "San Bruno",
      "San Carlos",
      "San Mateo",
      "South San Francisco",
      "Campbell",
      "Cupertino",
      "Gilroy",
      "Los Altos",
      "Milpitas",
      "Monte Sereno",
      "Morgan Hill",
      "Mountain View",
      "Palo Alto",
      "San Jose",
      "Santa Clara",
      "Saratoga",
      "Sunnyvale",
      "Benicia",
      "Dixon",
      "Fairfield",
      "Rio Vista",
      "Suisun City",
      "Vacaville",
      "Vallejo",
      "Cloverdale",
      "Cotati",
      "Healdsburg",
      "Petaluma",
      "Rohnert Park",
      "Santa Rosa",
      "Sebastopol",
      "Sonoma",
    ],
  },
];

const dataValues = [
  77409, 19420, 118585, 54523, 11111, 227934, 154507, 86493, 44677, 412040,
  11201, 77046, 89039, 73500, 108675, 56923, 11655, 126938, 24646, 25011, 25381,
  37544, 38968, 18936, 19040, 67998, 34395, 108303, 29991, 74366, 67568, 2017,
  12268, 14318, 54790, 58948, 7115, 20334, 5281, 79567, 5995, 2991, 850282,
  26918, 4568, 30118, 105543, 29353, 32967, 12281, 33319, 22522, 38844, 82595,
  42736, 29596, 102224, 66587, 40788, 60297, 52576, 30238, 73447, 3514, 41839,
  78827, 66649, 1009363, 122725, 30830, 149596, 27780, 19144, 110953, 8055,
  29045, 95607, 119644, 8763, 7415, 11614, 59757, 41928, 173165, 7630, 10952,
];

export function HierarchyTreeRadial(props) {
  const {
    layoutSettings,
    chartSettings,
    links,
    branches,
    leaves,
    title,
    caption,
    dataWeights,
    dataSeries,
  } = props;

  const { background, width } = layoutSettings;

  const dataWeightsJSON = JSON.stringify(dataWeights);
  const dataSeriesJSON = JSON.stringify(dataSeries);

  const dispatch = useDispatch();

  const buildTree = (d, nodesSpacing) => {
    //calc summ of leaves values and put value prop into nodes
    const root = d3.hierarchy(d).sum((elem) => elem.value);
    const depthLevels = root.height + 2;
    return d3
      .tree()
      .size([2 * Math.PI, nodesSpacing * depthLevels])
      .separation((a, b) => (a.parent === b.parent ? 1 : 2) / a.depth)(root);
  };

  const chartRef = React.useRef<HTMLDivElement>(null);
  const { headerRef, height } = useHeaderHeight(
    width,
    layoutSettings,
    caption,
    title,
    {},
    ""
  );

  const [fontLoaded, setFontLoaded] = React.useState(false);

  // custom fonts loading status
  title["fontLoaded"] = fontLoaded;
  for (let i = 0; i < caption.items.length; i++) {
    caption.items[i]["fontLoaded"] = fontLoaded;
  }

  useEffect(() => {
    document.fonts.ready.then(() => {
      setFontLoaded(true);
    });
  });

  const [data, setData] = useState(d3.hierarchy({}));
  const [dataChangedFlag, setDataChangedFlag] = useState(true);

  const labelsPadding = 4;

  const weightNodeMax = d3.max(data.descendants(), (d) => d.value);
  const weighNodetMin = d3.min(data.descendants(), (d) => d.value);
  const weightlinkMax = d3.max(data.links(), (d) => d.target.value);
  const weightlinkMin = d3.min(data.links(), (d) => d.target.value);

  const scaleLeavesSize = d3
    .scaleLinear()
    .domain([weighNodetMin, weightNodeMax])
    .range([chartSettings.nodeSizeMin, chartSettings.nodeSizeMax]);

  const scaleLinksSize = d3
    .scaleLinear()
    .domain([
      chartSettings.syncLinkWeights ? weighNodetMin : weightlinkMin,
      chartSettings.syncLinkWeights ? weightNodeMax : weightlinkMax,
    ])
    .range([
      chartSettings.syncLinkWeights
        ? chartSettings.nodeSizeMin
        : chartSettings.linkSizeMin,
      chartSettings.syncLinkWeights
        ? chartSettings.nodeSizeMax
        : chartSettings.linkSizeMax,
    ]);

  const svgViewBox = [
    -width / 2 - chartSettings.xOffset,
    -height / 2 - chartSettings.yOffset,
    width,
    height,
  ];

  //Apply style
  function applyStyle(svg) {
    svg.attr("width", width).attr("height", height).attr("viewBox", svgViewBox);

    svg
      .selectAll("#chartContainer")
      .attr("transform", `rotate(${chartSettings.rotate})`);

    //nodes
    svg
      .selectAll("circle")
      .attr("fill", (d) => (d.children ? branches.color : leaves.color))
      .attr("stroke", (d) =>
        d.children ? branches.strokeColor : leaves.strokeColor
      )
      .attr("stroke-width", (d) =>
        d.children ? branches.strokeWidth : leaves.strokeWidth
      )
      .attr("r", (d) =>
        d.children
          ? chartSettings.useWeights
            ? scaleLeavesSize(d.value) / 2
            : branches.size / 2
          : chartSettings.useWeights
          ? scaleLeavesSize(d.value) / 2
          : leaves.size / 2
      );

    //links
    svg
      .selectAll("path")
      .attr("stroke", links.color)
      .attr(
        "stroke-width",
        chartSettings.useWeights
          ? (d) => scaleLinksSize(d.target.value)
          : links.stroke
      )
      .attr("fill", "none");

    //labels
    svg
      .select("#svgTreeLabels")
      .selectAll("text")
      .attr("font-size", (d) =>
        d.children ? branches.labelFontSize : leaves.labelFontSize
      )
      .attr("fill", (d) =>
        d.children
          ? branches.showLabels
            ? branches.labelColor
            : "rgba(0, 0, 0, 0)"
          : leaves.showLabels
          ? leaves.labelColor
          : "rgba(0, 0, 0, 0)"
      )
      .attr("color", (d) =>
        d.children
          ? branches.showLabels
            ? branches.labelColor
            : "rgba(0, 0, 0, 0)"
          : leaves.showLabels
          ? leaves.labelColor
          : "rgba(0, 0, 0, 0)"
      )
      .attr("x", (d) => {
        const x = d.children
          ? chartSettings.useWeights
            ? -scaleLeavesSize(d.value) / 2 - labelsPadding
            : -branches.size / 2 - labelsPadding
          : chartSettings.useWeights
          ? scaleLeavesSize(d.value) / 2 + labelsPadding
          : leaves.size / 2 + labelsPadding;

        return d.x < Math.PI ? x : -x;
      })
      .attr("dy", "0.31em");
  }

  //Updating when recieve new data or width (and first time mounted)
  useEffect(() => {
    const svg = d3.select("#svgTree");

    if (data.children) {
      //links
      svg
        .select("#svgTreeLinks")
        .selectAll("path")
        .data(data.links())
        .join("path")
        .attr(
          "d",
          d3
            .linkRadial()
            .angle((d) => d.x)
            .radius((d) => d.y)
        );

      //nodes
      svg
        .select("#svgTreeNodes")
        .selectAll("circle")
        .data(data.descendants())
        .join("circle")
        .attr(
          "transform",
          (d) => `
        rotate(${(d.x * 180) / Math.PI - 90})
        translate(${d.y},0)
      `
        );

      //labels
      svg
        .select("#svgTreeLabels")
        .selectAll("text")
        .data(data.descendants())
        .join("text")
        .attr(
          "transform",
          (d) => `
        rotate(${(d.x * 180) / Math.PI - 90}) 
        translate(${d.y},0) 
        rotate(${d.x >= Math.PI ? 180 : 0})
      `
        )
        .attr("text-anchor", (d) =>
          d.x < Math.PI === !d.children ? "start" : "end"
        )
        .text((d) => d.data.name);
    } else {
      svg.select("#svgTreeLinks").selectAll("path").remove();
      svg.select("#svgTreeNodes").selectAll("circle").remove();
      svg.select("#svgTreeLabels").selectAll("text").remove();
    }
  }, [dataChangedFlag]);

  //Updating style and coordinates settings
  useEffect(() => {
    applyStyle(d3.select(chartRef.current).select("#svgTree"));
  });

  useEffect(() => {
    // const root = buildData(dataSeries.items, chartSettings.nodesSpacing);
    const dataRaw = buildHierarchyData({
      series: dataSeries.items,
      weights: dataWeights.Weights,
    });
    const root = buildTree(dataRaw, chartSettings.nodesSpacing);
    setData(root);
    setDataChangedFlag(!dataChangedFlag);
  }, [dataWeightsJSON, dataSeriesJSON, chartSettings.nodesSpacing, width]);

  useEffect(() => {
    const element = chartRef.current;
    if (element) {
      const svg = element;
      dispatch(UPDATE_CHART_REF(svg));
    }
  }, [chartRef, dispatch]);

  return (
    <div
      id="HierarchyTreeRadial"
      ref={chartRef}
      style={{ background: background }}
    >
      <Helmet>
        <link
          rel="stylesheet"
          href={
            "https://fonts.googleapis.com/css?family=" +
            title.fontFamily.replace(/ /g, "+") +
            ":" +
            title.fontVariants
          }
          crossOrigin="anonymous"
        />
        {caption.items.map((elem, i) => {
          return (
            <link
              key={"caption-" + i}
              rel="stylesheet"
              href={
                "https://fonts.googleapis.com/css?family=" +
                elem.fontFamily.replace(/ /g, "+") +
                ":" +
                elem.fontVariants
              }
              crossOrigin="anonymous"
            />
          );
        })}
      </Helmet>
      <div ref={headerRef} style={{ width: width }}>
        {title.isVisible ? (
          <TextBlock key={"title"} title={title} fontLoaded={fontLoaded} />
        ) : (
          ""
        )}
        {caption.isVisible
          ? caption.items.map((elem, i) => {
              return (
                <TextBlock
                  key={"caption" + i}
                  title={elem}
                  fontLoaded={fontLoaded}
                />
              );
            })
          : ""}
      </div>
      <svg id="svgTree" className="chart-surface">
        <g id="chartContainer">
          <g id="svgTreeLinks" />
          <g id="svgTreeNodes" />
          <g id="svgTreeLabels" />
        </g>
      </svg>
    </div>
  );
}

HierarchyTreeRadial.defaultProps = {
  isData: false,
  layoutSettings: {
    isFullscreen: true,
    isWidthAuto: true,
    minWidth: 320,
    maxWidth: 2500,
    isHeightAuto: true,
    background: "rgba(255, 255, 255, 1)",
  },
  chartSettings: {
    nodesSpacing: 75,
    useWeights: false,
    nodeSizeMin: 3,
    nodeSizeMax: 20,
    syncLinkWeights: true,
    linkSizeMin: 1,
    linkSizeMax: 8,
    rotate: 0,
    xOffset: 0,
    yOffset: 0,
  },
  sourceData: true,
  fileData: null,
  links: {
    color: "rgba(17, 153, 238, .25)",
    stroke: 1,
  },
  branches: {
    size: 6,
    color: "rgba(255, 255, 255, 1)",
    strokeColor: "rgba(17, 153, 238, 1)",
    strokeWidth: 1,
    showLabels: true,
    labelColor: "rgba(17, 153, 238, 1)",
    labelFontSize: 14,
  },
  leaves: {
    size: 6,
    color: "rgba(17, 153, 238, 1)",
    strokeColor: "rgba(17, 153, 238, 1)",
    strokeWidth: 0,
    showLabels: true,
    labelColor: "rgba(17, 153, 238, 1)",
    labelFontSize: 14,
  },
  title: {
    isVisible: false,
    text: "Chart title",
    txtColor: "rgba(0,0,0,1)",
    fontSize: 48,
    fontFamily: "Roboto",
    fontVariants: "regular",
    align: "center",
    margin: {
      margin: 0,
      isPerSide: true,
      top: 20,
      bottom: 20,
      left: 0,
      right: 0,
    },
  },
  caption: {
    isVisible: false,
    items: [
      {
        text: "Chart description or data source",
        txtColor: "rgba(0,0,0,.5)",
        fontSize: 18,
        fontFamily: "Roboto",
        fontVariants: "300",
        align: "center",
        margin: {
          margin: 0,
          isPerSide: true,
          top: 20,
          bottom: 20,
          left: 0,
          right: 0,
        },
      },
    ],
  },
  dataWeights: {
    Weights: dataValues,
  },
  dataSeries: { isVisible: true, items: dataGroups },
  dimensions: {
    Hierarchy: ControlType.String,
    Weights: ControlType.Number,
  },
};

applyPropertyControls(HierarchyTreeRadial, {
  //Settings or data
  isData: {
    type: ControlType.Boolean,
    title: "Change",
    disabledTitle: "settings",
    enabledTitle: "data",
    wide: true,
  },

  layoutSettings: presetLayout,

  chartSettings: {
    type: ControlType.Group,
    title: "Chart settings",
    isHeaderControls: false,
    propertyControl: {
      nodesSpacing: {
        type: ControlType.Number,
        title: "Spacing",
        min: 0,
        max: 500,
        displayStepper: true,
      },

      //Use weights for leaves and branches sizes
      useWeights: {
        type: ControlType.Boolean,
        title: "Use weights",
        enabledTitle: "yes",
        disabledTitle: "no",
      },
      nodeSizeMin: {
        type: ControlType.Number,
        title: "↳ Node Min",
        displayStepper: true,
        hidden(props) {
          return props.useWeights === false;
        },
      },
      nodeSizeMax: {
        type: ControlType.Number,
        title: "↳ Node Max",
        displayStepper: true,
        hidden(props) {
          return props.useWeights === false;
        },
      },
      syncLinkWeights: {
        type: ControlType.Boolean,
        title: "↳ Sync links",
        enabledTitle: "yes",
        disabledTitle: "no",
        hidden(props) {
          return props.useWeights === false;
        },
      },
      linkSizeMin: {
        type: ControlType.Number,
        title: "↳ Link Min",
        displayStepper: true,
        hidden(props) {
          return props.syncLinkWeights === true;
        },
      },
      linkSizeMax: {
        type: ControlType.Number,
        title: "↳ Link Max",
        displayStepper: true,
        hidden(props) {
          return props.syncLinkWeights === true;
        },
      },

      //Common settings
      rotate: {
        type: ControlType.Number,
        title: "Rotate",
        min: 0,
        max: 360,
        unit: "°",
      },
      xOffset: {
        type: ControlType.Number,
        title: "X offset",
        min: -1000,
        max: 1000,
      },
      yOffset: {
        type: ControlType.Number,
        title: "Y offset",
        min: -1000,
        max: 1000,
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Links
  links: {
    type: ControlType.Group,
    title: "Links",
    isHeaderControls: false,
    propertyControl: {
      color: {
        title: "Color",
        type: ControlType.Color,
      },
      stroke: {
        type: ControlType.Number,
        title: "Thickness",
        displayStepper: true,
        hidden(props, UI) {
          return UI.chartSettings.useWeights === true;
        },
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Branches
  branches: {
    type: ControlType.Group,
    title: "Branches",
    isHeaderControls: false,
    propertyControl: {
      size: {
        type: ControlType.Number,
        title: "Size",
        displayStepper: true,
        hidden(props, UI) {
          return UI.chartSettings.useWeights === true;
        },
      },
      color: {
        title: "Fill",
        type: ControlType.Color,
      },
      strokeColor: {
        title: "Stroke color",
        type: ControlType.Color,
      },
      strokeWidth: {
        type: ControlType.Number,
        title: "Stroke size",
        displayStepper: true,
      },
      showLabels: {
        type: ControlType.Boolean,
        title: "Labels",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      labelColor: {
        type: ControlType.Color,
        title: "↳ Color",
        hidden(props) {
          return props.showLabels === false;
        },
      },
      labelFontSize: {
        type: ControlType.Number,
        title: "↳ Size",
        hidden(props) {
          return props.showLabels === false;
        },
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Leaves
  leaves: {
    type: ControlType.Group,
    title: "Leaves",
    isHeaderControls: false,
    propertyControl: {
      size: {
        type: ControlType.Number,
        title: "Size",
        displayStepper: true,
        hidden(props, UI) {
          return UI.chartSettings.useWeights === true;
        },
      },
      color: {
        title: "Fill",
        type: ControlType.Color,
      },
      strokeColor: {
        title: "Stroke color",
        type: ControlType.Color,
      },
      strokeWidth: {
        type: ControlType.Number,
        title: "Stroke size",
        displayStepper: true,
      },
      showLabels: {
        type: ControlType.Boolean,
        title: "Labels",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      labelColor: {
        type: ControlType.Color,
        title: "↳ Color",
        hidden(props) {
          return props.showLabels === false;
        },
      },
      labelFontSize: {
        type: ControlType.Number,
        title: "↳ Size",
        hidden(props) {
          return props.showLabels === false;
        },
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Title
  title: presetTitle,

  //Caption
  caption: presetCaption,

  //Data
  sourceData: {
    type: ControlType.Boolean,
    title: "Data",
    enabledTitle: "editable",
    disabledTitle: "file",
    hidden(props) {
      return props.isData === false;
    },
  },
  fileData: {
    title: "↳ File",
    type: ControlType.File,
    allowedFileTypes: ["xls"],
    hidden(props) {
      return props.sourceData === true || props.isData === false;
    },
  },

  //Weights data
  dataWeights: {
    type: ControlType.Group,
    title: "Weights",
    propertyControl: {
      Weights: {
        type: ControlType.Array,
        title: "Weights",
        propertyControl: {
          type(props) {
            return props.dimensions.Weights;
          },
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },

  //Hierarchy Data
  dataSeries: {
    type: ControlType.Collection,
    title: "Hierarchy",
    // ref: "nodes",
    propertyControl: {
      Hierarchy: {
        type: ControlType.Array,
        title: "Hierarchy",
        propertyControl: {
          type(props) {
            return props.dimensions.Hierarchy;
          },
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },

  //Chart's dimensions
  dimensions: {
    type: ControlType.Group,
    title: "Dimensions",
    propertyControl: {
      Hierarchy: {
        type: ControlType.Enum,
        seriesType: true,
        title: "Group by",
        path: "dataSeries",
        options: [ControlType.String, ControlType.Number],
      },
      Weights: {
        type: ControlType.Enum,
        seriesType: false,
        title: "Weights",
        path: "dataWeights",
        options: [ControlType.Number],
      },
    },
    hidden() {
      return true;
    },
  },
});

export default HierarchyTreeRadial;
