import React from "react";
import { useSelector } from "react-redux";
import { Typography, Grid } from "@mui/material";
import domtoimage from "dom-to-image";
import { elementToSVG, inlineResources } from "dom-to-svg";
import pngIcon from "../../img/png.svg";
import svgIcon from "../../img/svg.svg";
import "../../App.css";

export function SaveFile() {
  const [hrefSVG, setHrefSVG] = React.useState("");
  const [hrefPNG, setHrefPNG] = React.useState("");
  const ref = useSelector((state: any) => state.chartRef);
  const chartType = useSelector((state: any) => state.chart.currentChartType);
  const now = new Date().getTime();
  const chartWidth = useSelector(
    (state: any) => state.chart.chartSettings.layoutSettings.width
  );
  const chartHeight = useSelector(
    (state: any) => state.chart.chartSettings.layoutSettings.height
  );

  async function asyncSVG(node) {
    // clone existing node by ref and append it to body to become widthOffset and heightOffset
    let el = node.cloneNode(true);
    el.style.position = "absolute";
    el.style.top = 0;
    el.style.left = 0;
    document.body.appendChild(el);

    try {
      // Capture specific DOM element
      const svgDocument = elementToSVG(el);
      // Inline external resources (fonts, images, etc) as data: URIs
      await inlineResources(svgDocument.documentElement);
      // Get SVG string
      let svgString = new XMLSerializer().serializeToString(svgDocument);
      // convert svg source to URI data scheme.
      let svgUrl =
        "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svgString);
      setHrefSVG(svgUrl);
    } catch (error) {
      console.error("oops, something went wrong (svg)!", error);
    }

    // remove clone
    el.parentNode.removeChild(el);
  }

  React.useEffect(() => {
    // get png
    domtoimage
      .toPng(ref, {
        width: chartWidth,
        height: chartHeight,
        style: { margin: 0 },
      })
      .then(function (dataUrl) {
        setHrefPNG(dataUrl);
      })
      .catch(function (error) {
        console.error("oops, something went wrong (png)!", error);
      });

    // get svg
    asyncSVG(ref);
  }, []);

  return (
    <Grid container spacing={2} style={{ marginBottom: 8 }}>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <Typography variant="h4" align="center" style={{ marginBottom: 16 }}>
          Download your chart
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ display: "flex", justifyContent: "space-evenly" }}
      >
        <a
          href={hrefSVG}
          download={chartType + now}
          title="Download chart as SVG"
        >
          <img
            src={svgIcon}
            width={64}
            height={64}
            alt="SVG"
            className="saveFileIcon"
          />
        </a>
        <a
          href={hrefPNG}
          download={chartType + now}
          title="Download chart as PNG"
        >
          <img
            src={pngIcon}
            width={64}
            height={64}
            alt="PNG"
            className="saveFileIcon"
          />
        </a>
      </Grid>
    </Grid>
  );
}

export default SaveFile;
