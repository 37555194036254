import React from "react";
import {
  TextField,
  Slider,
  Typography,
  InputAdornment,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useSelector, useDispatch } from "react-redux";
import { UPDATE_CHART_SETTINGS } from "../utils/actions";
import { getObjValueByPath, useIsMount } from "../utils/utils";

export function InputNumber(props) {
  const { name: nameOrigin, inputSettings, isArray, index } = props;

  const chartSettings = useSelector((state: any) => state.chart.chartSettings);

  const MIN = inputSettings.min || 0;
  const MAX = inputSettings.max || 100;
  const UNIT = inputSettings.unit;
  const STEP = inputSettings.step || 1;
  const IS_STEPPER = inputSettings.displayStepper;
  const IS_NULLS_ALLOWED = inputSettings.isNullsAllowed;
  const USE_REF =
    typeof inputSettings.useRef === "function"
      ? inputSettings.useRef(chartSettings)
      : false;
  const REF_NAME = inputSettings.refName || undefined;

  let name = nameOrigin;

  // if reference is set, use it to get and put value there
  if (USE_REF && REF_NAME) {
    const arr = nameOrigin.split(".");
    arr.splice(-1);
    name = arr.join(".") + "." + inputSettings.refName;
  }

  const VALUE = useSelector((state: any) =>
    getObjValueByPath(state.chart.chartUI, name)
  );
  const dispatch = useDispatch();

  const [inputValue, updateInputValue] = React.useState(VALUE);
  const [prevValue, updatePrevValue] = React.useState(VALUE);
  const [sliderValue, updateSliderValue] = React.useState(VALUE); // for debouncing

  const isFirstRender = useIsMount();

  // if use useEffect hook, component will re-render twice, now it re-renders immidiatly
  if (!isNaN(VALUE) || !isNaN(prevValue)) {
    if (VALUE !== prevValue) {
      updateInputValue(VALUE);
      updatePrevValue(VALUE);
      updateSliderValue(VALUE);
    }
  }

  // update local state while using keyboard
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value;
    updateInputValue(value);
  };

  // keyboard input update global state after unfocus
  const handleBlur = (event) => {
    let value = event.target.value === "" ? NaN : Number(event.target.value);
    if (isNaN(value)) {
      value = IS_NULLS_ALLOWED ? null : MIN;
    }

    // if value after validation are equal to global state value, then we updating
    // local state, else - we updating global state
    if (value === VALUE) {
      updateInputValue(value);
    } else {
      dispatch(UPDATE_CHART_SETTINGS(name, value));
    }
  };

  // slider update
  const handleSliderChange = (event, value) => {
    updateSliderValue(value);
  };

  // stepper update
  const handleStepperDec = () => {
    if (VALUE - STEP >= MIN) {
      dispatch(UPDATE_CHART_SETTINGS(name, VALUE - STEP));
    }
  };
  const handleStepperInc = () => {
    if (VALUE + STEP <= MAX) {
      dispatch(UPDATE_CHART_SETTINGS(name, VALUE + STEP));
    }
  };

  const isDisableDec =
    inputValue === MIN ? true : inputValue < MIN + STEP ? true : false;
  const isDisableInc =
    inputValue === MAX ? true : inputValue > MAX - STEP ? true : false;

  // debounce dispatch when slider moving
  React.useEffect(() => {
    if (!isFirstRender) {
      const delay = 100;
      const timeoutId = setTimeout(() => {
        dispatch(UPDATE_CHART_SETTINGS(name, sliderValue));
      }, delay);
      return () => clearTimeout(timeoutId);
    }
  }, [sliderValue]);

  const renderInput = (
    <TextField
      type="number"
      variant="standard"
      size="small"
      //   defaultValue={defaultValue}
      value={inputValue === null || isNaN(inputValue) ? "" : inputValue}
      inputProps={{
        min: MIN,
        // max: MAX,
        step: STEP,
        style: { padding: 0, fontSize: "0.8125rem" },
      }}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={(event) => {
        event.target.select();
      }}
      onKeyUp={(event) => {
        if (event.key === "Enter") {
          handleBlur(event);
        }
      }}
      style={{ width: "100%" }}
      InputProps={{
        endAdornment: UNIT ? (
          <InputAdornment position="end">
            <Typography style={{ color: "#999999" }}>{UNIT}</Typography>
          </InputAdornment>
        ) : null,
        style: { paddingRight: 0 },
      }}
    />
  );

  const renderStepper = (
    <ToggleButtonGroup size="small" style={{ width: "100%" }}>
      <ToggleButton
        style={{ padding: 0, width: "50%" }}
        color="primary"
        onClick={handleStepperDec}
        disabled={isDisableDec}
        value="Decrement"
      >
        <RemoveIcon
          fontSize="small"
          color={"primary"}
          style={{ opacity: isDisableDec ? 0.25 : 1 }}
        />
      </ToggleButton>
      <ToggleButton
        style={{ padding: 0, width: "50%" }}
        color="primary"
        onClick={handleStepperInc}
        disabled={isDisableInc}
        value="Increment"
      >
        <AddIcon
          fontSize="small"
          color={"primary"}
          style={{ opacity: isDisableInc ? 0.25 : 1 }}
        />
      </ToggleButton>
    </ToggleButtonGroup>
  );

  const renderSlider = (
    <Slider
      value={
        sliderValue === null || isNaN(sliderValue * 1) || sliderValue === ""
          ? MIN
          : sliderValue * 1
      }
      size="small"
      onChange={handleSliderChange}
      aria-labelledby="input-slider"
      min={MIN}
      max={MAX}
      style={{ padding: 0 }}
    />
  );

  return (
    <div style={{ display: "flex", columnGap: 16, flexGrow: 1 }}>
      <div style={{ width: "50%" }}>{renderInput}</div>
      <div style={{ width: "50%" }}>
        {IS_STEPPER ? renderStepper : renderSlider}
      </div>
    </div>
  );
}

export default InputNumber;
