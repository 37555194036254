import * as React from "react";
import Charts from "../charts/charts";
import { ControlType } from "../ui/controltype";
import * as d3 from "d3";

export const MIN_RIGHT_DRAWER_WIDTH = 300;
export const MAX_RIGHT_DRAWER_WIDTH = 600;
export const FONTFAMILY_DEFAULT = "Roboto";
export const TICKS_COUNT_DEFAULT = 5;

export function getChart(chartType, chartSettings?) {
  const Element = Charts[chartType].component;
  return <Element {...chartSettings} />;
}

export function getChartDefaultProps(chartType) {
  const Element = Charts[chartType].component;
  // Some default props may be getters
  // but charts components need static properties.
  // So, we need to kill getters and fill object with values.
  const props = JSON.parse(JSON.stringify(Element.defaultProps));
  return props;
}

// converts outdated stored chart to current config
export function convertOldChart(chartType, storedChartSettings, storedChartUI) {
  const updatedChartSettings = {};
  const updatedChartUI = {};
  function fillOutput(objTarget, objDefault, objStored) {
    for (let key in objDefault) {
      const src = {
        [key]:
          objStored !== false && key in objStored
            ? objStored[key]
            : objDefault[key],
      };
      if (typeof objDefault[key] === "object" && objDefault[key] !== null) {
        if (Array.isArray(objDefault[key])) {
          objTarget[key] = [];
          for (let i = 0; i < src[key].length; i++) {
            if (
              typeof src[key][i] === "object" &&
              !Array.isArray(src[key][i]) &&
              src[key][i] !== null
            ) {
              objTarget[key][i] = {};
              fillOutput(objTarget[key][i], objDefault[key][0], src[key][i]);
            } else {
              objTarget[key].push(src[key][i]);
            }
          }
        } else {
          objTarget[key] = {};
          fillOutput(objTarget[key], objDefault[key], src[key]);
        }
      } else {
        objTarget[key] = src[key];
      }
    }
    return objTarget;
  }

  const defaultChartProps = getChartDefaultProps(chartType);
  const resultSettings = fillOutput(
    updatedChartSettings,
    defaultChartProps,
    storedChartSettings
  );

  const defaultChartUI = createUISettings(chartType, resultSettings);
  const resultUI = fillOutput(updatedChartUI, defaultChartUI, storedChartUI);

  const { height: innerHeight, width: innerWidth } = getWindowDimensions();

  const width =
    storedChartSettings.layoutSettings &&
    storedChartSettings.layoutSettings.width
      ? storedChartSettings.layoutSettings.width
      : innerWidth - MIN_RIGHT_DRAWER_WIDTH;

  const height =
    storedChartSettings.layoutSettings &&
    storedChartSettings.layoutSettings.height
      ? storedChartSettings.layoutSettings.height
      : innerHeight;

  const updatedSettings = {
    ...resultSettings,
    layoutSettings: {
      ...resultSettings.layoutSettings,
      width: width,
      height: height,
    },
  };

  const updatedUI = {
    ...resultUI,
    layoutSettings: {
      ...resultUI.layoutSettings,
      width: width,
      height: height,
    },
  };

  return { updatedSettings, updatedUI };
}

// creates ui settings from chart settings
export function createUISettings(chartType, chartProps?) {
  const chartControls = getChart(chartType).type.propertyControls;
  const chartSettings = chartProps || getChartDefaultProps(chartType);
  let chartUI = {};
  // recursive
  function fillOutput(chartControls, chartSettings, path = "") {
    for (let key in chartControls) {
      switch (chartControls[key].type) {
        case ControlType.Array:
          setObjValueByPath(
            chartUI,
            path ? path + "." + key : key,
            chartSettings[key].map(function (item, i) {
              return { id: i, value: item, hidden: false };
            })
          );
          break;
        case ControlType.Group:
          if (path) {
            createObjKeysByPath(chartUI, path + "." + key);
          } else {
            chartUI[key] = {};
          }
          const groupPropertyControl =
            typeof chartControls[key].propertyControl === "function"
              ? chartControls[key].propertyControl(chartUI)
              : chartControls[key].propertyControl;
          fillOutput(
            groupPropertyControl,
            chartSettings[key],
            path ? path + "." + key : key
          );
          break;
        case ControlType.Collection:
          const collection = JSON.parse(JSON.stringify(chartSettings[key]));
          const collectionItems = collection.items;
          chartUI[key] = {
            isVisible: collection.isVisible,
            items: [],
          };
          const collectionPropertyControl =
            typeof chartControls[key].propertyControl === "function"
              ? chartControls[key].propertyControl(chartUI)
              : chartControls[key].propertyControl;
          for (let i = 0; i < collectionItems.length; i++) {
            chartUI[key]["items"][i] = {
              value: {},
              hidden: false,
              collapsed: false,
            };
            fillOutput(
              collectionPropertyControl,
              collectionItems[i],
              path
                ? path + "." + key + ".items." + i + ".value"
                : key + ".items." + i + ".value"
            );
          }
          break;
        case ControlType.FusedNumber:
          // creates container
          if (path) {
            createObjKeysByPath(chartUI, path + "." + key);
          } else {
            chartUI[key] = {};
          }
          // sets uniform value
          const uniformPath = path
            ? path + "." + key + "." + key
            : key + "." + key;
          setObjValueByPath(chartUI, uniformPath, chartSettings[key][key]);
          const toggleKey = chartControls[key].toggleKey;
          const valueKeys = chartControls[key].valueKeys;
          // sets toggle key
          const togglePath = path
            ? path + "." + key + "." + toggleKey
            : key + "." + toggleKey;
          setObjValueByPath(chartUI, togglePath, chartSettings[key][toggleKey]);
          // sets value keys
          for (let i = 0; i < valueKeys.length; i++) {
            const valuePath = path
              ? path + "." + key + "." + valueKeys[i]
              : key + "." + valueKeys[i];
            setObjValueByPath(
              chartUI,
              valuePath,
              chartSettings[key][valueKeys[i]]
            );
          }
          break;
        default:
          const defaultPath = path ? path + "." + key : key;
          setObjValueByPath(chartUI, defaultPath, chartSettings[key]);
      }
    }

    return chartUI;
  }
  return fillOutput(chartControls, chartSettings);
}

//creates chart settings from ui settings
export function createChartSettings(chartType, UISettings) {
  const chart = getChart(chartType);
  const chartControls =
    typeof chart.type.propertyControls === "function"
      ? chart.type.propertyControls(UISettings)
      : chart.type.propertyControls;
  let chartSettings = {};
  for (let key in chartControls) {
    switch (chartControls[key].type) {
      case ControlType.Array:
        chartSettings[key] = UISettings[key]
          .filter((item) => !item.hidden)
          .map((item) => item.value);
        break;
      case ControlType.Group:
        let group = JSON.parse(JSON.stringify(UISettings[key]));
        for (let k in group) {
          const groupPropertyControl =
            typeof chartControls[key].propertyControl === "function"
              ? chartControls[key].propertyControl(UISettings)
              : chartControls[key].propertyControl;
          if (groupPropertyControl[k].type === ControlType.Array) {
            const dataValues = Object.values(group[k])
              .filter((elem: any) => !elem.hidden)
              .map((elem: any) => elem.value);
            group[k] = dataValues;
          }
        }
        chartSettings[key] = group;
        break;
      case ControlType.Collection:
        const collection = JSON.parse(JSON.stringify(UISettings[key]));
        const collectionArr = collection.items;
        let newCollectionArr: any = [];
        const collectionPropertyControl =
          typeof chartControls[key].propertyControl === "function"
            ? chartControls[key].propertyControl(UISettings)
            : chartControls[key].propertyControl;
        for (let i = 0; i < collectionArr.length; i++) {
          if (!collectionArr[i].hidden) {
            const group = JSON.parse(JSON.stringify(collectionArr[i].value));
            for (let k in group) {
              if (collectionPropertyControl[k].type === ControlType.Array) {
                const dataValues = Object.values(group[k])
                  .filter((elem: any) => !elem.hidden)
                  .map((elem: any) => elem.value);
                group[k] = dataValues;
              }
            }
            newCollectionArr.push(group);
          }
        }
        chartSettings[key] = {
          isVisible: collection.isVisible,
          items: newCollectionArr,
        };
        break;
      case ControlType.FusedNumber:
        if (!chartSettings[key]) {
          chartSettings[key] = {};
        }
        chartSettings[key][key] = UISettings[key][key];
        const toggleKey = chartControls[key].toggleKey;
        const valueKeys = chartControls[key].valueKeys;
        chartSettings[key][toggleKey] = UISettings[key][toggleKey];
        for (let i = 0; i < valueKeys.length; i++) {
          chartSettings[key][valueKeys[i]] = UISettings[key][valueKeys[i]] || 0;
        }
        break;
      default:
        chartSettings[key] = UISettings[key];
    }
  }
  return chartSettings;
}

export function createObjKeysByPath(obj, path) {
  const keys = path.split(".");
  const lastKey = keys.pop();
  const lastObj = keys.reduce((obj, key) => (obj[key] = obj[key] || {}), obj);
  lastObj[lastKey] = {};
}

export function getObjValueByPath(obj, path) {
  const parts = path.split(".");
  if (obj[parts[0]] === undefined) return undefined;
  if (parts.length === 1) return obj[parts[0]];
  return getObjValueByPath(obj[parts[0]], parts.slice(1).join("."));
}

// assigns value to object by existing path
export function setObjValueByPath(obj, path, value) {
  const [head, ...rest] = path.toString().split(".");
  !rest.length
    ? (obj[head] = value)
    : setObjValueByPath(obj[head], rest.join("."), value);
}

// creates nested objects by path and assign value
export function createObjValueByPath(obj, path, value) {
  const pathArr = path.toString().split(".");
  pathArr.reduce(function (dir, path) {
    return (dir[path] = {});
  }, obj);
  setObjValueByPath(obj, path, value);
}

// adds array item in object by path
export function addArrayItemByPath(obj, path, value) {
  const [head, ...rest] = path.toString().split(".");
  !rest.length
    ? obj[head].push({ value: value, hidden: false })
    : addArrayItemByPath(obj[head], rest.join("."), value);
}

export function toggleObjValueByPath(obj, path) {
  const [head, ...rest] = path.toString().split(".");
  !rest.length
    ? (obj[head] = !obj[head])
    : toggleObjValueByPath(obj[head], rest.join("."));
}

export function removeArrayItemsByPath(obj, path) {
  const [head, ...rest] = path.toString().split(".");
  !rest.length
    ? (obj[head] = obj[head].filter((item) => !item.hidden))
    : removeArrayItemsByPath(obj[head], rest.join("."));
}

// cross links between collections
export function getCollectionRef(chartType, name) {
  const collection = getChart(chartType).type.propertyControls[name];
  return collection ? collection.ref : null;
}

export function insertGroup(array, item, i?) {
  const index = i || array.length;
  return [...array.slice(0, index), item, ...array.slice(index)];
}

export function dublicateLastGroup(array) {
  const item = JSON.parse(JSON.stringify(array.slice(-1)[0]));
  return [...array, item];
}

//Gets color in hex, rgb, rgba, hsl, hsla and returns object like {red: x, green: y, blue: z, alpha: a}
export function colorToObject(color) {
  var values = { red: 0, green: 0, blue: 0, alpha: 1 };
  if (typeof color === "string") {
    /* hex */
    if (color.indexOf("#") === 0) {
      color = color.substr(1);
      if (color.length === 3)
        values = {
          red: parseInt(color[0] + color[0], 16),
          green: parseInt(color[1] + color[1], 16),
          blue: parseInt(color[2] + color[2], 16),
          alpha: 1,
        };
      else
        values = {
          red: parseInt(color.substr(0, 2), 16),
          green: parseInt(color.substr(2, 2), 16),
          blue: parseInt(color.substr(4, 2), 16),
          alpha: 1,
        };
      /* rgb, rgba */
    } else if (color.indexOf("rgb(") === 0 || color.indexOf("rgba(") === 0) {
      var parseRGB = color
        .slice(color.indexOf("(") + 1, color.indexOf(")"))
        .split(",");
      values = {
        red: Number(parseRGB[0].trim()),
        green: Number(parseRGB[1].trim()),
        blue: Number(parseRGB[2].trim()),
        alpha: !isNaN(Number(parseRGB[3])) ? Number(parseRGB[3].trim()) : 1,
      };
    } else if (
      /* hsl, hsla */
      color.indexOf("hsl(") === 0 ||
      color.indexOf("hsla(") === 0
    ) {
      var parseHSL = color
        .slice(color.indexOf("(") + 1, color.indexOf(")"))
        .split(",");

      var h, s, l, a;
      h = parseHSL[0].trim();
      s = parseHSL[1].slice(0, parseHSL[1].indexOf("%")).trim();
      l = parseHSL[2].slice(0, parseHSL[2].indexOf("%")).trim();
      a = parseHSL[3] ? parseHSL[3].trim() : 1;

      var parseRBG = hslToRgb(h / 360, s / 100, l / 100);

      values = {
        red: parseRBG[0],
        green: parseRBG[1],
        blue: parseRBG[2],
        alpha: a,
      };
    }
  }
  return values;
}

/**
 * Converts an HSL color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes h, s, and l are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   {number}  h       The hue
 * @param   {number}  s       The saturation
 * @param   {number}  l       The lightness
 * @return  {Array}           The RGB representation
 */
function hslToRgb(h, s, l) {
  var r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    var hue2rgb = function hue2rgb(p, q, t) {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    var p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

export function RGBToHex(r, g, b) {
  r = r.toString(16);
  g = g.toString(16);
  b = b.toString(16);

  if (r.length === 1) r = "0" + r;
  if (g.length === 1) g = "0" + g;
  if (b.length === 1) b = "0" + b;

  return "#" + r + g + b;
}

export function HexToRGBA(hex, opacity) {
  const rgbObject = colorToObject(hex);
  return (
    "rgba(" +
    rgbObject.red +
    ", " +
    rgbObject.green +
    ", " +
    rgbObject.blue +
    ", " +
    opacity / 100 +
    ")"
  );
}

declare const window: any;

export function getWindowDimensions() {
  const { innerWidth, innerHeight, visualViewport } = window;

  let height = innerHeight;
  let width = innerWidth;

  // When pinch-zoom on mobile browser, it changes innerWidth and innerHeight values
  // and they are equal to visualViewport.width(height),
  // which depend on visualViewport.scale value
  // At the same time visualViewport.scale depends on zoom (scaleRatio), which we use
  // to fit chart to screen on sizes, less than min width.
  // So, if scale more than 1 (zoomed) and we watch trhough mobile browser (visualViewport.height === innerHeight),
  // we should replace innerWidth and innerHeight with calculated values

  // tested on mobile Chrome

  if (visualViewport) {
    if (
      visualViewport.scale > 1 &&
      (visualViewport.height === innerHeight ||
        visualViewport.width === innerWidth)
    ) {
      height = innerHeight * visualViewport.scale;
      width = innerWidth * visualViewport.scale;
    }
  }

  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export function toPercent(decimal, fixed = 0) {
  return `${(decimal * 100).toFixed(fixed)}%`;
}

export const getJSON = function (url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "json";

  xhr.onload = function () {
    var status = xhr.status;

    if (status === 200) {
      callback(null, xhr.response);
    } else {
      callback(status);
    }
  };

  xhr.send();
};

export const maxSize = getWindowDimensions();

//Convert number to Excel-like column's id: "A, B, ..., AA, AB, ..."
export function numberToLetters(num) {
  let letters = "";
  while (num >= 0) {
    letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"[num % 26] + letters;
    num = Math.floor(num / 26) - 1;
  }
  return letters;
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
  return item && typeof item === "object" && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

// iterate data to define axis type
export const getScaleType = (data) => {
  const types = new Set();
  for (let i = 0; i < data.length; i++) {
    if (data[i] !== "") types.add(typeof data[i]);
  }
  return types.size === 0
    ? undefined
    : types.size === 1
    ? Array.from(types)[0] === "number"
      ? "number"
      : "string"
    : "string";
};

// export const getSeriesScaleType = (data) => {
//   const types = new Set();
//   for (let i = 0; i < data.length; i++) {
//     for (let j = 0; j < data[i].length; j++) {
//       if (data[i][j] !== "") types.add(typeof data[i][j]);
//     }
//   }
//   return types.size === 0
//     ? undefined
//     : types.size === 1
//     ? Array.from(types)[0] === "number"
//       ? "number"
//       : "string"
//     : "string";
// };

function transpose(dataProps) {
  const a = dataProps.series;
  const seriesTranposed = Object.keys(a[0]).map(function (c) {
    return a.map(function (r) {
      return r[c];
    });
  });
  return { series: seriesTranposed, weights: dataProps.weights };
}

function toJSONStructure(dataProps) {
  const structure = {};

  dataProps.series.forEach((line, lineIndex) => {
    let currentNode = structure;

    line.forEach((nodeKey, nodeKeyIndex, self) => {
      //create structure like d3
      if (nodeKey) {
        const childNode = currentNode[nodeKey] || {};

        currentNode[nodeKey] = childNode;
        currentNode = childNode;
      }
      //add values to structure
      if (!self[nodeKeyIndex + 1]) {
        const value = dataProps.weights[lineIndex];
        Object.defineProperty(currentNode, "value", {
          // value: currentNode["value"] ? currentNode["value"] + value : value,
          value: value,
          writable: true,
          enumerable: false,
          configurable: true,
        });
      }
    });
  });
  return structure;
}

function toNameChildFormat(structure) {
  return Object.keys(structure).map((key) => {
    return {
      name: key,
      children: toNameChildFormat(structure[key]),
      value: structure[key].value || null,
    };
  });
}

function getHierarchies(dataProps) {
  return {
    series: dataProps.series.map((item) => item.Hierarchy),
    weights: dataProps.weights,
  };
}

export function buildHierarchyData(dataProps) {
  const compose =
    (...functions) =>
    (arg) =>
      functions.reduceRight((acc, fn) => fn(acc), arg);

  const d =
    dataProps.series.length > 0
      ? compose(
          toNameChildFormat,
          toJSONStructure,
          transpose,
          getHierarchies
        )(dataProps)
      : [];

  //if severeal root nodes, add parent one for them
  //if empty data, make empty root structure
  const dataRaw =
    d.length > 1
      ? {
          name: "",
          children: d,
        }
      : d.length === 1
      ? d[0]
      : { name: "" };

  return dataRaw;
}

export function arrToNumbers(arr) {
  const arrNumbers = arr.map((elem) => (elem === "" ? null : elem * 1));
  const isValid = arrNumbers.some((elem) => !isNaN(elem) && elem !== null); // check for array of NaN
  return isValid ? arrNumbers : [];
}

export function arrToStrings(arr) {
  const arrStr = arr.map((elem) => "" + elem + "");
  return arrStr;
}

// Get correct domains for axes. Returns "dataMin", "dataMax" or numbers.
export function getDomains(props) {
  const { dimensions, x, y, dimensionsData, layout } = props;

  let xDomain: any[] = [];
  if (
    (layout && dimensions[dimensionsData.x] === "number") ||
    (!layout && dimensions[dimensionsData.y] === "number")
  ) {
    if (!x.domain) {
      xDomain[0] = x.domainMin !== "custom" ? x.domainMin : x.domainMinValue;
      xDomain[1] = x.domainMax !== "custom" ? x.domainMax : x.domainMaxValue;
    } else xDomain = [0, "dataMax"];
  } else xDomain = undefined as any;

  let yDomain: any[] = [];
  if (
    (layout && dimensions[dimensionsData.y] === "number") ||
    (!layout && dimensions[dimensionsData.x] === "number")
  ) {
    if (!y.domain) {
      yDomain[0] = y.domainMin !== "custom" ? y.domainMin : y.domainMinValue;
      yDomain[1] = y.domainMax !== "custom" ? y.domainMax : y.domainMaxValue;
    } else yDomain = [0, "dataMax"];
  } else yDomain = undefined as any;

  return { X: xDomain, Y: yDomain };
}

// Replace "string" to "category" in dimensions object
export function getDimensions(dimensions) {
  const obj = {} as any;
  for (let key in dimensions) {
    obj[key] = dimensions[key].replace("string", "category") || undefined;
  }
  return obj;
}

// Replace "string" to "category" in domensions object
export function normalizeDimensions(dimensions) {
  const obj = {} as any;
  for (let key in dimensions) {
    obj[key] = dimensions[key].replace("string", "category") || undefined;
  }
  return obj;
}

// Custom hook to skip first render in useEffect
export const useIsMount = () => {
  const isMountRef = React.useRef(true);
  React.useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

// Validate data for chart (check for nan, null, empty values) before building chart
export function getValidDimensionDataArr(arr, type) {
  return arr.filter(
    (elem) => !Number.isNaN(elem) && elem !== null && elem !== ""
  ).length > 0
    ? arr
    : [];
}

// Get unique values from series data (if axis type is 'category')
export function getCatsFromSeries(series) {
  const arr = series.map((elem) => {
    const key = Object.keys(elem)[0]; // key might be 'line', 'area', 'bar'
    return elem[key];
  });
  return Array.from(new Set(arr.flat()));
}

// TODO - delete after refactoring (replace getTicksArr with getTicksNumber)
// Get ticks array. ticksCount - number of ticks. domain - defined axis domain. dataExtremes - data extreme values (min an max)
export function getTicksArr(
  ticksCount: number,
  domain,
  dataExtremes: Array<number>,
  accuracy: boolean
) {
  let arr = [] as any;

  if (
    dataExtremes[0] !== undefined &&
    !isNaN(dataExtremes[0]) &&
    dataExtremes[1] !== undefined &&
    !isNaN(dataExtremes[1]) &&
    domain !== undefined
  ) {
    // get min and max values from domain and data
    const min =
      domain[0] === "dataMin"
        ? dataExtremes[0]
        : Math.min(domain[0], dataExtremes[0]);
    const max =
      domain[1] === "dataMax"
        ? dataExtremes[1]
        : Math.max(domain[1], dataExtremes[1]);

    // fill array with ticks
    if (accuracy) {
      if (ticksCount) {
        if (ticksCount === 1) {
          const v =
            max - min < 1
              ? min + (max - min) / 2
              : Math.round((min + (max - min) / 2) * 100) / 100; // Math.round( num * 100 ) / 100 - hack to avoid float errors
          arr.push(v);
        }
        if (ticksCount > 1) {
          const step = (max - min) / (ticksCount - 1);
          for (let i = 0; i < ticksCount; i++) {
            const v =
              step < 1
                ? min + step * i
                : Math.round((min + step * i) * 100) / 100;
            arr.push(v);
          }
        }
      }
    } else {
      arr = d3.ticks(min, max, ticksCount);
    }
  }

  return arr;
}

// Get ticks array. ticksCount - number of ticks. domain - defined axis domain. dataExtremes - data extreme values (min an max)
export function getTicksNumber(
  ticksCount: number,
  domain,
  dataExtremes,
  accuracy: boolean
) {
  let arr = [] as any;

  if (
    dataExtremes.min !== undefined &&
    !isNaN(dataExtremes.min) &&
    dataExtremes.max !== undefined &&
    !isNaN(dataExtremes.max) &&
    domain !== undefined
  ) {
    // get min and max values from domain and data
    const min =
      domain[0] === "dataMin"
        ? dataExtremes.min
        : Math.min(domain[0], dataExtremes.min);
    const max =
      domain[1] === "dataMax"
        ? dataExtremes.max
        : Math.max(domain[1], dataExtremes.max);

    // fill array with ticks
    if (accuracy) {
      if (ticksCount) {
        if (ticksCount === 1) {
          const v =
            max - min < 1
              ? min + (max - min) / 2
              : Math.round((min + (max - min) / 2) * 100) / 100; // Math.round( num * 100 ) / 100 - hack to avoid float errors
          arr.push(v);
        }
        if (ticksCount > 1) {
          const step = (max - min) / (ticksCount - 1);
          for (let i = 0; i < ticksCount; i++) {
            const v =
              step < 1
                ? min + step * i
                : Math.round((min + step * i) * 100) / 100;
            arr.push(v);
          }
        }
      }
    } else {
      arr = d3.ticks(min, max, ticksCount);
    }
  }

  return arr;
}

// Get ticks array for category axis
export function getTicksCategory(values, interval: number) {
  return values.filter((entry, i) => i % (interval + 1) === 0);
}

// Validate chart data for emptyness
export function validateChartData(chartData, seriesCount) {
  return chartData.length > 0 && seriesCount > 0 ? true : false;
}

// Get min and max values from array
export function getExtremes(values) {
  let min = undefined as any;
  let max = undefined as any;

  for (let i = 0; i < values.length; i++) {
    const elem = values[i];
    if (!isNaN(elem) && elem !== "" && elem !== null) {
      min = min === undefined ? elem : Math.min(min, elem);
      max = max === undefined ? elem : Math.max(max, elem);
    }
  }

  return { min, max };
}

// Get ticks for axes (Area, Line, Barcharts... except Scatter)
export function getTicks(
  data,
  dimensions,
  domain,
  xAxisProps,
  yAxisProps,
  dimensionsData,
  layout,
  stacked?,
  stackOffsetType?
) {
  const itemsUnique = new Set() as any;
  const seriesUnique = new Set() as any;

  if (stacked) {
    let stackOffsetFn = d3.stackOffsetSilhouette;

    switch (stackOffsetType) {
      case "silhouette":
        stackOffsetFn = d3.stackOffsetSilhouette;
        break;
      case "wiggle":
        stackOffsetFn = d3.stackOffsetWiggle;
        break;
      case "expand":
        stackOffsetFn = d3.stackOffsetExpand;
        break;
      case "none":
        stackOffsetFn = d3.stackOffsetNone;
        break;
      default:
        stackOffsetFn = d3.stackOffsetNone;
        break;
    }

    const series = [] as any;

    for (let i = 0; i < data.length; i++) {
      if (data[i]) {
        const { name, ...s } = data[i]; // remove name from data row
        const rowArr = Object.values(s); // convert object into array
        series.push(rowArr);
        itemsUnique.add(data[i].name);
      }
    }

    const stack = d3
      .stack()
      .keys(d3.range(d3.transpose(series).length))
      .offset(stackOffsetFn)
      .order(d3.stackOrderNone);

    const stackedSeries = stack(series);

    for (let i = 0; i < stackedSeries.length; i++) {
      for (let j = 0; j < stackedSeries[i].length; j++) {
        seriesUnique.add(stackedSeries[i][j][0]);
        seriesUnique.add(stackedSeries[i][j][1]);
      }
    }
  } else {
    for (let i = 0; i < data.length; i++) {
      if (data[i]) {
        const keys = Object.keys(data[i]);
        for (let j = 0; j < keys.length; j++) {
          const v = data[i][keys[j]];
          if (keys[j] === "name") {
            itemsUnique.add(v);
          } else {
            seriesUnique.add(v);
          }
        }
      }
    }
  }

  let x = [] as any;
  let y = [] as any;

  if (
    (layout && dimensions[dimensionsData["x"]] === "number") ||
    (!layout && dimensions[dimensionsData["y"]] === "number")
  ) {
    x = getTicksNumber(
      xAxisProps.tickCountValue,
      domain.X,
      getExtremes([...Array.from(layout ? itemsUnique : seriesUnique)]),
      xAxisProps.tickCountAccuracy
    );
  } else {
    const xInterval = xAxisProps.isTicksAuto
      ? 0
      : xAxisProps.tickCountCategory
      ? 0
      : xAxisProps.tickCountValueCategory;
    x = getTicksCategory(
      [...Array.from(layout ? itemsUnique : seriesUnique)],
      xInterval
    );
  }

  if (
    (layout && dimensions[dimensionsData["y"]] === "number") ||
    (!layout && dimensions[dimensionsData["x"]] === "number")
  ) {
    y = getTicksNumber(
      yAxisProps.tickCountValue,
      domain.Y,
      getExtremes([...Array.from(layout ? seriesUnique : itemsUnique)]),
      yAxisProps.tickCountAccuracy
    );
  } else {
    const yInterval = yAxisProps.isTicksAuto
      ? 0
      : yAxisProps.tickCountCategory
      ? 0
      : yAxisProps.tickCountValueCategory;
    y = getTicksCategory(
      [...Array.from(layout ? seriesUnique : itemsUnique)],
      yInterval
    );
  }

  return { x, y };
}

// gets data and rule to highlight bars in barchart and returns data extended with isHighlighted prop
export function getHighlightedBars(data, rule, constant) {
  const valuesArr = data.map((elem) => elem.value);
  const result = [] as any;

  switch (rule) {
    case "Max":
      data.map((elem) => {
        result.push({
          ...elem,
          isHighlighted: elem.value === Math.max(...valuesArr),
        });
      });
      break;
    case "Min":
      data.map((elem) => {
        result.push({
          ...elem,
          isHighlighted: elem.value === Math.min(...valuesArr),
        });
      });
      break;
    case "More than…":
      data.map((elem) => {
        result.push({ ...elem, isHighlighted: elem.value > constant });
      });
      break;
    case "Less than…":
      data.map((elem) => {
        result.push({ ...elem, isHighlighted: elem.value < constant });
      });
      break;
    case "Equal to…":
      data.map((elem) => {
        result.push({ ...elem, isHighlighted: elem.value === constant });
      });
      break;
  }

  return result;
}

// get chart's center in pixels
export function getChartCenterX(chartWidth, chartMargins, axis) {
  const m = chartMargins.isPerSide
    ? chartMargins.left + chartMargins.right
    : chartMargins.margin * 2;
  const axisWidth = axis.isVisible ? axis.width : 0;
  const mLeft = chartMargins.isPerSide
    ? chartMargins.left
    : chartMargins.margin;
  return (
    mLeft +
    (axisWidth > 0 && axis.orient === "left" ? axisWidth : 0) +
    (chartWidth - m - axisWidth) / 2
  );
}
// doesn't count native rechart's brush and legend heights
export function getChartCenterY(chartHeight, chartMargins, axis) {
  const m = chartMargins.isPerSide
    ? chartMargins.top + chartMargins.bottom
    : chartMargins.margin * 2;
  const axisHeight = axis.isVisible ? axis.height : 0;
  const mTop = chartMargins.isPerSide ? chartMargins.top : chartMargins.margin;
  return (
    mTop +
    (axisHeight > 0 && axis.orient === "top" ? axisHeight : 0) +
    (chartHeight - m - axisHeight) / 2
  );
}

export function getChartViewBox(
  chartWidth,
  chartHeight,
  chartMargins,
  xAxis,
  yAxis
) {
  const mLeft = chartMargins.isPerSide
    ? chartMargins.left
    : chartMargins.margin;
  const yAxisWidth = yAxis.isVisible ? yAxis.width : 0;

  const x =
    mLeft + (yAxisWidth > 0 && yAxis.orient === "left" ? yAxisWidth : 0);

  const mTop = chartMargins.isPerSide ? chartMargins.top : chartMargins.margin;
  const xAxisHeight = xAxis.isVisible ? xAxis.height : 0;

  const y =
    mTop + (xAxisHeight > 0 && xAxis.orient === "top" ? xAxisHeight : 0);

  const mHoriz = chartMargins.isPerSide
    ? chartMargins.left + chartMargins.right
    : chartMargins.margin * 2;

  const mVert = chartMargins.isPerSide
    ? chartMargins.top + chartMargins.bottom
    : chartMargins.margin * 2;

  return {
    x: x,
    y: y,
    width: chartWidth - mHoriz - yAxisWidth,
    height: chartHeight - mVert - xAxisHeight,
  };
}

// get coordinates for ReferenceDot
export function getRefDotCoordinates(
  xType,
  yType,
  xNumValue,
  xCatValue,
  yNumValue,
  yCatValue,
  xExistedValue,
  yExistedValue,
  layout
) {
  if (!layout) {
    [xType, yType] = [yType, xType];
    [xNumValue, yNumValue] = [yNumValue, xNumValue];
    [xCatValue, yCatValue] = [yCatValue, xCatValue];
    [xExistedValue, yExistedValue] = [yExistedValue, xExistedValue];
  }

  let x = xType === "number" ? xNumValue : xCatValue;
  let y = yType === "number" ? yNumValue : yCatValue;

  let isX = true;
  let isY = true;

  if (x === null || x === "") {
    isX = false;
    x = xExistedValue; // any true value
  }

  if (y === null || y === "") {
    isY = false;
    y = yExistedValue;
  }

  return { x: x, y: y, isX: isX, isY: isY };
}
