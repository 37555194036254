import * as React from "react";
import { useState, useEffect } from "react";
import { ControlType, applyPropertyControls } from "../ui/controltype";
import { useDispatch } from "react-redux";
import { UPDATE_CHART_REF } from "../utils/actions";
import * as d3 from "d3";
import { Helmet } from "react-helmet";
import {
  presetLayout,
  presetTitle,
  presetCaption,
} from "./propertyControlsPresets";
import { buildHierarchyData } from "../utils/utils";
import { TextBlock } from "./textBlock";
import { useHeaderHeight } from "./customHooks";

const dataGroups = [
  {
    Hierarchy: [
      "King George VI",
      "King George VI",
      "King George VI",
      "King George VI",
      "King George VI",
      "King George VI",
      "King George VI",
      "King George VI",
      "King George VI",
      "King George VI",
      "King George VI",
      "King George VI",
    ],
  },
  {
    Hierarchy: [
      "Queen Elizabeth II",
      "Queen Elizabeth II",
      "Queen Elizabeth II",
      "Queen Elizabeth II",
      "Queen Elizabeth II",
      "Queen Elizabeth II",
      "Queen Elizabeth II",
      "Queen Elizabeth II",
      "Princess Magaret",
      "Princess Magaret",
      "Princess Magaret",
      "Princess Magaret",
    ],
  },
  {
    Hierarchy: [
      "Charles Prince of Wales",
      "Princess Anne",
      "Princess Anne",
      "Princess Anne",
      "Andrew Duke of York",
      "Andrew Duke of York",
      "Edward Earl of Wessex",
      "Edward Earl of Wessex",
      "David Viscount Linley",
      "David Viscount Linley",
      "Lady Sara Chatto",
      "Lady Sara Chatto",
    ],
  },
  {
    Hierarchy: [
      "Prince Harry of Wales",
      "Peter Phillips",
      "Peter Phillips",
      "Zara Tindall",
      "Princess Beatrice of York",
      "Princess Eugenie of York",
      "Lady Louise Windsor",
      "James, Viscount Severn",
      "Charles Armstrong Jones",
      "Armstrong Jones",
      "Samuel Chatto",
      "Arthur Chatto",
    ],
  },
  {
    Hierarchy: [
      0,
      "Savannah Phillips",
      "Isla Phillips",
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
    ],
  },
];
const dataValues = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];

const drag = (simulation) => {
  function dragstarted(event, d) {
    if (!event.active) simulation.alphaTarget(0.3).restart();
    d.fx = d.x;
    d.fy = d.y;
  }

  function dragged(event, d) {
    d.fx = event.x;
    d.fy = event.y;
  }

  function dragended(event, d) {
    if (!event.active) simulation.alphaTarget(0);
    d.fx = null;
    d.fy = null;
  }

  return d3
    .drag()
    .on("start", dragstarted)
    .on("drag", dragged)
    .on("end", dragended);
};

export function HierarchyForceDirected(props) {
  const {
    layoutSettings,
    chartSettings,
    links,
    branches,
    leaves,
    title,
    caption,
    dataWeights,
    dataSeries,
  } = props;

  const { background, width } = layoutSettings;

  const dataWeightsJSON = JSON.stringify(dataWeights);
  const dataSeriesJSON = JSON.stringify(dataSeries);

  const dispatch = useDispatch();

  const chartRef = React.useRef<HTMLDivElement>(null);
  const { headerRef, height } = useHeaderHeight(
    width,
    layoutSettings,
    caption,
    title,
    {},
    ""
  );

  const [fontLoaded, setFontLoaded] = React.useState(false);

  // custom fonts loading status
  title["fontLoaded"] = fontLoaded;
  for (let i = 0; i < caption.items.length; i++) {
    caption.items[i]["fontLoaded"] = fontLoaded;
  }

  useEffect(() => {
    document.fonts.ready.then(() => {
      setFontLoaded(true);
    });
  });

  const [data, setData] = useState(d3.hierarchy({}));
  const [dataChangedFlag, setDataChangedFlag] = useState(true);

  const simulationRef = React.useRef(null as any);
  const labelsPadding = 4;

  const weightNodeMax = d3.max(data.descendants(), (d) => d.value);
  const weighNodetMin = d3.min(data.descendants(), (d) => d.value);
  const weightlinkMax = d3.max(data.links(), (d) => d.target.value);
  const weightlinkMin = d3.min(data.links(), (d) => d.target.value);

  const scaleLeavesSize = d3
    .scaleLinear()
    .domain([weighNodetMin, weightNodeMax])
    .range([chartSettings.nodeSizeMin, chartSettings.nodeSizeMax]);

  const scaleLinksSize = d3
    .scaleLinear()
    .domain([
      chartSettings.syncLinkWeights ? weighNodetMin : weightlinkMin,
      chartSettings.syncLinkWeights ? weightNodeMax : weightlinkMax,
    ])
    .range([
      chartSettings.syncLinkWeights
        ? chartSettings.nodeSizeMin
        : chartSettings.linkSizeMin,
      chartSettings.syncLinkWeights
        ? chartSettings.nodeSizeMax
        : chartSettings.linkSizeMax,
    ]);

  //get third point triangle coordinates if we have two others and bendRadius
  function findD(A, B, dist, left = 1) {
    // if left = 1 the D is left of the line AB
    const nx = B.x - A.x;
    const ny = B.y - A.y;
    dist /= Math.sqrt(nx * nx + ny * ny) * left;
    return {
      x: A.x + nx / 2 - ny * dist,
      y: A.y + ny / 2 + nx * dist,
    };
  }

  //get path for link
  //d: link data, bendRadius: link bend radius (% of link length)
  function getPath(d, bendRadius) {
    let dx = d.target.x - d.source.x,
      dy = d.target.y - d.source.y,
      dr = Math.sqrt(dx * dx + dy * dy) / 2,
      drUser = (dr * bendRadius) / 100;
    const Q = findD(
      { x: d.source.x, y: d.source.y },
      { x: d.target.x, y: d.target.y },
      drUser
    );
    return (
      "M" +
      d.source.x +
      " " +
      d.source.y +
      "Q" +
      Q.x +
      " " +
      Q.y +
      " " +
      d.target.x +
      " " +
      d.target.y
    );
  }

  const svgViewBox = [
    -width / 2 - chartSettings.xOffset,
    -height / 2 - chartSettings.yOffset,
    width,
    height,
  ];

  //Apply style
  function applyStyle(svg) {
    svg
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", svgViewBox)
      .style("background-color", background);

    //nodes
    svg
      .selectAll("circle")
      //Size
      .attr("r", (d) =>
        d.hasOwnProperty("children")
          ? chartSettings.useWeights
            ? scaleLeavesSize(d.value) / 2
            : branches.size / 2
          : chartSettings.useWeights
          ? scaleLeavesSize(d.value) / 2
          : leaves.size / 2
      )
      //Fill and stroke leaves and branches
      .attr("fill", (d) =>
        d.hasOwnProperty("children") ? branches.color : leaves.color
      )
      .attr("stroke-width", (d) =>
        d.hasOwnProperty("children") ? branches.strokeWidth : leaves.strokeWidth
      )
      .attr("stroke", (d) =>
        d.hasOwnProperty("children") ? branches.strokeColor : leaves.strokeColor
      );

    //links
    svg
      .selectAll("path")
      .attr("fill", "none")
      .attr("stroke", links.color)
      .attr(
        "stroke-width",
        chartSettings.useWeights
          ? (d) => scaleLinksSize(d.target.value)
          : links.stroke
      )
      //bending links
      .attr("d", (d) => getPath(d, links.bendRadius));

    //labels
    svg
      .select("#svgTreeLabels")
      .selectAll("text")
      .attr("dx", (d) =>
        d.hasOwnProperty("children")
          ? chartSettings.useWeights
            ? scaleLeavesSize(d.value) / 2 + labelsPadding
            : branches.size / 2 + labelsPadding
          : chartSettings.useWeights
          ? scaleLeavesSize(d.value) / 2 + labelsPadding
          : leaves.size / 2 + labelsPadding
      )
      .style("font-size", (d) =>
        d.hasOwnProperty("children")
          ? branches.labelFontSize
          : leaves.labelFontSize
      )
      .attr("fill", (d) =>
        d.hasOwnProperty("children")
          ? branches.showLabels
            ? branches.labelColor
            : "rgba(0, 0, 0, 0)"
          : leaves.showLabels
          ? leaves.labelColor
          : "rgba(0, 0, 0, 0)"
      )
      .attr("color", (d) =>
        d.hasOwnProperty("children")
          ? branches.showLabels
            ? branches.labelColor
            : "rgba(0, 0, 0, 0)"
          : leaves.showLabels
          ? leaves.labelColor
          : "rgba(0, 0, 0, 0)"
      );
  }

  const dataLinks = data.links() || [];
  const dataNodes = data.descendants() || [];

  //Updating when strength or strengthCharge changes
  useEffect(() => {
    if (simulationRef.current) {
      simulationRef.current.force("link").strength(links.strength);
      simulationRef.current.force("charge").strength(-1 * links.strengthCharge);
      simulationRef.current.alpha(1).restart();
    }
  }, [links.strength, links.strengthCharge]);

  //Updating when recieve new data (and first time mounted)
  useEffect(() => {
    const svg = d3.select("#svgTree");

    if (data.children) {
      const linkForce = d3
        .forceLink(dataLinks)
        .id((d) => d.id)
        .distance(0)
        .strength(links.strength);

      const simulation = d3
        .forceSimulation(dataNodes)
        .force("link", linkForce)
        .force("charge", d3.forceManyBody().strength(-1 * links.strengthCharge))
        .force("x", d3.forceX())
        .force("y", d3.forceY());

      //TODO update link's bend radius while simulation doesn't work
      const linkSVG = svg
        .select("#svgTreeLinks")
        .selectAll("path")
        .data(dataLinks)
        .join("path");
      const node = svg
        .select("#svgTreeNodes")
        .selectAll("circle")
        .data(dataNodes)
        .join("circle")
        .call(drag(simulation));

      const label = svg
        .select("#svgTreeLabels")
        .selectAll("text")
        .data(dataNodes)
        .join("text")
        .style("text-anchor", "start")
        .text((d) => d.data.name)
        .attr("x", (d) => d.x)
        .attr("y", (d) => d.y);

      simulation.on("tick", () => {
        linkSVG.attr("d", (d) => getPath(d, links.bendRadius));
        node.attr("cx", (d) => d.x).attr("cy", (d) => d.y);
        label.attr("x", (d) => d.x).attr("y", (d) => d.y);
      });

      //Updating simulation ref
      simulationRef.current = simulation;
    } else {
      svg.select("#svgTreeLinks").selectAll("path").remove();
      svg.select("#svgTreeNodes").selectAll("circle").remove();
      svg.select("#svgTreeLabels").selectAll("text").remove();
    }
  }, [dataChangedFlag, chartSettings.useWeights]);

  useEffect(() => {
    applyStyle(d3.select(chartRef.current).select("#svgTree"));
  });

  useEffect(() => {
    const dataRaw = buildHierarchyData({
      series: dataSeries.items,
      weights: dataWeights.Weights,
    });
    //calc summ of leaves values and put value prop into nodes
    const root = dataRaw
      ? d3.hierarchy(dataRaw).sum((elem) => elem.value)
      : null;
    setData(root);
    setDataChangedFlag(!dataChangedFlag);
  }, [dataWeightsJSON, dataSeriesJSON]);

  useEffect(() => {
    const element = chartRef.current;
    if (element) {
      const svg = element;
      dispatch(UPDATE_CHART_REF(svg));
    }
  }, [chartRef, dispatch]);

  return (
    <div
      id="HierarchyForceDirected"
      ref={chartRef}
      style={{ background: background }}
    >
      <Helmet>
        <link
          rel="stylesheet"
          href={
            "https://fonts.googleapis.com/css?family=" +
            title.fontFamily.replace(/ /g, "+") +
            ":" +
            title.fontVariants
          }
          crossOrigin="anonymous"
        />
        {caption.items.map((elem, i) => {
          return (
            <link
              key={"caption-" + i}
              rel="stylesheet"
              href={
                "https://fonts.googleapis.com/css?family=" +
                elem.fontFamily.replace(/ /g, "+") +
                ":" +
                elem.fontVariants
              }
              crossOrigin="anonymous"
            />
          );
        })}
      </Helmet>
      <div ref={headerRef} style={{ width: width }}>
        {title.isVisible ? (
          <TextBlock key={"title"} title={title} fontLoaded={fontLoaded} />
        ) : (
          ""
        )}
        {caption.isVisible
          ? caption.items.map((elem, i) => {
              return (
                <TextBlock
                  key={"caption" + i}
                  title={elem}
                  fontLoaded={fontLoaded}
                />
              );
            })
          : ""}
      </div>
      <svg id="svgTree" className="chart-surface">
        <g id="svgTreeLinks" />
        <g id="svgTreeNodes" />
        <g id="svgTreeLabels" />
      </svg>
    </div>
  );
}

HierarchyForceDirected.defaultProps = {
  isData: false,
  layoutSettings: {
    isFullscreen: true,
    isWidthAuto: true,
    minWidth: 320,
    maxWidth: 2500,
    isHeightAuto: true,
    background: "rgba(255, 255, 255, 1)",
  },
  chartSettings: {
    useWeights: false,
    nodeSizeMin: 3,
    nodeSizeMax: 20,
    syncLinkWeights: true,
    linkSizeMin: 1,
    linkSizeMax: 8,
    xOffset: 0,
    yOffset: 0,
  },
  sourceData: true,
  fileData: null,
  links: {
    color: "rgba(17, 153, 238, .25)",
    stroke: 1,
    strength: 1,
    strengthCharge: 3000,
    bendRadius: 0,
  },
  branches: {
    size: 6,
    color: "rgba(255, 255, 255, 1)",
    strokeColor: "rgba(17, 153, 238, 1)",
    strokeWidth: 1,
    showLabels: true,
    labelColor: "rgba(17, 153, 238, 1)",
    labelFontSize: 21,
  },
  leaves: {
    size: 6,
    color: "rgba(17, 153, 238, 1)",
    strokeColor: "rgba(17, 153, 238, 1)",
    strokeWidth: 0,
    showLabels: true,
    labelColor: "rgba(17, 153, 238, 1)",
    labelFontSize: 12,
  },
  title: {
    isVisible: false,
    text: "Chart title",
    txtColor: "rgba(0,0,0,1)",
    fontSize: 48,
    fontFamily: "Roboto",
    fontVariants: "regular",
    align: "center",
    margin: {
      margin: 0,
      isPerSide: true,
      top: 20,
      bottom: 20,
      left: 0,
      right: 0,
    },
  },
  caption: {
    isVisible: false,
    items: [
      {
        text: "Chart description or data source",
        txtColor: "rgba(0,0,0,.5)",
        fontSize: 18,
        fontFamily: "Roboto",
        fontVariants: "300",
        align: "center",
        margin: {
          margin: 0,
          isPerSide: true,
          top: 20,
          bottom: 20,
          left: 0,
          right: 0,
        },
      },
    ],
  },
  dataWeights: {
    Weights: dataValues,
  },
  dataSeries: {
    isVisible: true,
    items: dataGroups,
  },
  dimensions: {
    Hierarchy: ControlType.String,
    Weights: ControlType.Number,
  },
};

applyPropertyControls(HierarchyForceDirected, {
  //Settings or data
  isData: {
    type: ControlType.Boolean,
    title: "Change",
    disabledTitle: "settings",
    enabledTitle: "data",
    wide: true,
  },

  layoutSettings: presetLayout,

  chartSettings: {
    type: ControlType.Group,
    title: "Chart settings",
    isHeaderControls: false,
    propertyControl: {
      //Use weights for leaves and branches sizes
      useWeights: {
        type: ControlType.Boolean,
        title: "Use weights",
        enabledTitle: "yes",
        disabledTitle: "no",
      },
      nodeSizeMin: {
        type: ControlType.Number,
        title: "↳ Node Min",
        displayStepper: true,
        hidden(props) {
          return props.useWeights === false;
        },
      },
      nodeSizeMax: {
        type: ControlType.Number,
        title: "↳ Node Max",
        displayStepper: true,
        hidden(props) {
          return props.useWeights === false;
        },
      },
      syncLinkWeights: {
        type: ControlType.Boolean,
        title: "↳ Sync links",
        enabledTitle: "yes",
        disabledTitle: "no",
        hidden(props) {
          return props.useWeights === false;
        },
      },
      linkSizeMin: {
        type: ControlType.Number,
        title: "↳ Link Min",
        displayStepper: true,
        hidden(props) {
          return props.syncLinkWeights === true;
        },
      },
      linkSizeMax: {
        type: ControlType.Number,
        title: "↳ Link Max",
        displayStepper: true,
        hidden(props) {
          return props.syncLinkWeights === true;
        },
      },

      //Common settings
      xOffset: {
        type: ControlType.Number,
        title: "X offset",
        min: -1000,
        max: 1000,
      },
      yOffset: {
        type: ControlType.Number,
        title: "Y offset",
        min: -1000,
        max: 1000,
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Links
  links: {
    type: ControlType.Group,
    title: "Links",
    isHeaderControls: false,
    propertyControl: {
      color: {
        title: "Color",
        type: ControlType.Color,
      },
      stroke: {
        type: ControlType.Number,
        title: "Thickness",
        displayStepper: true,
        hidden(props, UI) {
          return UI.chartSettings.useWeights === true;
        },
      },
      strength: {
        type: ControlType.Number,
        title: "",
        hidden: () => true,
      },
      strengthCharge: {
        type: ControlType.Number,
        title: "Strength",
        displayStepper: true,
        max: 10000,
        step: 100,
      },
      //Bend radius link's path
      bendRadius: {
        type: ControlType.Number,
        title: "Bend radius",
        min: -200,
        max: 200,
        unit: "%",
        // step: 0.1,
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Branches
  branches: {
    type: ControlType.Group,
    title: "Branches",
    isHeaderControls: false,
    propertyControl: {
      size: {
        type: ControlType.Number,
        title: "Size",
        displayStepper: true,
        hidden(props, UI) {
          return UI.chartSettings.useWeights === true;
        },
      },
      color: {
        title: "Fill",
        type: ControlType.Color,
      },
      strokeColor: {
        title: "Stroke color",
        type: ControlType.Color,
      },
      strokeWidth: {
        type: ControlType.Number,
        title: "Stroke size",
        displayStepper: true,
      },
      showLabels: {
        type: ControlType.Boolean,
        title: "Labels",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      labelColor: {
        type: ControlType.Color,
        title: "↳ Color",
        hidden(props) {
          return props.showLabels === false;
        },
      },
      labelFontSize: {
        type: ControlType.Number,
        title: "↳ Size",
        hidden(props) {
          return props.showLabels === false;
        },
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Leaves
  leaves: {
    type: ControlType.Group,
    title: "Leaves",
    isHeaderControls: false,
    propertyControl: {
      size: {
        type: ControlType.Number,
        title: "Size",
        displayStepper: true,
        hidden(props, UI) {
          return UI.chartSettings.useWeights === true;
        },
      },
      color: {
        title: "Fill",
        type: ControlType.Color,
      },
      strokeColor: {
        title: "Stroke color",
        type: ControlType.Color,
      },
      strokeWidth: {
        type: ControlType.Number,
        title: "Stroke size",
        displayStepper: true,
      },
      showLabels: {
        type: ControlType.Boolean,
        title: "Labels",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      labelColor: {
        type: ControlType.Color,
        title: "↳ Color",
        hidden(props) {
          return props.showLabels === false;
        },
      },
      labelFontSize: {
        type: ControlType.Number,
        title: "↳ Size",
        hidden(props) {
          return props.showLabels === false;
        },
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Title
  title: presetTitle,

  //Caption
  caption: presetCaption,

  //Data
  sourceData: {
    type: ControlType.Boolean,
    title: "Data",
    enabledTitle: "editable",
    disabledTitle: "file",
    hidden(props) {
      return props.isData === false;
    },
  },
  fileData: {
    title: "↳ File",
    type: ControlType.File,
    allowedFileTypes: ["xls"],
    hidden(props) {
      return props.sourceData === true || props.isData === false;
    },
  },

  //Weights data
  dataWeights: {
    type: ControlType.Group,
    title: "Weights",
    propertyControl: {
      Weights: {
        type: ControlType.Array,
        title: "Weights",
        propertyControl: {
          type(props) {
            return props.dimensions.Weights;
          },
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },

  //Hierarchy Data
  dataSeries: {
    type: ControlType.Collection,
    title: "Hierarchy",
    // ref: "nodes",
    propertyControl: {
      Hierarchy: {
        type: ControlType.Array,
        title: "Hierarchy",
        propertyControl: {
          type(props) {
            return props.dimensions.Hierarchy;
          },
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },

  //Chart's dimensions
  dimensions: {
    type: ControlType.Group,
    title: "Dimensions",
    propertyControl: {
      Hierarchy: {
        type: ControlType.Enum,
        seriesType: true,
        title: "Group by",
        path: "dataSeries",
        options: [ControlType.String, ControlType.Number],
      },
      Weights: {
        type: ControlType.Enum,
        seriesType: false,
        title: "Weights",
        path: "dataWeights",
        options: [ControlType.Number],
      },
    },
    hidden() {
      return true;
    },
  },
});

export default HierarchyForceDirected;
