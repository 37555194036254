import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, TextField, Button, Typography } from "@mui/material";
import { Charts } from "../charts/charts";
import {
  getFirestore,
  addDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import {
  UPDATE_CURRENT_CHART_ID,
  IS_CURRENT_CHART_SAVED,
  UPDATE_CURRENT_CHART_NAME,
} from "../utils/actions";
import { getChartList, getChartsLimit } from "../../firestore";
import "../../App.css";

export function SaveNewChart(props) {
  const { handleClose } = props;

  const chartType = useSelector((state: any) => state.chart.currentChartType);
  const chartSettings = useSelector((state: any) => state.chart.chartSettings);
  const chartUI = useSelector((state: any) => state.chart.chartUI);
  const collectionCharts = useSelector(
    (state: any) => state.firebase.collectionCharts
  );
  const collectionUsers = useSelector(
    (state: any) => state.firebase.collectionUsers
  );

  const chartName = Charts[chartType].title;

  const [inputValue, updateInputValue] = React.useState(chartName);
  const [saveProgress, setSaveProgress] = React.useState(false);
  const [saveError, setSaveError] = React.useState(false);
  const [isLoadingChartsList, setIsLoadingChartsList] = React.useState(true);
  const [isLoadingChartsLimit, setIsLoadingChartsLimit] = React.useState(true);
  const [chartsList, setChartsList] = React.useState([]);
  const [userChartsLimit, setUserChartsLimit] = React.useState(0);

  const auth = getAuth();
  const db = getFirestore();
  const dispatch = useDispatch();

  //update local state while using keyboard
  const handleChange = (event: React.ChangeEvent<{ value: string }>) => {
    const value = event.target.value;
    const str = !value.replace(/\s/g, "").length ? "" : value; // if value contains only spaces, delete it
    updateInputValue(str);
  };

  const handleSaveChart = async () => {
    if (auth.currentUser) {
      setSaveProgress(true);

      const chart = {
        chartType: chartType,
        chartSettings: chartSettings,
        chartUI: chartUI,
      };

      try {
        await addDoc(collection(db, collectionCharts), {
          userId: auth.currentUser.uid,
          created: serverTimestamp(),
          updated: serverTimestamp(),
          chart: chart,
          chartName: inputValue,
        }).then((docRef) => {
          dispatch(UPDATE_CURRENT_CHART_ID(docRef.id));
          dispatch(UPDATE_CURRENT_CHART_NAME(inputValue));
          dispatch(IS_CURRENT_CHART_SAVED(true));
          handleClose();
        });
      } catch (e) {
        setSaveProgress(false);
        setSaveError(true);
        console.error("Error adding document: ", e);
      }
    }
  };

  React.useEffect(() => {
    setIsLoadingChartsList(true);
    setIsLoadingChartsLimit(true);
    getChartList(auth, collectionCharts).then((result: any) => {
      if (result) {
        setChartsList(result);
      }
      setIsLoadingChartsList(false);
    });
    getChartsLimit(auth, db, collectionUsers).then((result: any) => {
      if (result) {
        setUserChartsLimit(result);
      }
      setIsLoadingChartsLimit(false);
    });
  }, []);

  const Save = () => (
    <Grid container spacing={2} style={{ marginBottom: 8, marginTop: 8 }}>
      {saveError ? (
        <Grid item style={{ width: "100%" }}>
          <Typography color="error">Chart was not saved. Try again.</Typography>
        </Grid>
      ) : (
        ""
      )}
      <Grid item style={{ width: "100%" }}>
        <TextField
          disabled={saveProgress}
          fullWidth
          type="text"
          variant="outlined"
          label="Name it"
          value={inputValue}
          autoFocus
          inputProps={{
            maxLength: 255,
          }}
          onChange={handleChange}
        />
      </Grid>
      <Grid container spacing={2} alignItems="center" style={{ margin: 0 }}>
        <Grid item>
          <Button
            disabled={inputValue === "" || saveProgress}
            variant="contained"
            color="primary"
            size="large"
            disableElevation
            onClick={handleSaveChart}
          >
            Save chart
          </Button>
        </Grid>
        <Grid item>
          {saveProgress ? (
            <div style={{ width: 40, height: 40 }}>
              <span className="loader" />
            </div>
          ) : (
            ""
          )}
        </Grid>
        <Grid item>{saveProgress ? <span>Please, wait</span> : ""}</Grid>
      </Grid>
    </Grid>
  );

  const Warning = () => (
    <Grid container spacing={2} style={{ marginBottom: 8 }}>
      <Grid item style={{ width: "100%" }}>
        <Typography variant="h5">Sorry, limit reached</Typography>
        <Typography style={{ marginTop: 8 }}>
          {chartsList.length} of {userChartsLimit} charts used. To save new
          chart, you should to delete some of existing ones.
        </Typography>
      </Grid>
    </Grid>
  );

  return isLoadingChartsList || isLoadingChartsLimit ? (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <span className="loader" />
    </div>
  ) : userChartsLimit && chartsList.length < userChartsLimit ? (
    <Save />
  ) : (
    <Warning />
  );
}

export default SaveNewChart;
