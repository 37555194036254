import * as React from "react";

export const CustomizedDot = (props) => {
  const {
    layout,
    cx,
    cy,
    dotsStyle,
    dotsColor,
    dotsSize,
    dotsStrokeWidth,
    dotsTypeDirection,
    Y,
    dotsType,
    valueY1,
    valueY2,
    svgStyle,
    index,
    highlightedDots,
    highlightedColor,
  } = props;

  const stroke = !dotsStyle ? dotsStrokeWidth : 0;
  const color =
    highlightedDots &&
    highlightedDots.length > 0 &&
    highlightedDots[index].isHighlighted
      ? highlightedColor
      : dotsColor;

  if (
    isNaN(cx) ||
    cx === null ||
    cx === "" ||
    cx === undefined ||
    isNaN(cy) ||
    cy === null ||
    cy === "" ||
    cy === undefined
  ) {
    return null;
  }

  switch (dotsType) {
    case "circle":
      return (
        <CustomizedCircle
          cx={cx}
          cy={cy}
          dotsStyle={dotsStyle}
          dotsColor={color}
          dotsSize={dotsSize}
          dotsStrokeWidth={stroke}
          svgStyle={svgStyle}
        />
      );
    case "rhombus":
      return (
        <CustomizedRhombus
          cx={cx}
          cy={cy}
          dotsStyle={dotsStyle}
          dotsColor={color}
          dotsSize={dotsSize}
          dotsStrokeWidth={stroke}
          svgStyle={svgStyle}
        />
      );
    case "cross":
      return (
        <CustomizedCross
          cx={cx}
          cy={cy}
          dotsStyle={dotsStyle}
          dotsColor={color}
          dotsSize={dotsSize}
          dotsStrokeWidth={dotsStrokeWidth}
          svgStyle={svgStyle}
        />
      );
    case "square":
      return (
        <CustomizedSquare
          cx={cx}
          cy={cy}
          dotsStyle={dotsStyle}
          dotsColor={color}
          dotsSize={dotsSize}
          dotsStrokeWidth={stroke}
          svgStyle={svgStyle}
        />
      );
    case "star":
      return (
        <CustomizedStar
          cx={cx}
          cy={cy}
          dotsStyle={dotsStyle}
          dotsColor={color}
          dotsSize={dotsSize}
          dotsStrokeWidth={stroke}
          svgStyle={svgStyle}
        />
      );
    case "direction":
      return (
        <CustomizedArrowAuto
          layout={layout}
          cx={cx}
          cy={cy}
          dotsStyle={dotsStyle}
          dotsColor={color}
          dotsSize={dotsSize}
          dotsStrokeWidth={dotsStrokeWidth}
          Y={Y}
          dotsTypeDirection={dotsTypeDirection}
          valueY1={valueY1}
          valueY2={valueY2}
          svgStyle={svgStyle}
        />
      );
    case "arrow left":
      return (
        <CustomizedArrowLeft
          cx={cx}
          cy={cy}
          dotsStyle={dotsStyle}
          dotsColor={color}
          dotsSize={dotsSize}
          dotsStrokeWidth={dotsStrokeWidth}
          svgStyle={svgStyle}
        />
      );
    case "arrow right":
      return (
        <CustomizedArrowRight
          cx={cx}
          cy={cy}
          dotsStyle={dotsStyle}
          dotsColor={color}
          dotsSize={dotsSize}
          dotsStrokeWidth={dotsStrokeWidth}
          svgStyle={svgStyle}
        />
      );
    case "arrow up":
      return (
        <CustomizedArrowUp
          cx={cx}
          cy={cy}
          dotsStyle={dotsStyle}
          dotsColor={color}
          dotsSize={dotsSize}
          dotsStrokeWidth={dotsStrokeWidth}
          svgStyle={svgStyle}
        />
      );
    case "arrow down":
      return (
        <CustomizedArrowDown
          cx={cx}
          cy={cy}
          dotsStyle={dotsStyle}
          dotsColor={color}
          dotsSize={dotsSize}
          dotsStrokeWidth={dotsStrokeWidth}
          svgStyle={svgStyle}
        />
      );
    case "tick":
      return (
        <CustomizedTick
          cx={cx}
          cy={cy}
          dotsStyle={dotsStyle}
          dotsColor={color}
          dotsSize={dotsSize}
          dotsStrokeWidth={dotsStrokeWidth}
          svgStyle={svgStyle}
        />
      );
    default:
      return null;
  }
};

export const CustomizedStar = (props) => {
  const { cx, cy, dotsStrokeWidth, dotsStyle, dotsColor, dotsSize, svgStyle } =
    props;

  const stroke = dotsStyle ? 0 : dotsStrokeWidth;
  const r = dotsSize / 2;

  return (
    <svg
      x={cx - r}
      y={cy - r}
      width={r * 2}
      height={r * 2}
      viewBox="0 0 1 1"
      style={svgStyle}
    >
      <g
        transform={"scale(" + (dotsStyle ? 1 : 1 - stroke / r) + ")"}
        style={{ transformOrigin: "center" }}
      >
        <path
          d="M 0.5 0 L 0.655 0.329 L 1 0.382 L 0.75 0.638 L 0.809 1 L 0.5 0.829 L 0.191 1 L 0.25 0.638 L 0 0.382 L 0.345 0.329 Z"
          stroke={dotsStyle ? "none" : dotsColor}
          fill={dotsStyle ? dotsColor : "none"}
          strokeWidth={stroke}
          vectorEffect="non-scaling-stroke"
        ></path>
      </g>
    </svg>
  );
};

export const CustomizedCircle = (props) => {
  const { cx, cy, dotsStyle, dotsColor, dotsSize, dotsStrokeWidth, svgStyle } =
    props;

  const r = dotsSize / 2;
  const stroke = dotsStrokeWidth;

  return (
    <svg
      x={cx - r - stroke / 2}
      y={cy - r - stroke / 2}
      width={r * 2 + stroke}
      height={r * 2 + stroke}
      style={svgStyle}
    >
      <circle
        cx={r + stroke / 2}
        cy={r + stroke / 2}
        r={r}
        stroke={dotsColor}
        strokeOpacity={!dotsStyle ? 1 : 0}
        fill={dotsColor}
        fillOpacity={dotsStyle ? 1 : 0}
        strokeWidth={stroke}
      />
    </svg>
  );
};

export const CustomizedSquare = (props) => {
  const { cx, cy, dotsStyle, dotsColor, dotsSize, dotsStrokeWidth, svgStyle } =
    props;

  const r = dotsSize / 2;
  const stroke = dotsStrokeWidth;

  return (
    <svg
      x={cx - r - stroke / 2}
      y={cy - r - stroke / 2}
      width={r * 2 + stroke}
      height={r * 2 + stroke}
      style={svgStyle}
    >
      <rect
        x={0}
        y={0}
        width={r * 2 + stroke}
        height={r * 2 + stroke}
        stroke={dotsColor}
        strokeOpacity={!dotsStyle ? 1 : 0}
        fill={dotsColor}
        fillOpacity={dotsStyle ? 1 : 0}
        strokeWidth={0}
      />

      <line
        x1={stroke / 2}
        x2={stroke / 2}
        y1={stroke / 2}
        y2={r * 2 + stroke / 2}
        stroke={dotsColor}
        strokeWidth={!dotsStyle ? stroke : 0}
        strokeLinecap="square"
      />
      <line
        x1={stroke / 2}
        x2={r * 2 + stroke / 2}
        y1={r * 2 + stroke / 2}
        y2={r * 2 + stroke / 2}
        stroke={dotsColor}
        strokeWidth={!dotsStyle ? stroke : 0}
        strokeLinecap="square"
      />
      <line
        x1={r * 2 + stroke / 2}
        x2={r * 2 + stroke / 2}
        y1={r * 2 + stroke / 2}
        y2={stroke / 2}
        stroke={dotsColor}
        strokeWidth={!dotsStyle ? stroke : 0}
        strokeLinecap="square"
      />
      <line
        x1={r * 2 + stroke / 2}
        x2={stroke / 2}
        y1={stroke / 2}
        y2={stroke / 2}
        stroke={dotsColor}
        strokeWidth={!dotsStyle ? stroke : 0}
        strokeLinecap="square"
      />
    </svg>
  );
};

export const CustomizedRhombus = (props) => {
  const { cx, cy, dotsStyle, dotsColor, dotsSize, dotsStrokeWidth, svgStyle } =
    props;

  const r = dotsSize / 2;
  const stroke = dotsStrokeWidth;

  const polyPoints =
    stroke +
    " " +
    (r + stroke / 2) +
    ", " +
    (r + stroke / 2) +
    " " +
    r * 2 +
    ", " +
    r * 2 +
    " " +
    (r + stroke / 2) +
    ", " +
    (r + stroke / 2) +
    " " +
    stroke;

  return (
    <svg
      x={cx - r - stroke}
      y={cy - r - stroke}
      width={r * 2 + stroke * 2}
      height={r * 2 + stroke * 2}
      style={svgStyle}
    >
      <polygon
        points={polyPoints}
        fill={dotsColor}
        fillOpacity={dotsStyle ? 1 : 0}
        strokeWidth={0}
      />

      <line
        x1={stroke}
        x2={r + stroke}
        y1={r + stroke}
        y2={r * 2 + stroke}
        stroke={dotsColor}
        strokeWidth={!dotsStyle ? stroke : 0}
        strokeLinecap="square"
      />
      <line
        x1={r + stroke}
        x2={r * 2 + stroke}
        y1={r * 2 + stroke}
        y2={r + stroke}
        stroke={dotsColor}
        strokeWidth={!dotsStyle ? stroke : 0}
        strokeLinecap="square"
      />
      <line
        x1={r * 2 + stroke}
        x2={r + stroke}
        y1={r + stroke}
        y2={stroke}
        stroke={dotsColor}
        strokeWidth={!dotsStyle ? stroke : 0}
        strokeLinecap="square"
      />
      <line
        x1={r + stroke}
        x2={stroke}
        y1={stroke}
        y2={r + stroke}
        stroke={dotsColor}
        strokeWidth={!dotsStyle ? stroke : 0}
        strokeLinecap="square"
      />
    </svg>
  );
};

export const CustomizedCross = (props) => {
  const { cx, cy, dotsColor, dotsSize, dotsStrokeWidth, svgStyle } = props;

  const r = dotsSize / 2;
  const stroke = dotsStrokeWidth;

  return (
    <svg
      x={cx - r - stroke / 2}
      y={cy - r - stroke / 2}
      width={r * 2 + stroke}
      height={r * 2 + stroke}
      style={svgStyle}
    >
      <line
        x1={stroke / 2}
        x2={r * 2 + stroke / 2}
        y1={stroke / 2}
        y2={r * 2 + stroke / 2}
        stroke={dotsColor}
        strokeWidth={stroke}
      />
      <line
        x1={stroke / 2}
        x2={r * 2 + stroke / 2}
        y1={r * 2 + stroke / 2}
        y2={stroke / 2}
        stroke={dotsColor}
        strokeWidth={stroke}
      />
    </svg>
  );
};

const tick = (r, stroke) =>
  "M" +
  stroke / 2 +
  " " +
  (r * 2 + stroke / 2) +
  " L " +
  stroke / 2 +
  " " +
  stroke / 2;

export const CustomizedTick = (props) => {
  const { cx, cy, dotsColor, dotsSize, dotsStrokeWidth, svgStyle } = props;

  const r = dotsSize / 2;
  const stroke = dotsStrokeWidth;

  return (
    <svg
      x={cx - stroke / 2}
      y={cy - r - stroke / 2}
      width={stroke}
      height={r * 2 + stroke}
      style={svgStyle}
    >
      <path d={tick(r, stroke)} stroke={dotsColor} strokeWidth={stroke}></path>
    </svg>
  );
};

const arrowUp = (r, stroke) =>
  "M" +
  stroke / 2 +
  " " +
  (r * 2 + stroke / 2) +
  " L " +
  (r + stroke / 2) +
  " " +
  (r + stroke / 2) +
  " L " +
  (r * 2 + stroke / 2) +
  " " +
  (r * 2 + stroke / 2);

const arrowDown = (r, stroke) =>
  "M" +
  stroke / 2 +
  " " +
  stroke / 2 +
  " L " +
  (r + stroke / 2) +
  " " +
  (r + stroke / 2) +
  " L " +
  (r * 2 + stroke / 2) +
  " " +
  stroke / 2;

const arrowLeft = (r, stroke) =>
  "M" +
  (r * 2 + stroke / 2) +
  " " +
  stroke / 2 +
  " L " +
  (r + stroke / 2) +
  " " +
  (r + stroke / 2) +
  " L " +
  (r * 2 + stroke / 2) +
  " " +
  (r * 2 + stroke / 2);

const arrowRight = (r, stroke) =>
  "M" +
  stroke / 2 +
  " " +
  stroke / 2 +
  " L " +
  (r + stroke / 2) +
  " " +
  (r + stroke / 2) +
  " L " +
  stroke / 2 +
  " " +
  (r * 2 + stroke / 2);

export const CustomizedArrowAuto = (props) => {
  const {
    layout,
    cx,
    cy,
    dotsColor,
    dotsSize,
    dotsStrokeWidth,
    dotsStyle,
    Y,
    dotsTypeDirection,
    valueY1,
    valueY2,
    svgStyle,
  } = props;

  const r = dotsSize / 2;
  const stroke = dotsStrokeWidth;

  const lineHoriz = "M0 " + r + " L " + (r * 2 + stroke) + " " + r;
  const lineVert = "M" + r + " 0 L " + r + " " + (r * 2 + stroke);

  let d = "";

  if (valueY1 === valueY2) {
    d = layout ? lineHoriz : lineVert;
  } else {
    if (dotsTypeDirection && Y === 2) {
      if (valueY2 > valueY1) {
        d = layout ? arrowUp(r, stroke) : arrowRight(r, stroke);
      }
      if (valueY2 < valueY1) {
        d = layout ? arrowDown(r, stroke) : arrowLeft(r, stroke);
      }
    }
    if (!dotsTypeDirection && Y === 1) {
      if (valueY2 > valueY1) {
        d = layout ? arrowDown(r, stroke) : arrowLeft(r, stroke);
      }
      if (valueY2 < valueY1) {
        d = layout ? arrowUp(r, stroke) : arrowRight(r, stroke);
      }
    }
  }

  return (
    <svg
      x={cx - r - stroke / 2}
      y={cy - r - stroke / 2}
      width={r * 2 + stroke}
      height={r * 2 + stroke}
      style={svgStyle}
    >
      <path
        d={d}
        stroke={dotsColor}
        strokeWidth={!dotsStyle ? stroke : 0}
        fill={dotsColor}
        fillOpacity={dotsStyle ? 1 : 0}
      ></path>
    </svg>
  );
};

export const CustomizedArrowLeft = (props) => {
  const { cx, cy, dotsColor, dotsStyle, dotsSize, dotsStrokeWidth, svgStyle } =
    props;

  const r = dotsSize / 2;
  const stroke = dotsStrokeWidth;

  return (
    <svg
      x={cx - r - stroke / 2}
      y={cy - r - stroke / 2}
      width={r * 2 + stroke}
      height={r * 2 + stroke}
      style={svgStyle}
    >
      <path
        d={arrowLeft(r, stroke)}
        stroke={dotsColor}
        strokeWidth={!dotsStyle ? stroke : 0}
        fill={dotsColor}
        fillOpacity={dotsStyle ? 1 : 0}
      ></path>
    </svg>
  );
};

export const CustomizedArrowRight = (props) => {
  const { cx, cy, dotsColor, dotsStyle, dotsSize, dotsStrokeWidth, svgStyle } =
    props;

  const r = dotsSize / 2;
  const stroke = dotsStrokeWidth;

  return (
    <svg
      x={cx - r - stroke / 2}
      y={cy - r - stroke / 2}
      width={r * 2 + stroke}
      height={r * 2 + stroke}
      style={svgStyle}
    >
      <path
        d={arrowRight(r, stroke)}
        stroke={dotsColor}
        strokeWidth={!dotsStyle ? stroke : 0}
        fill={dotsColor}
        fillOpacity={dotsStyle ? 1 : 0}
      ></path>
    </svg>
  );
};

export const CustomizedArrowUp = (props) => {
  const { cx, cy, dotsColor, dotsSize, dotsStyle, dotsStrokeWidth, svgStyle } =
    props;

  const r = dotsSize / 2;
  const stroke = dotsStrokeWidth;

  return (
    <svg
      x={cx - r - stroke / 2}
      y={cy - r - stroke / 2}
      width={r * 2 + stroke}
      height={r * 2 + stroke}
      style={svgStyle}
    >
      <path
        d={arrowUp(r, stroke)}
        stroke={dotsColor}
        strokeWidth={!dotsStyle ? stroke : 0}
        fill={dotsColor}
        fillOpacity={dotsStyle ? 1 : 0}
      ></path>
    </svg>
  );
};

export const CustomizedArrowDown = (props) => {
  const { cx, cy, dotsColor, dotsSize, dotsStyle, dotsStrokeWidth, svgStyle } =
    props;

  const r = dotsSize / 2;
  const stroke = dotsStrokeWidth;

  return (
    <svg
      x={cx - r - stroke / 2}
      y={cy - r - stroke / 2}
      width={r * 2 + stroke}
      height={r * 2 + stroke}
      style={svgStyle}
    >
      <path
        d={arrowDown(r, stroke)}
        stroke={dotsColor}
        strokeWidth={!dotsStyle ? stroke : 0}
        fill={dotsColor}
        fillOpacity={dotsStyle ? 1 : 0}
      ></path>
    </svg>
  );
};

export default CustomizedDot;
