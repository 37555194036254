import React, { useEffect } from "react";
import { ControlType, applyPropertyControls } from "../ui/controltype";
import {
  ScatterChart,
  Scatter as RScatter,
  LabelList,
  CartesianGrid,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip,
  Customized,
} from "recharts";
import { CustomizedDot } from "./customDot";
import {
  CustomizedDotLabel,
  CustomizedXAxisLabel,
  CustomizedYAxisLabel,
} from "./customLabel";
import {
  CustomizedRefDot,
  CustomizedRefLine,
  CustomizedRefArea,
} from "./customRef";
import { TextBlock } from "./textBlock";
import { CustomizedAxisTick } from "./customTick";
import { CustomizedTooltip } from "./customTooltip";
import { CustomLegend } from "./customLegend";
import { useDispatch } from "react-redux";
import { UPDATE_CHART_REF } from "../utils/actions";
import { Helmet } from "react-helmet";
import {
  presetLayout,
  presetXAxis,
  presetYAxis,
  presetGrid,
  presetDotsLabels,
  presetLegend,
  presetTitle,
  presetCaption,
  presetTooltip,
  presetRefDots,
  presetRefLines,
  presetRefAreas,
} from "./propertyControlsPresets";
import {
  getDomains,
  normalizeDimensions,
  getValidDimensionDataArr,
  getTicksNumber,
  getTicksCategory,
  validateChartData,
  getExtremes,
  TICKS_COUNT_DEFAULT,
} from "../utils/utils";
import { useHeaderHeight } from "./customHooks";

const ScatterDot = (props) => {
  const {
    cx,
    cy,
    z,
    zAxis,
    dotsType,
    dotsStyle,
    dotsColor,
    dotsStrokeWidth,
    isTooltipAllowed,
  } = props;

  return (
    <CustomizedDot
      cx={cx}
      cy={cy}
      dotsType={dotsType}
      dotsStyle={dotsStyle}
      dotsColor={dotsColor}
      dotsSize={getDotsSize(zAxis.domain, zAxis.range, z)}
      dotsStrokeWidth={dotsStrokeWidth}
      svgStyle={isTooltipAllowed ? {} : { pointerEvents: "none" }}
    />
  );
};

const ScatterDotLabel = (props) => {
  const {
    viewBox,
    z,
    value,
    domain,
    range,
    position,
    fontSize,
    fill,
    dotsType,
    dotsStyle,
    dotsStrokeWidth,
  } = props;

  return (
    <CustomizedDotLabel
      viewBox={viewBox}
      value={value}
      position={position}
      fontSize={fontSize}
      fill={fill}
      dotsType={dotsType}
      dotsStrokeWidth={dotsStrokeWidth}
      dotSize={
        dotsType === "tick"
          ? dotsStrokeWidth
          : dotsStyle
          ? getDotsSize(domain, range, z)
          : getDotsSize(domain, range, z) + dotsStrokeWidth
      }
    />
  );
};

function getValues(count) {
  return Array.from({ length: count }, () => Math.floor(Math.random() * 100));
}
function getNames(count) {
  return [...Array(count)].map((_, i) => {
    const numToLetters = (num) => {
      let mod = num % 26,
        pow = (num / 26) | 0,
        out = mod ? String.fromCharCode(64 + mod) : (--pow, "Z");
      return pow ? numToLetters(pow) + out : out;
    };
    return numToLetters(i + 1);
  });
}

function getTicks(data, dimensions, domain, xAxisProps, yAxisProps) {
  const xUnique = new Set() as any;
  const yUnique = new Set() as any;

  for (let i = 0; i < data.length; i++) {
    if (data[i]) {
      for (let j = 0; j < data[i].length; j++) {
        const valueX = data[i][j].x;
        const valueY = data[i][j].y;

        // get unique values for ticks
        xUnique.add(valueX);
        yUnique.add(valueY);
      }
    }
  }

  let x = [] as any;
  let y = [] as any;

  if (dimensions.X === "number") {
    x = getTicksNumber(
      xAxisProps.isTicksAuto ? TICKS_COUNT_DEFAULT : xAxisProps.tickCountValue,
      domain.X,
      getExtremes([...Array.from(xUnique)]),
      xAxisProps.tickCountAccuracy
    );
  } else {
    const xInterval = xAxisProps.isTicksAuto
      ? 0
      : xAxisProps.tickCountCategory
      ? 0
      : xAxisProps.tickCountValueCategory;
    x = getTicksCategory([...Array.from(xUnique)], xInterval);
  }

  if (dimensions.Y === "number") {
    y = getTicksNumber(
      yAxisProps.isTicksAuto ? TICKS_COUNT_DEFAULT : yAxisProps.tickCountValue,
      domain.Y,
      getExtremes([...Array.from(yUnique)]),
      yAxisProps.tickCountAccuracy
    );
  } else {
    const yInterval = yAxisProps.isTicksAuto
      ? 0
      : yAxisProps.tickCountCategory
      ? 0
      : yAxisProps.tickCountValueCategory;
    y = getTicksCategory([...Array.from(yUnique)], yInterval);
  }

  return { x, y };
}

function buildData(dataSeries, dataTypes) {
  const chartData = dataSeries.items.map((item, i) => {
    // validate, if data arrays consist of null or NaN or "" values, fill arrays with default values
    const xValues = getValidDimensionDataArr(item.X, dataTypes.X);
    const yValues = getValidDimensionDataArr(item.Y, dataTypes.Y);

    // build data for chart
    const valuesCount = Math.min(xValues.length, yValues.length);

    let arr = [] as any;
    for (let j = 0; j < valuesCount; j++) {
      if (
        ![xValues[j], yValues[j]].some(
          (elem) =>
            elem === null ||
            elem === undefined ||
            elem === "" ||
            Number.isNaN(elem) ||
            elem === "NaN" // sometimes there are NaN as string in excel files
        )
      ) {
        arr.push({
          x: xValues[j],
          y: yValues[j],
          z: item.Z[j] || 0,
          label: item.Labels[j] || "",
        });
      }
    }

    if (arr.length > 0) return arr;
  });
  return chartData.filter((elem) => elem !== undefined);
}

const scattersCount = 2;
const xCount = 10;
const dimensionsData = { x: "X", y: "Y" }; // link data with dimensions

//domain - min and max dot's sizes in px
//range - min and max z values
//value - current value
function getDotsSize(domain, range, value) {
  const domainZ = [
    domain && domain.length ? domain[0] : 0,
    domain && domain.length > 1 ? domain[1] : 0,
  ];
  const rangeZ = [
    range && range.length ? range[0] : 0,
    range && range.length > 1 ? range[1] : 0,
  ];
  const domainRange = domainZ[1] === domainZ[0] ? 1 : domainZ[1] - domainZ[0];
  const dotsSize =
    ((rangeZ[1] - rangeZ[0]) / domainRange) * (value - domainZ[0]) + rangeZ[0];

  return dotsSize;
}

export function Scatter(props) {
  const {
    layoutSettings,
    scatters,
    chartSettings,
    xAxis,
    yAxis,
    zAxis,
    grid,
    dataSeries,
    dimensions,
    legend,
    title,
    caption,
    tooltip,
    refDots,
    refLines,
    refAreas,
  } = props;

  const dataSeriesJSON = JSON.stringify(dataSeries);

  const { background, width } = layoutSettings;
  const { margin } = chartSettings;
  const { dotsMinSize, dotsMaxSize } = zAxis;

  //finding max and min values in z
  const zMinMax = dataSeries.items.map((item) => {
    return [Math.min.apply(Math, item.Z), Math.max.apply(Math, item.Z)];
  });

  const [data, setData] = React.useState([]);
  const [domain, setDomain] = React.useState({} as any);
  const [ticks, setTicks] = React.useState({} as any);
  const [dataTypes, setDataTypes] = React.useState({} as any);
  const [isData, setIsData] = React.useState(false);

  const [fontLoaded, setFontLoaded] = React.useState(false);

  const chartRef = React.useRef<HTMLDivElement>(null);
  const { headerRef, height } = useHeaderHeight(
    width,
    layoutSettings,
    caption,
    title,
    legend,
    scatters.items.map((elem) => elem.name).join("")
  );

  const dispatch = useDispatch();

  // custom fonts loading status
  title["fontLoaded"] = fontLoaded;
  for (let i = 0; i < caption.items.length; i++) {
    caption.items[i]["fontLoaded"] = fontLoaded;
  }

  useEffect(() => {
    document.fonts.ready.then(() => {
      setFontLoaded(true);
    });
  });

  let isNewData = false;

  useEffect(() => {
    const chartDimensions = normalizeDimensions(dimensions);
    const dataBuild = buildData(dataSeries, chartDimensions);
    const chartDomain = getDomains({
      dimensions: chartDimensions,
      x: xAxis,
      y: yAxis,
      dimensionsData: { x: "X", y: "Y" },
      layout: true,
    });
    const chartTicks = getTicks(
      dataBuild,
      chartDimensions,
      chartDomain,
      xAxis,
      yAxis
    );
    const isDataNotEmpty = validateChartData(dataBuild, scatters.items.length);

    setData(dataBuild);
    setDomain(chartDomain);
    setDataTypes(chartDimensions);
    setTicks(chartTicks);
    setIsData(isDataNotEmpty);

    isNewData = true;
  }, [dataSeriesJSON, dimensions.X, dimensions.Y]);

  useEffect(() => {
    if (isData && !isNewData) {
      const chartDomain = getDomains({
        dimensions: dataTypes,
        x: xAxis,
        y: yAxis,
        dimensionsData: { x: "X", y: "Y" },
        layout: true,
      });
      const chartTicks = getTicks(data, dataTypes, chartDomain, xAxis, yAxis);

      setDomain(chartDomain);
      setTicks(chartTicks);
    }
  }, [
    xAxis.domain,
    xAxis.domainMax,
    xAxis.domainMaxValue,
    xAxis.domainMin,
    xAxis.domainMinValue,
    yAxis.domain,
    yAxis.domainMax,
    yAxis.domainMaxValue,
    yAxis.domainMin,
    yAxis.domainMinValue,
  ]);

  useEffect(() => {
    if (isData && !isNewData) {
      const chartTicks = getTicks(data, dataTypes, domain, xAxis, yAxis);
      setTicks(chartTicks);
    }
  }, [
    xAxis.isTicksAuto,
    xAxis.tickCountValue,
    xAxis.tickCountAccuracy,
    xAxis.tickCountCategory,
    xAxis.tickCountValueCategory,
    yAxis.isTicksAuto,
    yAxis.tickCountValue,
    yAxis.tickCountAccuracy,
    yAxis.tickCountCategory,
    yAxis.tickCountValueCategory,
  ]);

  // TODO - chartRef in dep props is not good
  useEffect(() => {
    const element = chartRef.current;
    if (element) {
      const svg = element;
      dispatch(UPDATE_CHART_REF(svg));
    }
  }, [chartRef, dispatch]);

  return (
    <div ref={chartRef} style={{ background: background }}>
      <Helmet>
        <link
          rel="stylesheet"
          href={
            "https://fonts.googleapis.com/css?family=" +
            title.fontFamily.replace(/ /g, "+") +
            ":" +
            title.fontVariants
          }
          crossOrigin="anonymous"
        />
        {caption.items.map((elem, i) => {
          return (
            <link
              key={"caption-" + i}
              rel="stylesheet"
              href={
                "https://fonts.googleapis.com/css?family=" +
                elem.fontFamily.replace(/ /g, "+") +
                ":" +
                elem.fontVariants
              }
              crossOrigin="anonymous"
            />
          );
        })}
      </Helmet>
      <div ref={headerRef} style={{ width: width }}>
        {title.isVisible ? (
          <TextBlock key={"title"} title={title} fontLoaded={fontLoaded} />
        ) : (
          ""
        )}
        {caption.isVisible
          ? caption.items.map((elem, i) => {
              return (
                <TextBlock
                  key={"caption" + i}
                  title={elem}
                  fontLoaded={fontLoaded}
                />
              );
            })
          : ""}
        {legend.isVisible ? (
          <CustomLegend legendProps={legend} items={scatters.items} />
        ) : (
          ""
        )}
      </div>
      {isData ? (
        <ScatterChart
          width={width}
          height={height}
          layout={"horizontal"}
          margin={
            margin.isPerSide
              ? {
                  top: margin.top,
                  right: margin.right,
                  left: margin.left,
                  bottom: margin.bottom,
                }
              : {
                  top: margin.margin,
                  right: margin.margin,
                  left: margin.margin,
                  bottom: margin.margin,
                }
          }
          style={{ width: false, height: false }}
        >
          {refAreas.isVisible
            ? refAreas.items.map((elem, i) => (
                <Customized
                  key={"refArea-" + i}
                  component={
                    <CustomizedRefArea
                      cProps={{
                        elem: elem,
                        dataTypes: {
                          items: dataTypes.X,
                          series: dataTypes.Y,
                        },
                        existedValue: {
                          x: data[0][0]["x"],
                          y: data[0][0]["y"],
                        },
                        layout: true,
                        xAxisSettings: xAxis,
                        yAxisSettings: yAxis,
                        marginSettings: margin,
                        width: width,
                        height: height,
                      }}
                    />
                  }
                />
              ))
            : null}
          <CartesianGrid
            horizontal={grid.gridHoriz}
            vertical={grid.gridVert}
            stroke={grid.gridColor}
            syncWithTicks={true}
          />
          {tooltip.isVisible ? (
            <Tooltip
              cursor={
                tooltip.showCursor
                  ? { strokeWidth: 1, stroke: tooltip.cursorColor }
                  : false
              }
              content={
                <CustomizedTooltip
                  chartType={"scatter"}
                  txtColor={tooltip.txtColor}
                  background={tooltip.bgColor}
                  axesDataTypes={{
                    x: dataTypes.X,
                    y: dataTypes.Y,
                    z: dataTypes.Z,
                  }}
                  axesLabels={{
                    x: xAxis.labelText,
                    y: yAxis.labelText,
                    z: zAxis.labelText,
                  }}
                  units={{
                    x: xAxis.units,
                    y: yAxis.units,
                    z: zAxis.units,
                  }}
                  useAxesLabels={tooltip.useAxesLabels}
                  useLocales={{
                    x: xAxis.useLocale,
                    y: yAxis.useLocale,
                    z: zAxis.useLocale,
                  }}
                />
              }
            />
          ) : (
            ""
          )}
          <XAxis
            dataKey={"x"}
            type={dataTypes.X}
            axisLine={xAxis.isAxisLine}
            ticks={ticks.x}
            reversed={xAxis.tickOrder}
            hide={!xAxis.isVisible}
            height={xAxis.height}
            domain={domain.X}
            stroke={xAxis.color}
            orientation={xAxis.orient}
            allowDuplicatedCategory={false}
            // allowDataOverflow={true}
            tickSize={xAxis.tickSize}
            tickMargin={xAxis.tickMargin}
            interval={0}
            tick={
              ticks.x.length === 0 ? (
                false
              ) : (
                <CustomizedAxisTick
                  fontSize={xAxis.tickFontSize}
                  axis={"x"}
                  units={xAxis.units ? xAxis.units : ""}
                  useLocale={xAxis.useLocale}
                  type={dataTypes.X}
                  tickAlign={xAxis.orient}
                  angle={xAxis.tickRotate}
                />
              )
            }
            label={
              xAxis.isLabelVisible ? (
                <CustomizedXAxisLabel
                  value={xAxis.labelText}
                  fontSize={xAxis.labelFontSize}
                  color={xAxis.labelColor}
                  align={xAxis.labelAlign}
                  margin={xAxis.labelMargin}
                  axisOrient={xAxis.orient}
                />
              ) : (
                ""
              )
            }
          />
          <ZAxis dataKey="z" type="number" range={[dotsMinSize, dotsMaxSize]} />
          <YAxis
            dataKey={"y"}
            type={dataTypes.Y}
            axisLine={yAxis.isAxisLine}
            ticks={ticks.y}
            reversed={yAxis.tickOrder}
            hide={!yAxis.isVisible}
            width={yAxis.width}
            domain={domain.Y}
            stroke={yAxis.color}
            orientation={yAxis.orient}
            allowDuplicatedCategory={false}
            tickSize={yAxis.tickSize}
            tickMargin={yAxis.tickMargin}
            interval={0}
            tick={
              ticks.y.length === 0 ? (
                false
              ) : (
                <CustomizedAxisTick
                  fontSize={yAxis.tickFontSize}
                  axis={"y"}
                  units={yAxis.units ? yAxis.units : ""}
                  useLocale={yAxis.useLocale}
                  type={dataTypes.Y}
                  tickAlign={yAxis.orient}
                />
              )
            }
            label={
              yAxis.isLabelVisible ? (
                <CustomizedYAxisLabel
                  value={yAxis.labelText}
                  fontSize={yAxis.labelFontSize}
                  color={yAxis.labelColor}
                  align={yAxis.labelAlign}
                  margin={yAxis.labelMargin}
                  axisOrient={yAxis.orient}
                  rotateAngle={yAxis.labelRotate}
                />
              ) : (
                ""
              )
            }
          />

          {scatters.items.map((scatter, i) => {
            const {
              name,
              dotsType,
              dotsColor,
              dotsStyle,
              dotsStrokeWidth,
              showLabels,
              labelsColor,
              labelsFontSize,
              labelsPosition,
              showLine,
              lineColor,
              lineStrokeWidth,
              isDashed,
              dashes,
              spaces,
              isTooltipAllowed,
            } = scatter;
            const srokeDash = isDashed ? dashes + " " + spaces : "";
            return (
              <RScatter
                data={data[i]}
                key={"scatter-" + i}
                name={name ? name : i + 1}
                fill={dotsColor}
                line={
                  showLine
                    ? {
                        stroke: lineColor,
                        strokeWidth: lineStrokeWidth,
                        strokeDasharray: srokeDash,
                      }
                    : false
                }
                shape={
                  <ScatterDot
                    dotsType={dotsType}
                    dotsStyle={dotsStyle}
                    dotsColor={dotsColor}
                    dotsStrokeWidth={dotsStrokeWidth}
                    isTooltipAllowed={isTooltipAllowed}
                  />
                }
                isAnimationActive={false} // turn off animation because of dot's bug - https://github.com/recharts/recharts/issues/1426
                // animationDuration={500}
              >
                {showLabels ? (
                  <LabelList
                    dataKey="label"
                    content={
                      <ScatterDotLabel
                        domain={zMinMax[i]}
                        range={[dotsMinSize, dotsMaxSize]}
                        position={labelsPosition}
                        fontSize={labelsFontSize}
                        fill={labelsColor}
                        dotsType={dotsType}
                        dotsStrokeWidth={dotsStrokeWidth}
                      />
                    }
                  />
                ) : null}
              </RScatter>
            );
          })}
          {refLines.isVisible
            ? refLines.items.map((elem, i) => (
                <Customized
                  key={"refLine-" + i}
                  component={
                    <CustomizedRefLine
                      cProps={{
                        elem: elem,
                        dataTypes: {
                          items: dataTypes.X,
                          series: dataTypes.Y,
                        },
                        existedValue: {
                          x: data[0][0]["x"],
                          y: data[0][0]["y"],
                        },
                        layout: true,
                        xAxisSettings: xAxis,
                        yAxisSettings: yAxis,
                        marginSettings: margin,
                        width: width,
                        height: height,
                      }}
                    />
                  }
                />
              ))
            : null}
          {refDots.isVisible
            ? refDots.items.map((elem, i) => (
                <Customized
                  key={"refDot-" + i}
                  component={
                    <CustomizedRefDot
                      cProps={{
                        elem: elem,
                        dataTypes: {
                          items: dataTypes.X,
                          series: dataTypes.Y,
                        },
                        existedValue: {
                          x: data[0][0]["x"],
                          y: data[0][0]["y"],
                        },
                        layout: true,
                        xAxisSettings: xAxis,
                        yAxisSettings: yAxis,
                        marginSettings: margin,
                        width: width,
                        height: height,
                      }}
                    />
                  }
                />
              ))
            : null}
        </ScatterChart>
      ) : (
        <span style={{ opacity: 0.25 }}>Not enough data to chart</span>
      )}
    </div>
  );
}

const defaultDimensionsTypes = {
  Labels: ControlType.String,
  X: ControlType.Number,
  Y: ControlType.String,
  Z: ControlType.Number,
};

Scatter.defaultProps = {
  isData: false,
  layoutSettings: {
    isFullscreen: true,
    isWidthAuto: true,
    minWidth: 320,
    maxWidth: 2500,
    isHeightAuto: true,
    background: "rgba(255, 255, 255, 1)",
  },
  chartSettings: {
    margin: {
      margin: 80,
      isPerSide: false,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  sourceData: true,
  fileData: null,
  xAxis: {
    isVisible: false,
    isLabelVisible: true,
    labelText: "X axis label",
    labelAlign: "middle",
    labelMargin: 0,
    labelColor: "rgba(17, 153, 238, 1)",
    labelFontSize: 12,
    color: "rgba(17, 153, 238, 1)",
    height: 60,
    orient: "bottom",
    isAxisLine: true,
    domain: true,
    domainMin: "dataMin",
    domainMinValue: 0,
    domainMax: "dataMax",
    domainMaxValue: 1,
    tickFontSize: 12,
    isTicksAuto: true,
    tickCountValue: 5,
    tickCountAccuracy: false,
    tickCountCategory: true,
    tickCountValueCategory: 1,
    tickRotate: 0,
    tickSize: 6,
    tickMargin: 4,
    tickOrder: false,
    units: "",
    useLocale: false,
  },
  yAxis: {
    isVisible: false,
    isLabelVisible: true,
    labelText: "Y axis label",
    labelAlign: "middle",
    labelMargin: 0,
    labelColor: "rgba(17, 153, 238, 1)",
    labelFontSize: 12,
    labelRotate: -90,
    color: "rgba(17, 153, 238, 1)",
    width: 60,
    orient: "left",
    isAxisLine: true,
    domain: true,
    domainMin: "dataMin",
    domainMinValue: 0,
    domainMax: "dataMax",
    domainMaxValue: 1,
    tickFontSize: 12,
    isTicksAuto: true,
    tickCountValue: 5,
    tickCountAccuracy: false,
    tickCountCategory: true,
    tickCountValueCategory: 1,
    tickSize: 6,
    tickMargin: 4,
    tickOrder: false,
    units: "",
    useLocale: true,
  },
  zAxis: {
    units: "",
    dotsMinSize: 8,
    dotsMaxSize: 80,
    labelText: "Z axis label",
    useLocale: true,
  },
  grid: {
    gridHoriz: false,
    gridVert: false,
    gridColor: "rgba(17, 153, 238, .5)",
  },
  scatters: {
    isVisible: true,
    items: [...Array(scattersCount)].map((item, i) => {
      const color = "rgba(17, 153, 238, " + (i * 0.75 + 0.25) + ")";
      return {
        name: "",
        dotsType: "circle",
        dotsColor: color,
        dotsStyle: !i,
        dotsStrokeWidth: 1,
        showLabels: false,
        labelsColor: "rgba(0, 0, 0, .85)",
        labelsFontSize: 12,
        labelsPosition: "C",
        showLine: false,
        lineColor: color,
        lineStrokeWidth: 1,
        isDashed: false,
        dashes: 4,
        spaces: 4,
        isTooltipAllowed: true,
      };
    }),
  },
  dataSeries: {
    isVisible: true,
    items: [...Array(scattersCount)].map((item) => {
      return {
        Labels: [...Array(xCount)].map((_, i) =>
          String.fromCharCode("A".charCodeAt(0) + i)
        ),
        get X() {
          return getValues(xCount);
        },
        get Y() {
          return getNames(xCount);
        },
        get Z() {
          return getValues(xCount);
        },
      };
    }),
  },
  dimensions: defaultDimensionsTypes,
  legend: {
    isVisible: false,
    txtColor: "rgba(0,0,0,1)",
    fontSize: 14,
    iconSize: 14,
    layout: true,
    align: "center",
    margin: {
      margin: 0,
      isPerSide: true,
      top: 20,
      bottom: 20,
      left: 0,
      right: 0,
    },
  },
  title: {
    isVisible: false,
    text: "Chart title",
    txtColor: "rgba(0,0,0,1)",
    fontSize: 48,
    fontFamily: "Roboto",
    fontVariants: "regular",
    align: "center",
    margin: {
      margin: 0,
      isPerSide: true,
      top: 20,
      bottom: 20,
      left: 0,
      right: 0,
    },
  },
  refDots: {
    isVisible: false,
    items: [
      {
        xCoordinateNumber: null,
        xCoordinateCategory: "",
        yCoordinateNumber: null,
        yCoordinateCategory: "",
        dotSize: 16,
        dotColor: "rgba(238, 68, 69, 1)",
        dotStyle: true,
        dotStrokeWidth: 1,
        showLabel: false,
        name: "",
        labelColor: "rgba(0, 0, 0, 1)",
        labelFontSize: 12,
        labelPosition: "R",
        labelOffsetX: 0,
        labelOffsetY: 0,
        labelWrap: true,
        labelWidth: 20,
        showLabelLine: true,
        labelLineColor: "rgba(0, 0, 0, 1)",
        showLabelImage: false,
        labelImgUrl: "",
        labelImgPosition: false,
        labelImgCustomSize: false,
        labelImgWidth: 200,
      },
    ],
  },
  refLines: {
    isVisible: false,
    items: [
      {
        orient: true,
        xCoordinateNumber: null,
        xCoordinateCategory: "",
        yCoordinateNumber: null,
        yCoordinateCategory: "",
        lineColor: "rgba(238, 68, 69, 1)",
        lineStrokeWidth: 1,
        lineStyle: "Solid",
        showLabel: false,
        name: "",
        labelColor: "rgba(0, 0, 0, 1)",
        labelFontSize: 12,
        labelPosition: "TC",
        labelOffsetX: 0,
        labelOffsetY: 0,
        labelWrap: true,
        labelWidth: 20,
        labelPositionHoriz: "center",
        labelPositionVertOrient: false,
        labelPositionVert: "center",
        labelPositionVertAlign: "center",
      },
    ],
  },
  refAreas: {
    isVisible: false,
    items: [
      {
        x1CoordinateNumber: null,
        x2CoordinateNumber: null,
        x1CoordinateCategory: "",
        x2CoordinateCategory: "",
        y1CoordinateNumber: null,
        y2CoordinateNumber: null,
        y1CoordinateCategory: "",
        y2CoordinateCategory: "",
        fillColor: "rgba(238, 68, 69, .2)",
        strokeColor: "rgba(0, 0, 0, 1)",
        strokeWidth: 0,
        strokeStyle: "Solid",
      },
    ],
  },
  caption: {
    isVisible: false,
    items: [
      {
        text: "Chart description or data source",
        txtColor: "rgba(0,0,0,.5)",
        fontSize: 18,
        fontFamily: "Roboto",
        fontVariants: "300",
        align: "center",
        margin: {
          margin: 0,
          isPerSide: true,
          top: 20,
          bottom: 20,
          left: 0,
          right: 0,
        },
      },
    ],
  },
  tooltip: {
    isVisible: false,
    txtColor: "rgba(0,0,0,1)",
    bgColor: "rgba(255,255,255,1)",
    useAxesLabels: false,
    showCursor: true,
    cursorColor: "rgba(17, 153, 238, 1)",
  },
};

applyPropertyControls(Scatter, {
  //Settings or data
  isData: {
    type: ControlType.Boolean,
    title: "Change",
    disabledTitle: "settings",
    enabledTitle: "data",
    wide: true,
  },

  layoutSettings: presetLayout,

  chartSettings: {
    type: ControlType.Group,
    title: "Chart settings",
    isHeaderControls: false,
    propertyControl: {
      margin: {
        type: ControlType.FusedNumber,
        title: "Margin",
        toggleKey: "isPerSide",
        toggleTitles: ["All", "Individual"],
        valueKeys: ["top", "right", "bottom", "left"],
        valueLabels: ["T", "R", "B", "L"],
        min: 0,
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Axes
  xAxis: presetXAxis(dimensionsData, false), // link data with axes
  yAxis: presetYAxis(dimensionsData, false),
  zAxis: {
    type: ControlType.Group,
    title: "Z axis",
    isHeaderControls: false,
    propertyControl: {
      dotsMinSize: {
        type: ControlType.Number,
        title: "Min dot size",
        displayStepper: true,
        min: 1,
      },
      dotsMaxSize: {
        type: ControlType.Number,
        title: "Max dot size",
        displayStepper: true,
        min: 1,
      },
      labelText: {
        title: "Label",
        type: ControlType.String,
        placeholder: "",
      },
      units: {
        type: ControlType.String,
        title: "Units",
        placeholder: "%, $...",
      },
      useLocale: {
        type: ControlType.Boolean,
        title: "Locale",
        enabledTitle: "use",
        disabledTitle: "no",
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Scatters
  scatters: {
    type: ControlType.Collection,
    title: "Series",
    ref: "dataSeries",
    isGroupsColorLegend: true,
    propertyControl: {
      name: {
        title: "Name",
        type: ControlType.String,
        placeholder: "name for legend",
      },
      //Dots
      dotsType: {
        type: ControlType.Enum,
        title: "Type",
        options: ["circle", "square", "rhombus", "cross", "star", "tick"],
      },
      dotsColor: {
        title: "Color",
        type: ControlType.Color,
      },
      dotsStyle: {
        type: ControlType.Boolean,
        title: "Style",
        enabledTitle: "fill",
        disabledTitle: "stroke",
      },
      dotsStrokeWidth: {
        type: ControlType.Number,
        title: "↳ Stroke",
        displayStepper: true,
        hidden(props) {
          return props.dotsStyle === true;
        },
      },

      //Labels settings
      ...presetDotsLabels,

      //Line settings
      showLine: {
        type: ControlType.Boolean,
        title: "Line",
        enabledTitle: "show",
        disabledTitle: "hide",
      },
      lineColor: {
        title: "↳ Color",
        type: ControlType.Color,
        hidden(props) {
          return props.showLine === false;
        },
      },
      lineStrokeWidth: {
        type: ControlType.Number,
        title: "↳ Stroke",
        min: 0,
        displayStepper: true,
        hidden(props) {
          return props.showLine === false;
        },
      },
      //Dashed line
      isDashed: {
        type: ControlType.Boolean,
        title: "Dashed",
        enabledTitle: "yes",
        disabledTitle: "no",
        hidden(props) {
          return props.showLine === false;
        },
      },
      dashes: {
        type: ControlType.Number,
        title: "↳ Dashes",
        min: 1,
        displayStepper: true,
        hidden(props) {
          return props.isDashed === false || props.showLine === false;
        },
      },
      spaces: {
        type: ControlType.Number,
        title: "↳ Spaces",
        min: 1,
        displayStepper: true,
        hidden(props) {
          return props.isDashed === false || props.showLine === false;
        },
      },
      // Allow tooltip event
      isTooltipAllowed: {
        type: ControlType.Boolean,
        title: "Tooltip",
        enabledTitle: "allow",
        disabledTitle: "prevent",
      },
    },
    hidden(props) {
      return props.isData === true;
    },
  },

  //Grid
  grid: presetGrid,

  //Reference dots
  refDots: presetRefDots(dimensionsData, false),

  //Reference lines
  refLines: presetRefLines(dimensionsData, false),

  //Reference areas
  refAreas: presetRefAreas(dimensionsData, false),

  //Title
  title: presetTitle,

  //Caption
  caption: presetCaption,

  //Legend
  legend: presetLegend,

  //Tooltip
  tooltip: {
    ...presetTooltip(true),
    propertyControl: {
      useAxesLabels: {
        type: ControlType.Boolean,
        title: "Axes labels",
        enabledTitle: "use",
        disabledTitle: "ignore",
      },
      ...presetTooltip(true).propertyControl,
    },
  },

  //Data
  sourceData: {
    type: ControlType.Boolean,
    title: "Data",
    enabledTitle: "editable",
    disabledTitle: "file",
    hidden(props) {
      return props.isData === false;
    },
  },
  fileData: {
    title: "↳ File",
    type: ControlType.File,
    allowedFileTypes: ["xls"],
    hidden(props) {
      return props.sourceData === true || props.isData === false;
    },
  },

  // Data types
  dimensions: {
    type: ControlType.Group,
    title: "Data types",
    propertyControl: {
      X: {
        type: ControlType.Enum,
        seriesType: false,
        title: "X",
        path: "dataSeries",
        optionTitles: ["number", "category"],
        options: [ControlType.Number, ControlType.String],
        displaySegmentedControl: true,
      },
      Y: {
        type: ControlType.Enum,
        seriesType: false,
        title: "Y",
        path: "dataSeries",
        optionTitles: ["number", "category"],
        options: [ControlType.Number, ControlType.String],
        displaySegmentedControl: true,
      },
      Z: {
        type: ControlType.Enum,
        seriesType: false,
        title: "Z",
        path: "dataSeries",
        options: [ControlType.Number],
        hidden() {
          return true;
        },
      },
      Labels: {
        type: ControlType.Enum,
        seriesType: false,
        title: "Labels",
        path: "dataSeries",
        optionTitles: ["number", "category"],
        options: [ControlType.Number, ControlType.String],
        displaySegmentedControl: true,
        hidden() {
          return true;
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },

  dataSeries: {
    type: ControlType.Collection,
    title: "Scatters data",
    ref: "scatters",
    propertyControl: {
      Labels: {
        type: ControlType.Array,
        title: "Labels",
        propertyControl: {
          type(props) {
            return props.dimensions.Labels;
          },
        },
      },
      X: {
        type: ControlType.Array,
        title: "X values",
        propertyControl: {
          type(props) {
            return props.dimensions.X;
          },
          isNullsAllowed: true,
        },
      },
      Y: {
        type: ControlType.Array,
        title: "Y values",
        propertyControl: {
          type(props) {
            return props.dimensions.Y;
          },
          isNullsAllowed: true,
        },
      },
      Z: {
        type: ControlType.Array,
        title: "Z values",
        propertyControl: {
          type(props) {
            return props.dimensions.Z;
          },
          isNullsAllowed: true,
        },
      },
    },
    hidden(props) {
      return props.isData === false || props.sourceData === false;
    },
  },
});

export default Scatter;
